import React, { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom"
import { useIsValidUser } from '../customHooks/useIsValidUser';
import { useAccessToken } from '../customHooks/useAccessToken';

const ProtectedRoute = ({ children }) => {
    const [isAccessTokenAvailable, setIsAccessTokenAvailable] = useState<Boolean>(
        false
    )
    const history = useHistory();
    const accessToken = useAccessToken();
    const isValidUser = useIsValidUser();

    useEffect(() => {
        if (accessToken && accessToken !== '') {
            setIsAccessTokenAvailable(true)
        } else {
            setIsAccessTokenAvailable(false)
        }
    }, [accessToken])

    if (isAccessTokenAvailable && !isValidUser && window.location.pathname !== '/request_access') {
        history.push('/request_access');
        return null
    }
    return children
};

export default ProtectedRoute;