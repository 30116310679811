import React, { useEffect, useState } from 'react'
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  PDFViewer,
  Image,
  pdf,
} from '@react-pdf/renderer'
import dprlogo from '../../../Assets/dprlogoImage.png'
import panaromalogo from '../../../Assets/PanoramaImage.png'
import { alpha, IconButton, MenuItem, Skeleton } from '@mui/material'
import linkedinlogo from '../../../Assets/LinkedinLogo.png'
import { useLocation } from 'react-router-dom'
import { saveAs } from 'file-saver'
import { useSnackbar, VariantType } from 'notistack'
import { renderTitleHeader } from '../../CommonComponents/RenderTitleHeader/RenderTitleHeader'
import CloseIcon from '@mui/icons-material/Close'
import ReactDOM from 'react-dom'
import DialogAlert from '../../CommonComponents/DialogBox/dialogAlert'
import { SSConst } from '../../../config/config'
import { ExportStatus, useExportData } from '../../../customHooks/useExportData'

interface StakeholderPDFprops {
  getDataFunction: any
}
export const StakeHoldersPDF: React.FC<StakeholderPDFprops> = ({ getDataFunction }: StakeholderPDFprops) =>
// props: StakeholderPDFprops
{

  let tableData = {
    column1: [
      'Position Level',
      'Department',
      'Name',
      'Title',
      'Email',
      'Direct Phone',
      'LinkedIn',
    ],
    column2: [
      'position_level',
      'department_level',
      'executive_name',
      'title',
      'email',
      'personal_phone',
      'linkedin_profile',
    ],
  }
  const date = Date().split(' ')
  const monthConverter = (month: any) => {
    const monthArray = [
      ['Jan', '01'],
      ['Feb', '02'],
      ['Mar', '03'],
      ['Apr', '04'],
      ['May', '05'],
      ['Jun', '06'],
      ['Jul', '07'],
      ['Aug', '08'],
      ['Sep', '09'],
      ['Oct', '10'],
      ['Nov', '11'],
      ['Dec', '12'],
    ]
    for (let i = 0; i < monthArray.length; i++) {
      if (monthArray[i][0] == month) return monthArray[i][1]
    }
  }
  const search = useLocation().search
  const provider = new URLSearchParams(search).get('provider')
  const formatedDateTime = `${monthConverter(date[1])}/${date[2]}/${date[3]}`

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const ShowSnackBar = (
    message,
    variant: VariantType,
    duration,
    key?: string
  ) => {
    enqueueSnackbar(<div>{message}</div>, {
      variant: variant,
      persist: false,
      anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
      style: {
        flexDirection: 'row',
        maxWidth: '50px',
        marginLeft: '-150px',
      },
      autoHideDuration: 5000,
      key: key,
      action: (key) => (
        <IconButton
          color="inherit"
          aria-label="dismiss"
          onClick={() => closeSnackbar(key)}
        >
          <CloseIcon />
        </IconButton>
      ),
    })
    return key
  }

  const pdfStyle = StyleSheet.create({
    body: {
      paddingTop: 15,
      paddingBottom: 45,
      paddingHorizontal: 15,
    },

    footer: {
      flexDirection: 'column',
      bottom: 10,
      left: 10,
      position: 'absolute',
    },

    headerView: {
      display: 'flex',
      flexDirection: 'row',
      borderTop: '1px solid #EEE',
      paddingTop: 8,
      paddingBottom: 8,
      textAlign: 'center',
      fontSize: '6.01px',
      fontWeight: 600,
      color: '#3E3E3E',
      backgroundColor: '#F3F3F3',
      borderRadius: '5px',
    },
    rowView: {
      display: 'flex',
      flexDirection: 'row',
      borderTop: '1px solid #EEE',
      paddingTop: 8,
      paddingBottom: 8,
      textAlign: 'left',
      fontSize: '6px',
      color: '#3E3E3E',
    },
  })

  const MyDocument = (stakeHoldersDataExcel) => (
    <Document>
      <Page size="A4" style={pdfStyle.body}>
        <View fixed>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              paddingBottom: '10px',
              paddingRight: '20px',
            }}
          >
            <Image
              src={dprlogo}
              style={{
                width: '74px',
                height: '45px',
                padding: '5px',
              }}
            ></Image>

            <View
              style={{
                borderRight: '1px solid #CCCCCC',
                height: '30px',
                marginTop: '8px',
                marginLeft: '10px',
              }}
            ></View>

            <Image
              src={panaromalogo}
              style={{
                width: '100px',
                height: '30px',
                marginLeft: '20px',
                marginTop: '8px',
                padding: 5,
              }}
            ></Image>

            <Text
              style={{
                fontSize: '20px',
                color: '#000000',
                marginLeft: 'auto',
                marginTop: '15px',
              }}
            >
              CLIENT INSIGHTS
            </Text>
          </View>

          <View
            style={{
              borderBottom: '2px solid #4CAF50',
              width: '100%',
              marginBottom: '20px',
              marginTop: 10,
            }}
          ></View>
        </View>
        <View wrap style={{ flexDirection: 'row' }}>
          <Text
            style={{
              fontSize: '20px',
              fontWeight: 400,
              color: '#000000',
              marginRight: 'auto',
              marginTop: '8px',
            }}
          >
            STAKEHOLDERS
          </Text>
          <View
            style={{
              borderBottom: '1px solid black',
              width: '70%',
              marginBottom: '10px',
              marginTop: 10,
            }}
          ></View>
        </View>

        <View
          style={{
            marginTop: '20px',
          }}
        >
          <View>
            <Text
              style={{
                fontSize: '8px',
                marginBottom: '10px',
                fontStyle: 'italic',
                marginLeft: '5px',
              }}
            >
              Last Updated :{' '}
              {stakeHoldersDataExcel && stakeHoldersDataExcel.length
                ? stakeHoldersDataExcel[0].last_updated_on
                : '-'}
            </Text>
          </View>
          <View style={pdfStyle.headerView} fixed>
            {tableData['column1'].map((c) => (
              <Text
                style={{
                  width: `${100 / tableData['column1'].length}%`,
                  textAlign: 'left',
                  marginLeft: '10px',
                }}
              >
                {c}
              </Text>
            ))}
          </View>
          {stakeHoldersDataExcel.map((rowData) => (
            <>
              <View style={pdfStyle.rowView} wrap={false}>
                {tableData['column2'].map((c) => (
                  <Text
                    wrap={false}
                    style={{
                      width: `${100 / tableData['column1'].length}%`,
                      textAlign: 'left',
                      marginLeft:
                        c == 'linkedin_profile' || c == 'personal_phone'
                          ? '15px'
                          : '10px',
                    }}
                  >
                    {rowData[c] ? (
                      c == 'linkedin_profile' && rowData[c] != null ? (
                        <a rel="nooopener noreferrer" href={rowData[c]}>
                          <Image
                            src={linkedinlogo}
                            style={{ height: '10px', width: '10px' }}
                          ></Image>
                        </a>
                      ) : (
                        rowData[c]
                      )
                    ) : (
                      '-'
                    )}
                  </Text>
                ))}
              </View>
            </>
          ))}
        </View>

        <View wrap fixed style={pdfStyle.footer}>
          <View
            style={{
              border: '1px solid #D9D9D9',
              marginTop: '10px',
              width: '520px',
              height: '1px',
              left: '25px',
            }}
          ></View>
          <View
            style={{ flexDirection: 'row', fontSize: 8, marginTop: '10px' }}
          >
            <Text style={{ marginLeft: '30px', fontSize: 10 }}>
              Export Date : {formatedDateTime}
            </Text>
            <Text
              style={{
                fontWeight: 800,
                marginLeft: '70px',
                fontSize: 10,
              }}
            >
              Confidential: Do Not Distribute Outside of DPR
            </Text>
            <Text
              style={{ marginLeft: '110px', fontSize: 10 }}
              render={({ pageNumber }) => `Page ${pageNumber}`}
            />
          </View>
        </View>
      </Page>
    </Document>
  )

  return (
    <MenuItem
      style={{ color: 'black' }}
      onClick={() => {
        sessionStorage.setItem(
          SSConst.EXPORT_STATUS,
          'IN_PROGRESS'
        )
        ShowSnackBar(
          'Generating Export File, Please wait !!',
          'info',
          5000,
          'close'
        )
        getDataFunction().then(() => {
          ReactDOM.render(<DialogAlert />, document.createElement('div'))
          const ExportedData = JSON.parse(
            sessionStorage.getItem(SSConst.EXPORT_DATA)!
          )
          const stakeHoldersDataExcel = ExportedData?.getStakeholderData
          const pdfBlob = pdf(MyDocument(stakeHoldersDataExcel)).toBlob()
          pdfBlob.then((data) => {
            const blobObj = new Blob([data], { type: 'application/pdf' })
            saveAs(blobObj, 'Healthcare Panorama - Hospital Insights')
          })
        })
      }}
    >
      Current Tab
    </MenuItem>
  )
}
