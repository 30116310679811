import React, { useEffect } from 'react';
import { DataGrid, Sorting, HeaderFilter, Column, FilterRow, Selection, Scrolling, SearchPanel } from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.common.css';
import '../../../../../Theme/dx.material.panorama-datagrid-custom-scheme/dx.material.panorama-datagrid-custom-scheme.css';

import { Box, Stack } from '@mui/material';
import { useGetStakeholderInfoQuery } from '../../../../../__generated__/graphql';
import { useLocation } from 'react-router-dom';
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import { appInsights } from '../../../../../providers/appInsightProvider';

export const Stakeholders: React.FC = () => {
  const search = useLocation().search;
  const dataGrid = React.useRef(null);

  let definitive_id = new URLSearchParams(search).get('id')?.toString();
  let definitiveID: number = definitive_id ? parseInt(definitive_id) : 0;
  const { data: getStakeholderTabData, loading: loadingStakeholdersTab, error: errorStakeholderTab }
    = useGetStakeholderInfoQuery({
      variables: {
        definitive_id: definitiveID
      },
    });

  useEffect(() => {
    if (errorStakeholderTab instanceof Error) {
      appInsights.trackException({ exception: errorStakeholderTab, error: new Error("Failed to fetch Stakeholder Data.") })
    }
  }, [errorStakeholderTab])

  let Stakeholder_data =
    getStakeholderTabData &&
      getStakeholderTabData.DEFINITIVEHEALTHCARE_VW_HOSPITAL_EXECUTIVES &&
      getStakeholderTabData.DEFINITIVEHEALTHCARE_VW_HOSPITAL_EXECUTIVES.length
      ? getStakeholderTabData.DEFINITIVEHEALTHCARE_VW_HOSPITAL_EXECUTIVES
      : null

  return (
    <Box sx={{ height: "100vh", paddingLeft: "7px", paddingBottom: "2px", width: "calc(100vw - 50px)" }}>
      <Box style={{
        fontSize: "14px",
        margin: "2px 10px -2px 5px",
        fontWeight: "500",
        color: "#5d5d5d"
      }}>Last Updated : {Stakeholder_data && Stakeholder_data.length ? Stakeholder_data[0].last_updated_on : "-"}</Box>

      <DataGrid
        dataSource={Stakeholder_data}
        keyExpr="definitive_id"
        showBorders={true}
        columnResizingMode="widget"
        hoverStateEnabled
        id="stakeholder_grid"
        ref={dataGrid}
        style={{
          height: "calc(100% - 47.5vh)"
        }}
        width="calc(100vw - 50px)"
        allowColumnResizing
        allowColumnReordering
        columnAutoWidth
      >
        <Sorting mode="multiple" />

        <HeaderFilter allowSearch visible={true} />
        <FilterRow visible />
        <Selection />
        <SearchPanel visible={true} />

        <Scrolling useNative={true} preloadEnabled={true} />

        <Column allowSearch caption="Position Level" dataField="position_level" dataType="string" minWidth={250} width={200}></Column>
        <Column allowSearch caption="Department" dataField="department_level" dataType="string" minWidth={250} width={200}></Column>
        <Column allowSearch caption="Name"
          defaultSortOrder="asc"
          dataField="executive_name" dataType="string" minWidth={250} width={200}></Column>
        <Column allowSearch caption="Title" dataField="title" dataType="string" minWidth={250} width={200}></Column>
        <Column allowSearch caption="Email" dataField="email" dataType="string" minWidth={150} width={200}
          cellRender={(data) => {
            return (
              <a href={`mailto:${data.value}`} target="_blank" rel="noopener noreferrer">
                {data.value}
              </a>
            );
          }}></Column>
        <Column allowSearch caption="Direct Phone" minWidth={150} width={200} cellRender={(data) => {
          return (
            <a href={`tel:+${data.value}`} target="_blank" rel="noopener noreferrer">
              {data.value}
            </a>
          );
        }}
          dataField="personal_phone" dataType="string"></Column>
        <Column allowSearch caption="LinkedIn" minWidth={150} width={200} dataField="linkedin_profile" dataType="string"
          cellRender={(data) => {
            return (
              data.value ?
                <a href={data.value} target="_blank" rel="noopener noreferrer" title={data.value}>
                  <LinkedInIcon
                    color="primary"
                    fontSize="medium"
                  ></LinkedInIcon>
                </a> : null
            );
          }}
        ></Column>
      </DataGrid>
    </Box>
  )
}

export default Stakeholders;
