import { performanceTextExcel } from "../../config/commonFunction";

export const getExcelMarketSummaryaoa = (exportData) => {
    let positiveArray: any = [];
    let negativeArray: any = [];

    const summaryDataAll = exportData.MarketSummaryData ? exportData.MarketSummaryData : ['noData']

    // county factor
    if (summaryDataAll && summaryDataAll[0] && summaryDataAll[0]?.chr_rating_factor_type === 'positive' && summaryDataAll[0]?.chr_factor != null) {
        positiveArray.push(["", `${summaryDataAll[0].chr_factor}`])
    } else if (summaryDataAll && summaryDataAll[0] && summaryDataAll[0].chr_rating_factor_type === 'negative' && summaryDataAll[0]?.chr_factor != null) {
        negativeArray.push(["", `${summaryDataAll[0].chr_factor}`])
    }

    // market size factor
    if (summaryDataAll && summaryDataAll[0] && summaryDataAll[0]?.market_size_factor_type === 'positive' && summaryDataAll[0]?.market_size_rating_factor != null) {
        positiveArray.push(["", `${summaryDataAll[0]?.market_size_rating_factor}`])
    } else if (summaryDataAll && summaryDataAll[0] && summaryDataAll[0]?.market_size_factor_type === 'negative' && summaryDataAll[0]?.market_size_rating_factor != null) {
        negativeArray.push(["", `${summaryDataAll[0]?.market_size_rating_factor}`])
    }

    // outpatient factors
    if (summaryDataAll && summaryDataAll[0] && summaryDataAll[0]?.op_growth_factor_type === 'positive' && summaryDataAll[0]?.op_growth_factor != null) {
        positiveArray.push(["", `${summaryDataAll[0]?.op_growth_factor}`])
    } else if (summaryDataAll && summaryDataAll[0] && summaryDataAll[0]?.op_growth_factor_type === 'negative' && summaryDataAll[0]?.op_growth_factor != null) {
        negativeArray.push(["", `${summaryDataAll[0]?.op_growth_factor}`])
    }

    // inpatient factors
    if (summaryDataAll && summaryDataAll[0] && summaryDataAll[0].ip_growth_factor_type === 'positive' && summaryDataAll[0]?.ip_growth_factor != null) {
        positiveArray.push(["", `${summaryDataAll[0]?.ip_growth_factor}`])
    } else if (summaryDataAll && summaryDataAll[0] && summaryDataAll[0].ip_growth_factor_type === 'negative' && summaryDataAll[0]?.ip_growth_factor != null) {
        negativeArray.push(["", `${summaryDataAll[0]?.ip_growth_factor}`])
    }

    // growth factors
    if (summaryDataAll && summaryDataAll[0] && summaryDataAll[0]?.population_growth_factor_type === 'positive' && summaryDataAll[0]?.pop_growth_factor != null) {
        positiveArray.push(["", `${summaryDataAll[0]?.pop_growth_factor}`])
    } else if (summaryDataAll && summaryDataAll[0] && summaryDataAll[0]?.population_growth_factor_type === 'negative' && summaryDataAll[0]?.pop_growth_factor != null) {
        negativeArray.push(["", `${summaryDataAll[0]?.pop_growth_factor}`])
    }

    if (positiveArray && positiveArray[0]) {
        positiveArray[0][0] = 'Positive Factors';
    }
    if (negativeArray && negativeArray[0]) {
        negativeArray[0][0] = 'Risk Factors';
    }

    let MarketSummaryData = [
        ['Metric', 'Value', 'Score', 'Rating'],
        ['Overall Market Opportunity',
            ``,
            `${summaryDataAll[0]?.ovr_rating ? Math.round(summaryDataAll[0]?.ovr_rating) : 0}`,
            `${performanceTextExcel(summaryDataAll[0]?.ovr_rating ? Math.round(summaryDataAll[0]?.ovr_rating) : 0)}`],
        ['Market Concentration', `${summaryDataAll[0].market_score ? summaryDataAll[0].market_score.toFixed(2) : '-'}`, `${Math.round(summaryDataAll[0].market_size_rating)}`, `${performanceTextExcel(summaryDataAll[0].market_size_rating ? Math.round(summaryDataAll[0].market_size_rating) : 0)}`],
        ['5-Year Population Growth', `${summaryDataAll[0]?.population_growth ? summaryDataAll[0]?.population_growth.toFixed(1) + '%' : 'N/A'}`, `${summaryDataAll[0]?.population_growth_rating ? Math.round(summaryDataAll[0]?.population_growth_rating) : 0}`, `${performanceTextExcel(summaryDataAll[0]?.population_growth_rating ? Math.round(summaryDataAll[0]?.population_growth_rating) : 0)}`],
        ['5-Year Inpatient Growth', `${summaryDataAll[0]?.ip_growth ? summaryDataAll[0]?.ip_growth.toFixed(1) + '%' : '-'}`, `${summaryDataAll[0]?.ip_growth_rating ? Math.round(summaryDataAll[0]?.ip_growth_rating) : 0}`, `${performanceTextExcel(summaryDataAll[0]?.ip_growth_rating ? Math.round(summaryDataAll[0]?.ip_growth_rating) : 0)}`],
        ['5-Year Outpatient Growth', `${summaryDataAll[0]?.op_growth ? summaryDataAll[0]?.op_growth.toFixed(1) + '%' : '-'}`, `${summaryDataAll[0]?.op_growth_rating ? Math.round(summaryDataAll[0]?.op_growth_rating) : 0}`, `${performanceTextExcel(summaryDataAll[0]?.op_growth_rating ? Math.round(summaryDataAll[0]?.op_growth_rating) : 0)}`],
        ['County Health', `${summaryDataAll[0]?.chr_rank_avg ? summaryDataAll[0]?.chr_rank_avg.toFixed(2) : '-'}`, `${summaryDataAll[0]?.chr_rating ? Math.round(summaryDataAll[0]?.chr_rating) : 0}`, `${performanceTextExcel(summaryDataAll[0]?.chr_rating ? Math.round(summaryDataAll[0]?.chr_rating) : 0)}`],
        ['Number of Counties', `${summaryDataAll[0]?.num_of_ranked_counties ? summaryDataAll[0]?.num_of_ranked_counties : "-"}`],
        [''],
        ...positiveArray,
        ...negativeArray
    ]

    return MarketSummaryData
}