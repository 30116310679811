export const getExcelMarketDemographicsaoa = (exportData: any) => {
  let dataSet: any = []
  // Market heading from marketSummary
  // Market Opportunity from 'not there anywhere' (will bring from market oppurtunity score)
  // Total population from 'current_population'

  const summaryData = exportData?.MarketSummaryData;
  let demographicsCardData = exportData?.DemographicCard;
  let demographicsTableData = exportData?.DemographicTable;
  let marketServiceAreaByZipCodeData = exportData?.ServiceAreaTableDataZipCode

  // const concatData = demographicsCardData.concat(demographicsTableData[0])
  if (demographicsTableData != null && demographicsTableData[0] != null) {
    for (const key in demographicsCardData[0]) {
      demographicsTableData[0][key] = demographicsCardData[0][key];
    }
    demographicsTableData[0]['market'] = summaryData[0]['market'];
    demographicsTableData[0]['market_score'] = parseFloat(summaryData[0]['market_score'].toFixed(2));
    demographicsTableData[0]['pop_age_10_to_19'] = Math.round(demographicsTableData[0]?.curr_10_14 + demographicsTableData[0]?.curr_15_19);
    demographicsTableData[0]['pop_age_20_to_34'] = Math.round(demographicsTableData[0]?.curr_20_24 + demographicsTableData[0]?.curr_25_29 + demographicsTableData[0]?.curr_30_34);
    demographicsTableData[0]['pop_age_35_to_49'] = Math.round(demographicsTableData[0]?.curr_35_39 + demographicsTableData[0]?.curr_40_44 + demographicsTableData[0]?.curr_45_49);
    demographicsTableData[0]['pop_age_50_to_64'] = Math.round(demographicsTableData[0]?.curr_50_54 + demographicsTableData[0]?.curr_55_59 + demographicsTableData[0]?.curr_60_64);
    demographicsTableData[0]['pop_age_65_to_74'] = Math.round(demographicsTableData[0]?.curr_65_69 + demographicsTableData[0]?.curr_70_74);
    demographicsTableData[0]['pop_age_above_75'] = Math.round(demographicsTableData[0]?.curr_75_79 + demographicsTableData[0]?.curr_80_84 + demographicsTableData[0]?.curr_over_85);
    demographicsTableData[0]['pediatric_curr'] = Math.round(demographicsTableData[0]?.curr_0_4 + demographicsTableData[0]?.curr_5_9 + demographicsTableData[0]?.curr_10_14 + demographicsTableData[0]?.curr_15_19);
    demographicsTableData[0]['geriatric_curr'] = Math.round(demographicsTableData[0]?.curr_65_69 + demographicsTableData[0]?.curr_70_74 + demographicsTableData[0]?.curr_75_79 + demographicsTableData[0]?.curr_80_84 + demographicsTableData[0]?.curr_over_85);
    demographicsTableData[0]['pediatric_growth'] = Math.round(demographicsTableData[0]?.proj_0_4 + demographicsTableData[0]?.proj_5_9 + demographicsTableData[0]?.proj_10_14 + demographicsTableData[0]?.proj_15_19);
    demographicsTableData[0]['geriatric_growth'] = Math.round(demographicsTableData[0]?.proj_65_69 + demographicsTableData[0]?.proj_70_74 + demographicsTableData[0]?.proj_75_79 + demographicsTableData[0]?.proj_80_84 + demographicsTableData[0]?.proj_over_85);
  }

  // Removed - per capita income.
  const dataFormatHeaders = [
    'Market',
    'Market Opportunity Score',
    'Total Population',
    'Pop age 0-4',
    'Pop age 5 to 9',
    'Pop age 10 to 19',
    'Pop age 20 to 34',
    'Pop age 35 to 49',
    'Pop age 50 to 64',
    'Pop 65 to 74',
    'Pop age over 75',
    'Pediatric Only (<19)',
    'Geriatric Only (>65)',
    'Median Age',
    'Median Household Income',
    'High School Graduation Rate',
    'Projected Population',
    '5-Year Population Growth',
    'Pediatric Only (<19) 5-Year Population Growth',
    'Geriatric Only (>65) 5-Year Population Growth',
    '10-Year Inpatient Growth',
    '10-Year Outpatient Growth'

  ]
  const dataSetFormat = [
    'market',
    'market_score',
    'current_population',
    'curr_0_4',
    'curr_5_9',
    'pop_age_10_to_19',
    'pop_age_20_to_34',
    'pop_age_35_to_49',
    'pop_age_50_to_64',
    'pop_age_65_to_74',
    'pop_age_above_75',
    'pediatric_curr',
    'geriatric_curr',
    'median_age',
    'median_hh_income',
    'high_school_grad_pct',
    'projected_population',
    'pop_growth',
    'pop_growth_ped',
    'pop_growth_ger',
    'ip_growth_10',
    'op_growth_10'
  ]


  for (let i = 0; i < demographicsTableData!.length; i++) {
    let sett: string[] = []
    let j
    for (j = 0; j < dataSetFormat.length; j++) {
      for (const key in demographicsTableData![i]) {
        if (key == dataSetFormat[j]) {
          let temp = demographicsTableData![i][key]
          if (key === 'curr_0_4' || key === 'curr_5_9') temp = Math.round(temp)
          if (key === 'median_hh_income' || key === 'high_school_grad_pct')
            temp = parseFloat(temp.toFixed(2))
          if (key === 'median_age' || key === 'pop_growth' || key === 'pop_growth_ped' || key === 'pop_growth_ger' ||
            key === 'ip_growth_10' || key === 'op_growth_10')
            temp = parseFloat(temp.toFixed(1))
          sett.push(temp)
        }
      }
    }
    dataSet.push([...sett])
  }
  dataSet.unshift(dataFormatHeaders)
  return dataSet
}
