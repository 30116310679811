export const getClientStakeHolderaoa = (stakeHoldersDataExcel) => {
  let dataSet: (string[])[] = []
  let dataFormatHeaders = ['Functional Group', 'Position Level', 'Executive Name', 'Title', 'Email', 'Direct Phone', 'Linkedln Profile']
  let dataSetFormat = ['department_level', 'position_level', 'executive_name', 'title', 'email', 'personal_phone', 'linkedin_profile']
  for (let i = 0; i < stakeHoldersDataExcel!.length; i++) {
    let sett: string[] = []
    let j
    for (j = 0; j < dataSetFormat.length; j++) {
      for (const key in stakeHoldersDataExcel![i]) {
        if (key == dataSetFormat[j]) {
          sett.push(stakeHoldersDataExcel![i][key])
        }
      }
    }
    dataSet.push([...sett])
  }
  dataSet.unshift(dataFormatHeaders)
  return dataSet
}