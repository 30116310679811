import {
  Grid,
  Box,
  Divider,
  Rating,
  Stack,
  Skeleton,
  Typography,
  Tooltip,
} from '@mui/material'
import React from 'react'
import { styled } from '@mui/material/styles'
import { useLocation } from 'react-router-dom'
import { useGetPerformanceDataQuery } from '../../../../../__generated__/graphql'
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined'
import StarIcon from '@mui/icons-material/Star'
import { Factors } from './Factors'
import { PerformanceDialog } from './PerformanceDialog'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import {
  backgroundColor,
  performanceText,
  textColor,
} from '../../../../../config/commonFunction'
import { appInsights } from '../../../../../providers/appInsightProvider'

export const PerformanceTab: React.FC = () => {
  const search = useLocation().search
  const definitive_id = new URLSearchParams(search).get('id')
  const [open, setOpen] = React.useState(false)
  const [onClickType, setOnClickType] = React.useState('Fiscal Stewardship')

  const { data, loading, error } = useGetPerformanceDataQuery({
    variables: {
      definitive_id,
    },
  })

  let orderRating: any = {}

  let ratings: any =
    data &&
      data.DEFINITIVEHEALTHCARE_VW_OVERALL_RATINGS &&
      data.DEFINITIVEHEALTHCARE_VW_OVERALL_RATINGS.length
      ? data.DEFINITIVEHEALTHCARE_VW_OVERALL_RATINGS[0]
      : null
  if (ratings) {
    orderRating = {
      fiscal_stewardship: ratings.fiscal_stewardship,
      clinical_operations: ratings.clinical_operations,
      experiential_design: ratings.experiential_design,
      clinical_outcomes: ratings.clinical_outcomes,
      facility_conditions: ratings.facility_conditions,
    }
  }

  let rate_mapping = {
    fiscal_stewardship: 'Fiscal Stewardship',
    experiential_design: 'Patient Experience',
    clinical_outcomes: 'Clinical Outcomes',
    facility_conditions: 'Facility Conditions',
    clinical_operations: 'Operations',
  }

  const OrangeTopDivStyle = styled(Box)(() => ({
    background: backgroundColor(Math.round(ratings?.overall_rating)),
    border: `1px solid ${textColor(Math.round(ratings?.overall_rating))}`,
    borderRadius: '4px',
    padding: '10px 35px 10px 15px',
    margin: '0px 0px 0px 10px',
  }))

  const ClientPerformanceText = styled(Grid)(() => ({
    fontSize: '14px',
    margin: 'auto',
    fontWeight: 'bold',
  }))
  const GreenBoxStyle = styled(Box)(() => ({
    border: '2px solid #E8F5E9',
    borderRadius: '15px',
  }))

  const HeadingBox = styled(Box)(() => ({
    margin: '10px 0px 0px 10px',
    color: '#22577A',
    fontSize: '14px',
    fontWeight: '600',
  }))

  const ViewMoreGrid = styled(Grid)(() => ({
    background: '#E8F5E9',
    width: '100%',
    fontSize: '13px',
    marginTop: 'auto',
    borderBottomLeftRadius: '15px',
    borderBottomRightRadius: '15px',
  }))

  const PerformanceTypeText = styled(Grid)(() => ({
    textAlign: 'center',
    textTransform: 'capitalize',
    fontSize: '14px',
    fontWeight: '600',
    margin: 'auto',
  }))

  return (
    <React.Fragment>
      <Grid container>
        <Grid item lg={8} md={8}>
          {loading ? (
            <Box sx={{ margin: '10px' }}>
              <Typography variant="h1">
                <Skeleton variant="rounded" width="100%" />
              </Typography>
              <br />
              <Skeleton variant="rectangular" width="100%">
                <div style={{ paddingTop: '30%' }}></div>
              </Skeleton>
            </Box>
          ) : (
            <Grid>
              <OrangeTopDivStyle>
                <Grid container>
                  <ClientPerformanceText item lg={8.5} md={8.5}>
                    CLIENT PERFORMANCE SCORE
                    <Tooltip
                      title="DPR’s proprietary scoring system that evaluates performance at the organizational level, looking at specific metrics across five key categories. This score is intended for internal use only and should not be shared outside of the company"
                      placement="top"
                      arrow
                    >
                      <InfoOutlinedIcon
                        fontSize="small"
                        style={{
                          width: '15px',
                          verticalAlign: 'sub',
                          marginLeft: '10px',
                        }}
                      ></InfoOutlinedIcon>
                    </Tooltip>
                  </ClientPerformanceText>
                  <Grid item lg={2} md={2} sx={{ textAlign: 'end' }}>
                    <Rating
                      name="read-only"
                      value={ratings?.overall_rating}
                      readOnly
                      sx={{
                        color: textColor(Math.round(ratings?.overall_rating)),
                      }}
                    />
                  </Grid>
                  <PerformanceTypeText
                    item
                    lg={1.5}
                    md={1.5}
                    sx={{ textAlign: 'end' }}
                  >
                    {performanceText(Math.round(ratings?.overall_rating))
                      ?.replace('(', '')
                      .replace(')', '')}
                  </PerformanceTypeText>
                </Grid>
              </OrangeTopDivStyle>

              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{ margin: '0px -6px', marginTop: '6px' }}
              >
                {orderRating
                  ? Object.keys(orderRating).map((rate, i) => {
                    if (rate_mapping[rate])
                      return (
                        <Grid item md={4} lg={3}>
                          <GreenBoxStyle>
                            <Grid
                              container
                              direction="column"
                              justifyContent="flex-start"
                              alignItems="flex-start"
                              height={'128px'}
                            >
                              <Grid item lg={4}>
                                <HeadingBox>{rate_mapping[rate]}</HeadingBox>
                              </Grid>
                              <Grid item lg={0.5} sx={{ width: '100%' }}>
                                <Divider
                                  style={{
                                    borderColor: '#E8F5E9',
                                  }}
                                />
                              </Grid>
                              <Grid item lg={4} sx={{ width: '100%' }}>
                                <Box sx={{ marginTop: '10px' }}>
                                  <Grid
                                    container
                                    direction="row"
                                    sx={{ fontSize: '14px' }}
                                  >
                                    <Grid
                                      item
                                      md={2.5}
                                      sx={{ textAlign: 'center' }}
                                    >
                                      {ratings[rate]
                                        ? `(${Math.round(ratings[rate])}/5)`
                                        : ''}
                                    </Grid>
                                    <Grid item md={5.5}>
                                      {ratings[rate] ? (
                                        <StarIcon
                                          fontSize="small"
                                          sx={{
                                            color: textColor(
                                              Math.round(ratings[rate])
                                            ),
                                          }}
                                        />
                                      ) : null}
                                    </Grid>
                                    <Grid
                                      item
                                      md={4}
                                      sx={{ textAlign: 'center' }}
                                    >
                                      {performanceText(
                                        Math.round(ratings[rate]),
                                        'capitalize'
                                      )
                                        ?.replace('(', '')
                                        .replace(')', '')}
                                    </Grid>
                                  </Grid>
                                </Box>
                              </Grid>
                              <ViewMoreGrid item lg={3.5}>
                                <Stack
                                  direction="row"
                                  spacing={1}
                                  sx={{
                                    float: 'right',
                                    padding: '8px',
                                    cursor: 'pointer',
                                  }}
                                >
                                  <div
                                    onClick={() => {
                                      setOpen(true)
                                      setOnClickType(rate_mapping[rate])
                                      appInsights.trackEvent({ name: `Performance Tab | View More | ${rate_mapping[rate]}` })
                                    }}
                                  >
                                    View More
                                  </div>
                                  <KeyboardDoubleArrowRightOutlinedIcon
                                    fontSize="inherit"
                                    sx={{ marginTop: '2px !important' }}
                                  />
                                </Stack>
                              </ViewMoreGrid>
                            </Grid>
                          </GreenBoxStyle>
                        </Grid>
                      )
                  })
                  : null}
              </Grid>
            </Grid>
          )}
        </Grid>
        {
          open ? (
            <PerformanceDialog
              open={open}
              type={onClickType}
              setOpen={(open) => {
                setOpen(open)
              }}
              ratings={ratings}
            />
          ) : null
        }
        <Grid item lg={0.5} md={0.5}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh',
            }}
          >
            <Divider orientation="vertical" flexItem />
          </Box>
        </Grid>
        <Grid
          item
          lg={3.5}
          md={3.5}
          style={{ overflow: 'scroll', height: '50vh' }}
        >
          <Factors />
        </Grid>
      </Grid >
    </React.Fragment >
  )
}
