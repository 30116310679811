import { styled, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect } from 'react'
import { useExportData } from '../../customHooks/useExportData'

export const SnackbarExportStatus: React.FC = () => {
  const exportStatus = useExportData()

  useEffect(() => {
  }, [exportStatus])

  return <Box />
}
