import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { PredectiveNeedsDataTable } from './PredectiveneedsDataTable'
import { AzureMapsProvider } from 'react-azure-maps'
import MapController from '../../../CommonComponents/Maps/MapController'
import { PredictiveNeedsFilterButton } from './PredictiveNeedsFilter'
import { useLocation } from 'react-router-dom'

export interface IPredectiveNeedsProps { }

export const PredectiveNeeds: React.FC = () => {
  const search = useLocation().search

  const siteOfCareQueryParams = new URLSearchParams(search).get('siteOfCare')

  const [siteOfCare, setSiteOfCare] = useState<string>(
    siteOfCareQueryParams ? siteOfCareQueryParams : 'inpatient'
  )
  const [ageBracket, setAgeBracket] = useState<string>(
    'Adult,Geriatric,Pediatric'
  )
  const [serviceLine, setServiceLine] = useState<string>('all')
  const [year, setYear] = useState<string>(
    siteOfCareQueryParams ? '5-YearProjected' : '10-YearProjected'
  )

  const [uniqueServiceLine, setUniqueServiceLine] = useState([]);

  useEffect(() => {
    sessionStorage.setItem('predictive_patient_type', 'inpatient')
    sessionStorage.setItem('predictive_age_group', 'Adult,Geriatric,Pediatric')
    sessionStorage.setItem('predictive_year', '5-YearProjected')
    sessionStorage.setItem('predictive_serviceLine', 'all')
  }, [])

  return (
    <React.Fragment>
      <Grid container spacing={1} padding="10px" pt={0}>
        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          style={{
            height: '60vh',
          }}
        >
          <PredictiveNeedsFilterButton
            siteOfCare={siteOfCare}
            ageBracket={ageBracket}
            serviceLine={serviceLine}
            year={year}
            uniqueServiceLine={uniqueServiceLine}
            setSiteOfCare={(val) => {
              setSiteOfCare(val)
              sessionStorage.setItem('predictive_patient_type', val)
            }}
            setAgeBracket={(val) => {
              setAgeBracket(val)
              sessionStorage.setItem('predictive_age_group', val)
            }}
            setServiceLine={(val) => {
              setServiceLine(val)
              sessionStorage.setItem('predictive_serviceLine', val)
            }}
            setYear={(val) => {
              setYear(val)
              sessionStorage.setItem('predictive_year', val)
            }}
          />
          <AzureMapsProvider>
            <MapController
              MapType="PredictiveNeeds"
              AgeBracket=""
              MapView=""
              Providerdata=""
              siteOfCare={siteOfCare}
              ageBracket={ageBracket}
              serviceLine={serviceLine}
              year={year}
            />
          </AzureMapsProvider>
        </Grid>
        <Grid item lg={6} md={6} sm={12}>
          <PredectiveNeedsDataTable
            siteOfCare={siteOfCare}
            ageBracket={ageBracket}
            serviceLine={serviceLine}
            year={year}
            setUniqueServiceLine={(arr) => setUniqueServiceLine(arr)}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
