import DataGrid, {
  Column,
  HeaderFilter,
  Sorting, Scrolling
} from 'devextreme-react/data-grid'
import React, { useEffect } from 'react'
import 'devextreme/dist/css/dx.common.css';
import '../../../../../../Theme/dx.material.panorama-datagrid-custom-scheme/dx.material.panorama-datagrid-custom-scheme.css';
// import 'devextreme/dist/css/dx.material.blue.light.css'

import { renderTitleHeader } from '../../../../../CommonComponents/RenderTitleHeader/RenderTitleHeader';
import { useLocation } from 'react-router-dom'
import { useGetCaliforniaHcaiTableDataQuery } from '../../../../../../__generated__/graphql'
import { Box } from '@mui/material'
import { appInsights } from '../../../../../../providers/appInsightProvider';

export const CaliforniaHCAITable: React.FC = () => {
  const search = useLocation().search;

  let definitive_id = new URLSearchParams(search).get('id')?.toString();

  let definitiveID: number = definitive_id ? parseInt(definitive_id) : 0;

  const { data: CaliforniaHCAITableData, loading: CaliforniaHCAITableLoading, error: CaliforniaHCAITableError } = useGetCaliforniaHcaiTableDataQuery({
    variables: {
      definitive_id: definitiveID
    }
  });

  useEffect(() => {
    if (CaliforniaHCAITableError instanceof Error) {
      appInsights.trackException({ exception: CaliforniaHCAITableError, error: new Error("Failed to fetch California Table Data.") })
    }
  }, [CaliforniaHCAITableError])

  let california_table_data = CaliforniaHCAITableData?.OTHERDATA_VW_HCAI_BLDG_INFO ? CaliforniaHCAITableData?.OTHERDATA_VW_HCAI_BLDG_INFO : [];

  const dataGrid = React.useRef(null)
  return (
    <Box sx={{ height: "100vh", paddingLeft: "7px", paddingBottom: "2px", width: "calc(100vw - 50px)" }}>
      <Box style={{
        fontSize: "14px",
        margin: "10px",
        fontWeight: "500",
        color: "#5d5d5d"
      }}>Last Updated : {california_table_data && california_table_data.length ? california_table_data[0].last_updated_on : "-"}</Box>
      <DataGrid
        id="california_hcai_table_id"
        keyExpr="DEFINITIVE_ID"
        ref={dataGrid}
        columnAutoWidth
        dataSource={california_table_data}
        allowColumnResizing
        allowColumnReordering
        showBorders={true}
        columnResizingMode="widget"
        hoverStateEnabled
        style={{
          borderRadius: "5px",
          height: "calc(100% - 64vh)"
        }}
        width="calc(100vw - 50px)"

      >
        <Sorting mode="multiple" />
        <HeaderFilter visible={true} />
        <Scrolling useNative={true} preloadEnabled={true} />

        <Column minWidth={250} width={300}
          caption="BLDG Name"
          dataField="BUILDING_NAME"
          allowFiltering={true}
          alignment="left"
          headerCellRender={(cellData) =>
            renderTitleHeader(cellData.column?.caption)
          }
          defaultSortOrder="asc"
        ></Column>
        <Column minWidth={150} width={150}
          caption="BLDG Number"
          dataField="BUILDING_NBR"
          alignment="left"
          allowFiltering={true}
          headerCellRender={(cellData) =>
            renderTitleHeader(cellData.column?.caption)
          }
        ></Column>
        <Column minWidth={180} width={180}
          caption="BLDG Status"
          dataField="BUILDING_STATUS"
          alignment="left"
          headerCellRender={(cellData) =>
            renderTitleHeader(cellData.column?.caption)
          }
        ></Column>
        <Column minWidth={120} width={100}
          caption="Height"
          dataField="HEIGHT_FT"
          alignment="left"
          headerCellRender={(cellData) =>
            renderTitleHeader(cellData.column?.caption)
          }
        ></Column>
        <Column minWidth={120} width={100}
          caption="Stories"
          dataField="STORIES"
          alignment="left"
          headerCellRender={(cellData) =>
            renderTitleHeader(cellData.column?.caption)
          }
        ></Column>
        <Column minWidth={150} width={100}
          caption="Year Constructed"
          dataField="YEAR_COMPLETED"
          alignment="left"
          headerCellRender={(cellData) =>
            renderTitleHeader(cellData.column?.caption)
          }
        ></Column>
        <Column minWidth={250} width={200}
          caption="BLDG Code"
          dataField="BUILDING_CODE"
          headerCellRender={(cellData) =>
            renderTitleHeader(cellData.column?.caption)
          }
          alignment="left"
        ></Column>

        <Column minWidth={150} width={100}
          caption="Seismic Rating"
          dataField="SPC_RATING"
          alignment="left"
        ></Column>

        <Column minWidth={150} width={100}
          caption="OSHPD NPC Rating"
          dataField="OSHPD_RATING"
          alignment="left"
        ></Column>
      </DataGrid>
    </Box>
  )
}
