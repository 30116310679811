import App from './App';
import { reactPlugin } from './providers/appInsightProvider';
import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { msalConfig } from './providers/authProvider'
import { createRoot } from 'react-dom/client';
import './index.scss';
import 'devextreme/dist/css/dx.common.css';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';

const msalInstance = new PublicClientApplication(msalConfig)

const container = document.getElementById('root');
// @ts-ignore
const root = createRoot(container);

// TODO: remove ts ignore and infer proper type of store
// @ts-ignore
root.render(
  <MsalProvider instance={msalInstance}>
    <AppInsightsContext.Provider value={reactPlugin}>
      <App />
    </AppInsightsContext.Provider>
  </MsalProvider>
);