import { Box, Grid } from '@mui/material'
import React from 'react'
import { Summary_GeneralInfo } from './Summary_GeneralInfo'
import { Summary_Performance } from './Summary_Performance'
import { Summary_stackholder } from './Summary_stackholder'

export const ClientSummary: React.FC = () => {
  return (
    <Box>
      <Grid
        container
        style={{
          height: '56vh',
          overflow: 'auto',
          paddingBottom: '40px'
        }}
      >
        <Grid lg={4} md={12} xs={12} sm={12}>
          <Summary_GeneralInfo />
        </Grid>

        <Grid lg={4} md={12} xs={12} sm={12}>
          <Summary_Performance />
        </Grid>

        <Grid lg={4} md={12} xs={12} sm={12}>
          <Summary_stackholder />
        </Grid>
      </Grid>
    </Box>
  )
}

export default ClientSummary
