import React, { useState } from 'react'
import { DetailCard } from './DetailCard/DetailCard'
import { Table } from './HospitalTable/Table'

export const HeathSystem: React.FC = () => {
  const [dataSource, setDataSource] = useState([]);
  const setdataSourceFunction = (dataSource: any) => {
    setDataSource(dataSource)
  }
  return (
    <React.Fragment>
      <DetailCard dataSource={dataSource} />
      <Table setDataSourceFunction={setdataSourceFunction} />
    </React.Fragment>
  )
}
