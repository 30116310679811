import React, { useEffect, useState } from 'react'
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  Image,
  Svg,
  Path,
  pdf,
} from '@react-pdf/renderer'
import linkedinlogo from '../../../Assets/LinkedinLogo.png'
import dprlogo from '../../../Assets/dprlogoImage.png'
import panaromalogo from '../../../Assets/PanoramaImage.png'
import linkedInlogo from '../../../Assets/LinkedinLogo.png'
import { alpha, IconButton, MenuItem, Skeleton } from '@mui/material'
import {
  backgroundColor,
  performanceText,
  performanceTextPDF,
  textColor,
} from '../../../config/commonFunction'
import { useLocation } from 'react-router-dom'
import defaultmapimage from '../../../Assets/defaultmap.png'
import { saveAs } from 'file-saver'
import { useSnackbar, VariantType } from 'notistack'
import { renderTitleHeader } from '../../CommonComponents/RenderTitleHeader/RenderTitleHeader'
import CloseIcon from '@mui/icons-material/Close'
import ReactDOM from 'react-dom'
import DialogAlert from '../../CommonComponents/DialogBox/dialogAlert'
import { SSConst } from '../../../config/config'
import { ExportStatus, useExportData } from '../../../customHooks/useExportData'

interface HospitalAllTabPDFTableProps {
  desicionMaker: any
  getDataFunction: any
}

export const HospitalAllTabPDFTable: React.FC<HospitalAllTabPDFTableProps> = (
  props: HospitalAllTabPDFTableProps
) => {

  const date = Date().split(' ')
  const monthConverter = (month: any) => {
    const monthArray = [
      ['Jan', '01'],
      ['Feb', '02'],
      ['Mar', '03'],
      ['Apr', '04'],
      ['May', '05'],
      ['Jun', '06'],
      ['Jul', '07'],
      ['Aug', '08'],
      ['Sep', '09'],
      ['Oct', '10'],
      ['Nov', '11'],
      ['Dec', '12'],
    ]
    for (let i = 0; i < monthArray.length; i++) {
      if (monthArray[i][0] == month) return monthArray[i][1]
    }
  }

  const GeneralInfoMap: any = sessionStorage.getItem('GeneralInfoMap')
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const ShowSnackBar = (
    message,
    variant: VariantType,
    duration,
    key?: string
  ) => {
    enqueueSnackbar(<div>{message}</div>, {
      variant: variant,
      persist: false,
      anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
      style: {
        flexDirection: 'row',
        maxWidth: '50px',
        marginLeft: '-150px',
      },
      autoHideDuration: duration,
      key: key,
      action: (key) => (
        <IconButton
          color="inherit"
          aria-label="dismiss"
          onClick={() => closeSnackbar(key)}
        >
          <CloseIcon />
        </IconButton>
      ),
    })
    return key
  }

  let tableData = {
    column: [
      'BLDG Name',
      'BLDG Number',
      'BLDG Status',
      'Height',
      'Stories',
      'Year Constructed',
      'BLDG Code',
    ],
    column1: [
      'BLDG Name',
      'BLDG Number',
      'BLDG Status',
      'Height',
      'Stories',
      'Year Constructed',
      'BLDG Code',
      'Seismic Rating',
      'OSHPD NPC Rating',
    ],
    column2: [
      'BUILDING_NAME',
      'BUILDING_NBR',
      'BUILDING_STATUS',
      'HEIGHT_FT',
      'STORIES',
      'YEAR_COMPLETED',
      'BUILDING_CODE',
      'SPC_RATING',
      'OSHPD_RATING',
    ],
  }

  let tableData1 = {
    column1: [
      'Position Level',
      'Department',
      'Name',
      'Title',
      'Email',
      'Direct Phone',
      'LinkedIn',
    ],
    column2: [
      'position_level',
      'department_level',
      'executive_name',
      'title',
      'email',
      'personal_phone',
      'linkedin_profile',
    ],
  }

  const search = useLocation().search
  const type = new URLSearchParams(search).get('type')
  const state = new URLSearchParams(search).get('state')
  const provider = new URLSearchParams(search).get('provider')
  const formatedDateTime = `${monthConverter(date[1])}/${date[2]}/${date[3]}`
  const pdfStyle = StyleSheet.create({
    body: {
      paddingTop: 15,
      paddingBottom: 45,
      paddingHorizontal: 15,
    },

    headerView: {
      display: 'flex',
      flexDirection: 'row',
      borderTop: '1px solid #EEE',
      paddingTop: 8,
      paddingBottom: 8,
      textAlign: 'center',
      fontSize: '8px',
      fontWeight: 600,
      color: '#3E3E3E',
      backgroundColor: '#F3F3F3',
      borderRadius: '5px',
    },
    rowView: {
      display: 'flex',
      flexDirection: 'row',
      borderTop: '1px solid #EEE',
      paddingTop: 8,
      paddingBottom: 8,
      textAlign: 'left',
      fontSize: '6px',
      color: '#3E3E3E',
    },
    footer: {
      flexDirection: 'column',
      position: 'absolute',
      bottom: 10,
      left: 10,
    },
  })

  const RenderRating = (props) =>
    parseInt(props?.rating) == 1 ? (
      <View style={{ flexDirection: 'row' }}>
        <View>
          <Svg viewBox="0 0 60 60">
            <Path
              fill="#FF0707"
              stroke="#FF0707"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-10px' }}>
          <Svg viewBox="0 0 60 60">
            <Path
              fill="#CCCCCC"
              stroke="#CCCCCC"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-12px' }}>
          <Svg viewBox="0 0 60 60">
            <Path
              fill="#CCCCCC"
              stroke="#CCCCCC"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-12px' }}>
          <Svg viewBox="0 0 60 60">
            <Path
              fill="#CCCCCC"
              stroke="#CCCCCC"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-12px' }}>
          <Svg viewBox="0 0 60 60">
            <Path
              fill="#CCCCCC"
              stroke="#CCCCCC"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
      </View>
    ) : parseInt(props?.rating) == 2 ? (
      <View style={{ flexDirection: 'row' }}>
        <View>
          <Svg viewBox="0 0 60 60">
            <Path
              fill="#FB743F"
              stroke="#FB743F"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-10px' }}>
          <Svg viewBox="0 0 60 60">
            <Path
              fill="#FB743F"
              stroke="#FB743F"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-12px' }}>
          <Svg viewBox="0 0 60 60">
            <Path
              fill="#CCCCCC"
              stroke="#CCCCCC"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-12px' }}>
          <Svg viewBox="0 0 60 60">
            <Path
              fill="#CCCCCC"
              stroke="#CCCCCC"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-12px' }}>
          <Svg viewBox="0 0 60 60">
            <Path
              fill="#CCCCCC"
              stroke="#CCCCCC"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
      </View>
    ) : parseInt(props?.rating) == 3 ? (
      <View style={{ flexDirection: 'row' }}>
        <View>
          <Svg viewBox="0 0 60 60">
            <Path
              fill="#FAAF00"
              stroke="#FAAF00"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-10px' }}>
          <Svg viewBox="0 0 60 60">
            <Path
              fill="#FAAF00"
              stroke="#FAAF00"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-12px' }}>
          <Svg viewBox="0 0 60 60">
            <Path
              fill="#FAAF00"
              stroke="#FAAF00"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-12px' }}>
          <Svg viewBox="0 0 60 60">
            <Path
              fill="#CCCCCC"
              stroke="#CCCCCC"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-12px' }}>
          <Svg viewBox="0 0 60 60">
            <Path
              fill="#CCCCCC"
              stroke="#CCCCCC"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
      </View>
    ) : parseInt(props?.rating) == 4 ? (
      <View style={{ flexDirection: 'row' }}>
        <View>
          <Svg viewBox="0 0 60 60">
            <Path
              fill="#73E793"
              stroke="#73E793"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-10px' }}>
          <Svg viewBox="0 0 60 60">
            <Path
              fill="#73E793"
              stroke="#73E793"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-12px' }}>
          <Svg viewBox="0 0 60 60">
            <Path
              fill="#73E793"
              stroke="#73E793"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-12px' }}>
          <Svg viewBox="0 0 60 60">
            <Path
              fill="#73E793"
              stroke="#73E793"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-12px' }}>
          <Svg viewBox="0 0 60 60">
            <Path
              fill="#CCCCCC"
              stroke="#CCCCCC"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
      </View>
    ) : parseInt(props?.rating) == 5 ? (
      <View style={{ flexDirection: 'row' }}>
        <View>
          <Svg viewBox="0 0 60 60">
            <Path
              fill="#4CAF50"
              stroke="#4CAF50"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-10px' }}>
          <Svg viewBox="0 0 60 60">
            <Path
              fill="#4CAF50"
              stroke="#4CAF50"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-12px' }}>
          <Svg viewBox="0 0 60 60">
            <Path
              fill="#4CAF50"
              stroke="#4CAF50"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-12px' }}>
          <Svg viewBox="0 0 60 60">
            <Path
              fill="#4CAF50"
              stroke="#4CAF50"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-12px' }}>
          <Svg viewBox="0 0 60 60">
            <Path
              fill="#4CAF50"
              stroke="#4CAF50"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
      </View>
    ) : (
      <View style={{ flexDirection: 'row' }}>
        <View>
          <Svg viewBox="0 0 60 60">
            <Path
              fill="#CCCCCC"
              stroke="#CCCCCC"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-10px' }}>
          <Svg viewBox="0 0 60 60">
            <Path
              fill="#CCCCCC"
              stroke="#CCCCCC"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-12px' }}>
          <Svg viewBox="0 0 60 60">
            <Path
              fill="#CCCCCC"
              stroke="#CCCCCC"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-12px' }}>
          <Svg viewBox="0 0 60 60">
            <Path
              fill="#CCCCCC"
              stroke="#CCCCCC"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-12px' }}>
          <Svg viewBox="0 0 60 60">
            <Path
              fill="#CCCCCC"
              stroke="#CCCCCC"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
      </View>
    )

  const MyDocument = (provider_dataExcel, GeneralInfo_data, Performance_SummaryData, OverallPositiveArray, OverallNegativeArray, ratings, fiscal_rating_data, fiscal_score_data, positive_fiscal, negative_fiscal, operations_rating_data, operations_score_data, positive_clinical, negative_clinical, patient_experience_rating_data, patient_experience_score_data, positive_patient_exp, negative_patient_exp, clinical_outcome_rating_data, clinical_outcome_score_data, positive_clinical_outcomes, negative_clinical_outcomes, facility_conditions_rating_data, facility_conditions_score_data, positive_facility_conditions, negative_facility_conditions, stakeHoldersDataExcel, california_data, california_table_data, pairs) => (
    <Document>
      {/* Summary PDF */}
      <Page size="A4" style={pdfStyle.body}>
        <View fixed wrap>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              paddingBottom: '10px',
            }}
          >
            <Image
              src={dprlogo}
              style={{
                width: '74px',
                height: '45px',
                padding: '5px',
              }}
            ></Image>

            <View
              style={{
                borderRight: '1px solid #CCCCCC',
                height: '30px',
                marginTop: '8px',
                marginLeft: '10px',
              }}
            ></View>

            <Image
              src={panaromalogo}
              style={{
                width: '100px',
                height: '30px',
                marginLeft: '20px',
                marginTop: '8px',
                padding: 5,
              }}
            ></Image>

            <Text
              style={{
                fontSize: '20px',
                color: '#000000',
                marginLeft: 'auto',
                marginTop: '15px',
              }}
            >
              CLIENT INSIGHTS
            </Text>
          </View>

          <View
            style={{
              borderBottom: '2px solid #4CAF50',
              width: '100%',
              marginBottom: '20px',
              marginTop: 10,
            }}
          ></View>
        </View>

        <View
          wrap
          style={{
            backgroundColor: '#E8F5E9',
            border: '1px solid #E8F5E9',
            height: 60,
            width: '100%',
            borderRadius: 8,
          }}
        >
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <View>
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: 400,
                  color: '#3E3E3E',
                  padding: 5,
                  marginTop: '5px',
                  marginLeft: '10px',
                }}
              >
                {' '}
                Provider Type
              </Text>

              <Text
                style={{
                  fontSize: 12,
                  fontWeight: 'bold',
                  color: '#3E3E3E',
                  padding: 5,
                  marginTop: '-5px',
                  marginLeft: '13px',
                }}
              >
                {type}{' '}
              </Text>
            </View>

            <View>
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: 400,
                  color: '#3E3E3E',
                  padding: 5,
                  marginTop: '5px',
                  marginLeft: '40px',
                }}
              >
                {' '}
                State
              </Text>

              <Text
                style={{
                  fontSize: 12,
                  fontWeight: 'bold',
                  color: '#3E3E3E',
                  padding: 5,
                  marginLeft: '42px',
                  marginTop: '-5px',
                }}
              >
                {state}{' '}
              </Text>
            </View>

            <View
              style={{
                marginLeft: 15,
              }}
            >
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: 400,
                  color: '#3E3E3E',
                  padding: 5,
                  marginLeft: 25,
                  marginTop: '5px',
                }}
              >
                {' '}
                Provider
              </Text>

              <Text
                style={{
                  fontSize: 11,
                  fontWeight: 'bold',
                  color: '#3E3E3E',
                  padding: 5,
                  marginTop: '-7px',
                  marginLeft: 27,
                  width: '300px',
                }}
              >
                {provider?.split('')}{' '}
              </Text>
            </View>
          </View>
        </View>

        <View
          style={{
            backgroundColor: '#FAFAFA',
            border: '1px solid #E8F5E9',
            height: 120,
            width: '100%',
            borderRadius: 8,
            marginTop: 20,
            paddingBottom: 5,
          }}
        >
          <View>
            <Text
              style={{
                color: '#22577A',
                fontSize: 14,
                fontWeight: 'bold',
                padding: 8,
                marginTop: '3px',
              }}
            >
              {provider_dataExcel?.hospital_name}
            </Text>
            <View style={{ flexDirection: 'row' }}>
              <View
                style={{
                  width: '20px',
                  height: '20px',
                  marginTop: '2px',
                  marginLeft: '10px',
                }}
              >
                <Svg width="20" height="20" viewBox="0 0 30 30">
                  <Path
                    d="M14.36 12C14.44 11.34 14.5 10.68 14.5 10C14.5 9.32 14.44 8.66 14.36 8H17.74C17.9 8.64 18 9.31 18 10C18 10.69 17.9 11.36 17.74 12M12.59 17.56C13.19 16.45 13.65 15.25 13.97 14H16.92C15.96 15.65 14.43 16.93 12.59 17.56ZM12.34 12H7.66C7.56 11.34 7.5 10.68 7.5 10C7.5 9.32 7.56 8.65 7.66 8H12.34C12.43 8.65 12.5 9.32 12.5 10C12.5 10.68 12.43 11.34 12.34 12ZM10 17.96C9.17 16.76 8.5 15.43 8.09 14H11.91C11.5 15.43 10.83 16.76 10 17.96ZM6 6H3.08C4.03 4.34 5.57 3.06 7.4 2.44C6.8 3.55 6.35 4.75 6 6ZM3.08 14H6C6.35 15.25 6.8 16.45 7.4 17.56C5.57 16.93 4.03 15.65 3.08 14ZM2.26 12C2.1 11.36 2 10.69 2 10C2 9.31 2.1 8.64 2.26 8H5.64C5.56 8.66 5.5 9.32 5.5 10C5.5 10.68 5.56 11.34 5.64 12M10 2.03C10.83 3.23 11.5 4.57 11.91 6H8.09C8.5 4.57 9.17 3.23 10 2.03ZM16.92 6H13.97C13.65 4.75 13.19 3.55 12.59 2.44C14.43 3.07 15.96 4.34 16.92 6ZM10 0C4.47 0 0 4.5 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0Z"
                    fill="#3E3E3E"
                  />
                </Svg>
              </View>
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: 500,
                  color: 'blue',
                  paddingLeft: 10,
                  marginTop: '2px',
                  textDecoration: 'underline',
                }}
              >
                {provider_dataExcel?.website}
              </Text>
            </View>
            <View style={{ flexDirection: 'row' }}>
              <View
                style={{
                  height: '20px',
                  width: '20px',
                  marginTop: '10px',
                  marginLeft: '10px',
                }}
              >
                <Svg width="20" height="20" viewBox="0 0 30 30">
                  <Path
                    d="M17 12.5C15.8 12.5 14.5 12.3 13.4 11.9C13.3 11.9 13.2 11.9 13.1 11.9C12.8 11.9 12.6 12 12.4 12.2L10.2 14.4C7.4 12.9 5 10.6 3.6 7.8L5.8 5.6C6.1 5.3 6.2 4.9 6 4.6C5.7 3.5 5.5 2.2 5.5 1C5.5 0.5 5 0 4.5 0H1C0.5 0 0 0.5 0 1C0 10.4 7.6 18 17 18C17.5 18 18 17.5 18 17V13.5C18 13 17.5 12.5 17 12.5ZM2 2H3.5C3.6 2.9 3.8 3.8 4 4.6L2.8 5.8C2.4 4.6 2.1 3.3 2 2ZM16 16C14.7 15.9 13.4 15.6 12.2 15.2L13.4 14C14.2 14.2 15.1 14.4 16 14.4V16Z"
                    fill="#3E3E3E"
                  />
                </Svg>
              </View>
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: 500,
                  color: '#3E3E3E',
                  paddingLeft: 10,
                  paddingTop: 10,
                }}
              >
                {provider_dataExcel?.phone}
              </Text>
            </View>
            <View style={{ flexDirection: 'row' }}>
              <View
                style={{
                  height: '20px',
                  width: '20px',
                  marginTop: '10px',
                  marginLeft: '10px',
                }}
              >
                <Svg width="20" height="20" viewBox="0 0 30 30">
                  <Path
                    d="M7 4.5C7.66304 4.5 8.29893 4.76339 8.76777 5.23223C9.23661 5.70107 9.5 6.33696 9.5 7C9.5 7.3283 9.43534 7.65339 9.3097 7.95671C9.18406 8.26002 8.99991 8.53562 8.76777 8.76777C8.53562 8.99991 8.26002 9.18406 7.95671 9.3097C7.65339 9.43534 7.3283 9.5 7 9.5C6.33696 9.5 5.70107 9.23661 5.23223 8.76777C4.76339 8.29893 4.5 7.66304 4.5 7C4.5 6.33696 4.76339 5.70107 5.23223 5.23223C5.70107 4.76339 6.33696 4.5 7 4.5ZM7 0C8.85652 0 10.637 0.737498 11.9497 2.05025C13.2625 3.36301 14 5.14348 14 7C14 12.25 7 20 7 20C7 20 0 12.25 0 7C0 5.14348 0.737498 3.36301 2.05025 2.05025C3.36301 0.737498 5.14348 0 7 0ZM7 2C5.67392 2 4.40215 2.52678 3.46447 3.46447C2.52678 4.40215 2 5.67392 2 7C2 8 2 10 7 16.71C12 10 12 8 12 7C12 5.67392 11.4732 4.40215 10.5355 3.46447C9.59785 2.52678 8.32608 2 7 2Z"
                    fill="#3E3E3E"
                  />
                </Svg>
              </View>
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: 500,
                  color: '#3E3E3E',
                  paddingLeft: 10,
                  paddingTop: 12,
                }}
              >
                {provider_dataExcel?.address.split(', ,').join(',')}
              </Text>
            </View>
          </View>
        </View>
        <View wrap style={{ flexDirection: 'row', marginTop: '20px' }}>
          <Text
            style={{
              fontSize: '20px',
              color: alpha('#000000', 0.8),
              marginRight: 'auto',
              marginTop: '8px',
            }}
          >
            SUMMARY
          </Text>
          <View
            style={{
              borderBottom: '1.2px solid black',
              width: '80%',
              marginBottom: '10px',
              marginTop: 10,
            }}
          ></View>
        </View>
        <View style={{ flexDirection: 'column' }}>
          <View wrap style={{ flexDirection: 'row', marginTop: '25px' }}>
            <View style={{ flexDirection: 'column' }}>
              <View
                style={{
                  border: '1px solid #E8F5E9',
                  width: '230px',
                  height: '208.74px',
                  borderRadius: '4.8px',
                }}
              >
                <View style={{ flexDirection: 'row' }}>
                  <Text
                    style={{ fontSize: 7, color: '#22577A', padding: '8px' }}
                  >
                    General Information
                  </Text>
                </View>
                <View
                  style={{
                    border: '1px solid #E8F5E9',
                    width: '95%',
                    marginHorizontal: '5px',
                  }}
                ></View>
                <View style={{ flexDirection: 'row' }}>
                  <View style={{ flexDirection: 'column' }}>
                    <View style={{ height: '50px' }}>
                      <Text
                        style={{
                          fontSize: 6,
                          marginLeft: '10px',
                          marginTop: '15px',
                          color: '#3E3E3E',
                          fontWeight: 400,
                        }}
                      >
                        Parent Health System
                      </Text>
                      <Text
                        style={{
                          fontSize: 7,
                          marginLeft: '10px',
                          marginTop: '5px',
                          marginBottom: '5px',
                          width: '90px',
                        }}
                      >
                        {GeneralInfo_data[0].parent_health_system
                          ? GeneralInfo_data[0]?.parent_health_system
                          : '-'}
                      </Text>
                    </View>

                    <View style={{ height: '40px' }}>
                      <Text
                        style={{
                          fontSize: 6,
                          marginLeft: '10px',
                          marginTop: '5px',
                          color: '#3E3E3E',
                          fontWeight: 400,
                        }}
                      >
                        Regional Health System
                      </Text>
                      <Text
                        style={{
                          fontSize: 7,
                          marginLeft: '10px',
                          marginTop: '5px',
                          marginBottom: '5px',
                          width: '90px',
                        }}
                      >
                        {GeneralInfo_data[0].regional_health_system
                          ? GeneralInfo_data[0]?.regional_health_system
                          : '-'}
                      </Text>
                    </View>

                    <View style={{ height: '40px' }}>
                      <Text
                        style={{
                          fontSize: 6,
                          marginLeft: '10px',
                          marginTop: '5px',
                          color: '#3E3E3E',
                          fontWeight: 400,
                        }}
                      >
                        Ownership
                      </Text>
                      <Text
                        style={{
                          fontSize: 7,
                          marginLeft: '10px',
                          marginTop: '5px',
                          marginBottom: '5px',
                          width: '90px',
                        }}
                      >
                        {GeneralInfo_data[0].ownership
                          ? GeneralInfo_data[0]?.ownership
                          : '-'}
                      </Text>
                    </View>

                    <View style={{ height: '40px' }}>
                      <Text
                        style={{
                          fontSize: 6,
                          marginLeft: '10px',
                          marginTop: '5px',
                          color: '#3E3E3E',
                          fontWeight: 400,
                        }}
                      >
                        Number of Employees
                      </Text>
                      <Text
                        style={{
                          fontSize: 7,
                          marginLeft: '10px',
                          marginTop: '5px',
                          marginBottom: '5px',
                          width: '90px',
                        }}
                      >
                        {GeneralInfo_data[0].no_of_employees
                          ? parseInt(
                            GeneralInfo_data[0]?.no_of_employees
                          ).toLocaleString('en-US')
                          : '-'}
                      </Text>
                    </View>
                  </View>

                  <View style={{ flexDirection: 'column', marginLeft: '20px' }}>
                    <View style={{ height: '50px' }}>
                      <Text
                        style={{
                          fontSize: 6,
                          marginLeft: '10px',
                          marginTop: '14px',
                          color: '#3E3E3E',
                          fontWeight: 400,
                        }}
                      >
                        Type of Facility
                      </Text>
                      <Text
                        style={{
                          fontSize: 6,
                          marginLeft: '10px',
                          marginTop: '5px',
                          marginBottom: '5px',
                          width: '90px',
                        }}
                      >
                        {GeneralInfo_data[0].hospital_type}
                      </Text>
                    </View>
                    <View style={{ height: '40px' }}>
                      <Text
                        style={{
                          fontSize: 6,
                          marginLeft: '10px',
                          marginTop: '4px',
                          color: '#3E3E3E',
                          fontWeight: 400,
                        }}
                      >
                        Total Facility Square Footage
                      </Text>
                      <Text
                        style={{
                          fontSize: 7,
                          marginLeft: '10px',
                          marginTop: '5px',
                          marginBottom: '5px',
                          width: '90px',
                        }}
                      >
                        {GeneralInfo_data[0]?.total_facility_square_footage
                          ? parseInt(
                            GeneralInfo_data[0]?.total_facility_square_footage
                          ).toLocaleString('en-US')
                          : '-'}
                      </Text>
                    </View>
                    <View style={{ height: '40px' }}>
                      <Text
                        style={{
                          fontSize: 6,
                          marginLeft: '10px',
                          marginTop: '3px',
                          color: '#3E3E3E',
                          fontWeight: 400,
                        }}
                      >
                        Number of Interns and Residents
                      </Text>
                      <Text
                        style={{
                          fontSize: 7,
                          marginLeft: '10px',
                          marginTop: '5px',
                          marginBottom: '5px',
                          width: '90px',
                        }}
                      >
                        {GeneralInfo_data[0].no_of_interns_and_residents
                          ? parseInt(
                            GeneralInfo_data[0].no_of_interns_and_residents
                          ).toLocaleString('en-US')
                          : '-'}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <View style={{ flexDirection: 'column', marginLeft: '10px' }}>
              <View
                style={{
                  border: '1px solid #E8F5E9',
                  width: '230px',
                  height: '208.74px',
                  borderRadius: '4.8px',
                }}
              >
                <View style={{ flexDirection: 'row' }}>
                  <Text
                    style={{ fontSize: 7, color: '#22577A', padding: '8px' }}
                  >
                    Performance
                  </Text>
                </View>
                <View
                  style={{
                    border: '1px solid #E8F5E9',
                    width: '95%',
                    marginHorizontal: '5px',
                  }}
                ></View>
                <View style={{ flexDirection: 'column', padding: '5px' }}>
                  <View
                    style={{
                      padding: '2px',
                      border: `1px solid ${textColor(
                        Math.round(Performance_SummaryData.overall_rating)
                      )})`,
                      backgroundColor: backgroundColor(
                        Math.round(Performance_SummaryData?.overall_rating)
                      ),
                      width: '100%',
                      height: '26px',
                      borderRadius: '3.2px',
                      marginBottom: '5px',
                      alignItems: 'flex-end',
                    }}
                  >
                    <View style={{ flexDirection: 'row' }}>
                      <View style={{ flexDirection: 'column' }}>
                        <Text
                          style={{ fontSize: 6, width: '50%', padding: '3px' }}
                        >
                          CLIENT PERFORMANCE
                        </Text>
                        <Text
                          style={{
                            fontSize: 6,
                            width: '50%',
                            padding: '3px',
                            paddingTop: '1px',
                          }}
                        >
                          SCORE
                        </Text>
                      </View>
                      <View
                        style={{
                          flexDirection: 'row',
                          width: '35%',
                          height: '18px',
                          marginTop: '6px',
                          textAlign: 'right',
                          marginLeft: '46%',
                        }}
                      >
                        <RenderRating
                          rating={Math.round(
                            Performance_SummaryData.overall_rating
                          )}
                        />
                      </View>
                      <View>
                        <Text
                          style={{
                            marginLeft: '-15px',
                            fontSize: 6,
                            padding: '3px',
                            marginTop: '3.5px',
                            textAlign: 'right',
                            width: '50px',
                          }}
                        >
                          {performanceTextPDF(
                            Math.round(Performance_SummaryData?.overall_rating)
                          )}
                        </Text>
                      </View>
                    </View>
                  </View>

                  <View
                    style={{
                      padding: '2px',
                      border: '1px solid #F3F3F3',
                      width: '100%',
                      height: '26px',
                      borderRadius: '3.2px',
                      marginBottom: '5px',
                      alignItems: 'flex-end',
                    }}
                  >
                    <View style={{ flexDirection: 'row' }}>
                      <View style={{ flexDirection: 'column' }}>
                        <Text
                          style={{ fontSize: 6, width: '50%', padding: '3px' }}
                        >
                          Fiscal
                        </Text>
                        <Text
                          style={{
                            fontSize: 6,
                            width: '50%',
                            padding: '3px',
                            paddingTop: '1px',
                          }}
                        >
                          Stewardship
                        </Text>
                      </View>
                      <View
                        style={{
                          flexDirection: 'row',
                          width: '35%',
                          height: '18px',
                          marginTop: '6px',
                          textAlign: 'right',
                          marginLeft: '46%',
                        }}
                      >
                        <RenderRating
                          rating={Math.round(
                            Performance_SummaryData.fiscal_stewardship
                          )}
                        />
                      </View>
                      <View>
                        <Text
                          style={{
                            marginLeft: '-15px',
                            fontSize: 6,
                            padding: '3px',
                            marginTop: '3.5px',
                            textAlign: 'right',
                            width: '50px',
                          }}
                        >
                          {performanceTextPDF(
                            Math.round(
                              Performance_SummaryData?.fiscal_stewardship
                            )
                          )}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View
                    style={{
                      padding: '2px',
                      border: '1px solid #F3F3F3',
                      width: '100%',
                      height: '26px',
                      borderRadius: '3.2px',
                      marginBottom: '5px',
                      alignItems: 'flex-end',
                    }}
                  >
                    <View style={{ flexDirection: 'row' }}>
                      <View style={{ flexDirection: 'column' }}>
                        <Text
                          style={{ fontSize: 6, width: '50%', padding: '3px' }}
                        >
                          Patient
                        </Text>
                        <Text
                          style={{
                            fontSize: 6,
                            width: '50%',
                            padding: '3px',
                            paddingTop: '1px',
                          }}
                        >
                          Experience
                        </Text>
                      </View>
                      <View
                        style={{
                          flexDirection: 'row',
                          width: '35%',
                          height: '18px',
                          marginTop: '6px',
                          textAlign: 'right',
                          marginLeft: '46%',
                        }}
                      >
                        <RenderRating
                          rating={Math.round(
                            Performance_SummaryData.experiential_design
                          )}
                        />
                      </View>
                      <View>
                        <Text
                          style={{
                            marginLeft: '-15px',
                            fontSize: 6,
                            padding: '3px',
                            marginTop: '3.5px',
                            textAlign: 'right',
                            width: '50px',
                          }}
                        >
                          {performanceTextPDF(
                            Math.round(
                              Performance_SummaryData?.experiential_design
                            )
                          )}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View
                    style={{
                      padding: '2px',
                      border: '1px solid #F3F3F3',
                      width: '100%',
                      height: '26px',
                      borderRadius: '3.2px',
                      marginBottom: '5px',
                      alignItems: 'flex-end',
                    }}
                  >
                    <View style={{ flexDirection: 'row' }}>
                      <View style={{ flexDirection: 'column' }}>
                        <Text
                          style={{ fontSize: 6, width: '50%', padding: '3px' }}
                        >
                          Clnical
                        </Text>
                        <Text
                          style={{
                            fontSize: 6,
                            width: '50%',
                            padding: '3px',
                            paddingTop: '1px',
                          }}
                        >
                          Outcomes
                        </Text>
                      </View>
                      <View
                        style={{
                          flexDirection: 'row',
                          width: '35%',
                          height: '18px',
                          marginTop: '6px',
                          textAlign: 'right',
                          marginLeft: '46%',
                        }}
                      >
                        <RenderRating
                          rating={Math.round(
                            Performance_SummaryData.clinical_outcomes
                          )}
                        />
                      </View>
                      <View>
                        <Text
                          style={{
                            marginLeft: '-15px',
                            fontSize: 6,
                            padding: '3px',
                            marginTop: '3.5px',
                            textAlign: 'right',
                            width: '50px',
                          }}
                        >
                          {performanceTextPDF(
                            Math.round(
                              Performance_SummaryData?.clinical_outcomes
                            )
                          )}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View
                    style={{
                      padding: '2px',
                      border: '1px solid #F3F3F3',
                      width: '100%',
                      height: '26px',
                      borderRadius: '3.2px',
                      marginBottom: '5px',
                      alignItems: 'flex-end',
                    }}
                  >
                    <View style={{ flexDirection: 'row' }}>
                      <View style={{ flexDirection: 'column' }}>
                        <Text
                          style={{ fontSize: 6, width: '50%', padding: '3px' }}
                        >
                          Facility
                        </Text>
                        <Text
                          style={{
                            fontSize: 6,
                            width: '50%',
                            padding: '3px',
                            paddingTop: '1px',
                          }}
                        >
                          Conditions
                        </Text>
                      </View>
                      <View
                        style={{
                          flexDirection: 'row',
                          width: '35%',
                          height: '18px',
                          marginTop: '6px',
                          textAlign: 'right',
                          marginLeft: '46%',
                        }}
                      >
                        <RenderRating
                          rating={Math.round(
                            Performance_SummaryData.facility_conditions
                          )}
                        />
                      </View>
                      <View>
                        <Text
                          style={{
                            marginLeft: '-15px',
                            fontSize: 6,
                            padding: '3px',
                            marginTop: '3.5px',
                            textAlign: 'right',
                            width: '50px',
                          }}
                        >
                          {performanceTextPDF(
                            Math.round(
                              Performance_SummaryData?.facility_conditions
                            )
                          )}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View
                    style={{
                      padding: '2px',
                      border: '1px solid #F3F3F3',
                      width: '100%',
                      height: '26px',
                      borderRadius: '3.2px',
                      marginBottom: '5px',
                      alignItems: 'flex-end',
                    }}
                  >
                    <View style={{ flexDirection: 'row' }}>
                      <View style={{ flexDirection: 'column' }}>
                        <Text
                          style={{ fontSize: 6, width: '50%', padding: '3px' }}
                        >
                          Operational
                        </Text>
                        <Text
                          style={{
                            fontSize: 6,
                            width: '50%',
                            padding: '3px',
                            paddingTop: '1px',
                          }}
                        >
                          Efficiency
                        </Text>
                      </View>
                      <View
                        style={{
                          flexDirection: 'row',
                          width: '35%',
                          height: '18px',
                          marginTop: '6px',
                          textAlign: 'right',
                          marginLeft: '46%',
                        }}
                      >
                        <RenderRating
                          rating={Math.round(
                            Performance_SummaryData.clinical_operations
                          )}
                        />
                      </View>
                      <View>
                        <Text
                          style={{
                            marginLeft: '-15px',
                            fontSize: 6,
                            padding: '3px',
                            marginTop: '3.5px',
                            textAlign: 'right',
                            width: '50px',
                          }}
                        >
                          {performanceTextPDF(
                            Math.round(
                              Performance_SummaryData?.clinical_operations
                            )
                          )}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View
            style={{
              border: '1px solid #E8F5E9',
              // width: '200px',
              // height: '208.74px',
              borderRadius: '4.8px',
              marginTop: '10px',
            }}
          >
            <View style={{ flexDirection: 'row' }}>
              <Text style={{ fontSize: 7, color: '#22577A', padding: '8px' }}>
                Stakeholders
              </Text>
              {/* <Text style={{ fontSize: '4.48px', color: '#3E3E3E', padding: '8px', marginLeft: '80px' }}>{`View more>>`}</Text> */}
            </View>
            <View
              style={{
                border: '1px solid #E8F5E9',
                marginLeft: '7px',
                marginRight: '7px',
              }}
            ></View>
            {pairs.map((threedata) => (
              <View
                style={{ flexDirection: 'row', marginBottom: '5px' }}
                wrap={false}
              >
                {threedata.map((data) => (
                  <View
                    style={{
                      flexDirection: 'row',
                      marginLeft: '5px',
                      padding: '1px',
                    }}
                    wrap
                  >
                    <View
                      style={{
                        flexDirection: 'column',
                        paddingBottom: '2px',
                        paddingRight: '2px',
                        border: '1px solid #F3F3F3',
                        backgroundColor: '#FAFAFA',
                        width: '169.68px',
                        height: '60px',
                        borderRadius: '3.2px',
                        marginLeft: '6px',
                        marginTop: '6px',
                      }}
                    >
                      <View style={{ flexDirection: 'row' }}>
                        <Text
                          style={{
                            fontSize: 6,
                            padding: '3px',
                            marginLeft: '2px',
                            width: '175px',
                          }}
                        >
                          {data.executive_name ? data.executive_name : '-'}
                        </Text>
                        <Text
                          style={{
                            fontSize: 6,
                            padding: '3px',
                            marginLeft: '40px',
                            width: '200px',
                            color: '#22577A',
                            textDecoration: 'underline',
                            textDecorationColor: '#22577A',
                            textAlign: 'right',
                          }}
                        >
                          {data.email ? data.email.split('') : '-'}
                        </Text>
                      </View>
                      <View>
                        <Text
                          style={{
                            fontSize: 6,
                            color: alpha('#3E3E3E', 0.6),
                            marginLeft: '5px',
                            marginTop:
                              data?.linkedin_profile != null ? '10px' : '6px',
                            maxHeight: '10px',
                            marginBottom: '10px',
                          }}
                        >
                          {data.department_level ? data.department_level : '-'}
                        </Text>
                      </View>
                      <View
                        style={{
                          width: '158.16px',
                          border: '0.32px solid #E8F5E9',
                          marginLeft: '5px',
                          marginTop: '5px',
                          marginBottom: '5px',
                          top: data?.linkedin_profile != null ? '-2px' : '-7px',
                        }}
                      ></View>
                      <View
                        style={{
                          flexDirection: 'row',
                          marginTop: '3px',
                          marginLeft: '5px',
                        }}
                      >
                        <Text
                          style={{
                            bottom: data?.linkedin_profile == null ? 5 : -3,
                            fontSize: 5,
                            color: alpha('#3E3E3E', 0.6),
                          }}
                        >
                          Office Contact
                        </Text>
                        <Text
                          style={{
                            bottom: data?.linkedin_profile == null ? 5 : -3,
                            fontSize: 5,
                            color: alpha('#3E3E3E', 0.6),
                            marginLeft: '45px',
                          }}
                        >
                          Personal Contact
                        </Text>
                      </View>
                      <View
                        style={{
                          flexDirection: 'row',
                          marginTop: '3px',
                          marginLeft: '5px',
                          height: 'auto',
                        }}
                      >
                        <Text
                          style={{
                            bottom: data?.linkedin_profile == null ? 5 : -3,
                            fontSize: '5px',
                            fontWeight: 700,
                            width: '30px',
                          }}
                        >
                          {data.office_phone ? data.office_phone : '-'}
                        </Text>
                        <Text
                          style={{
                            bottom: data?.linkedin_profile == null ? 5 : -3,
                            fontSize: '5px',
                            fontWeight: 700,
                            width: '85px',
                            textAlign: 'right',
                          }}
                        >
                          {data.personal_phone
                            ? data.personal_phone
                            : '           -         '}
                        </Text>
                        <Text style={{ bottom: 8, left: 30 }}>
                          {data?.linkedin_profile != null ? (
                            <a
                              href={data?.linkedin_profile}
                              rel="nooopener noreferrer"
                            >
                              <Image
                                src={linkedInlogo}
                                style={{ height: '12.81px', width: '12.81px' }}
                              ></Image>
                            </a>
                          ) : (
                            <View
                              style={{ height: '10.81px', width: '12.81px' }}
                            ></View>
                          )}
                        </Text>
                      </View>
                    </View>
                  </View>
                ))}
              </View>
            ))}
          </View>
        </View>
        <View wrap fixed style={pdfStyle.footer}>
          <View
            style={{
              border: '1px solid #D9D9D9',
              marginTop: '10px',
              width: '520px',
              height: '1px',
              left: '25px',
            }}
          ></View>
          <View
            style={{ flexDirection: 'row', fontSize: 8, marginTop: '10px' }}
          >
            <Text style={{ marginLeft: '30px', fontSize: 10 }}>
              Export Date : {formatedDateTime}
            </Text>
            <Text
              style={{
                fontWeight: 800,
                marginLeft: '70px',
                fontSize: 10,
              }}
            >
              Confidential: Do Not Distribute Outside of DPR
            </Text>
            <Text
              style={{ marginLeft: '110px', fontSize: 10 }}
              render={({ pageNumber }) => `Page ${pageNumber}`}
            />
          </View>
        </View>
      </Page>

      {/* General Info */}
      <Page size="A4" style={pdfStyle.body}>
        <View wrap fixed>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              paddingBottom: '10px',
              paddingRight: '20px',
            }}
          >
            <Image
              src={dprlogo}
              style={{
                width: '74px',
                height: '45px',
                padding: '5px',
              }}
            ></Image>

            <View
              style={{
                borderRight: '1px solid #CCCCCC',
                height: '30px',
                marginTop: '8px',
                marginLeft: '10px',
              }}
            ></View>

            <Image
              src={panaromalogo}
              style={{
                width: '100px',
                height: '30px',
                marginLeft: '20px',
                marginTop: '8px',
                padding: 5,
              }}
            ></Image>

            <Text
              style={{
                fontSize: '20px',
                color: '#000000',
                marginLeft: 'auto',
                marginTop: '15px',
              }}
            >
              CLIENT INSIGHTS
            </Text>
          </View>

          <View
            style={{
              borderBottom: '2px solid #4CAF50',
              width: '568px',
              marginBottom: '20px',
              marginTop: 10,
            }}
          ></View>
        </View>
        <View wrap style={{ flexDirection: 'row' }}>
          <Text
            style={{
              fontSize: '20px',
              color: '#000000',
              marginRight: 'auto',
              marginTop: '8px',
            }}
          >
            GENERAL INFO
          </Text>
          <View
            style={{
              borderBottom: '1px solid black',
              width: '70%',
              marginBottom: '10px',
              marginTop: 10,
            }}
          ></View>
        </View>
        <Image
          src={GeneralInfoMap}
          style={{ width: '568px', height: '276px', marginTop: '20px' }}
        ></Image>
        <View style={{ flexDirection: 'row' }}>
          <View
            style={{
              border: '1px solid #E8F5E9',
              backgroundColor: '#FFFFFF',
              width: '267.99px',
              height: '300px',
              borderRadius: '8.34px',
              marginTop: '30px',
            }}
          >
            <Text
              style={{
                marginLeft: '15px',
                marginTop: '15px',
                fontSize: 11,
                color: '#22577A',
              }}
            >
              General Information
            </Text>
            <View
              style={{
                border: '1px solid #E8F5E9',
                width: '95%',
                marginHorizontal: '5px',
                marginTop: '20px',
              }}
            ></View>
            <View
              wrap
              style={{
                flexDirection: 'row',
                marginLeft: '12px',
                marginTop: '10px',
              }}
            >
              <View style={{ flexDirection: 'column' }}>
                <View style={{ height: '50px' }}>
                  <Text
                    style={{
                      fontSize: 8,
                      padding: '5px',
                      paddingBottom: '0px',
                      color: '#3E3E3E',
                    }}
                  >
                    Parent Health System
                  </Text>
                  <Text style={{ fontSize: 8, padding: '5px', width: '90px' }}>
                    {GeneralInfo_data[0].parent_health_system
                      ? GeneralInfo_data[0].parent_health_system
                      : '-'}
                  </Text>
                </View>
                <View style={{ height: '50px', marginTop: '15px' }}>
                  <Text
                    style={{
                      fontSize: 8,
                      padding: '5px',
                      paddingBottom: '0px',
                      color: '#3E3E3E',
                    }}
                  >
                    Regional Health System
                  </Text>
                  <Text style={{ fontSize: 8, padding: '5px', width: '90px' }}>
                    {GeneralInfo_data[0].regional_health_system
                      ? GeneralInfo_data[0].regional_health_system
                      : '-'}
                  </Text>
                </View>
                <View style={{ height: '50px', marginTop: '15px' }}>
                  <Text
                    style={{
                      fontSize: 8,
                      padding: '5px',
                      paddingBottom: '0px',
                      color: '#3E3E3E',
                    }}
                  >
                    Ownership
                  </Text>
                  <Text style={{ fontSize: 8, padding: '5px', width: '90px' }}>
                    {GeneralInfo_data[0].ownership
                      ? GeneralInfo_data[0].ownership
                      : '-'}
                  </Text>
                </View>
                <View style={{ height: '50px', marginTop: '15px' }}>
                  <Text
                    style={{
                      fontSize: 8,
                      padding: '5px',
                      paddingBottom: '0px',
                      color: '#3E3E3E',
                    }}
                  >
                    No of Employees
                  </Text>
                  <Text style={{ fontSize: 8, padding: '5px', width: '90px' }}>
                    {GeneralInfo_data[0].no_of_employees
                      ? parseInt(
                        GeneralInfo_data[0].no_of_employees
                      ).toLocaleString('en-US')
                      : '-'}
                  </Text>
                </View>
              </View>
              <View style={{ flexDirection: 'column', marginLeft: '25px' }}>
                <View style={{ height: '50px' }}>
                  <Text
                    style={{
                      fontSize: 8,
                      padding: '5px',
                      paddingBottom: '0px',
                      color: '#3E3E3E',
                    }}
                  >
                    Type of Facility
                  </Text>
                  <Text style={{ fontSize: 8, padding: '5px', width: '90px' }}>
                    {GeneralInfo_data[0].hospital_type}
                  </Text>
                </View>
                <View style={{ height: '50px', marginTop: '14px' }}>
                  <Text
                    style={{
                      fontSize: 8,
                      padding: '5px',
                      paddingBottom: '0px',
                      color: '#3E3E3E',
                    }}
                  >
                    Total Facility Square Footage
                  </Text>
                  <Text style={{ fontSize: 8, padding: '5px', width: '90px' }}>
                    {GeneralInfo_data[0].total_facility_square_footage
                      ? parseInt(
                        GeneralInfo_data[0].total_facility_square_footage
                      ).toLocaleString('en-US')
                      : '-'}
                  </Text>
                </View>
                <View style={{ height: '50px', marginTop: '13px' }}>
                  <Text
                    style={{
                      fontSize: 8,
                      padding: '5px',
                      paddingBottom: '0px',
                      color: '#3E3E3E',
                    }}
                  >
                    No of interns and Residents
                  </Text>
                  <Text style={{ fontSize: 8, padding: '5px', width: '90px' }}>
                    {GeneralInfo_data[0].no_of_interns_and_residents
                      ? parseInt(
                        GeneralInfo_data[0].no_of_interns_and_residents
                      ).toLocaleString('en-US')
                      : '-'}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View
            style={{
              border: '1px solid #E8F5E9',
              backgroundColor: '#FFFFFF',
              width: '267.99px',
              height: '300px',
              borderRadius: '8.34px',
              marginTop: '30px',
              marginLeft: '30px',
            }}
          >
            <Text
              style={{
                marginLeft: '15px',
                marginTop: '15px',
                fontSize: 12,
                color: '#22577A',
              }}
            >
              Key Clinical-Metrics
            </Text>
            <View
              style={{
                border: '1px solid #E8F5E9',
                width: '95%',
                marginHorizontal: '2px',
                marginTop: '20px',
              }}
            ></View>
            <View
              wrap
              style={{
                flexDirection: 'row',
                marginLeft: '12px',
                marginTop: '10px',
              }}
            >
              <View style={{ flexDirection: 'column' }}>
                <View>
                  <Text
                    style={{
                      fontSize: 8,
                      padding: '5px',
                      paddingBottom: '0px',
                      color: '#3E3E3E',
                    }}
                  >
                    No. of staffed beds
                  </Text>
                  <Text style={{ fontSize: 8, padding: '5px' }}>
                    {GeneralInfo_data[0].number_of_beds
                      ? parseInt(
                        GeneralInfo_data[0].number_of_beds
                      ).toLocaleString('en-US')
                      : ' - '}
                  </Text>
                </View>
                <View>
                  <Text
                    style={{
                      fontSize: 8,
                      padding: '5px',
                      paddingBottom: '0px',
                      color: '#3E3E3E',
                    }}
                  >
                    No. of ICU beds
                  </Text>
                  <Text style={{ fontSize: 8, padding: '5px' }}>
                    {GeneralInfo_data[0].intensive_care_unit_beds
                      ? parseInt(
                        GeneralInfo_data[0].intensive_care_unit_beds
                      ).toLocaleString('en-US')
                      : ' - '}
                  </Text>
                </View>
                <View>
                  <Text
                    style={{
                      fontSize: 8,
                      padding: '5px',
                      paddingBottom: '0px',
                      color: '#3E3E3E',
                    }}
                  >
                    No. of discharges
                  </Text>
                  <Text style={{ fontSize: 8, padding: '5px' }}>
                    {GeneralInfo_data[0].no_of_discharges
                      ? parseInt(
                        GeneralInfo_data[0].no_of_discharges
                      ).toLocaleString('en-US')
                      : ' - '}
                  </Text>
                </View>
                <View>
                  <Text
                    style={{
                      fontSize: 8,
                      padding: '5px',
                      paddingBottom: '0px',
                      color: '#3E3E3E',
                    }}
                  >
                    Est. no of outpatient surgeries
                  </Text>
                  <Text style={{ fontSize: 8, padding: '5px' }}>
                    {GeneralInfo_data[0].est_no_of_outpatient_surgeries
                      ? parseInt(
                        GeneralInfo_data[0].est_no_of_outpatient_surgeries
                      ).toLocaleString('en-US')
                      : ' - '}
                  </Text>
                </View>
                <View>
                  <Text
                    style={{
                      fontSize: 8,
                      padding: '5px',
                      paddingBottom: '0px',
                      color: '#3E3E3E',
                    }}
                  >
                    Est. no of outpatient visits
                  </Text>
                  <Text style={{ fontSize: 8, padding: '5px' }}>
                    {GeneralInfo_data[0].est_no_of_outpatient_visits
                      ? parseInt(
                        GeneralInfo_data[0].est_no_of_outpatient_visits
                      ).toLocaleString('en-US')
                      : ' - '}
                  </Text>
                </View>
                <View>
                  <Text
                    style={{
                      fontSize: 8,
                      padding: '5px',
                      paddingBottom: '0px',
                      color: '#3E3E3E',
                    }}
                  >
                    No. of births
                  </Text>
                  <Text style={{ fontSize: 8, padding: '5px' }}>
                    {GeneralInfo_data[0].no_of_births
                      ? parseInt(
                        GeneralInfo_data[0].no_of_births
                      ).toLocaleString('en-US')
                      : ' - '}
                  </Text>
                </View>
              </View>
              <View style={{ flexDirection: 'column', marginLeft: '25px' }}>
                <View>
                  <Text
                    style={{
                      fontSize: 8,
                      padding: '5px',
                      paddingBottom: '0px',
                      color: '#3E3E3E',
                    }}
                  >
                    No. of routine beds
                  </Text>
                  <Text style={{ fontSize: 8, padding: '5px' }}>
                    {GeneralInfo_data[0].routine_service_beds
                      ? parseInt(
                        GeneralInfo_data[0].routine_service_beds
                      ).toLocaleString('en-US')
                      : ' - '}
                  </Text>
                </View>
                <View>
                  <Text
                    style={{
                      fontSize: 8,
                      padding: '5px',
                      paddingBottom: '0px',
                      color: '#3E3E3E',
                    }}
                  >
                    No. of NICU beds
                  </Text>
                  <Text style={{ fontSize: 8, padding: '5px' }}>
                    {GeneralInfo_data[0].nicu_beds
                      ? parseInt(GeneralInfo_data[0].nicu_beds).toLocaleString(
                        'en-US'
                      )
                      : ' - '}
                  </Text>
                </View>
                <View>
                  <Text
                    style={{
                      fontSize: 8,
                      padding: '5px',
                      paddingBottom: '0px',
                      color: '#3E3E3E',
                    }}
                  >
                    Est. no of inpatient surgeries
                  </Text>
                  <Text style={{ fontSize: 8, padding: '5px' }}>
                    {GeneralInfo_data[0].est_no_of_inpatient_surgeries
                      ? parseInt(
                        GeneralInfo_data[0].est_no_of_inpatient_surgeries
                      ).toLocaleString('en-US')
                      : ' - '}
                  </Text>
                </View>
                <View>
                  <Text
                    style={{
                      fontSize: 8,
                      padding: '5px',
                      paddingBottom: '0px',
                      color: '#3E3E3E',
                    }}
                  >
                    Est. no total surgeries
                  </Text>
                  <Text style={{ fontSize: 8, padding: '5px' }}>
                    {GeneralInfo_data[0].est_no_of_total_surgeries
                      ? parseInt(
                        GeneralInfo_data[0].est_no_of_total_surgeries
                      ).toLocaleString('en-US')
                      : ' - '}
                  </Text>
                </View>
                <View>
                  <Text
                    style={{
                      fontSize: 8,
                      padding: '5px',
                      paddingBottom: '0px',
                      color: '#3E3E3E',
                    }}
                  >
                    Est. no of ER visits
                  </Text>
                  <Text style={{ fontSize: 8, padding: '5px' }}>
                    {GeneralInfo_data[0].est_no_of_er_visits
                      ? parseInt(
                        GeneralInfo_data[0].est_no_of_er_visits
                      ).toLocaleString('en-US')
                      : ' - '}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View wrap fixed style={pdfStyle.footer}>
          <View
            style={{
              border: '1px solid #D9D9D9',
              marginTop: '10px',
              width: '520px',
              height: '1px',
              left: '25px',
            }}
          ></View>
          <View
            style={{ flexDirection: 'row', fontSize: 8, marginTop: '10px' }}
          >
            <Text style={{ marginLeft: '30px', fontSize: 10 }}>
              Export Date : {formatedDateTime}
            </Text>
            <Text
              style={{
                fontWeight: 800,
                marginLeft: '70px',
                fontSize: 10,
              }}
            >
              Confidential: Do Not Distribute Outside of DPR
            </Text>
            <Text
              style={{ marginLeft: '110px', fontSize: 10 }}
              render={({ pageNumber }) => `Page ${pageNumber}`}
            />
          </View>
        </View>
      </Page>

      {/* Performace */}
      <Page size="A4" style={pdfStyle.body}>
        <View fixed>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              paddingBottom: '10px',
              paddingRight: '20px',
            }}
          >
            <Image
              src={dprlogo}
              style={{
                width: '74px',
                height: '45px',
                padding: '5px',
              }}
            ></Image>

            <View
              style={{
                borderRight: '1px solid #CCCCCC',
                height: '30px',
                marginTop: '8px',
                marginLeft: '10px',
              }}
            ></View>

            <Image
              src={panaromalogo}
              style={{
                width: '100px',
                height: '30px',
                marginLeft: '20px',
                marginTop: '8px',
                padding: 5,
              }}
            ></Image>

            <Text
              style={{
                fontSize: '20px',
                color: '#000000',
                marginLeft: 'auto',
                marginTop: '15px',
              }}
            >
              CLIENT INSIGHTS
            </Text>
          </View>

          <View
            style={{
              borderBottom: '2px solid #4CAF50',
              width: '100%',
              marginBottom: '20px',
              marginTop: 10,
            }}
          ></View>
        </View>
        <View wrap style={{ flexDirection: 'row' }}>
          <Text
            style={{
              fontSize: '20px',
              color: '#000000',
              marginRight: 'auto',
              marginTop: '8px',
            }}
          >
            PERFORMANCE
          </Text>
          <View
            style={{
              borderBottom: '1px solid black',
              width: '70%',
              marginBottom: '10px',
              marginTop: 10,
            }}
          ></View>
        </View>

        <View
          style={{
            position: 'relative',
            width: '568px',
            height: '27.33px',
            backgroundColor: backgroundColor(
              Math.round(ratings?.overall_rating)
            ),
            border: `1px solid ${textColor(
              Math.round(ratings?.overall_rating)
            )}`,
            borderRadius: '4.63295px',
            marginTop: '15px',
            marginBottom: '-50px',
            flexDirection: 'row',
            alignItems: 'stretch',
            alignContent: 'space-around',
            padding: '0.5px',
          }}
        >
          <Text
            style={{
              fontSize: 10,
              fontWeight: 900,
              marginTop: '7px',
              marginLeft: '10px',
            }}
          >
            CLIENT PERFORMANCE SCORE
          </Text>
          <View style={{ width: '220px' }}></View>
          {/* <Image style={{ marginTop: '4px', height: '15px', width: '80px', marginLeft: '50px' }} src={overallPerformanceImageSrc}></Image> */}
          <View
            style={{
              marginTop: '5px',
              height: '30px',
              width: '120px',
              marginLeft: '50px',
            }}
          >
            <RenderRating
              rating={Math.round(Performance_SummaryData.overall_rating)}
              viewbox="0 0 35 35"
            />
          </View>
          <Text
            style={{
              fontSize: 10,
              marginTop: '6.25px',
              fontWeight: 800,
              marginLeft: 'auto',
              paddingRight: '10px',
            }}
          >
            {performanceText(Math.round(ratings?.overall_rating))
              ?.replace('(', '')
              .replace(')', '')}
          </Text>
        </View>
        <View wrap style={{ flexDirection: 'row' }}>
          <View
            style={{
              position: 'absolute',
              width: '100px',
              height: '44px',
              top: '70px',
              backgroundColor: '#FFFFFF',
              border: '2px solid #E8F5E9',
              borderRadius: '5.52171px',
              flexDirection: 'column',
            }}
          >
            <Text style={{ fontSize: 8, padding: '5px', color: '#22577A' }}>
              Fiscal Stewardship
            </Text>
            <View
              style={{ flexDirection: 'row', marginTop: '10px', width: '80px' }}
            >
              <Text
                style={{ fontSize: 8, marginLeft: '5px', marginTop: '5px' }}
              >
                {ratings['fiscal_stewardship']
                  ? `(${Math.round(ratings['fiscal_stewardship'])}/5)`
                  : ''}
              </Text>
              <View style={{ marginLeft: '-25px', marginTop: '5px' }}>
                {ratings['fiscal_stewardship'] ? (
                  <Svg viewBox="0 0  35 35">
                    <Path
                      fill={textColor(
                        Math.round(ratings['fiscal_stewardship'])
                      )}
                      stroke={textColor(
                        Math.round(ratings['fiscal_stewardship'])
                      )}
                      d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
                    />
                  </Svg>
                ) : (
                  <Svg viewBox="0 0 30 30" />
                )}
              </View>
              <Text
                style={{
                  fontSize: 8,
                  marginLeft: '-65px',
                  marginTop: '5px',
                  width: '90px',
                  textAlign: 'right',
                }}
              >
                {performanceText(
                  Math.round(ratings['fiscal_stewardship']),
                  'capitalize'
                )
                  ?.replace('(', '')
                  .replace(')', '')}
              </Text>
            </View>
          </View>
          <View
            style={{
              position: 'absolute',
              width: '100px',
              height: '44px',
              top: '70px',
              marginLeft: '115px',
              backgroundColor: '#FFFFFF',
              border: '2px solid #E8F5E9',
              borderRadius: '5.52171px',
            }}
          >
            <Text style={{ fontSize: 8, padding: '5px', color: '#22577A' }}>
              Operations
            </Text>
            <View
              style={{ flexDirection: 'row', marginTop: '10px', width: '80px' }}
            >
              <Text
                style={{ fontSize: 8, marginLeft: '5px', marginTop: '5px' }}
              >
                {ratings['clinical_operations']
                  ? `(${Math.round(ratings['clinical_operations'])}/5)`
                  : ''}
              </Text>
              <View style={{ marginLeft: '-25px', marginTop: '5px' }}>
                {ratings['clinical_operations'] ? (
                  <Svg viewBox="0 0  35 35">
                    <Path
                      fill={textColor(
                        Math.round(ratings['clinical_operations'])
                      )}
                      stroke={textColor(
                        Math.round(ratings['clinical_operations'])
                      )}
                      d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
                    />
                  </Svg>
                ) : (
                  <Svg viewBox="0 0 30 30" />
                )}
              </View>
              <Text
                style={{
                  fontSize: 8,
                  marginLeft: '-65px',
                  marginTop: '5px',
                  width: '90px',
                  textAlign: 'right',
                }}
              >
                {performanceText(
                  Math.round(ratings['clinical_operations']),
                  'capitalize'
                )
                  ?.replace('(', '')
                  .replace(')', '')}
              </Text>
            </View>
          </View>
          <View
            wrap={false}
            style={{
              position: 'absolute',
              width: '100px',
              height: '44px',
              marginLeft: '230px',
              top: '70px',
              backgroundColor: '#FFFFFF',
              border: '2px solid #E8F5E9',
              borderRadius: '5.52171px',
            }}
          >
            <Text style={{ fontSize: 8, padding: '5px', color: '#22577A' }}>
              Patient Experience
            </Text>
            <View
              style={{ flexDirection: 'row', marginTop: '10px', width: '80px' }}
            >
              <Text
                style={{ fontSize: 8, marginLeft: '5px', marginTop: '5px' }}
              >
                {ratings['experiential_design']
                  ? `(${Math.round(ratings['experiential_design'])}/5)`
                  : ''}
              </Text>
              <View style={{ marginLeft: '-25px', marginTop: '5px' }}>
                {ratings['experiential_design'] ? (
                  <Svg viewBox="0 0  35 35">
                    <Path
                      fill={textColor(
                        Math.round(ratings['experiential_design'])
                      )}
                      stroke={textColor(
                        Math.round(ratings['experiential_design'])
                      )}
                      d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
                    />
                  </Svg>
                ) : (
                  <Svg viewBox="0 0 30 30" />
                )}
              </View>
              <Text
                style={{
                  fontSize: 8,
                  marginLeft: '-65px',
                  marginTop: '5px',
                  width: '90px',
                  textAlign: 'right',
                }}
              >
                {performanceText(
                  Math.round(ratings['experiential_design']),
                  'capitalize'
                )
                  ?.replace('(', '')
                  .replace(')', '')}
              </Text>
            </View>
          </View>
          <View
            wrap={false}
            style={{
              position: 'absolute',
              width: '100px',
              height: '44px',
              marginLeft: '345px',
              top: '70px',
              backgroundColor: '#FFFFFF',
              border: '2px solid #E8F5E9',
              borderRadius: '5.52171px',
            }}
          >
            <Text style={{ fontSize: 8, padding: '5px', color: '#22577A' }}>
              Clinical Outcomes
            </Text>
            <View
              style={{ flexDirection: 'row', marginTop: '10px', width: '80px' }}
            >
              <Text
                style={{ fontSize: 8, marginLeft: '5px', marginTop: '5px' }}
              >
                {ratings['clinical_outcomes']
                  ? `(${Math.round(ratings['clinical_outcomes'])}/5)`
                  : ''}
              </Text>
              <View style={{ marginLeft: '-25px', marginTop: '5px' }}>
                {ratings['clinical_outcomes'] ? (
                  <Svg viewBox="0 0  35 35">
                    <Path
                      fill={textColor(Math.round(ratings['clinical_outcomes']))}
                      stroke={textColor(
                        Math.round(ratings['clinical_outcomes'])
                      )}
                      d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
                    />
                  </Svg>
                ) : (
                  <Svg viewBox="0 0 30 30" />
                )}
              </View>
              <Text
                style={{
                  fontSize: 8,
                  marginLeft: '-65px',
                  marginTop: '5px',
                  width: '90px',
                  textAlign: 'right',
                }}
              >
                {performanceText(
                  Math.round(ratings['clinical_outcomes']),
                  'capitalize'
                )
                  ?.replace('(', '')
                  .replace(')', '')}
              </Text>
            </View>
          </View>
          <View
            style={{
              position: 'absolute',
              width: '100px',
              height: '44px',
              marginLeft: '460px',
              top: '70px',
              backgroundColor: '#FFFFFF',
              border: '2px solid #E8F5E9',
              borderRadius: '5.52171px',
            }}
          >
            <Text style={{ fontSize: 8, padding: '5px', color: '#22577A' }}>
              Facility Conditions
            </Text>
            <View
              style={{ flexDirection: 'row', marginTop: '10px', width: '80px' }}
            >
              <Text
                style={{ fontSize: 8, marginLeft: '5px', marginTop: '5px' }}
              >
                {ratings['facility_conditions']
                  ? `(${Math.round(ratings['facility_conditions'])}/5)`
                  : ''}
              </Text>
              <View style={{ marginLeft: '-25px', marginTop: '5px' }}>
                {ratings['facility_conditions'] ? (
                  <Svg viewBox="0 0  35 35">
                    <Path
                      fill={textColor(
                        Math.round(ratings['facility_conditions'])
                      )}
                      stroke={textColor(
                        Math.round(ratings['facility_conditions'])
                      )}
                      d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
                    />
                  </Svg>
                ) : (
                  <Svg viewBox="0 0 30 30" />
                )}
              </View>
              <Text
                style={{
                  fontSize: 8,
                  marginLeft: '-65px',
                  marginTop: '5px',
                  width: '90px',
                  textAlign: 'right',
                }}
              >
                {performanceText(
                  Math.round(ratings['facility_conditions']),
                  'capitalize'
                )
                  ?.replace('(', '')
                  .replace(')', '')}
              </Text>
            </View>
          </View>
        </View>
        <View
          wrap
          style={{
            flexDirection: 'row',
            justifyContent:
              OverallPositiveArray.length > OverallNegativeArray.length
                ? 'flex-start'
                : 'flex-end',
          }}
        >
          <View wrap style={{ flexDirection: 'column' }}>
            <Text
              style={{
                fontSize: 12,
                marginTop: '130px',
                color: '#22577A',
                marginLeft: '10px',
                fontWeight: 800,
                marginBottom: '10px',
              }}
            >
              Positive Factors
            </Text>
            <View
              wrap={false}
              style={{
                position: 'relative',
                width: '263.66px',
                padding: '5px',
                marginLeft: '20px',
              }}
            ></View>
            <View style={{ flexDirection: 'column' }}>
              {OverallPositiveArray
                ? OverallPositiveArray.map((data) => {
                  return data[1] != 'N/A' ? (
                    <View
                      wrap={false}
                      style={{
                        position: 'relative',
                        width: '271.5px',
                        padding: '5px',
                        marginLeft: '0px',
                        marginBottom: '10px',
                        backgroundColor: '#FAFAFA',
                        border: '1px solid #E8F5E9',
                        borderRadius: '8.3px',
                      }}
                    >
                      <Text style={{ fontSize: 7 }}>{data[1]}</Text>
                    </View>
                  ) : null
                })
                : 'N/A'}
            </View>
          </View>
          <View wrap style={{ flexDirection: 'column' }}>
            <Text
              style={{
                fontSize: 12,
                marginTop: '130px',
                color: '#22577A',
                marginLeft: '25px',
                fontWeight: 800,
              }}
            >
              Risk Factors
            </Text>
            <View
              wrap={false}
              style={{
                position: 'relative',
                width: '263.66px',
                padding: '5px',
                marginLeft: '20px',
              }}
            ></View>
            <View>
              {OverallNegativeArray
                ? OverallNegativeArray.map((data) => {
                  return data[1] != 'N/A' ? (
                    <View
                      wrap={false}
                      style={{
                        width: '271.5px',
                        padding: '5px',
                        marginLeft: '15px',
                        marginTop: '10px',
                        backgroundColor: '#FAFAFA',
                        border: '1px solid #E8F5E9',
                        borderRadius: '8.3px',
                      }}
                    >
                      <Text style={{ fontSize: 7 }}>{data[1]}</Text>
                    </View>
                  ) : null
                })
                : 'N/A'}
            </View>
          </View>
        </View>
        <View
          style={{
            border: '2px solid #D9D9D9',
            marginTop: '15px',
            marginBottom: '15px',
            backgroundColor: '#D9D9D9',
          }}
        ></View>
        <View
          wrap={false}
          style={{
            position: 'relative',
            width: '100%',
            marginLeft: '0px',
            backgroundColor: '#FFFFFF',
            border: '1px solid #E8F5E9',
            borderRadius: '8.3px',
            padding: '5px',
          }}
        >
          <View style={{ flexDirection: 'row' }}>
            <View>
              <Text
                style={{
                  fontSize: 12,
                  marginTop: '17px',
                  color: '#22577A',
                  marginLeft: '8px',
                  fontWeight: 800,
                }}
              >
                Fiscal Stewardship
              </Text>
            </View>
            {ratings['fiscal_stewardship'] ? (
              <View
                style={{
                  border: `1px solid ${textColor(
                    Math.round(ratings['fiscal_stewardship'])
                  )}`,
                  backgroundColor: backgroundColor(
                    Math.round(ratings['fiscal_stewardship'])
                  ),
                  height: '25px',
                  flexDirection: 'row',
                  borderRadius: '3.08px',
                  top: 11,
                  left: 5,
                  paddingHorizontal: '5px',
                  paddingTop: '2px',
                }}
              >
                <View
                  style={{
                    marginTop: '5.25px',
                    height: '22px',
                    width: '80px',
                    marginBottom: '10px',
                  }}
                >
                  <RenderRating
                    rating={Math.round(ratings['fiscal_stewardship'])}
                    viewbox="0 0 30 30"
                  />
                </View>
                <Text
                  style={{
                    fontSize: 7,
                    marginTop: '6.25px',
                    marginLeft: '5px',
                  }}
                >
                  {performanceText(Math.round(ratings['fiscal_stewardship']))
                    ?.replace('(', '')
                    .replace(')', '')}
                </Text>
              </View>
            ) : (
              <View></View>
            )}
          </View>
          <View
            wrap
            style={{
              flexDirection: 'row',
              marginTop: '15px',
              marginBottom: '50px',
            }}
          >
            <View
              style={{
                position: 'absolute',
                width: '20%',
                height: '44px',
                backgroundColor: '#FFFFFF',
                flexDirection: 'column',
              }}
            >
              <Text style={{ fontSize: 8, padding: '5px', marginLeft: '16px' }}>
                Net Profit Margin
              </Text>
              <Text
                style={{
                  fontSize: 8,
                  padding: '1px',
                  marginLeft: '19px',
                  color: textColor(
                    Math.round(fiscal_rating_data?.net_operating_profit_margin)
                  ),
                  width: '80px',
                }}
              >
                {fiscal_score_data?.net_operating_profit_margin || fiscal_score_data?.net_operating_profit_margin === 0 ? (fiscal_score_data.net_operating_profit_margin * 100).toFixed(1) + "%" : "N/A"}
                {fiscal_rating_data?.net_operating_profit_margin
                  ? performanceText(
                    Math.round(
                      fiscal_rating_data?.net_operating_profit_margin
                    )
                  )
                  : ''}
              </Text>
            </View>
            <View
              style={{
                position: 'absolute',
                width: '20%',
                height: '44px',
                marginLeft: '115px',
                backgroundColor: '#FFFFFF',
              }}
            >
              <Text style={{ fontSize: 8, padding: '5px' }}>
                Capital Expenditures
              </Text>
              <Text
                style={{
                  fontSize: 8,
                  padding: '1px',
                  marginLeft: '5px',
                  color: textColor(
                    Math.round(fiscal_rating_data?.capital_expenditures)
                  ),
                  width: '80px',
                }}
              >
                {fiscal_score_data?.capital_expenditures ||
                  fiscal_score_data?.capital_expenditures === 0
                  ? fiscal_score_data.capital_expenditures.toLocaleString(
                    'en-US',
                    {
                      style: 'currency',
                      currency: 'USD',
                    }
                  )
                  : 'N/A'}
                {fiscal_rating_data?.capital_expenditures
                  ? performanceText(
                    Math.round(fiscal_rating_data?.capital_expenditures)
                  )
                  : ''}
              </Text>
            </View>
            <View
              style={{
                position: 'absolute',
                width: '20%',
                height: '44px',
                marginLeft: '230px',
                backgroundColor: '#FFFFFF',
              }}
            >
              <Text style={{ fontSize: 8, padding: '5px' }}>
                Debt to Equity Ratio
              </Text>
              <Text
                style={{
                  fontSize: 8,
                  padding: '1px',
                  marginLeft: '5px',
                  color: textColor(
                    Math.round(fiscal_rating_data?.debt_to_equity_ratio)
                  ),
                  width: '80px',
                }}
              >
                {fiscal_score_data?.debt_to_equity_ratio ||
                  fiscal_score_data?.debt_to_equity_ratio === 0
                  ? fiscal_score_data.debt_to_equity_ratio.toFixed(2)
                  : 'N/A'}
                {fiscal_rating_data?.debt_to_equity_ratio
                  ? performanceText(
                    Math.round(fiscal_rating_data?.debt_to_equity_ratio)
                  )
                  : ''}
              </Text>
            </View>
            <View
              style={{
                position: 'absolute',
                width: '20%',
                height: '44px',
                marginLeft: '345px',
                backgroundColor: '#FFFFFF',
              }}
            >
              <Text style={{ fontSize: 8, padding: '5px' }}>
                Days Cash on Hand
              </Text>
              <Text
                style={{
                  fontSize: 8,
                  padding: '1px',
                  marginLeft: '5px',
                  color: textColor(
                    Math.round(fiscal_rating_data?.days_cash_on_hand)
                  ),
                  width: '80px',
                }}
              >
                {fiscal_score_data?.days_cash_on_hand ||
                  fiscal_score_data?.days_cash_on_hand === 0
                  ? fiscal_score_data.days_cash_on_hand.toFixed(1)
                  : 'N/A'}
                {fiscal_rating_data?.days_cash_on_hand
                  ? performanceText(
                    Math.round(fiscal_rating_data?.days_cash_on_hand)
                  )
                  : ''}
              </Text>
            </View>
            <View
              style={{
                position: 'absolute',
                width: '20%',
                height: '44px',
                marginLeft: '460px',
                backgroundColor: '#FFFFFF',
              }}
            >
              <Text style={{ fontSize: 8, padding: '5px' }}>Medicare Mix</Text>
              <Text
                style={{
                  fontSize: 8,
                  padding: '1px',
                  marginLeft: '5px',
                  color: textColor(
                    Math.round(fiscal_rating_data?.payor_mix_medicare_days)
                  ),
                  width: '80px',
                }}
              >
                {fiscal_score_data?.payor_mix_medicare_days || fiscal_score_data.payor_mix_medicare_days === 0 ? (fiscal_score_data.payor_mix_medicare_days * 100).toFixed(1) + "%" : "N/A"}
                {fiscal_rating_data?.payor_mix_medicare_days
                  ? performanceText(
                    Math.round(fiscal_rating_data?.payor_mix_medicare_days)
                  )
                  : ''}
              </Text>
            </View>
            <View
              style={{
                position: 'absolute',
                width: '20%',
                height: '44px',
                marginTop: '40px',
                marginLeft: '15px',
                backgroundColor: '#FFFFFF',
              }}
            >
              <Text style={{ fontSize: 8, padding: '5px' }}>Current Ratio</Text>
              <Text
                style={{
                  fontSize: 8,
                  padding: '1px',
                  marginLeft: '5px',
                  color: textColor(
                    Math.round(fiscal_rating_data?.current_ratio)
                  ),
                  width: '80px',
                }}
              >
                {fiscal_score_data?.current_ratio ||
                  fiscal_score_data?.current_ratio === 0
                  ? fiscal_score_data.current_ratio
                  : 'N/A'}
                {fiscal_rating_data?.current_ratio
                  ? performanceText(
                    Math.round(fiscal_rating_data?.current_ratio)
                  )
                  : ''}
              </Text>
            </View>
            <View
              style={{
                position: 'absolute',
                width: '20%',
                height: '44px',
                marginTop: '40px',
                marginLeft: '115px',
                backgroundColor: '#FFFFFF',
              }}
            >
              <Text style={{ fontSize: 8, padding: '5px' }}>Medicaid Mix</Text>
              <Text
                style={{
                  fontSize: 8,
                  padding: '1px',
                  marginLeft: '5px',
                  color: textColor(
                    Math.round(fiscal_rating_data?.payor_mix_medicaid_days)
                  ),
                  width: '80px',
                }}
              >
                {fiscal_score_data?.payor_mix_medicaid_days || fiscal_score_data?.payor_mix_medicaid_days === 0 ? (fiscal_score_data.payor_mix_medicaid_days * 100).toFixed(1) + "%" : "N/A"}
                {fiscal_rating_data?.payor_mix_medicaid_days
                  ? performanceText(
                    Math.round(fiscal_rating_data?.payor_mix_medicaid_days)
                  )
                  : ''}
              </Text>
            </View>
          </View>
          <View
            style={{
              border: '1px solid #D9D9D9',
              marginTop: '25px',
              marginBottom: '25px',
              width: '96%',
              marginLeft: '10px',
            }}
          ></View>
          <View
            wrap
            style={{
              marginLeft: '-10px',
              width: '100%',
              flexDirection: 'row',
              marginBottom: '5px',
              justifyContent:
                positive_fiscal.length > negative_fiscal.length
                  ? 'flex-start'
                  : 'flex-end',
            }}
          >
            <View
              wrap={false}
              style={{ flexDirection: 'column', width: '50%' }}
            >
              <Text
                style={{
                  fontSize: 12,
                  marginTop: '0px',
                  color: '#22577A',
                  marginLeft: '30px',
                  fontWeight: 800,
                }}
              >
                Positive Factors
              </Text>
              <View
                wrap={false}
                style={{
                  position: 'relative',
                  width: '263.66px',
                  padding: '5px',
                  marginLeft: '20px',
                  marginTop: '-10px',
                }}
              ></View>
              <View>
                {positive_fiscal.map((data) => {
                  return (
                    <View
                      wrap={false}
                      style={{
                        position: 'relative',
                        width: '263.66px',
                        padding: '5px',
                        marginLeft: '20px',
                        marginTop: '10px',
                        backgroundColor: '#FAFAFA',
                        border: '1px solid #E8F5E9',
                        borderRadius: '8.3px',
                      }}
                    >
                      <Text style={{ fontSize: 7 }}>{data[1]}</Text>
                    </View>
                  )
                })}
              </View>
            </View>
            <View
              wrap={false}
              style={{ flexDirection: 'column', width: '50%' }}
            >
              <Text
                style={{
                  fontSize: 12,
                  marginTop: '0px',
                  color: '#22577A',
                  marginLeft: '30px',
                  fontWeight: 800,
                }}
              >
                Risk Factors
              </Text>
              <View
                wrap={false}
                style={{
                  position: 'relative',
                  width: '263.66px',
                  padding: '5px',
                  marginLeft: '20px',
                  marginTop: '-10px',
                }}
              ></View>
              <View>
                {negative_fiscal.map((data) => {
                  return (
                    <View
                      wrap={false}
                      style={{
                        position: 'relative',
                        width: '243.66px',
                        padding: '5px',
                        marginLeft: '20px',
                        marginTop: '10px',
                        backgroundColor: '#FAFAFA',
                        border: '1px solid #E8F5E9',
                        borderRadius: '8.3px',
                      }}
                    >
                      <Text style={{ fontSize: 7 }}>{data[1]}</Text>
                    </View>
                  )
                })}
              </View>
            </View>
          </View>
        </View>
        <View
          style={{
            border: '2px solid #D9D9D9',
            marginTop: '20px',
            backgroundColor: '#D9D9D9',
          }}
        ></View>
        <View
          style={{
            position: 'relative',
            width: '100%',
            marginLeft: '0px',
            backgroundColor: '#FFFFFF',
            border: '1px solid #E8F5E9',
            borderRadius: '8.3px',
            padding: '5px',
            marginTop: '20px',
          }}
        >
          <View style={{ flexDirection: 'row' }} wrap={false}>
            <View>
              {' '}
              <Text
                style={{
                  fontSize: 12,
                  marginTop: '17px',
                  color: '#22577A',
                  marginLeft: '8px',
                  fontWeight: 800,
                }}
              >
                Operations
              </Text>
            </View>
            {ratings['clinical_operations'] ? (
              <View
                style={{
                  border: `1px solid ${textColor(
                    Math.round(ratings['clinical_operations'])
                  )}`,
                  backgroundColor: backgroundColor(
                    Math.round(ratings['clinical_operations'])
                  ),
                  height: '25px',
                  flexDirection: 'row',
                  borderRadius: '3.08px',
                  top: 11,
                  left: 5,
                  paddingTop: '2px',
                  paddingHorizontal: '5px',
                }}
              >
                <View
                  style={{
                    marginTop: '5.25px',
                    height: '22px',
                    width: '80px',
                    marginBottom: '10px',
                  }}
                >
                  <RenderRating
                    rating={Math.round(ratings['clinical_operations'])}
                    viewbox="0 0 35 35"
                  />
                </View>
                <Text
                  style={{
                    fontSize: 7,
                    marginTop: '6.25px',
                    marginLeft: '5px',
                  }}
                >
                  {performanceText(Math.round(ratings['clinical_operations']))
                    ?.replace('(', '')
                    .replace(')', '')}
                </Text>
              </View>
            ) : (
              <View></View>
            )}
          </View>
          <View
            wrap
            style={{
              flexDirection: 'row',
              marginTop: '15px',
              marginBottom: '10px',
            }}
          >
            <View style={{ flexDirection: 'column', width: '50%' }}>
              <View>
                <Text
                  style={{ fontSize: 8, padding: '5px', marginLeft: '12px' }}
                >
                  Bed Utilization
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    padding: '5px',
                    marginLeft: '12px',
                    color: textColor(
                      Math.round(operations_rating_data?.bed_utilization)
                    ),
                  }}
                >
                  {operations_score_data?.bed_utilization ||
                    operations_score_data?.bed_utilization === 0
                    ? operations_score_data.bed_utilization.toFixed(1) * 100 +
                    '%'
                    : 'N/A'}
                  {operations_rating_data?.bed_utilization
                    ? performanceText(
                      Math.round(operations_rating_data?.bed_utilization)
                    )
                    : ''}
                </Text>
              </View>
              <View>
                <Text
                  style={{ fontSize: 8, padding: '5px', marginLeft: '12px' }}
                >
                  Average number of mins spent in the emergency department
                  before discharge
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    padding: '5px',
                    marginLeft: '12px',
                    color: textColor(
                      Math.round(
                        operations_rating_data?.mins_in_ed_before_sent_home
                      )
                    ),
                  }}
                >
                  {operations_score_data?.mins_in_ed_before_sent_home ||
                    operations_score_data?.mins_in_ed_before_sent_home === 0
                    ? operations_score_data.mins_in_ed_before_sent_home
                    : 'N/A'}
                  {operations_rating_data?.mins_in_ed_before_sent_home
                    ? performanceText(
                      Math.round(
                        operations_rating_data?.mins_in_ed_before_sent_home
                      )
                    )
                    : ''}
                </Text>
              </View>
            </View>
            <View style={{ flexDirection: 'column', width: '50%' }}>
              <View>
                <Text
                  style={{ fontSize: 8, padding: '5px', marginLeft: '12px' }}
                >
                  % patients who left the emergency department before begin seen
                  rate
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    padding: '5px',
                    marginLeft: '12px',
                    color: textColor(
                      Math.round(
                        operations_rating_data?.pct_left_ed_before_being_seen
                      )
                    ),
                  }}
                >
                  {operations_score_data?.pct_left_ed_before_being_seen ||
                    operations_score_data?.pct_left_ed_before_being_seen === 0
                    ? Math.round(
                      operations_score_data.pct_left_ed_before_being_seen
                    ) *
                    100 +
                    '%'
                    : 'N/A'}
                  {operations_rating_data?.pct_left_ed_before_being_seen
                    ? performanceText(
                      Math.round(
                        operations_rating_data?.pct_left_ed_before_being_seen
                      )
                    )
                    : ''}
                </Text>
              </View>
              <View>
                <Text
                  style={{ fontSize: 8, padding: '5px', marginLeft: '12px' }}
                >
                  Annual surgeries per Operating Room
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    padding: '5px',
                    marginLeft: '12px',
                    color: textColor(
                      Math.round(operations_rating_data?.surgical_volume_per_or)
                    ),
                  }}
                >
                  {operations_score_data?.operating_room_throughput ||
                    operations_score_data?.operating_room_throughput === 0
                    ? Math.round(operations_score_data.operating_room_throughput)
                    : 'N/A'}
                  {operations_rating_data?.surgical_volume_per_or
                    ? performanceText(
                      Math.round(
                        operations_rating_data?.surgical_volume_per_or
                      )
                    )
                    : ''}
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{
              border: '1px solid #D9D9D9',
              marginTop: '25px',
              marginBottom: '25px',
              width: '96%',
              marginLeft: '10px',
            }}
          ></View>
          <View
            wrap
            style={{
              marginLeft: '-10px',
              width: '100%',
              flexDirection: 'row',
              marginBottom: '5px',
              justifyContent:
                positive_clinical.length > negative_clinical.length
                  ? 'flex-start'
                  : 'flex-end',
            }}
          >
            <View
              wrap={false}
              style={{ flexDirection: 'column', width: '50%' }}
            >
              <Text
                style={{
                  fontSize: 12,
                  marginTop: '0px',
                  color: '#22577A',
                  marginLeft: '30px',
                  fontWeight: 800,
                }}
              >
                Positive Factors
              </Text>
              <View
                wrap={false}
                style={{
                  position: 'relative',
                  width: '263.66px',
                  padding: '5px',
                  marginLeft: '20px',
                  marginTop: '-10px',
                }}
              ></View>
              <View>
                {positive_clinical.map((data) => {
                  return (
                    <View
                      wrap={false}
                      style={{
                        position: 'relative',
                        width: '263.66px',
                        padding: '5px',
                        marginLeft: '20px',
                        marginTop: '10px',
                        backgroundColor: '#FAFAFA',
                        border: '1px solid #E8F5E9',
                        borderRadius: '8.3px',
                      }}
                    >
                      <Text style={{ fontSize: 7 }}>{data[1]}</Text>
                    </View>
                  )
                })}
              </View>
            </View>
            <View
              wrap={false}
              style={{ flexDirection: 'column', width: '50%' }}
            >
              <Text
                style={{
                  fontSize: 12,
                  marginTop: '0px',
                  color: '#22577A',
                  marginLeft: '30px',
                  fontWeight: 800,
                }}
              >
                Risk Factors
              </Text>
              <View
                wrap={false}
                style={{
                  position: 'relative',
                  width: '263.66px',
                  padding: '5px',
                  marginLeft: '20px',
                  marginTop: '-10px',
                }}
              ></View>
              <View>
                {negative_clinical.map((data) => {
                  return (
                    <View
                      wrap={false}
                      style={{
                        position: 'relative',
                        width: '243.66px',
                        padding: '5px',
                        marginLeft: '20px',
                        marginTop: '10px',
                        backgroundColor: '#FAFAFA',
                        border: '1px solid #E8F5E9',
                        borderRadius: '8.3px',
                      }}
                    >
                      <Text style={{ fontSize: 7 }}>{data[1]}</Text>
                    </View>
                  )
                })}
              </View>
            </View>
          </View>
        </View>
        <View
          style={{
            border: '2px solid #D9D9D9',
            marginTop: '20px',
            backgroundColor: '#D9D9D9',
          }}
        ></View>
        <View
          wrap={false}
          style={{
            position: 'relative',
            width: '100%',
            marginLeft: '0px',
            backgroundColor: '#FFFFFF',
            border: '1px solid #E8F5E9',
            borderRadius: '8.3px',
            marginTop: '20px',
            padding: '5px',
          }}
        >
          <View style={{ flexDirection: 'row' }}>
            <View>
              <Text
                style={{
                  fontSize: 12,
                  marginTop: '15px',
                  color: '#22577A',
                  marginLeft: '8px',
                  fontWeight: 800,
                }}
              >
                Patient Experience
              </Text>
            </View>
            {ratings['experiential_design'] ? (
              <View
                style={{
                  border: `1px solid ${textColor(
                    Math.round(ratings['experiential_design'])
                  )}`,
                  backgroundColor: backgroundColor(
                    Math.round(ratings['experiential_design'])
                  ),
                  height: '25px',
                  flexDirection: 'row',
                  borderRadius: '3.08px',
                  top: 11,
                  left: 5,
                  paddingTop: '2px',
                  paddingHorizontal: '5px',
                }}
              >
                <View
                  style={{
                    marginTop: '5.25px',
                    height: '22px',
                    width: '80px',
                    marginBottom: '10px',
                  }}
                >
                  <RenderRating
                    rating={Math.round(ratings['experiential_design'])}
                    viewbox="0 0 35 35"
                  />
                </View>
                <Text
                  style={{
                    fontSize: 7,
                    marginTop: '6.25px',
                    marginLeft: '5px',
                  }}
                >
                  {performanceText(Math.round(ratings['experiential_design']))
                    ?.replace('(', '')
                    .replace(')', '')}
                </Text>
              </View>
            ) : (
              <View></View>
            )}
          </View>
          <View wrap style={{ flexDirection: 'row', marginTop: '25px' }}>
            <View style={{ flexDirection: 'column', width: '50%' }}>
              <View style={{ marginBottom: '10px' }}>
                <Text
                  style={{ fontSize: 8, padding: '5px', marginLeft: '12px' }}
                >
                  Patient Experience HCAHPAS Rating
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    padding: '5px',
                    marginLeft: '12px',
                    color: textColor(
                      Math.round(
                        patient_experience_rating_data?.patient_experience_hcahps_rating
                      )
                    ),
                  }}
                >
                  {patient_experience_score_data?.patient_experience_hcahps_rating ||
                    patient_experience_score_data?.patient_experience_hcahps_rating ===
                    0
                    ? patient_experience_score_data.patient_experience_hcahps_rating
                    : 'N/A'}
                  {patient_experience_rating_data?.patient_experience_hcahps_rating
                    ? performanceText(
                      Math.round(
                        patient_experience_rating_data?.patient_experience_hcahps_rating
                      )
                    )
                    : ''}
                </Text>
              </View>
              <View style={{ marginBottom: '10px' }}>
                <Text
                  style={{ fontSize: 8, padding: '5px', marginLeft: '12px' }}
                >
                  Patients who reported that their doctors always communicated
                  well Rate
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    padding: '5px',
                    marginLeft: '12px',
                    color: textColor(
                      Math.round(
                        patient_experience_rating_data?.my_doctors_always_communicated_well
                      )
                    ),
                  }}
                >
                  {patient_experience_score_data?.my_doctors_always_communicated_well ||
                    patient_experience_score_data?.my_doctors_always_communicated_well ===
                    0
                    ? Math.round(patient_experience_score_data.my_doctors_always_communicated_well * 100) +
                    '%'
                    : 'N/A'}
                  {patient_experience_rating_data?.my_doctors_always_communicated_well
                    ? performanceText(
                      Math.round(
                        patient_experience_rating_data?.my_doctors_always_communicated_well
                      )
                    )
                    : ''}
                </Text>
              </View>
              <View style={{ marginBottom: '10px' }}>
                <Text
                  style={{ fontSize: 8, padding: '5px', marginLeft: '12px' }}
                >
                  Patients who gave this hospital a rating of 9 to 10
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    padding: '5px',
                    marginLeft: '12px',
                    color: textColor(
                      Math.round(
                        patient_experience_rating_data?.i_gave_this_hospital_rating_of_9_or_10
                      )
                    ),
                  }}
                >
                  {patient_experience_score_data?.i_gave_this_hospital_rating_of_9_or_10 ||
                    patient_experience_score_data?.i_gave_this_hospital_rating_of_9_or_10 ===
                    0
                    ? Math.round(
                      patient_experience_score_data?.i_gave_this_hospital_rating_of_9_or_10 *
                      100
                    ) +
                    '%'
                    : 'N/A'}
                  {patient_experience_rating_data?.i_gave_this_hospital_rating_of_9_or_10
                    ? performanceText(
                      Math.round(
                        patient_experience_rating_data?.i_gave_this_hospital_rating_of_9_or_10
                      )
                    )
                    : ''}
                </Text>
              </View>
            </View>
            <View style={{ flexDirection: 'column', width: '50%' }}>
              <View style={{ marginBottom: '10px' }}>
                <Text
                  style={{ fontSize: 8, padding: '5px', marginLeft: '12px' }}
                >
                  Patients who reported that their nurses always communicate
                  well
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    padding: '5px',
                    marginLeft: '12px',
                    color: textColor(
                      Math.round(
                        patient_experience_rating_data?.my_nurses_always_communicated_well

                      )
                    ),
                  }}
                >
                  {patient_experience_score_data?.my_nurses_always_communicated_well ||
                    patient_experience_score_data?.my_nurses_always_communicated_well ===
                    0
                    ? Math.round(
                      patient_experience_score_data?.my_nurses_always_communicated_well
                      *
                      100
                    ) +
                    '%'
                    : 'N/A'}
                  {patient_experience_rating_data?.my_nurses_always_communicated_well
                    ? performanceText(
                      Math.round(
                        patient_experience_rating_data?.my_nurses_always_communicated_well
                      )
                    )
                    : ''}
                </Text>
              </View>
              <View style={{ marginBottom: '10px' }}>
                <Text
                  style={{ fontSize: 8, padding: '5px', marginLeft: '12px' }}
                >
                  Patients who always received help as soon as they wanted
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    padding: '5px',
                    marginLeft: '12px',
                    color: textColor(
                      Math.round(
                        patient_experience_rating_data?.i_always_received_help_as_soon_as_i_wanted
                      )
                    ),
                  }}
                >
                  {patient_experience_score_data?.i_always_received_help_as_soon_as_i_wanted ||
                    patient_experience_score_data?.i_always_received_help_as_soon_as_i_wanted ===
                    0
                    ? Math.round(
                      patient_experience_score_data.i_always_received_help_as_soon_as_i_wanted
                      *
                      100
                    ) +
                    '%'
                    : 'N/A'}
                  {patient_experience_rating_data?.i_always_received_help_as_soon_as_i_wanted
                    ? performanceText(
                      Math.round(
                        patient_experience_rating_data?.i_always_received_help_as_soon_as_i_wanted
                      )
                    )
                    : ''}
                </Text>
              </View>
              <View style={{ marginBottom: '10px' }}>
                <Text
                  style={{ fontSize: 8, padding: '5px', marginLeft: '12px' }}
                >
                  Patients who reported they would definitely recommend this
                  hospital
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    padding: '5px',
                    marginLeft: '12px',
                    color: textColor(
                      Math.round(
                        patient_experience_rating_data?.i_would_definitely_recommend_the_hospital
                      )
                    ),
                  }}
                >
                  {patient_experience_score_data?.i_would_definitely_recommend_the_hospital ||
                    patient_experience_score_data?.i_would_definitely_recommend_the_hospital ===
                    0
                    ? Math.round(
                      patient_experience_score_data.i_would_definitely_recommend_the_hospital
                      *
                      100
                    ) +
                    '%'
                    : 'N/A'}
                  {patient_experience_rating_data?.i_would_definitely_recommend_the_hospital
                    ? performanceText(
                      Math.round(
                        patient_experience_rating_data?.i_would_definitely_recommend_the_hospital
                      )
                    )
                    : ''}
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{
              border: '1px solid #D9D9D9',
              marginTop: '25px',
              marginBottom: '25px',
              width: '96%',
              marginLeft: '10px',
            }}
          ></View>
          <View
            wrap
            style={{
              marginLeft: '-10px',
              width: '100%',
              flexDirection: 'row',
              marginBottom: '5px',
              justifyContent:
                positive_patient_exp.length > negative_patient_exp.length
                  ? 'flex-start'
                  : 'flex-end',
            }}
          >
            <View
              wrap={false}
              style={{ flexDirection: 'column', width: '50%' }}
            >
              <Text
                style={{
                  fontSize: 12,
                  marginTop: '0px',
                  color: '#22577A',
                  marginLeft: '30px',
                  fontWeight: 800,
                }}
              >
                Positive Factors
              </Text>
              <View
                wrap={false}
                style={{
                  position: 'relative',
                  width: '263.66px',
                  padding: '5px',
                  marginLeft: '20px',
                  marginTop: '-10px',
                }}
              ></View>

              <View>
                {positive_patient_exp.map((data) => {
                  return (
                    <View
                      wrap={false}
                      style={{
                        position: 'relative',
                        width: '263.66px',
                        padding: '5px',
                        marginLeft: '20px',
                        marginTop: '10px',
                        backgroundColor: '#FAFAFA',
                        border: '1px solid #E8F5E9',
                        borderRadius: '8.3px',
                      }}
                    >
                      <Text style={{ fontSize: 7 }}>{data[1]}</Text>
                    </View>
                  )
                })}
              </View>
            </View>
            <View
              wrap={false}
              style={{ flexDirection: 'column', width: '50%' }}
            >
              <Text
                style={{
                  fontSize: 12,
                  marginTop: '0px',
                  color: '#22577A',
                  marginLeft: '30px',
                  fontWeight: 800,
                }}
              >
                Risk Factors
              </Text>
              <View
                wrap={false}
                style={{
                  position: 'relative',
                  width: '263.66px',
                  padding: '5px',
                  marginLeft: '20px',
                  marginTop: '-10px',
                }}
              ></View>
              <View>
                {negative_patient_exp.map((data) => {
                  return (
                    <View
                      wrap={false}
                      style={{
                        position: 'relative',
                        width: '243.66px',
                        padding: '5px',
                        marginLeft: '20px',
                        marginTop: '10px',
                        backgroundColor: '#FAFAFA',
                        border: '1px solid #E8F5E9',
                        borderRadius: '8.3px',
                      }}
                    >
                      <Text style={{ fontSize: 7 }}>{data[1]}</Text>
                    </View>
                  )
                })}
              </View>
            </View>
          </View>
        </View>
        <View
          style={{
            border: '2px solid #D9D9D9',
            marginTop: '20px',
            marginBottom: '20px',
            backgroundColor: '#D9D9D9',
          }}
        ></View>
        <View
          wrap={false}
          style={{
            position: 'relative',
            width: '100%',
            marginLeft: '0px',
            backgroundColor: '#FFFFFF',
            border: '1px solid #E8F5E9',
            borderRadius: '8.3px',
            padding: '5px',
          }}
        >
          <View style={{ flexDirection: 'row' }}>
            <View>
              <Text
                style={{
                  fontSize: 12,
                  marginTop: '17px',
                  color: '#22577A',
                  marginLeft: '8px',
                  fontWeight: 800,
                }}
              >
                Clinical Outcomes
              </Text>
            </View>
            {ratings['clinical_outcomes'] ? (
              <View
                style={{
                  border: `1px solid ${textColor(
                    Math.round(ratings['clinical_outcomes'])
                  )}`,
                  backgroundColor: backgroundColor(
                    Math.round(ratings['clinical_outcomes'])
                  ),
                  height: '25px',
                  flexDirection: 'row',
                  borderRadius: '3.08px',
                  top: 11,
                  left: 5,
                  paddingHorizontal: '5px',
                  paddingTop: '2px',
                }}
              >
                <View
                  style={{
                    marginTop: '5.25px',
                    height: '22px',
                    width: '80px',
                    marginBottom: '10px',
                  }}
                >
                  <RenderRating
                    rating={Math.round(ratings['clinical_outcomes'])}
                    viewbox="0 0 35 35"
                  />
                </View>
                <Text
                  style={{
                    fontSize: 7,
                    marginTop: '6.25px',
                    marginLeft: '5px',
                  }}
                >
                  {performanceText(Math.round(ratings['clinical_outcomes']))
                    ?.replace('(', '')
                    .replace(')', '')}
                </Text>
              </View>
            ) : (
              <View></View>
            )}
          </View>
          <View wrap style={{ flexDirection: 'row', marginTop: '25px' }}>
            <View style={{ flexDirection: 'column', width: '50%' }}>
              <View style={{ marginBottom: '10px' }}>
                <Text
                  style={{ fontSize: 8, padding: '5px', marginLeft: '12px' }}
                >
                  Hospital Compare Overall Quality Rating
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    padding: '5px',
                    marginLeft: '12px',
                    color: textColor(
                      Math.round(
                        clinical_outcome_rating_data?.overall_quality_rating
                      )
                    ),
                  }}
                >
                  {clinical_outcome_score_data?.overall_quality_rating ||
                    clinical_outcome_score_data?.overall_quality_rating === 0
                    ? clinical_outcome_score_data.overall_quality_rating
                    : 'N/A'}
                  {clinical_outcome_rating_data?.overall_quality_rating
                    ? performanceText(
                      Math.round(
                        clinical_outcome_rating_data?.overall_quality_rating
                      )
                    )
                    : ''}
                </Text>
              </View>
              <View style={{ marginBottom: '10px' }}>
                <Text
                  style={{ fontSize: 8, padding: '5px', marginLeft: '12px' }}
                >
                  Most Recent Year Revenue Loss Due to Hospital Acquired
                  Condition Penalty
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    padding: '5px',
                    marginLeft: '12px',
                    color: textColor(
                      Math.round(
                        clinical_outcome_rating_data?.revenue_penalty_due_to_hospital_acquired_condition
                      )
                    ),
                  }}
                >
                  {clinical_outcome_score_data?.revenue_penalty_due_to_hospital_acquired_condition ||
                    clinical_outcome_score_data?.revenue_penalty_due_to_hospital_acquired_condition ===
                    0
                    ? clinical_outcome_score_data.revenue_penalty_due_to_hospital_acquired_condition.toLocaleString(
                      'en-US',
                      {
                        style: 'currency',
                        currency: 'USD',
                      }
                    )
                    : 'N/A'}
                  {clinical_outcome_rating_data?.revenue_penalty_due_to_hospital_acquired_condition
                    ? performanceText(
                      Math.round(
                        clinical_outcome_rating_data?.revenue_penalty_due_to_hospital_acquired_condition
                      )
                    )
                    : ''}
                </Text>
              </View>
              <View style={{ marginBottom: '10px' }}>
                <Text
                  style={{ fontSize: 8, padding: '5px', marginLeft: '12px' }}
                >
                  Previous Year Revenue Loss Due to Hospital Acquired Condition
                  Penalty
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    padding: '5px',
                    marginLeft: '12px',
                    color: textColor(
                      Math.round(
                        clinical_outcome_rating_data?.prev_year_revenue_penalty_due_to_hospital_acquired_condition
                      )
                    ),
                  }}
                >
                  {clinical_outcome_score_data?.prev_year_revenue_penalty_due_to_hospital_acquired_condition ||
                    clinical_outcome_score_data.prev_year_revenue_penalty_due_to_hospital_acquired_condition ===
                    0
                    ? clinical_outcome_score_data.prev_year_revenue_penalty_due_to_hospital_acquired_condition.toLocaleString(
                      'en-US',
                      {
                        style: 'currency',
                        currency: 'USD',
                      }
                    )
                    : 'N/A'}
                  {clinical_outcome_rating_data?.prev_year_revenue_penalty_due_to_hospital_acquired_condition
                    ? performanceText(
                      Math.round(
                        clinical_outcome_rating_data?.prev_year_revenue_penalty_due_to_hospital_acquired_condition
                      )
                    )
                    : ''}
                </Text>
              </View>
            </View>
            <View style={{ flexDirection: 'column', width: '50%' }}>
              <View style={{ marginBottom: '10px' }}>
                <Text
                  style={{ fontSize: 8, padding: '5px', marginLeft: '12px' }}
                >
                  Readmission Rate
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    padding: '5px',
                    marginLeft: '12px',
                    color: textColor(
                      Math.round(clinical_outcome_rating_data?.readmission_rate)
                    ),
                  }}
                >
                  {clinical_outcome_score_data?.readmission_rate ||
                    clinical_outcome_score_data?.readmission_rate === 0
                    ? (clinical_outcome_score_data.readmission_rate * 100).toFixed(1)
                    + '%'
                    : 'N/A'}
                  {clinical_outcome_rating_data?.readmission_rate
                    ? performanceText(
                      Math.round(
                        clinical_outcome_rating_data?.readmission_rate
                      )
                    )
                    : ''}
                </Text>
              </View>
              <View style={{ marginBottom: '10px' }}>
                <Text
                  style={{ fontSize: 8, padding: '5px', marginLeft: '12px' }}
                >
                  Most Recent Year Revenue Loss Due to Readmission Penalty
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    padding: '5px',
                    marginLeft: '12px',
                    color:
                      clinical_outcome_score_data?.revenue_penalty_due_to_excess_readmissions <
                        0
                        ? '#FF0707'
                        : textColor(
                          Math.round(
                            clinical_outcome_rating_data?.revenue_penalty_due_to_excess_readmissions
                          )
                        ),
                  }}
                >
                  {clinical_outcome_score_data?.revenue_penalty_due_to_excess_readmissions ||
                    clinical_outcome_score_data?.revenue_penalty_due_to_excess_readmissions ===
                    0
                    ? clinical_outcome_score_data.revenue_penalty_due_to_excess_readmissions.toLocaleString(
                      'en-US',
                      {
                        style: 'currency',
                        currency: 'USD',
                      }
                    )
                    : 'N/A'}
                  {clinical_outcome_rating_data?.revenue_penalty_due_to_excess_readmissions
                    ? performanceText(
                      Math.round(
                        clinical_outcome_rating_data?.revenue_penalty_due_to_excess_readmissions
                      )
                    )
                    : ''}
                </Text>
              </View>
              <View style={{ marginBottom: '10px' }}>
                <Text
                  style={{ fontSize: 8, padding: '5px', marginLeft: '12px' }}
                >
                  Previous Year Revenue Loss Due to Readmission Penalty
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    padding: '5px',
                    marginLeft: '12px',
                    color:
                      clinical_outcome_score_data?.prev_year_revenue_penalty_due_to_excess_readmissions <
                        0
                        ? '#FF0707'
                        : textColor(
                          Math.round(
                            clinical_outcome_rating_data?.prev_year_revenue_penalty_due_to_excess_readmissions
                          )
                        ),
                  }}
                >
                  {clinical_outcome_score_data?.prev_year_revenue_penalty_due_to_excess_readmissions ||
                    clinical_outcome_score_data?.prev_year_revenue_penalty_due_to_excess_readmissions ===
                    0
                    ? clinical_outcome_score_data?.prev_year_revenue_penalty_due_to_excess_readmissions.toLocaleString(
                      'en-US',
                      {
                        style: 'currency',
                        currency: 'USD',
                      }
                    )
                    : 'N/A'}
                  {clinical_outcome_rating_data?.prev_year_revenue_penalty_due_to_excess_readmissions
                    ? performanceText(
                      Math.round(
                        clinical_outcome_rating_data?.prev_year_revenue_penalty_due_to_excess_readmissions
                      )
                    )
                    : ''}
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{
              border: '1px solid #D9D9D9',
              marginTop: '25px',
              marginBottom: '25px',
              width: '96%',
              marginLeft: '10px',
            }}
          ></View>
          <View
            wrap
            style={{
              marginLeft: '-10px',
              width: '100%',
              flexDirection: 'row',
              marginBottom: '5px',
              justifyContent:
                positive_clinical_outcomes.length >
                  negative_clinical_outcomes.length
                  ? 'flex-start'
                  : 'flex-end',
            }}
          >
            <View
              wrap={false}
              style={{ flexDirection: 'column', width: '50%' }}
            >
              <Text
                style={{
                  fontSize: 12,
                  marginTop: '0px',
                  color: '#22577A',
                  marginLeft: '30px',
                  fontWeight: 800,
                }}
              >
                Positive Factors
              </Text>
              <View
                wrap={false}
                style={{
                  position: 'relative',
                  width: '263.66px',
                  padding: '5px',
                  marginLeft: '20px',
                  marginTop: '-10px',
                }}
              ></View>
              <View>
                {positive_clinical_outcomes.map((data) => {
                  return (
                    <View
                      wrap={false}
                      style={{
                        position: 'relative',
                        width: '263.66px',
                        padding: '5px',
                        marginLeft: '20px',
                        marginTop: '10px',
                        backgroundColor: '#FAFAFA',
                        border: '1px solid #E8F5E9',
                        borderRadius: '8.3px',
                      }}
                    >
                      <Text style={{ fontSize: 7 }}>{data[1]}</Text>
                    </View>
                  )
                })}
              </View>
            </View>
            <View
              wrap={false}
              style={{ flexDirection: 'column', width: '50%' }}
            >
              <Text
                style={{
                  fontSize: 12,
                  marginTop: '0px',
                  color: '#22577A',
                  marginLeft: '30px',
                  fontWeight: 800,
                }}
              >
                Risk Factors
              </Text>
              <View
                wrap={false}
                style={{
                  position: 'relative',
                  width: '263.66px',
                  padding: '5px',
                  marginLeft: '20px',
                  marginTop: '-10px',
                }}
              ></View>
              <View>
                {negative_clinical_outcomes.map((data) => {
                  return (
                    <View
                      wrap={false}
                      style={{
                        position: 'relative',
                        width: '243.66px',
                        padding: '5px',
                        marginLeft: '20px',
                        marginTop: '10px',
                        backgroundColor: '#FAFAFA',
                        border: '1px solid #E8F5E9',
                        borderRadius: '8.3px',
                      }}
                    >
                      <Text style={{ fontSize: 7 }}>{data[1]}</Text>
                    </View>
                  )
                })}
              </View>
            </View>
          </View>
        </View>
        <View
          style={{
            border: '2px solid #D9D9D9',
            marginTop: '20px',
            marginBottom: '20px',
            backgroundColor: '#D9D9D9',
          }}
        ></View>
        <View
          wrap={false}
          style={{
            position: 'relative',
            width: '100%',
            marginLeft: '0px',
            backgroundColor: '#FFFFFF',
            border: '1px solid #E8F5E9',
            borderRadius: '8.3px',
            padding: '5px',
          }}
        >
          <View style={{ flexDirection: 'row' }}>
            <View>
              <Text
                style={{
                  fontSize: 12,
                  marginTop: '17px',
                  color: '#22577A',
                  marginLeft: '8px',
                  fontWeight: 800,
                }}
              >
                Facility Conditions
              </Text>
            </View>
            {ratings['facility_conditions'] ? (
              <View
                style={{
                  border: `1px solid ${textColor(
                    Math.round(ratings['facility_conditions'])
                  )}`,
                  backgroundColor: backgroundColor(
                    Math.round(ratings['facility_conditions'])
                  ),
                  height: '25px',
                  flexDirection: 'row',
                  borderRadius: '3.08px',
                  top: 11,
                  left: 5,
                  paddingHorizontal: '5px',
                  paddingTop: '2px',
                }}
              >
                <View
                  style={{
                    marginTop: '5.25px',
                    height: '22px',
                    width: '80px',
                    marginBottom: '10px',
                  }}
                >
                  <RenderRating
                    rating={Math.round(ratings['facility_conditions'])}
                    viewbox="0 0 35 35"
                  />
                </View>
                <Text
                  style={{
                    fontSize: 7,
                    marginTop: '6.25px',
                    marginLeft: '5px',
                  }}
                >
                  {performanceText(Math.round(ratings['facility_conditions']))
                    ?.replace('(', '')
                    .replace(')', '')}
                </Text>
              </View>
            ) : (
              <View></View>
            )}
          </View>
          <View wrap style={{ flexDirection: 'row', marginTop: '25px' }}>
            <View style={{ flexDirection: 'column', width: '40%' }}>
              <View style={{ marginBottom: '10px' }}>
                <Text
                  style={{ fontSize: 8, padding: '5px', marginLeft: '12px' }}
                >
                  Inpatient Square Footage
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    padding: '5px',
                    marginLeft: '12px',
                    color: textColor(
                      Math.round(
                        facility_conditions_rating_data?.inpatient_sq_footage
                      )
                    ),
                  }}
                >
                  {facility_conditions_score_data?.inpatient_sq_footage ||
                    facility_conditions_score_data?.inpatient_sq_footage === 0
                    ? parseInt(
                      facility_conditions_score_data.inpatient_sq_footage
                    ).toLocaleString('en-US')
                    : 'N/A'}
                  {facility_conditions_rating_data?.inpatient_sq_footage
                    ? performanceText(
                      Math.round(
                        facility_conditions_rating_data?.inpatient_sq_footage
                      )
                    )
                    : ''}
                </Text>
              </View>
              <View style={{ marginBottom: '10px' }}>
                <Text
                  style={{ fontSize: 8, padding: '5px', marginLeft: '12px' }}
                >
                  Average Age of Facility (in years)
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    padding: '5px',
                    marginLeft: '12px',
                    color: textColor(
                      Math.round(
                        facility_conditions_rating_data?.avg_age_of_facility_years
                      )
                    ),
                  }}
                >
                  {facility_conditions_score_data?.avg_age_of_facility_years ||
                    facility_conditions_score_data?.avg_age_of_facility_years ===
                    0
                    ? facility_conditions_score_data.avg_age_of_facility_years.toFixed(
                      2
                    )
                    : 'N/A'}
                  {facility_conditions_rating_data?.avg_age_of_facility_years
                    ? performanceText(
                      Math.round(
                        facility_conditions_rating_data?.avg_age_of_facility_years
                      )
                    )
                    : ''}
                </Text>
              </View>
              <View style={{ marginBottom: '10px' }}>
                <Text
                  style={{ fontSize: 8, padding: '5px', marginLeft: '12px' }}
                >
                  Room and Bathroom Were Always Clean
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    padding: '5px',
                    marginLeft: '12px',
                    color: textColor(
                      Math.round(
                        facility_conditions_rating_data?.my_room_and_bathroom_were_always_clean
                      )
                    ),
                  }}
                >
                  {facility_conditions_score_data?.my_room_and_bathroom_were_always_clean ||
                    facility_conditions_score_data?.my_room_and_bathroom_were_always_clean ===
                    0
                    ?
                    (facility_conditions_score_data.my_room_and_bathroom_were_always_clean * 100).toFixed(0) +
                    '%'
                    : 'N/A'}
                  {facility_conditions_rating_data?.my_room_and_bathroom_were_always_clean
                    ? performanceText(
                      Math.round(
                        facility_conditions_rating_data?.my_room_and_bathroom_were_always_clean
                      )
                    )
                    : ''}
                </Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: 'column',
                marginLeft: '60px',
                width: '30%',
              }}
            >
              <View style={{ marginBottom: '10px' }}>
                <Text style={{ fontSize: 8, padding: '5px' }}>
                  Number of Beds
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    padding: '5px',
                    color: textColor(
                      Math.round(
                        facility_conditions_rating_data?.number_of_beds
                      )
                    ),
                  }}
                >
                  {facility_conditions_score_data?.number_of_beds ||
                    facility_conditions_score_data?.number_of_beds === 0
                    ? facility_conditions_score_data.number_of_beds
                    : 'N/A'}
                  {facility_conditions_rating_data?.number_of_beds
                    ? performanceText(
                      Math.round(
                        facility_conditions_rating_data?.number_of_beds
                      )
                    )
                    : ''}
                </Text>
              </View>
              <View style={{ marginBottom: '10px' }}>
                <Text style={{ fontSize: 8, padding: '5px' }}>
                  Inpatient SF / Bed
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    padding: '5px',
                    color: textColor(
                      Math.round(
                        facility_conditions_rating_data?.inpatient_sq_ft_per_bed
                      )
                    ),
                  }}
                >
                  {facility_conditions_score_data?.inpatient_sq_ft_per_bed ||
                    facility_conditions_score_data?.inpatient_sq_ft_per_bed === 0
                    ? Math.round(
                      facility_conditions_score_data.inpatient_sq_ft_per_bed
                    ).toLocaleString('en-US')
                    : 'N/A'}
                  {facility_conditions_rating_data?.inpatient_sq_ft_per_bed
                    ? performanceText(
                      Math.round(
                        facility_conditions_rating_data?.inpatient_sq_ft_per_bed
                      )
                    )
                    : ''}
                </Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: 'column',
                marginLeft: '60px',
                width: '30%',
              }}
            >
              <View style={{ marginBottom: '10px' }}>
                <Text style={{ fontSize: 8, padding: '5px' }}>
                  Outpatient Square Footage
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    padding: '5px',
                    color: textColor(
                      Math.round(
                        facility_conditions_rating_data?.outpatient_sq_footage
                      )
                    ),
                  }}
                >
                  {facility_conditions_score_data?.outpatient_sq_footage ||
                    facility_conditions_score_data?.outpatient_sq_footage === 0
                    ? parseInt(
                      facility_conditions_score_data.outpatient_sq_footage
                    ).toLocaleString('en-US')
                    : 'N/A'}
                  {facility_conditions_rating_data?.outpatient_sq_footage
                    ? performanceText(
                      Math.round(
                        facility_conditions_rating_data?.outpatient_sq_footage
                      )
                    )
                    : ''}
                </Text>
              </View>
              <View style={{ marginBottom: '10px' }}>
                <Text style={{ fontSize: 8, padding: '5px' }}>
                  Inpatient Revenue per SF
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    padding: '5px',
                    color: textColor(
                      Math.round(
                        facility_conditions_rating_data?.inpatient_revenue_per_bed
                      )
                    ),
                  }}
                >
                  {facility_conditions_score_data?.inpatient_revenue_per_bed ||
                    facility_conditions_score_data?.inpatient_revenue_per_bed ===
                    0
                    ?
                    facility_conditions_score_data.inpatient_revenue_per_bed
                      .toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      })
                    : 'N/A'}
                  {facility_conditions_rating_data?.inpatient_revenue_per_bed
                    ? performanceText(
                      Math.round(
                        facility_conditions_rating_data?.inpatient_revenue_per_bed
                      )
                    )
                    : ''}
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{
              border: '1px solid #D9D9D9',
              marginTop: '25px',
              marginBottom: '25px',
              width: '96%',
              marginLeft: '10px',
            }}
          ></View>
          <View
            wrap
            style={{
              marginLeft: '-10px',
              width: '100%',
              flexDirection: 'row',
              marginBottom: '5px',
              justifyContent:
                positive_facility_conditions.length >
                  negative_facility_conditions
                  ? 'flex-start'
                  : 'flex-end',
            }}
          >
            <View wrap style={{ flexDirection: 'column', width: '50%' }}>
              <Text
                style={{
                  fontSize: 12,
                  marginTop: '0px',
                  color: '#22577A',
                  marginLeft: '30px',
                  fontWeight: 800,
                }}
              >
                Positive Factors
              </Text>
              <View
                wrap={false}
                style={{
                  position: 'relative',
                  width: '263.66px',
                  padding: '5px',
                  marginLeft: '20px',
                  marginTop: '-10px',
                }}
              ></View>
              <View>
                {positive_facility_conditions.map((data) => {
                  return (
                    <View
                      wrap={false}
                      style={{
                        position: 'relative',
                        width: '263.66px',
                        padding: '5px',
                        marginLeft: '20px',
                        marginTop: '10px',
                        backgroundColor: '#FAFAFA',
                        border: '1px solid #E8F5E9',
                        borderRadius: '8.3px',
                      }}
                    >
                      <Text style={{ fontSize: 7 }}>{data[1]}</Text>
                    </View>
                  )
                })}
              </View>
            </View>
            <View
              wrap={false}
              style={{ flexDirection: 'column', width: '50%' }}
            >
              <Text
                style={{
                  fontSize: 12,
                  marginTop: '0px',
                  color: '#22577A',
                  marginLeft: '30px',
                  fontWeight: 800,
                }}
              >
                Risk Factors
              </Text>
              <View
                wrap={false}
                style={{
                  position: 'relative',
                  width: '263.66px',
                  padding: '5px',
                  marginLeft: '20px',
                  marginTop: '-10px',
                }}
              ></View>
              <View>
                {negative_facility_conditions.map((data) => {
                  return (
                    <View
                      wrap={false}
                      style={{
                        position: 'relative',
                        width: '243.66px',
                        padding: '5px',
                        marginLeft: '20px',
                        marginTop: '10px',
                        backgroundColor: '#FAFAFA',
                        border: '1px solid #E8F5E9',
                        borderRadius: '8.3px',
                      }}
                    >
                      <Text style={{ fontSize: 7 }}>{data[1]}</Text>
                    </View>
                  )
                })}
              </View>
            </View>
          </View>
        </View>
        <View wrap fixed style={pdfStyle.footer}>
          <View
            style={{
              border: '1px solid #D9D9D9',
              marginTop: '10px',
              width: '520px',
              height: '1px',
              left: '25px',
            }}
          ></View>
          <View
            style={{ flexDirection: 'row', fontSize: 8, marginTop: '10px' }}
          >
            <Text style={{ marginLeft: '30px', fontSize: 10 }}>
              Export Date : {formatedDateTime}
            </Text>
            <Text
              style={{
                fontWeight: 800,
                marginLeft: '70px',
                fontSize: 10,
              }}
            >
              Confidential: Do Not Distribute Outside of DPR
            </Text>
            <Text
              style={{ marginLeft: '110px', fontSize: 10 }}
              render={({ pageNumber }) => `Page ${pageNumber}`}
            />
          </View>
        </View>
      </Page>

      {/* StakeHolders */}
      <Page size="A4" style={pdfStyle.body}>
        <View fixed>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              paddingBottom: '10px',
              paddingRight: '20px',
            }}
          >
            <Image
              src={dprlogo}
              style={{
                width: '74px',
                height: '45px',
                padding: '5px',
              }}
            ></Image>

            <View
              style={{
                borderRight: '1px solid #CCCCCC',
                height: '30px',
                marginTop: '8px',
                marginLeft: '10px',
              }}
            ></View>

            <Image
              src={panaromalogo}
              style={{
                width: '100px',
                height: '30px',
                marginLeft: '20px',
                marginTop: '8px',
                padding: 5,
              }}
            ></Image>

            <Text
              style={{
                fontSize: '20px',
                color: '#000000',
                marginLeft: 'auto',
                marginTop: '15px',
              }}
            >
              CLIENT INSIGHTS
            </Text>
          </View>

          <View
            style={{
              borderBottom: '2px solid #4CAF50',
              width: '100%',
              marginBottom: '20px',
              marginTop: 10,
            }}
          ></View>
        </View>
        <View wrap style={{ flexDirection: 'row' }}>
          <Text
            style={{
              fontSize: '20px',
              fontWeight: 400,
              color: '#000000',
              marginRight: 'auto',
              marginTop: '8px',
            }}
          >
            STAKEHOLDERS
          </Text>
          <View
            style={{
              borderBottom: '1px solid black',
              width: '70%',
              marginBottom: '10px',
              marginTop: 10,
            }}
          ></View>
        </View>

        <View
          style={{
            marginTop: '20px',
          }}
        >
          <View>
            <Text
              style={{
                fontSize: '8px',
                marginBottom: '10px',
                fontStyle: 'italic',
                marginLeft: '5px',
              }}
            >
              Last Updated :{' '}
              {stakeHoldersDataExcel && stakeHoldersDataExcel.length
                ? stakeHoldersDataExcel[0].last_updated_on
                : '-'}
            </Text>
          </View>
          <View style={pdfStyle.headerView} fixed>
            {tableData1['column1'].map((c) => (
              <Text
                style={{
                  width: `${100 / tableData['column1'].length}%`,
                  textAlign: 'left',
                  marginLeft: '10px',
                }}
              >
                {c}
              </Text>
            ))}
          </View>
          {stakeHoldersDataExcel.map((rowData) => (
            <>
              <View style={pdfStyle.rowView} wrap={false}>
                {tableData1['column2'].map((c) => (
                  <Text
                    wrap={false}
                    style={{
                      width: `${100 / tableData['column1'].length}%`,
                      textAlign: 'left',
                      marginLeft:
                        c == 'linkedin_profile' || c == 'personal_phone'
                          ? '15px'
                          : '10px',
                    }}
                  >
                    {rowData[c] ? (
                      c == 'linkedin_profile' && rowData[c] != null ? (
                        <a rel="nooopener noreferrer" href={rowData[c]}>
                          <Image
                            src={linkedinlogo}
                            style={{ height: '10px', width: '10px' }}
                          ></Image>
                        </a>
                      ) : (
                        rowData[c]
                      )
                    ) : (
                      '-'
                    )}
                  </Text>
                ))}
              </View>
            </>
          ))}
        </View>

        <View wrap fixed style={pdfStyle.footer}>
          <View
            style={{
              border: '1px solid #D9D9D9',
              marginTop: '10px',
              width: '520px',
              height: '1px',
              left: '25px',
            }}
          ></View>
          <View
            style={{ flexDirection: 'row', fontSize: 8, marginTop: '10px' }}
          >
            <Text style={{ marginLeft: '30px', fontSize: 10 }}>
              Export Date : {formatedDateTime}
            </Text>
            <Text
              style={{
                fontWeight: 800,
                marginLeft: '70px',
                fontSize: 10,
              }}
            >
              Confidential: Do Not Distribute Outside of DPR
            </Text>
            <Text
              style={{ marginLeft: '110px', fontSize: 10 }}
              render={({ pageNumber }) => `Page ${pageNumber}`}
            />
          </View>
        </View>
      </Page>

      {/* HCAI */}
      {props.desicionMaker == 'California' ? (
        <Page size="A4" style={pdfStyle.body}>
          <View fixed wrap>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                paddingBottom: '10px',
                paddingRight: '20px',
              }}
            >
              <Image
                src={dprlogo}
                style={{
                  width: '74px',
                  height: '45px',
                  padding: '5px',
                }}
              ></Image>

              <View
                style={{
                  borderRight: '1px solid #CCCCCC',
                  height: '30px',
                  marginTop: '8px',
                  marginLeft: '10px',
                }}
              ></View>

              <Image
                src={panaromalogo}
                style={{
                  width: '100px',
                  height: '30px',
                  marginLeft: '20px',
                  marginTop: '8px',
                  padding: 5,
                }}
              ></Image>

              <Text
                style={{
                  fontSize: '20px',
                  color: '#000000',
                  marginLeft: 'auto',
                  marginTop: '15px',
                }}
              >
                CLIENT INSIGHTS
              </Text>
            </View>

            <View
              style={{
                borderBottom: '2px solid #4CAF50',
                width: '100%',
                marginBottom: '20px',
                marginTop: 10,
              }}
            ></View>
          </View>
          <View wrap style={{ flexDirection: 'row' }}>
            <Text
              style={{
                fontSize: '20px',
                fontWeight: 400,
                color: '#000000',
                marginRight: 'auto',
                marginTop: '-8px',
              }}
            >
              CALIFORNIA HCAI
            </Text>
            <View
              style={{
                borderBottom: '1px solid black',
                width: '65%',
                marginBottom: '10px',
                marginTop: -8,
              }}
            ></View>
          </View>
          <View
            wrap
            style={{
              border: '1px solid #E8F5E9',
              backgroundColor: '#FAFAFA',
              borderRadius: '9px',
              marginTop: '20px',
              flexDirection: 'column',
              width: '568px',
              height: '80px',
            }}
          >
            <View
              style={{ flexDirection: 'row', marginTop: '5px', height: '50px' }}
            >
              <View
                style={{
                  flexDirection: 'column',
                  marginTop: '5px',
                  marginLeft: '10px',
                  width: '28%',
                }}
              >
                <Text
                  style={{
                    fontSize: '7.26px',
                    fontWeight: 400,
                    color: alpha('#3E3E3E', 0.8),
                  }}
                >
                  Campus Name
                </Text>
                <Text
                  style={{ fontSize: '8px', fontWeight: 700, marginTop: '4px' }}
                >
                  {california_data?.FACILITY_DESC
                    ? california_data?.FACILITY_DESC
                    : '-'}
                </Text>
              </View>
              <View
                style={{
                  flexDirection: 'column',
                  marginTop: '5px',
                  marginLeft: '15px',
                  width: '20%',
                }}
              >
                <Text
                  style={{
                    fontSize: '7.26px',
                    fontWeight: 400,
                    color: alpha('#3E3E3E', 0.8),
                  }}
                >
                  Active Projects
                </Text>
                <Text
                  style={{ fontSize: '8px', fontWeight: 700, marginTop: '4px' }}
                >
                  {california_data?.NO_OF_ACTIVE_PROJECTS
                    ? california_data?.NO_OF_ACTIVE_PROJECTS
                    : '-'}
                </Text>
              </View>
              <View
                style={{
                  flexDirection: 'column',
                  marginTop: '5px',
                  marginLeft: '15px',
                  width: '28%',
                }}
              >
                <Text
                  style={{
                    fontSize: '7.26px',
                    fontWeight: 400,
                    color: alpha('#3E3E3E', 0.8),
                  }}
                >
                  Overall HC Contruction Project Cost
                </Text>
                <Text
                  style={{ fontSize: '8px', fontWeight: 700, marginTop: '4px' }}
                >
                  {california_data?.ACTIVE_PROJECT_COST
                    ? california_data?.ACTIVE_PROJECT_COST.toLocaleString(
                      'en-US',
                      {
                        style: 'currency',
                        currency: 'USD',
                      }
                    )
                    : '-'}
                </Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: 'row',
                height: '50%',
                marginBottom: '10px',
              }}
            >
              <View
                style={{
                  flexDirection: 'column',
                  marginTop: '12px',
                  marginLeft: '10px',
                }}
              >
                <Text
                  style={{
                    fontSize: '7.26px',
                    fontWeight: 400,
                    color: alpha('#3E3E3E', 0.8),
                  }}
                >
                  Count of Buildings by Sesmic Ratings
                </Text>
                <View style={{ flexDirection: 'row', marginTop: '4px' }}>
                  <Text style={{ fontSize: 8, fontWeight: 700 }}>
                    Rating 1: {california_data?.NO_OF_BLDGS_RATING_1} bldgs |
                  </Text>
                  <Text
                    style={{ fontSize: 8, fontWeight: 700, marginLeft: '5px' }}
                  >
                    Rating 2: {california_data?.NO_OF_BLDGS_RATING_2} bldgs |
                  </Text>
                  <Text
                    style={{ fontSize: 8, fontWeight: 700, marginLeft: '5px' }}
                  >
                    Rating 3: {california_data?.NO_OF_BLDGS_RATING_3} bldgs |
                  </Text>
                  <Text
                    style={{ fontSize: 8, fontWeight: 700, marginLeft: '5px' }}
                  >
                    Rating 4: {california_data?.NO_OF_BLDGS_RATING_4} bldgs |
                  </Text>
                  <Text
                    style={{ fontSize: 8, fontWeight: 700, marginLeft: '5px' }}
                  >
                    Rating 5: {california_data?.NO_OF_BLDGS_RATING_5} bldgs{' '}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View
            style={{
              marginTop: '20px',
            }}
          >
            <View>
              <Text
                style={{
                  fontSize: '8px',
                  marginBottom: '10px',
                  fontStyle: 'italic',
                  marginLeft: '5px',
                }}
              >
                Last Updated :{' '}
                {california_table_data && california_table_data.length
                  ? california_table_data[0].last_updated_on
                  : '-'}
              </Text>
            </View>

            <View style={pdfStyle.headerView} fixed>
              {tableData['column1'].map((c) => (
                <Text
                  style={{
                    width: `${100 / tableData['column1'].length}%`,
                    height: `${100 / california_table_data.length}%`,
                    textAlign: 'left',
                    marginLeft: '10px',
                  }}
                >
                  {c}
                </Text>
              ))}
            </View>
            {california_table_data.map((rowData) => (
              <>
                <View style={pdfStyle.rowView} wrap={false}>
                  {tableData['column2'].map((c) => (
                    <Text
                      wrap={false}
                      style={{
                        width: `${100 / tableData['column2'].length}%`,
                        height: `${(100 / california_table_data.length) * 5}%`,
                        textAlign: 'left',
                        paddingLeft:
                          c == 'STORIES' ||
                            c == 'YEAR_COMPLETED' ||
                            c == 'OSHPD_RATING' ||
                            c == 'SPC_RATING'
                            ? '15px'
                            : '10px',
                      }}
                    >
                      {rowData[c] ? rowData[c] : '-'}
                    </Text>
                  ))}
                </View>
              </>
            ))}
          </View>

          <View wrap fixed style={pdfStyle.footer}>
            <View
              style={{
                border: '1px solid #D9D9D9',
                marginTop: '10px',
                width: '520px',
                height: '1px',
                left: '25px',
              }}
            ></View>
            <View
              style={{ flexDirection: 'row', fontSize: 8, marginTop: '10px' }}
            >
              <Text style={{ marginLeft: '30px', fontSize: 10 }}>
                Export Date : {formatedDateTime}
              </Text>
              <Text
                style={{
                  fontWeight: 800,
                  marginLeft: '70px',
                  fontSize: 10,
                }}
              >
                Confidential: Do Not Distribute Outside of DPR
              </Text>
              <Text
                style={{ marginLeft: '110px', fontSize: 10 }}
                render={({ pageNumber }) => `Page ${pageNumber}`}
              />
            </View>
          </View>
        </Page>
      ) : null}
    </Document>
  )

  return (
    <MenuItem
      style={{ color: 'black' }}
      onClick={() => {
        sessionStorage.setItem(
          SSConst.EXPORT_STATUS,
          'IN_PROGRESS'
        )
        ShowSnackBar('Generating Export File, Please wait !!', 'info', 5000, 'close')
        props.getDataFunction().then(() => {
          ReactDOM.render(<DialogAlert />, document.createElement('div'))
          const ExportedData = JSON.parse(sessionStorage.getItem(SSConst.EXPORT_DATA)!)
          let provider_dataExcel =
            ExportedData &&
              ExportedData.providerDetails &&
              ExportedData.providerDetails.length
              ? ExportedData.providerDetails[0]
              : null
          let GeneralInfo_SummaryData =
            ExportedData &&
              ExportedData.generalInfoSummaryData &&
              ExportedData.generalInfoSummaryData.length
              ? ExportedData.generalInfoSummaryData
              : null
          let GeneralInfo_data =
            ExportedData &&
              ExportedData.generalInfoSummaryData &&
              ExportedData.generalInfoSummaryData.length
              ? ExportedData.generalInfoSummaryData
              : null
          let Performance_SummaryData: any =
            ExportedData &&
              ExportedData.performanceData &&
              ExportedData.performanceData.length
              ? ExportedData.performanceData[0]
              : null

          let clinical_outcome_score_data: any =
            ExportedData &&
              ExportedData.clinicalOutcomeScore &&
              ExportedData.clinicalOutcomeScore.length
              ? ExportedData.clinicalOutcomeScore[0]
              : null
          let clinical_outcome_rating_data: any =
            ExportedData &&
              ExportedData.clinicalOutcomeRating &&
              ExportedData.clinicalOutcomeRating.length
              ? ExportedData.clinicalOutcomeRating[0]
              : null

          let facility_conditions_rating_data: any =
            ExportedData &&
              ExportedData.facilityConditionRating &&
              ExportedData.facilityConditionRating.length
              ? ExportedData.facilityConditionRating[0]
              : null
          let facility_conditions_score_data: any =
            ExportedData &&
              ExportedData.facilityConditionScore &&
              ExportedData.facilityConditionScore.length
              ? ExportedData.facilityConditionScore[0]
              : null
          let ratings: any =
            ExportedData &&
              ExportedData.performanceData &&
              ExportedData.performanceData.length
              ? ExportedData.performanceData[0]
              : null

          let fiscal_rating_data: any =
            ExportedData &&
              ExportedData.fiscalStewardshipRating &&
              ExportedData.fiscalStewardshipRating.length
              ? ExportedData.fiscalStewardshipRating[0]
              : null
          let fiscal_score_data: any =
            ExportedData &&
              ExportedData.fiscalStewardshipScore &&
              ExportedData.fiscalStewardshipScore.length
              ? ExportedData.fiscalStewardshipScore[0]
              : null

          let operations_rating_data: any =
            ExportedData &&
              ExportedData.clinicalOperationRating &&
              ExportedData.clinicalOperationRating.length
              ? ExportedData.clinicalOperationRating[0]
              : null
          let operations_score_data: any =
            ExportedData &&
              ExportedData.clinicalOperationScore &&
              ExportedData.clinicalOperationScore.length
              ? ExportedData.clinicalOperationScore[0]
              : null

          let patient_experience_rating_data: any =
            ExportedData &&
              ExportedData.experimentalDesignRating &&
              ExportedData.experimentalDesignRating.length
              ? ExportedData.experimentalDesignRating[0]
              : null
          let patient_experience_score_data: any =
            ExportedData &&
              ExportedData.experimentalDesignScore &&
              ExportedData.experimentalDesignScore.length
              ? ExportedData.experimentalDesignScore[0]
              : null

          let positive_fiscal: any =
            ExportedData &&
              ExportedData.getAllPositiveFactorsforFiscalStewardship &&
              ExportedData.getAllPositiveFactorsforFiscalStewardship.length
              ? ExportedData.getAllPositiveFactorsforFiscalStewardship.map((data) => [
                '',
                data.comment,
              ])
              : [[' ', 'N/A']]
          let negative_fiscal: any =
            ExportedData &&
              ExportedData.getAllNegativeFactorsforFiscalStewardship &&
              ExportedData.getAllNegativeFactorsforFiscalStewardship.length
              ? ExportedData.getAllNegativeFactorsforFiscalStewardship.map((data) => [
                '',
                data.comment,
              ])
              : [[' ', 'N/A']]

          let positive_clinical: any =
            ExportedData &&
              ExportedData.getAllPositiveFactorsforClinicalOperations &&
              ExportedData.getAllPositiveFactorsforClinicalOperations.length
              ? ExportedData.getAllPositiveFactorsforClinicalOperations.map((data) => [
                '',
                data.comment,
              ])
              : [[' ', 'N/A']]
          let negative_clinical: any =
            ExportedData &&
              ExportedData.getAllNegativeFactorsforClinicalOperations &&
              ExportedData.getAllNegativeFactorsforClinicalOperations.length
              ? ExportedData.getAllNegativeFactorsforClinicalOperations.map((data) => [
                '',
                data.comment,
              ])
              : [[' ', 'N/A']]

          let positive_patient_exp: any =
            ExportedData &&
              ExportedData.getAllPositiveFactorsforExperimentalDesign &&
              ExportedData.getAllPositiveFactorsforExperimentalDesign.length
              ? ExportedData.getAllPositiveFactorsforExperimentalDesign.map((data) => [
                '',
                data.comment,
              ])
              : [[' ', 'N/A']]
          let negative_patient_exp: any =
            ExportedData &&
              ExportedData.getAllNegativeFactorsforExperimentalDesign &&
              ExportedData.getAllNegativeFactorsforExperimentalDesign.length
              ? ExportedData.getAllNegativeFactorsforExperimentalDesign.map((data) => [
                '',
                data.comment,
              ])
              : [[' ', 'N/A']]

          let positive_clinical_outcomes: any =
            ExportedData &&
              ExportedData.getAllPositiveFactorsforClinicalOutcome &&
              ExportedData.getAllPositiveFactorsforClinicalOutcome.length
              ? ExportedData.getAllPositiveFactorsforClinicalOutcome.map((data) => [
                '',
                data.comment,
              ])
              : [[' ', 'N/A']]
          let negative_clinical_outcomes: any =
            ExportedData &&
              ExportedData.getAllNegativeFactorsforClinicalOutcome &&
              ExportedData.getAllNegativeFactorsforClinicalOutcome.length
              ? ExportedData.getAllNegativeFactorsforClinicalOutcome.map((data) => [
                '',
                data.comment,
              ])
              : [[' ', 'N/A']]

          let positive_facility_conditions: any =
            ExportedData &&
              ExportedData.getAllPositiveFactorsforFacilityConditions &&
              ExportedData.getAllPositiveFactorsforFacilityConditions.length
              ? ExportedData.getAllPositiveFactorsforFacilityConditions.map((data) => [
                '',
                data.comment,
              ])
              : [[' ', 'N/A']]
          let negative_facility_conditions: any =
            ExportedData &&
              ExportedData.getAllNegativeFactorsforFacilityConditions &&
              ExportedData.getAllNegativeFactorsforFacilityConditions.length
              ? ExportedData.getAllNegativeFactorsforFacilityConditions.map((data) => [
                '',
                data.comment,
              ])
              : [[' ', 'N/A']]

          let OverallPositiveArray = [
            ...(ExportedData &&
              ExportedData.getAllPositiveFactorsforFiscalStewardship &&
              ExportedData.getAllPositiveFactorsforFiscalStewardship.length
              ? ExportedData.getAllPositiveFactorsforFiscalStewardship.map((data) => [
                '',
                data.comment,
              ])
              : [[' ', 'N/A']]),
            ...(ExportedData &&
              ExportedData.getAllPositiveFactorsforClinicalOperations &&
              ExportedData.getAllPositiveFactorsforClinicalOperations.length
              ? ExportedData.getAllPositiveFactorsforClinicalOperations.map((data) => [
                '',
                data.comment,
              ])
              : [[' ', 'N/A']]),
            ...(ExportedData &&
              ExportedData.getAllPositiveFactorsforExperimentalDesign &&
              ExportedData.getAllPositiveFactorsforExperimentalDesign.length
              ? ExportedData.getAllPositiveFactorsforExperimentalDesign.map((data) => [
                '',
                data.comment,
              ])
              : [[' ', 'N/A']]),
            ...(ExportedData &&
              ExportedData.getAllPositiveFactorsforClinicalOutcome &&
              ExportedData.getAllPositiveFactorsforClinicalOutcome.length
              ? ExportedData.getAllPositiveFactorsforClinicalOutcome.map((data) => [
                '',
                data.comment,
              ])
              : [[' ', 'N/A']]),
            ...(ExportedData &&
              ExportedData.getAllPositiveFactorsforFacilityConditions &&
              ExportedData.getAllPositiveFactorsforFacilityConditions.length
              ? ExportedData.getAllPositiveFactorsforFacilityConditions.map((data) => [
                '',
                data.comment,
              ])
              : [[' ', 'N/A']]),
          ]
          OverallPositiveArray[0][0] = 'Positive Factors'
          let OverallNegativeArray = [
            ...(ExportedData &&
              ExportedData.getAllNegativeFactorsforFiscalStewardship &&
              ExportedData.getAllNegativeFactorsforFiscalStewardship.length
              ? ExportedData.getAllNegativeFactorsforFiscalStewardship.map((data) => [
                '',
                data.comment,
              ])
              : [[' ', 'N/A']]),
            ...(ExportedData &&
              ExportedData.getAllNegativeFactorsforClinicalOperations &&
              ExportedData.getAllNegativeFactorsforClinicalOperations.length
              ? ExportedData.getAllNegativeFactorsforClinicalOperations.map((data) => [
                '',
                data.comment,
              ])
              : [[' ', 'N/A']]),
            ...(ExportedData &&
              ExportedData.getAllNegativeFactorsforExperimentalDesign &&
              ExportedData.getAllNegativeFactorsforExperimentalDesign.length
              ? ExportedData.getAllNegativeFactorsforExperimentalDesign.map((data) => [
                '',
                data.comment,
              ])
              : [[' ', 'N/A']]),
            ...(ExportedData &&
              ExportedData.getAllNegativeFactorsforClinicalOutcome &&
              ExportedData.getAllNegativeFactorsforClinicalOutcome.length
              ? ExportedData.getAllNegativeFactorsforClinicalOutcome.map((data) => [
                '',
                data.comment,
              ])
              : [[' ', 'N/A']]),
            ...(ExportedData &&
              ExportedData.getAllNegativeFactorsforFacilityConditions &&
              ExportedData.getAllNegativeFactorsforFacilityConditions.length
              ? ExportedData.getAllNegativeFactorsforFacilityConditions.map((data) => [
                '',
                data.comment,
              ])
              : [[' ', 'N/A']]),
          ]
          OverallNegativeArray[0][0] = 'Risk Factors'

          let stakeHoldersDataExcel = ExportedData?.getStakeholderData

          let california_data =
            ExportedData?.getCaliforniaInfo && ExportedData?.getCaliforniaInfo.length
              ? ExportedData?.getCaliforniaInfo[0]
              : null

          let california_table_data = ExportedData?.getCaliforniaTableData
            ? ExportedData?.getCaliforniaTableData
            : []

          let pairs: any = []
          let i = 0
          for (i = 0; i + 3 <= stakeHoldersDataExcel?.length; i += 3)
            pairs.push([
              stakeHoldersDataExcel[i],
              stakeHoldersDataExcel[i + 1],
              stakeHoldersDataExcel[i + 2],
            ])
          let remaining: any = []
          for (let j = i; j < stakeHoldersDataExcel?.length; j++)
            remaining.push(stakeHoldersDataExcel[j])
          pairs.push(remaining)

          const pdfBlob = pdf(MyDocument(provider_dataExcel, GeneralInfo_data, Performance_SummaryData, OverallPositiveArray, OverallNegativeArray, ratings, fiscal_rating_data, fiscal_score_data, positive_fiscal, negative_fiscal, operations_rating_data, operations_score_data, positive_clinical, negative_clinical, patient_experience_rating_data, patient_experience_score_data, positive_patient_exp, negative_patient_exp, clinical_outcome_rating_data, clinical_outcome_score_data, positive_clinical_outcomes, negative_clinical_outcomes, facility_conditions_rating_data, facility_conditions_score_data, positive_facility_conditions, negative_facility_conditions, stakeHoldersDataExcel, california_data, california_table_data, pairs)).toBlob()
          pdfBlob.then((data) => {
            const blobObj = new Blob([data], { type: 'application/pdf' })
            saveAs(blobObj, 'Healthcare Panorama - Hospital Insights')
          })
        })
      }}
    >
      All Tabs
    </MenuItem>
  )
}
