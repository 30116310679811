import { Grid, Box, Divider, Rating, Stack, Skeleton, Typography, Tooltip } from '@mui/material';
import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import { useGetClinicalOperationRatingQuery, useGetClinicalOperationScoreQuery } from '../../../../../__generated__/graphql';
import { Factors } from './Factors';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { textColor, backgroundColor, performanceText } from '../../../../../config/commonFunction';
import { appInsights } from '../../../../../providers/appInsightProvider';


export interface OperationsProps {
    overall_rating: number | null;
}

export const Operations: React.FC<OperationsProps> = (props: OperationsProps) => {

    const search = useLocation().search;
    const definitive_id = new URLSearchParams(search).get('id');
    const { data: OperationsRatingData, loading: OperationsRatingLoading, error: OperationsRatingError } = useGetClinicalOperationRatingQuery({
        variables: {
            definitive_id
        }
    });

    const { data: OperationsScoreData, loading: OperationsScoreLoading, error: OperationsScoreError } = useGetClinicalOperationScoreQuery({
        variables: {
            definitive_id
        }
    });

    useEffect(() => {
        if (OperationsRatingError instanceof Error) {
            appInsights.trackException({ exception: OperationsRatingError, error: new Error("Failed to fetch Operations Rating.") })
        }
    }, [OperationsRatingError])

    useEffect(() => {
        if (OperationsScoreError instanceof Error) {
            appInsights.trackException({ exception: OperationsScoreError, error: new Error("Failed to fetch Operations Score.") })
        }
    }, [OperationsScoreError])

    let operations_rating_data: any = OperationsRatingData && OperationsRatingData.DEFINITIVEHEALTHCARE_VW_RATINGS_CLINICAL_OPERATIONS && OperationsRatingData.DEFINITIVEHEALTHCARE_VW_RATINGS_CLINICAL_OPERATIONS.length ? OperationsRatingData.DEFINITIVEHEALTHCARE_VW_RATINGS_CLINICAL_OPERATIONS[0] : null;
    let operations_score_data: any = OperationsScoreData && OperationsScoreData.DEFINITIVEHEALTHCARE_VW_SCORE_CLINICAL_OPERATIONS && OperationsScoreData.DEFINITIVEHEALTHCARE_VW_SCORE_CLINICAL_OPERATIONS.length ? OperationsScoreData.DEFINITIVEHEALTHCARE_VW_SCORE_CLINICAL_OPERATIONS[0] : null;

    const TopDivStyle = styled(Box)(() => ({
        borderRadius: "4px",
        padding: "10px 35px 10px 15px",
    }));

    const ClientPerformanceText = styled(Grid)(() => ({
        fontSize: "13px",
        margin: "auto",
        textAlign: "end",
        fontWeight: "600"
    }))

    const HeadingText = styled(Typography)(() => ({
        color: '#3E3E3E',
        fontWeight: '400px',
        fontSize: '13px',
    }))

    const DataText = styled(Typography)(() => ({
        fontWeight: '700',
        fontSize: '14px',
    }))

    return <React.Fragment>
        <Grid container sx={{ margin: "15px 0px" }}>
            <Grid item lg={4.5} md={4.5} >
                {props.overall_rating ?
                    <TopDivStyle sx={{ border: `1px solid ${textColor(Math.round(props.overall_rating))}`, background: backgroundColor(Math.round(props.overall_rating)) }}>
                        <Grid container>
                            <Grid item lg={6} md={6} sx={{ textAlign: "start" }}>
                                <Rating name="read-only" value={Math.round(props.overall_rating)} readOnly sx={{ color: textColor(Math.round(props.overall_rating)) }} />
                            </Grid>
                            <ClientPerformanceText item lg={6} md={6}>
                                {performanceText(Math.round(props.overall_rating))?.replace("(", "").replace(")", "")}
                            </ClientPerformanceText>
                        </Grid>
                    </TopDivStyle>
                    : null}

                {OperationsRatingLoading || OperationsScoreLoading ?
                    <Typography variant='h3' sx={{ margin: "5px" }}>
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                    </Typography>
                    :
                    operations_rating_data && operations_score_data ?
                        <Stack spacing={0.5} sx={{ marginTop: "20px", marginLeft: "10px", overflow: "scroll", height: "45vh", padding: "10px" }}>

                            <Stack direction="row" spacing={0.5}>
                                <HeadingText>Bed Utilization</HeadingText>
                                <Tooltip
                                    title="Average percent of beds occupied by patients. Hospitals must carefully manage occupancy against its ability to function safely and effectively"
                                    placeholder="top"
                                    arrow
                                >
                                    <InfoOutlinedIcon
                                        fontSize="small"
                                        style={{ width: '15px' }}
                                    ></InfoOutlinedIcon>
                                </Tooltip>
                            </Stack>
                            <DataText sx={{
                                color: textColor(Math.round(operations_rating_data?.bed_utilization))
                            }}>
                                {operations_score_data?.bed_utilization || operations_score_data?.bed_utilization === 0 ? (operations_score_data.bed_utilization * 100).toFixed(1) + "%" : "N/A"}
                                {operations_rating_data?.bed_utilization ? performanceText(Math.round(operations_rating_data?.bed_utilization)) : ""}
                            </DataText>

                            <br />

                            <Stack direction="row" spacing={0.5}>
                                <HeadingText>% patients who left the emergency department before being seen rate</HeadingText>
                                <Tooltip
                                    title="Left without being seen (LWBS) rates are often used as an ED performance metric. A high LWBS rate may reflect intrinsic system issues within the ED and/or poor access to timely clinic-based care"
                                    placeholder="top"
                                    arrow
                                >
                                    <InfoOutlinedIcon
                                        fontSize="small"
                                        style={{ width: '15px' }}
                                    ></InfoOutlinedIcon>
                                </Tooltip>
                            </Stack>
                            <DataText sx={{
                                color: textColor(Math.round(operations_rating_data?.pct_left_ed_before_being_seen))
                            }}>
                                {operations_score_data?.pct_left_ed_before_being_seen || operations_score_data?.pct_left_ed_before_being_seen === 0 ? Math.round(operations_score_data.pct_left_ed_before_being_seen) * 100 + "%" : "N/A"}
                                {operations_rating_data?.pct_left_ed_before_being_seen ? performanceText(Math.round(operations_rating_data?.pct_left_ed_before_being_seen)) : ""}
                            </DataText>
                            <br />

                            <Stack direction="row" spacing={0.5}>
                                <HeadingText>Average number of mins spent in the emergency department before discharge</HeadingText>
                                <Tooltip
                                    title="This metric is calculated as the time between registration and discharge from the ED. High lengths of stay (LOS) in the ED may be indicate of failure to optimize flow, hospital-wide overcrowding, and/or poor ED design"
                                    placeholder="top"
                                    arrow
                                >
                                    <InfoOutlinedIcon
                                        fontSize="small"
                                        style={{ width: '15px' }}
                                    ></InfoOutlinedIcon>
                                </Tooltip>
                            </Stack>
                            <DataText sx={{
                                color: textColor(Math.round(operations_rating_data?.mins_in_ed_before_sent_home))
                            }}>
                                {operations_score_data?.mins_in_ed_before_sent_home || operations_score_data?.mins_in_ed_before_sent_home === 0 ? operations_score_data.mins_in_ed_before_sent_home : "N/A"}
                                {operations_rating_data?.mins_in_ed_before_sent_home ? performanceText(Math.round(operations_rating_data?.mins_in_ed_before_sent_home)) : ""}
                            </DataText>
                            <br />

                            <Stack direction="row" spacing={0.5}>
                                <HeadingText>Annual surgeries per Operating Room</HeadingText>
                                <Tooltip
                                    title="Annual number of surgeries per operating room (OR) gives an indication of OR efficiency and capacity. High throughput may indicate high level of efficiency and/or lower acuity of cases and low throughput may indicate excess operating room capacity"
                                    placeholder="top"
                                    arrow
                                >
                                    <InfoOutlinedIcon
                                        fontSize="small"
                                        style={{ width: '15px' }}
                                    ></InfoOutlinedIcon>
                                </Tooltip>
                            </Stack>
                            <DataText sx={{
                                color: textColor(Math.round(operations_rating_data?.surgical_volume_per_or))
                            }}>
                                {operations_score_data?.operating_room_throughput || operations_score_data?.operating_room_throughput === 0 ? Math.round(operations_score_data.operating_room_throughput) : "N/A"}
                                {operations_rating_data?.surgical_volume_per_or ? performanceText(Math.round(operations_rating_data?.surgical_volume_per_or)) : ""}
                            </DataText>


                        </Stack>
                        : null
                }
            </Grid>
            <Grid item lg={0.5} md={0.5}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: "center", height: "50vh" }}>
                    <Divider orientation="vertical" flexItem />
                </Box>
            </Grid>
            <Grid item lg={7} md={7} style={{ overflow: "scroll", height: "50vh" }}>
                <Factors module_name='CLINICAL_OPERATIONS' />
            </Grid>
        </Grid >
    </React.Fragment >
}