export const ServiceAreaTable: any = { // Zip
  column1: [
    'Zip Code',
    'City',
    'County',
    'State',
    'Market Score',
    'Population',
    '5 - Yr Growth',
    'IP 10 - yr growth',
    'OP 10 - yr growth',
  ],
  column2: [
    'zipcode',
    'city',
    'county',
    'state',
    'market_score',
    'total_pop',
    'pop_growth_5yr',
    'ip_growth_10_yr',
    'op_growth_10_yr',
  ]
}

export const DemographicTableData = {
  column1: [
    'Age Cohort',
    'Current Population',
    '5-Yr Projected Population',
    '5-Yr Growth %',
  ],
  column2: ['age_cohort', 'current_pop', 'proj_pop', 'pop_growth'],
}

export const PredictiveNeedsTable = {
  column1: [
    'Service Line',
    'Sub service Line',
    'Current Volume',
    '10 Yr Volume',
    '10 Yr Growth ',
    '5 Yr Volume',
    '5 Yr Growth',
  ],
  column2: [
    'service_line',
    'sub_service_line',
    'vol_current',
    'volume_10_yr',
    'growth_10_yr',
    'volume_5_yr',
    'growth_5_yr',
  ],
}

export const monthArray = [
  ['Jan', '01'],
  ['Feb', '02'],
  ['Mar', '03'],
  ['Apr', '04'],
  ['May', '05'],
  ['Jun', '06'],
  ['Jul', '07'],
  ['Aug', '08'],
  ['Sep', '09'],
  ['Oct', '10'],
  ['Nov', '11'],
  ['Dec', '12'],
]

export const ServiceAreaProvider: any = {
  column1: [
    'Hospital Name',
    // 'Parent Health System',
    'Address',
    'City',
    'State',
    'Beds',
    'Performance Score',
  ],
  column2: [
    'hospital_name',
    // 'parent_health_system',
    'address',
    'city',
    'state',
    'number_of_staffed_beds',
    'total_performance_score',
  ],
}