export const getClientGeneralInfoaoa = (GeneralInfo_SummaryData, GeneralInfo_data) => {
    let aoaGeneralInfo = [['Metrics', 'Value'],
    ['Hospital Type', GeneralInfo_SummaryData[0].hospital_type ? GeneralInfo_SummaryData[0].hospital_type : '-'],
    ['# of Discharges', GeneralInfo_data[0].no_of_discharges ? GeneralInfo_data[0].no_of_discharges : '-'],
    ['# of Staffed Beds', GeneralInfo_data[0].number_of_beds ? GeneralInfo_data[0].number_of_beds : '-'],
    ['# of Licensed Beds', GeneralInfo_data[0].number_of_licensed_beds ? GeneralInfo_data[0].number_of_licensed_beds : '-'],
    ['Bed Uitilization Rate', GeneralInfo_data[0].bed_utilization ? GeneralInfo_data[0].bed_utilization : '-'],
    ['# of Employees', GeneralInfo_SummaryData[0].no_of_employees ? GeneralInfo_SummaryData[0].no_of_employees : '-'],
    ['Number of Interns and Residents', GeneralInfo_SummaryData[0].no_of_interns_and_residents ? GeneralInfo_SummaryData[0].no_of_interns_and_residents : "-"],
    ['Est # of Inpatient Surgeries', GeneralInfo_data[0].est_no_of_inpatient_surgeries ? GeneralInfo_data[0].est_no_of_inpatient_surgeries : '-'],
    ['Est # of Outpatient Surgeries', GeneralInfo_data[0].est_no_of_outpatient_surgeries ? GeneralInfo_data[0].est_no_of_outpatient_surgeries : '-'],
    ['Est # of Total Surgeries', GeneralInfo_data[0].est_no_of_total_surgeries ? GeneralInfo_data[0].est_no_of_total_surgeries : '-'],
    ['Est # of Outpatient Visits', GeneralInfo_data[0].est_no_of_outpatient_visits ? GeneralInfo_data[0].est_no_of_outpatient_visits : '-'],
    ['Est # of ER Visits', GeneralInfo_data[0].est_no_of_er_visits ? GeneralInfo_data[0].est_no_of_er_visits : '-'],
    ['Number of Births', GeneralInfo_data[0].no_of_births ? GeneralInfo_data[0].no_of_births : '-'],
    ['Number of Operating Rooms', GeneralInfo_SummaryData[0].no_of_operating_rooms ? GeneralInfo_SummaryData[0].no_of_operating_rooms : '-'],
    ['Total Facility Square Footage', GeneralInfo_SummaryData[0].total_facility_square_footage ? GeneralInfo_SummaryData[0].total_facility_square_footage : '-'],
    ['Average Age of Facility (In Years)', GeneralInfo_data[0].avg_age_of_plant ? GeneralInfo_data[0].avg_age_of_plant : '-'],
    ['Inpatient Cost Center Square Footage', GeneralInfo_data[0].inpatient_sq_footage ? GeneralInfo_data[0].inpatient_sq_footage : '-'],
    ['Outpatient Cost Center Square Footage', GeneralInfo_data[0].outpatient_sq_footage ? GeneralInfo_data[0].outpatient_sq_footage : '-'],
    ['Intensive Care Unit Beds', GeneralInfo_data[0].intensive_care_unit_beds ? GeneralInfo_data[0].intensive_care_unit_beds : '-'],
    ['Neonatal Intensive Care Unit Beds', GeneralInfo_data[0].nicu_beds ? GeneralInfo_data[0].nicu_beds : '-'],
    ['Electronic Health/Medical Record-Inpatient', GeneralInfo_data[0].electronic_health_medical_record_inpatient ? GeneralInfo_data[0].electronic_health_medical_record_inpatient : '-'],
    ['Electronic Health/Medical Record-Ambulatory', GeneralInfo_data[0].electronic_health_medical_record_ambulatory ? GeneralInfo_data[0].electronic_health_medical_record_ambulatory : '-'],
    ['Computerized Physician Order Entry (CPOE)', GeneralInfo_data[0].computerized_physician_order_entry ? GeneralInfo_data[0].computerized_physician_order_entry : '-'],
    ['Population Health Management', GeneralInfo_data[0].population_health_management ? GeneralInfo_data[0].population_health_management : '-'],
    ['Emergency Department Information System', GeneralInfo_data[0].emergency_department_information_system ? GeneralInfo_data[0].emergency_department_information_system : '-'],
    ['Cardiology Information System', GeneralInfo_data[0].cardiology_information_system ? GeneralInfo_data[0].cardiology_information_system : '-'],
    ['Revenue Cycle Management', GeneralInfo_data[0].revenue_cycle_management ? GeneralInfo_data[0].revenue_cycle_management : '-'],
    ['Chief Executive Officer', GeneralInfo_data[0].chief_executive_officer ? GeneralInfo_data[0].chief_executive_officer : '-'],
    ['Cheif Executive Officer Title', GeneralInfo_data[0].chief_executive_officer_title ? GeneralInfo_data[0].chief_executive_officer_title : '-'],
    ['Chief Executive Officer Email', GeneralInfo_data[0].chief_executive_officer_email ? GeneralInfo_data[0].chief_executive_officer_email : '-'],
    ['Chief Executive Officer Phone', GeneralInfo_data[0].chief_executive_officer_phone ? GeneralInfo_data[0].chief_executive_officer_phone : '-'],
    ['Chief Financial Officer', GeneralInfo_data[0].chief_financial_officer ? GeneralInfo_data[0].chief_financial_officer : '-'],
    ['Chiel Financial Officer Title', GeneralInfo_data[0].chief_financial_officer_title ? GeneralInfo_data[0].chief_financial_officer_title : '-'],
    ['Chief Financial Officer Email', GeneralInfo_data[0].chief_financial_officer_email ? GeneralInfo_data[0].chief_financial_officer_email : '-'],
    ['Chief Financial Officer Phone', GeneralInfo_data[0].chief_financial_officer_phone ? GeneralInfo_data[0].chief_financial_officer_phone : '-'],
    ['Chief Information Officer', GeneralInfo_data[0].chief_information_officer ? GeneralInfo_data[0].chief_information_officer : '-'],
    ['Chief Information Officer Title', GeneralInfo_data[0].chief_information_officer_title ? GeneralInfo_data[0].chief_information_officer_title : '-'],
    ['Chief Information Officer Email', GeneralInfo_data[0].chief_information_officer_email ? GeneralInfo_data[0].chief_information_officer_email : '-'],
    ['Chief Information Officer Phone', GeneralInfo_data[0].chief_information_officer_phone ? GeneralInfo_data[0].chief_information_officer_phone : '-'],
    ['Chief Medical Officer', GeneralInfo_data[0].chief_medical_officer ? GeneralInfo_data[0].chief_medical_officer : '-'],
    ['Chief Medical Officer Title', GeneralInfo_data[0].chief_medical_officer_title ? GeneralInfo_data[0].chief_medical_officer_title : '-'],
    ['Chief Medical Officer Email', GeneralInfo_data[0].chief_medical_officer_email ? GeneralInfo_data[0].chief_medical_officer_email : '-'],
    ['Chief Medical Officer Phone', GeneralInfo_data[0].chief_medical_officer_phone ? GeneralInfo_data[0].chief_medical_officer_phone : '-'],
    ['Chief Nursing Officer', GeneralInfo_data[0].chief_nursing_officer ? GeneralInfo_data[0].chief_nursing_officer : '-'],
    ['Chief Nursing Officer Title', GeneralInfo_data[0].chief_nursing_officer_title ? GeneralInfo_data[0].chief_nursing_officer_title : '-'],
    ['Chief Nursing Officer Email', GeneralInfo_data[0].chief_nursing_officer_email ? GeneralInfo_data[0].chief_nursing_officer_email : '-'],
    ['Chief Nursing Officer Phone', GeneralInfo_data[0].chief_nursing_officer_phone ? GeneralInfo_data[0].chief_nursing_officer_phone : '-'],
    ['HIM Director', GeneralInfo_data[0].him_director ? GeneralInfo_data[0].him_director : '-'],
    ['HIM Director Title', GeneralInfo_data[0].him_director_title ? GeneralInfo_data[0].him_director_title : '-'],
    ['HIM Director Email', GeneralInfo_data[0].him_director_email ? GeneralInfo_data[0].him_director_email : '-'],
    ['HIM Director Phone', GeneralInfo_data[0].him_director_phone ? GeneralInfo_data[0].him_director_phone : '-'],
    ['Director of IT', GeneralInfo_data[0].director_of_it ? GeneralInfo_data[0].director_of_it : '-'],
    ['Director of IT Title', GeneralInfo_data[0].director_of_it_title ? GeneralInfo_data[0].director_of_it_title : '-'],
    ['Director of Email', GeneralInfo_data[0].director_of_it_email ? GeneralInfo_data[0].director_of_it_email : '-'],
    ['Director of Phone', GeneralInfo_data[0].director_of_it_phone ? GeneralInfo_data[0].director_of_it_phone : '-']]
    return aoaGeneralInfo;
}