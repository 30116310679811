import { Configuration, RedirectRequest } from '@azure/msal-browser';
import axios from 'axios';

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    authority:
      'https://login.microsoftonline.com/3ea4ee53-5e07-4a30-bd0d-e464769a4f4f',
    clientId: '4de2bae1-b053-40c4-aede-acdf15535199',
    postLogoutRedirectUri: window.location.origin,
    redirectUri: window.location.origin,
    // After being redirected to the "redirectUri" page, should user
    // be redirected back to the Url where their login originated from?
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
}

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: RedirectRequest = {
  scopes: [
    'api://a25f5b47-ff48-405b-9410-f05d13485bb7/access_as_user',
    'User.Read',
    'openid',
    'email',
    'profile'
  ],
}

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
  graphUserEndpoint: 'https://graph.microsoft.com/v1.0/users',
}

export async function GetUserProfile(accessToken: string) {
  const headers = new Headers()
  const bearer = `Bearer ${accessToken}`

  headers.append('Authorization', bearer)

  const options = {
    method: 'GET',
    headers,
  }

  const response = await fetch(graphConfig.graphMeEndpoint, options)
  return response.json()
}


export const graphLoginRequest: RedirectRequest = {
  scopes: ['Group.Read.All', 'User.Read', 'openid', 'email', 'profile', 'Sites.Read.All'],
}


export async function GetUserPhoto(accessToken: string) {
  const response = await axios({
    method: 'get',
    url: 'https://graph.microsoft.com/v1.0/me/photos/120x120/$value',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    responseType: 'arraybuffer',
  })


  //@ts-ignore
  return btoa(String.fromCharCode.apply(null, new Uint8Array(response.data ?? [])))//Buffer.from(response.data, 'binary').toString('base64')
}