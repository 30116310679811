import { styled, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

interface ILoaderProps {
  message: string;
  top?: string;
}
export const Loader: React.FC<ILoaderProps> = (props: ILoaderProps) => {
  const LoaderText = styled(Typography)(() => ({
    textAlign: 'center',
    marginTop: '5px',
    fontWeight: '500',
    fontSize: '16px',
  }))
  return (
    <Box style={{ width: '100%' }}>
      <img
        src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PHN2ZyB4bWxuczpzdmc9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjAiIHdpZHRoPSI2NHB4IiBoZWlnaHQ9IjY0cHgiIHZpZXdCb3g9IjAgMCAxMjggMTI4IiB4bWw6c3BhY2U9InByZXNlcnZlIj48Zz48cGF0aCBmaWxsPSIjNGNhZjUwIiBkPSJNNjQsMTI4YTY0LDY0LDAsMSwxLDY0LTY0QTY0LDY0LDAsMCwxLDY0LDEyOFpNNjQsMi43NUE2MS4yNSw2MS4yNSwwLDEsMCwxMjUuMjUsNjQsNjEuMjUsNjEuMjUsMCwwLDAsNjQsMi43NVoiLz48cGF0aCBmaWxsPSIjNGNhZjUwIiBkPSJNNjQgMTI4YTY0IDY0IDAgMSAxIDY0LTY0IDY0IDY0IDAgMCAxLTY0IDY0ek02NCAyLjc1QTYxLjIgNjEuMiAwIDAgMCAzLjM0IDcyLjRjMS4yOC0zLjUyIDMuOS02LjMyIDcuNS02Ljg2IDYuNTUtMSAxMS45IDIuNjMgMTMuNiA4LjA4IDMuNTIgMTEuMjcuNSAyMyAxNSAzNS4yNSAxOS40NyAxNi40NiA0MC4zNCAxMy41NCA1Mi44NCA5LjQ2QTYxLjI1IDYxLjI1IDAgMCAwIDY0IDIuNzV6Ii8+PGFuaW1hdGVUcmFuc2Zvcm0gYXR0cmlidXRlTmFtZT0idHJhbnNmb3JtIiB0eXBlPSJyb3RhdGUiIGZyb209IjAgNjQgNjQiIHRvPSIzNjAgNjQgNjQiIGR1cj0iMTIwMG1zIiByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSI+PC9hbmltYXRlVHJhbnNmb3JtPjwvZz48L3N2Zz4K"
        alt="loader"
        style={{
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '5%',
          marginTop: props.top ? props.top : '45vh',
        }}
      />

      <LoaderText>{props.message}</LoaderText>
    </Box>
  )
}
