import {
  Button,
  Grid,
  Menu,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  SelectChangeEvent,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from 'material-ui-popup-state/hooks'
import { Box } from '@mui/system'
import { appInsights } from '../../../../providers/appInsightProvider'

interface ServiceAreaFilterButtonProps {
  mapview: string
  ageBracket: string
  getFilterMapdata: any
  setMapview: any
  setAgeBracket: any
}

export const ServiceAreaFilterButton: React.FC<ServiceAreaFilterButtonProps> = (
  props: ServiceAreaFilterButtonProps
) => {
  const MapmenuPopupState = usePopupState({
    variant: 'popover',
    popupId: 'aboutMenu',
  })
  const AgeMenuPopupState = usePopupState({
    variant: 'popover',
    popupId: 'aboutMenu',
  })

  const [map, setMap] = React.useState('Current Population Density')
  const [ageBracket, setageBracket] = React.useState('All')

  const mapHandleChange = (event: SelectChangeEvent) => {
    setMap(event.target.value as string)
  }

  const ageHandleChange = (event: SelectChangeEvent) => {
    setageBracket(event.target.value as string)
  }

  useEffect(() => {
    props.getFilterMapdata(props.mapview, props.ageBracket)
  }, [props.mapview, props.ageBracket])

  return (
    <div style={{ display: 'flex' }}>
      <FormControl size="small"
        style={{
          width: 'auto',
          marginTop: '4px',
          marginLeft: '7px',
        }}
      >
        <InputLabel id="demo-simple-select-label" style={{ fontSize: '14px', fontWeight: "600" }}>
          Select Map View
        </InputLabel>
        <Select
          variant="outlined"
          label="Select Map View"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          onChange={mapHandleChange}
          defaultValue={map}
          value={map}
          style={{ fontSize: '14px' }}
        >
          <MenuItem
            onClick={() => {
              props.setMapview('CurrentPopulation')
              MapmenuPopupState.close()
              appInsights.trackEvent({ name: 'Current Population Filter' })
            }}
            style={{
              backgroundColor:
                props.mapview === 'CurrentPopulation' ? '#eee' : 'transparent',
              fontSize: '13px',
            }}
            value="Current Population Density"
          >
            Current Population Density
          </MenuItem>
          <MenuItem
            onClick={() => {
              props.setMapview('ProjectPopulation')
              MapmenuPopupState.close()
              appInsights.trackEvent({
                name: '5-Year Projected Population Density Filter',
              })
            }}
            style={{
              backgroundColor:
                props.mapview === 'ProjectPopulation' ? '#eee' : 'transparent',
              fontSize: '13px',
            }}
            value="5-Year Projected Population Density"
          >
            5-Year Projected Population Density
          </MenuItem>
          <MenuItem
            onClick={() => {
              props.setMapview('GrowthRate')
              MapmenuPopupState.close()
              appInsights.trackEvent({ name: '5-Year Growth Rate Filter' })
            }}
            style={{
              backgroundColor:
                props.mapview === 'GrowthRate' ? '#eee' : 'transparent',
              fontSize: '13px',
            }}
            value="5-Year Growth Rate"
          >
            5-Year Growth Rate
          </MenuItem>
        </Select>
      </FormControl>

      <FormControl size="small"
        style={{ width: '162px', marginTop: '4px', marginLeft: '10px' }}
      >
        <InputLabel id="Age-Bracket-Label" style={{ fontSize: '14px', fontWeight: "600" }}>
          Select Age Bracket
        </InputLabel>
        <Select
          variant="outlined"
          label="Select Age Bracket"
          labelId="Age-Bracket-Label"
          onChange={ageHandleChange}
          value={ageBracket}
          defaultValue={ageBracket}
          style={{ fontSize: '14px' }}
        >
          <MenuItem
            onClick={() => {
              props.setAgeBracket('All')
              sessionStorage.setItem('Demo_age_group', 'All')
              AgeMenuPopupState.close()
              appInsights.trackEvent({ name: 'All Age Bracket Filter' })
            }}
            style={{
              backgroundColor:
                props.ageBracket === 'All' ? '#eee' : 'transparent',
              fontSize: '13px',
            }}
            value="All"
          >
            All
          </MenuItem>
          <MenuItem
            onClick={() => {
              props.setAgeBracket('Pediatric')
              sessionStorage.setItem('Demo_age_group', 'Pediatric')
              AgeMenuPopupState.close()
              appInsights.trackEvent({ name: 'Pediatric Filter' })
            }}
            style={{
              backgroundColor:
                props.ageBracket === 'Pediatric' ? '#eee' : 'transparent',
              fontSize: '13px',
            }}
            value="Pediatric (0-19)"
          >
            Pediatric (0-19)
          </MenuItem>
          <MenuItem
            onClick={() => {
              props.setAgeBracket('Adult')
              sessionStorage.setItem('Demo_age_group', 'Adult')
              AgeMenuPopupState.close()
              appInsights.trackEvent({ name: 'Adult Filter' })
            }}
            style={{
              backgroundColor:
                props.ageBracket === 'Adult' ? '#eee' : 'transparent',
              fontSize: '13px',
            }}
            value="Adult (20-64)"
          >
            Adult (20-64)
          </MenuItem>
          <MenuItem
            onClick={() => {
              props.setAgeBracket('Geriatric')
              sessionStorage.setItem('Demo_age_group', 'Geriatric')
              AgeMenuPopupState.close()
              appInsights.trackEvent({ name: 'Geriatric Filter' })
            }}
            style={{
              backgroundColor:
                props.ageBracket === 'Geriatric' ? '#eee' : 'transparent',
              fontSize: '13px',
            }}
            value="Geriatric (65+)"
          >
            Geriatric (65+)
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  )
}
