import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import React from 'react'
import { usePopupState } from 'material-ui-popup-state/hooks'
import { appInsights } from '../../../../providers/appInsightProvider'

interface PredictiveNeedsFilterButtonProps {
  siteOfCare: string
  ageBracket: string
  serviceLine: string
  year: string
  setSiteOfCare: Function
  setAgeBracket: Function
  setServiceLine: Function
  setYear: Function
  uniqueServiceLine: string[]
}

export const PredictiveNeedsFilterButton: React.FC<PredictiveNeedsFilterButtonProps> = (
  props: PredictiveNeedsFilterButtonProps
) => {
  const SiteOfCaremenuPopupState = usePopupState({
    variant: 'popover',
    popupId: 'aboutMenu',
  })
  const AgeMenuPopupState = usePopupState({
    variant: 'popover',
    popupId: 'aboutMenu',
  })

  const ServiceLineMenuPopupState = usePopupState({
    variant: 'popover',
    popupId: 'aboutMenu',
  })
  const YearMenuPopupState = usePopupState({
    variant: 'popover',
    popupId: 'aboutMenu',
  })

  const [ageBracket, setageBracket] = React.useState(
    'Adult,Geriatric,Pediatric'
  )
  const [siteOfCare, setsiteOfCare] = React.useState('Inpatient')
  const [serviceLine, setServiceLine] = React.useState('all')
  const [Year, setYear] = React.useState('10-Year Projected')

  const ageHandleChange = (event: SelectChangeEvent) => {
    setageBracket(event.target.value as string)
  }

  const siteOfCareHandleChange = (event: SelectChangeEvent) => {
    setServiceLine("all")
    setsiteOfCare(event.target.value as string)
  }

  const serviceLineHandleChange = (event: SelectChangeEvent) => {
    setServiceLine(event.target.value as string)
  }
  const yearHandleChange = (event: SelectChangeEvent) => {
    setYear(event.target.value as string)
  }

  return (
    <div style={{ display: 'flex', marginBottom: '5px' }}>
      <FormControl size="small"
        style={{ width: '162px', marginTop: '4px', marginLeft: '7px' }}
      >
        <InputLabel id="Age-Bracket-Label" style={{ fontSize: '14px', fontWeight: "600" }}>
          Select Age Bracket
        </InputLabel>
        <Select
          variant="outlined"
          label="Select Age Bracket"
          labelId="Age-Bracket-Label"
          onChange={ageHandleChange}
          value={ageBracket}
          defaultValue={ageBracket}
          style={{ fontSize: '14px' }}
        >
          <MenuItem
            onClick={() => {
              props.setAgeBracket('Adult,Geriatric,Pediatric')
              sessionStorage.setItem(
                'Demo_age_group',
                'Adult,Geriatric,Pediatric'
              )
              AgeMenuPopupState.close()
              appInsights.trackEvent({ name: 'All Age Bracket Filter' })
            }}
            style={{
              backgroundColor:
                props.ageBracket === 'Adult,Geriatric,Pediatric'
                  ? '#eee'
                  : 'transparent',
              fontSize: '13px',
            }}
            value="Adult,Geriatric,Pediatric"
          >
            All
          </MenuItem>
          <MenuItem
            onClick={() => {
              props.setAgeBracket('Pediatric')
              sessionStorage.setItem('Demo_age_group', 'Pediatric')
              AgeMenuPopupState.close()
              appInsights.trackEvent({ name: 'Pediatric Filter' })
            }}
            style={{
              backgroundColor:
                props.ageBracket === 'Pediatric' ? '#eee' : 'transparent',
              fontSize: '13px',
            }}
            value="Pediatric (0-19)"
          >
            Pediatric (0-19)
          </MenuItem>
          <MenuItem
            onClick={() => {
              props.setAgeBracket('Adult')
              sessionStorage.setItem('Demo_age_group', 'Adult')
              AgeMenuPopupState.close()
              appInsights.trackEvent({ name: 'Adult Filter' })
            }}
            style={{
              backgroundColor:
                props.ageBracket === 'Adult' ? '#eee' : 'transparent',
              fontSize: '13px',
            }}
            value="Adult (20-64)"
          >
            Adult (20-64)
          </MenuItem>
          <MenuItem
            onClick={() => {
              props.setAgeBracket('Geriatric')
              sessionStorage.setItem('Demo_age_group', 'Geriatric')
              AgeMenuPopupState.close()
              appInsights.trackEvent({ name: 'Geriatric Filter' })
            }}
            style={{
              backgroundColor:
                props.ageBracket === 'Geriatric' ? '#eee' : 'transparent',
              fontSize: '13px',
            }}
            value="Geriatric (65+)"
          >
            Geriatric (65+)
          </MenuItem>
        </Select>
      </FormControl>

      <FormControl size="small"
        style={{
          width: 'auto',
          marginTop: '4px',
          marginLeft: '7px',
        }}
      >
        <InputLabel id="Site-of-Care-Label" style={{ fontSize: '14px', fontWeight: "600" }}>
          Site of Care
        </InputLabel>
        <Select
          variant="outlined"
          labelId="Site-of-Care-Label"
          onChange={siteOfCareHandleChange}
          value={siteOfCare}
          defaultValue={siteOfCare}
          style={{ fontSize: '14px' }}
          label="Site of Care"
        >
          <MenuItem
            onClick={() => {
              props.setSiteOfCare('asc')
              props.setServiceLine('all')
              SiteOfCaremenuPopupState.close()
              appInsights.trackEvent({
                name: 'County | Ambulatory Surgery Center Filter',
              })
            }}
            value="Ambulatory Surgery Center"
            style={{
              backgroundColor:
                props.siteOfCare === 'asc' ? '#eee' : 'transparent',
              fontSize: '13px',
            }}
          >
            Ambulatory Surgery Center
          </MenuItem>
          <MenuItem
            onClick={() => {
              props.setSiteOfCare('cancer')
              props.setServiceLine('all')
              SiteOfCaremenuPopupState.close()
              appInsights.trackEvent({ name: 'County | Cancer Center Filter' })
            }}
            style={{
              backgroundColor:
                props.siteOfCare === 'cancer' ? '#eee' : 'transparent',
              fontSize: '13px',
            }}
            value="Cancer Center"
          >
            Cancer Center
          </MenuItem>
          <MenuItem
            onClick={() => {
              props.setSiteOfCare('ed')
              props.setServiceLine('all')
              SiteOfCaremenuPopupState.close()
              appInsights.trackEvent({
                name: 'County | Emergency Department Filter',
              })
            }}
            value="Emergency Department"
            style={{
              backgroundColor:
                props.siteOfCare === 'ed' ? '#eee' : 'transparent',
              fontSize: '13px',
            }}
          >
            Emergency Department
          </MenuItem>
          <MenuItem
            onClick={() => {
              props.setSiteOfCare('inpatient')
              props.setServiceLine('all')
              SiteOfCaremenuPopupState.close()
              appInsights.trackEvent({ name: 'County | Inpatient Filter' })
            }}
            style={{
              backgroundColor:
                props.siteOfCare === 'inpatient' ? '#eee' : 'transparent',
              fontSize: '13px',
            }}
            value="Inpatient"
          >
            Inpatient
          </MenuItem>
          <MenuItem
            onClick={() => {
              props.setSiteOfCare('clinic')
              props.setServiceLine('all')
              SiteOfCaremenuPopupState.close()
              appInsights.trackEvent({ name: 'County | MOB/Clinic Filter' })
            }}
            value="MOB/Clinic"
            style={{
              backgroundColor:
                props.siteOfCare === 'clinic' ? '#eee' : 'transparent',
              fontSize: '13px',
            }}
          >
            MOB/Clinic
          </MenuItem>

          <MenuItem
            onClick={() => {
              props.setSiteOfCare('outpatient')
              props.setServiceLine('all')
              SiteOfCaremenuPopupState.close()
              appInsights.trackEvent({
                name: 'County | Outpatient Overall Filter',
              })
            }}
            value="Outpatient Overall"
            style={{
              backgroundColor:
                props.siteOfCare === 'outpatient' ? '#eee' : 'transparent',
              fontSize: '13px',
            }}
          >
            Outpatient Overall
          </MenuItem>
        </Select>
      </FormControl>

      <FormControl size="small"
        style={{
          width: '160px',
          marginTop: '4px',
          marginLeft: '7px',
        }}
      >
        <InputLabel id="ServiceLine-Label" style={{ fontSize: '14px', fontWeight: "600" }}>
          Select Service Line
        </InputLabel>

        <Select
          variant="outlined"
          labelId="ServiceLine-Label"
          onChange={serviceLineHandleChange}
          value={serviceLine}
          defaultValue={serviceLine}
          style={{ fontSize: '14px' }}
          label="Select Service Line"
          MenuProps={{
            style: {
              height: '60vh',
            }
          }}
        >
          <MenuItem
            onClick={() => {
              props.setServiceLine('all')
              ServiceLineMenuPopupState.close()
              appInsights.trackEvent({
                name: 'County | All | Service Line |  Filter',
              })
            }}
            value="all"
            style={{
              backgroundColor:
                props.serviceLine === 'all' ? '#eee' : 'transparent',
              fontSize: '13px',
            }}
          >
            All
          </MenuItem>
          {props.uniqueServiceLine && props.uniqueServiceLine.length
            ? props.uniqueServiceLine.map((service_line) => {
              if (service_line != ' Sum') {
                return (
                  <MenuItem
                    onClick={() => {
                      props.setServiceLine(service_line)
                      ServiceLineMenuPopupState.close()
                      appInsights.trackEvent({
                        name: `County | ${service_line} Filter`,
                      })
                    }}
                    style={{
                      backgroundColor:
                        props.serviceLine === service_line
                          ? '#eee'
                          : 'transparent',
                      fontSize: '13px',
                    }}
                    value={service_line}
                  >
                    {service_line}
                  </MenuItem>
                )
              }
            })
            : null}
        </Select>
      </FormControl>

      <FormControl size="small"
        style={{
          width: 'auto',
          marginTop: '4px',
          marginLeft: '7px',
        }}
      >
        <InputLabel id="Select-Year-Label" style={{ fontSize: '14px', fontWeight: "600" }}>
          Select Year
        </InputLabel>
        <Select
          variant="outlined"
          label="Select Year"
          labelId="Select-Year-Label"
          onChange={yearHandleChange}
          value={Year}
          defaultValue={Year}
          style={{ fontSize: '14px' }}
        >
          <MenuItem
            onClick={() => {
              props.setYear('Current')
              YearMenuPopupState.close()
              appInsights.trackEvent({ name: 'County | Current Year Filter' })
            }}
            style={{
              backgroundColor:
                props.year === 'Current' ? '#eee' : 'transparent',
              fontSize: '13px',
            }}
            value="Current"
          >
            Current
          </MenuItem>
          <MenuItem
            onClick={() => {
              props.setYear('5-YearProjected')
              YearMenuPopupState.close()
              appInsights.trackEvent({
                name: 'County | 5-Year Projected Filter',
              })
            }}
            style={{
              backgroundColor:
                props.year === '5-YearProjected' ? '#eee' : 'transparent',
              fontSize: '13px',
            }}
            value="5-Year Projected"
          >
            5-Year Projected
          </MenuItem>
          <MenuItem
            onClick={() => {
              props.setYear('10-YearProjected')
              YearMenuPopupState.close()
              appInsights.trackEvent({
                name: 'County | 10-Year Projected Filter',
              })
            }}
            style={{
              backgroundColor:
                props.year === '10-YearProjected' ? '#eee' : 'transparent',
              fontSize: '13px',
            }}
            value="10-Year Projected"
          >
            10-Year Projected
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  )
}
