import { useIsAuthenticated } from '@azure/msal-react';
import { useEffect, useState } from 'react';

export function useAccessToken() {
    const [accessToken, setAccessToken] = useState<string | null>(null)
    const isAuthenticated = useIsAuthenticated()
    setInterval(() => {
        if (isAuthenticated) {
            setAccessToken(sessionStorage.getItem("user_accessToken") ?? null)
        }
    }, 1000)

    return accessToken
}
