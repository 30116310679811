import { Box, Grid, Stack, styled } from '@mui/material'
import React from 'react'
import AboutLogo from '../../../Assets/bg_about.webp'

export const AboutPanorama: React.FC = () => {

    const BackGroundImage = styled(Box)(() => ({
        backgroundImage: `url(${AboutLogo})`,
        backgroundRepeat: 'no-repeat',
        height: '78%',
        width: '95%',
        position: 'relative',
        backgroundSize: "100%",
    }));

    const StyledGrid = styled(Grid)(() => ({
        height: '65vh', width: '50vw'
    }))

    const StyledBox = styled(Box)(() => ({
        marginRight: '20px', marginLeft: '30px'
    }))

    return <React.Fragment>
        <StyledBox>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} alignItems={'top'}>
                <StyledGrid item lg={6} md={12} sm={12} sx={{ marginTop: '7px' }}>
                    <BackGroundImage></BackGroundImage>
                </StyledGrid>
                <StyledGrid item lg={6} md={12} sm={12}>
                    <>
                        <div style={{ color: '#22577A', fontWeight: 600 }}>Panorama is an on-demand analytics tool that utilizes a proprietary scoring methodology to quickly assess a client or market's performance. Panorama seeks to optimize how DPR teams pursue opportunities while strengthening our understanding of current partnerships and prospects.</div>
                        <br></br>
                        <div>Goals of the tool include:</div>
                        <div></div>
                        <ul>
                            <li>Understanding the built needs of a market well before the capital planning process is initiated</li>
                            <li>Reducing risk by helping our clients make better, informed decisions</li>
                            <li>Enhancing our annual business planning process by providing inputs needed for strategic decision making</li>
                            <li>Differentiating our pursuits with a data-informed understanding of client’s strategic and business objectives</li>
                            <li>Engaging in conversations with the C-Suite about their strategic planning process</li>
                        </ul>
                        <br></br>
                        <div style={{ color: '#22577A', fontWeight: 600 }}>Panorama is an internal tool, intended for internal use only. The outputs of this tool should not be shared outside of the company.</div>
                    </>
                </StyledGrid>
            </Grid>
        </StyledBox>
    </React.Fragment>
}

export default AboutPanorama
