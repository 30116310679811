import {
  Box,
  Divider,
  Grid,
  Skeleton,
  styled,
  Tooltip,
  Typography,
} from '@mui/material'
import { Stack } from '@mui/system'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useGetGeneralInfoDataQuery } from '../../../../../__generated__/graphql'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { appInsights } from '../../../../../providers/appInsightProvider'

export const KeyClinicalMetrics: React.FC = () => {
  const search = useLocation().search
  const definitive_id = new URLSearchParams(search).get('id')
  //definitive_id = '842956'

  const {
    data: GeneralInfo,
    loading: loadingGeneralinfo,
    error: errorGeneralInfo,
  } = useGetGeneralInfoDataQuery({
    variables: {
      definitive_id,
    },
  })

  useEffect(() => {
    if (errorGeneralInfo instanceof Error) {
      appInsights.trackException({ exception: errorGeneralInfo, error: new Error("Failed to fetch Key Clinical Metric data.") })
    }
  }, [errorGeneralInfo])

  let GeneralInfo_data =
    GeneralInfo &&
      GeneralInfo.DEFINITIVEHEALTHCARE_VW_HOSPITAL_INFO &&
      GeneralInfo.DEFINITIVEHEALTHCARE_VW_HOSPITAL_INFO.length
      ? GeneralInfo.DEFINITIVEHEALTHCARE_VW_HOSPITAL_INFO[0]
      : null


  const HeadingText = styled(Typography)(() => ({
    color: '#3E3E3E',
    fontWeight: '400px',
    fontSize: '12px',
  }))

  const DataText = styled(Typography)(() => ({
    color: '#3E3E3E',
    fontWeight: '700',
    fontSize: '14px',
  }))

  const KeyClinicalMetrics = styled(Box)(() => ({
    color: ' #22577A',
    fontWeight: '700',
    fontSize: '1.2rem',
    margin: '10px',
  }))

  return (
    <Box>

      <Grid
        spacing={2}
        style={{
          margin: '10px', // Temporary
          height: '75vh',
          border: '2px solid #E8F5E9',
          borderRadius: '10px',
        }}
      >
        <Grid lg={12} md={12} item>
          <KeyClinicalMetrics>Key Clinical Metrics</KeyClinicalMetrics>
        </Grid>

        <Grid lg={12} md={12} item>
          <Divider
            orientation="horizontal"
            style={{
              borderColor: '#E8F5E9',
              margin: '10px',
            }}
          />
        </Grid>
        {
          loadingGeneralinfo ? (
            <Typography variant='h3' sx={{ margin: "10px" }}>
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </Typography>
          ) : GeneralInfo_data ?
            <Grid>
              <Grid
                spacing={2}
                container
                style={{
                  margin: '15px',
                }}
              >
                <Grid lg={6} md={6} spacing={2}>
                  <Stack spacing={0.5}>
                    <Stack direction="row" spacing={0.5}>
                      <HeadingText>No. of staffed beds</HeadingText>

                      <Tooltip
                        title="Number of beds that are set-up, staffed, and ready for use"
                        placeholder="top"
                        arrow
                      >
                        <InfoOutlinedIcon
                          fontSize="small"
                          style={{ width: '15px' }}
                        ></InfoOutlinedIcon>
                      </Tooltip>
                    </Stack>
                    <DataText>
                      {GeneralInfo_data.number_of_beds != null
                        ? parseInt(GeneralInfo_data.number_of_beds).toLocaleString('en-US')
                        : ' - '}
                    </DataText>
                  </Stack>
                </Grid>
                <Grid lg={6} md={6} spacing={2}>
                  <Stack spacing={0.5}>
                    <Stack direction="row" spacing={0.5}>
                      <HeadingText>No. of routine beds</HeadingText>

                      <Tooltip
                        title="Number of available beds"
                        placement="top"
                        arrow
                      >
                        <InfoOutlinedIcon
                          fontSize="small"
                          style={{ width: '15px' }}
                        ></InfoOutlinedIcon>
                      </Tooltip>
                    </Stack>
                    <DataText>
                      {GeneralInfo_data.routine_service_beds != null
                        ? parseInt(GeneralInfo_data.routine_service_beds).toLocaleString('en-US')
                        : ' - '}
                    </DataText>
                  </Stack>
                </Grid>
              </Grid>

              <Grid
                spacing={2}
                container
                style={{
                  margin: '15px',
                }}
              >
                <Grid lg={6} md={6} spacing={2}>
                  <Stack spacing={0.5}>
                    <Stack direction="row" spacing={0.5}>
                      <HeadingText>No. of ICU beds</HeadingText>
                    </Stack>
                    <DataText>
                      {GeneralInfo_data.intensive_care_unit_beds != null
                        ? parseInt(GeneralInfo_data.intensive_care_unit_beds).toLocaleString('en-US')
                        : ' - '}
                    </DataText>
                  </Stack>
                </Grid>
                <Grid lg={6} md={6} spacing={2}>
                  <Stack spacing={0.5}>
                    <Stack direction="row" spacing={0.5}>
                      <HeadingText>No. of NICU beds</HeadingText>
                    </Stack>
                    <DataText>
                      {GeneralInfo_data.nicu_beds != null
                        ? parseInt(GeneralInfo_data.nicu_beds).toLocaleString('en-US')
                        : ' - '}
                    </DataText>
                  </Stack>
                </Grid>
              </Grid>

              <Grid
                spacing={2}
                container
                style={{
                  margin: '15px',
                }}
              >
                <Grid lg={6} md={6} spacing={2}>
                  <Stack spacing={0.5}>
                    <Stack direction="row" spacing={0.5}>
                      <HeadingText>No. of discharges</HeadingText>
                    </Stack>
                    <DataText>
                      {GeneralInfo_data.no_of_discharges != null
                        ? parseInt(GeneralInfo_data.no_of_discharges).toLocaleString('en-US')
                        : ' - '}
                    </DataText>
                  </Stack>
                </Grid>
                <Grid lg={6} md={6} spacing={2}>
                  <Stack spacing={0.5}>
                    <Stack direction="row" spacing={0.5}>
                      <HeadingText>Est no. of inpatient surgeries</HeadingText>
                    </Stack>
                    <DataText>
                      {GeneralInfo_data.est_no_of_inpatient_surgeries != null
                        ? parseInt(GeneralInfo_data.est_no_of_inpatient_surgeries).toLocaleString('en-US')
                        : ' - '}
                    </DataText>
                  </Stack>
                </Grid>
              </Grid>

              <Grid
                spacing={2}
                container
                style={{
                  margin: '15px',
                }}
              >
                <Grid lg={6} md={6} spacing={2}>
                  <Stack spacing={0.5}>
                    <Stack direction="row" spacing={0.5}>
                      <HeadingText>Est no. of outpatient surgeries</HeadingText>
                    </Stack>
                    <DataText>
                      {GeneralInfo_data.est_no_of_outpatient_surgeries != null
                        ? parseInt(GeneralInfo_data.est_no_of_outpatient_surgeries).toLocaleString('en-US')
                        : ' - '}
                    </DataText>
                  </Stack>
                </Grid>
                <Grid lg={6} md={6} spacing={2}>
                  <Stack spacing={0.5}>
                    <Stack direction="row" spacing={0.5}>
                      <HeadingText>Est no. of total surgeries</HeadingText>
                    </Stack>
                    <DataText>
                      {GeneralInfo_data.est_no_of_total_surgeries != null
                        ? parseInt(GeneralInfo_data.est_no_of_total_surgeries).toLocaleString('en-US')
                        : ' - '}
                    </DataText>
                  </Stack>
                </Grid>
              </Grid>

              <Grid
                spacing={2}
                container
                style={{
                  margin: '15px',
                }}
              >
                <Grid lg={6} md={6} spacing={2}>
                  <Stack spacing={0.5}>
                    <Stack direction="row" spacing={0.5}>
                      <HeadingText>Est no. of outpatient visits</HeadingText>
                    </Stack>
                    <DataText>
                      {GeneralInfo_data.est_no_of_outpatient_visits != null
                        ? parseInt(GeneralInfo_data.est_no_of_outpatient_visits).toLocaleString('en-US')
                        : ' - '}
                    </DataText>
                  </Stack>
                </Grid>
                <Grid lg={6} md={6} spacing={2}>
                  <Stack spacing={0.5}>
                    <Stack direction="row" spacing={0.5}>
                      <HeadingText>Est no. of ER visits</HeadingText>
                    </Stack>
                    <DataText>
                      {GeneralInfo_data.est_no_of_er_visits != null
                        ? parseInt(GeneralInfo_data.est_no_of_er_visits).toLocaleString('en-US')
                        : ' - '}
                    </DataText>
                  </Stack>
                </Grid>
              </Grid>

              <Grid
                spacing={2}
                container
                style={{
                  margin: '15px',
                }}
              >
                <Grid lg={6} md={6} spacing={2}>
                  <Stack spacing={0.5}>
                    <Stack direction="row" spacing={0.5}>
                      <HeadingText>No. of births</HeadingText>
                    </Stack>
                    <DataText>
                      {GeneralInfo_data.no_of_births != null
                        ? parseInt(GeneralInfo_data.no_of_births).toLocaleString('en-US')
                        : ' - '}
                    </DataText>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
            : null
        }
      </Grid>
    </Box>
  )
}

export default KeyClinicalMetrics
