import React, { useContext, useEffect, useState } from 'react'
import * as atlas from 'azure-maps-control'
import {
  AzureMap,
  AzureMapsContext,
  IAzureMapOptions,
  IAzureMapsContextProps,
} from 'react-azure-maps'
import { AuthenticationType } from 'azure-maps-control'
import config from '../../../config/config'
import { useLocation } from 'react-router-dom'
import {
  useGetCountyMapDataForCityLazyQuery,
  useGetCountyMapDataForZipCodeLazyQuery,
  useGetCountyMapDataForZipCodeAndRadiusLazyQuery,
  useGetCountyMapDataForCountyLazyQuery,
  useGetCountyGeometryDataLazyQuery,
} from '../../../__generated__/graphql'
import { control as legendControl } from '../../../customMapsControl/LayerLegendControl'
import { appInsights } from '../../../providers/appInsightProvider'
import { MapImageExporter } from '../../../customMapsControl/MapImageExport'

interface CountyMapProps {
  reference?: atlas.Map | null
  mapReady?: boolean
  countyName?: string
}

const CountyAreaMapPDF: React.FC<CountyMapProps> = (props: CountyMapProps) => {
  const { mapRef, isMapReady } = useContext<IAzureMapsContextProps>(
    AzureMapsContext
  )
  const search = useLocation().search
  const dpr_city = new URLSearchParams(search).get('city')
  const zipCode = new URLSearchParams(search).get('zipcode')
  const county = new URLSearchParams(search).get('county')
  const radius = new URLSearchParams(search).get('radius')

  const [geometry_data, setGeometryData] = useState<string>('')

  let county_data: string[] = []
  if (county) {
    let counties_array = county.split(',')
    for (let i = 0; i < counties_array.length; i++) {
      const element = counties_array[i]
      let splitted_val = element.split(' - ')
      let string_val: string = splitted_val[1].trim() + splitted_val[0].trim()
      county_data.push(string_val)
    }
  }

  const getMapImage = () => {
    if (mapRef) {
      setTimeout(() => {
        MapImageExporter.getImage(mapRef).then((i) => {
          const image: any = i.getAttribute('src')
          sessionStorage.setItem('CountyMap', image)
        })
      }, 10000)
    }
  }

  const [
    getDprCityData,
    { data: cityMapData, loading: loadingCityMapData },
  ] = useGetCountyMapDataForCityLazyQuery({
    variables: {
      city: dpr_city,
    },
  })

  const [
    getzipcodeData,
    { data: zipMapData, loading: loadingZipMapData },
  ] = useGetCountyMapDataForZipCodeLazyQuery({
    variables: {
      zipcode: zipCode,
    },
  })

  const [
    getZipCodeRadiusdata,
    { data: zipRadiusData, loading: loadingZipRadiusData },
  ] = useGetCountyMapDataForZipCodeAndRadiusLazyQuery({
    variables: {
      zipcode: zipCode,
      radius: radius ? parseInt(radius) : 0,
    },
  })

  const [
    getCountyData,
    { data: countyData, loading: loadingcountyData },
  ] = useGetCountyMapDataForCountyLazyQuery({
    variables: {
      county: county_data.join(','),
    },
  })

  const [
    getGeometryData,
    {
      data: geometryData,
      loading: geometryDataLoading,
      error: geometryDataError,
    },
  ] = useGetCountyGeometryDataLazyQuery({
    variables: {
      state: geometry_data,
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (geometryDataError instanceof Error) {
      appInsights.trackException({
        exception: geometryDataError,
        error: new Error('Failed to fetch County Map Data.'),
      })
    }
  }, [geometryDataError])

  let dataSource = new atlas.source.DataSource()
  let dataSource1 = new atlas.source.DataSource()

  //@ts-ignore

  const Rankinglegend = new legendControl.LegendControl({
    layout: 'column',
    itemLayout: 'row',
    showToggle: false,
    fitItem: true,
    title: 'Ranking',
    legends: [
      {
        type: 'category',
        // eslint-disable-next-line no-sparse-arrays
        items: [
          {
            color: '#f69a81',
            label: '1 - 15',
            shape: 'square',
          },
          {
            color: '#f59660',
            label: '15 - 30',
            shape: 'square',
          },
          {
            color: '#f58c1f',
            label: '30 - 45',
            shape: 'square',
          },
          {
            color: '#cb7812',
            label: 'Above 45',
            shape: 'square',
          },
          {
            color: '#8d8c8c',
            label: 'NOT RANKED',
            shape: 'square',
          },
        ],
      },
    ],
    id: 'ranking-legend',
  })

  const addRank = (data, obj) => {
    let data1 = JSON.parse(JSON.stringify(data))
    data1.features[0].properties['county_rank'] =
      obj.county_rank === 'NR' ? 10000 : parseInt(obj.county_rank)
    return data1
  }

  const LegendColor = () => {
    let expr: any = [
      'case',
      ['<=', ['get', 'county_rank'], 15],
      '#f69a81',
      ['<=', ['get', 'county_rank'], 30],
      '#f59660',
      ['<=', ['get', 'county_rank'], 45],
      '#f58c1f',
      ['>', ['get', 'county_rank'], 45],
      '#cb7812',
      ['==', ['get', 'county_rank'], 10000],
      '#8d8c8c',
      '#794f07',
    ]

    return expr
  }

  const getZipData = () => {
    if (zipMapData?.udf_county_data_map_multi_zip && mapRef && isMapReady) {
      mapRef.controls.add(Rankinglegend, {
        position: atlas.ControlPosition.TopRight,
      })
      if (zipMapData?.udf_county_data_map_multi_zip.length) {
        setGeometryData(
          zipMapData?.udf_county_data_map_multi_zip[0]?.statename
            ? zipMapData?.udf_county_data_map_multi_zip[0]?.statename
            : ''
        )
      }
    }
  }

  const getCityData = () => {
    if (cityMapData?.udf_county_data_map_dpr_market && mapRef && isMapReady) {
      mapRef.controls.add(Rankinglegend, {
        position: atlas.ControlPosition.TopRight,
      })

      if (cityMapData?.udf_county_data_map_dpr_market.length) {
        setGeometryData(
          cityMapData?.udf_county_data_map_dpr_market[0]?.statename
            ? cityMapData?.udf_county_data_map_dpr_market[0]?.statename
            : ''
        )
      }
    }
  }

  const getZipRadiusData = () => {
    if (zipRadiusData?.udf_county_data_map_zip_radius && mapRef && isMapReady) {
      mapRef.controls.add(Rankinglegend, {
        position: atlas.ControlPosition.TopRight,
      })

      if (zipRadiusData?.udf_county_data_map_zip_radius.length) {
        setGeometryData(
          zipRadiusData?.udf_county_data_map_zip_radius[0]?.statename
            ? zipRadiusData?.udf_county_data_map_zip_radius[0]?.statename
            : ''
        )
      }
    }
  }

  const getCountyMapData = () => {
    if (countyData?.udf_county_data_map_multi_county && mapRef && isMapReady) {
      mapRef.controls.add(Rankinglegend, {
        position: atlas.ControlPosition.TopRight,
      })

      if (countyData?.udf_county_data_map_multi_county.length) {
        setGeometryData(
          countyData?.udf_county_data_map_multi_county[0]?.statename
            ? countyData?.udf_county_data_map_multi_county[0]?.statename
            : ''
        )
      }
    }
  }

  useEffect(() => {
    if (geometryData?.OTHERDATA_COUNTY_MAPPING) {
      mapRef?.sources.add(dataSource)
      mapRef?.sources.add(dataSource1)

      geometryData.OTHERDATA_COUNTY_MAPPING.map((Geometry) => {
        let map_data: any = []
        if (dpr_city) {
          map_data = cityMapData?.udf_county_data_map_dpr_market
        } else if (zipCode && radius) {
          map_data = zipRadiusData?.udf_county_data_map_zip_radius
        } else if (zipCode) {
          map_data = zipMapData?.udf_county_data_map_multi_zip
        } else if (county) {
          map_data = countyData?.udf_county_data_map_multi_county
        }
        if (map_data && map_data.length) {
          map_data.map((propertyData) => {
            if (
              Geometry.zip_geometry &&
              Geometry.county === propertyData.county
            ) {
              let RankData: any = addRank(
                Geometry.zip_geometry.geometry_json.body.additionalData[0]
                  .geometryData,
                propertyData
              )
              if (RankData) {
                dataSource.add(RankData)
              }
              if (dataSource) {
                getMapImage()
                if (propertyData.county === props.countyName) {
                  dataSource1.add(
                    Geometry.zip_geometry.geometry_json.body.additionalData[0]
                      .geometryData
                  )
                }

                mapRef?.layers.add(
                  propertyData.county === props.countyName
                    ? [
                      new atlas.layer.PolygonLayer(
                        dataSource,
                        'Ranking data',
                        {
                          fillColor: LegendColor(),
                          fillOpacity: 0.5,
                        }
                      ),
                      new atlas.layer.LineLayer(dataSource1, 'myLineLayer', {
                        strokeColor: 'brown',
                        strokeWidth: 2,
                      }),
                    ]
                    : new atlas.layer.PolygonLayer(dataSource, 'Ranking data', {
                      fillColor: LegendColor(),
                      fillOpacity: 0.5,
                    })
                )
              }
              mapRef?.setCamera({
                center: [
                  Geometry.zip_geometry.geometry_json.center.lon,
                  Geometry.zip_geometry.geometry_json.center.lat,
                ],
              })
            }
          })
        }
      })
    }
  }, [geometryDataLoading])

  useEffect(() => {
    if (geometry_data && geometry_data.length)
      getGeometryData()
  }, [geometry_data])

  useEffect(() => {
    if (dpr_city) {
      getDprCityData()
    } else if (zipCode && radius) {
      getZipCodeRadiusdata()
    } else if (zipCode) {
      getzipcodeData()
    } else if (county) {
      getCountyData()
    }
  }, [])

  useEffect(() => {
    if (
      cityMapData &&
      cityMapData.udf_county_data_map_dpr_market &&
      cityMapData?.udf_county_data_map_dpr_market.length
    ) {
      getCityData()
    } else if (
      zipMapData &&
      zipMapData?.udf_county_data_map_multi_zip &&
      zipMapData.udf_county_data_map_multi_zip.length
    ) {
      getZipData()
    } else if (
      zipRadiusData &&
      zipRadiusData.udf_county_data_map_zip_radius &&
      zipRadiusData.udf_county_data_map_zip_radius.length
    ) {
      getZipRadiusData()
    } else if (
      countyData &&
      countyData.udf_county_data_map_multi_county &&
      countyData.udf_county_data_map_multi_county.length
    ) {
      getCountyMapData()
    }
  }, [
    loadingCityMapData,
    mapRef,
    isMapReady,
    loadingZipMapData,
    loadingZipRadiusData,
    loadingcountyData,
  ])

  const option: IAzureMapOptions = {
    authOptions: {
      authType: AuthenticationType.subscriptionKey,
      subscriptionKey: config.azure_subscription_key,
    },
    renderWorldCopies: true,
    showLogo: false,
    view: 'Auto',
    center: [0, 0],
    zoom: 5,
    type: 'fly',
    preserveDrawingBuffer: true,
  }

  return <AzureMap options={option} styles={{ marginLeft: '15px' }}></AzureMap>
}

export default CountyAreaMapPDF
