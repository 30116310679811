import {
  Box,
  Button,
  Grid,
  styled,
  Typography,
  Tooltip,
  Rating,
  Divider,
  Skeleton,
} from '@mui/material'
import React, { useEffect } from 'react'
import StarIcon from '@mui/icons-material/Star'
import { Stack } from '@mui/system'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { useGetPerformanceDataQuery } from '../../../../../__generated__/graphql'
import { useHistory, useLocation } from 'react-router-dom'
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined'
import {
  textColor,
  backgroundColor,
  performanceText,
} from '../../../../../config/commonFunction'
import { PerformanceDialog } from '../Performance/PerformanceDialog'
import { appInsights } from '../../../../../providers/appInsightProvider'

export const Summary_Performance: React.FC = () => {
  const search = useLocation().search
  let definitive_id = new URLSearchParams(search).get('id')
  let history = useHistory()
  let location = useLocation()
  const [open, setOpen] = React.useState(false)
  const [onClickType, setOnClickType] = React.useState('Fiscal Stewardship')

  const {
    data: PerformanceData,
    loading: loadingPerformanceData,
    error: errorPerformanceData,
  } = useGetPerformanceDataQuery({
    variables: {
      definitive_id,
    },
  })

  useEffect(() => {
    if (errorPerformanceData instanceof Error) {
      appInsights.trackException({ exception: errorPerformanceData, error: new Error("Failed to fetch Performance SummaryData.") })
    }
  }, [errorPerformanceData])

  let Performance_data: any =
    PerformanceData &&
      PerformanceData.DEFINITIVEHEALTHCARE_VW_OVERALL_RATINGS &&
      PerformanceData.DEFINITIVEHEALTHCARE_VW_OVERALL_RATINGS.length
      ? PerformanceData.DEFINITIVEHEALTHCARE_VW_OVERALL_RATINGS[0]
      : null

  const [value, setValue] = React.useState<number | null>(2)

  const StyledRating = styled(Rating)(({ theme }) => ({
    '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
      color: theme.palette.action.disabled,
    },
    paddingTop: '15px',
  }))

  const Performance = styled(Box)(() => ({
    color: ' #22577A',
    fontWeight: '700',
    fontSize: '1.2rem',
    paddingTop: '28px',
    paddingLeft: '27px',
  }))

  const HeadingText = styled(Box)(() => ({
    fontWeight: '700',
    fontSize: '13px',
    color: '#3E3E3E',
    margin: '10px',
    width: '165px',
    cursor: 'pointer',
  }))

  const OuterDiv = styled(Box)(() => ({
    border: '1px solid #F3F3F3',
    background: '#F3F3F3',
    display: 'flex',
    height: '65px',
    borderRadius: '9px',
    margin: '10px',
  }))

  const PerformanceScoreOuterDiv = styled(Box)(() => ({
    display: 'flex',
    height: '65px',
    borderRadius: '9px',
    margin: '10px',
  }))

  const LabelText = styled(Box)(() => ({
    fontWeight: '600',
    fontSize: '13px',
    color: '#3E3E3E',
    paddingTop: '17px',
    textAlign: 'center',
  }))

  return (
    <Box>
      <Box
        style={{
          margin: '10px', // Temporary
          height: '75vh',
          border: '2px solid #E8F5E9',
          borderRadius: '10px',
        }}
      >
        <Grid container spacing={2} style={{ position: 'relative' }}>
          <Grid lg={6} md={6}>
            <Performance>Performance</Performance>
          </Grid>
          <Grid
            lg={6}
            md={6}
            sx={{
              textAlign: 'end',
              margin: 'auto',
              padding: '25px 15px 0px 15px',
            }}
          >
            <Button data-performance-view-more-id="Summary | Performance | View More"
              style={{
                color: '#3E3E3E',
                fontWeight: '400px',
                fontSize: '0.7rem',
                textTransform: 'inherit',
                backgroundColor: 'transparent',
              }}
              endIcon={<KeyboardDoubleArrowRightOutlinedIcon />}
              onClick={() => {
                history.push(`/client_insights/Performance${location.search}`)
              }}
            >
              View More
            </Button>
          </Grid>
        </Grid>
        <Grid lg={12} md={12}>
          <Divider
            orientation="horizontal"
            flexItem
            style={{
              borderColor: '#E8F5E9',
              margin: '10px',
            }}
          />
        </Grid>

        {open ? (
          <PerformanceDialog
            open={open}
            type={onClickType}
            setOpen={(open) => {
              setOpen(open)
            }}
            ratings={Performance_data}
          />
        ) : null}

        {loadingPerformanceData ? (
          <Typography variant="h3" sx={{ margin: '10px' }}>
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </Typography>
        ) : PerformanceData ? (
          <Grid>
            <PerformanceScoreOuterDiv
              sx={{
                border: `1px solid ${textColor(
                  Math.round(Performance_data?.overall_rating)
                )}`,
                background: backgroundColor(
                  Math.round(Performance_data?.overall_rating)
                ),
              }}
            >
              <Grid container>
                <Grid
                  lg={6}
                  md={6}
                  style={{
                    paddingTop: '5px',
                  }}
                >
                  <Stack direction="row" spacing={0.2}>
                    <HeadingText>CLIENT PERFORMANCE SCORE</HeadingText>
                    <Tooltip
                      title="DPR’s proprietary scoring system that evaluates performance at the organizational level, looking at specific metrics across five key categories. This score is intended for internal use only and should not be shared outside of the company"
                      placement="top"
                      arrow
                    >
                      <InfoOutlinedIcon
                        fontSize="small"
                        style={{ width: '15px', paddingTop: '9px' }}
                      ></InfoOutlinedIcon>
                    </Tooltip>
                  </Stack>
                </Grid>

                <Grid lg={6} md={6} container style={{ marginTop: "-5px" }}>
                  <Grid lg={5} md={5} item>
                    <StyledRating
                      defaultValue={0}
                      readOnly
                      value={
                        Performance_data?.overall_rating
                          ? Math.round(Performance_data?.overall_rating)
                          : 0
                      }
                      icon={
                        <StarIcon
                          style={{
                            color: textColor(
                              Performance_data?.overall_rating
                                ? Math.round(Performance_data?.overall_rating)
                                : 0
                            ),
                          }}
                        ></StarIcon>
                      }
                    />
                  </Grid>
                  <Grid lg={7} md={7} item>
                    {value !== null && (
                      <LabelText sx={{ ml: 2 }}>
                        {performanceText(
                          Math.round(Performance_data?.overall_rating)
                        )
                          ?.replace('(', '')
                          .replace(')', '')}
                      </LabelText>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </PerformanceScoreOuterDiv>
            {/*  Fiscal Stewardship */}
            <OuterDiv>
              <Grid container style={{ margin: "auto" }}>
                <Grid
                  lg={6}
                  md={6}
                  style={{
                    paddingTop: '5px',
                  }}
                >
                  <Stack direction="row" spacing={0.2}>
                    <HeadingText
                      onClick={() => {
                        setOpen(true)
                        setOnClickType('Fiscal Stewardship')
                      }}
                    >
                      Fiscal Stewardship
                    </HeadingText>

                    <Tooltip
                      title="Evaluates the current financial health and long-term sustainability of the hospital"
                      placement="top"
                      arrow
                    >
                      <InfoOutlinedIcon
                        fontSize="small"
                        style={{ width: '15px', paddingTop: '9px' }}
                      ></InfoOutlinedIcon>
                    </Tooltip>
                  </Stack>
                </Grid>

                <Grid lg={6} md={6} container style={{ marginTop: "-5px" }}>
                  <Grid lg={5} md={5}>
                    <StyledRating
                      defaultValue={0}
                      readOnly
                      value={
                        Performance_data?.fiscal_stewardship
                          ? Math.round(Performance_data?.fiscal_stewardship)
                          : 0
                      }
                      icon={
                        <StarIcon
                          style={{
                            color: textColor(
                              Performance_data?.fiscal_stewardship
                                ? Math.round(
                                  Performance_data?.fiscal_stewardship
                                )
                                : 0
                            ),
                          }}
                        ></StarIcon>
                      }
                    />
                  </Grid>
                  <Grid lg={7} md={7}>
                    {value !== null && (
                      <LabelText sx={{ ml: 2 }}>
                        {performanceText(
                          Math.round(Performance_data?.fiscal_stewardship)
                        )
                          ?.replace('(', '')
                          .replace(')', '')}
                      </LabelText>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </OuterDiv>

            {/* Patient Experience */}
            <OuterDiv>
              <Grid container style={{ margin: "auto" }}>
                <Grid
                  lg={6}
                  md={6}
                  style={{
                    paddingTop: '5px',
                  }}
                >
                  <Stack direction="row" spacing={0.2}>
                    <HeadingText
                      onClick={() => {
                        setOpen(true)
                        setOnClickType('Patient Experience')
                      }}
                    >
                      Patient Experience
                    </HeadingText>
                    <Tooltip
                      title="Evaluates how well the hospital delivers a positive patient experience"
                      placement="top"
                      arrow
                    >
                      <InfoOutlinedIcon
                        fontSize="small"
                        style={{ width: '15px', paddingTop: '9px' }}
                      ></InfoOutlinedIcon>
                    </Tooltip>
                  </Stack>
                </Grid>

                <Grid lg={6} md={6} container style={{ marginTop: "-5px" }}>
                  <Grid lg={5} md={5}>
                    <StyledRating
                      defaultValue={0}
                      readOnly
                      value={
                        Performance_data?.experiential_design
                          ? Math.round(Performance_data?.experiential_design)
                          : 0
                      }
                      icon={
                        <StarIcon
                          style={{
                            color: textColor(
                              Performance_data?.experiential_design
                                ? Math.round(
                                  Performance_data?.experiential_design
                                )
                                : 0
                            ),
                          }}
                        ></StarIcon>
                      }
                    />
                  </Grid>
                  <Grid lg={7} md={7}>
                    {value !== null && (
                      <LabelText sx={{ ml: 2 }}>
                        {performanceText(
                          Math.round(Performance_data?.experiential_design)
                        )
                          ?.replace('(', '')
                          .replace(')', '')}
                      </LabelText>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </OuterDiv>

            {/* Clinical Outcomes */}
            <OuterDiv>
              <Grid container style={{ margin: "auto" }}>
                <Grid
                  lg={6}
                  md={6}
                  style={{
                    paddingTop: '5px',
                  }}
                >
                  <Stack direction="row" spacing={0.2}>
                    <HeadingText
                      onClick={() => {
                        setOpen(true)
                        setOnClickType('Clinical Outcomes')
                      }}
                    >
                      Clinical Outcomes
                    </HeadingText>
                    <Tooltip
                      title="Evaluates the hospital’s performance in delivering optimal care and high clinical outcomes"
                      placement="top"
                      arrow
                    >
                      <InfoOutlinedIcon
                        fontSize="small"
                        style={{ width: '15px', paddingTop: '9px' }}
                      ></InfoOutlinedIcon>
                    </Tooltip>
                  </Stack>
                </Grid>

                <Grid lg={6} md={6} container style={{ marginTop: "-5px" }}>
                  <Grid lg={5} md={5}>
                    <StyledRating
                      defaultValue={0}
                      readOnly
                      value={
                        Performance_data?.clinical_outcomes
                          ? Math.round(Performance_data?.clinical_outcomes)
                          : 0
                      }
                      icon={
                        <StarIcon
                          style={{
                            color: textColor(
                              Performance_data?.clinical_outcomes
                                ? Math.round(
                                  Performance_data?.clinical_outcomes
                                )
                                : 0
                            ),
                          }}
                        ></StarIcon>
                      }
                    />
                  </Grid>
                  <Grid lg={7} md={7}>
                    {value !== null && (
                      <LabelText sx={{ ml: 2 }}>
                        {performanceText(
                          Math.round(Performance_data?.clinical_outcomes)
                        )
                          ?.replace('(', '')
                          .replace(')', '')}
                      </LabelText>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </OuterDiv>

            {/* Facility Conditions */}
            <OuterDiv>
              <Grid container style={{ margin: "auto" }}>
                <Grid
                  lg={6}
                  md={6}
                  style={{
                    paddingTop: '5px',
                  }}
                >
                  <Stack direction="row" spacing={0.2}>
                    <HeadingText
                      onClick={() => {
                        setOpen(true)
                        setOnClickType('Facility Conditions')
                      }}
                    >
                      Facility Conditions
                    </HeadingText>
                    <Tooltip
                      title="Evaluates how the hospital performs with respect to modern architectural standards"
                      placement="top"
                      arrow
                    >
                      <InfoOutlinedIcon
                        fontSize="small"
                        style={{ width: '15px', paddingTop: '9px' }}
                      ></InfoOutlinedIcon>
                    </Tooltip>
                  </Stack>
                </Grid>

                <Grid lg={6} md={6} container style={{ marginTop: "-5px" }}>
                  <Grid lg={5} md={5}>
                    <StyledRating
                      defaultValue={2}
                      readOnly
                      value={
                        Performance_data?.facility_conditions
                          ? Math.round(Performance_data?.facility_conditions)
                          : 0
                      }
                      icon={
                        <StarIcon
                          style={{
                            color: textColor(
                              Performance_data?.facility_conditions
                                ? Math.round(
                                  Performance_data?.facility_conditions
                                )
                                : 0
                            ),
                          }}
                        ></StarIcon>
                      }
                    />
                  </Grid>
                  <Grid lg={7} md={7}>
                    {value !== null && (
                      <LabelText sx={{ ml: 2 }}>
                        {performanceText(
                          Math.round(Performance_data?.facility_conditions)
                        )
                          ?.replace('(', '')
                          .replace(')', '')}
                      </LabelText>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </OuterDiv>

            {/* Operational Efficiency */}
            <OuterDiv>
              <Grid container style={{ margin: "auto" }}>
                <Grid
                  lg={6}
                  md={6}
                  style={{
                    paddingTop: '5px',
                  }}
                >
                  <Stack direction="row" spacing={0.2}>
                    <HeadingText
                      onClick={() => {
                        setOpen(true)
                        setOnClickType('Operations')
                      }}
                    >
                      Operational Efficiency
                    </HeadingText>

                    <Tooltip
                      title="Evaluates the operational throughput and efficiency of the hospital"
                      placement="top"
                      arrow
                    >
                      <InfoOutlinedIcon
                        fontSize="small"
                        style={{ width: '15px', paddingTop: '9px' }}
                      ></InfoOutlinedIcon>
                    </Tooltip>
                  </Stack>
                </Grid>

                <Grid lg={6} md={6} container style={{ marginTop: "-5px" }}>
                  <Grid lg={5} md={5}>
                    <StyledRating
                      defaultValue={0}
                      readOnly
                      value={
                        Performance_data?.clinical_operations
                          ? Math.round(Performance_data?.clinical_operations)
                          : 0
                      }
                      icon={
                        <StarIcon
                          style={{
                            color: textColor(
                              Performance_data?.clinical_operations
                                ? Math.round(
                                  Performance_data?.clinical_operations
                                )
                                : 0
                            ),
                          }}
                        ></StarIcon>
                      }
                    />
                  </Grid>
                  <Grid lg={7} md={7}>
                    {value !== null && (
                      <LabelText sx={{ ml: 2 }}>
                        {performanceText(
                          Math.round(Performance_data?.clinical_operations)
                        )
                          ?.replace('(', '')
                          .replace(')', '')}
                      </LabelText>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </OuterDiv>
          </Grid>
        ) : null}
      </Box>
    </Box>
  )
}
