import { Autocomplete, Box, FormControl, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, Skeleton, TextField, Typography } from "@mui/material"
import { Stack } from "@mui/system"
import React, { useEffect, useState } from "react"
import { zipCodeRadiusList } from "../../../config/config";
import { ICity } from "../../../models/ICity";
import { useGetCityDataQuery, useGetCountyDataQuery } from "../../../__generated__/graphql";
import { ButtonComponent } from "../Home/ButtonComponent";
import { CountySelection } from "./CountySelection";
//@ts-ignore
import { IMaskInput } from 'react-imask';
import { appInsights } from "../../../providers/appInsightProvider";


interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const TextMaskCustom = React.forwardRef<HTMLElement, CustomProps>(
  function TextMaskCustom(props, ref: any) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask={/^([0-9](,{0,9}))*$/i}
        inputRef={ref}
        onAccept={(value: any) => onChange(value)}
        overwrite
      />
    );
  },
);

export const MarketInsights: React.FC = () => {

  const [selectedCity, setSelectedCity] = useState<ICity | null>(null);
  const [type, setType] = useState<string>("");
  const [singleZipcode, setSingleZipCode] = useState<string>("");
  const [multipleZipcode, setMultipleZipCode] = useState<string>("");
  const [zipInputType, setzipInputType] = useState<string>("ZipCodeAndRadius");
  const [radius, setRadius] = React.useState<string>('');
  const [selectedCounty, setSelectedCounty] = React.useState<string>("");

  const { data: getCityData, loading: loadingCity, error: errorCity } = useGetCityDataQuery();
  const { data: getCountyData, loading: loadingCounty, error: errorCounty } = useGetCountyDataQuery();

  useEffect(() => {
    if (errorCity instanceof Error) {
      appInsights.trackException({ exception: errorCity, error: new Error("Failed to fetch City data.") })
    }
  }, [errorCity])

  useEffect(() => {
    if (errorCounty instanceof Error) {
      appInsights.trackException({ exception: errorCounty, error: new Error("Failed to fetch County Data.") })
    }
  }, [errorCounty])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadius(event.target.value);
  }

  let city_data = getCityData && getCityData.OTHERDATA_DPR_OFFICES && getCityData.OTHERDATA_DPR_OFFICES.length ?
    getCityData?.OTHERDATA_DPR_OFFICES : []

  city_data = city_data.slice().sort((a: any, b: any) => {
    return (a.CITY.toString()).localeCompare(b.CITY.toString())
  })

  let county_data = getCountyData && getCountyData.OTHERDATA_ZIPCODE_MAPPING && getCountyData.OTHERDATA_ZIPCODE_MAPPING.length ?
    getCountyData?.OTHERDATA_ZIPCODE_MAPPING : []

  county_data = county_data.slice().sort((a: any, b: any) => {
    return (a.state.toString()).localeCompare(b.state.toString())
  })

  const TextFieldCityType = (
    loadingCity ?
      <Typography component="div" key="h2" variant="h2">
        <Skeleton />
      </Typography>
      :
      type === "Market City Selection" && city_data ?
        <FormControl fullWidth>
          <Autocomplete
            id="select-city"
            key="CityAutocomplete"
            disableClearable
            options={type ? city_data as ICity[] : []}
            noOptionsText="No cities found"
            value={selectedCity as ICity}
            onChange={(e, selectCity: ICity) => {
              setSelectedCity(selectCity);
            }}
            getOptionLabel={(option: ICity) => `${option?.CITY}`}
            renderInput={(params) => <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                style: { background: "#FAFAFA" },
              }} InputLabelProps={{ style: { fontSize: "14px" } }}
              size="small" margin='dense'
              variant="outlined"
              label={"DPR Market City"} />}
          />
        </FormControl>
        : <FormControl fullWidth>
          <Autocomplete
            id="select-city-disable" disabled
            options={[]}
            renderInput={(params) => <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                style: { background: "#FAFAFA" },
              }} InputLabelProps={{ style: { fontSize: "14px" } }}
              size="small" margin='dense'
              label="DPR Market City" />}
          /></FormControl>
  );

  const TextFieldZipCode = (
    type === 'Zip Code Selection' ?
      <FormControl fullWidth>
        <RadioGroup row key="subRadioGroup"
          value={zipInputType}
          onChange={(event, value) => {
            setzipInputType(value);
            setMultipleZipCode("");
            setSingleZipCode("");
            setRadius("");
          }}>
          <Box sx={{ width: '26vw' }}>
            <Grid container direction='row'>
              <Grid item xs={6}>
                <FormControlLabel value={'ZipCodeAndRadius'} sx={{
                  ".MuiFormControlLabel-label": {
                    fontSize: "14px",
                    color: "#22577A"
                  }
                }} control={<Radio style={{ color: "#22577A" }} />}
                  label={'by Zip Code & Radius'} />
              </Grid>
              <Grid item xs={6}>

                <FormControlLabel value={'ZipCode'} sx={{
                  ".MuiFormControlLabel-label": {
                    fontSize: "14px",
                    color: "#22577A"
                  }
                }} control={<Radio style={{ color: "#22577A" }} />}
                  label={'by Zip Code(s)'} />

              </Grid>
            </Grid>
          </Box>
        </RadioGroup>

        {zipInputType === 'ZipCodeAndRadius' ?
          renderZipcodeRadiusInput(setSingleZipCode, singleZipcode, radius, handleChange, zipCodeRadiusList, zipInputType)
          :
          null
        }
        {zipInputType === 'ZipCode' ?
          renderZipcodeTextField(setMultipleZipCode, multipleZipcode, zipInputType)
          :
          null}
      </FormControl>
      :
      <FormControl>
        <RadioGroup row aria-disabled key="subRadioGroupDisabled">
          <Box sx={{ width: '26vw' }}>
            <Grid container direction='row' >
              <Grid item xs={6} >
                <FormControlLabel
                  sx={{
                    ".MuiFormControlLabel-label": {
                      fontSize: "14px"
                    }
                  }}
                  disabled={true} value={'disabled'} control={<Radio style={{ color: "#22577A" }} />} label={'by Zip Code & Radius'}></FormControlLabel>
              </Grid>
              <Grid item xs={6} >
                <FormControlLabel
                  sx={{
                    ".MuiFormControlLabel-label": {
                      fontSize: "14px"
                    }
                  }} disabled={true} value={'disabled'} control={<Radio style={{ color: "#22577A" }} />} label={'by Zip Code(s)'}></FormControlLabel>
              </Grid>
            </Grid>
          </Box>
        </RadioGroup>
      </FormControl>
  );

  const TextFieldCountyType = (
    loadingCounty ?
      <Typography component="div" key="h2" variant="h2">
        <Skeleton />
      </Typography>
      :
      <FormControl fullWidth sx={{ marginTop: "10px" }}>
        <CountySelection
          type={type}
          label={"Select County"}
          disabled={type === "County Selection" ? false : true}
          county_data={county_data} getSelectedCounty={(value) => { setSelectedCounty(value) }} />
      </FormControl>
  );


  return (
    <React.Fragment>
      <Box sx={{ height: "100%" }} id="market-insights-box">
        <Grid direction={"column"} sx={{ height: "calc(100% - 95px)" }} container>
          <Grid item lg={8} md={7} xl={8} sx={{ height: '80%' }}>
            <FormControl fullWidth>
              <RadioGroup value={type} key="mainRadioGroup"
                onChange={(event, value) => {
                  setType(value);
                  setSelectedCity(null); setMultipleZipCode(""); setRadius("");
                  setSingleZipCode(""); setSelectedCounty(""); setzipInputType("")
                }}>
                <Stack spacing={3} style={{ padding: "24px" }}>
                  <Box p={2} sx={{ backgroundColor: "#FAFAFA", border: '2px solid #E8F5E9', borderRadius: "15px", }}>
                    <Box sx={{ color: '#22577A', padding: '3px' }}>
                      <FormControlLabel value="Market City Selection"
                        control={<Radio style={{ color: "#22577A" }} />}
                        label="Market City Selection" sx={{
                          ".MuiFormControlLabel-label": {
                            fontWeight: "600"
                          }
                        }} />
                    </Box>
                    <Box pl={4}>
                      {TextFieldCityType}
                    </Box>
                  </Box>
                  <Box p={2} sx={{ backgroundColor: "#FAFAFA", border: '2px solid #E8F5E9', borderRadius: "15px", }}>
                    <Box >
                      <Box sx={{ color: '#22577A', padding: '3px' }}>
                        <FormControlLabel value="Zip Code Selection"
                          control={<Radio style={{ color: "#22577A" }}
                          />} label="Zip Code Selection"
                          sx={{
                            ".MuiFormControlLabel-label": {
                              fontWeight: "600"
                            }
                          }} />
                      </Box>
                      <Box pl={4}>
                        {TextFieldZipCode}
                      </Box>
                    </Box>
                  </Box>
                  <Box p={2} sx={{ backgroundColor: "#FAFAFA", border: '2px solid #E8F5E9', borderRadius: "15px", }}>
                    <Box >
                      <Box sx={{ color: '#22577A', padding: '3px' }}>
                        <FormControlLabel value="County Selection"
                          control={<Radio style={{ color: "#22577A" }} />} label="County Selection"
                          sx={{
                            ".MuiFormControlLabel-label": {
                              fontWeight: "600"
                            }
                          }} />
                      </Box>
                      <Box pl={4}>
                        {TextFieldCountyType}
                      </Box>
                    </Box>
                  </Box>
                </Stack>
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item lg md xl>
          </Grid>
          <Grid item lg={1} md={1} xl={1} style={{ display: 'flex', flexDirection: "column", justifyContent: "flex-end" }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: "center", width: '42vw' }}>
              <ButtonComponent
                disable={selectedCity || selectedCounty || (singleZipcode && radius) || multipleZipcode ? false : true}
                url={selectedCity ? `/market_insights/Summary?city=${selectedCity.CITY}` :
                  selectedCounty ? `/market_insights/Summary?county=${selectedCounty}` :
                    (singleZipcode && radius) ? `/market_insights/Summary?zipcode=${singleZipcode}&radius=${radius}` :
                      multipleZipcode ? `/market_insights/Summary?zipcode=${multipleZipcode}` : "/"}
                clear={() => {
                  setSelectedCity(null); setMultipleZipCode(""); setType(""); setRadius("");
                  setSingleZipCode(""); setSelectedCounty(""); setzipInputType("")
                }} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  )
}

// Render Zip code and radius component
function renderZipcodeRadiusInput(setZipCode: React.Dispatch<React.SetStateAction<string>>, zipcode: string, radius: string, handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void, zipCodeRadiusList: { value: string; label: string; }[], inputType: string): string | number | boolean | {} | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactNodeArray | React.ReactPortal | null | undefined {
  return <Box sx={{ width: '34vw' }}>
    <Grid container direction='row' sx={{ marginTop: "12px" }}>
      <Grid item xs={6} sx={{ paddingLeft: '10px', margin: "auto" }}>

        <TextField size="small" margin="dense" label={inputType === "ZipCodeAndRadius" ? 'Enter the Zip Code' : "Enter the Zip Code"} type="number" key="zipTextField" onChange={(event) => {
          setZipCode(event?.target?.value);
        }}
          value={zipcode ?? ""}
          InputLabelProps={{ style: { fontSize: "14px" } }}
          fullWidth />
      </Grid>

      <Grid item xs={6} sx={{ paddingLeft: '10px' }}>
        <TextField select value={radius} disabled={zipcode ? false : true} onChange={handleChange} InputLabelProps={{ style: { fontSize: "14px" } }} label={inputType === "ZipCodeAndRadius" ? "Select Radius" : "Select Radius"} fullWidth size="small" margin="dense" inputProps={{ style: { fontSize: '14px' } }}>
          {zipCodeRadiusList.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </Grid>
  </Box>;
}

// Render Zipcode textfield
function renderZipcodeTextField(setZipCode: React.Dispatch<React.SetStateAction<string>>, zipcode: string, inputType: string): string | number | boolean | {} | React.ReactNodeArray | React.ReactPortal | React.ReactElement<any, string | React.JSXElementConstructor<any>> | null | undefined {

  return <Box sx={{ width: '34vw' }}>
    <Grid container direction='row' >
      <Grid item xs={12} sx={{ paddingLeft: '10px', marginTop: "12px" }}>
        <TextField
          value={zipcode}
          onChange={(event: any) => {
            setZipCode(event)
          }}
          id="formatted-text-mask-input"
          InputProps={{
            inputComponent: TextMaskCustom as any
          }}
          fullWidth size="small" margin="dense"
          multiline rows={3} title="Zipcode" placeholder={inputType === "ZipCode" ? 'Enter Zip Codes separated by commas' : "Enter Zip Codes separated by commas"}
        />
      </Grid>
    </Grid>
  </Box >;
}

