import React, { useContext, useEffect, useState } from 'react'
import { AzureMapsContext, IAzureMapsContextProps } from 'react-azure-maps'
import { control } from 'azure-maps-control'
import { control as fscontrol } from '../../../customMapsControl/FullScreenControl'
import GeneralInfoMap from './GeneralInfoMap'
import ServiceAreaMap from './ServiceAreaMap'
import CountyMap from './CountyAreaMap'
import {
  useGetServiceAreaTableDataForCityByProviderLazyQuery,
  useGetServiceAreaTableDataForCountyByProviderLazyQuery,
  useGetServiceAreaTableDataForZipCodeAndRadiusByProviderLazyQuery,
  useGetServiceAreaTableDataForZipCodeByProviderLazyQuery,
} from '../../../__generated__/graphql'
import { Link, useLocation } from 'react-router-dom'
import PredictiveNeedsMap from './PredectiveNeedMap'
import { appInsights } from '../../../providers/appInsightProvider'
import { performanceText } from '../../../config/commonFunction'

interface MapControllerProps {
  MapType: string
  MapView: string
  AgeBracket: string
  Providerdata: any
  siteOfCare?: string
  ageBracket?: string
  serviceLine?: string
  year?: string
  countyName?: string
}

const MapController: React.FC<MapControllerProps> = (
  props: MapControllerProps
) => {
  const [isReady, setIsReady] = useState<boolean>(false)
  const { mapRef, isMapReady } = useContext<IAzureMapsContextProps>(
    AzureMapsContext
  )
  const [dataSourceByProvider, setDataSourceByProvider] = useState([])

  const search = useLocation().search
  const city = new URLSearchParams(search).get('city')
  const county = new URLSearchParams(search).get('county')
  const zip_code = new URLSearchParams(search).get('zipcode')
  const radius = new URLSearchParams(search).get('radius')
  let county_data: string[] = []

  if (county) {
    let counties_array = county.split(',')
    for (let i = 0; i < counties_array.length; i++) {
      const element = counties_array[i]
      let splitted_val = element.split(' - ')
      let string_val: string = splitted_val[1].trim() + splitted_val[0].trim()
      county_data.push(string_val)
    }
  }

  const [
    loadServiceAreaTableDataForCityByProvider,
    {
      data: ServiceAreaTableDataForCityByProvider,
      loading: ServiceAreaTableDataForCityByProviderLoading,
      error: ServiceAreaTableDataForCityByProviderError,
    },
  ] = useGetServiceAreaTableDataForCityByProviderLazyQuery({
    variables: {
      city,
    },
    fetchPolicy: 'network-only',
  })

  const [
    loadServiceAreaTableDataForZipCodeByProvider,
    {
      data: ServiceAreaTableDataForZipCodeByProvider,
      loading: ServiceAreaTableDataForZipCodeByProviderLoading,
      error: ServiceAreaTableDataForZipCodeByProviderError,
    },
  ] = useGetServiceAreaTableDataForZipCodeByProviderLazyQuery({
    variables: {
      zipcode: zip_code,
    },
    fetchPolicy: 'network-only',
  })

  const [
    loadServiceAreaTableDataForCountyByProvider,
    {
      data: ServiceAreaTableDataForCountyByProvider,
      loading: ServiceAreaTableDataForCountyByProviderLoading,
      error: ServiceAreaTableDataForCountyByProviderError,
    },
  ] = useGetServiceAreaTableDataForCountyByProviderLazyQuery({
    variables: {
      county: county_data.join(','),
    },
    fetchPolicy: 'network-only',
  })

  const [
    loadServiceAreaTableDataForZipCodeAndRadiusByProvider,
    {
      data: ServiceAreaTableDataByForZipCodeAndRadiusByProvider,
      loading: ServiceAreaTableDataByForZipCodeAndRadiusByProviderLoading,
      error: ServiceAreaTableDataByForZipCodeAndRadiusByProviderError,
    },
  ] = useGetServiceAreaTableDataForZipCodeAndRadiusByProviderLazyQuery({
    variables: {
      zipcode: zip_code,
      radius: radius ? parseInt(radius) : 1,
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (ServiceAreaTableDataByForZipCodeAndRadiusByProviderError instanceof Error) {
      appInsights.trackException({ exception: ServiceAreaTableDataByForZipCodeAndRadiusByProviderError, error: new Error("Failed to fetch Service Area Table Data for Zip code and radius.") })
    }
    else if (ServiceAreaTableDataForCountyByProviderError instanceof Error) {
      appInsights.trackException({ exception: ServiceAreaTableDataForCountyByProviderError, error: new Error("Failed to fetch Service Area Table Data for County.") })
    }
    else if (ServiceAreaTableDataForZipCodeByProviderError instanceof Error) {
      appInsights.trackException({ exception: ServiceAreaTableDataForZipCodeByProviderError, error: new Error("Failed to fetch Service Area Table Data for Zip Code.") })
    }
    else if (ServiceAreaTableDataForCityByProviderError instanceof Error) {
      appInsights.trackException({ exception: ServiceAreaTableDataForCityByProviderError, error: new Error("Failed to fetch Service Area Table Data for City.") })
    }
  }, [ServiceAreaTableDataByForZipCodeAndRadiusByProviderError, ServiceAreaTableDataForCountyByProviderError, ServiceAreaTableDataForZipCodeByProviderError, ServiceAreaTableDataForCityByProviderError])

  useEffect(() => {
    if (props.MapType === 'Demographic') {
      if (city) {
        loadServiceAreaTableDataForCityByProvider()
      } else if (zip_code && radius) {
        loadServiceAreaTableDataForZipCodeAndRadiusByProvider()
      } else if (zip_code) {
        loadServiceAreaTableDataForZipCodeByProvider()
      } else if (county) {
        loadServiceAreaTableDataForCountyByProvider()
      }
    }
  }, [])

  useEffect(() => {
    let Table_data: any = []
    if (
      ServiceAreaTableDataForCityByProvider &&
      ServiceAreaTableDataForCityByProvider?.udf_tbl_by_provider_dpr_market &&
      ServiceAreaTableDataForCityByProvider
        ?.udf_tbl_by_provider_dpr_market.length
    )
      Table_data =
        ServiceAreaTableDataForCityByProvider?.udf_tbl_by_provider_dpr_market
    else if (
      ServiceAreaTableDataForZipCodeByProvider &&
      ServiceAreaTableDataForZipCodeByProvider?.udf_tbl_by_provider_multi_zip &&
      ServiceAreaTableDataForZipCodeByProvider?.udf_tbl_by_provider_multi_zip
        .length
    )
      Table_data =
        ServiceAreaTableDataForZipCodeByProvider?.udf_tbl_by_provider_multi_zip
    else if (
      ServiceAreaTableDataForCountyByProvider &&
      ServiceAreaTableDataForCountyByProvider?.udf_tbl_by_provider_multi_county &&
      ServiceAreaTableDataForCountyByProvider?.udf_tbl_by_provider_multi_county
        .length
    )
      Table_data =
        ServiceAreaTableDataForCountyByProvider?.udf_tbl_by_provider_multi_county
    else if (
      ServiceAreaTableDataByForZipCodeAndRadiusByProvider &&
      ServiceAreaTableDataByForZipCodeAndRadiusByProvider.udf_zip_radius_by_provider_tbl &&
      ServiceAreaTableDataByForZipCodeAndRadiusByProvider
        .udf_zip_radius_by_provider_tbl.length
    )
      Table_data =
        ServiceAreaTableDataByForZipCodeAndRadiusByProvider.udf_zip_radius_by_provider_tbl
    let updated_array_by_provider: any = []
    if (Table_data && Table_data.length) {
      for (var i = 0; i < Table_data?.length; i++) {
        let table_json: any = {
          ...Table_data[i],
          total_performance_score: typeof Table_data[i].total_performance_score === "number" ? performanceText(Math.round(Table_data[i].total_performance_score))?.replace("(", "").replace(")", "") : "N/A",
        }
        updated_array_by_provider.push(table_json as any)
      }
    }
    setDataSourceByProvider(updated_array_by_provider)
  }, [
    ServiceAreaTableDataForCityByProviderLoading,
    ServiceAreaTableDataForCountyByProviderLoading,
    ServiceAreaTableDataForZipCodeByProviderLoading,
    ServiceAreaTableDataByForZipCodeAndRadiusByProviderLoading,
  ])

  useEffect(() => {
    if (isMapReady && mapRef) {
      const zoomControl = new control.ZoomControl()
      const stylecontrol = new control.StyleControl({})
      const compasscontrol = new control.CompassControl()
      const pitchcontrol = new control.PitchControl()
      //@ts-ignore
      const fullScreenControl = new fscontrol.FullscreenControl({
        style: 'auto',
      })

      mapRef.controls.add([
        zoomControl,
        stylecontrol,
        compasscontrol,
        pitchcontrol,
        fullScreenControl,
      ])
      setIsReady(true)
    }
  }, [isMapReady, mapRef])

  return (
    <>
      {props.MapType === 'ServiceArea' ? (
        <ServiceAreaMap
          reference={mapRef}
          mapReady={isReady}
          MapView={props.MapView}
          AgeBracket={props.AgeBracket}
          Providerdata={props.Providerdata} type="service-area"
        />
      ) : props.MapType === 'GeneralInfo' ? (
        <GeneralInfoMap reference={mapRef} mapReady={isReady} />
      ) : props.MapType === 'Demographic' ? (
        <ServiceAreaMap
          reference={mapRef}
          mapReady={isReady}
          MapView={props.MapView}
          AgeBracket={props.AgeBracket}
          Providerdata={dataSourceByProvider} type="demographics"
        />
      ) : props.MapType === 'County' ? (
        <CountyMap reference={mapRef} mapReady={isReady} countyName={props.countyName ? props.countyName : ""} />
      ) :
        props.MapType === 'PredictiveNeeds' ? (
          <PredictiveNeedsMap reference={mapRef} mapReady={isReady}
            siteOfCare={props.siteOfCare ? props.siteOfCare : ""}
            ageBracket={props.ageBracket ? props.ageBracket : ""}
            serviceLine={props.serviceLine ? props.serviceLine : ""}
            year={props.year ? props.year : ""} />
        )
          :
          null}
    </>
  )
}

export default MapController
