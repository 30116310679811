import { Button, Grid } from '@mui/material';
import React from 'react'
import { styled } from '@mui/material/styles';
import { Box } from "@mui/system";
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import { renderTitleHeader } from '../../CommonComponents/RenderTitleHeader/RenderTitleHeader';

const OuterDiv = styled(Box)(() => ({
    backgroundColor: "#ddecde",
    borderRadius: "6px"
}));

const OuterDivText = styled(Grid)(() => ({
    textAlign: "center",
    fontSize: "18px",
    fontWeight: "600",
    color: "#22577A",
}));

const InnerDivText1 = styled(Box)(() => ({
    color: "#212121",
    fontSize: "12px"
}));

const InnerDivText2 = styled(Box)(() => ({
    fontWeight: "550",
    color: "#212121",
    fontSize: "13px",
    wordBreak: "break-word"

}));

const InnerGrid = styled(Grid)(() => ({
    lineHeight: "20px",
    paddingTop: "12px !important"
}));

export const MarketInsightsTopHeader: React.FC = () => {

    const search = useLocation().search;
    const city = new URLSearchParams(search).get('city');
    const zipcode = new URLSearchParams(search).get('zipcode');
    const radius = new URLSearchParams(search).get('radius');
    const county = new URLSearchParams(search).get('county');

    return <React.Fragment>
        <Grid container spacing={1} padding="30px 15px 15px 0px">
            <OuterDivText xs={1.5} item
                justifyContent="space-between"
                alignItems="center"
            >
                Defined Market
            </OuterDivText>
            <Grid item xs={10.5}>
                <OuterDiv>
                    <Grid container spacing={2} padding="0px 5px 5px 20px">
                        {city ? <InnerGrid item lg={8}>
                            <InnerDivText1>DPR Market City</InnerDivText1>
                            <InnerDivText2>{city}</InnerDivText2>
                        </InnerGrid> : null}
                        {zipcode && !radius ? <InnerGrid item lg={8}>
                            <InnerDivText1>Zip Code</InnerDivText1>
                            <InnerDivText2 style={{
                                width: "100%",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap"
                            }}>{renderTitleHeader(zipcode ? zipcode : "")}</InnerDivText2>
                        </InnerGrid> : null}
                        {zipcode && radius ? <InnerGrid item lg={8} >
                            <InnerDivText1>Zip Code, Radius</InnerDivText1>
                            <InnerDivText2>{radius} miles from {zipcode}</InnerDivText2>
                        </InnerGrid> : null}
                        {county ? <InnerGrid item lg={8}>
                            <InnerDivText1>County</InnerDivText1>
                            <InnerDivText2 style={{
                                width: "100%",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap"
                            }}>{renderTitleHeader(county ? county : "")}</InnerDivText2>
                        </InnerGrid> : null}
                        <Grid item lg={4} container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="flex-end" paddingTop="6px">
                            <Link to="/" style={{ textDecoration: "none" }}>
                                <Button data-change-insights-id="Change Insights" variant="contained" style={{ background: "#4CAF50", margin: "auto 10px 10px 0px", textTransform: "capitalize", boxShadow: "none" }}>
                                    Change Insights
                                </Button>
                            </Link>
                        </Grid>
                    </Grid>
                </OuterDiv>
            </Grid>
        </Grid>
    </React.Fragment>
}

