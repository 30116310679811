import React, { useEffect, useState } from 'react'
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  PDFViewer,
  Image,
  Polygon,
  Svg,
  Canvas,
  Path,
  pdf,
} from '@react-pdf/renderer'
import ReactDOMServer from 'react-dom/server'
import dprlogo from '../../../Assets/dprlogoImage.png'
import panaromalogo from '../../../Assets/PanoramaImage.png'
import {
  alpha,
  Box,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Rating,
  Skeleton,
  Stack,
  styled,
  Tooltip,
  Typography,
} from '@mui/material'
import { Redirect, useLocation } from 'react-router-dom'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import StarIcon from '@mui/icons-material/Star'
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined'
import {
  backgroundColor,
  performanceText,
  textColor,
} from '../../../config/commonFunction'
import { PerformanceDialog } from '../../Pages/ClientInsights/Hospitals/Performance/PerformanceDialog'
import { Factors } from '../../Pages/ClientInsights/Hospitals/Performance/Factors'
import { useGetPerformanceDataQuery } from '../../../__generated__/graphql'
import ReactDOM, { render } from 'react-dom'
import { saveAs } from 'file-saver'
import { useSnackbar, VariantType } from 'notistack'
import { renderTitleHeader } from '../../CommonComponents/RenderTitleHeader/RenderTitleHeader'
import CloseIcon from '@mui/icons-material/Close'
import DialogAlert from '../../CommonComponents/DialogBox/dialogAlert'
import { SSConst } from '../../../config/config'
import { ExportStatus, useExportData } from '../../../customHooks/useExportData'

interface PerformancePdfTableProps {
  getDataFunction: any
}

export const PerformancePdfTable: React.FC<PerformancePdfTableProps> = ({ getDataFunction }: PerformancePdfTableProps) => {
  const ClientPerformanceText = styled(Grid)(() => ({
    fontSize: '14px',
    margin: 'auto',
    fontWeight: 'bold',
  }))
  const GreenBoxStyle = styled(Box)(() => ({
    border: '2px solid #E8F5E9',
    borderRadius: '15px',
  }))

  const HeadingBox = styled(Box)(() => ({
    margin: '10px 0px 0px 10px',
    color: '#22577A',
    fontSize: '14px',
    fontWeight: '600',
  }))

  const ViewMoreGrid = styled(Grid)(() => ({
    background: '#E8F5E9',
    width: '100%',
    fontSize: '13px',
    marginTop: 'auto',
    borderBottomLeftRadius: '15px',
    borderBottomRightRadius: '15px',
  }))

  const PerformanceTypeText = styled(Grid)(() => ({
    textAlign: 'center',
    textTransform: 'capitalize',
    fontSize: '14px',
    fontWeight: '600',
    margin: 'auto',
  }))
  const search = useLocation().search
  const provider = new URLSearchParams(search).get('provider')

  const pdfStyle = StyleSheet.create({
    body: {
      paddingTop: 15,
      paddingBottom: 45,
      paddingHorizontal: 15,
    },

    headerView: {
      display: 'flex',
      flexDirection: 'row',
      borderTop: '1px solid #EEE',
      paddingTop: 8,
      paddingBottom: 8,
      textAlign: 'center',
      fontSize: '14px',
      color: '#3E3E3E',
      backgroundColor: '#F3F3F3',
      borderRadius: '5px',
    },
    rowView: {
      display: 'flex',
      flexDirection: 'row',
      borderTop: '1px solid #EEE',
      paddingTop: 8,
      paddingBottom: 8,
      textAlign: 'center',
      fontSize: '12px',
      color: '#3E3E3E',
    },
    footer: {
      flexDirection: 'column',
      bottom: 10,
      left: 10,
      position: 'absolute',
    },
  })

  const RenderRating = (ratingprops) =>
    parseInt(ratingprops?.rating) == 1 ? (
      <View style={{ flexDirection: 'row' }}>
        <View>
          <Svg viewBox={ratingprops.viewbox}>
            <Path
              fill="#FF0707"
              stroke="#FF0707"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-10px' }}>
          <Svg viewBox={ratingprops.viewbox}>
            <Path
              fill="#CCCCCC"
              stroke="#CCCCCC"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-12px' }}>
          <Svg viewBox={ratingprops.viewbox}>
            <Path
              fill="#CCCCCC"
              stroke="#CCCCCC"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-12px' }}>
          <Svg viewBox={ratingprops.viewbox}>
            <Path
              fill="#CCCCCC"
              stroke="#CCCCCC"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-12px' }}>
          <Svg viewBox={ratingprops.viewbox}>
            <Path
              fill="#CCCCCC"
              stroke="#CCCCCC"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
      </View>
    ) : parseInt(ratingprops?.rating) == 2 ? (
      <View style={{ flexDirection: 'row' }}>
        <View>
          <Svg viewBox={ratingprops.viewbox}>
            <Path
              fill="#FB743F"
              stroke="#FB743F"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-10px' }}>
          <Svg viewBox={ratingprops.viewbox}>
            <Path
              fill="#FB743F"
              stroke="#FB743F"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-12px' }}>
          <Svg viewBox={ratingprops.viewbox}>
            <Path
              fill="#CCCCCC"
              stroke="#CCCCCC"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-12px' }}>
          <Svg viewBox={ratingprops.viewbox}>
            <Path
              fill="#CCCCCC"
              stroke="#CCCCCC"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-12px' }}>
          <Svg viewBox={ratingprops.viewbox}>
            <Path
              fill="#CCCCCC"
              stroke="#CCCCCC"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
      </View>
    ) : parseInt(ratingprops?.rating) == 3 ? (
      <View style={{ flexDirection: 'row' }}>
        <View>
          <Svg viewBox={ratingprops.viewbox}>
            <Path
              fill="#FAAF00"
              stroke="#FAAF00"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-10px' }}>
          <Svg viewBox={ratingprops.viewbox}>
            <Path
              fill="#FAAF00"
              stroke="#FAAF00"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-12px' }}>
          <Svg viewBox={ratingprops.viewbox}>
            <Path
              fill="#FAAF00"
              stroke="#FAAF00"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-12px' }}>
          <Svg viewBox={ratingprops.viewbox}>
            <Path
              fill="#CCCCCC"
              stroke="#CCCCCC"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-12px' }}>
          <Svg viewBox={ratingprops.viewbox}>
            <Path
              fill="#CCCCCC"
              stroke="#CCCCCC"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
      </View>
    ) : parseInt(ratingprops?.rating) == 4 ? (
      <View style={{ flexDirection: 'row' }}>
        <View>
          <Svg viewBox={ratingprops.viewbox}>
            <Path
              fill="#73E793"
              stroke="#73E793"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-10px' }}>
          <Svg viewBox={ratingprops.viewbox}>
            <Path
              fill="#73E793"
              stroke="#73E793"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-12px' }}>
          <Svg viewBox={ratingprops.viewbox}>
            <Path
              fill="#73E793"
              stroke="#73E793"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-12px' }}>
          <Svg viewBox={ratingprops.viewbox}>
            <Path
              fill="#73E793"
              stroke="#73E793"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-12px' }}>
          <Svg viewBox={ratingprops.viewbox}>
            <Path
              fill="#CCCCCC"
              stroke="#CCCCCC"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
      </View>
    ) : parseInt(ratingprops?.rating) == 5 ? (
      <View style={{ flexDirection: 'row' }}>
        <View>
          <Svg viewBox={ratingprops.viewbox}>
            <Path
              fill="#4CAF50"
              stroke="#4CAF50"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-10px' }}>
          <Svg viewBox={ratingprops.viewbox}>
            <Path
              fill="#4CAF50"
              stroke="#4CAF50"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-12px' }}>
          <Svg viewBox={ratingprops.viewbox}>
            <Path
              fill="#4CAF50"
              stroke="#4CAF50"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-12px' }}>
          <Svg viewBox={ratingprops.viewbox}>
            <Path
              fill="#4CAF50"
              stroke="#4CAF50"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-12px' }}>
          <Svg viewBox={ratingprops.viewbox}>
            <Path
              fill="#4CAF50"
              stroke="#4CAF50"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
      </View>
    ) : (
      <View style={{ flexDirection: 'row' }}>
        <View>
          <Svg viewBox={ratingprops.viewbox}>
            <Path
              fill="#CCCCCC"
              stroke="#CCCCCC"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-10px' }}>
          <Svg viewBox={ratingprops.viewbox}>
            <Path
              fill="#CCCCCC"
              stroke="#CCCCCC"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-12px' }}>
          <Svg viewBox={ratingprops.viewbox}>
            <Path
              fill="#CCCCCC"
              stroke="#CCCCCC"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-12px' }}>
          <Svg viewBox={ratingprops.viewbox}>
            <Path
              fill="#CCCCCC"
              stroke="#CCCCCC"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-12px' }}>
          <Svg viewBox={ratingprops.viewbox}>
            <Path
              fill="#CCCCCC"
              stroke="#CCCCCC"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
      </View>
    )

  const date = Date().split(' ')
  const monthConverter = (month) => {
    const monthArray = [
      ['Jan', '01'],
      ['Feb', '02'],
      ['Mar', '03'],
      ['Apr', '04'],
      ['May', '05'],
      ['Jun', '06'],
      ['Jul', '07'],
      ['Aug', '08'],
      ['Sep', '09'],
      ['Oct', '10'],
      ['Nov', '11'],
      ['Dec', '12'],
    ]
    for (let i = 0; i < monthArray.length; i++) {
      if (monthArray[i][0] == month) return monthArray[i][1]
    }
  }
  const formatedDateTime = `${monthConverter(date[1])}/${date[2]}/${date[3]}`
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const ShowSnackBar = (
    message,
    variant: VariantType,
    duration,
    key?: string
  ) => {
    enqueueSnackbar(<div>{message}</div>, {
      variant: variant,
      persist: false,
      anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
      style: {
        flexDirection: 'row',
        maxWidth: '50px',
        marginLeft: '-150px',
      },
      autoHideDuration: duration,
      key: key,
      action: (key) => (
        <IconButton
          color="inherit"
          aria-label="dismiss"
          onClick={() => closeSnackbar(key)}
        >
          <CloseIcon />
        </IconButton>
      ),
    })
    return key
  }

  const MyDocument = (ratings, Performance_SummaryData, OverallPositiveArray, OverallNegativeArray, fiscal_rating_data, fiscal_score_data, positive_fiscal, negative_fiscal, operations_rating_data, operations_score_data, negative_clinical, positive_clinical, patient_experience_rating_data, patient_experience_score_data, positive_patient_exp, negative_patient_exp, clinical_outcome_rating_data, clinical_outcome_score_data, positive_clinical_outcomes, negative_clinical_outcomes, facility_conditions_score_data, facility_conditions_rating_data, positive_facility_conditions, negative_facility_conditions, OrangeTopDivStyle) => (
    <Document>
      <Page size="A4" style={pdfStyle.body}>
        <View fixed>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              paddingBottom: '10px',
              paddingRight: '20px',
            }}
          >
            <Image
              src={dprlogo}
              style={{
                width: '74px',
                height: '45px',
                padding: '5px',
              }}
            ></Image>

            <View
              style={{
                borderRight: '1px solid #CCCCCC',
                height: '30px',
                marginTop: '8px',
                marginLeft: '10px',
              }}
            ></View>

            <Image
              src={panaromalogo}
              style={{
                width: '100px',
                height: '30px',
                marginLeft: '20px',
                marginTop: '8px',
                padding: 5,
              }}
            ></Image>

            <Text
              style={{
                fontSize: '20px',
                color: '#000000',
                marginLeft: 'auto',
                marginTop: '15px',
              }}
            >
              CLIENT INSIGHTS
            </Text>
          </View>

          <View
            style={{
              borderBottom: '2px solid #4CAF50',
              width: '100%',
              marginBottom: '20px',
              marginTop: 10,
            }}
          ></View>
        </View>
        <View wrap style={{ flexDirection: 'row' }}>
          <Text
            style={{
              fontSize: '20px',
              color: '#000000',
              marginRight: 'auto',
              marginTop: '8px',
            }}
          >
            PERFORMANCE
          </Text>
          <View
            style={{
              borderBottom: '1px solid black',
              width: '70%',
              marginBottom: '10px',
              marginTop: 10,
            }}
          ></View>
        </View>

        <View
          style={{
            position: 'relative',
            width: '568px',
            height: '27.33px',
            backgroundColor: backgroundColor(
              Math.round(ratings?.overall_rating)
            ),
            border: `1px solid ${textColor(
              Math.round(ratings?.overall_rating)
            )}`,
            borderRadius: '4.63295px',
            marginTop: '15px',
            marginBottom: '-50px',
            flexDirection: 'row',
            alignItems: 'stretch',
            alignContent: 'space-around',
            padding: '0.5px',
          }}
        >
          <Text
            style={{
              fontSize: 10,
              fontWeight: 900,
              marginTop: '7px',
              marginLeft: '10px',
            }}
          >
            CLIENT PERFORMANCE SCORE
          </Text>
          <View style={{ width: '220px' }}></View>
          {/* <Image style={{ marginTop: '4px', height: '15px', width: '80px', marginLeft: '50px' }} src={overallPerformanceImageSrc}></Image> */}
          <View
            style={{
              marginTop: '5px',
              height: '25px',
              width: '120px',
              marginLeft: '50px',
            }}
          >
            <RenderRating
              rating={Math.round(Performance_SummaryData.overall_rating)}
              viewbox="0 0 45 45"
            />
          </View>
          <Text
            style={{
              fontSize: 10,
              marginTop: '6.5px',
              fontWeight: 800,
              marginLeft: 'auto',
              paddingRight: '10px',
            }}
          >
            {performanceText(Math.round(ratings?.overall_rating))
              ?.replace('(', '')
              .replace(')', '')}
          </Text>
        </View>
        <View wrap style={{ flexDirection: 'row' }}>
          <View
            style={{
              position: 'absolute',
              width: '100px',
              height: '44px',
              top: '70px',
              backgroundColor: '#FFFFFF',
              border: '2px solid #E8F5E9',
              borderRadius: '5.52171px',
              flexDirection: 'column',
            }}
          >
            <Text style={{ fontSize: 8, padding: '5px', color: '#22577A' }}>
              Fiscal Stewardship
            </Text>
            <View
              style={{
                flexDirection: 'row',
                marginTop: '10px',
                width: '80px',
              }}
            >
              <Text
                style={{ fontSize: 8, marginLeft: '5px', marginTop: '5px' }}
              >
                {ratings['fiscal_stewardship']
                  ? `(${Math.round(ratings['fiscal_stewardship'])}/5)`
                  : ''}
              </Text>
              <View style={{ marginLeft: '-25px', marginTop: '5px' }}>
                {ratings['fiscal_stewardship'] ? (
                  <Svg viewBox="0 0  35 35">
                    <Path
                      fill={textColor(
                        Math.round(ratings['fiscal_stewardship'])
                      )}
                      stroke={textColor(
                        Math.round(ratings['fiscal_stewardship'])
                      )}
                      d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
                    />
                  </Svg>
                ) : (
                  <Svg viewBox="0 0 30 30" />
                )}
              </View>
              <Text
                style={{
                  fontSize: 8,
                  marginLeft: '-65px',
                  marginTop: '5px',
                  width: '90px',
                  textAlign: 'right',
                }}
              >
                {performanceText(
                  Math.round(ratings['fiscal_stewardship']),
                  'capitalize'
                )
                  ?.replace('(', '')
                  .replace(')', '')}
              </Text>
            </View>
          </View>
          <View
            style={{
              position: 'absolute',
              width: '100px',
              height: '44px',
              top: '70px',
              marginLeft: '115px',
              backgroundColor: '#FFFFFF',
              border: '2px solid #E8F5E9',
              borderRadius: '5.52171px',
            }}
          >
            <Text style={{ fontSize: 8, padding: '5px', color: '#22577A' }}>
              Operations
            </Text>
            <View
              style={{
                flexDirection: 'row',
                marginTop: '10px',
                width: '80px',
              }}
            >
              <Text
                style={{ fontSize: 8, marginLeft: '5px', marginTop: '5px' }}
              >
                {ratings['clinical_operations']
                  ? `(${Math.round(ratings['clinical_operations'])}/5)`
                  : ''}
              </Text>
              <View style={{ marginLeft: '-25px', marginTop: '5px' }}>
                {ratings['clinical_operations'] ? (
                  <Svg viewBox="0 0  35 35">
                    <Path
                      fill={textColor(
                        Math.round(ratings['clinical_operations'])
                      )}
                      stroke={textColor(
                        Math.round(ratings['clinical_operations'])
                      )}
                      d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
                    />
                  </Svg>
                ) : (
                  <Svg viewBox="0 0 30 30" />
                )}
              </View>
              <Text
                style={{
                  fontSize: 8,
                  marginLeft: '-65px',
                  marginTop: '5px',
                  width: '90px',
                  textAlign: 'right',
                }}
              >
                {performanceText(
                  Math.round(ratings['clinical_operations']),
                  'capitalize'
                )
                  ?.replace('(', '')
                  .replace(')', '')}
              </Text>
            </View>
          </View>
          <View
            wrap={false}
            style={{
              position: 'absolute',
              width: '100px',
              height: '44px',
              marginLeft: '230px',
              top: '70px',
              backgroundColor: '#FFFFFF',
              border: '2px solid #E8F5E9',
              borderRadius: '5.52171px',
            }}
          >
            <Text style={{ fontSize: 8, padding: '5px', color: '#22577A' }}>
              Patient Experience
            </Text>
            <View
              style={{
                flexDirection: 'row',
                marginTop: '10px',
                width: '80px',
              }}
            >
              <Text
                style={{ fontSize: 8, marginLeft: '5px', marginTop: '5px' }}
              >
                {ratings['experiential_design']
                  ? `(${Math.round(ratings['experiential_design'])}/5)`
                  : ''}
              </Text>
              <View style={{ marginLeft: '-25px', marginTop: '5px' }}>
                {ratings['experiential_design'] ? (
                  <Svg viewBox="0 0  35 35">
                    <Path
                      fill={textColor(
                        Math.round(ratings['experiential_design'])
                      )}
                      stroke={textColor(
                        Math.round(ratings['experiential_design'])
                      )}
                      d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
                    />
                  </Svg>
                ) : (
                  <Svg viewBox="0 0 30 30" />
                )}
              </View>
              <Text
                style={{
                  fontSize: 8,
                  marginLeft: '-65px',
                  marginTop: '5px',
                  width: '90px',
                  textAlign: 'right',
                }}
              >
                {performanceText(
                  Math.round(ratings['experiential_design']),
                  'capitalize'
                )
                  ?.replace('(', '')
                  .replace(')', '')}
              </Text>
            </View>
          </View>
          <View
            wrap={false}
            style={{
              position: 'absolute',
              width: '100px',
              height: '44px',
              marginLeft: '345px',
              top: '70px',
              backgroundColor: '#FFFFFF',
              border: '2px solid #E8F5E9',
              borderRadius: '5.52171px',
            }}
          >
            <Text style={{ fontSize: 8, padding: '5px', color: '#22577A' }}>
              Clinical Outcomes
            </Text>
            <View
              style={{
                flexDirection: 'row',
                marginTop: '10px',
                width: '80px',
              }}
            >
              <Text
                style={{ fontSize: 8, marginLeft: '5px', marginTop: '5px' }}
              >
                {ratings['clinical_outcomes']
                  ? `(${Math.round(ratings['clinical_outcomes'])}/5)`
                  : ''}
              </Text>
              <View style={{ marginLeft: '-25px', marginTop: '5px' }}>
                {ratings['clinical_outcomes'] ? (
                  <Svg viewBox="0 0  35 35">
                    <Path
                      fill={textColor(
                        Math.round(ratings['clinical_outcomes'])
                      )}
                      stroke={textColor(
                        Math.round(ratings['clinical_outcomes'])
                      )}
                      d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
                    />
                  </Svg>
                ) : (
                  <Svg viewBox="0 0 30 30" />
                )}
              </View>
              <Text
                style={{
                  fontSize: 8,
                  marginLeft: '-65px',
                  marginTop: '5px',
                  width: '90px',
                  textAlign: 'right',
                }}
              >
                {performanceText(
                  Math.round(ratings['clinical_outcomes']),
                  'capitalize'
                )
                  ?.replace('(', '')
                  .replace(')', '')}
              </Text>
            </View>
          </View>
          <View
            style={{
              position: 'absolute',
              width: '100px',
              height: '44px',
              marginLeft: '460px',
              top: '70px',
              backgroundColor: '#FFFFFF',
              border: '2px solid #E8F5E9',
              borderRadius: '5.52171px',
            }}
          >
            <Text style={{ fontSize: 8, padding: '5px', color: '#22577A' }}>
              Facility Conditions
            </Text>
            <View
              style={{
                flexDirection: 'row',
                marginTop: '10px',
                width: '80px',
              }}
            >
              <Text
                style={{ fontSize: 8, marginLeft: '5px', marginTop: '5px' }}
              >
                {ratings['facility_conditions']
                  ? `(${Math.round(ratings['facility_conditions'])}/5)`
                  : ''}
              </Text>
              <View style={{ marginLeft: '-25px', marginTop: '5px' }}>
                {ratings['facility_conditions'] ? (
                  <Svg viewBox="0 0  35 35">
                    <Path
                      fill={textColor(
                        Math.round(ratings['facility_conditions'])
                      )}
                      stroke={textColor(
                        Math.round(ratings['facility_conditions'])
                      )}
                      d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
                    />
                  </Svg>
                ) : (
                  <Svg viewBox="0 0 30 30" />
                )}
              </View>
              <Text
                style={{
                  fontSize: 8,
                  marginLeft: '-65px',
                  marginTop: '5px',
                  width: '90px',
                  textAlign: 'right',
                }}
              >
                {performanceText(
                  Math.round(ratings['facility_conditions']),
                  'capitalize'
                )
                  ?.replace('(', '')
                  .replace(')', '')}
              </Text>
            </View>
          </View>
        </View>
        <View
          wrap
          style={{
            flexDirection: 'row',
            justifyContent:
              OverallPositiveArray.length > OverallNegativeArray.length
                ? 'flex-start'
                : 'flex-end',
          }}
        >
          <View wrap style={{ flexDirection: 'column' }}>
            <Text
              style={{
                fontSize: 12,
                marginTop: '130px',
                color: '#22577A',
                marginLeft: '10px',
                fontWeight: 800,
                marginBottom: '10px',
              }}
            >
              Positive Factors
            </Text>
            <View
              wrap={false}
              style={{
                position: 'relative',
                width: '263.66px',
                padding: '5px',
                marginLeft: '20px',
              }}
            ></View>
            <View style={{ flexDirection: 'column' }}>
              {OverallPositiveArray
                ? OverallPositiveArray.map((data) => {
                  return data[1] != 'N/A' ? (
                    <View
                      wrap={false}
                      style={{
                        position: 'relative',
                        width: '271.5px',
                        padding: '5px',
                        marginLeft: '0px',
                        marginBottom: '10px',
                        backgroundColor: '#FAFAFA',
                        border: '1px solid #E8F5E9',
                        borderRadius: '8.3px',
                      }}
                    >
                      <Text style={{ fontSize: 7 }}>{data[1]}</Text>
                    </View>
                  ) : null
                })
                : 'N/A'}
            </View>
          </View>
          <View wrap style={{ flexDirection: 'column' }}>
            <Text
              style={{
                fontSize: 12,
                marginTop: '130px',
                color: '#22577A',
                marginLeft: '25px',
                fontWeight: 800,
              }}
            >
              Risk Factors
            </Text>
            <View
              wrap={false}
              style={{
                position: 'relative',
                width: '263.66px',
                padding: '5px',
                marginLeft: '20px',
              }}
            ></View>
            <View>
              {OverallNegativeArray
                ? OverallNegativeArray.map((data) => {
                  return data[1] != 'N/A' ? (
                    <View
                      wrap={false}
                      style={{
                        width: '271.5px',
                        padding: '5px',
                        marginLeft: '15px',
                        marginTop: '10px',
                        backgroundColor: '#FAFAFA',
                        border: '1px solid #E8F5E9',
                        borderRadius: '8.3px',
                      }}
                    >
                      <Text style={{ fontSize: 7 }}>{data[1]}</Text>
                    </View>
                  ) : null
                })
                : 'N/A'}
            </View>
          </View>
        </View>
        <View
          style={{
            border: '2px solid #D9D9D9',
            marginTop: '15px',
            marginBottom: '15px',
            backgroundColor: '#D9D9D9',
          }}
        ></View>
        <View
          wrap={false}
          style={{
            position: 'relative',
            width: '100%',
            marginLeft: '0px',
            backgroundColor: '#FFFFFF',
            border: '1px solid #E8F5E9',
            borderRadius: '8.3px',
            padding: '5px',
          }}
        >
          <View style={{ flexDirection: 'row' }}>
            <View>
              <Text
                style={{
                  fontSize: 12,
                  marginTop: '17px',
                  color: '#22577A',
                  marginLeft: '8px',
                  fontWeight: 800,
                }}
              >
                Fiscal Stewardship
              </Text>
            </View>
            {ratings['fiscal_stewardship'] ? (
              <View
                style={{
                  border: `1px solid ${textColor(
                    Math.round(ratings['fiscal_stewardship'])
                  )}`,
                  backgroundColor: backgroundColor(
                    Math.round(ratings['fiscal_stewardship'])
                  ),
                  height: '25px',
                  flexDirection: 'row',
                  borderRadius: '3.08px',
                  top: 11,
                  left: 5,
                  paddingHorizontal: '5px',
                  paddingTop: '2px',
                }}
              >
                <View
                  style={{
                    marginTop: '5px',
                    height: '15px',
                    width: '80px',
                    marginBottom: '10px',
                  }}
                >
                  <RenderRating
                    rating={Math.round(ratings['fiscal_stewardship'])}
                    viewbox="0 0 35 35"
                  />
                </View>
                <Text
                  style={{
                    fontSize: 7,
                    marginTop: '6.25px',
                    marginLeft: '5px',
                  }}
                >
                  {performanceText(Math.round(ratings['fiscal_stewardship']))
                    ?.replace('(', '')
                    .replace(')', '')}
                </Text>
              </View>
            ) : (
              <View></View>
            )}
          </View>
          <View
            wrap
            style={{
              flexDirection: 'row',
              marginTop: '15px',
              marginBottom: '50px',
            }}
          >
            <View
              style={{
                position: 'absolute',
                width: '20%',
                height: '44px',
                backgroundColor: '#FFFFFF',
                flexDirection: 'column',
              }}
            >
              <Text
                style={{ fontSize: 8, padding: '5px', marginLeft: '16px' }}
              >
                Net Profit Margin
              </Text>
              <Text
                style={{
                  fontSize: 8,
                  padding: '1px',
                  marginLeft: '20px',
                  color: textColor(
                    Math.round(
                      fiscal_rating_data?.net_operating_profit_margin
                    )
                  ),
                  width: '80px',
                }}
              >
                {fiscal_score_data?.net_operating_profit_margin || fiscal_score_data?.net_operating_profit_margin === 0 ? (fiscal_score_data.net_operating_profit_margin * 100).toFixed(1) + "%" : "N/A"}
                {fiscal_rating_data?.net_operating_profit_margin
                  ? performanceText(
                    Math.round(
                      fiscal_rating_data?.net_operating_profit_margin
                    )
                  )
                  : ''}
              </Text>
            </View>
            <View
              style={{
                position: 'absolute',
                width: '20%',
                height: '44px',
                marginLeft: '115px',
                backgroundColor: '#FFFFFF',
              }}
            >
              <Text style={{ fontSize: 8, padding: '5px' }}>
                Capital Expenditures
              </Text>
              <Text
                style={{
                  fontSize: 8,
                  padding: '1px',
                  marginLeft: '5px',
                  color: textColor(
                    Math.round(fiscal_rating_data?.capital_expenditures)
                  ),
                  width: '80px',
                }}
              >
                {fiscal_score_data?.capital_expenditures ||
                  fiscal_score_data?.capital_expenditures === 0
                  ? fiscal_score_data.capital_expenditures.toLocaleString(
                    'en-US',
                    {
                      style: 'currency',
                      currency: 'USD',
                    }
                  )
                  : 'N/A'}
                {fiscal_rating_data?.capital_expenditures
                  ? performanceText(
                    Math.round(fiscal_rating_data?.capital_expenditures)
                  )
                  : ''}
              </Text>
            </View>
            <View
              style={{
                position: 'absolute',
                width: '20%',
                height: '44px',
                marginLeft: '230px',
                backgroundColor: '#FFFFFF',
              }}
            >
              <Text style={{ fontSize: 8, padding: '5px' }}>
                Debt to Equity Ratio
              </Text>
              <Text
                style={{
                  fontSize: 8,
                  padding: '1px',
                  marginLeft: '5px',
                  color: textColor(
                    Math.round(fiscal_rating_data?.debt_to_equity_ratio)
                  ),
                  width: '80px',
                }}
              >
                {fiscal_score_data?.debt_to_equity_ratio ||
                  fiscal_score_data?.debt_to_equity_ratio === 0
                  ? fiscal_score_data.debt_to_equity_ratio.toFixed(2)
                  : 'N/A'}
                {fiscal_rating_data?.debt_to_equity_ratio
                  ? performanceText(
                    Math.round(fiscal_rating_data?.debt_to_equity_ratio)
                  )
                  : ''}
              </Text>
            </View>
            <View
              style={{
                position: 'absolute',
                width: '20%',
                height: '44px',
                marginLeft: '345px',
                backgroundColor: '#FFFFFF',
              }}
            >
              <Text style={{ fontSize: 8, padding: '5px' }}>
                Days Cash on Hand
              </Text>
              <Text
                style={{
                  fontSize: 8,
                  padding: '1px',
                  marginLeft: '5px',
                  color: textColor(
                    Math.round(fiscal_rating_data?.days_cash_on_hand)
                  ),
                  width: '80px',
                }}
              >
                {fiscal_score_data?.days_cash_on_hand ||
                  fiscal_score_data?.days_cash_on_hand === 0
                  ? fiscal_score_data.days_cash_on_hand.toFixed(1)
                  : 'N/A'}
                {fiscal_rating_data?.days_cash_on_hand
                  ? performanceText(
                    Math.round(fiscal_rating_data?.days_cash_on_hand)
                  )
                  : ''}
              </Text>
            </View>
            <View
              style={{
                position: 'absolute',
                width: '20%',
                height: '44px',
                marginLeft: '460px',
                backgroundColor: '#FFFFFF',
              }}
            >
              <Text style={{ fontSize: 8, padding: '5px' }}>
                Medicare Mix
              </Text>
              <Text
                style={{
                  fontSize: 8,
                  padding: '1px',
                  marginLeft: '5px',
                  color: textColor(
                    Math.round(fiscal_rating_data?.payor_mix_medicare_days)
                  ),
                  width: '80px',
                }}
              >
                {fiscal_score_data?.payor_mix_medicare_days || fiscal_score_data.payor_mix_medicare_days === 0 ? (fiscal_score_data.payor_mix_medicare_days * 100).toFixed(1) + "%" : "N/A"}
                {fiscal_rating_data?.payor_mix_medicare_days
                  ? performanceText(
                    Math.round(fiscal_rating_data?.payor_mix_medicare_days)
                  )
                  : ''}
              </Text>
            </View>
            <View
              style={{
                position: 'absolute',
                width: '20%',
                height: '44px',
                marginTop: '40px',
                marginLeft: '15px',
                backgroundColor: '#FFFFFF',
              }}
            >
              <Text style={{ fontSize: 8, padding: '5px' }}>
                Current Ratio
              </Text>
              <Text
                style={{
                  fontSize: 8,
                  padding: '1px',
                  marginLeft: '5px',
                  color: textColor(
                    Math.round(fiscal_rating_data?.current_ratio)
                  ),
                  width: '80px',
                }}
              >
                {fiscal_score_data?.current_ratio ||
                  fiscal_score_data?.current_ratio === 0
                  ? fiscal_score_data.current_ratio
                  : 'N/A'}
                {fiscal_rating_data?.current_ratio
                  ? performanceText(
                    Math.round(fiscal_rating_data?.current_ratio)
                  )
                  : ''}
              </Text>
            </View>
            <View
              style={{
                position: 'absolute',
                width: '20%',
                height: '44px',
                marginTop: '40px',
                marginLeft: '115px',
                backgroundColor: '#FFFFFF',
              }}
            >
              <Text style={{ fontSize: 8, padding: '5px' }}>
                Medicaid Mix
              </Text>
              <Text
                style={{
                  fontSize: 8,
                  padding: '1px',
                  marginLeft: '5px',
                  color: textColor(
                    Math.round(fiscal_rating_data?.payor_mix_medicaid_days)
                  ),
                  width: '80px',
                }}
              >
                {fiscal_score_data?.payor_mix_medicaid_days || fiscal_score_data?.payor_mix_medicaid_days === 0 ? (fiscal_score_data.payor_mix_medicaid_days * 100).toFixed(1) + "%" : "N/A"}
                {fiscal_rating_data?.payor_mix_medicaid_days
                  ? performanceText(
                    Math.round(fiscal_rating_data?.payor_mix_medicaid_days)
                  )
                  : ''}
              </Text>
            </View>
          </View>
          <View
            style={{
              border: '1px solid #D9D9D9',
              marginTop: '25px',
              marginBottom: '25px',
              width: '96%',
              marginLeft: '10px',
            }}
          ></View>
          <View
            wrap
            style={{
              marginLeft: '-10px',
              width: '100%',
              flexDirection: 'row',
              marginBottom: '5px',
              justifyContent:
                positive_fiscal.length > negative_fiscal.length
                  ? 'flex-start'
                  : 'flex-end',
            }}
          >
            <View
              wrap={false}
              style={{ flexDirection: 'column', width: '50%' }}
            >
              <Text
                style={{
                  fontSize: 12,
                  marginTop: '0px',
                  color: '#22577A',
                  marginLeft: '30px',
                  fontWeight: 800,
                }}
              >
                Positive Factors
              </Text>
              <View
                wrap={false}
                style={{
                  position: 'relative',
                  width: '263.66px',
                  padding: '5px',
                  marginLeft: '20px',
                  marginTop: '-10px',
                }}
              ></View>
              <View>
                {positive_fiscal.map((data) => {
                  return (
                    <View
                      wrap={false}
                      style={{
                        position: 'relative',
                        width: '263.66px',
                        padding: '5px',
                        marginLeft: '20px',
                        marginTop: '10px',
                        backgroundColor: '#FAFAFA',
                        border: '1px solid #E8F5E9',
                        borderRadius: '8.3px',
                      }}
                    >
                      <Text style={{ fontSize: 7 }}>{data[1]}</Text>
                    </View>
                  )
                })}
              </View>
            </View>
            <View
              wrap={false}
              style={{ flexDirection: 'column', width: '50%' }}
            >
              <Text
                style={{
                  fontSize: 12,
                  marginTop: '0px',
                  color: '#22577A',
                  marginLeft: '30px',
                  fontWeight: 800,
                }}
              >
                Risk Factors
              </Text>
              <View
                wrap={false}
                style={{
                  position: 'relative',
                  width: '263.66px',
                  padding: '5px',
                  marginLeft: '20px',
                  marginTop: '-10px',
                }}
              ></View>
              <View>
                {negative_fiscal.map((data) => {
                  return (
                    <View
                      wrap={false}
                      style={{
                        position: 'relative',
                        width: '243.66px',
                        padding: '5px',
                        marginLeft: '20px',
                        marginTop: '10px',
                        backgroundColor: '#FAFAFA',
                        border: '1px solid #E8F5E9',
                        borderRadius: '8.3px',
                      }}
                    >
                      <Text style={{ fontSize: 7 }}>{data[1]}</Text>
                    </View>
                  )
                })}
              </View>
            </View>
          </View>
        </View>
        <View
          style={{
            border: '2px solid #D9D9D9',
            marginTop: '20px',
            backgroundColor: '#D9D9D9',
          }}
        ></View>
        <View
          wrap={false}
          style={{
            position: 'relative',
            width: '100%',
            marginLeft: '0px',
            backgroundColor: '#FFFFFF',
            border: '1px solid #E8F5E9',
            borderRadius: '8.3px',
            padding: '5px',
            marginTop: '20px',
          }}
        >
          <View style={{ flexDirection: 'row' }}>
            <View>
              {' '}
              <Text
                style={{
                  fontSize: 12,
                  marginTop: '17px',
                  color: '#22577A',
                  marginLeft: '8px',
                  fontWeight: 800,
                }}
              >
                Operations
              </Text>
            </View>
            {ratings['clinical_operations'] ? (
              <View
                style={{
                  border: `1px solid ${textColor(
                    Math.round(ratings['clinical_operations'])
                  )}`,
                  backgroundColor: backgroundColor(
                    Math.round(ratings['clinical_operations'])
                  ),
                  height: '25px',
                  flexDirection: 'row',
                  borderRadius: '3.08px',
                  top: 11,
                  left: 5,
                  paddingTop: '2px',
                  paddingHorizontal: '5px',
                }}
              >
                <View
                  style={{
                    marginTop: '5px',
                    height: '15px',
                    width: '80px',
                    marginBottom: '10px',
                  }}
                >
                  <RenderRating
                    rating={Math.round(ratings['clinical_operations'])}
                    viewbox="0 0 35 35"
                  />
                </View>
                <Text
                  style={{
                    fontSize: 7,
                    marginTop: '6.25px',
                    marginLeft: '5px',
                  }}
                >
                  {performanceText(Math.round(ratings['clinical_operations']))
                    ?.replace('(', '')
                    .replace(')', '')}
                </Text>
              </View>
            ) : (
              <View></View>
            )}
          </View>
          <View
            wrap
            style={{
              flexDirection: 'row',
              marginTop: '15px',
              marginBottom: '10px',
            }}
          >
            <View style={{ flexDirection: 'column', width: '50%' }}>
              <View>
                <Text
                  style={{ fontSize: 8, padding: '5px', marginLeft: '12px' }}
                >
                  Bed Utilization
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    padding: '5px',
                    marginLeft: '12px',
                    color: textColor(
                      Math.round(operations_rating_data?.bed_utilization)
                    ),
                  }}
                >
                  {operations_score_data?.bed_utilization ||
                    operations_score_data?.bed_utilization === 0
                    ? operations_score_data.bed_utilization.toFixed(1) * 100 +
                    '%'
                    : 'N/A'}
                  {operations_rating_data?.bed_utilization
                    ? performanceText(
                      Math.round(operations_rating_data?.bed_utilization)
                    )
                    : ''}
                </Text>
              </View>
              <View>
                <Text
                  style={{ fontSize: 8, padding: '5px', marginLeft: '12px' }}
                >
                  Average number of mins spent in the emergency department
                  before discharge
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    padding: '5px',
                    marginLeft: '12px',
                    color: textColor(
                      Math.round(
                        operations_rating_data?.mins_in_ed_before_sent_home
                      )
                    ),
                  }}
                >
                  {operations_score_data?.mins_in_ed_before_sent_home ||
                    operations_score_data?.mins_in_ed_before_sent_home === 0
                    ? operations_score_data.mins_in_ed_before_sent_home
                    : 'N/A'}
                  {operations_rating_data?.mins_in_ed_before_sent_home
                    ? performanceText(
                      Math.round(
                        operations_rating_data?.mins_in_ed_before_sent_home
                      )
                    )
                    : ''}
                </Text>
              </View>
            </View>
            <View style={{ flexDirection: 'column', width: '50%' }}>
              <View>
                <Text
                  style={{ fontSize: 8, padding: '5px', marginLeft: '12px' }}
                >
                  % patients who left the emergency department before begin
                  seen rate
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    padding: '5px',
                    marginLeft: '12px',
                    color: textColor(
                      Math.round(
                        operations_rating_data?.pct_left_ed_before_being_seen
                      )
                    ),
                  }}
                >
                  {operations_score_data?.pct_left_ed_before_being_seen ||
                    operations_score_data?.pct_left_ed_before_being_seen === 0
                    ? Math.round(
                      operations_score_data.pct_left_ed_before_being_seen
                    ) *
                    100 +
                    '%'
                    : 'N/A'}
                  {operations_rating_data?.pct_left_ed_before_being_seen
                    ? performanceText(
                      Math.round(
                        operations_rating_data?.pct_left_ed_before_being_seen
                      )
                    )
                    : ''}
                </Text>
              </View>
              <View>
                <Text
                  style={{ fontSize: 8, padding: '5px', marginLeft: '12px' }}
                >
                  Annual surgeries per Operating Room
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    padding: '5px',
                    marginLeft: '12px',
                    color: textColor(
                      Math.round(
                        operations_rating_data?.surgical_volume_per_or
                      )
                    ),
                  }}
                >
                  {operations_score_data?.operating_room_throughput ||
                    operations_score_data?.operating_room_throughput === 0
                    ? Math.round(
                      operations_rating_data?.surgical_volume_per_or
                    )
                    : 'N/A'}
                  {operations_rating_data?.surgical_volume_per_or
                    ? performanceText(
                      Math.round(
                        operations_rating_data?.surgical_volume_per_or
                      )
                    )
                    : ''}
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{
              border: '1px solid #D9D9D9',
              marginTop: '25px',
              marginBottom: '25px',
              width: '96%',
              marginLeft: '10px',
            }}
          ></View>
          <View
            wrap
            style={{
              marginLeft: '-10px',
              width: '100%',
              flexDirection: 'row',
              marginBottom: '5px',
              justifyContent:
                positive_clinical.length > negative_clinical.length
                  ? 'flex-start'
                  : 'flex-end',
            }}
          >
            <View
              wrap={false}
              style={{ flexDirection: 'column', width: '50%' }}
            >
              <Text
                style={{
                  fontSize: 12,
                  marginTop: '0px',
                  color: '#22577A',
                  marginLeft: '30px',
                  fontWeight: 800,
                }}
              >
                Positive Factors
              </Text>
              <View
                wrap={false}
                style={{
                  position: 'relative',
                  width: '263.66px',
                  padding: '5px',
                  marginLeft: '20px',
                  marginTop: '-10px',
                }}
              ></View>
              <View>
                {positive_clinical.map((data) => {
                  return (
                    <View
                      wrap={false}
                      style={{
                        position: 'relative',
                        width: '263.66px',
                        padding: '5px',
                        marginLeft: '20px',
                        marginTop: '10px',
                        backgroundColor: '#FAFAFA',
                        border: '1px solid #E8F5E9',
                        borderRadius: '8.3px',
                      }}
                    >
                      <Text style={{ fontSize: 7 }}>{data[1]}</Text>
                    </View>
                  )
                })}
              </View>
            </View>
            <View
              wrap={false}
              style={{ flexDirection: 'column', width: '50%' }}
            >
              <Text
                style={{
                  fontSize: 12,
                  marginTop: '0px',
                  color: '#22577A',
                  marginLeft: '30px',
                  fontWeight: 800,
                }}
              >
                Risk Factors
              </Text>
              <View
                wrap={false}
                style={{
                  position: 'relative',
                  width: '263.66px',
                  padding: '5px',
                  marginLeft: '20px',
                  marginTop: '-10px',
                }}
              ></View>
              <View>
                {negative_clinical.map((data) => {
                  return (
                    <View
                      wrap={false}
                      style={{
                        position: 'relative',
                        width: '243.66px',
                        padding: '5px',
                        marginLeft: '20px',
                        marginTop: '10px',
                        backgroundColor: '#FAFAFA',
                        border: '1px solid #E8F5E9',
                        borderRadius: '8.3px',
                      }}
                    >
                      <Text style={{ fontSize: 7 }}>{data[1]}</Text>
                    </View>
                  )
                })}
              </View>
            </View>
          </View>
        </View>
        <View
          style={{
            border: '2px solid #D9D9D9',
            marginTop: '20px',
            backgroundColor: '#D9D9D9',
          }}
        ></View>
        <View
          wrap={false}
          style={{
            position: 'relative',
            width: '100%',
            marginLeft: '0px',
            backgroundColor: '#FFFFFF',
            border: '1px solid #E8F5E9',
            borderRadius: '8.3px',
            padding: '5px',
            marginTop: '20px',
          }}
        >
          <View style={{ flexDirection: 'row' }}>
            <View>
              <Text
                style={{
                  fontSize: 12,
                  marginTop: '17px',
                  color: '#22577A',
                  marginLeft: '8px',
                  fontWeight: 800,
                }}
              >
                Patient Experience
              </Text>
            </View>
            {ratings['experiential_design'] ? (
              <View
                style={{
                  border: `1px solid ${textColor(
                    Math.round(ratings['experiential_design'])
                  )}`,
                  backgroundColor: backgroundColor(
                    Math.round(ratings['experiential_design'])
                  ),
                  height: '25px',
                  flexDirection: 'row',
                  borderRadius: '3.08px',
                  top: 11,
                  left: 5,
                  paddingHorizontal: '5px',
                  paddingTop: '2px',
                }}
              >
                <View
                  style={{
                    marginTop: '5px',
                    height: '15px',
                    width: '80px',
                    marginBottom: '10px',
                  }}
                >
                  <RenderRating
                    rating={Math.round(ratings['experiential_design'])}
                    viewbox="0 0 35 35"
                  />
                </View>
                <Text
                  style={{
                    fontSize: 7,
                    marginTop: '6.5px',
                    marginLeft: '5px',
                  }}
                >
                  {performanceText(Math.round(ratings['experiential_design']))
                    ?.replace('(', '')
                    .replace(')', '')}
                </Text>
              </View>
            ) : (
              <View></View>
            )}
          </View>
          <View wrap style={{ flexDirection: 'row', marginTop: '25px' }}>
            <View style={{ flexDirection: 'column', width: '50%' }}>
              <View style={{ marginBottom: '10px' }} wrap={false}>
                <Text
                  style={{ fontSize: 8, padding: '5px', marginLeft: '12px' }}
                >
                  Patient Experience HCAHPAS Rating
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    padding: '5px',
                    marginLeft: '12px',
                    color: textColor(
                      Math.round(
                        patient_experience_rating_data?.patient_experience_hcahps_rating
                      )
                    ),
                  }}
                >
                  {patient_experience_score_data?.patient_experience_hcahps_rating ||
                    patient_experience_score_data?.patient_experience_hcahps_rating ===
                    0
                    ? patient_experience_score_data.patient_experience_hcahps_rating
                    : 'N/A'}
                  {patient_experience_rating_data?.patient_experience_hcahps_rating
                    ? performanceText(
                      Math.round(
                        patient_experience_rating_data?.patient_experience_hcahps_rating
                      )
                    )
                    : ''}
                </Text>
              </View>
              <View style={{ marginBottom: '10px' }} wrap={false}>
                <Text
                  style={{ fontSize: 8, padding: '5px', marginLeft: '12px' }}
                >
                  Patients who reported that their doctors always communicated
                  well
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    padding: '5px',
                    marginLeft: '12px',
                    color: textColor(
                      Math.round(
                        patient_experience_rating_data?.my_doctors_always_communicated_well
                      )
                    ),
                  }}
                >
                  {patient_experience_score_data?.my_doctors_always_communicated_well ||
                    patient_experience_score_data?.my_doctors_always_communicated_well ===
                    0
                    ? Math.round(
                      patient_experience_score_data?.my_doctors_always_communicated_well *
                      100
                    ) +
                    '%'
                    : 'N/A'}
                  {patient_experience_rating_data?.my_doctors_always_communicated_well
                    ? performanceText(
                      Math.round(
                        patient_experience_rating_data?.my_doctors_always_communicated_well
                      )
                    )
                    : ''}
                </Text>
              </View>
              <View style={{ marginBottom: '10px' }} wrap={false}>
                <Text
                  style={{ fontSize: 8, padding: '5px', marginLeft: '12px' }}
                >
                  Patients who gave this hospital a rating of 9 to 10
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    padding: '5px',
                    marginLeft: '12px',
                    color: textColor(
                      Math.round(
                        patient_experience_rating_data?.i_gave_this_hospital_rating_of_9_or_10
                      )
                    ),
                  }}
                >
                  {patient_experience_score_data?.i_gave_this_hospital_rating_of_9_or_10 ||
                    patient_experience_score_data?.i_gave_this_hospital_rating_of_9_or_10 ===
                    0
                    ? Math.round(
                      patient_experience_score_data?.i_gave_this_hospital_rating_of_9_or_10 *
                      100
                    ) +
                    '%'
                    : 'N/A'}
                  {patient_experience_rating_data?.i_gave_this_hospital_rating_of_9_or_10
                    ? performanceText(
                      Math.round(
                        patient_experience_rating_data?.i_gave_this_hospital_rating_of_9_or_10
                      )
                    )
                    : ''}
                </Text>
              </View>
            </View>
            <View style={{ flexDirection: 'column', width: '50%' }}>
              <View style={{ marginBottom: '10px' }} wrap={false}>
                <Text
                  style={{ fontSize: 8, padding: '5px', marginLeft: '12px' }}
                >
                  Patients who reported that their nurses always communicate
                  well
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    padding: '5px',
                    marginLeft: '12px',
                    color: textColor(
                      Math.round(
                        patient_experience_rating_data?.my_nurses_always_communicated_well
                      )
                    ),
                  }}
                >
                  {patient_experience_score_data?.my_nurses_always_communicated_well ||
                    patient_experience_score_data?.my_nurses_always_communicated_well ===
                    0
                    ? Math.round(
                      patient_experience_score_data?.my_nurses_always_communicated_well *
                      100
                    ) +
                    '%'
                    : 'N/A'}
                  {patient_experience_rating_data?.my_nurses_always_communicated_well
                    ? performanceText(
                      Math.round(
                        patient_experience_rating_data?.my_nurses_always_communicated_well
                      )
                    )
                    : ''}
                </Text>
              </View>
              <View style={{ marginBottom: '10px' }} wrap={false}>
                <Text
                  style={{ fontSize: 8, padding: '5px', marginLeft: '12px' }}
                >
                  Patients who always received help as soon as they wanted
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    padding: '5px',
                    marginLeft: '12px',
                    color: textColor(
                      Math.round(
                        patient_experience_rating_data?.i_always_received_help_as_soon_as_i_wanted
                      )
                    ),
                  }}
                >
                  {patient_experience_score_data?.i_always_received_help_as_soon_as_i_wanted ||
                    patient_experience_score_data?.i_always_received_help_as_soon_as_i_wanted ===
                    0
                    ? Math.round(
                      patient_experience_score_data.i_always_received_help_as_soon_as_i_wanted *
                      100
                    ) +
                    '%'
                    : 'N/A'}
                  {patient_experience_rating_data?.i_always_received_help_as_soon_as_i_wanted
                    ? performanceText(
                      Math.round(
                        patient_experience_rating_data?.i_always_received_help_as_soon_as_i_wanted
                      )
                    )
                    : ''}
                </Text>
              </View>
              <View style={{ marginBottom: '10px' }} wrap={false}>
                <Text
                  style={{ fontSize: 8, padding: '5px', marginLeft: '12px' }}
                >
                  Patients who reported they would definitely recommend this
                  hospital
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    padding: '5px',
                    marginLeft: '12px',
                    color: textColor(
                      Math.round(
                        patient_experience_rating_data?.i_would_definitely_recommend_the_hospital
                      )
                    ),
                  }}
                >
                  {patient_experience_score_data?.i_would_definitely_recommend_the_hospital ||
                    patient_experience_score_data?.i_would_definitely_recommend_the_hospital ===
                    0
                    ? Math.round(
                      patient_experience_score_data.i_would_definitely_recommend_the_hospital *
                      100
                    ) +
                    '%'
                    : 'N/A'}
                  {patient_experience_rating_data?.i_would_definitely_recommend_the_hospital
                    ? performanceText(
                      Math.round(
                        patient_experience_rating_data?.i_would_definitely_recommend_the_hospital
                      )
                    )
                    : ''}
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{
              border: '1px solid #D9D9D9',
              marginTop: '25px',
              marginBottom: '25px',
              width: '96%',
              marginLeft: '10px',
            }}
          ></View>
          <View
            wrap
            style={{
              marginLeft: '-10px',
              width: '100%',
              flexDirection: 'row',
              marginBottom: '5px',
              justifyContent:
                positive_patient_exp.length > negative_patient_exp.length
                  ? 'flex-start'
                  : 'flex-end',
            }}
          >
            <View
              wrap={false}
              style={{ flexDirection: 'column', width: '50%' }}
            >
              <Text
                style={{
                  fontSize: 12,
                  marginTop: '0px',
                  color: '#22577A',
                  marginLeft: '30px',
                  fontWeight: 800,
                }}
              >
                Positive Factors
              </Text>
              <View
                wrap={false}
                style={{
                  position: 'relative',
                  width: '263.66px',
                  padding: '5px',
                  marginLeft: '20px',
                  marginTop: '-10px',
                }}
              ></View>

              <View>
                {positive_patient_exp.map((data) => {
                  return (
                    <View
                      wrap={false}
                      style={{
                        position: 'relative',
                        width: '263.66px',
                        padding: '5px',
                        marginLeft: '20px',
                        marginTop: '10px',
                        backgroundColor: '#FAFAFA',
                        border: '1px solid #E8F5E9',
                        borderRadius: '8.3px',
                      }}
                    >
                      <Text style={{ fontSize: 7 }}>{data[1]}</Text>
                    </View>
                  )
                })}
              </View>
            </View>
            <View wrap style={{ flexDirection: 'column', width: '50%' }}>
              <Text
                style={{
                  fontSize: 12,
                  marginTop: '0px',
                  color: '#22577A',
                  marginLeft: '30px',
                  fontWeight: 800,
                }}
              >
                Risk Factors
              </Text>
              <View
                wrap={false}
                style={{
                  position: 'relative',
                  width: '263.66px',
                  padding: '5px',
                  marginLeft: '20px',
                  marginTop: '-10px',
                }}
              ></View>
              <View>
                {negative_patient_exp.map((data) => {
                  return (
                    <View
                      wrap={false}
                      style={{
                        position: 'relative',
                        width: '243.66px',
                        padding: '5px',
                        marginLeft: '20px',
                        marginTop: '10px',
                        backgroundColor: '#FAFAFA',
                        border: '1px solid #E8F5E9',
                        borderRadius: '8.3px',
                      }}
                    >
                      <Text style={{ fontSize: 7 }}>{data[1]}</Text>
                    </View>
                  )
                })}
              </View>
            </View>
          </View>
        </View>
        <View
          style={{
            border: '2px solid #D9D9D9',
            marginTop: '20px',
            marginBottom: '20px',
            backgroundColor: '#D9D9D9',
          }}
        ></View>
        <View
          wrap={false}
          style={{
            position: 'relative',
            width: '100%',
            marginLeft: '0px',
            backgroundColor: '#FFFFFF',
            border: '1px solid #E8F5E9',
            borderRadius: '8.3px',
            padding: '5px',
          }}
        >
          <View style={{ flexDirection: 'row' }}>
            <View>
              <Text
                style={{
                  fontSize: 12,
                  marginTop: '17px',
                  color: '#22577A',
                  marginLeft: '8px',
                  fontWeight: 800,
                }}
              >
                Clinical Outcomes
              </Text>
            </View>
            {ratings['clinical_outcomes'] ? (
              <View
                style={{
                  border: `1px solid ${textColor(
                    Math.round(ratings['clinical_outcomes'])
                  )}`,
                  backgroundColor: backgroundColor(
                    Math.round(ratings['clinical_outcomes'])
                  ),
                  height: '25px',
                  flexDirection: 'row',
                  borderRadius: '3.08px',
                  top: 11,
                  left: 5,
                  paddingHorizontal: '5px',
                  paddingTop: '2px',
                }}
              >
                <View
                  style={{
                    marginTop: '5px',
                    height: '15px',
                    width: '80px',
                    marginBottom: '10px',
                  }}
                >
                  <RenderRating
                    rating={Math.round(ratings['clinical_outcomes'])}
                    viewbox="0 0 35 35"
                  />
                </View>
                <Text
                  style={{
                    fontSize: 7,
                    marginTop: '6.5px',
                    marginLeft: '5px',
                  }}
                >
                  {performanceText(Math.round(ratings['clinical_outcomes']))
                    ?.replace('(', '')
                    .replace(')', '')}
                </Text>
              </View>
            ) : (
              <View></View>
            )}
          </View>
          <View wrap style={{ flexDirection: 'row', marginTop: '25px' }}>
            <View style={{ flexDirection: 'column', width: '50%' }}>
              <View style={{ marginBottom: '10px' }}>
                <Text
                  style={{ fontSize: 8, padding: '5px', marginLeft: '12px' }}
                >
                  Hospital Compare Overall Quality Rating
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    padding: '5px',
                    marginLeft: '12px',
                    color: textColor(
                      Math.round(
                        clinical_outcome_rating_data?.overall_quality_rating
                      )
                    ),
                  }}
                >
                  {clinical_outcome_score_data?.overall_quality_rating ||
                    clinical_outcome_score_data?.overall_quality_rating === 0
                    ? clinical_outcome_score_data.overall_quality_rating
                    : 'N/A'}
                  {clinical_outcome_rating_data?.overall_quality_rating
                    ? performanceText(
                      Math.round(
                        clinical_outcome_rating_data?.overall_quality_rating
                      )
                    )
                    : ''}
                </Text>
              </View>
              <View style={{ marginBottom: '10px' }}>
                <Text
                  style={{ fontSize: 8, padding: '5px', marginLeft: '12px' }}
                >
                  Most Recent Year Revenue Loss Due to Hospital Acquired
                  Condition Penalty
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    padding: '5px',
                    marginLeft: '12px',
                    color: textColor(
                      Math.round(
                        clinical_outcome_rating_data?.revenue_penalty_due_to_hospital_acquired_condition
                      )
                    ),
                  }}
                >
                  {clinical_outcome_score_data?.revenue_penalty_due_to_hospital_acquired_condition ||
                    clinical_outcome_score_data?.revenue_penalty_due_to_hospital_acquired_condition ===
                    0
                    ? clinical_outcome_score_data.revenue_penalty_due_to_hospital_acquired_condition.toLocaleString(
                      'en-US',
                      {
                        style: 'currency',
                        currency: 'USD',
                      }
                    )
                    : 'N/A'}
                  {clinical_outcome_rating_data?.revenue_penalty_due_to_hospital_acquired_condition
                    ? performanceText(
                      Math.round(
                        clinical_outcome_rating_data?.revenue_penalty_due_to_hospital_acquired_condition
                      )
                    )
                    : ''}
                </Text>
              </View>
              <View style={{ marginBottom: '10px' }}>
                <Text
                  style={{ fontSize: 8, padding: '5px', marginLeft: '12px' }}
                >
                  Previous Year Revenue Loss Due to Hospital Acquired
                  Condition Penalty
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    padding: '5px',
                    marginLeft: '12px',
                    color: textColor(
                      Math.round(
                        clinical_outcome_rating_data?.prev_year_revenue_penalty_due_to_hospital_acquired_condition
                      )
                    ),
                  }}
                >
                  {clinical_outcome_score_data?.prev_year_revenue_penalty_due_to_hospital_acquired_condition ||
                    clinical_outcome_score_data.prev_year_revenue_penalty_due_to_hospital_acquired_condition ===
                    0
                    ? clinical_outcome_score_data.prev_year_revenue_penalty_due_to_hospital_acquired_condition.toLocaleString(
                      'en-US',
                      {
                        style: 'currency',
                        currency: 'USD',
                      }
                    )
                    : 'N/A'}
                  {clinical_outcome_rating_data?.prev_year_revenue_penalty_due_to_hospital_acquired_condition
                    ? performanceText(
                      Math.round(
                        clinical_outcome_rating_data?.prev_year_revenue_penalty_due_to_hospital_acquired_condition
                      )
                    )
                    : ''}
                </Text>
              </View>
            </View>
            <View style={{ flexDirection: 'column', width: '50%' }}>
              <View style={{ marginBottom: '10px' }}>
                <Text
                  style={{ fontSize: 8, padding: '5px', marginLeft: '12px' }}
                >
                  Readmission Rate
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    padding: '5px',
                    marginLeft: '12px',
                    color: textColor(
                      Math.round(
                        clinical_outcome_rating_data?.readmission_rate
                      )
                    ),
                  }}
                >
                  {clinical_outcome_score_data?.readmission_rate ||
                    clinical_outcome_score_data?.readmission_rate === 0
                    ? (clinical_outcome_score_data.readmission_rate * 100).toFixed(1)
                    +
                    '%'
                    : 'N/A'}
                  {clinical_outcome_rating_data?.readmission_rate
                    ? performanceText(
                      Math.round(
                        clinical_outcome_rating_data?.readmission_rate
                      )
                    )
                    : ''}
                </Text>
              </View>
              <View style={{ marginBottom: '10px' }}>
                <Text
                  style={{ fontSize: 8, padding: '5px', marginLeft: '12px' }}
                >
                  Most Recent Year Revenue Loss Due to Readmission Penalty
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    padding: '5px',
                    marginLeft: '12px',
                    color:
                      clinical_outcome_score_data?.revenue_penalty_due_to_excess_readmissions <
                        0
                        ? '#FF0707'
                        : textColor(
                          Math.round(
                            clinical_outcome_rating_data?.revenue_penalty_due_to_excess_readmissions
                          )
                        ),
                  }}
                >
                  {clinical_outcome_score_data?.revenue_penalty_due_to_excess_readmissions ||
                    clinical_outcome_score_data?.revenue_penalty_due_to_excess_readmissions ===
                    0
                    ? clinical_outcome_score_data.revenue_penalty_due_to_excess_readmissions.toLocaleString(
                      'en-US',
                      {
                        style: 'currency',
                        currency: 'USD',
                      }
                    )
                    : 'N/A'}
                  {clinical_outcome_rating_data?.revenue_penalty_due_to_excess_readmissions
                    ? performanceText(
                      Math.round(
                        clinical_outcome_rating_data?.revenue_penalty_due_to_excess_readmissions
                      )
                    )
                    : ''}
                </Text>
              </View>
              <View style={{ marginBottom: '10px' }}>
                <Text
                  style={{ fontSize: 8, padding: '5px', marginLeft: '12px' }}
                >
                  Previous Year Revenue Loss Due to Readmission Penalty
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    padding: '5px',
                    marginLeft: '12px',
                    color:
                      clinical_outcome_score_data?.prev_year_revenue_penalty_due_to_excess_readmissions <
                        0
                        ? '#FF0707'
                        : textColor(
                          Math.round(
                            clinical_outcome_rating_data?.prev_year_revenue_penalty_due_to_excess_readmissions
                          )
                        ),
                  }}
                >
                  {clinical_outcome_score_data?.prev_year_revenue_penalty_due_to_excess_readmissions ||
                    clinical_outcome_score_data?.prev_year_revenue_penalty_due_to_excess_readmissions ===
                    0
                    ? clinical_outcome_score_data?.prev_year_revenue_penalty_due_to_excess_readmissions.toLocaleString(
                      'en-US',
                      {
                        style: 'currency',
                        currency: 'USD',
                      }
                    )
                    : 'N/A'}
                  {clinical_outcome_rating_data?.prev_year_revenue_penalty_due_to_excess_readmissions
                    ? performanceText(
                      Math.round(
                        clinical_outcome_rating_data?.prev_year_revenue_penalty_due_to_excess_readmissions
                      )
                    )
                    : ''}
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{
              border: '1px solid #D9D9D9',
              marginTop: '25px',
              marginBottom: '25px',
              width: '96%',
              marginLeft: '10px',
            }}
          ></View>
          <View
            wrap
            style={{
              marginLeft: '-10px',
              width: '100%',
              flexDirection: 'row',
              marginBottom: '5px',
              justifyContent:
                positive_clinical_outcomes.length >
                  negative_clinical_outcomes.length
                  ? 'flex-start'
                  : 'flex-end',
            }}
          >
            <View
              wrap={false}
              style={{ flexDirection: 'column', width: '50%' }}
            >
              <Text
                style={{
                  fontSize: 12,
                  marginTop: '0px',
                  color: '#22577A',
                  marginLeft: '30px',
                  fontWeight: 800,
                }}
              >
                Positive Factors
              </Text>
              <View
                wrap={false}
                style={{
                  position: 'relative',
                  width: '263.66px',
                  padding: '5px',
                  marginLeft: '20px',
                  marginTop: '-10px',
                }}
              ></View>
              <View>
                {positive_clinical_outcomes.map((data) => {
                  return (
                    <View
                      wrap={false}
                      style={{
                        position: 'relative',
                        width: '263.66px',
                        padding: '5px',
                        marginLeft: '20px',
                        marginTop: '10px',
                        backgroundColor: '#FAFAFA',
                        border: '1px solid #E8F5E9',
                        borderRadius: '8.3px',
                      }}
                    >
                      <Text style={{ fontSize: 7 }}>{data[1]}</Text>
                    </View>
                  )
                })}
              </View>
            </View>
            <View
              wrap={false}
              style={{ flexDirection: 'column', width: '50%' }}
            >
              <Text
                style={{
                  fontSize: 12,
                  marginTop: '0px',
                  color: '#22577A',
                  marginLeft: '30px',
                  fontWeight: 800,
                }}
              >
                Risk Factors
              </Text>
              <View
                wrap={false}
                style={{
                  position: 'relative',
                  width: '263.66px',
                  padding: '5px',
                  marginLeft: '20px',
                  marginTop: '-10px',
                }}
              ></View>
              <View>
                {negative_clinical_outcomes.map((data) => {
                  return (
                    <View
                      wrap={false}
                      style={{
                        position: 'relative',
                        width: '243.66px',
                        padding: '5px',
                        marginLeft: '20px',
                        marginTop: '10px',
                        backgroundColor: '#FAFAFA',
                        border: '1px solid #E8F5E9',
                        borderRadius: '8.3px',
                      }}
                    >
                      <Text style={{ fontSize: 7 }}>{data[1]}</Text>
                    </View>
                  )
                })}
              </View>
            </View>
          </View>
        </View>
        <View
          style={{
            border: '2px solid #D9D9D9',
            marginTop: '20px',
            marginBottom: '20px',
            backgroundColor: '#D9D9D9',
          }}
        ></View>
        <View
          wrap={false}
          style={{
            position: 'relative',
            width: '100%',
            marginLeft: '0px',
            backgroundColor: '#FFFFFF',
            border: '1px solid #E8F5E9',
            borderRadius: '8.3px',
            padding: '5px',
          }}
        >
          <View style={{ flexDirection: 'row' }}>
            <View>
              <Text
                style={{
                  fontSize: 12,
                  marginTop: '17px',
                  color: '#22577A',
                  marginLeft: '8px',
                  fontWeight: 800,
                }}
              >
                Facility Conditions
              </Text>
            </View>
            {ratings['facility_conditions'] ? (
              <View
                style={{
                  border: `1px solid ${textColor(
                    Math.round(ratings['facility_conditions'])
                  )}`,
                  backgroundColor: backgroundColor(
                    Math.round(ratings['facility_conditions'])
                  ),
                  height: '25px',
                  flexDirection: 'row',
                  borderRadius: '3.08px',
                  top: 11,
                  left: 5,
                  paddingHorizontal: '5px',
                  paddingTop: '2px',
                }}
              >
                <View
                  style={{
                    marginTop: '5px',
                    height: '15px',
                    width: '80px',
                    marginBottom: '10px',
                  }}
                >
                  <RenderRating
                    rating={Math.round(ratings['facility_conditions'])}
                    viewbox="0 0 35 35"
                  />
                </View>
                <Text
                  style={{
                    fontSize: 7,
                    marginTop: '6.5px',
                    marginLeft: '5px',
                  }}
                >
                  {performanceText(Math.round(ratings['facility_conditions']))
                    ?.replace('(', '')
                    .replace(')', '')}
                </Text>
              </View>
            ) : (
              <View></View>
            )}
          </View>
          <View wrap style={{ flexDirection: 'row', marginTop: '25px' }}>
            <View style={{ flexDirection: 'column', width: '40%' }}>
              <View style={{ marginBottom: '10px' }}>
                <Text
                  style={{ fontSize: 8, padding: '5px', marginLeft: '12px' }}
                >
                  Inpatient Square Footage
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    padding: '5px',
                    marginLeft: '12px',
                    color: textColor(
                      Math.round(
                        facility_conditions_rating_data?.inpatient_sq_footage
                      )
                    ),
                  }}
                >
                  {facility_conditions_score_data?.inpatient_sq_footage ||
                    facility_conditions_score_data?.inpatient_sq_footage === 0
                    ? parseInt(
                      facility_conditions_score_data.inpatient_sq_footage
                    ).toLocaleString('en-US')
                    : 'N/A'}
                  {facility_conditions_rating_data?.inpatient_sq_footage
                    ? performanceText(
                      Math.round(
                        facility_conditions_rating_data?.inpatient_sq_footage
                      )
                    )
                    : ''}
                </Text>
              </View>
              <View style={{ marginBottom: '10px' }}>
                <Text
                  style={{ fontSize: 8, padding: '5px', marginLeft: '12px' }}
                >
                  Average Age of Facility (in years)
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    padding: '5px',
                    marginLeft: '12px',
                    color: textColor(
                      Math.round(
                        facility_conditions_rating_data?.avg_age_of_facility_years
                      )
                    ),
                  }}
                >
                  {facility_conditions_score_data?.avg_age_of_facility_years ||
                    facility_conditions_score_data?.avg_age_of_facility_years ===
                    0
                    ? facility_conditions_score_data.avg_age_of_facility_years.toFixed(
                      2
                    )
                    : 'N/A'}
                  {facility_conditions_rating_data?.avg_age_of_facility_years
                    ? performanceText(
                      Math.round(
                        facility_conditions_rating_data?.avg_age_of_facility_years
                      )
                    )
                    : ''}
                </Text>
              </View>
              <View style={{ marginBottom: '10px' }}>
                <Text
                  style={{ fontSize: 8, padding: '5px', marginLeft: '12px' }}
                >
                  Room and Bathroom Were Always Clean
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    padding: '5px',
                    marginLeft: '12px',
                    color: textColor(
                      Math.round(
                        facility_conditions_rating_data?.my_room_and_bathroom_were_always_clean
                      )
                    ),
                  }}
                >
                  {facility_conditions_score_data?.my_room_and_bathroom_were_always_clean ||
                    facility_conditions_score_data?.my_room_and_bathroom_were_always_clean ===
                    0
                    ? (facility_conditions_score_data.my_room_and_bathroom_were_always_clean * 100).toFixed(0) +
                    '%'
                    : 'N/A'}
                  {facility_conditions_rating_data?.my_room_and_bathroom_were_always_clean
                    ? performanceText(
                      Math.round(
                        facility_conditions_rating_data?.my_room_and_bathroom_were_always_clean
                      )
                    )
                    : ''}
                </Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: 'column',
                marginLeft: '60px',
                width: '30%',
              }}
            >
              <View style={{ marginBottom: '10px' }}>
                <Text style={{ fontSize: 8, padding: '5px' }}>
                  Number of Beds
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    padding: '5px',
                    color: textColor(
                      Math.round(
                        facility_conditions_rating_data?.number_of_beds
                      )
                    ),
                  }}
                >
                  {facility_conditions_score_data?.number_of_beds ||
                    facility_conditions_score_data?.number_of_beds === 0
                    ? facility_conditions_score_data.number_of_beds
                    : 'N/A'}
                  {facility_conditions_rating_data?.number_of_beds
                    ? performanceText(
                      Math.round(
                        facility_conditions_rating_data?.number_of_beds
                      )
                    )
                    : ''}
                </Text>
              </View>
              <View style={{ marginBottom: '10px' }}>
                <Text style={{ fontSize: 8, padding: '5px' }}>
                  Inpatient SF / Bed
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    padding: '5px',
                    color: textColor(
                      Math.round(
                        facility_conditions_rating_data?.inpatient_sq_ft_per_bed
                      )
                    ),
                  }}
                >
                  {facility_conditions_score_data?.inpatient_sq_ft_per_bed ||
                    facility_conditions_score_data?.inpatient_sq_ft_per_bed ===
                    0
                    ? Math.round(
                      facility_conditions_score_data.inpatient_sq_ft_per_bed
                    ).toLocaleString('en-US')
                    : 'N/A'}
                  {facility_conditions_rating_data?.inpatient_sq_ft_per_bed
                    ? performanceText(
                      Math.round(
                        facility_conditions_rating_data?.inpatient_sq_ft_per_bed
                      )
                    )
                    : ''}
                </Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: 'column',
                marginLeft: '60px',
                width: '30%',
              }}
            >
              <View style={{ marginBottom: '10px' }}>
                <Text style={{ fontSize: 8, padding: '5px' }}>
                  Outpatient Square Footage
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    padding: '5px',
                    color: textColor(
                      Math.round(
                        facility_conditions_rating_data?.outpatient_sq_footage
                      )
                    ),
                  }}
                >
                  {facility_conditions_score_data?.outpatient_sq_footage ||
                    facility_conditions_score_data?.outpatient_sq_footage === 0
                    ? parseInt(
                      facility_conditions_score_data.outpatient_sq_footage
                    ).toLocaleString('en-US')
                    : 'N/A'}
                  {facility_conditions_rating_data?.outpatient_sq_footage
                    ? performanceText(
                      Math.round(
                        facility_conditions_rating_data?.outpatient_sq_footage
                      )
                    )
                    : ''}
                </Text>
              </View>
              <View style={{ marginBottom: '10px' }}>
                <Text style={{ fontSize: 8, padding: '5px' }}>
                  Inpatient Revenue per SF
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    padding: '5px',
                    color: textColor(
                      Math.round(
                        facility_conditions_rating_data?.inpatient_revenue_per_bed
                      )
                    ),
                  }}
                >
                  {facility_conditions_score_data?.inpatient_revenue_per_bed ||
                    facility_conditions_score_data?.inpatient_revenue_per_bed ===
                    0 ?
                    facility_conditions_score_data.inpatient_revenue_per_bed
                      .toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      })
                    : 'N/A'}
                  {facility_conditions_rating_data?.inpatient_revenue_per_bed
                    ? performanceText(
                      Math.round(
                        facility_conditions_rating_data?.inpatient_revenue_per_bed
                      )
                    )
                    : ''}
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{
              border: '1px solid #D9D9D9',
              marginTop: '25px',
              marginBottom: '25px',
              width: '96%',
              marginLeft: '10px',
            }}
          ></View>
          <View
            wrap
            style={{
              marginLeft: '-10px',
              width: '100%',
              flexDirection: 'row',
              marginBottom: '5px',
              justifyContent:
                positive_facility_conditions.length >
                  negative_facility_conditions
                  ? 'flex-start'
                  : 'flex-end',
            }}
          >
            <View
              wrap={false}
              style={{ flexDirection: 'column', width: '50%' }}
            >
              <Text
                style={{
                  fontSize: 12,
                  marginTop: '0px',
                  color: '#22577A',
                  marginLeft: '30px',
                  fontWeight: 800,
                }}
              >
                Positive Factors
              </Text>
              <View
                wrap={false}
                style={{
                  position: 'relative',
                  width: '263.66px',
                  padding: '5px',
                  marginLeft: '20px',
                  marginTop: '-10px',
                }}
              ></View>
              <View>
                {positive_facility_conditions.map((data) => {
                  return (
                    <View
                      wrap={false}
                      style={{
                        position: 'relative',
                        width: '263.66px',
                        padding: '5px',
                        marginLeft: '20px',
                        marginTop: '10px',
                        backgroundColor: '#FAFAFA',
                        border: '1px solid #E8F5E9',
                        borderRadius: '8.3px',
                      }}
                    >
                      <Text style={{ fontSize: 7 }}>{data[1]}</Text>
                    </View>
                  )
                })}
              </View>
            </View>
            <View
              wrap={false}
              style={{ flexDirection: 'column', width: '50%' }}
            >
              <Text
                style={{
                  fontSize: 12,
                  marginTop: '0px',
                  color: '#22577A',
                  marginLeft: '30px',
                  fontWeight: 800,
                }}
              >
                Risk Factors
              </Text>
              <View
                wrap={false}
                style={{
                  position: 'relative',
                  width: '263.66px',
                  padding: '5px',
                  marginLeft: '20px',
                  marginTop: '-10px',
                }}
              ></View>
              <View>
                {negative_facility_conditions.map((data) => {
                  return (
                    <View
                      wrap={false}
                      style={{
                        position: 'relative',
                        width: '243.66px',
                        padding: '5px',
                        marginLeft: '20px',
                        marginTop: '10px',
                        backgroundColor: '#FAFAFA',
                        border: '1px solid #E8F5E9',
                        borderRadius: '8.3px',
                      }}
                    >
                      <Text style={{ fontSize: 7 }}>{data[1]}</Text>
                    </View>
                  )
                })}
              </View>
            </View>
          </View>
        </View>
        <View wrap fixed style={pdfStyle.footer}>
          <View
            style={{
              border: '1px solid #D9D9D9',
              marginTop: '10px',
              width: '520px',
              height: '1px',
              left: '25px',
            }}
          ></View>
          <View
            style={{ flexDirection: 'row', fontSize: 8, marginTop: '10px' }}
          >
            <Text style={{ marginLeft: '30px', fontSize: 10 }}>
              Export Date : {formatedDateTime}
            </Text>
            <Text
              style={{
                fontWeight: 800,
                marginLeft: '70px',
                fontSize: 10,
              }}
            >
              Confidential: Do Not Distribute Outside of DPR
            </Text>
            <Text
              style={{ marginLeft: '110px', fontSize: 10 }}
              render={({ pageNumber }) => `Page ${pageNumber}`}
            />
          </View>
        </View>
      </Page>
    </Document>
  )
  return (
    <MenuItem
      style={{ color: 'black' }}
      onClick={() => {
        ShowSnackBar(
          'Generating Export File, Please wait !!',
          'info',
          5000,
          'close'
        )
        getDataFunction().then(() => {
          sessionStorage.setItem(
            SSConst.EXPORT_STATUS,
            'IN_PROGRESS'
          )
          ReactDOM.render(<DialogAlert />, document.createElement('div'))

          const ExportedData = JSON.parse(
            sessionStorage.getItem(SSConst.EXPORT_DATA)!
          )
          const ratings =
            ExportedData &&
              ExportedData.performanceData &&
              ExportedData.performanceData.length
              ? ExportedData.performanceData[0]
              : null
          const OrangeTopDivStyle = styled(Box)(() => ({
            background: backgroundColor(Math.round(ratings?.overall_rating)),
            border: `1px solid ${textColor(Math.round(ratings?.overall_rating))}`,
            borderRadius: '4px',
            padding: '10px 35px 10px 15px',
            margin: '0px 0px 0px 10px',
          }))
          const Performance_SummaryData =
            ExportedData &&
              ExportedData.performanceData &&
              ExportedData.performanceData.length
              ? ExportedData.performanceData[0]
              : null

          const OverallPositiveArray = [
            ...(ExportedData &&
              ExportedData.getAllPositiveFactorsforFiscalStewardship &&
              ExportedData.getAllPositiveFactorsforFiscalStewardship.length
              ? ExportedData.getAllPositiveFactorsforFiscalStewardship.map(
                (data) => ['', data.comment]
              )
              : [[' ', 'N/A']]),
            ...(ExportedData &&
              ExportedData.getAllPositiveFactorsforClinicalOperations &&
              ExportedData.getAllPositiveFactorsforClinicalOperations.length
              ? ExportedData.getAllPositiveFactorsforClinicalOperations.map(
                (data) => ['', data.comment]
              )
              : [[' ', 'N/A']]),
            ...(ExportedData &&
              ExportedData.getAllPositiveFactorsforExperimentalDesign &&
              ExportedData.getAllPositiveFactorsforExperimentalDesign.length
              ? ExportedData.getAllPositiveFactorsforExperimentalDesign.map(
                (data) => ['', data.comment]
              )
              : [[' ', 'N/A']]),
            ...(ExportedData &&
              ExportedData.getAllPositiveFactorsforClinicalOutcome &&
              ExportedData.getAllPositiveFactorsforClinicalOutcome.length
              ? ExportedData.getAllPositiveFactorsforClinicalOutcome.map((data) => [
                '',
                data.comment,
              ])
              : [[' ', 'N/A']]),
            ...(ExportedData &&
              ExportedData.getAllPositiveFactorsforFacilityConditions &&
              ExportedData.getAllPositiveFactorsforFacilityConditions.length
              ? ExportedData.getAllPositiveFactorsforFacilityConditions.map(
                (data) => ['', data.comment]
              )
              : [[' ', 'N/A']]),
          ]
          OverallPositiveArray[0][0] = 'Positive Factors'

          const OverallNegativeArray = [
            ...(ExportedData &&
              ExportedData.getAllNegativeFactorsforFiscalStewardship &&
              ExportedData.getAllNegativeFactorsforFiscalStewardship.length
              ? ExportedData.getAllNegativeFactorsforFiscalStewardship.map((data) => [
                '',
                data.comment,
              ])
              : [[' ', 'N/A']]),
            ...(ExportedData &&
              ExportedData.getAllNegativeFactorsforClinicalOperations &&
              ExportedData.getAllNegativeFactorsforClinicalOperations.length
              ? ExportedData.getAllNegativeFactorsforClinicalOperations.map(
                (data) => ['', data.comment]
              )
              : [[' ', 'N/A']]),
            ...(ExportedData &&
              ExportedData.getAllNegativeFactorsforExperimentalDesign &&
              ExportedData.getAllNegativeFactorsforExperimentalDesign.length
              ? ExportedData.getAllNegativeFactorsforExperimentalDesign.map(
                (data) => ['', data.comment]
              )
              : [[' ', 'N/A']]),
            ...(ExportedData &&
              ExportedData.getAllNegativeFactorsforClinicalOutcome &&
              ExportedData.getAllNegativeFactorsforClinicalOutcome.length
              ? ExportedData.getAllNegativeFactorsforClinicalOutcome.map((data) => [
                '',
                data.comment,
              ])
              : [[' ', 'N/A']]),
            ...(ExportedData &&
              ExportedData.getAllNegativeFactorsforFacilityConditions &&
              ExportedData.getAllNegativeFactorsforFacilityConditions.length
              ? ExportedData.getAllNegativeFactorsforFacilityConditions.map(
                (data) => ['', data.comment]
              )
              : [[' ', 'N/A']]),
          ]
          OverallNegativeArray[0][0] = 'Risk Factors'

          const fiscal_rating_data =
            ExportedData &&
              ExportedData.fiscalStewardshipRating &&
              ExportedData.fiscalStewardshipRating.length
              ? ExportedData.fiscalStewardshipRating[0]
              : null

          const fiscal_score_data =
            ExportedData &&
              ExportedData.fiscalStewardshipScore &&
              ExportedData.fiscalStewardshipScore.length
              ? ExportedData.fiscalStewardshipScore[0]
              : null

          const operations_rating_data =
            ExportedData &&
              ExportedData.clinicalOperationRating &&
              ExportedData.clinicalOperationRating.length
              ? ExportedData.clinicalOperationRating[0]
              : null

          const operations_score_data =
            ExportedData &&
              ExportedData.clinicalOperationScore &&
              ExportedData.clinicalOperationScore.length
              ? ExportedData.clinicalOperationScore[0]
              : null

          const positive_fiscal =
            ExportedData &&
              ExportedData.getAllPositiveFactorsforFiscalStewardship &&
              ExportedData.getAllPositiveFactorsforFiscalStewardship.length
              ? ExportedData.getAllPositiveFactorsforFiscalStewardship.map((data) => [
                '',
                data.comment,
              ])
              : [[' ', 'N/A']]

          const negative_fiscal: any =
            ExportedData &&
              ExportedData.getAllNegativeFactorsforFiscalStewardship &&
              ExportedData.getAllNegativeFactorsforFiscalStewardship.length
              ? ExportedData.getAllNegativeFactorsforFiscalStewardship.map((data) => [
                '',
                data.comment,
              ])
              : [[' ', 'N/A']]

          const positive_clinical: any =
            ExportedData &&
              ExportedData.getAllPositiveFactorsforClinicalOperations &&
              ExportedData.getAllPositiveFactorsforClinicalOperations.length
              ? ExportedData.getAllPositiveFactorsforClinicalOperations.map(
                (data) => ['', data.comment]
              )
              : [[' ', 'N/A']]
          const negative_clinical: any =
            ExportedData &&
              ExportedData.getAllNegativeFactorsforClinicalOperations &&
              ExportedData.getAllNegativeFactorsforClinicalOperations.length
              ? ExportedData.getAllNegativeFactorsforClinicalOperations.map(
                (data) => ['', data.comment]
              )
              : [[' ', 'N/A']]

          const positive_patient_exp: any =
            ExportedData &&
              ExportedData.getAllPositiveFactorsforExperimentalDesign &&
              ExportedData.getAllPositiveFactorsforExperimentalDesign.length
              ? ExportedData.getAllPositiveFactorsforExperimentalDesign.map(
                (data) => ['', data.comment]
              )
              : [[' ', 'N/A']]
          const negative_patient_exp: any =
            ExportedData &&
              ExportedData.getAllNegativeFactorsforExperimentalDesign &&
              ExportedData.getAllNegativeFactorsforExperimentalDesign.length
              ? ExportedData.getAllNegativeFactorsforExperimentalDesign.map(
                (data) => ['', data.comment]
              )
              : [[' ', 'N/A']]

          const positive_clinical_outcomes: any =
            ExportedData &&
              ExportedData.getAllPositiveFactorsforClinicalOutcome &&
              ExportedData.getAllPositiveFactorsforClinicalOutcome.length
              ? ExportedData.getAllPositiveFactorsforClinicalOutcome.map((data) => [
                '',
                data.comment,
              ])
              : [[' ', 'N/A']]
          const negative_clinical_outcomes: any =
            ExportedData &&
              ExportedData.getAllNegativeFactorsforClinicalOutcome &&
              ExportedData.getAllNegativeFactorsforClinicalOutcome.length
              ? ExportedData.getAllNegativeFactorsforClinicalOutcome.map((data) => [
                '',
                data.comment,
              ])
              : [[' ', 'N/A']]

          const positive_facility_conditions: any =
            ExportedData &&
              ExportedData.getAllPositiveFactorsforFacilityConditions &&
              ExportedData.getAllPositiveFactorsforFacilityConditions.length
              ? ExportedData.getAllPositiveFactorsforFacilityConditions.map(
                (data) => ['', data.comment]
              )
              : [[' ', 'N/A']]
          const negative_facility_conditions: any =
            ExportedData &&
              ExportedData.getAllNegativeFactorsforFacilityConditions &&
              ExportedData.getAllNegativeFactorsforFacilityConditions.length
              ? ExportedData.getAllNegativeFactorsforFacilityConditions.map(
                (data) => ['', data.comment]
              )
              : [[' ', 'N/A']]

          const patient_experience_rating_data: any =
            ExportedData &&
              ExportedData.experimentalDesignRating &&
              ExportedData.experimentalDesignRating.length
              ? ExportedData.experimentalDesignRating[0]
              : null

          const patient_experience_score_data: any =
            ExportedData &&
              ExportedData.experimentalDesignScore &&
              ExportedData.experimentalDesignScore.length
              ? ExportedData.experimentalDesignScore[0]
              : null

          const clinical_outcome_score_data: any =
            ExportedData &&
              ExportedData.clinicalOutcomeScore &&
              ExportedData.clinicalOutcomeScore.length
              ? ExportedData.clinicalOutcomeScore[0]
              : null
          const clinical_outcome_rating_data: any =
            ExportedData &&
              ExportedData.clinicalOutcomeRating &&
              ExportedData.clinicalOutcomeRating.length
              ? ExportedData.clinicalOutcomeRating[0]
              : null

          const facility_conditions_rating_data: any =
            ExportedData &&
              ExportedData.facilityConditionRating &&
              ExportedData.facilityConditionRating.length
              ? ExportedData.facilityConditionRating[0]
              : null
          const facility_conditions_score_data: any =
            ExportedData &&
              ExportedData.facilityConditionScore &&
              ExportedData.facilityConditionScore.length
              ? ExportedData.facilityConditionScore[0]
              : null
          const pdfBlob = pdf(MyDocument(ratings, Performance_SummaryData, OverallPositiveArray, OverallNegativeArray, fiscal_rating_data, fiscal_score_data, positive_fiscal, negative_fiscal, operations_rating_data, operations_score_data, negative_clinical, positive_clinical, patient_experience_rating_data, patient_experience_score_data, positive_patient_exp, negative_patient_exp, clinical_outcome_rating_data, clinical_outcome_score_data, positive_clinical_outcomes, negative_clinical_outcomes, facility_conditions_score_data, facility_conditions_rating_data, positive_facility_conditions, negative_facility_conditions, OrangeTopDivStyle)).toBlob()
          pdfBlob.then((data) => {
            const blobObj = new Blob([data], { type: 'application/pdf' })
            saveAs(blobObj, 'Healthcare Panorama - Hospital Insights')
          })
        })
      }}
    >
      Current Tab
    </MenuItem>
  )
}
