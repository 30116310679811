import React, { useEffect, useState } from 'react'
import {
  PDFDownloadLink,
  PDFViewer,
  Document,
  Page,
  StyleSheet,
  View,
  Image,
  Text,
  Svg,
  Path,
  pdf,
} from '@react-pdf/renderer'
import dprlogo from '../../../Assets/dprlogoImage.png'
import panaromalogo from '../../../Assets/PanoramaImage.png'
import { alpha, width } from '@mui/system'
import {
  backgroundColor,
  performanceText,
  textColor,
} from '../../../config/commonFunction'
import { useLocation } from 'react-router'
import { Button, IconButton, Skeleton, MenuItem } from '@mui/material'
import { useSnackbar, VariantType } from 'notistack'
import { saveAs } from 'file-saver'
import { ExportStatus, useExportData } from '../../../customHooks/useExportData'
import { renderTitleHeader } from '../../CommonComponents/RenderTitleHeader/RenderTitleHeader'
import CloseIcon from '@mui/icons-material/Close'
import ReactDOM from 'react-dom'
import DialogAlert from '../../CommonComponents/DialogBox/dialogAlert'

interface MarketSummaryPDFprops {
  getPdfData: any
}

export const MarketSummaryPDF: React.FC<MarketSummaryPDFprops> = ({ getPdfData }: MarketSummaryPDFprops) => {

  const date = Date().split(' ')
  const search = useLocation().search
  const dpr_city = new URLSearchParams(search).get('city')
  const zipCode = new URLSearchParams(search).get('zipcode')
  const county = new URLSearchParams(search).get('county')
  const radius = new URLSearchParams(search).get('radius')
  let ZipCodeArray: string[] = []
  let ZipCodeTitle = ''
  let CountyTitle = ''
  let countyArray: string[] = []

  if (zipCode) {
    ZipCodeArray = zipCode.split(',')
  }

  if (ZipCodeArray && ZipCodeArray.length > 12) {
    for (let i = 0; i < 12; i++) {
      if (i === 0) {
        ZipCodeTitle = ZipCodeTitle.concat(ZipCodeArray[i])
      } else {
        ZipCodeTitle = ZipCodeTitle.concat(',', ZipCodeArray[i])
      }
    }
    if (ZipCodeTitle) {
      ZipCodeTitle = ZipCodeTitle.concat(`...${ZipCodeArray.length - 12} more`)
    }
  } else {
    if (zipCode) ZipCodeTitle = zipCode
  }

  if (county) {
    countyArray = county.split(',')
  }

  if (countyArray && countyArray.length > 12) {
    for (let i = 0; i < 12; i++) {
      if (i === 0) {
        CountyTitle = CountyTitle.concat(countyArray[i])
      } else {
        CountyTitle = CountyTitle.concat(',', countyArray[i])
      }
    }
    if (CountyTitle) {
      CountyTitle = CountyTitle.concat(`...${countyArray.length - 8} more`)
    }
  } else {
    if (county) CountyTitle = county
  }




  let summary_data

  const monthConverter = (month: any) => {
    const monthArray = [
      ['Jan', '01'],
      ['Feb', '02'],
      ['Mar', '03'],
      ['Apr', '04'],
      ['May', '05'],
      ['Jun', '06'],
      ['Jul', '07'],
      ['Aug', '08'],
      ['Sep', '09'],
      ['Oct', '10'],
      ['Nov', '11'],
      ['Dec', '12'],
    ]

    for (let i = 0; i < monthArray.length; i++) {
      if (monthArray[i][0] == month) return monthArray[i][1]
    }
  }

  const formatedDateTime = `${monthConverter(date[1])}/${date[2]}/${date[3]}`

  const pdfStyle = StyleSheet.create({
    body: {
      paddingTop: 15,
      paddingBottom: 45,
      paddingHorizontal: 15,
    },

    headerView: {
      display: 'flex',
      flexDirection: 'row',
      borderTop: '1px solid #EEE',
      paddingTop: 8,
      paddingBottom: 8,
      textAlign: 'center',
      fontSize: '14px',
      color: '#3E3E3E',
      backgroundColor: '#F3F3F3',
      borderRadius: '5px',
    },
    rowView: {
      display: 'flex',
      flexDirection: 'row',
      borderTop: '1px solid #EEE',
      paddingTop: 8,
      paddingBottom: 8,
      textAlign: 'center',
      fontSize: '12px',
      color: '#3E3E3E',
    },
    footer: {
      flexDirection: 'column',
      position: 'absolute',
      bottom: 10,
      left: 10,
    },
  })

  const RenderRating = (ratingprops) =>
    parseInt(ratingprops?.rating) == 1 ? (
      <View style={{ flexDirection: 'row' }}>
        <View>
          <Svg viewBox={ratingprops.viewbox}>
            <Path
              fill="#FF0707"
              stroke="#FF0707"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-10px' }}>
          <Svg viewBox={ratingprops.viewbox}>
            <Path
              fill="#CCCCCC"
              stroke="#CCCCCC"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-12px' }}>
          <Svg viewBox={ratingprops.viewbox}>
            <Path
              fill="#CCCCCC"
              stroke="#CCCCCC"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-12px' }}>
          <Svg viewBox={ratingprops.viewbox}>
            <Path
              fill="#CCCCCC"
              stroke="#CCCCCC"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-12px' }}>
          <Svg viewBox={ratingprops.viewbox}>
            <Path
              fill="#CCCCCC"
              stroke="#CCCCCC"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
      </View>
    ) : parseInt(ratingprops?.rating) == 2 ? (
      <View style={{ flexDirection: 'row' }}>
        <View>
          <Svg viewBox={ratingprops.viewbox}>
            <Path
              fill="#FB743F"
              stroke="#FB743F"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-10px' }}>
          <Svg viewBox={ratingprops.viewbox}>
            <Path
              fill="#FB743F"
              stroke="#FB743F"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-12px' }}>
          <Svg viewBox={ratingprops.viewbox}>
            <Path
              fill="#CCCCCC"
              stroke="#CCCCCC"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-12px' }}>
          <Svg viewBox={ratingprops.viewbox}>
            <Path
              fill="#CCCCCC"
              stroke="#CCCCCC"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-12px' }}>
          <Svg viewBox={ratingprops.viewbox}>
            <Path
              fill="#CCCCCC"
              stroke="#CCCCCC"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
      </View>
    ) : parseInt(ratingprops?.rating) == 3 ? (
      <View style={{ flexDirection: 'row' }}>
        <View>
          <Svg viewBox={ratingprops.viewbox}>
            <Path
              fill="#FAAF00"
              stroke="#FAAF00"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-10px' }}>
          <Svg viewBox={ratingprops.viewbox}>
            <Path
              fill="#FAAF00"
              stroke="#FAAF00"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-12px' }}>
          <Svg viewBox={ratingprops.viewbox}>
            <Path
              fill="#FAAF00"
              stroke="#FAAF00"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-12px' }}>
          <Svg viewBox={ratingprops.viewbox}>
            <Path
              fill="#CCCCCC"
              stroke="#CCCCCC"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-12px' }}>
          <Svg viewBox={ratingprops.viewbox}>
            <Path
              fill="#CCCCCC"
              stroke="#CCCCCC"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
      </View>
    ) : parseInt(ratingprops?.rating) == 4 ? (
      <View style={{ flexDirection: 'row' }}>
        <View>
          <Svg viewBox={ratingprops.viewbox}>
            <Path
              fill="#73E793"
              stroke="#73E793"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-10px' }}>
          <Svg viewBox={ratingprops.viewbox}>
            <Path
              fill="#73E793"
              stroke="#73E793"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-12px' }}>
          <Svg viewBox={ratingprops.viewbox}>
            <Path
              fill="#73E793"
              stroke="#73E793"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-12px' }}>
          <Svg viewBox={ratingprops.viewbox}>
            <Path
              fill="#73E793"
              stroke="#73E793"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-12px' }}>
          <Svg viewBox={ratingprops.viewbox}>
            <Path
              fill="#CCCCCC"
              stroke="#CCCCCC"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
      </View>
    ) : parseInt(ratingprops?.rating) == 5 ? (
      <View style={{ flexDirection: 'row' }}>
        <View>
          <Svg viewBox={ratingprops.viewbox}>
            <Path
              fill="#4CAF50"
              stroke="#4CAF50"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-10px' }}>
          <Svg viewBox={ratingprops.viewbox}>
            <Path
              fill="#4CAF50"
              stroke="#4CAF50"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-12px' }}>
          <Svg viewBox={ratingprops.viewbox}>
            <Path
              fill="#4CAF50"
              stroke="#4CAF50"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-12px' }}>
          <Svg viewBox={ratingprops.viewbox}>
            <Path
              fill="#4CAF50"
              stroke="#4CAF50"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-12px' }}>
          <Svg viewBox={ratingprops.viewbox}>
            <Path
              fill="#4CAF50"
              stroke="#4CAF50"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
      </View>
    ) : (
      <View style={{ flexDirection: 'row' }}>
        <View>
          <Svg viewBox={ratingprops.viewbox}>
            <Path
              fill="#CCCCCC"
              stroke="#CCCCCC"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-10px' }}>
          <Svg viewBox={ratingprops.viewbox}>
            <Path
              fill="#CCCCCC"
              stroke="#CCCCCC"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-12px' }}>
          <Svg viewBox={ratingprops.viewbox}>
            <Path
              fill="#CCCCCC"
              stroke="#CCCCCC"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-12px' }}>
          <Svg viewBox={ratingprops.viewbox}>
            <Path
              fill="#CCCCCC"
              stroke="#CCCCCC"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
        <View style={{ marginLeft: '-12px' }}>
          <Svg viewBox={ratingprops.viewbox}>
            <Path
              fill="#CCCCCC"
              stroke="#CCCCCC"
              d="M12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7z"
            />
          </Svg>
        </View>
      </View>
    )



  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const ShowSnackBar = (
    message,
    variant: VariantType,
    duration,
    key?: string
  ) => {
    enqueueSnackbar(<div>{message}</div>, {
      variant: variant,
      autoHideDuration: duration,
      anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
      style: {
        flexDirection: 'row',
        maxWidth: '50px',
        marginLeft: '-150px',
      },
      key: key,
      action: (key) => (
        <IconButton
          color="inherit"
          aria-label="dismiss"
          onClick={() => closeSnackbar(key)}
        >
          <CloseIcon />
        </IconButton>
      ),
    })
    return key
  }

  const MyDocument = (HeaderTitle, MarketTitle, Summary_data, market_data, positiveFactorData, negativeFactorData) => (
    <Document>
      <Page size="A4" style={pdfStyle.body}>
        <View fixed wrap>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              paddingBottom: '10px',
            }}
          >
            <Image
              src={dprlogo}
              style={{
                width: '74px',
                height: '45px',
                padding: '5px',
              }}
            ></Image>

            <View
              style={{
                borderRight: '1px solid #CCCCCC',
                height: '30px',
                marginTop: '8px',
                marginLeft: '10px',
              }}
            ></View>

            <Image
              src={panaromalogo}
              style={{
                width: '100px',
                height: '30px',
                marginLeft: '20px',
                marginTop: '8px',
                padding: 5,
              }}
            ></Image>

            <Text
              style={{
                fontSize: '20px',
                color: '#000000',
                marginLeft: 'auto',
                marginTop: '15px',
              }}
            >
              MARKET INSIGHTS
            </Text>
          </View>

          <View
            style={{
              borderBottom: '2px solid #4CAF50',
              width: '100%',
              marginBottom: '20px',
              marginTop: 10,
            }}
          ></View>

          <View
            wrap
            style={{
              backgroundColor: '#E8F5E9',
              border: '1px solid #E8F5E9',
              height: 60,
              width: '100%',
              borderRadius: 8,
            }}
          >
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <View>
                <Text
                  style={{
                    fontSize: 10,
                    fontWeight: 400,
                    color: '#3E3E3E',
                    padding: 5,
                    marginTop: '5px',
                    marginLeft: '10px',
                  }}
                >
                  {HeaderTitle}
                </Text>

                <Text
                  style={{
                    fontSize: 13,
                    fontWeight: 'bold',
                    color: '#3E3E3E',
                    padding: 5,
                    marginTop: '-5px',
                    marginLeft: '10px',
                  }}
                >
                  {MarketTitle}
                </Text>
              </View>
            </View>
          </View>

          <View style={{ flexDirection: 'row', marginTop: '13px' }}>
            <Text
              style={{
                fontSize: '20px',
                color: alpha('#000000', 0.8),
                marginRight: 'auto',
                marginTop: '8px',
              }}
            >
              SUMMARY
            </Text>
            <View
              style={{
                borderBottom: '1.2px solid black',
                width: '80%',
                marginBottom: '10px',
                marginTop: 10,
              }}
            ></View>
          </View>

          <View
            style={{
              position: 'relative',
              width: '568px',
              height: '32px',
              backgroundColor: backgroundColor(
                Math.round(Summary_data?.ovr_rating)
              ),
              border: `1px solid ${textColor(
                Math.round(Summary_data?.ovr_rating)
              )}`,
              borderRadius: '4.63295px',
              marginTop: '15px',
              flexDirection: 'row',
              alignItems: 'stretch',
              alignContent: 'space-around',
              padding: '0.5px',
            }}
          >
            <Text
              style={{
                fontSize: 10,
                fontWeight: 900,
                marginTop: '7px',
                marginLeft: '10px',
              }}
            >
              OVERALL MARKET OPPORTUNITY
            </Text>
            <View style={{ width: '220px' }}></View>
            <View
              style={{
                marginTop: '5px',
                height: '25px',
                width: '120px',
                marginLeft: '50px',
              }}
            >
              <RenderRating
                rating={Math.round(Summary_data?.ovr_rating)}
                viewbox="0 0 45 45"
              />
            </View>
            <Text
              style={{
                fontSize: 10,
                marginTop: '6.5px',
                fontWeight: 800,
                marginLeft: 'auto',
                paddingRight: '14px',
              }}
            >
              {performanceText(Math.round(Summary_data?.ovr_rating))
                ?.replace('(', '')
                .replace(')', '')}
            </Text>
          </View>

          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <View style={{ display: 'flex', flexDirection: 'column' }}>
              {market_data
                ? market_data.map((marketData) => {
                  return (
                    <View
                      style={{
                        border: '2px solid #E8F5E9',
                        height: '115px',
                        borderRadius: '7px',
                        marginTop: '10px',
                        width: '310px',
                      }}
                    >
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          position: 'relative',
                        }}
                      >
                        <Text
                          style={{
                            fontSize: '14px',
                            color: '#22577A',
                            paddingTop: '8px',
                            paddingLeft: '15px',
                          }}
                        >
                          {marketData.Title}
                        </Text>

                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginTop: '10px',
                            position: 'absolute',
                            width: '70px',
                            textAlign: 'right',
                            right: 5,
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 8,
                              marginRight: '3px',
                              marginTop: '2px',
                            }}
                          >
                            {marketData.rating
                              ? `(${Math.round(marketData.rating)}/5)`
                              : ''}
                          </Text>

                          <Svg
                            width="13px"
                            height="12px"
                            viewBox="0 0 13 12"
                            style={{
                              marginRight: '8px',
                            }}
                          >
                            <Path
                              d="M6.3164 9.43052L9.88566 11.5848L8.93848 7.52461L12.0919 4.79279L7.93931 4.43471L6.3164 0.611328L4.69348 4.43471L0.540894 4.79279L3.68854 7.52461L2.74714 11.5848L6.3164 9.43052Z"
                              fill={textColor(Math.round(marketData.rating))}
                              stroke={textColor(
                                Math.round(marketData.rating)
                              )}
                            />
                          </Svg>

                          <Text
                            style={{
                              fontSize: 8,
                              marginTop: '2px',
                            }}
                          >
                            {performanceText(
                              Math.round(marketData.rating),
                              'capitalize'
                            )
                              ?.replace('(', '')
                              .replace(')', '')}
                          </Text>
                        </View>
                      </View>

                      <View
                        style={{
                          borderTop: '2px solid #E8F5E9',
                          width: '290px',
                          marginTop: '5px',
                          marginLeft: '5px',
                        }}
                      ></View>

                      <Text
                        style={{
                          fontSize: '11px',
                          color: '#3E3E3E',
                          paddingTop: '8px',
                          paddingLeft: '15px',
                        }}
                      >
                        {marketData.Heading}
                      </Text>

                      <Text
                        style={{
                          fontSize: '9px',
                          color: '#3E3E3E',
                          paddingTop: '8px',
                          paddingLeft: '15px',
                          paddingRight: '4px',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          paddingBottom: '8px',
                        }}
                      >
                        {marketData.Comment}
                      </Text>
                    </View>
                  )
                })
                : null}
            </View>

            <View>
              <View wrap style={{ flexDirection: 'column' }}>
                <Text
                  style={{
                    fontSize: 12,
                    marginTop: '13px',
                    color: '#22577A',
                    marginLeft: '20px',
                    fontWeight: 800,
                    marginBottom: '5px',
                  }}
                >
                  Positive Factors
                </Text>
                <View
                  wrap={false}
                  style={{
                    position: 'relative',
                    width: '263.66px',
                    padding: '5px',
                    marginLeft: '20px',
                  }}
                ></View>
                <View style={{ flexDirection: 'column' }}>
                  {positiveFactorData ? (
                    positiveFactorData.map((data) => {
                      return (
                        <View
                          wrap={false}
                          style={{
                            position: 'relative',
                            width: '250px',
                            padding: '5px',
                            marginLeft: '20px',
                            marginBottom: '10px',
                            backgroundColor: '#FAFAFA',
                            border: '1px solid #E8F5E9',
                            borderRadius: '8.3px',
                          }}
                        >
                          <Text style={{ fontSize: 9 }}>{data}</Text>
                        </View>
                      )
                    })
                  ) : (
                    <Text style={{ fontSize: 12, marginLeft: '20px' }}>
                      N/A
                    </Text>
                  )}
                </View>
              </View>

              <View wrap style={{ flexDirection: 'column' }}>
                <Text
                  style={{
                    fontSize: 12,
                    marginTop: '13px',
                    color: '#22577A',
                    marginLeft: '20px',
                    fontWeight: 800,
                    marginBottom: '5px',
                  }}
                >
                  Risk Factors
                </Text>
                <View
                  wrap={false}
                  style={{
                    position: 'relative',
                    width: '263.66px',
                    padding: '5px',
                    marginLeft: '20px',
                  }}
                ></View>
                <View style={{ flexDirection: 'column' }}>
                  {negativeFactorData && negativeFactorData.length > 0 ? (
                    negativeFactorData.map((data) => {
                      return (
                        <View
                          wrap={false}
                          style={{
                            position: 'relative',
                            width: '250px',
                            padding: '5px',
                            marginLeft: '20px',
                            marginBottom: '10px',
                            backgroundColor: '#FAFAFA',
                            border: '1px solid #E8F5E9',
                            borderRadius: '8.3px',
                          }}
                        >
                          <Text style={{ fontSize: 8 }}>{data}</Text>
                        </View>
                      )
                    })
                  ) : (
                    <Text style={{ fontSize: 12, marginLeft: '20px' }}>
                      N/A
                    </Text>
                  )}
                </View>
              </View>
            </View>
          </View>
        </View>

        <View wrap fixed style={pdfStyle.footer}>
          <View
            style={{
              border: '1px solid #D9D9D9',
              marginTop: '10px',
              width: '520px',
              height: '1px',
              left: '25px',
            }}
          ></View>
          <View
            style={{ flexDirection: 'row', fontSize: 8, marginTop: '10px' }}
          >
            <Text style={{ marginLeft: '30px', fontSize: 10 }}>
              Export Date : {formatedDateTime}
            </Text>
            <Text
              style={{
                fontWeight: 800,
                marginLeft: '70px',
                fontSize: 10,
              }}
            >
              Confidential: Do Not Distribute Outside of DPR
            </Text>
            <Text
              style={{ marginLeft: '110px', fontSize: 10 }}
              render={({ pageNumber }) => `Page ${pageNumber}`}
            />
          </View>
        </View>
      </Page>
    </Document>
  )

  return (
    <MenuItem
      onClick={() => {
        const closeSnack = ShowSnackBar(
          'Generating Export File, Please wait !!',
          'info',
          10000000,
          'close'
        )
        getPdfData().then(() => {
          ReactDOM.render(<DialogAlert />, document.createElement('div'))
          summary_data = localStorage.getItem('EXPORT_DATA')
          let marketSummaryData = JSON.parse(summary_data)
          let Summary_data
          let Factors
          if (marketSummaryData) {
            Summary_data = marketSummaryData.MarketSummaryData[0]
            Factors = marketSummaryData.MarketFactorData[0]
          }

          let market_data = [
            {
              Title: 'Market Concentration',
              Heading: Summary_data?.market_size_rating_desc
                ? Summary_data?.market_size_rating_desc
                : 'N/A',
              Comment: Summary_data?.market_size_rating_comment
                ? Summary_data?.market_size_rating_comment
                : 'N/A',
              rating: Summary_data?.market_size_rating
                ? Summary_data?.market_size_rating
                : 0,
            },
            {
              Title: '5-Yr Population Growth',
              Heading: Summary_data?.population_growth
                ? Summary_data?.population_growth.toFixed(1) + '%'
                : 'N/A',
              Comment: Summary_data?.pop_growth_comment
                ? Summary_data?.pop_growth_comment
                : 'N/A',
              rating: Summary_data?.population_growth_rating
                ? Summary_data?.population_growth_rating
                : 0,
            },
            {
              Title: '5-Yr Inpatient Growth',
              Heading: Summary_data?.ip_growth
                ? Summary_data?.ip_growth.toFixed(1) + '%'
                : 'N/A',
              Comment: Summary_data?.ip_growth_comment
                ? Summary_data?.ip_growth_comment
                : 'N/A',
              rating: Summary_data?.ip_growth_rating
                ? Summary_data?.ip_growth_rating
                : 0,
            },
            {
              Title: '5-Yr Outpatient Growth',
              Heading: Summary_data?.op_growth
                ? Summary_data?.op_growth.toFixed(1) + '%'
                : 'N/A',
              Comment: Summary_data?.op_growth_comment
                ? Summary_data?.op_growth_comment
                : 'N/A',
              rating: Summary_data?.op_growth_rating
                ? Summary_data?.op_growth_rating
                : 0,
            },
            {
              Title: 'County Health',
              Heading: Summary_data?.chr_desc ? Summary_data?.chr_desc : 'N/A',
              Comment: Summary_data?.chr_comment ? Summary_data?.chr_comment : 'N/A',
              rating: Summary_data?.chr_rating ? Summary_data.chr_rating : 0,
            },
          ]

          let positiveFactorData: string[] = []
          let negativeFactorData: string[] = []

          if (Factors) {
            if (Factors.chr_rating_factor_type === 'positive')
              positiveFactorData.push(Factors?.chr_factor as any)
            else negativeFactorData.push(Factors?.chr_factor as any)

            if (Factors.ip_growth_factor_type === 'positive')
              positiveFactorData.push(Factors?.ip_growth_factor as any)
            else negativeFactorData.push(Factors?.ip_growth_factor as any)

            if (Factors.market_size_factor_type === 'positive')
              positiveFactorData.push(Factors?.market_size_rating_factor as any)
            else negativeFactorData.push(Factors?.market_size_rating_factor as any)

            if (Factors.op_growth_factor_type === 'positive')
              positiveFactorData.push(Factors?.op_growth_factor as any)
            else negativeFactorData.push(Factors?.op_growth_factor as any)

            if (Factors.population_growth_factor_type === 'positive')
              positiveFactorData.push(Factors?.pop_growth_factor as any)
            else negativeFactorData.push(Factors?.pop_growth_factor as any)
          }

          let HeaderTitle = dpr_city
            ? 'DPR Market City'
            : zipCode && radius
              ? 'Zip Code, Radius'
              : zipCode
                ? 'Zip Code'
                : 'County'

          let MarketTitle = dpr_city
            ? dpr_city
            : zipCode && radius
              ? `${radius} miles from ${zipCode}`
              : zipCode
                ? ZipCodeTitle
                : CountyTitle

          const pdfBlob = pdf(MyDocument(HeaderTitle, MarketTitle, Summary_data, market_data, positiveFactorData, negativeFactorData)).toBlob()
          pdfBlob.then((data) => {
            const blobObj = new Blob([data], { type: 'application/pdf' })
            saveAs(blobObj, 'Healthcare Panorama - Market Insights')
          })
          setTimeout(() => {
            closeSnackbar(closeSnack)
          }, 4000);
        })
      }}
    >
      Current Tab
    </MenuItem>
  )
}
