import React from "react";
import { Button, Grid } from "@mui/material";
import { alpha, styled } from '@mui/material/styles';
import { Link } from "react-router-dom";

interface IButtonProps {
    clear: Function;
    url: string;
    disable: boolean;
}

export const ButtonComponent: React.FC<IButtonProps> = (props: IButtonProps) => {

    const ClearButton = styled(Button)(() => ({
        color: "#3e3e3e",
        padding: "14px", textTransform: "capitalize", borderRadius: "0px",
        backgroundColor: "#F3F3F3",
        '&:hover': {
            backgroundColor: "#F3F3F3",
        },
        fontFamily: "Open Sans",
        width: "100%",
        fontSize: "16px",
        fontWeight: "400"
    }));

    const SearchButton = styled(Button)(() => ({
        padding: "14px", textTransform: "capitalize", borderRadius: "0px",
        backgroundColor: alpha("#4CAF50", props.disable ? 0.5 : 1),
        '&:hover': {
            backgroundColor: "#4CAF50",
        },
        fontFamily: "Open Sans",
        width: "100%",
        fontSize: "16px",
        fontWeight: "400", color: props.disable ? "#000" : "#fff",
    }));

    return (
        <Grid container
        >
            <Grid xs={6}>
                <ClearButton data-clear-id="Clear button clicked" onClick={() => { props.clear() }}> Clear</ClearButton>
            </Grid>
            <Grid xs={6}>
                {props.disable ?
                    <SearchButton disabled={props.disable}>Search</SearchButton>
                    :
                    <Link to={props.url} style={{ textDecoration: "none" }}>
                        <SearchButton data-search-id="Search button clicked" disabled={props.disable}>Search</SearchButton>
                    </Link>}
            </Grid>
        </Grid>

    );
}
