/* eslint-disable react-hooks/rules-of-hooks */
import { Grid, Box, Stack, Typography, Skeleton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import { useLocation } from 'react-router-dom'
import { useGetMarketFactorsByCityLazyQuery, useGetMarketFactorsByCountyLazyQuery, useGetMarketFactorsByZipCodeAndRadiusLazyQuery, useGetMarketFactorsByZipCodeLazyQuery } from '../../../../__generated__/graphql'
import { appInsights } from '../../../../providers/appInsightProvider'

export interface IFactorProps {
  module_name?: string
}

export const MarketFactors: React.FC<IFactorProps> = (props: IFactorProps) => {
  const search = useLocation().search
  const city = new URLSearchParams(search).get('city');
  const county = new URLSearchParams(search).get('county');
  const zip_code = new URLSearchParams(search).get('zipcode');
  const radius = new URLSearchParams(search).get('radius');

  const [positiveFactors, setPositiveFactors] = useState<any[]>([]);
  const [negativeFactors, setNegativeFactors] = useState<any[]>([]);


  const [loadMarketFactorsByCity, {
    data: MarkerFactorsByCity,
    loading: MarkerFactorsByCityLoading,
    error: MarkerFactorsByCityError,
  }] = useGetMarketFactorsByCityLazyQuery({
    variables: {
      city
    },
    fetchPolicy: 'network-only'
  })

  const [loadMarketFactorsByZipCode, {
    data: MarkerFactorsByZipCode,
    loading: MarkerFactorsByZipCodeLoading,
    error: MarkerFactorsByZipCodeError,
  }] = useGetMarketFactorsByZipCodeLazyQuery({
    variables: {
      zip_codes: zip_code
    },
    fetchPolicy: 'network-only'
  })

  let county_data: string[] = [];
  if (county) {
    let counties_array = county.split(",");
    for (let i = 0; i < counties_array.length; i++) {
      const element = counties_array[i];
      let splitted_val = element.split(" - ");
      let string_val: string = splitted_val[1].trim() + splitted_val[0].trim();
      county_data.push(string_val);
    }
  }

  const [loadMarketFactorsByCounty, {
    data: MarkerFactorsByCounty,
    loading: MarkerFactorsByCountyLoading,
    error: MarkerFactorsByCountyError,
  }] = useGetMarketFactorsByCountyLazyQuery({
    variables: {
      county: county_data.join(",")
    },
    fetchPolicy: 'network-only'
  })


  const [loadMarketFactorsByZipCodeAndRadius, {
    data: MarkerFactorsByAndRadius,
    loading: MarkerFactorsByZipCodeAndRadiusLoading,
    error: MarkerFactorsByZipCodeAndRadiusError,
  }] = useGetMarketFactorsByZipCodeAndRadiusLazyQuery({
    variables: {
      zip_codes: zip_code,
      radius: radius ? parseInt(radius) : 1
    },
    fetchPolicy: 'network-only'
  })

  useEffect(() => {
    if (MarkerFactorsByCityError instanceof Error) {
      appInsights.trackException({ exception: MarkerFactorsByCityError, error: new Error("Failed to fetch Market factors for City.") })
    }
    else if (MarkerFactorsByZipCodeError instanceof Error) {
      appInsights.trackException({ exception: MarkerFactorsByZipCodeError, error: new Error("Failed to fetch Market factors for Zip code.") })
    }
    else if (MarkerFactorsByCountyError instanceof Error) {
      appInsights.trackException({ exception: MarkerFactorsByCountyError, error: new Error("Failed to fetch Market factors for county.") })
    }
    else if (MarkerFactorsByZipCodeAndRadiusError instanceof Error) {
      appInsights.trackException({ exception: MarkerFactorsByZipCodeAndRadiusError, error: new Error("Failed to fetch Market factors for zipcode and radius.") })
    }
  }, [MarkerFactorsByCityError, MarkerFactorsByZipCodeError, MarkerFactorsByCountyError, MarkerFactorsByZipCodeAndRadiusError])


  useEffect(() => {
    if (city) {
      loadMarketFactorsByCity();
    }
    else if (zip_code && radius) {
      loadMarketFactorsByZipCodeAndRadius();
    }
    else if (zip_code) {
      loadMarketFactorsByZipCode();
    }
    else if (county) {
      loadMarketFactorsByCounty();
    }
  }, [])

  useEffect(() => {

    let Factors: any = null;
    if (MarkerFactorsByCity && MarkerFactorsByCity?.udf_summary_dpr_market && MarkerFactorsByCity.udf_summary_dpr_market.length)
      Factors = MarkerFactorsByCity?.udf_summary_dpr_market[0];
    else if (MarkerFactorsByZipCode && MarkerFactorsByZipCode?.udf_multi_zip_result && MarkerFactorsByZipCode?.udf_multi_zip_result.length)
      Factors = MarkerFactorsByZipCode?.udf_multi_zip_result[0];
    else if (MarkerFactorsByCounty && MarkerFactorsByCounty?.udf_multi_counties_result && MarkerFactorsByCounty.udf_multi_counties_result.length)
      Factors = MarkerFactorsByCounty?.udf_multi_counties_result[0]
    else if (MarkerFactorsByAndRadius && MarkerFactorsByAndRadius?.udf_zip_radius_result && MarkerFactorsByAndRadius?.udf_zip_radius_result.length)
      Factors = MarkerFactorsByAndRadius?.udf_zip_radius_result[0];

    let positiveFactorData: string[] = [];

    let negativeFactorData: string[] = [];
    if (Factors) {
      if (Factors.chr_rating_factor_type === "positive")
        positiveFactorData.push(Factors?.chr_factor as any);
      else negativeFactorData.push(Factors?.chr_factor as any);

      if (Factors.ip_growth_factor_type === "positive")
        positiveFactorData.push(Factors?.ip_growth_factor as any);
      else negativeFactorData.push(Factors?.ip_growth_factor as any);

      if (Factors.market_size_factor_type === "positive")
        positiveFactorData.push(Factors?.market_size_rating_factor as any);
      else negativeFactorData.push(Factors?.market_size_rating_factor as any);

      if (Factors.op_growth_factor_type === "positive")
        positiveFactorData.push(Factors?.op_growth_factor as any);
      else negativeFactorData.push(Factors?.op_growth_factor as any);

      if (Factors.population_growth_factor_type === "positive")
        positiveFactorData.push(Factors?.pop_growth_factor as any);
      else negativeFactorData.push(Factors?.pop_growth_factor as any);
    }

    setNegativeFactors(negativeFactorData);
    setPositiveFactors(positiveFactorData);

  }, [MarkerFactorsByCityLoading, MarkerFactorsByZipCodeLoading, MarkerFactorsByCountyLoading, MarkerFactorsByZipCodeAndRadiusLoading])

  const FactorsTextStyle = styled(Box)(() => ({
    color: '#22577A',
    fontSize: "16px",
    fontWeight: "700"
  }))

  const Item = styled(Box)(({ theme }) => ({
    backgroundColor: "#FAFAFA",
    padding: theme.spacing(1),
    textAlign: 'left',
    color: "#3e3e3e",
    border: "1px solid #E8F5E9",
    borderRadius: "15px",
    margin: "10px 10px 5px 0px",
    fontSize: "13px"
  }));

  return (
    <React.Fragment>
      {MarkerFactorsByCityLoading || MarkerFactorsByZipCodeLoading || MarkerFactorsByCountyLoading || MarkerFactorsByZipCodeAndRadiusLoading ?
        <Typography variant='h3' sx={{ margin: "10px" }}>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </Typography>
        :
        <Stack>
          <FactorsTextStyle>Positive Factors</FactorsTextStyle>
          {
            positiveFactors && positiveFactors.length ?
              <Grid>
                {positiveFactors.map(comments => {
                  return <Item>{comments}</Item>
                })}
              </Grid>
              : "N/A"
          }
        </Stack>
      }
      <br />
      {MarkerFactorsByCityLoading || MarkerFactorsByZipCodeLoading || MarkerFactorsByCountyLoading ?
        <Typography variant='h3' sx={{ margin: "10px" }}>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </Typography>
        :
        <Stack>
          <FactorsTextStyle>Risk Factors</FactorsTextStyle>

          {
            negativeFactors && negativeFactors.length ?
              <Grid>
                {negativeFactors.map(comments => {
                  return <Item>{comments}</Item>
                })}
              </Grid>
              : "N/A"
          }
        </Stack>
      }
    </React.Fragment >

  )
}
