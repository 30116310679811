
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { memo, useRef, useState } from 'react';


const DialogAlert = () => {

    const [open, setOpen] = useState(true)
    const handleClose = () => {
        setOpen(!open)
    }

    const windowSize = useRef(window.innerWidth)
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={{ width: '500px', height: '500px', alignSelf: 'flex-end', justifySelf: 'flex-end', position: 'absolute', left: `${windowSize.current / 2.66}px`, marginTop: '10%', padding: '-5px' }}
        >
            <DialogTitle id="alert-dialog-title" style={{ display: 'inline' }}>
                {"Alert !!"}
                <IconButton onClick={handleClose} style={{ left: '288px' }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent id="alert-dialog-description">
                In keeping with DPR’s policy of protecting sensitive information, please do not forward this document outside of DPR.
            </DialogContent>
        </Dialog>
    )
};

export default memo(DialogAlert);