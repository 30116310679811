import { Grid, Box, Divider, Rating, Stack, Skeleton, Typography, Tooltip } from '@mui/material';
import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import { useGetClinicalOutcomeRatingQuery, useGetClinicalOutcomeScoreQuery, useGetFiscalStewarshipRatingQuery, useGetFiscalStewarshipScoreQuery } from '../../../../../__generated__/graphql';
import { Factors } from './Factors';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { performanceText, textColor, backgroundColor } from '../../../../../config/commonFunction';
import { appInsights } from '../../../../../providers/appInsightProvider';


export interface ClinicalOutcomeProps {
    overall_rating: number | null;
}

export const ClinicalOutcome: React.FC<ClinicalOutcomeProps> = (props: ClinicalOutcomeProps) => {

    const search = useLocation().search;
    const definitive_id = new URLSearchParams(search).get('id');
    const { data: ClinicalOutcomeRatingData, loading: ClinicalOutcomeRatingLoading, error: ClinicalOutcomeRatingError } = useGetClinicalOutcomeRatingQuery({
        variables: {
            definitive_id
        }
    });

    const { data: ClinicalOutcomeScoreData, loading: ClinicalOutcomeScoreLoading, error: ClinicalOutcomeScoreError } = useGetClinicalOutcomeScoreQuery({
        variables: {
            definitive_id
        }
    });

    useEffect(() => {
        if (ClinicalOutcomeRatingError instanceof Error) {
            appInsights.trackException({ exception: ClinicalOutcomeRatingError, error: new Error("Failed to fetch Clinical Outcome Rating.") })
        }
    }, [ClinicalOutcomeRatingError])

    useEffect(() => {
        if (ClinicalOutcomeScoreError instanceof Error) {
            appInsights.trackException({ exception: ClinicalOutcomeScoreError, error: new Error("Failed to fetch Clinical Outcome Score.") })
        }
    }, [ClinicalOutcomeScoreError])

    let clinical_outcome_rating_data: any = ClinicalOutcomeRatingData && ClinicalOutcomeRatingData.DEFINITIVEHEALTHCARE_VW_RATINGS_CLINICAL_OUTCOMES && ClinicalOutcomeRatingData.DEFINITIVEHEALTHCARE_VW_RATINGS_CLINICAL_OUTCOMES.length ? ClinicalOutcomeRatingData.DEFINITIVEHEALTHCARE_VW_RATINGS_CLINICAL_OUTCOMES[0] : null;
    let clinical_outcome_score_data: any = ClinicalOutcomeScoreData && ClinicalOutcomeScoreData.DEFINITIVEHEALTHCARE_VW_SCORE_CLINICAL_OUTCOMES && ClinicalOutcomeScoreData.DEFINITIVEHEALTHCARE_VW_SCORE_CLINICAL_OUTCOMES.length ? ClinicalOutcomeScoreData.DEFINITIVEHEALTHCARE_VW_SCORE_CLINICAL_OUTCOMES[0] : null;

    const TopDivStyle = styled(Box)(() => ({
        borderRadius: "4px",
        padding: "10px 35px 10px 15px",
    }));

    const ClientPerformanceText = styled(Grid)(() => ({
        fontSize: "13px",
        margin: "auto",
        textAlign: "end",
        fontWeight: "600"
    }))

    const HeadingText = styled(Typography)(() => ({
        color: '#3E3E3E',
        fontWeight: '400px',
        fontSize: '13px',
    }))

    const DataText = styled(Typography)(() => ({
        fontWeight: '700',
        fontSize: '14px',
    }))

    return <React.Fragment>
        <Grid container sx={{ margin: "15px 0px" }}>
            <Grid item lg={4.5} md={4.5} >
                {props.overall_rating ?
                    <TopDivStyle sx={{ border: `1px solid ${textColor(Math.round(props.overall_rating))}`, background: backgroundColor(Math.round(props.overall_rating)) }}>
                        <Grid container>
                            <Grid item lg={6} md={6} sx={{ textAlign: "start" }}>
                                <Rating name="read-only" value={Math.round(props.overall_rating)} readOnly sx={{ color: textColor(Math.round(props.overall_rating)) }} />
                            </Grid>
                            <ClientPerformanceText item lg={6} md={6}>
                                {performanceText(Math.round(props.overall_rating))?.replace("(", "").replace(")", "")}
                            </ClientPerformanceText>
                        </Grid>
                    </TopDivStyle>
                    : null}

                {ClinicalOutcomeRatingLoading || ClinicalOutcomeScoreLoading ?
                    <Typography variant='h3' sx={{ margin: "5px" }}>
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                    </Typography>
                    :
                    clinical_outcome_rating_data && clinical_outcome_score_data ?
                        <Stack spacing={0.5} sx={{ marginTop: "20px", marginLeft: "10px", overflow: "scroll", height: "45vh", padding: "10px" }}>

                            <Stack direction="row" spacing={0.5}>
                                <HeadingText>Hospital Compare Overall Quality Rating</HeadingText>
                                <Tooltip
                                    title="Centers for Medicare and Medicaid Services (CMS) Summary Star Ratings reflect a hospital’s quality performance based on a maximum score of 5 stars."
                                    placeholder="top"
                                    arrow
                                >
                                    <InfoOutlinedIcon
                                        fontSize="small"
                                        style={{ width: '15px' }}
                                    ></InfoOutlinedIcon>
                                </Tooltip>
                            </Stack>
                            <DataText sx={{
                                color: textColor(Math.round(clinical_outcome_rating_data?.overall_quality_rating))
                            }}>
                                {clinical_outcome_score_data?.overall_quality_rating || clinical_outcome_score_data?.overall_quality_rating === 0 ? clinical_outcome_score_data.overall_quality_rating : "N/A"}
                                {clinical_outcome_rating_data?.overall_quality_rating ? performanceText(Math.round(clinical_outcome_rating_data?.overall_quality_rating)) : ""}
                            </DataText>

                            <br />

                            <Stack direction="row" spacing={0.5}>
                                <HeadingText>Readmission Rate</HeadingText>
                                <Tooltip
                                    title=" 30-day readmission rate of a patient back to the hospital. High readmissions are typically associated with poor inpatient or outpatient care, unfavorable patient outcomes, and high financial costs"
                                    placeholder="top"
                                    arrow
                                >
                                    <InfoOutlinedIcon
                                        fontSize="small"
                                        style={{ width: '15px' }}
                                    ></InfoOutlinedIcon>
                                </Tooltip>
                            </Stack>
                            <DataText sx={{
                                color: textColor(Math.round(clinical_outcome_rating_data?.readmission_rate))
                            }}>
                                {clinical_outcome_score_data?.readmission_rate || clinical_outcome_score_data?.readmission_rate === 0 ? (clinical_outcome_score_data.readmission_rate * 100).toFixed(1) + "%" : "N/A"}
                                {clinical_outcome_rating_data?.readmission_rate ? performanceText(Math.round(clinical_outcome_rating_data?.readmission_rate)) : ""}
                            </DataText>
                            <br />

                            <Stack direction="row" spacing={0.5}>
                                <HeadingText>Most Recent Year Revenue Loss Due to Hospital Acquired Condition Penalty</HeadingText>
                                <Tooltip
                                    title="Most recent year’s penalty on all Medicare payments for excess readmissions back to the hospital"
                                    placeholder="top"
                                    arrow
                                >
                                    <InfoOutlinedIcon
                                        fontSize="small"
                                        style={{ width: '15px' }}
                                    ></InfoOutlinedIcon>
                                </Tooltip>
                            </Stack>
                            <DataText sx={{
                                color: textColor(Math.round(clinical_outcome_rating_data?.revenue_penalty_due_to_hospital_acquired_condition))
                            }}>
                                {clinical_outcome_score_data?.revenue_penalty_due_to_hospital_acquired_condition || clinical_outcome_score_data?.revenue_penalty_due_to_hospital_acquired_condition === 0 ? clinical_outcome_score_data.revenue_penalty_due_to_hospital_acquired_condition.toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                }) : "N/A"}
                                {clinical_outcome_rating_data?.revenue_penalty_due_to_hospital_acquired_condition ? performanceText(Math.round(clinical_outcome_rating_data?.revenue_penalty_due_to_hospital_acquired_condition)) : ""}
                            </DataText>
                            <br />

                            <Stack direction="row" spacing={0.5}>
                                <HeadingText>Most Recent Year Revenue Loss Due to Readmission Penalty</HeadingText>
                                <Tooltip
                                    title="Most recent year’s penalty on all Medicare payments for conditions acquired during the patient’s stay at the hospital"
                                    placeholder="top"
                                    arrow
                                >
                                    <InfoOutlinedIcon
                                        fontSize="small"
                                        style={{ width: '15px' }}
                                    ></InfoOutlinedIcon>
                                </Tooltip>
                            </Stack>
                            <DataText sx={{
                                color: clinical_outcome_score_data?.revenue_penalty_due_to_excess_readmissions < 0 ? "#FF0707" : textColor(Math.round(clinical_outcome_rating_data?.revenue_penalty_due_to_excess_readmissions))
                            }}>
                                {clinical_outcome_score_data?.revenue_penalty_due_to_excess_readmissions || clinical_outcome_score_data?.revenue_penalty_due_to_excess_readmissions === 0 ? clinical_outcome_score_data.revenue_penalty_due_to_excess_readmissions.toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                }) : "N/A"}
                                {clinical_outcome_rating_data?.revenue_penalty_due_to_excess_readmissions ? performanceText(Math.round(clinical_outcome_rating_data?.revenue_penalty_due_to_excess_readmissions)) : ""}
                            </DataText>
                            <br />

                            <Stack direction="row" spacing={0.5}>
                                <HeadingText>Previous Year Revenue Loss Due to Hospital Acquired Condition Penalty</HeadingText>
                                <Tooltip
                                    title="Previous year’s penalty on all Medicare payments for excess readmissions back to the hospital"
                                    placeholder="top"
                                    arrow
                                >
                                    <InfoOutlinedIcon
                                        fontSize="small"
                                        style={{ width: '15px' }}
                                    ></InfoOutlinedIcon>
                                </Tooltip>
                            </Stack>
                            <DataText
                                sx={{
                                    color: textColor(Math.round(clinical_outcome_rating_data?.prev_year_revenue_penalty_due_to_hospital_acquired_condition))
                                }}>
                                {clinical_outcome_score_data?.prev_year_revenue_penalty_due_to_hospital_acquired_condition || clinical_outcome_score_data.prev_year_revenue_penalty_due_to_hospital_acquired_condition === 0 ? clinical_outcome_score_data.prev_year_revenue_penalty_due_to_hospital_acquired_condition.toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                }) : "N/A"}
                                {clinical_outcome_rating_data?.prev_year_revenue_penalty_due_to_hospital_acquired_condition ? performanceText(Math.round(clinical_outcome_rating_data?.prev_year_revenue_penalty_due_to_hospital_acquired_condition)) : ""}
                            </DataText>
                            <br />

                            <Stack direction="row" spacing={0.5}>
                                <HeadingText>Previous Year Revenue Loss Due to Readmission Penalty</HeadingText>
                                <Tooltip
                                    title="Previous year’s penalty on all Medicare payments for conditions acquired during the patient’s stay at the hospital"
                                    placeholder="top"
                                    arrow
                                >
                                    <InfoOutlinedIcon
                                        fontSize="small"
                                        style={{ width: '15px' }}
                                    ></InfoOutlinedIcon>
                                </Tooltip>
                            </Stack>
                            <DataText sx={{
                                color: clinical_outcome_score_data?.prev_year_revenue_penalty_due_to_excess_readmissions < 0 ? "#FF0707" : textColor(Math.round(clinical_outcome_rating_data?.prev_year_revenue_penalty_due_to_excess_readmissions))
                            }}>
                                {clinical_outcome_score_data?.prev_year_revenue_penalty_due_to_excess_readmissions || clinical_outcome_score_data?.prev_year_revenue_penalty_due_to_excess_readmissions === 0 ? clinical_outcome_score_data?.prev_year_revenue_penalty_due_to_excess_readmissions.toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                }) : "N/A"}
                                {clinical_outcome_rating_data?.prev_year_revenue_penalty_due_to_excess_readmissions ? performanceText(Math.round(clinical_outcome_rating_data?.prev_year_revenue_penalty_due_to_excess_readmissions)) : ""}
                            </DataText>

                        </Stack>
                        : null
                }
            </Grid>
            <Grid item lg={0.5} md={0.5}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: "center", height: "50vh" }}>
                    <Divider orientation="vertical" flexItem />
                </Box>
            </Grid>
            <Grid item lg={7} md={7} style={{ overflow: "scroll", height: "50vh" }}>
                <Factors module_name='CLINICAL_OUTCOMES' />
            </Grid>
        </Grid >
    </React.Fragment >
}
