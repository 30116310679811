import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Tab,
  TabProps,
  Tabs,
} from '@mui/material'
import React from 'react'
import { useLocation } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close'
import { styled } from '@mui/material/styles'
import { FiscalStewardship } from './FiscalStewardship'
import { IRatings } from '../../../../../models/IRatings'
import { Operations } from './Operations'
import { PatientExperience } from './PatientExperience'
import { ClinicalOutcome } from './ClinicalOutcome'
import { FacilityConditions } from './FacilityConditions'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: any
}

export interface PerformanceDialogProps {
  open: boolean
  setOpen: Function
  ratings: IRatings
  type: string
}

export const PerformanceDialog: React.FC<PerformanceDialogProps> = (
  props: PerformanceDialogProps
) => {
  const search = useLocation().search
  const definitive_id = new URLSearchParams(search).get('id')
  const [value, setValue] = React.useState(
    props.type === 'Fiscal Stewardship'
      ? 0
      : props.type === 'Operations'
        ? 1
        : props.type === 'Patient Experience'
          ? 2
          : props.type === 'Clinical Outcomes'
            ? 3
            : props.type === 'Facility Conditions'
              ? 4
              : 0
  )

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      props.setOpen(false)
    }
  }

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    ":root": {
      height: "600px",
      marginTop: "10%",
    },
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }))

  const TabStyle = styled(Tab)<TabProps>(({ theme }) => ({
    width: '100%',
    textTransform: 'capitalize',
    fontSize: '14px',
    fontWeight: '600',
    fontFamily: 'Open Sans',
    cursor: "pointer"
  }))

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={(event, reason) => handleClose(event, reason)}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        fullWidth
        maxWidth="lg"
      >
        <DialogContent style={{ paddingTop: "10px" }}>
          <Grid container>
            <Box
              sx={{
                width: '100%',
                height: '100%',
                bgcolor: 'background.paper',
              }}
            >
              <Tabs
                variant="fullWidth"
                value={value}
                onChange={handleChange}
                centered
                textColor="inherit"
                TabIndicatorProps={{
                  style: {
                    backgroundColor: '#4CAF50',
                    color: '#E8F5E9',
                  },
                }}
                style={{ color: '#4CAF50', marginRight: '30px', cursor: "pointer" }}
              >
                <TabStyle data-fiscal-id="Fiscal Stewardship" label="Fiscal Stewardship" style={value === 0 ? { color: '#4CAF50', fontWeight: 'bold' } : { color: 'GrayText' }} />
                <TabStyle data-operations-id="Operations" label="Operations" style={value === 1 ? { color: '#4CAF50', fontWeight: 'bold' } : { color: 'GrayText' }} />
                <TabStyle data-patient-exp-id="Patient Experience" label="Patient Experience" style={value === 2 ? { color: '#4CAF50', fontWeight: 'bold' } : { color: 'GrayText' }} />
                <TabStyle data-clinical-outcome-id="Clinical Outcomes" label="Clinical Outcomes" style={value === 3 ? { color: '#4CAF50', fontWeight: 'bold' } : { color: 'GrayText' }} />
                <TabStyle data-facility-conditions-id="Facility Conditions" label="Facility Conditions" style={value === 4 ? { color: '#4CAF50', fontWeight: 'bold' } : { color: 'GrayText' }} />
              </Tabs>
              <Divider />

              <TabPanel value={value} index={0}>
                <FiscalStewardship
                  overall_rating={props.ratings.fiscal_stewardship}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Operations
                  overall_rating={props.ratings.clinical_operations}
                />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <PatientExperience
                  overall_rating={props.ratings.experiential_design}
                />
              </TabPanel>
              <TabPanel value={value} index={3}>
                <ClinicalOutcome
                  overall_rating={props.ratings.clinical_outcomes}
                />
              </TabPanel>
              <TabPanel value={value} index={4}>
                <FacilityConditions
                  overall_rating={props.ratings.facility_conditions}
                />
              </TabPanel>
            </Box>

            <IconButton
              aria-label="close"
              onClick={() => props.setOpen(false)}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
                ':hover': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              <Divider
                orientation="vertical"
                sx={{ height: '20px', padding: '10px' }}
              />
              <CloseIcon sx={{ padding: '10px', cursor: "pointer" }} />
            </IconButton>
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  )
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      sx={{ height: '100%' }}
      {...other}
    >
      {value === index && <Box sx={{ p: 0, height: '100%' }}>{children}</Box>}
    </Box>
  )
}
