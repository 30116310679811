export const getHCAIaoa = (california_data, california_table_data) => {
    let dataSet: (string[])[] = []
    let dataFormatHeaders = ['Facility Name', 'City','Building Nbr', 'Building Name','Building Status', 'Building URL', 'Height (ft)','Stories','Building Code','Building Code Year', 'Year Completed', 'SPC Rating*', 'OSHPD NPC Rating']
    let dataSetFormat = ['DEFINITIVE_HOSPITAL_NAME', 'DEFINITIVE_HOSPITAL_NAME', 'BUILDING_NBR', 'BUILDING_NAME', 'BUILDING_STATUS', 'BUILDING_NAME', 'HEIGHT_FT','STORIES','BUILDING_CODE','BUILDING_CODE','YEAR_COMPLETED','SPC_RATING','OSHPD_RATING',]
    for (let i = 0; i < california_table_data!.length; i++) {
      let sett: string[] = []
      let j
      let countDHN = 0
      let countBC = 0
      for (j = 0; j < dataSetFormat.length; j++) {
        for (const key in california_table_data![i]) {
          if (key == dataSetFormat[j]) {
            if(key == 'DEFINITIVE_HOSPITAL_NAME'){
                if(countDHN == 0){
                    sett.push(california_table_data![i][key].split(' - ')[0])
                }
                else{
                    sett.push(california_table_data![i][key].split(' - ')[1])                    
                }
                countDHN++
                continue
            }
            if(key == 'BUILDING_CODE'){
                let temp = california_table_data![i][key].split(' ')
                if(countBC == 0){
                    sett.push([temp[1], temp[2]].join(' '))                    
                }else{
                    sett.push(temp[0])
                }
                countBC++
                continue
            }
            sett.push(california_table_data![i][key])
        }
        }
      }
      dataSet.push([...sett])
}
    dataSet.unshift(dataFormatHeaders)
    return dataSet
}