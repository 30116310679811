import {
  Box,
  Button,
  Grid,
  styled,
  Typography,
  Tooltip,
  Divider,
  Skeleton,
} from '@mui/material'
import React, { useEffect } from 'react'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Stack } from '@mui/system'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined'
import { useGetGeneralInfoSummaryDataQuery } from '../../../../../__generated__/graphql';
import { appInsights } from '../../../../../providers/appInsightProvider'

export const Summary_GeneralInfo: React.FC = () => {
  const search = useLocation().search
  let definitive_id = new URLSearchParams(search).get('id')
  const provider = new URLSearchParams(search).get('provider')
  let history = useHistory();
  let location = useLocation();
  let { tabtype } = useParams<{ tabtype: string }>();

  const {
    data: GeneralInfo,
    loading: loadingGeneralinfo,
    error: errorGeneralInfo,
  } = useGetGeneralInfoSummaryDataQuery({
    variables: {
      definitive_id,
    },
  })

  useEffect(() => {
    if (errorGeneralInfo instanceof Error) {
      appInsights.trackException({ exception: errorGeneralInfo, error: new Error("Failed to fetch Genral Info Summary Data.") })
    }
  }, [errorGeneralInfo])

  let GeneralInfo_data =
    GeneralInfo &&
      GeneralInfo.DEFINITIVEHEALTHCARE_VW_HOSPITAL_INFO &&
      GeneralInfo.DEFINITIVEHEALTHCARE_VW_HOSPITAL_INFO.length ?
      GeneralInfo.DEFINITIVEHEALTHCARE_VW_HOSPITAL_INFO[0]
      : null


  const GeneralInformation = styled(Box)(() => ({
    color: ' #22577A',
    fontWeight: '700',
    fontSize: '1.2rem',
    paddingTop: '28px',
    paddingLeft: '27px',
  }))

  const HeadingText = styled(Typography)(() => ({
    color: '#3E3E3E',
    fontWeight: '400px',
    fontSize: '12px',
    textTransform: 'inherit'
  }))

  const DataTextLink = styled(Typography)(() => ({
    color: 'rgb(0,0,238)',
    fontWeight: '600',
    fontSize: '14px',
  }))

  const DataText = styled(Typography)(() => ({
    color: '#3e3e3e',
    fontWeight: '600',
    fontSize: '14px',
    textTransform: 'inherit',
  }))

  const ViewMoreButton = styled(Button)(() => ({
    color: '#3E3E3E',
    fontWeight: '400px',
    position: 'absolute',
    right: 0,
    fontSize: '0.7rem',
    textTransform: 'inherit',
    backgroundColor: 'transparent',
    padding: '30px 15px',
  }))

  return (
    <Box
      style={{
        margin: '10px', // Temporary
        height: '75vh',
        border: '2px solid #E8F5E9',
        borderRadius: '10px',
      }}
    >
      <Grid container spacing={2} style={{ position: 'relative' }}>
        <Grid lg={6} md={6}>
          <GeneralInformation>General Information</GeneralInformation>
        </Grid>
        <Grid lg={6} md={6} sx={{
          textAlign: "end",
          margin: "auto",
          padding: "25px 15px 0px 15px"
        }}>
          {tabtype === "GeneralInfo" ? null :
            <Button data-general-info-view-more-id="Summary | General Information | View More"
              style={{
                color: '#3E3E3E',
                fontWeight: '400px',
                fontSize: '0.7rem',
                textTransform: 'inherit',
                backgroundColor: 'transparent'
              }}
              endIcon={<KeyboardDoubleArrowRightOutlinedIcon />}
              onClick={() => { history.push(`/client_insights/GeneralInfo${location.search}`) }}

            >
              View More
            </Button>}
        </Grid>
      </Grid>

      <Grid lg={12} md={12}>
        <Divider
          orientation="horizontal"
          flexItem
          style={{
            borderColor: '#E8F5E9',
            margin: '10px',
          }}
        />
      </Grid>

      {loadingGeneralinfo ?
        <Typography variant='h3' sx={{ margin: "10px" }}>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </Typography>
        : GeneralInfo_data ?
          <Grid>
            <Grid
              container
              style={{
                marginTop: '1.3vw',
                padding: '10px',
              }}
            >
              <Grid lg={6} md={6} style={{ padding: "5px" }}>
                <Stack spacing={0.5}>
                  <Stack direction="row" spacing={0.5}>
                    <HeadingText>Parent Health System</HeadingText>
                    <Tooltip
                      title="Parent organization or health system that owns and operates this individual healthcare facility"
                      placement="top"
                      arrow
                    >
                      <InfoOutlinedIcon
                        fontSize="small"
                        style={{ width: '15px', marginLeft: "10px" }}
                      ></InfoOutlinedIcon>
                    </Tooltip>
                  </Stack>
                  {GeneralInfo_data.parent_system_id ?
                    <Link data-parent-health-system-id="General Information | Parent Health System" style={{ color: "rgb(0, 0, 238)" }} to={`/client_insights/Summary?type=Health%20System&state=${GeneralInfo_data?.state}&provider=${GeneralInfo_data?.hospital_name}&id=${GeneralInfo_data?.parent_system_id}`} target="_blank" rel="noopener noreferrer">
                      <DataTextLink> {GeneralInfo_data.parent_health_system} </DataTextLink>
                    </Link>
                    :
                    <DataText>-</DataText>
                  }
                </Stack>
              </Grid>

              <Grid
                lg={6}
                md={6}
                item style={{ padding: "5px" }}
              >
                <Stack spacing={0.5}>
                  <Stack direction="row" spacing={0.5}>

                    <HeadingText>Type of Facility</HeadingText>
                    <Tooltip
                      title="Indicates which core facility type this healthcare facility is defined as"
                      placeholder="top"
                      arrow
                    >
                      <InfoOutlinedIcon
                        fontSize="small"
                        style={{ width: '15px', marginLeft: "10px" }}
                      ></InfoOutlinedIcon>
                    </Tooltip>
                  </Stack>
                  <DataText>{GeneralInfo_data.hospital_type}</DataText>
                </Stack>
              </Grid>
            </Grid>

            <Grid
              container
              style={{
                marginTop: '1.3vw',
                padding: '10px',
              }}
            >

              <Grid lg={6} md={6} style={{ padding: "5px" }}>
                <Stack spacing={0.5}>

                  <Stack direction="row" spacing={0.5}>
                    <HeadingText>Regional Health System</HeadingText>

                    <Tooltip
                      title="Local health system (may be the same as parent health system if it has not been acquired by a larger organization)"
                      placeholder="top"
                      arrow
                    >
                      <InfoOutlinedIcon
                        fontSize="small"
                        style={{ width: '15px', marginLeft: "10px" }}
                      ></InfoOutlinedIcon>
                    </Tooltip>
                  </Stack>
                  {GeneralInfo_data?.regional_health_system ?
                    GeneralInfo_data?.regional_system_id ?
                      <Link data-regional-health-system-id="General Information | Regional Health System" to={`/client_insights/Summary?type=Health%20System&state=${GeneralInfo_data.state}&provider=${GeneralInfo_data?.hospital_name}&id=${GeneralInfo_data?.regional_system_id}`}
                        style={{ color: "rgb(0, 0, 238)" }}
                        target="_blank" rel="noopener noreferrer">
                        <DataTextLink>{GeneralInfo_data?.regional_health_system ? GeneralInfo_data?.regional_health_system : "-"}</DataTextLink>
                      </Link>
                      :
                      <DataText>{GeneralInfo_data?.regional_health_system}</DataText>
                    :
                    <DataText>-</DataText>
                  }
                </Stack>
              </Grid>


              <Grid lg={6} md={6} style={{ padding: "5px" }}>
                <Stack spacing={0.5}>
                  <Stack direction="row" spacing={0.1}>
                    <HeadingText>Total Facility Square Footage</HeadingText>
                    <Tooltip
                      title="Total square footage across inpatient, outpatient, and non reimbursable cost center areas"
                      placeholder="top"
                      arrow
                    >
                      <InfoOutlinedIcon
                        fontSize="small"
                        style={{ width: '15px', marginLeft: "10px" }}
                      ></InfoOutlinedIcon>
                    </Tooltip>
                  </Stack>
                  <DataText>{GeneralInfo_data?.total_facility_square_footage ? parseInt(GeneralInfo_data?.total_facility_square_footage).toLocaleString('en-US') : "-"}</DataText>
                </Stack>
              </Grid>
            </Grid>

            <Grid
              container
              style={{
                marginTop: '1.3vw',
                padding: '10px',
              }}
            >
              <Grid lg={6} md={6} style={{ padding: "5px" }}>
                <Stack spacing={0.5}>
                  <Stack direction="row" spacing={0.5}>
                    <HeadingText>Ownership</HeadingText>

                    <Tooltip
                      title="Indicates which category of hospital ownership this healthcare facility falls under"
                      placeholder="top"
                      arrow
                    >
                      <InfoOutlinedIcon
                        fontSize="small"
                        style={{ width: '15px', marginLeft: "10px" }}
                      ></InfoOutlinedIcon>
                    </Tooltip>

                  </Stack>
                  <DataText>{GeneralInfo_data?.ownership ? GeneralInfo_data?.ownership : "-"}</DataText>
                </Stack>
              </Grid>


              <Grid lg={6} md={6} style={{ padding: "5px" }}>
                <Stack spacing={0.5}>
                  <Stack direction="row" spacing={0.1}>
                    <HeadingText>Number of Interns and Residents</HeadingText>

                    <Tooltip
                      title="Indicates number of interns and residents at this healthcare facility, if it is a teaching or academic medical center"
                      placeholder="top"
                      arrow
                    >
                      <InfoOutlinedIcon
                        fontSize="small"
                        style={{ width: '15px', marginLeft: "10px" }}
                      ></InfoOutlinedIcon>
                    </Tooltip>
                  </Stack>
                  <DataText>{GeneralInfo_data?.no_of_interns_and_residents ? parseInt(GeneralInfo_data?.no_of_interns_and_residents).toLocaleString('en-US') : "-"}</DataText>
                </Stack>
              </Grid>
            </Grid>

            <Grid
              container
              style={{
                marginTop: '1vw',
                padding: '10px',
              }}
            >
              <Grid lg={6} md={6} style={{ padding: "5px" }}>
                <Stack spacing={0.5}>
                  <Stack direction="row" spacing={0.1}>
                    <HeadingText>Number of Employees</HeadingText>

                    <Tooltip
                      title="Indicates number of people employed at this healthcare facility"
                      placeholder="top"
                      arrow
                    >
                      <InfoOutlinedIcon
                        fontSize="small"
                        style={{ width: '15px', marginLeft: "10px" }}
                      ></InfoOutlinedIcon>
                    </Tooltip>
                  </Stack>
                  <DataText>{GeneralInfo_data?.no_of_employees ? parseInt(GeneralInfo_data?.no_of_employees).toLocaleString('en-US') : "-"}</DataText>
                </Stack>
              </Grid>
            </Grid>
          </Grid >
          : null}
    </Box >
  )
}
