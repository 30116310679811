import { utils } from 'xlsx-js-style'
import { write } from 'xlsx-js-style'
import { writeFile } from 'xlsx-js-style'
import { getClientPerformanceaoa } from './GetClientPerformanceArrayOfArray';
import { getClientStakeHolderaoa } from './GetClientStakeHolderArrayOfArray';
import { getHCAIaoa } from './GetHCAIArrayOfArrays';
import { getClientGeneralInfoaoa } from './GetClientGeneralInfoArrayOfArray';

export const downloadExcelHospitalSystem = (value, GeneralInfo_SummaryData, GeneralInfo_data, Performance_SummaryData, clinical_outcome_rating_data, clinical_outcome_score_data, facility_conditions_rating_data, facility_conditions_score_data, ratings, fiscal_rating_data, fiscal_score_data, operations_rating_data, operations_score_data, patient_experience_rating_data, patient_experience_score_data, positive_fiscal, negative_fiscal, positive_clinical, negative_clinical, positive_patient_exp, negative_patient_exp, positive_clinical_outcomes, negative_clinical_outcomes, positive_facility_conditions, negative_facility_conditions, OverallPositiveArray, OverallNegativeArray, provider_dataExcel, stakeHoldersDataExcel, california_data, california_table_data, city, state, performaceDataExcel) => {

    let clientWorkBook = utils.book_new()
    let clientWorkBookTab = utils.book_new()
    const currentDate = new Date();
    const date = `${currentDate.getMonth() + 1}/${currentDate.getDate()}/${currentDate.getFullYear()}`;
    let ZipCode = provider_dataExcel?.address.split(' ')[provider_dataExcel?.address.split(' ').length - 1]
    let info = [['DPR Panorama Tool'], ['Confidential: Do Not Distribute Outside of DPR'], [' '], ['Date of Report', date], ['Client Name', provider_dataExcel.hospital_name], ['Address', provider_dataExcel.address?.replace(" , ", " ")], ['City', GeneralInfo_data[0]?.city], ['State', state], ['Zip Code', ZipCode], ['Phone Number', provider_dataExcel.phone]]
    let generalInfoExportWS = utils.aoa_to_sheet(info)
    let aoaGeneralInfo = getClientGeneralInfoaoa(GeneralInfo_SummaryData, GeneralInfo_data)
    generalInfoExportWS['!cols'] = [{ 'width': 40 }, { 'width': 40 }]
    utils.sheet_add_aoa(generalInfoExportWS, aoaGeneralInfo, { origin: { r: 12, c: 0 } })
    generalInfoExportWS['A13'].s = { font: { bold: true, sz: 12 } }
    generalInfoExportWS['B13'].s = { font: { bold: true, sz: 12 } }
    utils.book_append_sheet(clientWorkBook, generalInfoExportWS, 'Client General Info')
    if (value === 1) utils.book_append_sheet(clientWorkBookTab, generalInfoExportWS, 'Client General Info')

    positive_fiscal[0].length > 0 ? positive_fiscal[0][0] = 'Positive Factors' : positive_fiscal[0] = 'Positive Factors'
    positive_clinical[0].length > 0 ? positive_clinical[0][0] = 'Positive Factors' : positive_clinical[0] = 'Positive Factors'
    positive_patient_exp[0].length > 0 ? positive_patient_exp[0][0] = 'Positive Factors' : positive_patient_exp[0] = 'Positive Factors'
    positive_clinical_outcomes[0].length > 0 ? positive_clinical_outcomes[0][0] = 'Positive Factors' : positive_clinical_outcomes[0] = 'Positive Factors'
    positive_facility_conditions[0].length > 0 ? positive_facility_conditions[0][0] = 'Positive Factors' : positive_facility_conditions[0] = 'Positive Factors'

    negative_fiscal[0].length > 0 ? negative_fiscal[0][0] = 'Risk Factors' : negative_fiscal[0] = 'Risk Factors'
    negative_clinical[0].length > 0 ? negative_clinical[0][0] = 'Risk Factors' : negative_clinical[0] = 'Risk Factors'
    negative_patient_exp[0].length > 0 ? negative_patient_exp[0][0] = 'Risk Factors' : negative_patient_exp[0] = 'Risk Factors'
    negative_clinical_outcomes[0].length > 0 ? negative_clinical_outcomes[0][0] = 'Risk Factors' : negative_clinical_outcomes[0] = 'Risk Factors'
    negative_facility_conditions[0].length > 0 ? negative_facility_conditions[0][0] = 'Risk Factors' : negative_facility_conditions[0] = 'Risk Factors'

    let clientPerformanceWS = utils.aoa_to_sheet(info)
    clientPerformanceWS['!cols'] = [{ 'width': 80 }, { 'width': 40 }, { 'width': 30 }, { 'width': 30 }]
    let aoaPerformance = getClientPerformanceaoa(GeneralInfo_SummaryData, GeneralInfo_data, Performance_SummaryData, clinical_outcome_rating_data, clinical_outcome_score_data, facility_conditions_rating_data, facility_conditions_score_data, fiscal_rating_data, fiscal_score_data, operations_rating_data, operations_score_data, patient_experience_rating_data, patient_experience_score_data, positive_fiscal, negative_fiscal, positive_clinical, negative_clinical, positive_patient_exp, negative_patient_exp, positive_clinical_outcomes, negative_clinical_outcomes, positive_facility_conditions, negative_facility_conditions, OverallPositiveArray, OverallNegativeArray, performaceDataExcel)
    utils.sheet_add_aoa(clientPerformanceWS, aoaPerformance, { origin: { r: 12, c: 0 } })
    if (clientPerformanceWS['A13'] !== undefined) clientPerformanceWS['A13'].s = { font: { bold: true, sz: 12 } }
    if (clientPerformanceWS['B13'] !== undefined) clientPerformanceWS['B13'].s = { font: { bold: true, sz: 12 } }
    if (clientPerformanceWS['C13'] !== undefined) clientPerformanceWS['C13'].s = { font: { bold: true, sz: 12 } }
    if (clientPerformanceWS['D13'] !== undefined) clientPerformanceWS['D13'].s = { font: { bold: true, sz: 12 } }

    let x = 0
    let toBeBolded = ['Overall Performance Score', 'Fiscal Stewardship Score', 'Operations score', 'Clinical Outcomes Score', 'Patient Experience Score', 'Facility Conditions Score', '']
    for (let k = 0; k < toBeBolded.length; k++) {
        for (x = 1; x < 500; x++) {
            let b = 'A' + x
            if (clientPerformanceWS[b] !== undefined) {
                if (clientPerformanceWS[b].v === toBeBolded[k]) {
                    if (clientPerformanceWS['A' + x] !== undefined) clientPerformanceWS['A' + x].s = { font: { bold: true, sz: 12 } }
                    if (clientPerformanceWS['C' + x] !== undefined) clientPerformanceWS['C' + x].s = { font: { bold: true, sz: 12 } }
                    if (clientPerformanceWS['D' + x] !== undefined) clientPerformanceWS['D' + x].s = { font: { bold: true, sz: 12 } }
                }
            }
        }
    }
    utils.book_append_sheet(clientWorkBook, clientPerformanceWS, 'Client Performance')
    if (value === 2) utils.book_append_sheet(clientWorkBookTab, clientPerformanceWS, 'Client Performance')
    let clientStakeHoldersaoa = getClientStakeHolderaoa(stakeHoldersDataExcel)
    let clientStakeHoldersWS = utils.aoa_to_sheet(info)
    clientStakeHoldersWS['!cols'] = [{ 'width': 40 }]
    for (let i = 1; i <= 6; i++) clientStakeHoldersWS['!cols'].push({ 'width': 30 })

    utils.sheet_add_aoa(clientStakeHoldersWS, clientStakeHoldersaoa, { origin: { r: 12, c: 0 } })
    if (clientStakeHoldersWS['A13'] !== undefined) clientStakeHoldersWS['A13'].s = { font: { bold: true, sz: 12 } }
    if (clientStakeHoldersWS['B13'] !== undefined) clientStakeHoldersWS['B13'].s = { font: { bold: true, sz: 12 } }
    if (clientStakeHoldersWS['C13'] !== undefined) clientStakeHoldersWS['C13'].s = { font: { bold: true, sz: 12 } }
    if (clientStakeHoldersWS['D13'] !== undefined) clientStakeHoldersWS['D13'].s = { font: { bold: true, sz: 12 } }
    if (clientStakeHoldersWS['E13'] !== undefined) clientStakeHoldersWS['E13'].s = { font: { bold: true, sz: 12 } }
    if (clientStakeHoldersWS['F13'] !== undefined) clientStakeHoldersWS['F13'].s = { font: { bold: true, sz: 12 } }
    if (clientStakeHoldersWS['G13'] !== undefined) clientStakeHoldersWS['G13'].s = { font: { bold: true, sz: 12 } }
    utils.book_append_sheet(clientWorkBook, clientStakeHoldersWS, 'Client Stakeholder')
    if (value === 3) utils.book_append_sheet(clientWorkBookTab, clientStakeHoldersWS, 'Client Stakeholder')

    if (state === 'California') {
        let hcaiaoa = getHCAIaoa(california_data, california_table_data)
        let hcaiInfo = [['DPR Panorama Tool'], ['Confidential: Do Not Distribute Outside of DPR'], [' '], ['Date of Report', date], ['Client Name', provider_dataExcel.hospital_name], ['Address', provider_dataExcel.address], ['City', GeneralInfo_data[0]?.city], ['State', state], ['Zip Code', ZipCode], ['Phone Number', provider_dataExcel.phone], ['active_projects', california_data?.NO_OF_ACTIVE_PROJECTS], ['active_project_costs', california_data?.ACTIVE_PROJECT_COST], ['spc_1', california_data?.NO_OF_BLDGS_RATING_1], ['spc_2', california_data?.NO_OF_BLDGS_RATING_2], ['spc_3', california_data?.NO_OF_BLDGS_RATING_3], ['spc_4', california_data?.NO_OF_BLDGS_RATING_4], ['spc_5', california_data?.NO_OF_BLDGS_RATING_5]]
        let hcaiWS = utils.aoa_to_sheet(hcaiInfo)
        hcaiWS['!cols'] = [{ 'width': 40 }]
        for (let i = 1; i <= 12; i++) hcaiWS['!cols'].push({ 'width': 30 })
        utils.sheet_add_aoa(hcaiWS, hcaiaoa, { origin: { r: 19, c: 0 } })
        if (hcaiWS['A20'] !== undefined) hcaiWS['A20'].s = { font: { bold: true, sz: 12 } }
        if (hcaiWS['B20'] !== undefined) hcaiWS['B20'].s = { font: { bold: true, sz: 12 } }
        if (hcaiWS['C20'] !== undefined) hcaiWS['C20'].s = { font: { bold: true, sz: 12 } }
        if (hcaiWS['D20'] !== undefined) hcaiWS['D20'].s = { font: { bold: true, sz: 12 } }
        if (hcaiWS['E20'] !== undefined) hcaiWS['E20'].s = { font: { bold: true, sz: 12 } }
        if (hcaiWS['F20'] !== undefined) hcaiWS['F20'].s = { font: { bold: true, sz: 12 } }
        if (hcaiWS['G20'] !== undefined) hcaiWS['G20'].s = { font: { bold: true, sz: 12 } }
        if (hcaiWS['H20'] !== undefined) hcaiWS['H20'].s = { font: { bold: true, sz: 12 } }
        if (hcaiWS['I20'] !== undefined) hcaiWS['I20'].s = { font: { bold: true, sz: 12 } }
        if (hcaiWS['J20'] !== undefined) hcaiWS['J20'].s = { font: { bold: true, sz: 12 } }
        if (hcaiWS['K20'] !== undefined) hcaiWS['K20'].s = { font: { bold: true, sz: 12 } }
        if (hcaiWS['L20'] !== undefined) hcaiWS['L20'].s = { font: { bold: true, sz: 12 } }
        if (hcaiWS['M20'] !== undefined) hcaiWS['M20'].s = { font: { bold: true, sz: 12 } }
        utils.book_append_sheet(clientWorkBook, hcaiWS, 'HCAI')
        if (value === 4) utils.book_append_sheet(clientWorkBookTab, hcaiWS, 'HCAI')

    }

    let buffer = write(value === -1 || value === 0 ? clientWorkBook : clientWorkBookTab, { bookType: 'xlsx', type: 'buffer' })
    write(value === -1 || value === 0 ? clientWorkBook : clientWorkBookTab, { bookType: 'xlsx', type: 'binary' })
    // writeFile(value === -1 || value === 0 ? clientWorkBook : clientWorkBookTab, `${provider_dataExcel.hospital_name}.xlsx`)
    writeFile(value === -1 || value === 0 ? clientWorkBook : clientWorkBookTab, `Healthcare Panorama - Hospital Insights.xlsx`)
}



