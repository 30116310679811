import React, { useEffect, useState } from 'react'
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  PDFViewer,
  Image,
  pdf,
} from '@react-pdf/renderer'
import dprlogo from '../../../Assets/dprlogoImage.png'
import panaromalogo from '../../../Assets/PanoramaImage.png'
import { alpha, IconButton, MenuItem, Skeleton } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { saveAs } from 'file-saver'
import { useSnackbar, VariantType } from 'notistack'
import { renderTitleHeader } from '../../CommonComponents/RenderTitleHeader/RenderTitleHeader'
import CloseIcon from '@mui/icons-material/Close'
import ReactDOM from 'react-dom'
import DialogAlert from '../../CommonComponents/DialogBox/dialogAlert'
import { SSConst } from '../../../config/config'
import { ExportStatus, useExportData } from '../../../customHooks/useExportData'

interface CaliforniaHCAIPDFProps {
  getDataFunction: any
}

export const CaliforniaHCAIPDF: React.FC<CaliforniaHCAIPDFProps> = ({ getDataFunction }: CaliforniaHCAIPDFProps) =>
// props: CaliforniaHCAIPDFProps
{
  let tableData = {
    column: [
      'BLDG Name',
      'BLDG Number',
      'BLDG Status',
      'Height',
      'Stories',
      'Year Constructed',
      'BLDG Code',
    ],
    column1: [
      'BLDG Name',
      'BLDG Number',
      'BLDG Status',
      'Height',
      'Stories',
      'Year Constructed',
      'BLDG Code',
      'Seismic Rating',
      'OSHPD NPC Rating',
    ],
    column2: [
      'BUILDING_NAME',
      'BUILDING_NBR',
      'BUILDING_STATUS',
      'HEIGHT_FT',
      'STORIES',
      'YEAR_COMPLETED',
      'BUILDING_CODE',
      'SPC_RATING',
      'OSHPD_RATING',
    ],
  }
  let firstTime = 0;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const ShowSnackBar = (
    message,
    variant: VariantType,
    duration,
    key?: string
  ) => {
    enqueueSnackbar(<div>{message}</div>, {
      variant: variant,
      persist: false,
      anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
      style: {
        flexDirection: 'row',
        maxWidth: '50px',
        marginLeft: '-150px',
      },
      autoHideDuration: duration,
      key: key,
      action: (key) => (
        <IconButton
          color="inherit"
          aria-label="dismiss"
          onClick={() => closeSnackbar(key)}
        >
          <CloseIcon />
        </IconButton>
      ),
    })
    return key
  }

  const search = useLocation().search
  const provider = new URLSearchParams(search).get('provider')
  const date = Date().split(' ')
  const monthConverter = (month: any) => {
    const monthArray = [
      ['Jan', '01'],
      ['Feb', '02'],
      ['Mar', '03'],
      ['Apr', '04'],
      ['May', '05'],
      ['Jun', '06'],
      ['Jul', '07'],
      ['Aug', '08'],
      ['Sep', '09'],
      ['Oct', '10'],
      ['Nov', '11'],
      ['Dec', '12'],
    ]
    for (let i = 0; i < monthArray.length; i++) {
      if (monthArray[i][0] == month) return monthArray[i][1]
    }
  }
  const formatedDateTime = `${monthConverter(date[1])}/${date[2]}/${date[3]}`
  const pdfStyle = StyleSheet.create({
    body: {
      paddingTop: 15,
      paddingBottom: 45,
      paddingHorizontal: 15,
    },

    footer: {
      flexDirection: 'column',
      bottom: 10,
      left: 10,
      position: 'absolute',
    },

    headerView: {
      display: 'flex',
      flexDirection: 'row',
      borderTop: '1px solid #EEE',
      paddingTop: 8,
      paddingBottom: 8,
      textAlign: 'center',
      fontSize: '6.01px',
      fontWeight: 600,
      color: '#3E3E3E',
      backgroundColor: '#F3F3F3',
      borderRadius: '5px',
    },
    rowView: {
      display: 'flex',
      flexDirection: 'row',
      borderTop: '1px solid #EEE',
      paddingTop: 8,
      paddingBottom: 8,
      textAlign: 'center',
      fontSize: '6px',
      color: '#3E3E3E',
    },
  })

  const MyDocument = (california_data, california_table_data) => (
    <Document>
      <Page size="A4" style={pdfStyle.body}>
        <View fixed wrap>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              paddingBottom: '10px',
              paddingRight: '20px',
            }}
          >
            <Image
              src={dprlogo}
              style={{
                width: '74px',
                height: '45px',
                padding: '5px',
              }}
            ></Image>

            <View
              style={{
                borderRight: '1px solid #CCCCCC',
                height: '30px',
                marginTop: '8px',
                marginLeft: '10px',
              }}
            ></View>

            <Image
              src={panaromalogo}
              style={{
                width: '100px',
                height: '30px',
                marginLeft: '20px',
                marginTop: '8px',
                padding: 5,
              }}
            ></Image>

            <Text
              style={{
                fontSize: '20px',
                color: '#000000',
                marginLeft: 'auto',
                marginTop: '15px',
              }}
            >
              CLIENT INSIGHTS
            </Text>
          </View>

          <View
            style={{
              borderBottom: '2px solid #4CAF50',
              width: '100%',
              marginBottom: '20px',
              marginTop: 10,
            }}
          ></View>
        </View>
        <View wrap style={{ flexDirection: 'row' }}>
          <Text
            style={{
              fontSize: '20px',
              fontWeight: 400,
              color: '#000000',
              marginRight: 'auto',
              marginTop: '-8px',
            }}
          >
            CALIFORNIA HCAI
          </Text>
          <View
            style={{
              borderBottom: '1px solid black',
              width: '65%',
              marginBottom: '10px',
              marginTop: -8,
            }}
          ></View>
        </View>
        <View
          wrap
          style={{
            border: '1px solid #E8F5E9',
            backgroundColor: '#FAFAFA',
            borderRadius: '9px',
            marginTop: '20px',
            flexDirection: 'column',
            width: '568px',
            height: '80px',
          }}
        >
          <View
            style={{ flexDirection: 'row', marginTop: '5px', height: '50%' }}
          >
            <View
              style={{
                flexDirection: 'column',
                marginTop: '5px',
                marginLeft: '10px',
                width: '28%',
              }}
            >
              <Text
                style={{
                  fontSize: '7.26px',
                  fontWeight: 400,
                  color: alpha('#3E3E3E', 0.8),
                }}
              >
                Campus Name
              </Text>
              <Text
                style={{ fontSize: '8px', fontWeight: 700, marginTop: '4px' }}
              >
                {california_data?.FACILITY_DESC
                  ? california_data?.FACILITY_DESC
                  : '-'}
              </Text>
            </View>
            <View
              style={{
                flexDirection: 'column',
                marginTop: '5px',
                marginLeft: '15px',
                width: '20%',
              }}
            >
              <Text
                style={{
                  fontSize: '7.26px',
                  fontWeight: 400,
                  color: alpha('#3E3E3E', 0.8),
                }}
              >
                Active Projects
              </Text>
              <Text
                style={{ fontSize: '8px', fontWeight: 700, marginTop: '4px' }}
              >
                {california_data?.NO_OF_ACTIVE_PROJECTS
                  ? california_data?.NO_OF_ACTIVE_PROJECTS
                  : '-'}
              </Text>
            </View>
            <View
              style={{
                flexDirection: 'column',
                marginTop: '5px',
                marginLeft: '15px',
                width: '28%',
              }}
            >
              <Text
                style={{
                  fontSize: '7.26px',
                  fontWeight: 400,
                  color: alpha('#3E3E3E', 0.8),
                }}
              >
                Overall HC Contruction Project Cost
              </Text>
              <Text
                style={{ fontSize: '8px', fontWeight: 700, marginTop: '4px' }}
              >
                {california_data?.ACTIVE_PROJECT_COST
                  ? california_data?.ACTIVE_PROJECT_COST.toLocaleString(
                    'en-US',
                    {
                      style: 'currency',
                      currency: 'USD',
                    }
                  )
                  : '-'}
              </Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: 'row',
              height: '50%',
              marginBottom: '10px',
            }}
          >
            <View
              style={{
                flexDirection: 'column',
                marginTop: '12px',
                marginLeft: '10px',
              }}
            >
              <Text
                style={{
                  fontSize: '7.26px',
                  fontWeight: 400,
                  color: alpha('#3E3E3E', 0.8),
                }}
              >
                Count of Buildings by Sesmic Ratings
              </Text>
              <View style={{ flexDirection: 'row', marginTop: '4px' }}>
                <Text style={{ fontSize: 8, fontWeight: 700 }}>
                  Rating 1: {california_data?.NO_OF_BLDGS_RATING_1} bldgs |
                </Text>
                <Text
                  style={{ fontSize: 8, fontWeight: 700, marginLeft: '5px' }}
                >
                  Rating 2: {california_data?.NO_OF_BLDGS_RATING_2} bldgs |
                </Text>
                <Text
                  style={{ fontSize: 8, fontWeight: 700, marginLeft: '5px' }}
                >
                  Rating 3: {california_data?.NO_OF_BLDGS_RATING_3} bldgs |
                </Text>
                <Text
                  style={{ fontSize: 8, fontWeight: 700, marginLeft: '5px' }}
                >
                  Rating 4: {california_data?.NO_OF_BLDGS_RATING_4} bldgs |
                </Text>
                <Text
                  style={{ fontSize: 8, fontWeight: 700, marginLeft: '5px' }}
                >
                  Rating 5: {california_data?.NO_OF_BLDGS_RATING_5} bldgs{' '}
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View
          style={{
            marginTop: '20px',
          }}
        >
          <View>
            <Text
              style={{
                fontSize: '8px',
                marginBottom: '10px',
                fontStyle: 'italic',
                marginLeft: '5px',
              }}
            >
              Last Updated :{' '}
              {california_table_data && california_table_data.length
                ? california_table_data[0].last_updated_on
                : '-'}
            </Text>
          </View>
          <View style={pdfStyle.headerView} fixed>
            {tableData['column1'].map((c) => (
              <Text
                style={{
                  width: `${100 / tableData['column1'].length}%`,
                  height: `${100 / california_table_data.length}%`,
                  textAlign: 'left',
                  marginLeft: '10px',
                }}
              >
                {c}
              </Text>
            ))}
          </View>
          {california_table_data.map((rowData) => (
            <>
              <View style={pdfStyle.rowView} wrap={false}>
                {tableData['column2'].map((c) => (
                  <Text
                    wrap={false}
                    style={{
                      width: `${100 / tableData['column2'].length}%`,
                      height: `${(100 / california_table_data.length) * 5}%`,
                      textAlign: 'left',
                      paddingLeft:
                        c == 'STORIES' ||
                          c == 'YEAR_COMPLETED' ||
                          c == 'OSHPD_RATING' ||
                          c == 'SPC_RATING'
                          ? '15px'
                          : '10px',
                    }}
                  >
                    {rowData[c] ? rowData[c] : '-'}
                  </Text>
                ))}
              </View>
            </>
          ))}
        </View>

        <View wrap fixed style={pdfStyle.footer}>
          <View
            style={{
              border: '1px solid #D9D9D9',
              marginTop: '10px',
              width: '520px',
              height: '1px',
              left: '25px',
            }}
          ></View>
          <View
            style={{ flexDirection: 'row', fontSize: 8, marginTop: '10px' }}
          >
            <Text style={{ marginLeft: '30px', fontSize: 10 }}>
              Export Date : {formatedDateTime}
            </Text>
            <Text
              style={{
                fontWeight: 800,
                marginLeft: '70px',
                fontSize: 10,
              }}
            >
              Confidential: Do Not Distribute Outside of DPR
            </Text>
            <Text
              style={{ marginLeft: '110px', fontSize: 10 }}
              render={({ pageNumber }) => `Page ${pageNumber}`}
            />
          </View>
        </View>
      </Page>
    </Document>
  )

  return (
    <MenuItem
      onClick={() => {
        sessionStorage.setItem(
          SSConst.EXPORT_STATUS,
          'IN_PROGRESS'
        )
        ShowSnackBar(
          'Generating Export File, Please wait !!',
          'info',
          5000,
          'close'
        )
        getDataFunction().then(() => {
          ReactDOM.render(<DialogAlert />, document.createElement('div'))

          const ExportedData = JSON.parse(
            sessionStorage.getItem(SSConst.EXPORT_DATA)!
          )
          const california_data =
            ExportedData?.getCaliforniaInfo && ExportedData?.getCaliforniaInfo.length
              ? ExportedData?.getCaliforniaInfo[0]
              : null

          const california_table_data = ExportedData?.getCaliforniaTableData
            ? ExportedData?.getCaliforniaTableData
            : []

          const pdfBlob = pdf(MyDocument(california_data, california_table_data)).toBlob()
          pdfBlob.then((data) => {
            const blobObj = new Blob([data], { type: 'application/pdf' })
            saveAs(blobObj, 'Healthcare Panorama - Hospital Insights')
          })
        })
      }}
    >
      Current Tab
    </MenuItem>
  )
}
