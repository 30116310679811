import { BrowserRouter, Route, Switch } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { InteractionType } from '@azure/msal-browser'
import {
  loginRequest,
  graphLoginRequest,
  GetUserPhoto,
} from './providers/authProvider'
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useAccount,
  useIsAuthenticated,
  useMsal,
  useMsalAuthentication,
} from '@azure/msal-react'
import { Home } from './components/Pages/Home/Home'
import { Main } from './components/Pages/ClientInsights/Main'
import { Header } from './components/Pages/Home/Header'
import { Divider, Grid } from '@mui/material'
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloProvider,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import config from './config/config'
import { useAccessToken } from './customHooks/useAccessToken'
import { Loader } from './components/CommonComponents/Loader/Loader'
import AdditionalResources from './components/Header/OtherResources/AdditionalResources'
import { MarketInsightsTab } from './components/Pages/MarketInsights/MarketInsightsTab'
import { About } from './components/Header/About/About'
import { SnackbarProvider } from 'notistack'
import { appInsights } from './providers/appInsightProvider'
import axios from 'axios'
import ProtectedRoute from './providers/ProtectedRoute'
import { RequestAccess } from './components/Pages/RequestAccess/RequestAccess'
import { useIsValidUser } from './customHooks/useIsValidUser'

const App: React.FC = () => {
  const { accounts, instance } = useMsal()
  const account = useAccount(accounts[0] || {})
  const isAuthenticated = useIsAuthenticated()
  const [isAccessTokenAvailable, setIsAccessTokenAvailable] = useState<Boolean>(
    false
  )

  // const [isValidUser, setIsValidUser] = useState<Boolean>(
  //   false
  // )

  const accesss_token = useAccessToken()
  // const is_valid_user = useIsValidUser()

  const { login, error } = useMsalAuthentication(
    InteractionType.Redirect,
    loginRequest
  )

  useEffect(() => {
    if (accesss_token && accesss_token !== '') {
      setIsAccessTokenAvailable(true)
    } else {
      setIsAccessTokenAvailable(false)
    }
  }, [accesss_token])

  // useEffect(() => {
  //   if (is_valid_user) {
  //     setIsValidUser(true)
  //   } else {
  //     setIsValidUser(false)
  //   }
  // }, [is_valid_user])

  useEffect(() => {
    if (account) {
      RequestProfileData()
      // setInterval(() => RequestProfileData(), 2580000)
    }
  }, [account])

  async function getUserPhoto(accessToken) {
    const photo = await GetUserPhoto(accessToken)
    sessionStorage.setItem('user_photo', photo ?? '')
  }

  async function RequestProfileData() {
    if (account) {
      try {
        setTimeout(() => {
          if (!isAuthenticated) {
            const currentPath =
              encodeURIComponent(window.location.pathname) +
              encodeURIComponent(window.location.search)
            window.location.href = `/session-expired.html?redirectUri=${currentPath}`
          } else {
            RequestProfileData()
          }
        }, 3000000)

        appInsights.setAuthenticatedUserContext(
          account?.username?.replace(/[,;=| ]+/g, '_'),
          account?.homeAccountId?.replace(/[,;=| ]+/g, '_'),
          true
        )

        const res = await instance.acquireTokenSilent({
          ...loginRequest,
          account,
        })
        const resGraph = await instance.acquireTokenSilent({
          ...graphLoginRequest,
          account,
        })

        try {
          let group_data = await axios({
            url: `https://graph.microsoft.com/v1.0/me/memberOf?$filter=id eq '12bfa6e2-f960-40c3-a051-f2d1041471db'`,
            method: 'GET',
            headers: {
              'Authorization': 'Bearer ' + resGraph.accessToken
            }
          })
          sessionStorage.setItem('IS_VALID_USER', '1');
        }
        catch (err) {
          sessionStorage.setItem('IS_VALID_USER', '0');
        }


        getUserPhoto(resGraph.accessToken)
        sessionStorage.setItem('EXPORT_STATUS', 'NOT_STARTED')
        sessionStorage.setItem('user_name', account?.name ?? '')
        sessionStorage.setItem('initials_name', account?.name ?? '')
        sessionStorage.setItem('user_email', account?.username ?? '')
        sessionStorage.setItem('user_accessToken', res.accessToken)
        sessionStorage.setItem('user_graphaccessToken', resGraph.accessToken)
      } catch (err) {
        sessionStorage.removeItem('user_accessToken')
        sessionStorage.removeItem('alert')
        sessionStorage.removeItem('user_graphaccessToken')
        sessionStorage.removeItem('IS_VALID_USER')

        const currentPath =
          encodeURIComponent(window.location.pathname) +
          encodeURIComponent(window.location.search)
        window.location.href = `/session-expired.html?redirectUri=${currentPath}`
      }
    }
  }

  const httpLink = createHttpLink({
    uri: ``,
  })

  const authLink = setContext((_, { headers, uri }) => {
    // get the authentication token from local storage if it exists
    const token = sessionStorage.getItem('user_accessToken')
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
      uri: `${config.hasura_url}/v1/graphql`,
    }
  })

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        pollInterval: 0
      },
      query: {
        pollInterval: 0
      }
    }
  })

  return (
    <SnackbarProvider maxSnack={10} preventDuplicate hideIconVariant={true}>
      <React.Fragment>
        <AuthenticatedTemplate>
          {isAccessTokenAvailable ?
            (<ApolloProvider client={client}>
              <BrowserRouter>
                <Header />
                <Divider />
                <Switch>
                  <Route exact path="/">
                    <ProtectedRoute>
                      <Home />
                    </ProtectedRoute>
                  </Route>
                  <Route exact path="/client_insights/:tabtype">
                    <ProtectedRoute>
                      <Main />
                    </ProtectedRoute>
                  </Route>
                  <Route exact path="/market_insights/:tabtype">
                    <ProtectedRoute>
                      <MarketInsightsTab />
                    </ProtectedRoute>
                  </Route>
                  <Route exact path="/about/about_panorama">
                    <ProtectedRoute>
                      <About />
                    </ProtectedRoute>
                  </Route>
                  <Route exact path="/additional_resources">
                    <ProtectedRoute>
                      <AdditionalResources />
                    </ProtectedRoute>
                  </Route>
                  <Route exact path="/request_access">
                    <RequestAccess />
                  </Route>
                </Switch>
              </BrowserRouter>
            </ApolloProvider>
            ) : (
              <Loader message="Please wait loading the data...." />
            )}
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Loader message="Please wait while we authenticate you...." />
        </UnauthenticatedTemplate>
      </React.Fragment>
    </SnackbarProvider>
  )
}
export default App
