export const getExcelMarketCountyHealth = (exportData: any, countiesHealth: any) => {

    let dataSet: string[][] = [];

    const countyData = countiesHealth?.udf_county_data_export;

    const dataFormatHeaders = ['State', '# of ranked counties', 'County', 'Health Outcomes county rank', 'Poor or Fair health',
        'Diabetes Prevalence',
        'Children in poverty', 'Adult obesity', 'Primary care physicians', 'Mental health providers', 'Severe housing problems', 'Air pollution - particulate matter', 'Poor physical health days', 'Poor mental health days', 'Adult smoking', 'Physical inactivity', 'Access to exercise opportunities', 'Excessive drinking', 'High school completion', 'Some college']
    const dataFormat = ['state', 'no_of_ranked_counties', 'county', 'health_outcomes_rank', 'fair_poor_health_pct', 'diabetes_prevelance_pct', 'pct_children_in_poverty', 'pct_adults_w_obesity', 'primary_care_physicians_ratio', 'mental_health_providers_ratio', 'severe_housing_problems_pct_households', 'air_pollution_avg_daily_pm25', 'poor_physical_health_days', 'poor_mental_health_days', 'adult_smoking_pct', 'physical_inactivity_pct', 'access_to_exercise_opps_pct', 'excessive_drinking_pct', 'high_school_completion_pct', 'some_college_pct'];
    for (let i = 0; i < countyData.length; i++) {
        let sett: string[] = [];
        for (const key in countyData[i]) {
            for (let j = 0; j < dataFormat.length; j++) {
                if (key === dataFormat[j])
                    sett.push(countyData[i][key]);
            }
        }
        dataSet.push(sett);
    }

    dataSet.unshift(dataFormatHeaders)

    return dataSet;
}