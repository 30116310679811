import { Box, Grid, Icon, styled } from "@mui/material";
import React, { useState } from "react";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropUp';


let initializer: any
const QuestionContext = React.createContext(initializer)

const StylesInnerBox = styled(Box)(() => ({
    marginLeft: '10px', paddingRight: '15px', cursor: "pointer"
}))

const StylesOutterBox = styled(Box)(() => ({
    border: '2px solid #E8F5E9', borderRadius: '15px', width: '85vw', backgroundColor: '#FAFAFA', marginLeft: '45px', marginRight: '45px', marginBottom: '15px'
}))

const GridItemStyle = styled(Grid)(() => ({
    margin: '15px', color: '#22577A', fontWeight: 600
}))

export default function FAQsRenderer({ children, ...props }) {

    return (
        <div {...props}>
            <div>{children}</div>
        </div>
    )
}

FAQsRenderer.Content = function FAQsRendererContent({ children, ...props }) {

    const [open, setOpen] = useState(false);

    return (
        <QuestionContext.Provider value={{ open, setOpen }}>
            <StylesOutterBox onClick={() => setOpen((open: boolean) => !open)} {...props}> {children}</StylesOutterBox>
        </QuestionContext.Provider>
    );

};

FAQsRenderer.Question = function FAQsRendererQuestion({ children, ...props }) {

    const { open } = React.useContext(QuestionContext);

    return (
        <StylesInnerBox>
            <Grid container rowSpacing={0} justifyContent={'space-between'} alignItems={'center'} {...props}>
                <GridItemStyle item>{children}</GridItemStyle>
                <Grid item>
                    <Icon>
                        {open ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                    </Icon>
                </Grid>
            </Grid>
        </StylesInnerBox>
    )
}

FAQsRenderer.Answer = function FAQsRendererAnswer({ children, ...props }) {

    const { open } = React.useContext(QuestionContext);

    return open ? <StylesInnerBox {...props}>
        {children}
    </StylesInnerBox> : null

}

