import React, { useEffect, useState } from 'react'
import {
  PDFDownloadLink,
  PDFViewer,
  Document,
  Page,
  StyleSheet,
  View,
  Image,
  Text,
  Svg,
  Path,
  pdf,
} from '@react-pdf/renderer'
import dprlogo from '../../../Assets/dprlogoImage.png'
import panaromalogo from '../../../Assets/PanoramaImage.png'
import { useLocation } from 'react-router'
import { alpha } from '@mui/system'
import { saveAs } from 'file-saver'
import { useSnackbar, VariantType } from 'notistack'
import { Button, IconButton, MenuItem, Skeleton } from '@mui/material'
import { ExportStatus, useExportData } from '../../../customHooks/useExportData'
import { renderTitleHeader } from '../../CommonComponents/RenderTitleHeader/RenderTitleHeader'
import CloseIcon from '@mui/icons-material/Close'
import { monthArray } from '../../../config/Constant'
import ReactDOM from 'react-dom'
import DialogAlert from '../../CommonComponents/DialogBox/dialogAlert'

interface CountyPDFProps {
  getPdfData: any
}
export const CountyPDF: React.FC<CountyPDFProps> = ({ getPdfData }: CountyPDFProps) => {

  const date = Date().split(' ')
  const monthConverter = (month: any) => {
    for (let i = 0; i < monthArray.length; i++) {
      if (monthArray[i][0] == month) return monthArray[i][1]
    }
  }

  const formatedDateTime = `${monthConverter(date[1])}/${date[2]}/${date[3]}`

  const getCountyDataForTable = (county_data) => {
    let rows = [
      'Poor or Fair Health',
      'Diabetes Prevalence',
      'Children in Poverty',
      'Adult Obesity',
      'Primary Care Physicians',
      'Mental Health Providers',
      'Severe Housing Problems',
      'Air Pollution – Particulate Matter',
    ]
    let row_map = [
      'fair_poor_health_pct',
      'diabetes_prevalence_pct',
      'pct_children_in_poverty',
      'pct_adults_w_obesity',
      'primary_care_physicians_ratio',
      'mental_health_providers_ratio',
      'severe_housing_problems_pct_households',
      'air_pollution_avg_daily_pm25',
    ]
    let table_data: any = []
    let san_mateo_data: any = null
    let california_data: any = null
    let top_decile_data: any = null
    let county_name: string = ''
    let state_name: string = ''
    if (county_data && county_data.length) {
      for (let i = 0; i < county_data.length; i++) {
        const element = county_data[i]
        if (element.state === 'NATIONAL') {
          top_decile_data = element
        }
        if (element.county) {
          san_mateo_data = element
          county_name = element.county
        }
        if (element.county === null && element.state !== 'NATIONAL') {
          california_data = element
        }
        if (element.state && element.state !== 'NATIONAL') {
          state_name = element.state
        }
      }

      for (let index = 0; index < rows.length; index++) {
        const ele = rows[index]
        let percent_check_index = [4, 5, 7]
        table_data.push({
          type: ele,
          county: county_name,
          state: state_name,
          san_mateo:
            percent_check_index.indexOf(index) === -1 && san_mateo_data !== null
              ? san_mateo_data[row_map[index]] + '%'
              : san_mateo_data === null
                ? null
                : san_mateo_data[row_map[index]],
          california:
            percent_check_index.indexOf(index) === -1 &&
              california_data !== null
              ? california_data[row_map[index]] + '%'
              : california_data === null
                ? null
                : california_data[row_map[index]],
          top_decile:
            percent_check_index.indexOf(index) === -1 &&
              top_decile_data !== null
              ? top_decile_data[row_map[index]] + '%'
              : top_decile_data === null
                ? null
                : top_decile_data[row_map[index]],
          higher:
            san_mateo_data !== null &&
              california_data !== null &&
              parseInt(san_mateo_data[row_map[index]]) ===
              parseInt(california_data[row_map[index]])
              ? ''
              : san_mateo_data !== null &&
                california_data !== null &&
                parseInt(san_mateo_data[row_map[index]]) >
                parseInt(california_data[row_map[index]])
                ? 'san_mateo'
                : 'california',
        })
      }
    }
    return table_data
  }



  const pdfStyle = StyleSheet.create({
    body: {
      paddingTop: 15,
      paddingBottom: 45,
      paddingHorizontal: 15,
    },

    headerView: {
      display: 'flex',
      flexDirection: 'row',
      borderTop: '1px solid #EEE',
      paddingTop: 8,
      paddingBottom: 8,
      textAlign: 'center',
      fontSize: '8px',
      color: '#3E3E3E',
      backgroundColor: '#F3F3F3',
      borderRadius: '5px',
    },
    rowView: {
      display: 'flex',
      flexDirection: 'row',
      borderTop: '1px solid #EEE',
      paddingTop: 8,
      paddingBottom: 8,
      textAlign: 'center',
      fontSize: '6px',
      color: '#3E3E3E',
    },
    footer: {
      flexDirection: 'column',
      position: 'absolute',
      bottom: 10,
      left: 10,
    },
  })

  const MyDocument = (CountyAreaMap, tableData, countyDataForTable) => (
    <Document>
      <Page size="A4" style={pdfStyle.body}>
        <View wrap fixed>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              paddingBottom: '10px',
            }}
          >
            <Image
              src={dprlogo}
              style={{
                width: '74px',
                height: '45px',
                padding: '5px',
              }}
            ></Image>

            <View
              style={{
                borderRight: '1px solid #CCCCCC',
                height: '30px',
                marginTop: '8px',
                marginLeft: '10px',
              }}
            ></View>

            <Image
              src={panaromalogo}
              style={{
                width: '100px',
                height: '30px',
                marginLeft: '20px',
                marginTop: '8px',
                padding: 5,
              }}
            ></Image>

            <Text
              style={{
                fontSize: '20px',
                color: '#000000',
                marginLeft: 'auto',
                marginTop: '15px',
              }}
            >
              MARKET INSIGHTS
            </Text>
          </View>

          <View
            style={{
              borderBottom: '2px solid #4CAF50',
              width: '100%',
              marginBottom: '20px',
              marginTop: 10,
            }}
          ></View>

          <View style={{ flexDirection: 'row', marginTop: '5px' }}>
            <Text
              style={{
                fontSize: '20px',
                color: alpha('#000000', 0.8),
                marginRight: 'auto',
                marginTop: '5px',
              }}
            >
              COUNTY DATA
            </Text>
            <View
              style={{
                borderBottom: '1.2px solid black',
                width: '74%',
                marginBottom: '10px',
                marginTop: 10,
              }}
            ></View>
          </View>

          <View
            style={{
              marginTop: '12px',
              marginBottom: '5px',
            }}
          >
            <Image
              style={{
                width: '550px',
                height: '330px',
              }}
              src={CountyAreaMap}
            ></Image>
          </View>

          <View style={{ marginTop: '20px' }}>
            <View style={pdfStyle.headerView} fixed>
              {tableData['column1'] && tableData['column1'].length ?
                tableData['column1'].map((c) => (
                  <Text
                    style={{
                      width: `${100 / tableData['column1'].length}%`,
                      textAlign: 'center',
                      marginLeft: '10px',
                    }}
                  >
                    {c}
                  </Text>
                )) : null}
            </View>

            {countyDataForTable.map((rowData) => {
              return (
                <>
                  <View style={pdfStyle.rowView}>
                    {tableData['column1'] && tableData['column1'].length ?
                      tableData['column2'].map((c) => (
                        <Text
                          style={{
                            width: `${100 / tableData['column2'].length}%`,
                          }}
                        >
                          {rowData[c]}
                        </Text>
                      )) : null}
                  </View>
                </>
              )
            })}
          </View>
        </View>

        <View wrap fixed style={pdfStyle.footer}>
          <View
            style={{
              border: '1px solid #D9D9D9',
              marginTop: '10px',
              width: '520px',
              height: '1px',
              left: '25px',
            }}
          ></View>

          <View
            style={{ flexDirection: 'row', fontSize: 8, marginTop: '10px' }}
          >
            <Text style={{ marginLeft: '30px', fontSize: 10 }}>
              Export Date : {formatedDateTime}
            </Text>
            <Text
              style={{
                fontWeight: 800,
                marginLeft: '70px',
                fontSize: 10,
              }}
            >
              Confidential: Do Not Distribute Outside of DPR
            </Text>
            <Text
              style={{ marginLeft: '110px', fontSize: 10 }}
              render={({ pageNumber }) => `Page ${pageNumber}`}
            />
          </View>
        </View>
      </Page>
    </Document>
  )

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const ShowSnackBar = (
    message,
    variant: VariantType,
    duration,
    key?: string
  ) => {
    enqueueSnackbar(<div>{message}</div>, {
      variant: variant,
      persist: false,
      anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
      style: {
        flexDirection: 'row',
        maxWidth: '50px',
        marginLeft: '-150px',
      },
      autoHideDuration: duration,
      key: key,
      action: (key) => (
        <IconButton
          color="inherit"
          aria-label="dismiss"
          onClick={() => closeSnackbar(key)}
        >
          <CloseIcon />
        </IconButton>
      ),
    })
    return key
  }

  return (
    <MenuItem
      onClick={() => {
        const closeSnack = ShowSnackBar(
          'Generating Export File, Please wait !!',
          'info',
          1000000,
          'close'
        )
        getPdfData().then(() => {
          ReactDOM.render(<DialogAlert />, document.createElement('div'))

          let county: any = localStorage.getItem('EXPORT_DATA')
          let countyData = JSON.parse(county)
          let countyDataForTable: any = []
          if (countyData) {
            countyDataForTable = getCountyDataForTable(countyData.CountyDataTable)
          }
          if (countyDataForTable) {
          }

          let tableData = {
            column1: ['', `County`, `State`, 'National Average'],
            column2: ['type', 'san_mateo', 'california', 'top_decile'],
          }
          if (countyDataForTable.length > 0) {
            tableData = {
              column1: [
                '',
                `${countyDataForTable[0].county ? countyDataForTable[1].county : ''}`,
                `${countyDataForTable[0].state ? countyDataForTable[1].state : ''}`,
                'National Average',
              ],
              column2: ['type', 'san_mateo', 'california', 'top_decile'],
            }
          }

          const CountyAreaMap: any = sessionStorage.getItem('CountyMap')
          const pdfBlob = pdf(MyDocument(CountyAreaMap, tableData, countyDataForTable)).toBlob()
          pdfBlob.then((data) => {
            const blobObj = new Blob([data], { type: 'application/pdf' })
            saveAs(blobObj, 'Healthcare Panorama - Market Insights')
          })
          setTimeout(() => {
            closeSnackbar(closeSnack);
          }, 4000);
        })
      }}
    >
      Current Tab
    </MenuItem>
  )
}
