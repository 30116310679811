import { Button, Grid } from '@mui/material';
import React from 'react'
import { styled } from '@mui/material/styles';
import { Box } from "@mui/system";
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";

export interface ITopHeaderProps {
  type: string; // Client or Market Insights
}

const OuterDiv = styled(Box)(() => ({
  backgroundColor: "#ddecde",
  borderRadius: "6px"
}));

const OuterDivText = styled(Grid)(() => ({
  textAlign: "center",
  fontSize: "18px",
  fontWeight: "600",
  color: "#22577A",
}));

const InnerDivText1 = styled(Box)(() => ({
  color: "#3e3e3e",
  fontSize: "12px"
}));

const InnerDivText2 = styled(Box)(() => ({
  fontWeight: "550",
  color: "#3e3e3e",
  fontSize: "13px",
  wordBreak: "break-word"

}));

const InnerGrid = styled(Grid)(() => ({
  lineHeight: "20px",
  paddingTop: "12px !important"
}));

export const TopHeader: React.FC = () => {

  const search = useLocation().search;
  const type = new URLSearchParams(search).get('type');
  const state = new URLSearchParams(search).get('state');
  const provider = new URLSearchParams(search).get('provider');

  return <React.Fragment>
    <Grid container spacing={1} padding="30px 15px 15px 0px">
      <OuterDivText xs={1.5} container
        direction="column"
        justifyContent="space-between"
        alignItems="center" marginTop={"10px"}
      >
        Defined Client
      </OuterDivText>
      <Grid item xs={10.5}>
        <OuterDiv>
          <Grid container spacing={2} padding="0px 5px 5px 20px">
            <InnerGrid item xs={1.5} >
              <InnerDivText1>Provider Type</InnerDivText1>
              <InnerDivText2>{type}</InnerDivText2>
            </InnerGrid>
            <InnerGrid item xs={1.5} >
              <InnerDivText1>State</InnerDivText1>
              <InnerDivText2>{state}</InnerDivText2>
            </InnerGrid>
            <InnerGrid item xs={5}>
              <InnerDivText1>Provider</InnerDivText1>
              <InnerDivText2>{provider}</InnerDivText2>
            </InnerGrid>
            <Grid item xs={4} container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-end" paddingTop="6px">
              <Link to="/" style={{ textDecoration: "none" }}>
                <Button data-change-insights-id="Change Insights" variant="contained" style={{ background: "#4CAF50", margin: "auto 10px 10px 0px", textTransform: "capitalize", boxShadow: "none" }}>
                  Change Insights
                </Button>
              </Link>
            </Grid>
          </Grid>
        </OuterDiv>
      </Grid>
    </Grid>
  </React.Fragment>
}

