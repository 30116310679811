import { Drawer } from '@mui/material'
import React, { useEffect } from 'react'
import { DPRFeedback } from 'feedback'
import { appInsights } from '../../../providers/appInsightProvider'
export interface IFeedbackProps {
  open: boolean
  setOpen: Function
}

export const Feedback: React.FC<IFeedbackProps> = (props: IFeedbackProps) => {
  const handleCancel = () => {
    props.setOpen(false)
  }

  const handleSubmit = () => {
    appInsights.trackEvent({ name: 'Feedback Submitted' });
    props.setOpen(false)
  }

  const email: string = sessionStorage.getItem('user_email') as string

  return (
    <>
      <Drawer
        open={props.open}
        PaperProps={{ style: { width: '35vw', height: '100vh' } }}
        onClose={handleCancel}
        anchor="right"
      >
        <DPRFeedback
          ApplicationName="Panorama"
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          SupportEmail="Panorama-Healthcare@dpr.com"
          userInfo={email}
          ReleaseNoteURL="https://dprprod.sharepoint.com/sites/Panorama-Healthcare/SitePages/Panorama---Release-Notes.aspx"
          ReleaseNotes="1.0.0"
        />
      </Drawer>
    </>
  )
}
