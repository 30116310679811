import { Box, Grid } from "@mui/material";
import React from "react";
import { ImageComponent } from "./ImageComponent";
import { FilterTab } from "./FilterTab";

export const Home: React.FC = () => {
    const [value, setValue] = React.useState(0);

    const getFilterTabValue = (value: number) => {
        setValue(value);
    };

    return (
        <React.Fragment>
            <Box>
                <Grid container>
                    <Grid item lg={7} md={7}>
                        <Box sx={{ height: "100%" }}>
                            <ImageComponent TabValue={value} />
                        </Box>
                    </Grid>
                    <Grid item lg={5} md={5}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: "center", height: '100vh' }}>
                            <FilterTab index={0} value={getFilterTabValue} />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </React.Fragment >
    );
}
