import { Box, Grid, styled, Tab, Tabs, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { appInsights } from '../../../providers/appInsightProvider';
import { AboutPanorama } from './AboutPanorama';
import { FAQs } from './FAQs/FAQs';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function panelChangeFunction(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const About: React.FC = () => {

  const [value, setValue] = React.useState(0);

  const handleChangeValues = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const Outgridstyle = styled(Grid)(() => ({
    background: "rgb(237 237 237)",
    padding: "40px"
  }))

  const MidGridStyle = styled(Grid)(() => ({
    background: "#fff", width: '100%'
  }))

  const InGridStyle = styled(Grid)(() => ({
    display: "flex", flexWrap: "wrap"
  }))

  const MarginBox = styled(Box)(() => ({
    marginTop: '30px', marginLeft: '30px', height: '7vh'
  }))
  const TablePanelBox = styled(Box)(() => ({
    height: '77vh', overflowY: 'scroll', marginTop: '-6.1px', paddingTop: '10px'
  }))
  return <React.Fragment>
    <Outgridstyle >
      <MidGridStyle>
        <InGridStyle container lg={12}>
          <MarginBox>
            <Tabs value={value} onChange={handleChangeValues} aria-label="basic tabs example" style={{ marginLeft: '30px' }} TabIndicatorProps={{ style: { background: '#4CAF50', height: 3 } }}>
              <Tab data-about-id="About Panorama Page" label="About Panorama" {...panelChangeFunction(0)} style={{ color: value === 0 ? '#4CAF50' : 'GrayText', fontWeight: value === 0 ? 600 : 200, fontFamily: 'Open Sans' }} />
              <Tab data-faq-id="FAQs Page" key="FAQs Page" label="FAQs" {...panelChangeFunction(1)} style={{ color: value === 1 ? '#4CAF50' : 'GrayText', fontWeight: value === 1 ? 600 : 200, textTransform: "none", fontFamily: 'Open Sans', marginLeft: '50px' }} />
            </Tabs>
          </MarginBox>
          <TablePanelBox>
            <TabPanel value={value} index={0}>
              <AboutPanorama />
            </TabPanel>
            <TabPanel value={value} index={1} >
              <FAQs />
            </TabPanel>
          </TablePanelBox>
        </InGridStyle>
      </MidGridStyle>
    </Outgridstyle>
  </React.Fragment>
}


