import React from 'react'
import {
  Document,
  Page,
  StyleSheet,
  View,
  Image,
  Text,
  pdf,
} from '@react-pdf/renderer'
import dprlogo from '../../../Assets/dprlogoImage.png'
import panaromalogo from '../../../Assets/PanoramaImage.png'
import { alpha } from '@mui/system'
import { IconButton, MenuItem } from '@mui/material'
import { saveAs } from 'file-saver'
import { useSnackbar, VariantType } from 'notistack'
import CloseIcon from '@mui/icons-material/Close'
import {
  ServiceAreaTable,
  monthArray,
  ServiceAreaProvider,
} from '../../../config/Constant'
import ReactDOM from 'react-dom'
import DialogAlert from '../../CommonComponents/DialogBox/dialogAlert'
import { performanceText } from '../../../config/commonFunction'

interface ServiceAreaZipCodePDFprops {
  getPdfData: any
}

export const ServiceAreaZipCodePDF: React.FC<ServiceAreaZipCodePDFprops> = ({ getPdfData }: ServiceAreaZipCodePDFprops) => {

  const date = Date().split(' ')

  const monthConverter = (month: any) => {
    for (let i = 0; i < monthArray.length; i++) {
      if (monthArray[i][0] == month) return monthArray[i][1]
    }
  }
  const formatedDateTime = `${monthConverter(date[1])}/${date[2]}/${date[3]}`

  const pdfStyle = StyleSheet.create({
    body: {
      paddingTop: 15,
      paddingBottom: 45,
      paddingHorizontal: 15,
    },

    headerView: {
      display: 'flex',
      flexDirection: 'row',
      borderTop: '1px solid #EEE',
      paddingTop: 8,
      paddingBottom: 8,
      textAlign: 'center',
      fontSize: '8px',
      color: '#3E3E3E',
      backgroundColor: '#F3F3F3',
      borderRadius: '5px',
      fontWeight: 600,
    },
    rowView: {
      display: 'flex',
      flexDirection: 'row',
      borderTop: '1px solid #EEE',
      paddingTop: 8,
      paddingBottom: 8,
      textAlign: 'left',
      fontSize: '6px',
      color: '#3E3E3E',
    },
    footer: {
      flexDirection: 'column',
      position: 'absolute',
      bottom: 10,
      left: 10,
    },
  })

  const MyDocument = (ServiceAreaMapImage, zipData, ProviderData) => (
    <Document>
      <Page size="A4" style={pdfStyle.body}>
        <View>
          <View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                paddingBottom: '10px',
              }}
            >
              <Image
                src={dprlogo}
                style={{
                  width: '74px',
                  height: '45px',
                  padding: '5px',
                }}
              ></Image>

              <View
                style={{
                  borderRight: '1px solid #CCCCCC',
                  height: '30px',
                  marginTop: '8px',
                  marginLeft: '10px',
                }}
              ></View>

              <Image
                src={panaromalogo}
                style={{
                  width: '100px',
                  height: '30px',
                  marginLeft: '20px',
                  marginTop: '8px',
                  padding: 5,
                }}
              ></Image>

              <Text
                style={{
                  fontSize: '20px',
                  color: '#000000',
                  marginLeft: 'auto',
                  marginTop: '15px',
                }}
              >
                MARKET INSIGHTS
              </Text>
            </View>

            <View
              style={{
                borderBottom: '2px solid #4CAF50',
                width: '100%',
                marginTop: '35px',
              }}
            ></View>

            <View
              style={{ flexDirection: 'row', marginTop: '10px' }}
              fixed={false}
            >
              <Text
                style={{
                  fontSize: '20px',
                  color: alpha('#000000', 0.8),
                  marginRight: 'auto',
                  marginTop: '5px',
                }}
              >
                SERVICE AREA BY PROVIDER
              </Text>
              <View
                style={{
                  borderBottom: '1.2px solid black',
                  width: '48%',
                  marginBottom: '10px',
                  marginTop: 10,
                }}
              ></View>

            </View>

            <View
              style={{
                marginTop: '10px',
              }}
            >
              <Image
                style={{
                  width: '550px',
                  height: '150px',
                }}
                src={ServiceAreaMapImage}
              ></Image>
            </View>


          </View>
          <View style={{ marginTop: '20px' }}>
            <View style={{ ...pdfStyle.headerView, marginBottom: "8px" }} fixed>
              {ServiceAreaProvider['column1'].map((c) => (
                <Text
                  style={{
                    width: `${100 / ServiceAreaProvider['column1'].length}%`,
                    textAlign: 'center',
                    marginLeft: c == 'City' ? '6px' : c == 'Hospital Name' ? '-10px' : c == 'Beds' ? '-10px' : c == 'State' ? '-5px' : '0px'
                  }}
                >
                  {c}
                </Text>
              ))}
            </View>

            {ProviderData.map((rowData) => {
              console.log(rowData, ProviderData)
              return (
                <>
                  <View style={pdfStyle.rowView}>
                    {ServiceAreaProvider['column2'].map((c) => (
                      <Text
                        style={{
                          width: `${100 / ServiceAreaProvider['column2'].length
                            }%`,
                          marginLeft: c == 'city' ? '50px' : '10px'
                        }}
                      >
                        {c === 'total_performance_score'
                          ? performanceText(Math.round(rowData[c]))
                            ?.replace('(', '')
                            .replace(')', '')
                          : rowData[c]}
                      </Text>
                    ))}
                  </View>
                </>
              )
            })}
          </View>
        </View>

        <View wrap fixed style={pdfStyle.footer}>
          <View
            style={{
              border: '1px solid #D9D9D9',
              marginTop: '10px',
              width: '520px',
              height: '1px',
              left: '25px',
            }}
          ></View>
          <View
            style={{ flexDirection: 'row', fontSize: 8, marginTop: '10px' }}
          >
            <Text style={{ marginLeft: '30px', fontSize: 10 }}>
              Export Date : {formatedDateTime}
            </Text>
            <Text
              style={{
                fontWeight: 800,
                marginLeft: '70px',
                fontSize: 10,
                color: alpha('#000000', 0.4),
              }}
            >
              Confidential: Do Not Distribute Outside of DPR
            </Text>
            <Text
              style={{ marginLeft: '110px', fontSize: 10 }}
              render={({ pageNumber }) => `Page ${pageNumber}`}
            />
          </View>
        </View>
      </Page>
      <Page size="A4" style={pdfStyle.body}>
        <View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              paddingBottom: '10px',
            }}
          >
            <Image
              src={dprlogo}
              style={{
                width: '74px',
                height: '45px',
                padding: '5px',
              }}
            ></Image>

            <View
              style={{
                borderRight: '1px solid #CCCCCC',
                height: '30px',
                marginTop: '8px',
                marginLeft: '10px',
              }}
            ></View>

            <Image
              src={panaromalogo}
              style={{
                width: '100px',
                height: '30px',
                marginLeft: '20px',
                marginTop: '8px',
                padding: 5,
              }}
            ></Image>

            <Text
              style={{
                fontSize: '20px',
                color: '#000000',
                marginLeft: 'auto',
                marginTop: '15px',
              }}
            >
              MARKET INSIGHTS
            </Text>
          </View>

          <View
            style={{
              borderBottom: '2px solid #4CAF50',
              width: '100%',
              marginBottom: '20px',
              marginTop: 10,
            }}
          ></View>

          <View style={{ flexDirection: 'row', marginTop: '10px' }}>
            <Text
              style={{
                fontSize: '20px',
                color: alpha('#000000', 0.8),
                marginRight: 'auto',
                marginTop: '5px',
              }}
            >
              SERVICE AREA BY ZIPCODE
            </Text>
            <View
              style={{
                borderBottom: '1.2px solid black',
                width: '48%',
                marginBottom: '10px',
                marginTop: 10,
              }}
            ></View>
          </View>

        </View>

        <View style={{ marginTop: '10px' }}>
          <View style={pdfStyle.headerView} fixed>
            {ServiceAreaTable['column1'].map((c) => (
              <Text
                style={{
                  width: `${100 / ServiceAreaTable['column1'].length}%`,
                  textAlign: 'left',
                  marginLeft: '10px',
                }}
              >
                {c}
              </Text>
            ))}
          </View>

          {zipData.map((rowData) => {
            return (
              <>
                <View style={pdfStyle.rowView}>
                  {ServiceAreaTable['column2'].map((c) => (
                    <Text
                      style={{
                        width: `${100 / ServiceAreaTable['column2'].length}%`,
                      }}
                    >
                      {c === 'market_score'
                        ? performanceText(Math.round(rowData[c]))
                          ?.replace('(', '')
                          .replace(')', '')
                        : c === 'population_growth' ||
                          c === 'ip_growth' ||
                          c === 'op_growth'
                          ? parseFloat(rowData[c])?.toFixed(1) + '%'
                          : rowData[c]}
                    </Text>
                  ))}
                </View>
              </>
            )
          })}
        </View>

        <View wrap fixed style={pdfStyle.footer}>
          <View
            style={{
              border: '1px solid #D9D9D9',
              marginTop: '10px',
              width: '520px',
              height: '1px',
              left: '25px',
            }}
          ></View>
          <View
            style={{ flexDirection: 'row', fontSize: 8, marginTop: '10px' }}
          >
            <Text style={{ marginLeft: '30px', fontSize: 10 }}>
              Export Date : {formatedDateTime}
            </Text>
            <Text
              style={{
                fontWeight: 800,
                marginLeft: '70px',
                fontSize: 10,
              }}
            >
              Confidential: Do Not Distribute Outside of DPR
            </Text>
            <Text
              style={{ marginLeft: '110px', fontSize: 10 }}
              render={({ pageNumber }) => `Page ${pageNumber}`}
            />
          </View>
        </View>
      </Page>
    </Document>
  )
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const ShowSnackBar = (
    message,
    variant: VariantType,
    duration,
    key?: string
  ) => {
    enqueueSnackbar(<div>{message}</div>, {
      variant: variant,
      persist: false,
      anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
      style: {
        flexDirection: 'row',
        maxWidth: '50px',
        marginLeft: '-150px',
      },
      key: key,
      autoHideDuration: duration,
      action: (key) => (
        <IconButton
          color="inherit"
          aria-label="dismiss"
          onClick={() => closeSnackbar(key)}
        >
          <CloseIcon />
        </IconButton>
      ),
    })
    return key
  }

  return (
    <MenuItem
      onClick={() => {
        const closeSnack = ShowSnackBar('Generating Export File, Please wait !!', 'info', 1000000, 'close')
        getPdfData().then(() => {

          let serviceAreaData: any = localStorage.getItem('EXPORT_DATA')
          let serviceAreaZipData = JSON.parse(serviceAreaData)
          let zipData
          let ProviderData
          if (serviceAreaZipData) {
            zipData = serviceAreaZipData.ServiceAreaTableDataZipCode
            ProviderData = serviceAreaZipData.ServiceAreaTableDataProvider
          }
          const ServiceAreaMapImage: any = sessionStorage.getItem('ServiceAreaMap')
          const pdfBlob = pdf(MyDocument(ServiceAreaMapImage, zipData, ProviderData)).toBlob()
          ReactDOM.render(<DialogAlert />, document.createElement('div'))

          pdfBlob.then((data) => {
            const blobObj = new Blob([data], { type: 'application/pdf' })
            saveAs(blobObj, 'Healthcare Panorama - Market Insights')
          })

          setTimeout(() => {
            closeSnackbar(closeSnack);
          }, 4000);
        })
      }}
    >
      Current Tab
    </MenuItem>
  )
}
