import React, { useEffect, useState } from 'react'
import {
  Box,
  Grid,
  MenuItem,
  Skeleton,
  Typography,
  Select,
  InputLabel,
  FormControl,
  SelectChangeEvent,
} from '@mui/material'
import CountyDataTable from './CountyDataTable'
import { AzureMapsProvider } from 'react-azure-maps'
import MapController from '../../../CommonComponents/Maps/MapController'
import {
  usePopupState,
} from 'material-ui-popup-state/hooks'
import { useGetCountiesByStateLazyQuery } from '../../../../__generated__/graphql'
import { appInsights } from '../../../../providers/appInsightProvider'

interface ICountyDataProps {
  // CountyName: any
}

export const CountyData: React.FC<ICountyDataProps> = (
  props: ICountyDataProps
) => {
  const [countyName, setCountyName] = useState('')
  const [county, setcounty] = React.useState(countyName)
  const [stateName, setState] = useState('')
  const [isFilter, setIsFilter] = useState(false)
  const [countyList, setCountyList] = useState<any>(null)
  const countyHandleChange = (event: SelectChangeEvent) => {
    const countyName = sessionStorage.getItem('COUNTY_NAME')
    if (countyName !== event.target.value) {
      setcounty(event.target.value as string)
      sessionStorage.setItem("COUNTY_NAME", event.target.value)
    }
  }
  const [
    loadCountiesByState,
    {
      data: GetCountiesDataByState,
      loading: GetCountiesDataByStateLoading,
      error: GetCountiesDataByStateError,
    },
  ] = useGetCountiesByStateLazyQuery({
    variables: {
      state: stateName,
    },
  })

  useEffect(() => {
    if (stateName !== '') loadCountiesByState()
  }, [stateName])

  useEffect(() => {
    if (
      GetCountiesDataByState &&
      GetCountiesDataByState?.OTHERDATA_COUNTY_MAPPING?.length
    ) {
      setCountyList(GetCountiesDataByState?.OTHERDATA_COUNTY_MAPPING)
    }
  }, [GetCountiesDataByStateLoading])

  const PopupState = usePopupState({
    variant: 'popover',
    popupId: 'aboutMenu',
  })

  return (
    <React.Fragment>
      <Grid container spacing={1} padding="10px">
        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          style={{
            height: '66vh',
          }}
        >
          {GetCountiesDataByStateLoading ? (
            <Typography component="div" key="h3" variant="h3">
              <Skeleton style={{ width: '150px', marginLeft: '14px', marginBottom: '5px' }} />
            </Typography>
          ) : (
            <Box style={{ display: 'block' }}>
              <FormControl size="small"
                style={{
                  width: '150px',
                  marginLeft: '14px',
                  marginBottom: '5px',
                }}
              >
                <InputLabel
                  id="demo-simple-select-label"
                  style={{ fontSize: '14px', fontWeight: "600" }}
                >
                  Select County
                </InputLabel>
                <Select
                  variant="outlined"
                  label="Select County"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={countyHandleChange}
                  defaultValue={countyName}
                  value={county ? county : countyName}
                  style={{ fontSize: '14px' }}
                  MenuProps={{
                    style: { height: "60vh" }
                  }}
                >
                  {countyList && countyList.length
                    ? countyList.map((data) => {
                      return (
                        <MenuItem
                          onClick={(e) => {
                            if (data.county !== countyName) {
                              setCountyName(
                                data.county ? data.county + data.statecode : ''
                              )
                              PopupState.close()
                              setIsFilter(true)
                              appInsights.trackEvent(
                                { name: 'County Filter on County Data Tab' },
                                { filter: data.county ? data.county : '' }
                              )
                              sessionStorage.setItem(
                                'select_county',
                                data.county ? data.county : ''
                              )
                            }
                          }}
                          value={data.county}
                          style={{
                            backgroundColor:
                              countyName === data.county + data.statecode ||
                                countyName === data.county
                                ? '#eee'
                                : 'transparent',
                            fontSize: '13px',
                          }}
                        >
                          {data.county}
                        </MenuItem>
                      )
                    })
                    : null}
                </Select>
              </FormControl>
            </Box>
          )}
          <AzureMapsProvider>
            <MapController
              MapType="County"
              AgeBracket=""
              MapView=""
              Providerdata=""
              countyName={countyName}
            />
          </AzureMapsProvider>
        </Grid>
        <Grid item lg={6} md={6} sm={12}>
          <CountyDataTable
            setState={(val) => {
              setState(val)
            }}
            setCounty={(val) => {
              setCountyName(val)
              sessionStorage.setItem("COUNTY_NAME", val)
            }}
            countyName={countyName}
            stateName={stateName}
            isFilter={isFilter}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
