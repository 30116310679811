import { Box, Grid } from "@mui/material"
import React, { useEffect } from "react"
import { appInsights } from "../../../../providers/appInsightProvider";
import { useGetFaqDataQuery } from "../../../../__generated__/graphql";
import FAQsRenderer from "./FAQsRenderer";

export const FAQs: React.FC = () => {
    const contentStyle = {
        marginTop: '-5px'
    }
    const { data: FAQsData, loading: loadingFAQs, error: errorFAQs } = useGetFaqDataQuery()

    const faqData = FAQsData?.OTHERDATA_FAQ;

    useEffect(() => {
        if (errorFAQs instanceof Error) {
            appInsights.trackException({ exception: errorFAQs, error: new Error("Failed to fetch FAQs.") })
        }
    }, [errorFAQs])

    return <React.Fragment>
        <Box>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6} sx={{ height: '100%', width: '100%' }}>{
                    <Box style={{ justifyContent: 'center' }}>
                        <FAQsRenderer>
                            {faqData?.map((item) => (
                                <FAQsRenderer.Content key={item.id}>
                                    <FAQsRenderer.Question>{item.questions}</FAQsRenderer.Question>
                                    <FAQsRenderer.Answer><ul style={contentStyle} dangerouslySetInnerHTML={{ __html: item.answers }} /></FAQsRenderer.Answer>
                                </FAQsRenderer.Content>
                            ))}
                        </FAQsRenderer>
                    </Box>
                }</Grid>

            </Grid>
        </Box>
    </React.Fragment>
}

