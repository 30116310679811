import { useAccount, useMsal } from '@azure/msal-react';
import { useEffect } from 'react';

export function useUser() {
    const { accounts } = useMsal()
    const account = useAccount(accounts[0] || {})

    useEffect(() => {
        if (account) {
            sessionStorage.setItem('user_name', account?.name ?? '');
            sessionStorage.setItem('initials_name', account?.name ?? '');
            sessionStorage.setItem('user_email', account?.username ?? '')
        }
    })
}
