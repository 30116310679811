import React, { useEffect, useState } from 'react'
import {
  DataGrid,
  Sorting,
  HeaderFilter,
  Column,
  FilterRow,
  Selection,
  Scrolling,
} from 'devextreme-react/data-grid'
import 'devextreme/dist/css/dx.common.css'
import { Box, Grid } from '@mui/material'
import { renderTitleHeader } from '../../../CommonComponents/RenderTitleHeader/RenderTitleHeader'
import {
  useGetServiceAreaTableDataForCityByProviderLazyQuery,
  useGetServiceAreaTableDataForCityByZipLazyQuery,
  useGetServiceAreaTableDataForCountyByProviderLazyQuery,
  useGetServiceAreaTableDataForCountyByZipLazyQuery,
  useGetServiceAreaTableDataForZipCodeAndRadiusByProviderLazyQuery,
  useGetServiceAreaTableDataForZipCodeAndRadiusByZipLazyQuery,
  useGetServiceAreaTableDataForZipCodeByProviderLazyQuery,
  useGetServiceAreaTableDataForZipCodeByZipLazyQuery,
} from '../../../../__generated__/graphql'
import { Link, useLocation } from 'react-router-dom'
import { appInsights } from '../../../../providers/appInsightProvider'
import { performanceText } from '../../../../config/commonFunction'

interface ServiceAreaDataTableProps {
  Data: any
  buttonType: string
  ageBracket: string
}

export const ServiceAreaDataTable: React.FC<ServiceAreaDataTableProps> = (
  props: ServiceAreaDataTableProps
) => {
  const dataGrid = React.useRef(null)
  const search = useLocation().search
  const city = new URLSearchParams(search).get('city')
  const county = new URLSearchParams(search).get('county')
  const zip_code = new URLSearchParams(search).get('zipcode')
  const radius = new URLSearchParams(search).get('radius')

  const [dataSourceByProvider, setDataSourceByProvider] = useState([])
  const [dataSourceByZip, setDataSourceByZip] = useState([])

  const [
    loadServiceAreaTableDataForCityByProvider,
    {
      data: ServiceAreaTableDataForCityByProvider,
      loading: ServiceAreaTableDataForCityByProviderLoading,
      error: ServiceAreaTableDataForCityByProviderError,
    },
  ] = useGetServiceAreaTableDataForCityByProviderLazyQuery({
    variables: {
      city,
    },
    fetchPolicy: 'network-only',
  })

  const [
    loadServiceAreaTableDataForCityByZip,
    {
      data: ServiceAreaTableDataForCityByZip,
      loading: ServiceAreaTableDataForCityByZipLoading,
      error: ServiceAreaTableDataForCityByZipError,
    },
  ] = useGetServiceAreaTableDataForCityByZipLazyQuery({
    variables: {
      city: city,
      age_group:
        props.ageBracket === 'All'
          ? 'Adult,Geriatric,Pediatric'
          : props.ageBracket,
    },
    fetchPolicy: 'network-only',
  })

  const [
    loadServiceAreaTableDataForZipCodeByProvider,
    {
      data: ServiceAreaTableDataForZipCodeByProvider,
      loading: ServiceAreaTableDataForZipCodeByProviderLoading,
      error: ServiceAreaTableDataForZipCodeByProviderError,
    },
  ] = useGetServiceAreaTableDataForZipCodeByProviderLazyQuery({
    variables: {
      zipcode: zip_code,
    },
    fetchPolicy: 'network-only',
  })

  const [
    loadServiceAreaTableDataForZipCodeByZip,
    {
      data: ServiceAreaTableDataForZipCodeByZip,
      loading: ServiceAreaTableDataForZipCodeByZipLoading,
      error: ServiceAreaTableDataForZipCodeByZipError,
    },
  ] = useGetServiceAreaTableDataForZipCodeByZipLazyQuery({
    variables: {
      zipcode: zip_code,
      age_group:
        props.ageBracket === 'All'
          ? 'Adult,Geriatric,Pediatric'
          : props.ageBracket,
    },
    fetchPolicy: 'network-only',
  })

  let county_data: string[] = []
  if (county) {
    let counties_array = county.split(',')
    for (let i = 0; i < counties_array.length; i++) {
      const element = counties_array[i]
      let splitted_val = element.split(' - ')
      let string_val: string = splitted_val[1].trim() + splitted_val[0].trim()
      county_data.push(string_val)
    }
  }

  const [
    loadServiceAreaTableDataForCountyByProvider,
    {
      data: ServiceAreaTableDataForCountyByProvider,
      loading: ServiceAreaTableDataForCountyByProviderLoading,
      error: ServiceAreaTableDataForCountyByProviderError,
    },
  ] = useGetServiceAreaTableDataForCountyByProviderLazyQuery({
    variables: {
      county: county_data.join(','),
    },
    fetchPolicy: 'network-only',
  })

  const [
    loadServiceAreaTableDataForCountyByZip,
    {
      data: ServiceAreaTableDataForCountyByZip,
      loading: ServiceAreaTableDataForCountyByZipLoading,
      error: ServiceAreaTableDataForCountyByZipError,
    },
  ] = useGetServiceAreaTableDataForCountyByZipLazyQuery({
    variables: {
      county: county_data.join(','),
      age_group:
        props.ageBracket === 'All'
          ? 'Adult,Geriatric,Pediatric'
          : props.ageBracket,
    },
    fetchPolicy: 'network-only',
  })

  const [
    loadServiceAreaTableDataForZipCodeAndRadiusByProvider,
    {
      data: ServiceAreaTableDataByForZipCodeAndRadiusByProvider,
      loading: ServiceAreaTableDataByForZipCodeAndRadiusByProviderLoading,
      error: ServiceAreaTableDataByForZipCodeAndRadiusByProviderError,
    },
  ] = useGetServiceAreaTableDataForZipCodeAndRadiusByProviderLazyQuery({
    variables: {
      zipcode: zip_code,
      radius: radius ? parseInt(radius) : 1,
    },
    fetchPolicy: 'network-only',
  })

  const [
    loadServiceAreaTableDataForZipCodeAndRadiusByZip,
    {
      data: ServiceAreaTableDataByForZipCodeAndRadiusByZip,
      loading: ServiceAreaTableDataByForZipCodeAndRadiusByZipLoading,
      error: ServiceAreaTableDataByForZipCodeAndRadiusByZipError,
    },
  ] = useGetServiceAreaTableDataForZipCodeAndRadiusByZipLazyQuery({
    variables: {
      zipcode: zip_code,
      radius: radius ? parseInt(radius) : 1,
      age_group:
        props.ageBracket === 'All'
          ? 'Adult,Geriatric,Pediatric'
          : props.ageBracket,
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (ServiceAreaTableDataForCityByProviderError instanceof Error) {
      appInsights.trackException({
        exception: ServiceAreaTableDataForCityByProviderError,
        error: new Error(
          'Failed to fetch Service Area Table Data for City by Provider.'
        ),
      })
    } else if (ServiceAreaTableDataForCityByZipError instanceof Error) {
      appInsights.trackException({
        exception: ServiceAreaTableDataForCityByZipError,
        error: new Error(
          'Failed to fetch Service Area Table Data for city by Zip.'
        ),
      })
    } else if (ServiceAreaTableDataForZipCodeByProviderError instanceof Error) {
      appInsights.trackException({
        exception: ServiceAreaTableDataForZipCodeByProviderError,
        error: new Error(
          'Failed to fetch Service Area Table Data for zipcode by provider.'
        ),
      })
    } else if (ServiceAreaTableDataForZipCodeByZipError instanceof Error) {
      appInsights.trackException({
        exception: ServiceAreaTableDataForZipCodeByZipError,
        error: new Error(
          'Failed to fetch Service Area Table Data for zipcode by Zip.'
        ),
      })
    } else if (ServiceAreaTableDataForCountyByProviderError instanceof Error) {
      appInsights.trackException({
        exception: ServiceAreaTableDataForCountyByProviderError,
        error: new Error(
          'Failed to fetch Service Area Table Data for county by provider.'
        ),
      })
    } else if (ServiceAreaTableDataForCountyByZipError instanceof Error) {
      appInsights.trackException({
        exception: ServiceAreaTableDataForCountyByZipError,
        error: new Error(
          'Failed to fetch Service Area Table Data for county by Zip.'
        ),
      })
    } else if (
      ServiceAreaTableDataByForZipCodeAndRadiusByProviderError instanceof Error
    ) {
      appInsights.trackException({
        exception: ServiceAreaTableDataByForZipCodeAndRadiusByProviderError,
        error: new Error(
          'Failed to Service Area Table Data for zipcode and radius by provider.'
        ),
      })
    } else if (
      ServiceAreaTableDataByForZipCodeAndRadiusByZipError instanceof Error
    ) {
      appInsights.trackException({
        exception: ServiceAreaTableDataByForZipCodeAndRadiusByZipError,
        error: new Error(
          'Failed to fetch Service Area Table Data for zipcode and radius by Zip.'
        ),
      })
    }
  }, [
    ServiceAreaTableDataForCityByProviderError,
    ServiceAreaTableDataForCityByZipError,
    ServiceAreaTableDataForZipCodeByProviderError,
    ServiceAreaTableDataForZipCodeByZipError,
    ServiceAreaTableDataForCountyByProviderError,
    ServiceAreaTableDataForCountyByZipError,
    ServiceAreaTableDataByForZipCodeAndRadiusByProviderError,
    ServiceAreaTableDataByForZipCodeAndRadiusByZipError,
  ])

  useEffect(() => {
    if (city) {
      loadServiceAreaTableDataForCityByProvider()
    } else if (zip_code && radius) {
      loadServiceAreaTableDataForZipCodeAndRadiusByProvider()
    } else if (zip_code) {
      loadServiceAreaTableDataForZipCodeByProvider()
    } else if (county) {
      loadServiceAreaTableDataForCountyByProvider()
    }
  }, [])

  useEffect(() => {
    if (city) {
      if (props.buttonType === 'Provider')
        loadServiceAreaTableDataForCityByProvider()
      else loadServiceAreaTableDataForCityByZip()
    } else if (zip_code && radius) {
      if (props.buttonType === 'Provider')
        loadServiceAreaTableDataForZipCodeAndRadiusByProvider()
      else loadServiceAreaTableDataForZipCodeAndRadiusByZip()
    } else if (zip_code) {
      if (props.buttonType === 'Provider')
        loadServiceAreaTableDataForZipCodeByProvider()
      else loadServiceAreaTableDataForZipCodeByZip()
    } else if (county) {
      if (props.buttonType === 'Provider')
        loadServiceAreaTableDataForCountyByProvider()
      else loadServiceAreaTableDataForCountyByZip()
    }
  }, [props.buttonType])

  useEffect(() => {
    if (city) {
      loadServiceAreaTableDataForCityByZip()
    } else if (zip_code && radius) {
      loadServiceAreaTableDataForZipCodeAndRadiusByZip()
    } else if (zip_code) {
      loadServiceAreaTableDataForZipCodeByZip()
    } else if (county) {
      loadServiceAreaTableDataForCountyByZip()
    }
  }, [props.ageBracket])

  useEffect(() => {
    let Table_data: any = []
    if (
      ServiceAreaTableDataForCityByProvider &&
      ServiceAreaTableDataForCityByProvider?.udf_tbl_by_provider_dpr_market &&
      ServiceAreaTableDataForCityByProvider
        ?.udf_tbl_by_provider_dpr_market.length
    )
      Table_data =
        ServiceAreaTableDataForCityByProvider?.udf_tbl_by_provider_dpr_market
    else if (
      ServiceAreaTableDataForZipCodeByProvider &&
      ServiceAreaTableDataForZipCodeByProvider?.udf_tbl_by_provider_multi_zip &&
      ServiceAreaTableDataForZipCodeByProvider?.udf_tbl_by_provider_multi_zip
        .length
    )
      Table_data =
        ServiceAreaTableDataForZipCodeByProvider?.udf_tbl_by_provider_multi_zip
    else if (
      ServiceAreaTableDataForCountyByProvider &&
      ServiceAreaTableDataForCountyByProvider?.udf_tbl_by_provider_multi_county &&
      ServiceAreaTableDataForCountyByProvider?.udf_tbl_by_provider_multi_county
        .length
    )
      Table_data =
        ServiceAreaTableDataForCountyByProvider?.udf_tbl_by_provider_multi_county
    else if (
      ServiceAreaTableDataByForZipCodeAndRadiusByProvider &&
      ServiceAreaTableDataByForZipCodeAndRadiusByProvider.udf_zip_radius_by_provider_tbl &&
      ServiceAreaTableDataByForZipCodeAndRadiusByProvider
        .udf_zip_radius_by_provider_tbl.length
    )
      Table_data =
        ServiceAreaTableDataByForZipCodeAndRadiusByProvider.udf_zip_radius_by_provider_tbl
    let updated_array_by_provider: any = []
    if (Table_data && Table_data.length) {
      for (var i = 0; i < Table_data?.length; i++) {
        let table_json: any = {
          ...Table_data[i],
          total_performance_score:
            typeof Table_data[i].total_performance_score === 'number'
              ? Math.round(Table_data[i].total_performance_score) + performanceText(
                Math.round(Table_data[i].total_performance_score)
              )
              : 'N/A',
        }
        updated_array_by_provider.push(table_json as any)
      }
    }
    setDataSourceByProvider(updated_array_by_provider)

    props.Data(updated_array_by_provider)
  }, [
    ServiceAreaTableDataForCityByProviderLoading,
    ServiceAreaTableDataForCountyByProviderLoading,
    ServiceAreaTableDataForZipCodeByProviderLoading,
    ServiceAreaTableDataByForZipCodeAndRadiusByProviderLoading,
  ])

  useEffect(() => {
    let Table_data: any = null
    if (
      ServiceAreaTableDataForCityByZip &&
      ServiceAreaTableDataForCityByZip?.udf_tbl_by_zip_dpr_city &&
      ServiceAreaTableDataForCityByZip?.udf_tbl_by_zip_dpr_city.length
    )
      Table_data = ServiceAreaTableDataForCityByZip?.udf_tbl_by_zip_dpr_city
    else if (
      ServiceAreaTableDataForZipCodeByZip &&
      ServiceAreaTableDataForZipCodeByZip?.udf_tbl_by_zip_multi_zip &&
      ServiceAreaTableDataForZipCodeByZip?.udf_tbl_by_zip_multi_zip.length
    )
      Table_data = ServiceAreaTableDataForZipCodeByZip?.udf_tbl_by_zip_multi_zip
    else if (
      ServiceAreaTableDataForCountyByZip &&
      ServiceAreaTableDataForCountyByZip?.udf_tbl_by_zip_multi_county &&
      ServiceAreaTableDataForCountyByZip?.udf_tbl_by_zip_multi_county.length
    )
      Table_data =
        ServiceAreaTableDataForCountyByZip?.udf_tbl_by_zip_multi_county
    else if (
      ServiceAreaTableDataByForZipCodeAndRadiusByZip &&
      ServiceAreaTableDataByForZipCodeAndRadiusByZip.udf_zip_radius_by_zip_tbl &&
      ServiceAreaTableDataByForZipCodeAndRadiusByZip.udf_zip_radius_by_zip_tbl
        .length
    )
      Table_data =
        ServiceAreaTableDataByForZipCodeAndRadiusByZip.udf_zip_radius_by_zip_tbl

    let updated_array_by_zip: any = []
    if (Table_data && Table_data.length) {
      for (var i = 0; i < Table_data?.length; i++) {
        let table_json: any = {
          ...Table_data[i],
          market_score: Table_data[i].market_score
            ? performanceText(Math.round(Table_data[i].market_score))
              ?.replace('(', '')
              .replace(')', '')
            : 'N/A',
          population_growth: Table_data[i].population_growth
            ? parseFloat(Table_data[i].population_growth)?.toFixed(1)
            : 'N/A',
          current_population: typeof Table_data[i].current_population === 'number'
            ? parseInt(Table_data[i].current_population)
            : 'N/A',
          ip_growth: Table_data[i].ip_growth
            ? parseFloat(Table_data[i].ip_growth)?.toFixed(1)
            : 'N/A',
          op_growth: Table_data[i].op_growth
            ? parseFloat(Table_data[i].op_growth)?.toFixed(1)
            : 'N/A',
        }

        updated_array_by_zip.push(table_json as any)
      }
    }
    setDataSourceByZip(updated_array_by_zip)
  }, [
    ServiceAreaTableDataForCityByZipLoading,
    ServiceAreaTableDataForZipCodeByZipLoading,
    ServiceAreaTableDataForCountyByZipLoading,
    ServiceAreaTableDataByForZipCodeAndRadiusByZipLoading,
  ])

  return (
    <Grid container spacing={2}>
      {props.buttonType === 'Provider' ? (
        <Grid md={12} lg={12}>
          <Box
            sx={{
              paddingLeft: '24px',
              paddingBottom: '2px',
              width: 'calc(100vw - 50px)',
              height: '100vh',
              marginTop: '12px',
            }}
          >
            <DataGrid
              dataSource={dataSourceByProvider}
              keyExpr="hospital_name"
              showBorders={true}
              columnResizingMode="widget"
              hoverStateEnabled
              id="service_area_table_by_provider"
              ref={dataGrid}
              style={{
                height: 'auto',
              }}
              width="calc(100vw - 50px)"
              allowColumnResizing
              allowColumnReordering
              columnAutoWidth
            >
              <Sorting mode="multiple" />

              <HeaderFilter allowSearch visible={true} />
              <FilterRow visible />
              <Selection />

              <Scrolling useNative={true} preloadEnabled={true} />

              <Column
                allowSearch
                caption="Hospital Name"
                dataField="hospital_name"
                dataType="string"
                sortOrder="asc"
                minWidth={250}
                width={200}
                cellRender={(data) => {
                  let searchParams = new URLSearchParams(window.location.search)
                  searchParams.delete('zipcode')
                  searchParams.delete('radius')
                  searchParams.delete('county')
                  searchParams.delete('city')
                  searchParams.set('type', 'Hospital')
                  searchParams.set('state', data?.data.state)
                  searchParams.set('provider', data?.data.hospital_name)
                  searchParams.set('id', data?.data.definitive_id)
                  let newurl =
                    window.location.pathname.replace(
                      'market_insights/ServiceArea',
                      'client_insights/Summary'
                    ) +
                    '?' +
                    searchParams.toString()
                  return (
                    <Link
                      to={newurl}
                      target="_blank"
                      rel="noopener noreferrer"
                      data-hospital-name="Market Insights | Service Area | Hospital"
                    >
                      {renderTitleHeader(data.value)}
                    </Link>
                  )
                }}
              ></Column>
              <Column
                allowSearch
                caption="Health System"
                dataField="parent_health_system"
                dataType="string"
                minWidth={250}
                width={200}
                headerCellRender={(cellData) =>
                  renderTitleHeader(
                    cellData.column?.caption,
                    'Parent organization or health system that owns and operates this individual healthcare facility'
                  )
                }
                cellRender={(data) => {
                  let searchParams = new URLSearchParams(window.location.search)
                  searchParams.delete('zipcode')
                  searchParams.delete('radius')
                  searchParams.delete('county')
                  searchParams.delete('city')
                  searchParams.set('type', 'Health System')
                  searchParams.set('state', data?.data.state)
                  searchParams.set('provider', data?.data.parent_health_system)
                  searchParams.set('id', data?.data.parent_definitive_id)
                  let newurl =
                    window.location.pathname.replace(
                      'market_insights/ServiceArea',
                      'client_insights/Summary'
                    ) +
                    '?' +
                    searchParams.toString()
                  return data?.data.parent_definitive_id ? (
                    <Link
                      to={newurl}
                      target="_blank"
                      rel="noopener noreferrer"
                      data-health-system-name="Market Insights | Service Area | Health System"
                    >
                      {renderTitleHeader(data.value)}
                    </Link>
                  ) : (
                    renderTitleHeader(data.value)
                  )
                }}
              ></Column>
              <Column
                allowSearch
                caption="Address"
                dataField="address"
                dataType="string"
                minWidth={250}
                width={200}
                cellRender={(data) => {
                  return data.value?.replace(' , ', ' ')
                }}
              ></Column>
              <Column
                allowSearch
                caption="City"
                dataField="city"
                dataType="string"
                minWidth={250}
                width={200}
              ></Column>
              <Column
                allowSearch
                caption="State"
                dataField="state"
                dataType="string"
                minWidth={250}
                width={200}
              ></Column>
              <Column
                allowSearch
                caption="Beds"
                dataField="number_of_beds"
                dataType="string"
                minWidth={250}
                alignment="left"
                width={200}
                cellRender={(data) => {
                  return typeof data.value === 'number' ? parseInt(data.value).toLocaleString() : 'N/A'
                }}
              ></Column>
              <Column
                allowSearch
                caption="Performance Score"
                dataField="total_performance_score"
                dataType="number"
                alignment={'left'}
                minWidth={250}
                width={200}
                cellRender={(data) => {
                  return typeof data.value === 'number'
                    ? Math.round(data.value) + performanceText(Math.round(data.value))
                    : data.value
                }}
                headerCellRender={(cellData) =>
                  renderTitleHeader(
                    cellData.column?.caption,
                    'DPR’s proprietary scoring system that evaluates performance at the organizational level, looking at specific metrics across five key categories. This score is intended for internal use only and should not be shared outside of the company.'
                  )
                }
              ></Column>
            </DataGrid>
          </Box>
        </Grid>
      ) : (
        <Grid md={12} lg={12}>
          <Box
            sx={{
              height: '100vh',
              paddingLeft: '24px',
              paddingBottom: '2px',
              width: 'calc(100vw - 50px)',
              marginTop: '12px',
            }}
          >
            <DataGrid
              dataSource={dataSourceByZip}
              keyExpr="zipcode"
              showBorders={true}
              columnResizingMode="widget"
              hoverStateEnabled
              id="service_area_table_by_zip_code"
              ref={dataGrid}
              style={{
                height: 'auto',
              }}
              width="calc(100vw - 50px)"
              allowColumnResizing
              allowColumnReordering
              columnAutoWidth
            >
              <Sorting mode="multiple" />

              <HeaderFilter allowSearch visible={true} />
              <FilterRow visible />
              <Selection />

              <Scrolling useNative={true} preloadEnabled={true} />

              <Column
                allowSearch
                caption="Zip Code"
                dataField="zipcode"
                dataType="string"
                minWidth={150}
                width={100}
              ></Column>
              <Column
                allowSearch
                caption="City"
                dataField="dpr_market_city"
                dataType="string"
                minWidth={150}
                width={100}
              ></Column>
              <Column
                allowSearch
                caption="County"
                dataField="county"
                dataType="string"
                minWidth={150}
                width={100}
                sortOrder="asc"
              ></Column>
              <Column
                allowSearch
                caption="State"
                dataField="statename"
                dataType="string"
                minWidth={150}
                width={100}
              ></Column>
              <Column
                allowSearch
                caption="Market Opportunity Score"
                dataField="market_score"
                dataType="string"
                minWidth={250}
                width={200}
                cellRender={(data) => {
                  return typeof data.value === 'number'
                    ? performanceText(Math.round(data.value))
                      ?.replace('(', '')
                      .replace(')', '')
                    : data.value
                }}
                headerCellRender={(cellData) =>
                  renderTitleHeader(
                    cellData.column?.caption,
                    'DPR’s proprietary scoring system that evaluates a customized market’s attractiveness for DPR and its clients, looking at specific metrics across five key categories. This score is intended for internal use only and should not be shared outside of the company'
                  )
                }
              ></Column>
              <Column
                allowSearch
                caption="Population"
                dataField="current_population"
                dataType="string"
                minWidth={150}
                width={120}
                cellRender={(data) => {
                  return typeof data.value === 'number' ? parseInt(data.value).toLocaleString() : 'N/A'
                }}
              ></Column>
              <Column
                allowSearch
                caption="5 - Yr Growth"
                dataField="population_growth"
                dataType="string"
                minWidth={200}
                width={150}
                cellRender={(data) => {
                  return typeof data.value === 'number'
                    ? parseFloat(data.value)?.toFixed(1) + '%'
                    : data.value === 'N/A'
                      ? data.value
                      : data.value + '%'
                }}
                headerCellRender={(cellData) =>
                  renderTitleHeader(
                    cellData.column?.caption,
                    'Projected 5-year growth of the overall population of the selected market'
                  )
                }
              ></Column>
              <Column
                allowSearch
                caption="IP 10 - Yr Growth"
                dataField="ip_growth"
                dataType="string"
                minWidth={200}
                width={150}
                cellRender={(data) => {
                  return typeof data.value === 'number'
                    ? parseFloat(data.value)?.toFixed(1) + '%'
                    : data.value === 'N/A'
                      ? data.value
                      : data.value + '%'
                }}
                headerCellRender={(cellData) =>
                  renderTitleHeader(
                    cellData.column?.caption,
                    'Projected 10-year growth for inpatient services for the selected market. Inpatient growth translates to need for inpatient beds'
                  )
                }
              ></Column>
              <Column
                allowSearch
                caption="OP 10 - Yr Growth"
                dataField="op_growth"
                dataType="string"
                minWidth={200}
                width={150}
                cellRender={(data) => {
                  return typeof data.value === 'number'
                    ? parseFloat(data.value)?.toFixed(1) + '%'
                    : data.value === 'N/A'
                      ? data.value
                      : data.value + '%'
                }}
                headerCellRender={(cellData) =>
                  renderTitleHeader(
                    cellData.column?.caption,
                    'Projected 10-year growth for outpatient services for the selected market. Outpatient growth translates to need for clinics, ambulatory surgery centers, cancer centers, and medical office buildings'
                  )
                }
              ></Column>
            </DataGrid>
          </Box>
        </Grid>
      )}
    </Grid>
  )
}
