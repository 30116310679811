import { Box, Grid } from '@mui/material'
import React, { useEffect } from 'react'
import { appInsights } from '../../../providers/appInsightProvider'
import { additional_resources } from './AdditionalResourcesConfig'

export interface IAdditionalResourcesProps { }

export const AdditionalResources: React.FC = () => {
  useEffect(() => {
    appInsights.trackEvent({ name: 'Additional Resources Page Opened' });
  }, [])
  return <React.Fragment>
    <Grid style={{ background: "rgb(237 237 237)", padding: "40px" }}>
      <Grid style={{ background: "#fff", height: "90vh", overflowY: "scroll" }}>
        <Grid container lg={12} sx={{ display: "flex", flexWrap: "wrap" }}>
          {
            additional_resources.map((resources, i) => {
              return (
                <Grid container direction="row" style={{ background: "#fff", width: "49.5%", display: "flex", padding: "20px" }}>
                  <Grid item lg={2} style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: resources.name == "Kaiser Health News" || resources.name == "Medscape News" || resources.name == "Modern Healthcare" ? "-25px" : '0px'
                  }}>
                    <a href={resources.site} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                      <img alt="logo" src={resources.logo}
                        width={resources.width ? resources.width : "90px"}
                        height={resources.height ? resources.height : "60px"}></img></a>
                  </Grid>
                  <Grid item lg={10}>
                    <a href={resources.site} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                      <div style={{ color: "#454BA1", fontWeight: "600" }}>
                        {resources.name}</div></a>
                    <div style={{ fontSize: "13px", marginTop: "10px" }}>{resources.description}</div>
                  </Grid>
                </Grid>)
            })
          }
        </Grid>
      </Grid>
    </Grid>
  </React.Fragment>
}

export default AdditionalResources
