import React from 'react'
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  Image,
  Svg,
  Path,
  pdf,
} from '@react-pdf/renderer'
import dprlogo from '../../Assets/dprlogoImage.png'
import panaromalogo from '../../Assets/PanoramaImage.png'
import { useLocation } from 'react-router-dom'
import { alpha, IconButton, MenuItem } from '@mui/material'
import { useSnackbar, VariantType } from 'notistack'
import { renderTitleHeader } from '../CommonComponents/RenderTitleHeader/RenderTitleHeader'
import { saveAs } from 'file-saver'
import CloseIcon from '@mui/icons-material/Close'

interface PdfTableProps {
  provider_data: any
  dataSource: any
}
export const PdfTable: React.FC<PdfTableProps> = (props: PdfTableProps) => {
  const search = useLocation().search
  const type = new URLSearchParams(search).get('type')
  const state = new URLSearchParams(search).get('state')
  const provider = new URLSearchParams(search).get('provider')
  let tableData = {
    column1: [
      'Hospital Name',
      'Parent Health System',
      'Regional Health System',
      'Address',
      'City',
      'State',
      'Overall Performance Score',
      'No. of Beds',
      'Net Patient Revenue',
      'Operating Profit Margin',
      'Total SF',
      'Average Age of Plant',
      'Capital Expenditures',
      'Current Ratio',
      'Days Cash on Hand',
    ],
    column2: [
      'hospital_name',
      'parent_health_system',
      'regional_health_system',
      'address',
      'city',
      'state',
      'total_performance_score',
      'number_of_beds',
      'net_patient_revenue',
      'net_operating_profit_margin',
      'total_facility_square_footage',
      'avg_age_of_plant',
      'capital_expenditures',
      'current_ratio',
      'days_cash_on_hands',
    ],
  }

  const pdfStyle = StyleSheet.create({
    body: {
      paddingTop: 15,
      paddingBottom: 45,
      paddingHorizontal: 15,
    },

    headerView: {
      display: 'flex',
      flexDirection: 'row',
      borderTop: '1px solid #EEE',
      paddingTop: 8,
      paddingBottom: 8,
      textAlign: 'center',
      fontSize: '6.01px',
      fontWeight: 600,
      color: '#3E3E3E',
      backgroundColor: '#F3F3F3',
      borderRadius: '5px',
    },
    rowView: {
      display: 'flex',
      flexDirection: 'row',
      borderTop: '1px solid #EEE',
      paddingTop: 8,
      paddingBottom: 8,
      textAlign: 'center',
      fontSize: '6px',
      color: '#3E3E3E',
    },
    footer: {
      flexDirection: 'column',
      position: 'absolute',
      bottom: 10,
      left: 10,
    },
  })

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const ShowSnackBar = (
    message,
    variant: VariantType,
    duration,
    key?: string
  ) => {
    enqueueSnackbar(<div>{message}</div>, {
      variant: variant,
      persist: false,
      anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
      style: {
        flexDirection: 'row',
        maxWidth: '50px',
        marginLeft: '-150px',
      },
      autoHideDuration: 4000,
      key: key,
      action: (key) => (
        <IconButton
          color="inherit"
          aria-label="dismiss"
          onClick={() => closeSnackbar(key)}
        >
          <CloseIcon />
        </IconButton>
      ),
    })
    return key
  }

  const date = Date().split(' ')
  const monthConverter = (month: any) => {
    const monthArray = [
      ['Jan', '01'],
      ['Feb', '02'],
      ['Mar', '03'],
      ['Apr', '04'],
      ['May', '05'],
      ['Jun', '06'],
      ['Jul', '07'],
      ['Aug', '08'],
      ['Sep', '09'],
      ['Oct', '10'],
      ['Nov', '11'],
      ['Dec', '12'],
    ]
    for (let i = 0; i < monthArray.length; i++) {
      if (monthArray[i][0] == month) return monthArray[i][1]
    }
  }
  const formatedDateTime = `${monthConverter(date[1])}/${date[2]}/${date[3]}`

  const getHospitalName = (data) => {
    let searchParams = new URLSearchParams(window.location.search)
    searchParams.set('type', 'Hospital')
    searchParams.set('id', data?.definitive_id)
    searchParams.set('state', data?.state)
    searchParams.set('provider', data?.hospital_name)
    const newurl =
      window.location.origin +
      window.location.pathname +
      '?' +
      searchParams.toString()
    return (
      <a
        href={`${newurl.toString()}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Text>{data?.hospital_name}</Text>
      </a>
    )
  }
  const MyDocument = () => (
    <Document>
      <Page size="A4" style={pdfStyle.body}>
        <View wrap fixed style={{ paddingBottom: '10px' }}>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              paddingBottom: '10px',
            }}
          >
            <Image
              src={dprlogo}
              style={{
                width: '74px',
                height: '45px',
                padding: '5px',
              }}
            ></Image>

            <View
              style={{
                borderRight: '1px solid #CCCCCC',
                height: '30px',
                marginTop: '8px',
                marginLeft: '10px',
              }}
            ></View>

            <Image
              src={panaromalogo}
              style={{
                width: '100px',
                height: '30px',
                marginLeft: '20px',
                marginTop: '8px',
                padding: 5,
              }}
            ></Image>

            <Text
              style={{
                fontSize: '20px',
                color: '#000000',
                marginLeft: 'auto',
                marginTop: '15px',
              }}
            >
              CLIENT INSIGHTS
            </Text>
          </View>

          <View
            style={{
              borderBottom: '2px solid #4CAF50',
              width: '100%',
              marginBottom: '20px',
              marginTop: 10,
            }}
          ></View>
        </View>

        <View
          wrap
          style={{
            backgroundColor: '#E8F5E9',
            border: '1px solid #E8F5E9',
            height: 'auto',
            width: '100%',
            borderRadius: 8,
          }}
        >
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <View>
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: 400,
                  color: '#3E3E3E',
                  padding: 5,
                  marginTop: '5px',
                  marginLeft: '10px',
                }}
              >
                {' '}
                Provider Type
              </Text>

              <Text
                style={{
                  fontSize: 13,
                  fontWeight: 'bold',
                  color: '#3E3E3E',
                  padding: 5,
                  marginTop: '-5px',
                  marginLeft: '13px',
                }}
              >
                {type}{' '}
              </Text>
            </View>

            <View>
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: 400,
                  color: '#3E3E3E',
                  padding: 5,
                  marginTop: '5px',
                  marginLeft: '40px',
                }}
              >
                {' '}
                State
              </Text>

              <Text
                style={{
                  fontSize: 13,
                  fontWeight: 'bold',
                  color: '#3E3E3E',
                  padding: 5,
                  marginLeft: '42px',
                  marginTop: '-5px',
                }}
              >
                {state}{' '}
              </Text>
            </View>

            <View
              style={{
                marginLeft: 15,
              }}
            >
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: 400,
                  color: '#3E3E3E',
                  padding: 6,
                  marginLeft: 25,
                  marginTop: '5px',
                }}
              >
                {' '}
                Provider
              </Text>

              <Text
                style={{
                  fontSize: 13,
                  fontWeight: 'bold',
                  color: '#3E3E3E',
                  padding: 5,
                  marginTop: '-5px',
                  marginLeft: 27,
                  width: '255px',
                }}
              >
                {provider?.split('')}{' '}
              </Text>
            </View>
          </View>
        </View>

        <View
          style={{
            backgroundColor: '#FAFAFA',
            border: '1px solid #E8F5E9',
            height: 250,
            width: '100%',
            borderRadius: 8,
            marginTop: 23,
            paddingBottom: 5,
          }}
        >
          <View>
            <Text
              style={{
                color: '#22577A',
                fontSize: 14,
                fontWeight: 'bold',
                padding: 8,
                marginTop: '3px',
              }}
            >
              {props.provider_data?.hospital_name}
            </Text>
            <View style={{ flexDirection: 'row' }}>
              <View
                style={{
                  width: '20px',
                  height: '20px',
                  marginTop: '5px',
                  marginLeft: '10px',
                }}
              >
                <Svg width="20" height="20" viewBox="0 0 30 30">
                  <Path
                    d="M14.36 12C14.44 11.34 14.5 10.68 14.5 10C14.5 9.32 14.44 8.66 14.36 8H17.74C17.9 8.64 18 9.31 18 10C18 10.69 17.9 11.36 17.74 12M12.59 17.56C13.19 16.45 13.65 15.25 13.97 14H16.92C15.96 15.65 14.43 16.93 12.59 17.56ZM12.34 12H7.66C7.56 11.34 7.5 10.68 7.5 10C7.5 9.32 7.56 8.65 7.66 8H12.34C12.43 8.65 12.5 9.32 12.5 10C12.5 10.68 12.43 11.34 12.34 12ZM10 17.96C9.17 16.76 8.5 15.43 8.09 14H11.91C11.5 15.43 10.83 16.76 10 17.96ZM6 6H3.08C4.03 4.34 5.57 3.06 7.4 2.44C6.8 3.55 6.35 4.75 6 6ZM3.08 14H6C6.35 15.25 6.8 16.45 7.4 17.56C5.57 16.93 4.03 15.65 3.08 14ZM2.26 12C2.1 11.36 2 10.69 2 10C2 9.31 2.1 8.64 2.26 8H5.64C5.56 8.66 5.5 9.32 5.5 10C5.5 10.68 5.56 11.34 5.64 12M10 2.03C10.83 3.23 11.5 4.57 11.91 6H8.09C8.5 4.57 9.17 3.23 10 2.03ZM16.92 6H13.97C13.65 4.75 13.19 3.55 12.59 2.44C14.43 3.07 15.96 4.34 16.92 6ZM10 0C4.47 0 0 4.5 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0Z"
                    fill="#3E3E3E"
                  />
                </Svg>
              </View>
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: 500,
                  color: 'blue',
                  paddingLeft: 10,
                  marginTop: '5px',
                  textDecoration: 'underline',
                }}
              >
                {props.provider_data?.website}
              </Text>
            </View>
            <View style={{ flexDirection: 'row' }}>
              <View
                style={{
                  height: '20px',
                  width: '20px',
                  marginTop: '10px',
                  marginLeft: '10px',
                }}
              >
                <Svg width="20" height="20" viewBox="0 0 30 30">
                  <Path
                    d="M17 12.5C15.8 12.5 14.5 12.3 13.4 11.9C13.3 11.9 13.2 11.9 13.1 11.9C12.8 11.9 12.6 12 12.4 12.2L10.2 14.4C7.4 12.9 5 10.6 3.6 7.8L5.8 5.6C6.1 5.3 6.2 4.9 6 4.6C5.7 3.5 5.5 2.2 5.5 1C5.5 0.5 5 0 4.5 0H1C0.5 0 0 0.5 0 1C0 10.4 7.6 18 17 18C17.5 18 18 17.5 18 17V13.5C18 13 17.5 12.5 17 12.5ZM2 2H3.5C3.6 2.9 3.8 3.8 4 4.6L2.8 5.8C2.4 4.6 2.1 3.3 2 2ZM16 16C14.7 15.9 13.4 15.6 12.2 15.2L13.4 14C14.2 14.2 15.1 14.4 16 14.4V16Z"
                    fill="#3E3E3E"
                  />
                </Svg>
              </View>
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: 500,
                  color: '#3E3E3E',
                  paddingLeft: 10,
                  paddingTop: 10,
                }}
              >
                {props.provider_data?.phone}
              </Text>
            </View>
            <View style={{ flexDirection: 'row' }}>
              <View
                style={{
                  height: '20px',
                  width: '20px',
                  marginTop: '10px',
                  marginLeft: '10px',
                }}
              >
                <Svg width="20" height="20" viewBox="0 0 30 30">
                  <Path
                    d="M7 4.5C7.66304 4.5 8.29893 4.76339 8.76777 5.23223C9.23661 5.70107 9.5 6.33696 9.5 7C9.5 7.3283 9.43534 7.65339 9.3097 7.95671C9.18406 8.26002 8.99991 8.53562 8.76777 8.76777C8.53562 8.99991 8.26002 9.18406 7.95671 9.3097C7.65339 9.43534 7.3283 9.5 7 9.5C6.33696 9.5 5.70107 9.23661 5.23223 8.76777C4.76339 8.29893 4.5 7.66304 4.5 7C4.5 6.33696 4.76339 5.70107 5.23223 5.23223C5.70107 4.76339 6.33696 4.5 7 4.5ZM7 0C8.85652 0 10.637 0.737498 11.9497 2.05025C13.2625 3.36301 14 5.14348 14 7C14 12.25 7 20 7 20C7 20 0 12.25 0 7C0 5.14348 0.737498 3.36301 2.05025 2.05025C3.36301 0.737498 5.14348 0 7 0ZM7 2C5.67392 2 4.40215 2.52678 3.46447 3.46447C2.52678 4.40215 2 5.67392 2 7C2 8 2 10 7 16.71C12 10 12 8 12 7C12 5.67392 11.4732 4.40215 10.5355 3.46447C9.59785 2.52678 8.32608 2 7 2Z"
                    fill="#3E3E3E"
                  />
                </Svg>
              </View>
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: 500,
                  color: '#3E3E3E',
                  paddingLeft: 10,
                  paddingTop: 12,
                }}
              >
                {props.provider_data?.address.split(', ,').join(',')}
              </Text>
            </View>
          </View>

          <View
            style={{
              marginTop: 25,
              display: 'flex',
              //   alignContent: 'space-between',
              flexDirection: 'column',
            }}
          >
            <View
              style={{
                marginTop: 25,
                display: 'flex',
                //   alignContent: 'space-between',
                flexDirection: 'row',
              }}
            >
              <View style={{ flex: 1 }}>
                <Text
                  style={{
                    fontSize: 10,
                    fontWeight: 500,
                    paddingLeft: 8,
                    color: '#3E3E3E',
                    paddingTop: 5,
                  }}
                >
                  Number of Hospital
                </Text>
                <Text
                  style={{
                    fontSize: 12,
                    fontWeight: 900,
                    paddingLeft: 8,
                    color: '#3E3E3E',
                    paddingTop: 5,
                  }}
                >
                  {props.provider_data?.number_of_hospitals
                    ? props.provider_data?.number_of_hospitals
                    : '-'}
                </Text>
              </View>

              <View style={{ flex: 1 }}>
                <Text
                  style={{
                    fontSize: 10,
                    fontWeight: 500,
                    color: '#3E3E3E',
                    paddingTop: 5,
                  }}
                >
                  Number of Urgent Care Clinics
                </Text>
                <Text
                  style={{
                    fontSize: 12,
                    fontWeight: 900,
                    color: '#3E3E3E',
                    paddingTop: 5,
                  }}
                >
                  {props.provider_data?.number_of_urgent_care_clinics
                    ? props.provider_data?.number_of_urgent_care_clinics
                    : '-'}
                </Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text
                  style={{
                    fontSize: 10,
                    fontWeight: 500,

                    color: '#3E3E3E',
                    paddingTop: 5,
                  }}
                >
                  Number of Ambulatory Surgery Centers
                </Text>
                <Text
                  style={{
                    fontSize: 12,
                    fontWeight: 900,
                    color: '#3E3E3E',
                    paddingTop: 5,
                  }}
                >
                  {props.provider_data?.number_of_ambulatory_surgery_centers
                    ? props.provider_data?.number_of_ambulatory_surgery_centers
                    : '-'}
                </Text>
              </View>
            </View>
            <View
              style={{
                marginTop: 25,
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <View style={{ flex: 1 }}>
                <Text
                  style={{
                    fontSize: 10,
                    fontWeight: 500,
                    paddingLeft: 8,
                    color: '#3E3E3E',
                    paddingTop: 5,
                  }}
                >
                  Number of Imaging Centers
                </Text>
                <Text
                  style={{
                    fontSize: 12,
                    fontWeight: 900,
                    paddingLeft: 8,
                    color: '#3E3E3E',
                    paddingTop: 5,
                  }}
                >
                  {props.provider_data?.number_of_imaging_centers
                    ? props.provider_data?.number_of_imaging_centers
                    : '-'}
                </Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text
                  style={{
                    fontSize: 10,
                    fontWeight: 500,
                    color: '#3E3E3E',
                    paddingTop: 5,
                  }}
                >
                  Number of Physician Groups
                </Text>
                <Text
                  style={{
                    fontSize: 12,
                    fontWeight: 900,
                    color: '#3E3E3E',
                    paddingTop: 5,
                  }}
                >
                  {props.provider_data?.number_of_physician_groups
                    ? props.provider_data?.number_of_physician_groups
                    : '-'}
                </Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text
                  style={{
                    fontSize: 10,
                    fontWeight: 500,
                    color: '#3E3E3E',
                    paddingTop: 5,
                  }}
                >
                  Number of Skilled Nursing Facilities
                </Text>
                <Text
                  style={{
                    fontSize: 12,
                    fontWeight: 900,
                    color: '#3E3E3E',
                    paddingTop: 5,
                  }}
                >
                  {props.provider_data?.number_of_skilled_nursing_facilities
                    ? props.provider_data?.number_of_skilled_nursing_facilities
                    : '-'}
                </Text>
              </View>
            </View>
          </View>
        </View>

        <View
          style={{
            marginTop: '20px',
          }}
        >
          <View style={pdfStyle.headerView} fixed>
            {tableData['column1'].map((c) => (
              <Text
                style={{
                  width: `${90 / tableData['column1'].length}%`,
                  marginLeft: `${10 / tableData['column1'].length}%`,
                  textAlign: 'left',
                }}
              >
                {c}
              </Text>
            ))}
          </View>
          {props?.dataSource?.DEFINITIVEHEALTHCARE_VW_HOSPITAL_INFO.map(
            (rowData) => (
              <>
                <View style={pdfStyle.rowView} wrap={false}>
                  {tableData['column2'].map((c) => (
                    <Text
                      wrap={false}
                      style={{
                        width: `${90 / tableData['column2'].length}%`,
                        marginLeft: `${10 / tableData['column2'].length}%`,
                        textAlign: 'left',
                      }}
                    >
                      {rowData[c]
                        ? c === 'hospital_name'
                          ? getHospitalName(rowData)
                          : c === 'address'
                            ? rowData[c].split(', ,').join(',')
                            : c === 'total_performance_score' ||
                              c === 'avg_age_of_plant'
                              ? parseFloat(rowData[c]).toFixed(2)
                              : c === 'net_operating_profit_margin' ?
                                (parseFloat(rowData[c]) * 100).toFixed(2)
                                : c === 'net_patient_revenue' ||
                                  c === 'capital_expenditures'
                                  ? `$${rowData[c]}`
                                  : rowData[c]
                        : '-'}
                    </Text>
                  ))}
                </View>
              </>
            )
          )}
        </View>

        <View wrap fixed style={pdfStyle.footer}>
          <View
            style={{
              border: '1px solid #D9D9D9',
              marginTop: '10px',
              width: '500px',
              height: '1px',
              left: '25px',
            }}
          ></View>
          <View
            style={{ flexDirection: 'row', fontSize: 8, marginTop: '10px' }}
          >
            <Text style={{ marginLeft: '30px', fontSize: 10 }}>
              Export Date : {formatedDateTime}
            </Text>
            <Text
              style={{
                fontWeight: 800,
                marginLeft: '70px',
                fontSize: 10,
              }}
            >
              Confidential: Do Not Distribute Outside of DPR
            </Text>
            <Text
              style={{ marginLeft: '90px', fontSize: 10 }}
              render={({ pageNumber }) => `Page ${pageNumber}`}
            />
          </View>
        </View>
      </Page>
    </Document>
  )

  return (
    <MenuItem
      style={{ fontSize: 14, fontWeight: 500, color: 'black' }}
      onClick={() => {
        ShowSnackBar('Generating Export File, Please wait !!', 'info', 'close')
        const pdfBlob = pdf(MyDocument()).toBlob()
        pdfBlob.then((data) => {
          const blobObj = new Blob([data], { type: 'application/pdf' })
          saveAs(blobObj, 'Healthcare Panorama - Health system Insights')
        })
      }}
    >
      PDF
    </MenuItem>
  )
}
