import { Autocomplete, Box, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Skeleton, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ButtonComponent } from "../Home/ButtonComponent";
import { IState } from "../../../models/IState";
import { useGetHealthsystemsLazyQuery, useGetProvidersLazyQuery, useGetStatesQuery } from "../../../__generated__/graphql";
import { IProviderInfo } from "../../../models/IByProvider";
import { appInsights } from "../../../providers/appInsightProvider";

export const ClientInsights: React.FC = () => {

  const [selectedState, setSelectedState] = useState<IState | null>(null);
  const [type, setType] = useState<string>("");
  const [selectedProvider, setSelectedProvider] = useState<IProviderInfo | null>(null);
  const { data: getStateData, loading: loadingState, error: errorState } = useGetStatesQuery();
  const [loadProviderHealthSystem, { data: getProvidersHealthSystemData, loading: loadingProvidersHealthSystem, error: errorProviderHealthSystem }] = useGetHealthsystemsLazyQuery({
    variables: {
      state: selectedState?.state,
    },
  });

  const [loadProvider, { data: getProvidersData, loading: loadingProviders, error: errorProvider }] = useGetProvidersLazyQuery({
    variables: {
      state: selectedState?.state,
      selected_type: type
    },
  });


  useEffect(() => {
    if (errorState instanceof Error) {
      appInsights.trackException({ exception: errorState, error: new Error("Failed to fetch States.") })
    }
  }, [errorState])

  useEffect(() => {
    if (errorProvider instanceof Error) {
      appInsights.trackException({ exception: errorProvider, error: new Error("Failed to fetch Hospitals.") })
    }
  }, [errorProvider])

  useEffect(() => {
    if (errorProviderHealthSystem instanceof Error) {
      appInsights.trackException({ exception: errorProviderHealthSystem, error: new Error("Failed to fetch Health Systems.") })
    }
  }, [errorProviderHealthSystem])


  useEffect(() => {
    if (selectedState && selectedState?.state && type) {
      if (type === "Hospital")
        loadProvider();
      else
        loadProviderHealthSystem();
    }
  }, [selectedState])

  let state_data = getStateData && getStateData.OTHERDATA_ZIPCODE_MAPPING && getStateData.OTHERDATA_ZIPCODE_MAPPING.length ?
    getStateData?.OTHERDATA_ZIPCODE_MAPPING : []

  state_data = state_data.slice().sort((a: any, b: any) => {
    return (a.statecode.toString()).localeCompare(b.statecode.toString())
  })

  let provider_data: any = [];

  if (type === "Hospital" && !loadingProviders) {
    if (getProvidersData && getProvidersData.DEFINITIVEHEALTHCARE_VW_HOSPITAL_INFO && getProvidersData.DEFINITIVEHEALTHCARE_VW_HOSPITAL_INFO.length) {
      provider_data = getProvidersData.DEFINITIVEHEALTHCARE_VW_HOSPITAL_INFO
    }
  }
  else if (type === "Health System" && !loadingProvidersHealthSystem) {
    if (getProvidersHealthSystemData && getProvidersHealthSystemData.DEFINITIVEHEALTHCARE_VW_HEALTHSYSTEMS && getProvidersHealthSystemData.DEFINITIVEHEALTHCARE_VW_HEALTHSYSTEMS.length)
      provider_data = getProvidersHealthSystemData.DEFINITIVEHEALTHCARE_VW_HEALTHSYSTEMS;
  }

  console.log(provider_data)
  let unique_providers = [];
  let unique_provider_info = [];

  if (provider_data && provider_data.length) {
    for (let index = 0; index < provider_data.length; index++) {
      const element: any = provider_data[index];
      //@ts-ignore
      if (unique_providers.indexOf(element?.hospital_name as any) === -1) {
        //@ts-ignore
        unique_providers.push(element?.hospital_name as any);
        //@ts-ignore
        unique_provider_info.push(element);
      }
    }
    unique_provider_info = unique_provider_info.slice().sort((a: any, b: any) => {
      return (a.hospital_name.toString()).localeCompare(b.hospital_name.toString())
    })
  }

  return (
    <React.Fragment>
      <Box sx={{ height: '100%' }} id="client-insights-box">
        <Grid direction={"column"} sx={{ height: "calc(100% - 95px)" }} container >
          <Grid item lg={8} md={7} xl={8} >
            <Stack spacing={3} style={{
              padding: "24px"
            }}>
              <FormControl>
                <FormLabel style={{ color: "rgb(80 80 80)", fontWeight: "bold", fontSize: "14px", marginBottom: "15px" }}>Provider Type</FormLabel>
                <RadioGroup row value={type}
                  onChange={(event, value) => {
                    setType(value);
                    setSelectedState(null);
                    setSelectedProvider(null);
                  }} sx={{
                    ".MuiFormControlLabel-label": {
                      color: "#22577A"
                    }
                  }}>
                  <FormControlLabel sx={{
                    ".MuiFormControlLabel-label": {
                      color: "#22577A", fontWeight: "600"
                    }
                  }} value="Health System" control={<Radio style={{ color: "#22577A" }} />} label="Health System" />
                  <FormControlLabel sx={{
                    ".MuiFormControlLabel-label": {
                      color: "#22577A", fontWeight: "600"
                    }
                  }} value="Hospital" control={<Radio style={{ color: "#22577A" }} />} label="Hospital" />
                </RadioGroup>
              </FormControl>
              <>
                {
                  loadingState ?
                    renderSkeleton()
                    :
                    type && state_data ?
                      renderStateDropdown(type, state_data, selectedState, setSelectedState, setSelectedProvider)
                      : renderDisableStateDropdown()
                }

                {
                  loadingProviders || loadingProvidersHealthSystem ?
                    renderSkeleton()
                    :
                    selectedState && unique_provider_info ?
                      renderProviderDropdown(unique_provider_info, selectedProvider, setSelectedProvider)
                      :
                      renderDisableProviderDropdown()
                }
              </>
            </Stack>
          </Grid>
          <Grid item lg md xl>
          </Grid>
          <Grid item lg={2} md={3} xl={2} style={{ display: 'flex', flexDirection: "column", justifyContent: "flex-end" }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: "center", width: '42vw' }}>
              <ButtonComponent
                disable={selectedState && type && selectedProvider ? false : true}
                url={`/client_insights/Summary?type=${type}&state=${selectedState?.state}&provider=${selectedProvider?.hospital_name}&id=${selectedProvider?.definitive_id}`}
                clear={() => { setSelectedState(null); setSelectedProvider(null); setType(""); }} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment >
  );
}
function renderDisableProviderDropdown() {
  return <FormControl fullWidth>
    <Autocomplete
      id="select-provider-disable" disabled
      options={[]}
      renderInput={(params) => <TextField
        {...params}
        InputProps={{
          ...params.InputProps,
          style: { padding: "6px 30px 6px 6px" },
        }}
        size="small" margin='dense' InputLabelProps={{ style: { fontSize: "14px" } }}
        variant="outlined" label="Select Provider" />} />
  </FormControl>;
}

function renderProviderDropdown(provider_data: { definitive_id?: string | null | undefined; hospital_name?: string | null | undefined; }[], selectedProvider: IProviderInfo | null, setSelectedProvider: React.Dispatch<React.SetStateAction<IProviderInfo | null>>) {
  return <FormControl fullWidth>
    <Autocomplete
      id="select-provider"
      key="ProviderAutocomplete"
      disableClearable
      options={provider_data as IProviderInfo[]}
      noOptionsText="No provider found"
      value={selectedProvider as IProviderInfo}
      onChange={(e, selectProvider: IProviderInfo) => {
        setSelectedProvider(selectProvider);
      }}
      getOptionLabel={(option: IProviderInfo) => `${option?.hospital_name}`}
      renderInput={(params) => <TextField
        {...params}
        InputProps={{
          ...params.InputProps,
          style: { padding: "6px 30px 6px 6px" },
        }}
        size="small" margin='dense' InputLabelProps={{ style: { fontSize: "14px" } }}
        variant="outlined" label="Select Provider" />} />
  </FormControl>;
}

function renderDisableStateDropdown() {
  return <FormControl fullWidth>
    <Autocomplete
      id="select-state-disable" disabled
      options={[]}
      renderInput={(params) => <TextField
        {...params}
        InputProps={{
          ...params.InputProps,
          style: { padding: "6px 30px 6px 6px" },
        }}
        size="small" margin='dense' InputLabelProps={{ style: { fontSize: "14px" } }}
        variant="outlined" label="Select State" />} />
  </FormControl>;
}

function renderStateDropdown(type: string, state_data: { __typename?: "OTHERDATA_ZIPCODE_MAPPING" | undefined; state?: string | null | undefined; statecode?: string | null | undefined; }[], selectedState: IState | null, setSelectedState: React.Dispatch<React.SetStateAction<IState | null>>, setSelectedProvider: React.Dispatch<React.SetStateAction<IProviderInfo | null>>) {
  return <FormControl fullWidth>
    <Autocomplete
      id="select-state"
      key="StateAutocomplete"
      disableClearable
      options={type ? state_data as IState[] : []}
      noOptionsText="No states found"
      value={selectedState as IState}
      onChange={(e, selectState: IState) => {
        setSelectedState(selectState);
        setSelectedProvider(null);
      }}
      getOptionLabel={(option: IState) => `${option?.statecode} - ${option?.state}`}
      renderInput={(params) => <TextField
        {...params}
        InputProps={{
          ...params.InputProps,
          style: { padding: "6px 30px 6px 6px" },
        }} InputLabelProps={{ style: { fontSize: "14px" } }}
        size="small" margin='dense'
        variant="outlined" label="Select State" />} />
  </FormControl>;
}

function renderSkeleton() {
  return <Typography component="div" key="h2" variant="h2">
    <Skeleton />
  </Typography>;
}

