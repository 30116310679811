import { Grid } from '@mui/material'
import React, { createContext, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { TopHeader } from './TopHeader'
import { HeathSystem } from './HealthcareSystem/HealthSystem'
import { Hospitals } from './Hospitals/Hospitals'

export const Main: React.FC = () => {
  const search = useLocation().search
  const type = new URLSearchParams(search).get('type')
  let location = useLocation()
  const [tabType, setTabType] = useState('')

  useEffect(() => {
    setTabType(location.pathname)
  }, [location])

  return (
    <React.Fragment>
      <Grid
        container
        style={{ background: 'rgb(240 240 240)' }}
        direction="column"
      >
        <TopHeader />
        {type === 'Hospital' ? <Hospitals /> : <HeathSystem />}
      </Grid>
    </React.Fragment>
  )
}
