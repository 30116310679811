import { IDemographicsTable } from "../models/IDemographicsTable";

export const textColor = (rate) => {
    if (rate) {
        if (rate === 1)
            return "#FF0707";
        else if (rate === 2)
            return "#FB743F";
        else if (rate === 3)
            return "#FFB300";
        else if (rate === 4)
            return "#73E793";
        else if (rate === 5)
            return "#4CAF50";
    }
    else return "#212121"

}

export const performanceText = (rate, textTransform?) => {
    if (rate) {
        if (rate === 1)
            return textTransform === "capitalize" ? "Very Poor" : " (VERY POOR)";
        if (rate === 2)
            return textTransform === "capitalize" ? "Poor" : " (POOR)";
        if (rate === 3)
            return textTransform === "capitalize" ? "Fair" : " (FAIR)";
        if (rate === 4)
            return textTransform === "capitalize" ? "Good" : " (GOOD)";
        if (rate === 5)
            return textTransform === "capitalize" ? "Excellent" : " (EXCELLENT)";
        else return " (N/A)";
    }
    else return " (N/A)";
}

export const performanceTextPDF = (rate, textTransform?) => {
    if (rate) {
        if (rate === 1)
            return textTransform === "capitalize" ? "Very Poor" : "VERY POOR";
        if (rate === 2)
            return textTransform === "capitalize" ? "Poor" : "POOR";
        if (rate === 3)
            return textTransform === "capitalize" ? "Fair" : "FAIR";
        if (rate === 4)
            return textTransform === "capitalize" ? "Good" : "GOOD";
        if (rate === 5)
            return textTransform === "capitalize" ? "Excellent" : "EXCELLENT";
        else return "N/A";
    }
    else return "N/A";
}


export const performanceTextExcel = (rate, textTransform?) => {
    rate = Number(rate)
    if (rate) {
        if (rate === 1)
            return textTransform === "capitalize" ? "Very Poor" : "Very Poor";
        if (rate === 2)
            return textTransform === "capitalize" ? "Poor" : "Poor";
        if (rate === 3)
            return textTransform === "capitalize" ? "Fair" : "Fair";
        if (rate === 4)
            return textTransform === "capitalize" ? "Good" : "Good";
        if (rate === 5)
            return textTransform === "capitalize" ? "Excellent" : "Excellent";
        else return "N/A";
    }
    else return "N/A";
}

export const backgroundColor = (rate) => {
    if (rate) {
        if (rate === 1)
            return "#FB743F0D";
        else if (rate === 2)
            return "#FB743F0D";
        else if (rate === 3)
            return "#FFB3000D";
        else if (rate === 4)
            return "#73E7930D";
        else if (rate === 5)
            return "#4CAF500D";
    }
    else return "#eee"

}

export const getDemographicsTableData = (data) => {
    let DemographicsTableData: IDemographicsTable[] = [];

    let get_age_range = ["0_4", "5_9", "10_14", "15_19", "20_24", "25_29", "30_34", "35_39", "40_44", "45_49", "50_54", "55_59", "60_64", "65_69", "70_74", "75_79", "80_84", "over_85"]
    let age_label_array = ["0-4", "5-9", "10-14", "15-19", "20-24", "25-29", "30-34", "35-39", "40-44", "45-49", "50-54", "55-59", "60-64", "65-69", "70-74", "75-79", "80-84", "Above 85"]

    if (data && Object.keys(data).length) {
        for (let index = 0; index < age_label_array.length; index++) {
            const element = age_label_array[index];
            DemographicsTableData.push({
                age_cohort: element,
                current_pop: parseFloat(data["curr_" + get_age_range[index]]).toFixed(0),
                pop_growth: data["growth_" + get_age_range[index]] ? parseFloat(data["growth_" + get_age_range[index]]).toFixed(1) : parseFloat(data["growht_" + get_age_range[index]]).toFixed(1),
                proj_pop: parseFloat(data["proj_" + get_age_range[index]]).toFixed(0)
            })
        }
    }
    return DemographicsTableData;
}

export const numFormatter = (num) => {
    if (num > 999 && num < 1000000) {
        return (num / 1000).toFixed(1) + 'K'; // convert to K for number from > 1000 < 1 million 
    } else if (num > 1000000) {
        return (num / 1000000).toFixed(1) + 'M'; // convert to M for number from > 1 million 
    } else if (num < 900) {
        return num; // if value < 1000, nothing to do
    }
}


export const calculatePercentile = (arr, p) => {
    let n = arr.length;
    let k = (n - 1) * p;
    let f = Math.floor(k);
    let c = Math.ceil(k);

    if (f === c) {
        return arr[f];
    }

    let d0 = arr[f] * (c - k);
    let d1 = arr[c] * (k - f);

    return d0 + d1;
}