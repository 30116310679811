import { Grid } from '@mui/material'
import React from 'react'
import { AzureMapsProvider } from 'react-azure-maps'
import GeneralInfoMap from '../../CommonComponents/Maps/GeneralInfoMap'

export const HiddenComponentClient: React.FC = () => {
    return <div style={{ visibility: 'hidden', }}>
        <Grid
            item
            lg={0}
            md={0}
            sm={0}
            xs={0}
            xl={0}
            p="10px"
            height={'60vh'}
            overflow="hidden"
        >
            <AzureMapsProvider>
                <GeneralInfoMap />
            </AzureMapsProvider>
        </Grid>
    </div>
}
