import React, { useEffect, useState } from 'react'
import {
  DataGrid,
  Sorting,
  HeaderFilter,
  Column,
  FilterRow,
  Selection,
  Scrolling,
} from 'devextreme-react/data-grid'
import 'devextreme/dist/css/dx.common.css'
import { Box, Skeleton } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { useGetCountyTableDataForCityLazyQuery, useGetCountyTableDataForCountyLazyQuery, useGetCountyTableDataForZipCodeAndRadiusLazyQuery, useGetCountyTableDataForZipCodeLazyQuery } from '../../../../__generated__/graphql'
import { appInsights } from '../../../../providers/appInsightProvider'
import { renderTitleHeader } from '../../../CommonComponents/RenderTitleHeader/RenderTitleHeader'

interface ICountyProps {
  setState: Function
  setCounty: Function
  countyName: string
  stateName: string
  isFilter: boolean
}

export const CountydataTable: React.FC<ICountyProps> = (props: ICountyProps) => {
  const search = useLocation().search
  const dataGrid = React.useRef(null)
  const city = new URLSearchParams(search).get('city');
  const county = new URLSearchParams(search).get('county');
  const zip_code = new URLSearchParams(search).get('zipcode');
  const radius = new URLSearchParams(search).get('radius');

  const [dataSource, setDataSource] = useState([]);
  const [healthText, setHealthText] = useState("");

  const [loadCountyTableDataForCity, {
    data: CountyTableDataForCity,
    loading: CountyTableDataForCityLoading,
    error: CountyTableDataForCityError,
  }] = useGetCountyTableDataForCityLazyQuery({
    variables: {
      city
    },
    fetchPolicy: 'network-only'
  })

  const [loadCountyTableDataForZipCode, {
    data: CountyTableDataForZipCode,
    loading: CountyTableDataForZipCodeLoading,
    error: CountyTableDataForZipCodeError,
  }] = useGetCountyTableDataForZipCodeLazyQuery({
    variables: {
      zipcode: zip_code
    },
    fetchPolicy: 'network-only'
  })


  let county_data: string[] = [];
  if (county) {
    let counties_array = county.split(",");
    for (let i = 0; i < counties_array.length; i++) {
      const element = counties_array[i];
      let splitted_val = element.split(" - ");
      let string_val: string = splitted_val[1].trim() + splitted_val[0].trim();
      county_data.push(string_val);
    }
  }

  const [loadCountyTableDataForCounty, {
    data: CountyTableDataForCounty,
    loading: CountyTableDataForCountyLoading,
    error: CountyTableDataForCountyError,
  }] = useGetCountyTableDataForCountyLazyQuery({
    variables: {
      county: props.countyName && props.isFilter === true ? props.countyName : county_data.join(",")
    },
    fetchPolicy: 'network-only'
  })

  const [loadCountyTableDataForZipCodeAndRadius, {
    data: CountyTableDataForZipCodeAndRadius,
    loading: CountyTableDataForZipCodeAndRadiusLoading,
    error: CountyTableDataForZipCodeAndRadiusError,
  }] = useGetCountyTableDataForZipCodeAndRadiusLazyQuery({
    variables: {
      zipcode: zip_code,
      radius: radius ? parseInt(radius) : 1
    },
    fetchPolicy: 'network-only'
  })

  useEffect(() => {
    if (CountyTableDataForCityError instanceof Error) {
      appInsights.trackException({ exception: CountyTableDataForCityError, error: new Error("Failed to fetch County Table Data for City.") })
    }
    else if (CountyTableDataForZipCodeError instanceof Error) {
      appInsights.trackException({ exception: CountyTableDataForZipCodeError, error: new Error("Failed to fetch County Table Data for Zip code.") })
    }
    else if (CountyTableDataForCountyError instanceof Error) {
      appInsights.trackException({ exception: CountyTableDataForCountyError, error: new Error("Failed to fetch County Table Data for county.") })
    }
    else if (CountyTableDataForZipCodeAndRadiusError instanceof Error) {
      appInsights.trackException({ exception: CountyTableDataForZipCodeAndRadiusError, error: new Error("Failed to fetch County Table Data for zipcode and radius.") })
    }
  }, [CountyTableDataForCityError, CountyTableDataForZipCode, CountyTableDataForCountyError, CountyTableDataForZipCodeAndRadiusError])

  useEffect(() => {
    if (city) {
      loadCountyTableDataForCity()
    }
    else if (zip_code && radius) {
      loadCountyTableDataForZipCodeAndRadius()
    }
    else if (zip_code) {
      loadCountyTableDataForZipCode()
    }
    else if (county) {
      loadCountyTableDataForCounty()
    }
  }, [])

  useEffect(() => {
    if (props.countyName && props.isFilter === true)
      loadCountyTableDataForCounty()
  }, [props.countyName])

  const getCountyDataForTable = (county_data) => {
    let rows = ["Poor or Fair Health", "Diabetes Prevalence", "Children in Poverty", "Adult Obesity", "Primary Care Physicians", "Mental Health Providers", "Severe Housing Problems", "Air Pollution – Particulate Matter"]
    let row_map = ["fair_poor_health_pct", "diabetes_prevalence_pct", "pct_children_in_poverty", "pct_adults_w_obesity", "primary_care_physicians_ratio", "mental_health_providers_ratio", "severe_housing_problems_pct_households", "air_pollution_avg_daily_pm25"]
    let tooltip = ["Percentage of adults reporting fair or poor health",
      "Percentage of adults aged 20 and above with diagnosed diabetes",
      "Percentage of people under age 18 in poverty",
      "Percentage of the adult population that reports a body mass index (BMI) greater than or equal to 30",
      "Ratio of population to primary care physicians",
      "Ratio of population to mental health providers",
      " Percentage of households with at least 1 of 4 housing problems: overcrowding, high housing costs, lack of kitchen facilities, or lack of plumbing facilities.",
      "Average daily density of fine particulate matter in micrograms per cubic meter (PM2.5)"]
    let table_data: any = [];
    let san_mateo_data: any = null;
    let california_data: any = null;
    let top_decile_data: any = null;
    let county_name: string = "";
    let state_name: string = "";
    if (county_data && county_data.length) {
      for (let i = 0; i < county_data.length; i++) {
        const element = county_data[i];
        if (element.state === "NATIONAL") {
          top_decile_data = element;
        }
        if (element.county) {
          san_mateo_data = element;
          county_name = element.county;
        }
        if (element.county === null && element.state !== "NATIONAL") {
          california_data = element;
        }
        if (element.state && element.state !== "NATIONAL") {
          state_name = element.state;
        }
      }

      for (let index = 0; index < rows.length; index++) {
        const ele = rows[index];
        let percent_check_index = [4, 5, 7];
        table_data.push({
          type: ele, tooltip: tooltip[index],
          county: county_name, state: state_name,
          san_mateo: percent_check_index.indexOf(index) === -1 && san_mateo_data !== null ? san_mateo_data[row_map[index]] + "%" : san_mateo_data === null ? null : san_mateo_data[row_map[index]],
          california: percent_check_index.indexOf(index) === -1 && california_data !== null ? california_data[row_map[index]] + "%" : california_data === null ? null : california_data[row_map[index]],
          top_decile: percent_check_index.indexOf(index) === -1 && top_decile_data !== null ? top_decile_data[row_map[index]] + "%" : top_decile_data === null ? null : top_decile_data[row_map[index]],
          higher: san_mateo_data !== null && california_data !== null && parseFloat(san_mateo_data[row_map[index]]) === parseFloat(california_data[row_map[index]]) ? "" : san_mateo_data !== null && california_data !== null && parseFloat(san_mateo_data[row_map[index]]) > parseFloat(california_data[row_map[index]]) ? "san_mateo" : "california"
        })
      }
    }
    return table_data;
  }

  useEffect(() => {
    let Table_data: any = []; let healthText: any = ""
    if (CountyTableDataForCity && CountyTableDataForCity?.udf_county_data_tbl_dpr_market && CountyTableDataForCity?.udf_county_data_tbl_dpr_market.length) {
      healthText = CountyTableDataForCity?.udf_county_data_cmt_dpr_market[0]?.cmt;
      Table_data = CountyTableDataForCity?.udf_county_data_tbl_dpr_market;
    }
    if (CountyTableDataForZipCode && CountyTableDataForZipCode?.udf_county_data_tbl_zip && CountyTableDataForZipCode?.udf_county_data_tbl_zip.length) {
      healthText = CountyTableDataForZipCode?.udf_county_data_cmt_zip[0]?.cmt;
      Table_data = CountyTableDataForZipCode?.udf_county_data_tbl_zip;
    }
    if (CountyTableDataForZipCodeAndRadius && CountyTableDataForZipCodeAndRadius.udf_county_data_tbl_zip_radius && CountyTableDataForZipCodeAndRadius.udf_county_data_tbl_zip_radius.length) {
      healthText = CountyTableDataForZipCodeAndRadius?.udf_county_data_cmt_zip_radius[0]?.cmt
      Table_data = CountyTableDataForZipCodeAndRadius.udf_county_data_tbl_zip_radius;
    }
    if (CountyTableDataForCounty && CountyTableDataForCounty?.udf_county_data_tbl_multi_county && CountyTableDataForCounty?.udf_county_data_tbl_multi_county.length) {
      healthText = CountyTableDataForCounty?.udf_county_data_cmt_county[0]?.cmt;
      Table_data = CountyTableDataForCounty?.udf_county_data_tbl_multi_county;
    }
    if (Table_data && Table_data.length) {
      let data: any = getCountyDataForTable(Table_data)
      setDataSource(data);
      setHealthText(healthText);
      if (data && data.length) {
        if (props.isFilter === false) {
          props.setCounty(data[0].county)
          props.setState(data[0].state)
        }
      }
    }

  }, [CountyTableDataForCityLoading, CountyTableDataForZipCodeLoading, CountyTableDataForCountyLoading, CountyTableDataForZipCodeAndRadiusLoading])

  return (
    <Box>
      <Box
        style={{
          background: '#FAFAFA',
          border: '1px solid #E8F5E9',
          borderRadius: '15px',
          padding: '10px',
          marginBottom: '10px',
        }}
      >

        <Box style={{ fontSize: '14px', color: '#22577A', fontWeight: '600' }}>
          How healthy is my county?
        </Box>
        <Box style={{ fontSize: '13px', color: '#3E3E3E', marginTop: '10px' }}>
          {healthText}
        </Box>
      </Box>
      <Box sx={{ height: '100vh', paddingLeft: '7px', paddingBottom: '2px' }}>
        {
          CountyTableDataForCityLoading || CountyTableDataForZipCodeLoading || CountyTableDataForCountyLoading || CountyTableDataForZipCodeAndRadiusLoading ?
            <Skeleton variant="rectangular" width="100%">
              <div style={{ paddingTop: '50%' }} />
            </Skeleton>
            :
            <DataGrid
              dataSource={dataSource}
              keyExpr="type"
              showBorders={true}
              columnResizingMode="widget"
              hoverStateEnabled
              id="county_table_data"
              ref={dataGrid}
              style={{
                height: 'calc(100% - 49vh)',
              }}
              allowColumnResizing
              allowColumnReordering
              columnAutoWidth
            >
              <HeaderFilter visible={true} />
              <Scrolling useNative={true} preloadEnabled={true} />

              <Column
                caption=" "
                dataField="type"
                dataType="string"
                allowFiltering={false}
                allowSorting={false} width={250} minWidth={200}
                cellRender={(data) => {
                  return (
                    renderTitleHeader(data.value, data.data.tooltip)
                  )
                }}
              ></Column>
              <Column
                caption={props.isFilter ? props.countyName.slice(0, -2) : props.countyName}
                dataField="san_mateo"
                cellRender={(data) => {
                  return <div style={{ color: data.data.higher === "san_mateo" ? "rgb(237 9 9)" : "#3E3E3E" }}>{data.value}</div>
                }}
              ></Column>
              <Column
                caption={props.stateName}
                dataField="california"
                cellRender={(data) => {
                  return <div style={{ color: data.data.higher === "california" ? "rgb(237 9 9)" : "#3E3E3E" }}>{data.value}</div>
                }}
              ></Column>
              <Column
                caption="National Average"
                dataField="top_decile"
                dataType="string"
              ></Column>
            </DataGrid>
        }

      </Box>
    </Box>
  )
}

export default CountydataTable
