import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Popover,
  Tab,
  TabProps,
  Tabs,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { MarketInsightsTopHeader } from './MarketInsightsTopHeader'
import { styled } from '@mui/material/styles'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import ManageSearchIcon from '@mui/icons-material/ManageSearch'
import HomeRepairServiceOutlinedIcon from '@mui/icons-material/HomeRepairServiceOutlined'
import PollOutlinedIcon from '@mui/icons-material/PollOutlined'
import ShareIcon from '@mui/icons-material/Share'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import TimelineSharpIcon from '@mui/icons-material/TimelineSharp'
import LanguageSharpIcon from '@mui/icons-material/LanguageSharp'
import { MarketSummary } from './Summary/MarketSummary'
import { ServiceArea } from './ServiceArea/ServiceArea'
import { CountyData } from './CountyData/CountyData'
import { PredectiveNeeds } from './PredectiveNeeds/PredectiveNeeds'
import { DemographicsTab } from './Demographics/DemographicsTab'
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'
import {
  GetPdfDataCityDocument,
  GetPdfDataZipRadiusDocument,
  GetPdfDataZipcodeDocument,
  GetPdfDataCountyDocument,
  GetPredictiveNeedsCityDocument,
  GetPredictiveNeedsZipRadiusDocument,
  GetPredictiveNeedsZipCodeDocument,
  GetPredictiveNeedsCountyDocument,
  GetCountyDataFromStateDocument,
} from '../../../__generated__/graphql'
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import config, { SSConst } from '../../../config/config'
import { setContext } from '@apollo/client/link/context'
import { SnackbarExportStatus } from '../../CommonComponents/SnackbarExportStatus'
import { downloadExcelMarketInsights } from '../../ExportFunctionalityMarketInsights/ExcelMarketInsights'
import { useSnackbar, VariantType } from 'notistack'
import CloseIcon from '@mui/icons-material/Close'
import { ServiceAreaZipCodePDF } from '../../PDF Export Functionality/MarketInsightPDF/ServiceAreaZipCodePDF'
import { DemographicPDF } from '../../PDF Export Functionality/MarketInsightPDF/DemographicPDF'
import { PredictiveNeedPDF } from '../../PDF Export Functionality/MarketInsightPDF/PredictiveNeedPDF'
import { CountyPDF } from '../../PDF Export Functionality/MarketInsightPDF/CountyPDF'
import { MarketSummaryPDF } from '../../PDF Export Functionality/MarketInsightPDF/MarketSummaryPDF'
import { MarketAllTab } from '../../PDF Export Functionality/MarketInsightPDF/MarketAllTab'
import { HiddenComponent } from './HiddenComponent'
import DialogAlert from '../../CommonComponents/DialogBox/dialogAlert'
import ReactDOM from 'react-dom'

export interface IMarketInsightsTabProps { }

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      style={{ width: '100%' }}
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

export const MarketInsightsTab: React.FC = () => {
  const search = useLocation().search
  const dpr_city = new URLSearchParams(search).get('city')
  const zipCode = new URLSearchParams(search).get('zipcode')
  const county = new URLSearchParams(search).get('county')
  const radius = new URLSearchParams(search).get('radius')
  const [value, setValue] = React.useState(0)
  let history = useHistory()
  let { tabtype } = useParams<{ tabtype: string }>()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [countyName, setCounty] = useState('')

  let county_data: string[] = []
  if (county) {
    let counties_array = county.split(',')
    for (let i = 0; i < counties_array.length; i++) {
      const element = counties_array[i]
      let splitted_val = element.split(' - ')
      let string_val: string = splitted_val[1].trim() + splitted_val[0].trim()
      county_data.push(string_val)
    }
  }

  const open = Boolean(anchorEl)

  let duration = 300000

  const downloadExcel = (search, click, value) => {
    const exportData = localStorage.getItem(SSConst.EXPORT_DATA)
    const predictiveData = localStorage.getItem(
      SSConst.EXPORT_PREDICTIVE_DATA
    )
    const countiesHealthData = localStorage.getItem(
      SSConst.EXPORT_COUNTY_DATA
    )
    downloadExcelMarketInsights(
      JSON.parse(exportData!),
      JSON.parse(predictiveData!),
      JSON.parse(countiesHealthData!),
      search,
      click,
      value
    )
  }

  const ExportButtonStyle = styled(Button)(() => ({
    marginBottom: '10px',
    color: '#212121',
    border: '1px solid #212121',
    margin: 'auto 10px',
    textTransform: 'capitalize',
    '&:hover': { backgroundColor: 'transparent', border: '1px solid #3e3e3e' },
  }))

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    let searchParams = new URLSearchParams(window.location.search)
    searchParams.delete('siteOfCare')
    history.push(
      `/market_insights/${newValue === 0
        ? 'Summary'
        : newValue === 1
          ? 'ServiceArea'
          : newValue === 2
            ? 'Demographics'
            : newValue === 3
              ? 'CountyData'
              : newValue === 4
                ? 'PredictiveNeeds'
                : 'Summary'
      }` +
      '?' +
      searchParams.toString()
    )
    setValue(newValue)
  }

  const httpLink = createHttpLink({
    uri: ``,
  })

  const authLink = setContext((_, { headers, uri }) => {
    // get the authentication token from local storage if it exists
    const token = sessionStorage.getItem('user_accessToken')
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
      uri: `${config.hasura_url}/v1/graphql`,
    }
  })

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        pollInterval: 0,
      },
      query: {
        pollInterval: 0,
      },
    },
  })

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const ShowSnackBar = (
    message,
    variant: VariantType,
    duration,
    key?: string
  ) => {
    enqueueSnackbar(<div>{message}</div>, {
      variant: variant,
      anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
      style: {
        flexDirection: 'row',
        maxWidth: '50px',
        marginLeft: '-150px',
      },
      key: key,
      autoHideDuration: duration,
      action: (key) => (
        <IconButton
          color="inherit"
          aria-label="dismiss"
          onClick={() => closeSnackbar(key)}
        >
          <CloseIcon />
        </IconButton>
      ),
    })
    return key
  }

  const getPdfData = async () => {
    let ExportData = localStorage.getItem(SSConst.EXPORT_DATA)

    if (!ExportData) {
      if (dpr_city) {
        const response1 = await client.query({
          query: GetPdfDataCityDocument,
          variables: {
            city: dpr_city,
            age_group: 'Adult,Geriatric,Pediatric',
            patient_type: 'inpatient',
            sl: 'all',
          },
        })

        localStorage.setItem(
          SSConst.EXPORT_DATA,
          response1 ? JSON.stringify(response1.data) : 'No data'
        )

        let state = "";
        if (response1.data.CountyDataTable && response1.data.CountyDataTable.length) {
          for (let i = 0; i < response1.data.CountyDataTable.length; i++) {
            const element = response1.data.CountyDataTable[i];
            if (element.state && element.state !== "NATIONAL") {
              state = element?.state;
            }
          }
        }

        const response2 = await client.query({
          query: GetPredictiveNeedsCityDocument,
          variables: {
            city: dpr_city,
            age_group_Adult: 'Adult',
            age_group_Pediatric: 'Pediatric',
            age_group_Geriatric: 'Geriatric',
            age_group_All: 'Adult,Geriatric,Pediatric',
            patient_type_inpatient: 'inpatient',
            patient_type_asc: 'asc',
            patient_type_clinic: 'clinic',
            patient_type_cancer: 'cancer',
            patient_type_outpatient: 'outpatient',
            patient_type_ed: 'ed',
            sl: 'all',
          },
        })
        localStorage.setItem(
          SSConst.EXPORT_PREDICTIVE_DATA,
          response2 ? JSON.stringify(response2.data) : 'No data'
        )

        const response3 = await client.query({
          query: GetCountyDataFromStateDocument,
          variables: {
            state: state,
          },
        })

        localStorage.setItem(
          SSConst.EXPORT_COUNTY_DATA,
          response3 ? JSON.stringify(response3.data) : 'No data'
        )

        sessionStorage.setItem(SSConst.EXPORT_STATUS, 'COMPLETE')
      } else if (zipCode && radius) {
        const response1 = await client.query({
          query: GetPdfDataZipRadiusDocument,
          variables: {
            zip_code: zipCode,
            age_group: 'Adult,Geriatric,Pediatric',
            patient_type: 'inpatient',
            sl: 'all',
            radius: radius,
          },
        })

        localStorage.setItem(
          SSConst.EXPORT_DATA,
          JSON.stringify(response1.data)
        )

        let state = "";
        if (response1.data.CountyDataTable && response1.data.CountyDataTable.length) {
          for (let i = 0; i < response1.data.CountyDataTable.length; i++) {
            const element = response1.data.CountyDataTable[i];
            if (element.state && element.state !== "NATIONAL") {
              state = element?.state;
            }
          }
        }
        const response2 = await client.query({
          query: GetPredictiveNeedsZipRadiusDocument,
          variables: {
            zip_code: zipCode,
            age_group_Adult: 'Adult',
            age_group_Pediatric: 'Pediatric',
            age_group_Geriatric: 'Geriatric',
            age_group_All: 'Adult,Geriatric,Pediatric',
            patient_type_inpatient: 'inpatient',
            patient_type_asc: 'asc',
            patient_type_clinic: 'clinic',
            patient_type_cancer: 'cancer',
            patient_type_outpatient: 'outpatient',
            patient_type_ed: 'ed',
            sl: 'all',
            radius: radius,
          },
        })

        localStorage.setItem(
          SSConst.EXPORT_PREDICTIVE_DATA,
          JSON.stringify(response2.data)
        )

        const response3 = await client.query({
          query: GetCountyDataFromStateDocument,
          variables: {
            state: state,
          },
        })

        localStorage.setItem(
          SSConst.EXPORT_COUNTY_DATA,
          response3 ? JSON.stringify(response3.data) : 'No data'
        )
        sessionStorage.setItem(SSConst.EXPORT_STATUS, 'COMPLETE')
      } else if (zipCode) {
        const response1 = await client.query({
          query: GetPdfDataZipcodeDocument,
          variables: {
            zip_codes: zipCode,
            age_group: 'Adult,Geriatric,Pediatric',
            patient_type: 'inpatient',
            sl: 'all',
          },
        })
        localStorage.setItem(
          SSConst.EXPORT_DATA,
          JSON.stringify(response1.data)
        )

        let state = "";
        if (response1.data.CountyDataTable && response1.data.CountyDataTable.length) {
          for (let i = 0; i < response1.data.CountyDataTable.length; i++) {
            const element = response1.data.CountyDataTable[i];
            if (element.state && element.state !== "NATIONAL") {
              state = element?.state;
            }
          }
        }
        const response2 = await client.query({
          query: GetPredictiveNeedsZipCodeDocument,
          variables: {
            zip_codes: zipCode,
            age_group_Adult: 'Adult',
            age_group_Pediatric: 'Pediatric',
            age_group_Geriatric: 'Geriatric',
            age_group_All: 'Adult,Geriatric,Pediatric',
            patient_type_inpatient: 'inpatient',
            patient_type_asc: 'asc',
            patient_type_clinic: 'clinic',
            patient_type_cancer: 'cancer',
            patient_type_outpatient: 'outpatient',
            patient_type_ed: 'ed',
            sl: 'all',
          },
        })
        localStorage.setItem(
          SSConst.EXPORT_PREDICTIVE_DATA,
          JSON.stringify(response2.data)
        )

        const response3 = await client.query({
          query: GetCountyDataFromStateDocument,
          variables: {
            state: state,
          },
        })

        localStorage.setItem(
          SSConst.EXPORT_COUNTY_DATA,
          response3 ? JSON.stringify(response3.data) : 'No data'
        )
        sessionStorage.setItem(SSConst.EXPORT_STATUS, 'COMPLETE')
      } else if (county) {
        const response1 = await client.query({
          query: GetPdfDataCountyDocument,
          variables: {
            county: county_data.join(','),
            age_group: 'Adult,Geriatric,Pediatric',
            patient_type: 'inpatient',
            sl: 'all',
          },
        })
        localStorage.setItem(
          SSConst.EXPORT_DATA,
          JSON.stringify(response1.data)
        )

        let state = "";
        if (response1.data.CountyDataTable && response1.data.CountyDataTable.length) {
          for (let i = 0; i < response1.data.CountyDataTable.length; i++) {
            const element = response1.data.CountyDataTable[i];
            if (element.state && element.state !== "NATIONAL") {
              state = element?.state;
            }
          }
        }
        const response2 = await client.query({
          query: GetPredictiveNeedsCountyDocument,
          variables: {
            county: county_data.join(','),
            age_group_Adult: 'Adult',
            age_group_Pediatric: 'Pediatric',
            age_group_Geriatric: 'Geriatric',
            age_group_All: 'Adult,Geriatric,Pediatric',
            patient_type_inpatient: 'inpatient',
            patient_type_asc: 'asc',
            patient_type_clinic: 'clinic',
            patient_type_cancer: 'cancer',
            patient_type_outpatient: 'outpatient',
            patient_type_ed: 'ed',
            sl: 'all',
          },
        })

        localStorage.setItem(
          SSConst.EXPORT_PREDICTIVE_DATA,
          JSON.stringify(response2.data)
        )

        const response3 = await client.query({
          query: GetCountyDataFromStateDocument,
          variables: {
            state: state,
          },
        })

        localStorage.setItem(
          SSConst.EXPORT_COUNTY_DATA,
          response3 ? JSON.stringify(response3.data) : 'No data'
        )
        sessionStorage.setItem(SSConst.EXPORT_STATUS, 'COMPLETE')
      }
    } else {
      sessionStorage.setItem(SSConst.EXPORT_STATUS, 'COMPLETE')
    }
  }

  useEffect(() => {
    setTabValue()
    localStorage.removeItem(SSConst.EXPORT_PREDICTIVE_DATA)
    sessionStorage.removeItem(SSConst.EXPORT_STATUS)
    localStorage.removeItem(SSConst.EXPORT_DATA)
    sessionStorage.removeItem(SSConst.COUNTY_HEALTH_DATA)
    sessionStorage.removeItem(SSConst.COUNTIES)
    localStorage.removeItem(SSConst.EXPORT_COUNTY_DATA)
    duration = 300000
  }, [])

  useEffect(() => {
    setTabValue()
  }, [tabtype])

  const setTabValue = () => {
    if (tabtype === 'Summary') setValue(0)
    else if (tabtype === 'ServiceArea') setValue(1)
    else if (tabtype === 'Demographics') setValue(2)
    else if (tabtype === 'CountyData') setValue(3)
    else if (tabtype === 'PredictiveNeeds') setValue(4)
  }

  const OuterGridStyle = styled(Grid)(() => ({
    background: '#fff',
    margin: '2px 15px',
    borderRadius: '5px',
    width: '100%',
    marginBottom: '10px',
  }))
  console.log(county)
  const TabStyle = styled(Tab)<TabProps>(({ theme }) => ({
    textTransform: 'capitalize',
    fontSize: '16px',
    fontFamily: 'Open Sans',
    padding: '9px 25px 9px 20px',

  }))

  const ButtonDivStyle = styled(Button)(() => ({
    border: '1px solid #3e3e3e',
    color: 'black',
    display: 'inline-block',
    padding: '5px 6px 2px 6px',
    minWidth: '3px',
    height: '32px',
    '&:hover': { backgroundColor: 'transparent', border: '1px solid #3e3e3e' },
  }))
  return (
    <React.Fragment>
      <Grid
        container
        style={{ background: 'rgb(240 240 240)' }}
        direction="column"
      >
        <MarketInsightsTopHeader />
        <>
          <SnackbarExportStatus />
          <Grid container>
            <OuterGridStyle
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Grid xs={8} item>
                <Tabs
                  variant="standard"
                  value={value}
                  onChange={handleChange}
                  textColor="inherit"
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: '#4CAF50',
                      color: '#4CAF50',
                    },
                  }}
                  style={{
                    background: '#fff',
                    color: '#212121',
                    fontWeight: '600',
                    margin: '0px 12px 20px',
                  }}
                >
                  <TabStyle
                    data-market-summary-id="Market Summary"
                    icon={<ManageSearchIcon />}
                    iconPosition="start"
                    label="Summary"
                    style={
                      value === 0
                        ? { color: '#4CAF50', fontWeight: 'bold' }
                        : { color: 'GrayText' }
                    }
                  />
                  <TabStyle
                    data-service-area-id="Service Area"
                    icon={<HomeRepairServiceOutlinedIcon />}
                    iconPosition="start"
                    label="Service Area"
                    style={
                      value === 1
                        ? { color: '#4CAF50', fontWeight: 'bold' }
                        : { color: 'GrayText' }
                    }
                  />
                  <TabStyle
                    data-demographics-id="Demographics"
                    icon={<PollOutlinedIcon />}
                    iconPosition="start"
                    label="Demographics"
                    style={
                      value === 2
                        ? { color: '#4CAF50', fontWeight: 'bold' }
                        : { color: 'GrayText' }
                    }
                  />
                  <TabStyle
                    data-market-county-id="County Data"
                    icon={<LanguageSharpIcon />}
                    iconPosition="start"
                    label="County Data"
                    style={
                      value === 3
                        ? { color: '#4CAF50', fontWeight: 'bold' }
                        : { color: 'GrayText' }
                    }
                  />

                  <TabStyle
                    data-market-predictive-id="Predictive Needs"
                    icon={<TimelineSharpIcon />}
                    iconPosition="start"
                    label="Predictive Needs"
                    style={
                      value === 4
                        ? { color: '#4CAF50', fontWeight: 'bold' }
                        : { color: 'GrayText' }
                    }
                  />
                </Tabs>
              </Grid>
              <Grid
                xs={4}
                item
                style={{
                  textAlign: 'right',
                  padding: '20px',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Grid
                  container
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  <Grid item>
                    <ButtonDivStyle
                      style={{ display: 'none' }}
                      data-share-id="Share"
                      variant="outlined"
                      onClick={() => {
                        let formattedBody = `Hi,\n\nBelow is the link to Panorama-Healthcare - Insights\nLink: ${window.location.href}`
                        let mailToLink =
                          `mailto:?subject=Panorama-Healthcare - Insights&body=` +
                          encodeURIComponent(formattedBody)
                        window.location.href = mailToLink
                      }}
                    >
                      <ShareIcon
                        style={{
                          fontSize: '1.2rem',
                          cursor: 'pointer',
                        }}
                      />
                    </ButtonDivStyle>
                  </Grid>
                  <Grid item>
                    <PopupState variant="dialog" popupId="Export">
                      {(popupState) => (
                        <div>
                          <ExportButtonStyle
                            variant="outlined"
                            {...bindTrigger(popupState)}
                            endIcon={<KeyboardArrowDownIcon />}
                            id="export-button-hospital"
                            aria-controls={
                              open ? 'export-menu-hospital' : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                          >
                            Export
                          </ExportButtonStyle>
                          <Popover
                            {...bindPopover(popupState)}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'center',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'center',
                            }}
                          >
                            <Typography
                              sx={{ width: '90px', justifyContent: 'right' }}
                            >
                              <PopupState variant="dialog" popupId="PDF">
                                {(popupState1) => (
                                  <div>
                                    <MenuItem
                                      style={{
                                        fontSize: 14,
                                        fontWeight: 500,
                                        marginBottom: '25px',
                                      }}
                                      {...bindTrigger(popupState1)}
                                    >
                                      PDF
                                    </MenuItem>
                                    <Popover
                                      {...bindPopover(popupState1)}
                                      anchorOrigin={{
                                        vertical: -100,
                                        horizontal: -82,
                                      }}
                                      transformOrigin={{
                                        vertical: -110,
                                        horizontal: 35,
                                      }}
                                      onClick={(event) => {
                                        popupState.close()
                                        popupState1.close()
                                      }}
                                      keepMounted={true}
                                    >

                                      {/* {
                                        (
                                          // getPdfData(),
                                          sessionStorage.setItem(
                                            SSConst.EXPORT_STATUS,
                                            'IN_PROGRESS'
                                          ))
                                      } */}

                                      {value === 0 ? (
                                        <MarketSummaryPDF getPdfData={getPdfData} />
                                      ) : value === 1 ? (
                                        <ServiceAreaZipCodePDF getPdfData={getPdfData} />
                                      ) : value === 2 ? (
                                        <DemographicPDF getPdfData={getPdfData} />
                                      ) : value === 3 ? (
                                        <CountyPDF getPdfData={getPdfData} />
                                      ) : value === 4 ? (
                                        <PredictiveNeedPDF getPdfData={getPdfData} />
                                      ) : null}

                                      <MarketAllTab getPdfData={getPdfData} />
                                    </Popover>
                                  </div>
                                )}
                              </PopupState>
                            </Typography>
                            <Typography
                              sx={{ width: '90px', marginTop: '-25px' }}
                            >
                              <PopupState variant="dialog" popupId="Excel">
                                {(popupState2) => (
                                  <div>
                                    <MenuItem
                                      style={{
                                        fontSize: 14,
                                        fontWeight: 500,
                                      }}
                                      {...bindTrigger(popupState2)}
                                    >
                                      EXCEL
                                    </MenuItem>
                                    <Popover
                                      {...bindPopover(popupState2)}
                                      anchorOrigin={{
                                        vertical: -100,
                                        horizontal: -82,
                                      }}
                                      transformOrigin={{
                                        vertical: -110,
                                        horizontal: 35,
                                      }}
                                    >
                                      <div>
                                        <MenuItem
                                          style={{ color: 'black' }}
                                          onClick={(event) => {
                                            ReactDOM.render(
                                              <DialogAlert />,
                                              document.createElement('div')
                                            )

                                            const close = ShowSnackBar(
                                              'Generating Export File, Please wait !!',
                                              'info',
                                              duration,
                                              'close'
                                            )
                                            sessionStorage.setItem(
                                              'EXPORT_STATUS',
                                              'IN_PROGRESS'
                                            )
                                            popupState.close()
                                            getPdfData().then(() => {
                                              downloadExcel(
                                                search,
                                                'current',
                                                value
                                              )
                                              if (duration !== 4000)
                                                closeSnackbar(close)
                                              duration = 4000
                                            })
                                          }}
                                        >
                                          Current Tab
                                        </MenuItem>
                                      </div>
                                      <div>
                                        <MenuItem
                                          style={{ color: 'black' }}
                                          onClick={() => {
                                            ReactDOM.render(
                                              <DialogAlert />,
                                              document.createElement('div')
                                            )

                                            const close = ShowSnackBar(
                                              'Generating Export File, Please wait !!',
                                              'info',
                                              duration,
                                              'close'
                                            )
                                            sessionStorage.setItem(
                                              'EXPORT_STATUS',
                                              'IN_PROGRESS'
                                            )
                                            popupState.close()
                                            getPdfData().then(() => {
                                              downloadExcel(
                                                search,
                                                'all',
                                                value
                                              )
                                              if (duration !== 4000)
                                                closeSnackbar(close)
                                              duration = 4000
                                            })
                                          }}
                                        >
                                          All Tabs
                                        </MenuItem>
                                      </div>
                                    </Popover>
                                  </div>
                                )}
                              </PopupState>
                            </Typography>
                          </Popover>
                        </div>
                      )}
                    </PopupState>
                  </Grid>
                </Grid>
              </Grid>
              <Grid xs={12} item>
                <TabPanel value={value} index={0}>
                  <MarketSummary />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <ServiceArea />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <DemographicsTab />
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <CountyData />
                </TabPanel>
                <TabPanel value={value} index={4}>
                  <PredectiveNeeds />
                </TabPanel>
              </Grid>
            </OuterGridStyle>
          </Grid>
        </>
      </Grid>

      <HiddenComponent countyName={countyName} />
    </React.Fragment>
  )
}
