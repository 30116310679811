import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material'

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

export function renderTitleHeader(text: string, customTooltip?: string, type?: string) {
  const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 700,
    },
  });
  text = type === "link" && text.indexOf("https") === -1 ? `https://${text}` : text;
  return (
    <div>
      {customTooltip ?
        <>
          {text}
          <Tooltip title={customTooltip} placement="top" arrow>
            <InfoOutlinedIcon
              style={{
                fontSize: 'small',
                margin: '-2px 10px',
                cursor: "pointer"
              }}
            />
          </Tooltip>
        </>
        :
        type ?
          type === "snackbar" ?
            <Tooltip title={text} placement="top-start" arrow>
              <div style={{ maxWidth: "inherit", minWidth: "inherit", textOverflow: "ellipsis", overflow: "hidden", width: "350px" }}>{text}</div>
            </Tooltip>
            :
            <Tooltip title={text} placement="top" arrow>
              <div style={{ width: "inherit", maxWidth: "inherit", minWidth: "inherit", textOverflow: "ellipsis", overflow: "hidden" }}>
                <a href={type === "link" ? text : `tel:+${text}`} target="_blank" rel="noreferrer" style={{ color: "rgb(0,0,238)" }}>{text}</a>
              </div>
            </Tooltip>
          :
          <CustomWidthTooltip title={text} placement="bottom-start" arrow>
            <div style={{ width: "inherit", maxWidth: "inherit", minWidth: "inherit", textOverflow: "ellipsis", overflow: "hidden" }}>{text}</div>
          </CustomWidthTooltip>
      }
    </div>
  )
}
