import React from 'react'
import ReportGmailerrorredTwoToneIcon from '@mui/icons-material/ReportGmailerrorredTwoTone'
import { Button } from '@mui/material'

export const RequestAccess: React.FC = () => {
  return (
    <div>
      <div style={{ display: 'flex', marginLeft: '60vh', marginTop: '30vh' }}>
        <ReportGmailerrorredTwoToneIcon
          style={{
            fontSize: '200px',
          }}
        />
        <div
          style={{
            marginLeft: '10vh',
          }}
        >
          <div
            style={{
              fontSize: '25px',
              fontWeight: '800',
            }}
          >
            Uh-oh, you do not have access.
          </div>
          <div
            style={{
              marginTop: '3vh',
              fontWeight: '500',
              fontSize: '19px',
            }}
          >
            Your account is not authorized to view this page.
          </div>
          <div
            style={{
              fontWeight: '500',
              fontSize: '19px',
            }}
          >
            You may request access by contacting the admin.
          </div>
          <Button
            variant="contained"
            style={{
              marginLeft: '14vh',
              marginTop: '6vh',
            }}
            onClick={() => {
              let formattedBody = `Hi Team,\n\nCould you please provide me access to the Healthcare Panorama site?`
              let mailToLink =
                `mailto:supinam@dpr.com?cc=Panorama-Healthcare@dpr.com&subject=Access Request for Healthcare Panorama site&body=` +
                encodeURIComponent(formattedBody)
              window.location.href = mailToLink
            }}
          >
            Request access
          </Button>
        </div>
      </div>
    </div>
  )
}
