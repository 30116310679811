import { Grid, Box, Divider, Rating, Stack, Skeleton, Typography, Tooltip } from '@mui/material';
import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import { useGetPatientExperienceRatingQuery, useGetPatientExperienceScoreQuery } from '../../../../../__generated__/graphql';
import { Factors } from './Factors';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { textColor, backgroundColor, performanceText } from '../../../../../config/commonFunction';
import { appInsights } from '../../../../../providers/appInsightProvider';


export interface PatientExperienceProps {
    overall_rating: number | null;
}

export const PatientExperience: React.FC<PatientExperienceProps> = (props: PatientExperienceProps) => {

    const search = useLocation().search;
    const definitive_id = new URLSearchParams(search).get('id');
    const { data: PatientExperienceRatingData, loading: PatientExperienceRatingLoading, error: PatientExperienceRatingError } = useGetPatientExperienceRatingQuery({
        variables: {
            definitive_id
        }
    });

    const { data: PatientExperienceScoreData, loading: PatientExperienceScoreLoading, error: PatientExperienceScoreError } = useGetPatientExperienceScoreQuery({
        variables: {
            definitive_id
        }
    });

    useEffect(() => {
        if (PatientExperienceRatingError instanceof Error) {
            appInsights.trackException({ exception: PatientExperienceRatingError, error: new Error("Failed to fetch Patient Experience Rating.") })
        }
    }, [PatientExperienceRatingError])

    useEffect(() => {
        if (PatientExperienceScoreError instanceof Error) {
            appInsights.trackException({ exception: PatientExperienceScoreError, error: new Error("Failed to fetch Patient Experience Score.") })
        }
    }, [PatientExperienceScoreError])

    let patient_experience_rating_data: any = PatientExperienceRatingData && PatientExperienceRatingData.DEFINITIVEHEALTHCARE_VW_RATINGS_EXPERIENTIAL_DESIGN && PatientExperienceRatingData.DEFINITIVEHEALTHCARE_VW_RATINGS_EXPERIENTIAL_DESIGN.length ? PatientExperienceRatingData.DEFINITIVEHEALTHCARE_VW_RATINGS_EXPERIENTIAL_DESIGN[0] : null;
    let patient_experience_score_data: any = PatientExperienceScoreData && PatientExperienceScoreData.DEFINITIVEHEALTHCARE_VW_SCORE_EXPERIENTIAL_DESIGN && PatientExperienceScoreData.DEFINITIVEHEALTHCARE_VW_SCORE_EXPERIENTIAL_DESIGN.length ? PatientExperienceScoreData.DEFINITIVEHEALTHCARE_VW_SCORE_EXPERIENTIAL_DESIGN[0] : null;

    const TopDivStyle = styled(Box)(() => ({
        borderRadius: "4px",
        padding: "10px 35px 10px 15px",
    }));

    const ClientPerformanceText = styled(Grid)(() => ({
        fontSize: "13px",
        margin: "auto",
        textAlign: "end",
        fontWeight: "600"
    }))

    const HeadingText = styled(Typography)(() => ({
        color: '#3E3E3E',
        fontWeight: '400px',
        fontSize: '13px',
    }))

    const DataText = styled(Typography)(() => ({
        fontWeight: '700',
        fontSize: '14px',
    }))

    return <React.Fragment>
        <Grid container sx={{ margin: "15px 0px" }}>
            <Grid item lg={4.5} md={4.5} >
                {props.overall_rating ?
                    <TopDivStyle sx={{ border: `1px solid ${textColor(Math.round(props.overall_rating))}`, background: backgroundColor(Math.round(props.overall_rating)) }}>
                        <Grid container>
                            <Grid item lg={6} md={6} sx={{ textAlign: "start" }}>
                                <Rating name="read-only" value={Math.round(props.overall_rating)} readOnly sx={{ color: textColor(Math.round(props.overall_rating)) }} />
                            </Grid>
                            <ClientPerformanceText item lg={6} md={6}>
                                {performanceText(Math.round(props.overall_rating))?.replace("(", "").replace(")", "")}
                            </ClientPerformanceText>
                        </Grid>
                    </TopDivStyle>
                    : null}
                {PatientExperienceRatingLoading || PatientExperienceScoreLoading ?
                    <Typography variant='h3' sx={{ margin: "5px" }}>
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                    </Typography>
                    :
                    patient_experience_rating_data && patient_experience_score_data ?
                        <Stack spacing={0.5} sx={{ marginTop: "20px", marginLeft: "10px", overflow: "scroll", height: "45vh", padding: "10px" }}>

                            <Stack direction="row" spacing={0.5}>
                                <HeadingText>Patient Experience HCAHPS Rating</HeadingText>
                                <Tooltip
                                    title="Centers for Medicare and Medicaid Services (CMS) Patient Satisfaction scores"
                                    placeholder="top"
                                    arrow
                                >
                                    <InfoOutlinedIcon
                                        fontSize="small"
                                        style={{ width: '15px' }}
                                    ></InfoOutlinedIcon>
                                </Tooltip>
                            </Stack>
                            <DataText sx={{
                                color: textColor(Math.round(patient_experience_rating_data?.patient_experience_hcahps_rating))
                            }}>
                                {patient_experience_score_data?.patient_experience_hcahps_rating || patient_experience_score_data?.patient_experience_hcahps_rating === 0 ? patient_experience_score_data.patient_experience_hcahps_rating : "N/A"}
                                {patient_experience_rating_data?.patient_experience_hcahps_rating ? performanceText(Math.round(patient_experience_rating_data?.patient_experience_hcahps_rating)) : ""}
                            </DataText>

                            <br />

                            <Stack direction="row" spacing={0.5}>
                                <HeadingText>Patients who reported that their nurses always communicated well </HeadingText>
                                <Tooltip
                                    title="Percentage of patient survey respondents who reported that their nurses always communicated well"
                                    placeholder="top"
                                    arrow
                                >
                                    <InfoOutlinedIcon
                                        fontSize="small"
                                        style={{ width: '15px' }}
                                    ></InfoOutlinedIcon>
                                </Tooltip>
                            </Stack>
                            <DataText sx={{
                                color: textColor(Math.round(patient_experience_rating_data?.my_nurses_always_communicated_well))
                            }}>
                                {patient_experience_score_data?.my_nurses_always_communicated_well || patient_experience_score_data?.my_nurses_always_communicated_well === 0 ? Math.round(patient_experience_score_data.my_nurses_always_communicated_well * 100) + "%" : "N/A"}
                                {patient_experience_rating_data?.my_nurses_always_communicated_well ? performanceText(Math.round(patient_experience_rating_data?.my_nurses_always_communicated_well)) : ""}
                            </DataText>
                            <br />

                            <Stack direction="row" spacing={0.5}>
                                <HeadingText>Patients who reported that their doctors always communicated well</HeadingText>
                                <Tooltip
                                    title="Percentage of patient survey respondents who reported that their doctors always communicated well"
                                    placeholder="top"
                                    arrow
                                >
                                    <InfoOutlinedIcon
                                        fontSize="small"
                                        style={{ width: '15px' }}
                                    ></InfoOutlinedIcon>
                                </Tooltip>
                            </Stack>
                            <DataText sx={{
                                color: textColor(Math.round(patient_experience_rating_data?.my_doctors_always_communicated_well))
                            }}>
                                {patient_experience_score_data?.my_doctors_always_communicated_well || patient_experience_score_data?.my_doctors_always_communicated_well === 0 ? Math.round(patient_experience_score_data.my_doctors_always_communicated_well * 100) + "%" : "N/A"}
                                {patient_experience_rating_data?.my_doctors_always_communicated_well ? performanceText(Math.round(patient_experience_rating_data?.my_doctors_always_communicated_well)) : ""}
                            </DataText>
                            <br />

                            <Stack direction="row" spacing={0.5}>
                                <HeadingText>Patients who always received help as soon as they wanted</HeadingText>
                                <Tooltip
                                    title="Percentage of patient survey respondents who reported that they always received help as soon as they wanted"
                                    placeholder="top"
                                    arrow
                                >
                                    <InfoOutlinedIcon
                                        fontSize="small"
                                        style={{ width: '15px' }}
                                    ></InfoOutlinedIcon>
                                </Tooltip>
                            </Stack>
                            <DataText sx={{
                                color: textColor(Math.round(patient_experience_rating_data?.i_always_received_help_as_soon_as_i_wanted))
                            }}>
                                {patient_experience_score_data?.i_always_received_help_as_soon_as_i_wanted || patient_experience_score_data?.i_always_received_help_as_soon_as_i_wanted === 0 ? Math.round(patient_experience_score_data.i_always_received_help_as_soon_as_i_wanted * 100) + "%" : "N/A"}
                                {patient_experience_rating_data?.i_always_received_help_as_soon_as_i_wanted ? performanceText(Math.round(patient_experience_rating_data?.i_always_received_help_as_soon_as_i_wanted)) : ""}
                            </DataText>
                            <br />

                            <Stack direction="row" spacing={0.5}>
                                <HeadingText>Patients who gave this hospital a rating of 9 or 10</HeadingText>
                                <Tooltip
                                    title="Percentage of patient survey respondents who gave the hospital the highest rating of “9” or “10”"
                                    placeholder="top"
                                    arrow
                                >
                                    <InfoOutlinedIcon
                                        fontSize="small"
                                        style={{ width: '15px' }}
                                    ></InfoOutlinedIcon>
                                </Tooltip>
                            </Stack>
                            <DataText sx={{
                                color: textColor(Math.round(patient_experience_rating_data?.i_gave_this_hospital_rating_of_9_or_10))
                            }}>
                                {patient_experience_score_data?.i_gave_this_hospital_rating_of_9_or_10 || patient_experience_score_data?.i_gave_this_hospital_rating_of_9_or_10 === 0 ? Math.round(patient_experience_score_data.i_gave_this_hospital_rating_of_9_or_10 * 100) + "%" : "N/A"}
                                {patient_experience_rating_data?.i_gave_this_hospital_rating_of_9_or_10 ? performanceText(Math.round(patient_experience_rating_data?.i_gave_this_hospital_rating_of_9_or_10)) : ""}
                            </DataText>
                            <br />

                            <Stack direction="row" spacing={0.5}>
                                <HeadingText>Patients who reported they would definitely recommend this hospital</HeadingText>
                                <Tooltip
                                    title="Percentage of patient survey respondents who would definitely recommend this hospital"
                                    placeholder="top"
                                    arrow
                                >
                                    <InfoOutlinedIcon
                                        fontSize="small"
                                        style={{ width: '15px' }}
                                    ></InfoOutlinedIcon>
                                </Tooltip>
                            </Stack>
                            <DataText sx={{
                                color: textColor(Math.round(patient_experience_rating_data?.i_would_definitely_recommend_the_hospital))
                            }}>
                                {patient_experience_score_data?.i_would_definitely_recommend_the_hospital || patient_experience_score_data?.i_would_definitely_recommend_the_hospital === 0 ? Math.round(patient_experience_score_data.i_would_definitely_recommend_the_hospital * 100) + "%" : "N/A"}
                                {patient_experience_rating_data?.i_would_definitely_recommend_the_hospital ? performanceText(Math.round(patient_experience_rating_data?.i_would_definitely_recommend_the_hospital)) : ""}
                            </DataText>

                        </Stack>
                        : null
                }
            </Grid>
            <Grid item lg={0.5} md={0.5}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: "center", height: "50vh" }}>
                    <Divider orientation="vertical" flexItem />
                </Box>
            </Grid>
            <Grid item lg={7} md={7} style={{ overflow: "scroll", height: "50vh" }}>
                <Factors module_name='EXPERIENTIAL_DESIGN' />
            </Grid>
        </Grid >
    </React.Fragment >
}