import { Grid, Box, Divider, Rating, Stack, Skeleton, Typography, Tooltip } from '@mui/material';
import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import { useGetFacilityConditionsRatingQuery, useGetFacilityConditionsScoreQuery } from '../../../../../__generated__/graphql';
import { Factors } from './Factors';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { textColor, backgroundColor, performanceText } from '../../../../../config/commonFunction';
import { appInsights } from '../../../../../providers/appInsightProvider';


export interface FacilityConditionsProps {
    overall_rating: number | null;
}

export const FacilityConditions: React.FC<FacilityConditionsProps> = (props: FacilityConditionsProps) => {

    const search = useLocation().search;
    const definitive_id = new URLSearchParams(search).get('id');
    const { data: FacilityConditionsRatingData, loading: FacilityConditionsRatingLoading, error: FacilityConditionsRatingError } = useGetFacilityConditionsRatingQuery({
        variables: {
            definitive_id
        }
    });

    const { data: FacilityConditionsScoreData, loading: FacilityConditionsScoreLoading, error: FacilityConditionsScoreError } = useGetFacilityConditionsScoreQuery({
        variables: {
            definitive_id
        }
    });

    useEffect(() => {
        if (FacilityConditionsRatingError instanceof Error) {
            appInsights.trackException({ exception: FacilityConditionsRatingError, error: new Error("Failed to fetch Facility Conditions Rating.") })
        }
    }, [FacilityConditionsRatingError])

    useEffect(() => {
        if (FacilityConditionsScoreError instanceof Error) {
            appInsights.trackException({ exception: FacilityConditionsScoreError, error: new Error("Failed to fetch Facility Conditions Score.") })
        }
    }, [FacilityConditionsScoreError])

    let facility_conditions_rating_data: any = FacilityConditionsRatingData && FacilityConditionsRatingData.DEFINITIVEHEALTHCARE_VW_RATINGS_FACILITY_CONDITIONS && FacilityConditionsRatingData.DEFINITIVEHEALTHCARE_VW_RATINGS_FACILITY_CONDITIONS.length ? FacilityConditionsRatingData.DEFINITIVEHEALTHCARE_VW_RATINGS_FACILITY_CONDITIONS[0] : null;
    let facility_conditions_score_data: any = FacilityConditionsScoreData && FacilityConditionsScoreData.DEFINITIVEHEALTHCARE_VW_SCORE_FACILITY_CONDITIONS && FacilityConditionsScoreData.DEFINITIVEHEALTHCARE_VW_SCORE_FACILITY_CONDITIONS.length ? FacilityConditionsScoreData.DEFINITIVEHEALTHCARE_VW_SCORE_FACILITY_CONDITIONS[0] : null;

    const TopDivStyle = styled(Box)(() => ({
        borderRadius: "4px",
        padding: "10px 35px 10px 15px",
    }));

    const ClientPerformanceText = styled(Grid)(() => ({
        fontSize: "13px",
        margin: "auto",
        textAlign: "end",
        fontWeight: "600"
    }))

    const HeadingText = styled(Typography)(() => ({
        color: '#3E3E3E',
        fontWeight: '400px',
        fontSize: '13px',
    }))

    const DataText = styled(Typography)(() => ({
        fontWeight: '700',
        fontSize: '14px',
    }))

    return <React.Fragment>
        <Grid container sx={{ margin: "15px 0px" }}>
            <Grid item lg={4.5} md={4.5} >
                {props.overall_rating ?
                    <TopDivStyle sx={{ border: `1px solid ${textColor(Math.round(props.overall_rating))}`, background: backgroundColor(Math.round(props.overall_rating)) }}>
                        <Grid container>
                            <Grid item lg={6} md={6} sx={{ textAlign: "start" }}>
                                <Rating name="read-only" value={Math.round(props.overall_rating)} readOnly sx={{ color: textColor(Math.round(props.overall_rating)) }} />
                            </Grid>
                            <ClientPerformanceText item lg={6} md={6}>
                                {performanceText(Math.round(props.overall_rating))?.replace("(", "").replace(")", "")}
                            </ClientPerformanceText>
                        </Grid>
                    </TopDivStyle>
                    : null}

                {FacilityConditionsRatingLoading || FacilityConditionsScoreLoading ?
                    <Typography variant='h3' sx={{ margin: "5px" }}>
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                    </Typography>
                    :
                    facility_conditions_rating_data && facility_conditions_score_data ?
                        <Stack spacing={0.5} sx={{ marginTop: "20px", marginLeft: "10px", overflow: "scroll", height: "45vh", padding: "10px" }}>

                            <Stack direction="row" spacing={0.5}>
                                <HeadingText>Inpatient Square Footage</HeadingText>
                                <Tooltip
                                    title="Square footage associated with the facility’s inpatient cost center areas"
                                    placeholder="top"
                                    arrow
                                >
                                    <InfoOutlinedIcon
                                        fontSize="small"
                                        style={{ width: '15px' }}
                                    ></InfoOutlinedIcon>
                                </Tooltip>
                            </Stack>
                            <DataText sx={{
                                color: textColor(Math.round(facility_conditions_rating_data?.inpatient_sq_footage))
                            }}>
                                {facility_conditions_score_data?.inpatient_sq_footage || facility_conditions_score_data?.inpatient_sq_footage === 0 ? parseInt(facility_conditions_score_data.inpatient_sq_footage).toLocaleString('en-US') : "N/A"}
                                {facility_conditions_rating_data?.inpatient_sq_footage ? performanceText(Math.round(facility_conditions_rating_data?.inpatient_sq_footage)) : ""}
                            </DataText>

                            <br />

                            <Stack direction="row" spacing={0.5}>
                                <HeadingText>Number of Beds</HeadingText>
                                <Tooltip
                                    title="Number of beds as reported in the Medicare Cost Reports"
                                    placeholder="top"
                                    arrow
                                >
                                    <InfoOutlinedIcon
                                        fontSize="small"
                                        style={{ width: '15px' }}
                                    ></InfoOutlinedIcon>
                                </Tooltip>
                            </Stack>
                            <DataText sx={{
                                color: textColor(Math.round(facility_conditions_rating_data?.number_of_beds))
                            }}>
                                {facility_conditions_score_data?.number_of_beds || facility_conditions_score_data?.number_of_beds === 0 ? facility_conditions_score_data.number_of_beds : "N/A"}
                                {facility_conditions_rating_data?.number_of_beds ? performanceText(Math.round(facility_conditions_rating_data?.number_of_beds)) : ""}
                            </DataText>
                            <br />

                            <Stack direction="row" spacing={0.5}>
                                <HeadingText>Outpatient Square Footage</HeadingText>
                                <Tooltip
                                    title="Square footage associated with the facility’s outpatient cost center areas"
                                    placeholder="top"
                                    arrow
                                >
                                    <InfoOutlinedIcon
                                        fontSize="small"
                                        style={{ width: '15px' }}
                                    ></InfoOutlinedIcon>
                                </Tooltip>
                            </Stack>
                            <DataText sx={{
                                color: textColor(Math.round(facility_conditions_rating_data?.outpatient_sq_footage))
                            }}>
                                {facility_conditions_score_data?.outpatient_sq_footage || facility_conditions_score_data?.outpatient_sq_footage === 0 ? parseInt(facility_conditions_score_data.outpatient_sq_footage).toLocaleString('en-US') : "N/A"}
                                {facility_conditions_rating_data?.outpatient_sq_footage ? performanceText(Math.round(facility_conditions_rating_data?.outpatient_sq_footage)) : ""}
                            </DataText>
                            <br />

                            <Stack direction="row" spacing={0.5}>
                                <HeadingText>Average Age of Facility (in years)</HeadingText>
                                <Tooltip
                                    title="Weighted average of all of the ages of all fixed assets of the facility. Generally, organizations with an older age of facility will need to invest in their fixed assets to remain competitive"
                                    placeholder="top"
                                    arrow
                                >
                                    <InfoOutlinedIcon
                                        fontSize="small"
                                        style={{ width: '15px' }}
                                    ></InfoOutlinedIcon>
                                </Tooltip>
                            </Stack>
                            <DataText sx={{
                                color: textColor(Math.round(facility_conditions_rating_data?.avg_age_of_facility_years))
                            }}>
                                {facility_conditions_score_data?.avg_age_of_facility_years || facility_conditions_score_data?.avg_age_of_facility_years === 0 ? facility_conditions_score_data.avg_age_of_facility_years.toFixed(2) : "N/A"}
                                {facility_conditions_rating_data?.avg_age_of_facility_years ? performanceText(Math.round(facility_conditions_rating_data?.avg_age_of_facility_years)) : ""}
                            </DataText>
                            <br />

                            <Stack direction="row" spacing={0.5}>
                                <HeadingText>Inpatient SF / Bed</HeadingText>
                                <Tooltip
                                    title="Square feet allocation per bed is a proxy for understanding the amount of support, clinical, and public support spaces to support the inpatient clinical operations"
                                    placeholder="top"
                                    arrow
                                >
                                    <InfoOutlinedIcon
                                        fontSize="small"
                                        style={{ width: '15px' }}
                                    ></InfoOutlinedIcon>
                                </Tooltip>
                            </Stack>
                            <DataText sx={{
                                color: textColor(Math.round(facility_conditions_rating_data?.inpatient_sq_ft_per_bed))
                            }}>
                                {facility_conditions_score_data?.inpatient_sq_ft_per_bed || facility_conditions_score_data?.inpatient_sq_ft_per_bed === 0 ?
                                    Math.round(facility_conditions_score_data.inpatient_sq_ft_per_bed).toLocaleString('en-US') : "N/A"}
                                {facility_conditions_rating_data?.inpatient_sq_ft_per_bed ? performanceText(Math.round(facility_conditions_rating_data?.inpatient_sq_ft_per_bed)) : ""}
                            </DataText>
                            <br />

                            <Stack direction="row" spacing={0.5}>
                                <HeadingText>Inpatient Revenue per SF</HeadingText>
                                <Tooltip
                                    title="Inpatient revenue per square foot indicates the amount of money a facility makes for each square foot on its inpatient admissions"
                                    placeholder="top"
                                    arrow
                                >
                                    <InfoOutlinedIcon
                                        fontSize="small"
                                        style={{ width: '15px' }}
                                    ></InfoOutlinedIcon>
                                </Tooltip>
                            </Stack>
                            <DataText sx={{
                                color: textColor(Math.round(facility_conditions_rating_data?.inpatient_revenue_per_bed))
                            }}>
                                {facility_conditions_score_data?.inpatient_revenue_per_bed || facility_conditions_score_data?.inpatient_revenue_per_bed === 0 ?
                                    facility_conditions_score_data.inpatient_revenue_per_bed.toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                    }) : "N/A"}
                                {facility_conditions_rating_data?.inpatient_revenue_per_bed ? performanceText(Math.round(facility_conditions_rating_data?.inpatient_revenue_per_bed)) : ""}
                            </DataText>
                            <br />
                            <Stack direction="row" spacing={0.5}>
                                <HeadingText>Room and Bathroom Were Always Clean</HeadingText>
                                <Tooltip
                                    title="Percentage of patient survey respondents who reported that their room and bathroom were always clean"
                                    placeholder="top"
                                    arrow
                                >
                                    <InfoOutlinedIcon
                                        fontSize="small"
                                        style={{ width: '15px' }}
                                    ></InfoOutlinedIcon>
                                </Tooltip>
                            </Stack>
                            <DataText sx={{
                                color: textColor(Math.round(facility_conditions_rating_data?.my_room_and_bathroom_were_always_clean))
                            }}>
                                {facility_conditions_score_data?.my_room_and_bathroom_were_always_clean || facility_conditions_score_data?.my_room_and_bathroom_were_always_clean === 0 ? (facility_conditions_score_data.my_room_and_bathroom_were_always_clean * 100).toFixed(0) + "%" : "N/A"}
                                {facility_conditions_rating_data?.my_room_and_bathroom_were_always_clean ? performanceText(Math.round(facility_conditions_rating_data?.my_room_and_bathroom_were_always_clean)) : ""}
                            </DataText>

                        </Stack>
                        : null
                }
            </Grid>
            <Grid item lg={0.5} md={0.5}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: "center", height: "50vh" }}>
                    <Divider orientation="vertical" flexItem />
                </Box>
            </Grid>
            <Grid item lg={7} md={7} style={{ overflow: "scroll", height: "50vh" }}>
                <Factors module_name='FACILITY_CONDITIONS' />
            </Grid>
        </Grid >
    </React.Fragment >
}