import { Box, Grid } from '@mui/material'
import React from 'react'
import { AzureMapsProvider } from 'react-azure-maps'
import MapController from '../../../../CommonComponents/Maps/MapController'
import { Summary_GeneralInfo } from '../Summary/Summary_GeneralInfo'
import { KeyClinicalMetrics } from './KeyClinicalMetrics'

export const GeneralInfo: React.FC = () => {
  return (
    <Box>
      <Grid
        container
        style={{
          height: '56vh',
          overflow: 'auto',
          paddingBottom: '40px',
        }}
      >
        <Grid item lg={5} md={12} sm={12} p="10px" height={'78vh'}>
          <AzureMapsProvider>
            <MapController
              MapType="GeneralInfo"
              AgeBracket=""
              MapView=""
              Providerdata=""
            />
          </AzureMapsProvider>
        </Grid>

        <Grid item lg={3.5} md={12} sm={12}>
          <Summary_GeneralInfo />
        </Grid>

        <Grid item lg={3.5} md={12} sm={12}>
          <KeyClinicalMetrics></KeyClinicalMetrics>
        </Grid>
      </Grid>
    </Box>
  )
}

export default GeneralInfo
