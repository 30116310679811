import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Popover,
  Tab,
  TabProps,
  Tabs,
  Typography,
} from '@mui/material'
import React, { useEffect } from 'react'
import { styled } from '@mui/material/styles'
import { DetailCard } from '../HealthcareSystem/DetailCard/DetailCard'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import ShareIcon from '@mui/icons-material/Share'
import ManageSearchIcon from '@mui/icons-material/ManageSearch'
import HomeRepairServiceOutlinedIcon from '@mui/icons-material/HomeRepairServiceOutlined'
import PollOutlinedIcon from '@mui/icons-material/PollOutlined'
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined'
import ClientSummary from './Summary/Client_Summary'
import GeneralInfo from './GeneralInfo/GeneralInfo'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import Stakeholders from './Stakeholders/Stakeholders'
import { PerformanceTab } from './Performance/PerformanceTab'
import CaliforniaHCAI from './CaliforniaHCAI/CaliforniaHCAI'
import { GetExportedDataDocument } from '../../../../__generated__/graphql'
import { downloadExcelHospitalSystem } from '../../../Export Functionality/ExcelHospitalSystem'
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state'
import { GeneralInfoPDFTable } from '../../../PDF Export Functionality/HospitalPDFExport/GeneralnfoPDF'
import { SummaryPDFTable } from '../../../PDF Export Functionality/HospitalPDFExport/SummaryPDF'
import { StakeHoldersPDF } from '../../../PDF Export Functionality/HospitalPDFExport/StakeHoldersPDF'
import { CaliforniaHCAIPDF } from '../../../PDF Export Functionality/HospitalPDFExport/CaliforniaHCAIPDF'
import { PerformancePdfTable } from '../../../PDF Export Functionality/HospitalPDFExport/PerformancePDF'
import { HospitalAllTabPDFTable } from '../../../PDF Export Functionality/HospitalPDFExport/HospitalAllTabPDF'
import { useSnackbar, VariantType } from 'notistack'
import CloseIcon from '@mui/icons-material/Close';
import ReactDOM from 'react-dom'
import DialogAlert from '../../../CommonComponents/DialogBox/dialogAlert'
import config, { SSConst } from '../../../../config/config'
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { ExportStatus } from '../../../../customHooks/useExportData'
import { HiddenComponentClient } from '../HiddenComponentClient'

export const MyContext = React.createContext<any>([])

export const Hospitals: React.FC = () => {
  const search = useLocation().search
  const [value, setValue] = React.useState(0)
  let history = useHistory()
  let location = useLocation()
  let { tabtype } = useParams<{ tabtype: string }>()
  const state = new URLSearchParams(search).get('state')
  const city = new URLSearchParams(search).get('city')

  let definitive_id = new URLSearchParams(search).get('id')
  let definitiveID: number = definitive_id ? parseInt(definitive_id) : 0

  const httpLink = createHttpLink({
    uri: ``,
  })

  const authLink = setContext((_, { headers, uri }) => {
    // get the authentication token from local storage if it exists
    const token = sessionStorage.getItem('user_accessToken')
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
      uri: `${config.hasura_url}/v1/graphql`,
    }
  })

  let duration;
  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        pollInterval: 0,
      },
      query: {
        pollInterval: 0,
      },
    },
    connectToDevTools: true,
  })

  const getDataFunction = async () => {
    let ExportData = sessionStorage.getItem(SSConst.EXPORT_DATA);

    if (!ExportData) {
      const response = await client.query(({
        query: GetExportedDataDocument,
        variables: {
          def_id: definitiveID,
          definitive_id,
        }
      }))

      sessionStorage.setItem(
        SSConst.EXPORT_DATA,
        response ? JSON.stringify(response.data) : 'No data'
      )
      sessionStorage.setItem(SSConst.EXPORT_STATUS, ExportStatus.COMPLETE)
    }
  }

  useEffect(() => {
    sessionStorage.removeItem(SSConst.EXPORT_PREDICTIVE_DATA)
    sessionStorage.setItem(SSConst.EXPORT_STATUS, ExportStatus.NOT_STARTED)
    sessionStorage.removeItem(SSConst.EXPORT_DATA)
    sessionStorage.removeItem(SSConst.COUNTY_HEALTH_DATA)
    sessionStorage.removeItem(SSConst.COUNTIES)
    sessionStorage.removeItem(SSConst.EXPORT_COUNTY_DATA)
    duration = 10000
  })

  const downloadExcelData = (value) => {
    const ExportedData = JSON.parse(sessionStorage.getItem(SSConst.EXPORT_DATA)!);
    let provider_dataExcel =
      ExportedData &&
        ExportedData.providerDetails &&
        ExportedData.providerDetails.length
        ? ExportedData.providerDetails[0]
        : null
    let GeneralInfo_SummaryData =
      ExportedData &&
        ExportedData.generalInfoSummaryData &&
        ExportedData.generalInfoSummaryData.length
        ? ExportedData.generalInfoSummaryData
        : null
    let GeneralInfo_data =
      ExportedData &&
        ExportedData.generalInfoSummaryData &&
        ExportedData.generalInfoSummaryData.length
        ? ExportedData.generalInfoSummaryData
        : null
    let Performance_SummaryData: any =
      ExportedData &&
        ExportedData.performanceData &&
        ExportedData.performanceData.length
        ? ExportedData.performanceData[0]
        : null

    let clinical_outcome_score_data: any =
      ExportedData &&
        ExportedData.clinicalOutcomeScore &&
        ExportedData.clinicalOutcomeScore.length
        ? ExportedData.clinicalOutcomeScore[0]
        : null
    let clinical_outcome_rating_data: any =
      ExportedData &&
        ExportedData.clinicalOutcomeRating &&
        ExportedData.clinicalOutcomeRating.length
        ? ExportedData.clinicalOutcomeRating[0]
        : null

    let facility_conditions_rating_data: any =
      ExportedData &&
        ExportedData.facilityConditionRating &&
        ExportedData.facilityConditionRating.length
        ? ExportedData.facilityConditionRating[0]
        : null
    let facility_conditions_score_data: any =
      ExportedData &&
        ExportedData.facilityConditionScore &&
        ExportedData.facilityConditionScore.length
        ? ExportedData.facilityConditionScore[0]
        : null
    let ratings: any =
      ExportedData &&
        ExportedData.performanceData &&
        ExportedData.performanceData.length
        ? ExportedData.performanceData[0]
        : null

    let fiscal_rating_data: any =
      ExportedData &&
        ExportedData.fiscalStewardshipRating &&
        ExportedData.fiscalStewardshipRating.length
        ? ExportedData.fiscalStewardshipRating[0]
        : null
    let fiscal_score_data: any =
      ExportedData &&
        ExportedData.fiscalStewardshipScore &&
        ExportedData.fiscalStewardshipScore.length
        ? ExportedData.fiscalStewardshipScore[0]
        : null

    let operations_rating_data: any =
      ExportedData &&
        ExportedData.clinicalOperationRating &&
        ExportedData.clinicalOperationRating.length
        ? ExportedData.clinicalOperationRating[0]
        : null
    let operations_score_data: any =
      ExportedData &&
        ExportedData.clinicalOperationScore &&
        ExportedData.clinicalOperationScore.length
        ? ExportedData.clinicalOperationScore[0]
        : null

    let patient_experience_rating_data: any =
      ExportedData &&
        ExportedData.experimentalDesignRating &&
        ExportedData.experimentalDesignRating.length
        ? ExportedData.experimentalDesignRating[0]
        : null
    let patient_experience_score_data: any =
      ExportedData &&
        ExportedData.experimentalDesignScore &&
        ExportedData.experimentalDesignScore.length
        ? ExportedData.experimentalDesignScore[0]
        : null

    let positive_fiscal: any =
      ExportedData &&
        ExportedData.getAllPositiveFactorsforFiscalStewardship &&
        ExportedData.getAllPositiveFactorsforFiscalStewardship.length
        ? ExportedData.getAllPositiveFactorsforFiscalStewardship.map((data) => [
          '',
          data.comment,
        ])
        : [[' ', 'N/A']]
    let negative_fiscal: any =
      ExportedData &&
        ExportedData.getAllNegativeFactorsforFiscalStewardship &&
        ExportedData.getAllNegativeFactorsforFiscalStewardship.length
        ? ExportedData.getAllNegativeFactorsforFiscalStewardship.map((data) => [
          '',
          data.comment,
        ])
        : [[' ', 'N/A']]

    let positive_clinical: any =
      ExportedData &&
        ExportedData.getAllPositiveFactorsforClinicalOperations &&
        ExportedData.getAllPositiveFactorsforClinicalOperations.length
        ? ExportedData.getAllPositiveFactorsforClinicalOperations.map((data) => [
          '',
          data.comment,
        ])
        : [[' ', 'N/A']]
    let negative_clinical: any =
      ExportedData &&
        ExportedData.getAllNegativeFactorsforClinicalOperations &&
        ExportedData.getAllNegativeFactorsforClinicalOperations.length
        ? ExportedData.getAllNegativeFactorsforClinicalOperations.map((data) => [
          '',
          data.comment,
        ])
        : [[' ', 'N/A']]

    let positive_patient_exp: any =
      ExportedData &&
        ExportedData.getAllPositiveFactorsforExperimentalDesign &&
        ExportedData.getAllPositiveFactorsforExperimentalDesign.length
        ? ExportedData.getAllPositiveFactorsforExperimentalDesign.map((data) => [
          '',
          data.comment,
        ])
        : [[' ', 'N/A']]
    let negative_patient_exp: any =
      ExportedData &&
        ExportedData.getAllNegativeFactorsforExperimentalDesign &&
        ExportedData.getAllNegativeFactorsforExperimentalDesign.length
        ? ExportedData.getAllNegativeFactorsforExperimentalDesign.map((data) => [
          '',
          data.comment,
        ])
        : [[' ', 'N/A']]

    let positive_clinical_outcomes: any =
      ExportedData &&
        ExportedData.getAllPositiveFactorsforClinicalOutcome &&
        ExportedData.getAllPositiveFactorsforClinicalOutcome.length
        ? ExportedData.getAllPositiveFactorsforClinicalOutcome.map((data) => [
          '',
          data.comment,
        ])
        : [[' ', 'N/A']]
    let negative_clinical_outcomes: any =
      ExportedData &&
        ExportedData.getAllNegativeFactorsforClinicalOutcome &&
        ExportedData.getAllNegativeFactorsforClinicalOutcome.length
        ? ExportedData.getAllNegativeFactorsforClinicalOutcome.map((data) => [
          '',
          data.comment,
        ])
        : [[' ', 'N/A']]

    let positive_facility_conditions: any =
      ExportedData &&
        ExportedData.getAllPositiveFactorsforFacilityConditions &&
        ExportedData.getAllPositiveFactorsforFacilityConditions.length
        ? ExportedData.getAllPositiveFactorsforFacilityConditions.map((data) => [
          '',
          data.comment,
        ])
        : [[' ', 'N/A']]
    let negative_facility_conditions: any =
      ExportedData &&
        ExportedData.getAllNegativeFactorsforFacilityConditions &&
        ExportedData.getAllNegativeFactorsforFacilityConditions.length
        ? ExportedData.getAllNegativeFactorsforFacilityConditions.map((data) => [
          '',
          data.comment,
        ])
        : [[' ', 'N/A']]

    let OverallPositiveArray = [
      ...ExportedData &&
        ExportedData.getAllPositiveFactorsforFiscalStewardship &&
        ExportedData.getAllPositiveFactorsforFiscalStewardship.length
        ? ExportedData.getAllPositiveFactorsforFiscalStewardship.map((data) => [
          '',
          data.comment,
        ])
        : [[' ', 'N/A']],
      ...ExportedData &&
        ExportedData.getAllPositiveFactorsforClinicalOperations &&
        ExportedData.getAllPositiveFactorsforClinicalOperations.length
        ? ExportedData.getAllPositiveFactorsforClinicalOperations.map((data) => [
          '',
          data.comment,
        ])
        : [[' ', 'N/A']],
      ...ExportedData &&
        ExportedData.getAllPositiveFactorsforExperimentalDesign &&
        ExportedData.getAllPositiveFactorsforExperimentalDesign.length
        ? ExportedData.getAllPositiveFactorsforExperimentalDesign.map((data) => [
          '',
          data.comment,
        ])
        : [[' ', 'N/A']],
      ...ExportedData &&
        ExportedData.getAllPositiveFactorsforClinicalOutcome &&
        ExportedData.getAllPositiveFactorsforClinicalOutcome.length
        ? ExportedData.getAllPositiveFactorsforClinicalOutcome.map((data) => [
          '',
          data.comment,
        ])
        : [[' ', 'N/A']],
      ...ExportedData &&
        ExportedData.getAllPositiveFactorsforFacilityConditions &&
        ExportedData.getAllPositiveFactorsforFacilityConditions.length
        ? ExportedData.getAllPositiveFactorsforFacilityConditions.map((data) => [
          '',
          data.comment,
        ])
        : [[' ', 'N/A']],
    ]
    OverallPositiveArray[0][0] = 'Positive Factors'
    let OverallNegativeArray = [
      ...ExportedData &&
        ExportedData.getAllNegativeFactorsforFiscalStewardship &&
        ExportedData.getAllNegativeFactorsforFiscalStewardship.length
        ? ExportedData.getAllNegativeFactorsforFiscalStewardship.map((data) => [
          '',
          data.comment,
        ])
        : [[' ', 'N/A']],
      ...ExportedData &&
        ExportedData.getAllNegativeFactorsforClinicalOperations &&
        ExportedData.getAllNegativeFactorsforClinicalOperations.length
        ? ExportedData.getAllNegativeFactorsforClinicalOperations.map((data) => [
          '',
          data.comment,
        ])
        : [[' ', 'N/A']],
      ...ExportedData &&
        ExportedData.getAllNegativeFactorsforExperimentalDesign &&
        ExportedData.getAllNegativeFactorsforExperimentalDesign.length
        ? ExportedData.getAllNegativeFactorsforExperimentalDesign.map((data) => [
          '',
          data.comment,
        ])
        : [[' ', 'N/A']],
      ...ExportedData &&
        ExportedData.getAllNegativeFactorsforClinicalOutcome &&
        ExportedData.getAllNegativeFactorsforClinicalOutcome.length
        ? ExportedData.getAllNegativeFactorsforClinicalOutcome.map((data) => [
          '',
          data.comment,
        ])
        : [[' ', 'N/A']],
      ...ExportedData &&
        ExportedData.getAllNegativeFactorsforFacilityConditions &&
        ExportedData.getAllNegativeFactorsforFacilityConditions.length
        ? ExportedData.getAllNegativeFactorsforFacilityConditions.map((data) => [
          '',
          data.comment,
        ])
        : [[' ', 'N/A']],
    ]
    OverallNegativeArray[0][0] = 'Risk Factors'

    let performaceDataExcel = ExportedData?.excelPerformanceData

    let stakeHoldersDataExcel = ExportedData?.getStakeholderData

    let california_data =
      ExportedData?.getCaliforniaInfo && ExportedData?.getCaliforniaInfo.length
        ? ExportedData?.getCaliforniaInfo[0]
        : null

    let california_table_data = ExportedData?.getCaliforniaTableData
      ? ExportedData?.getCaliforniaTableData
      : []

    downloadExcelHospitalSystem(
      value,
      GeneralInfo_SummaryData,
      GeneralInfo_data,
      Performance_SummaryData,
      clinical_outcome_rating_data,
      clinical_outcome_score_data,
      facility_conditions_rating_data,
      facility_conditions_score_data,
      ratings,
      fiscal_rating_data,
      fiscal_score_data,
      operations_rating_data,
      operations_score_data,
      patient_experience_rating_data,
      patient_experience_score_data,
      positive_fiscal,
      negative_fiscal,
      positive_clinical,
      negative_clinical,
      positive_patient_exp,
      negative_patient_exp,
      positive_clinical_outcomes,
      negative_clinical_outcomes,
      positive_facility_conditions,
      negative_facility_conditions,
      OverallPositiveArray,
      OverallNegativeArray,
      provider_dataExcel,
      stakeHoldersDataExcel,
      california_data,
      california_table_data,
      city,
      state,
      performaceDataExcel
    )
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    history.push(
      `/client_insights/${newValue === 0
        ? 'Summary'
        : newValue === 1
          ? 'GeneralInfo'
          : newValue === 2
            ? 'Performance'
            : newValue === 3
              ? 'Stakeholder'
              : newValue === 4
                ? 'CaliforniaHCAI'
                : 'Summary'
      }${location.search}`
    )
    setValue(newValue)
  }

  useEffect(() => {
    setTabValue()
  }, [])

  useEffect(() => {
    setTabValue()
  }, [tabtype])

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const ShowSnackBar = (message, variant: VariantType, duration, key?: string) => {
    enqueueSnackbar(<div>{message}</div>, {
      variant: variant, persist: false, autoHideDuration: 4000,
      anchorOrigin: { horizontal: "center", vertical: "bottom" },
      style: {
        flexDirection: 'row',
        maxWidth: '50px',
        marginLeft: '-150px',
      },

      key: key,
      action: (key) => (
        <IconButton
          color="inherit"
          aria-label="dismiss"
          onClick={() => closeSnackbar(key)}
        >
          <CloseIcon />
        </IconButton>
      ),
    })
    return key;
  }
  const setTabValue = () => {
    if (tabtype === 'Summary') setValue(0)
    else if (tabtype === 'GeneralInfo') setValue(1)
    else if (tabtype === 'Performance') setValue(2)
    else if (tabtype === 'Stakeholder') setValue(3)
    else if (tabtype === 'CaliforniaHCAI') setValue(4)
  }

  const TabStyle = styled(Tab)<TabProps>(({ theme }) => ({
    textTransform: 'capitalize',
    fontSize: '16px',
    fontFamily: 'Open Sans',
    padding: '9px 25px 9px 20px',
  }))

  const ButtonDivStyle = styled(Button)(() => ({
    border: '1px solid #3e3e3e',
    color: 'black',
    display: 'inline-block',
    padding: '5px 6px 2px 6px',
    minWidth: '3px',
    height: '32px',
    '&:hover': { backgroundColor: 'transparent', border: '1px solid #3e3e3e' },
  }))

  const OuterGridStyle = styled(Grid)(() => ({
    background: '#fff',
    margin: '2px 15px',
    borderRadius: '5px',
    width: '100%',
    marginBottom: '10px',
  }))

  const ExportButtonStyle = styled(Button)(() => ({
    color: '#3e3e3e',
    border: '1px solid #3e3e3e',
    textTransform: 'capitalize',
    '&:hover': { backgroundColor: 'transparent', border: '1px solid #3e3e3e' },
  }))

  interface TabPanelProps {
    children?: React.ReactNode
    index: number
    value: any
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
      <Box
        style={{
          width: '100%',
          fontSize: '15px',
          padding: '0px',
          margin: '0px',
        }}
        role="tabpanel"
        hidden={value !== index}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 0 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </Box>
    )
  }

  return (
    <>
      <DetailCard dataSource={null} />
      <Grid container>
        <OuterGridStyle
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid xs={8} item>
            <Tabs
              variant="scrollable"
              value={value}
              onChange={handleChange}
              textColor="inherit"
              TabIndicatorProps={{
                style: {
                  backgroundColor: '#4CAF50',
                  color: '#4CAF50',
                },
              }}
            >
              <TabStyle
                icon={<ManageSearchIcon />}
                iconPosition="start"
                label="Summary"
                data-summary-id="Client Summary"
                style={
                  value === 0
                    ? { color: '#4CAF50', fontWeight: 'bold' }
                    : { color: 'GrayText' }
                }
              />
              <TabStyle
                icon={<HomeRepairServiceOutlinedIcon />}
                iconPosition="start"
                label="General Info"
                data-general-info-id="General Info"
                style={
                  value === 1
                    ? { color: '#4CAF50', fontWeight: 'bold' }
                    : { color: 'GrayText' }
                }
              />
              <TabStyle
                icon={<PollOutlinedIcon />}
                iconPosition="start"
                label="Performance"
                data-performance-id="Performance"
                style={
                  value === 2
                    ? { color: '#4CAF50', fontWeight: 'bold' }
                    : { color: 'GrayText' }
                }
              />
              <TabStyle
                icon={<GroupOutlinedIcon />}
                iconPosition="start"
                label="Stakeholders"
                data-stakeholder-id="Stakeholders"
                style={
                  value === 3
                    ? { color: '#4CAF50', fontWeight: 'bold' }
                    : { color: 'GrayText' }
                }
              />
              {state === 'California' ? (
                <TabStyle
                  icon={value === 4
                    ?
                    <img
                      alt=""
                      src={require('../../../../Assets/CaliforniaMapGreen.webp')}
                    ></img>
                    :
                    <img
                      alt=""
                      src={require('../../../../Assets/CaliforniaMap.webp')}
                    ></img>
                  }
                  iconPosition="start"
                  data-california-id="California HCAI"
                  label="California HCAI"
                  style={
                    value === 4
                      ? { color: '#4CAF50', fontWeight: 'bold' }
                      : { color: 'GrayText' }
                  }
                />
              ) : null}
            </Tabs>
          </Grid>
          <Grid
            xs={4}
            item
            sx={{ textAlign: 'right', padding: '20px', zIndex: '1' }}
            direction="column"
          >
            <Grid
              container
              sx={{
                alignItems: 'center',
                textAlign: 'right',
                justifyContent: 'right',
                alignContent: 'center',
              }}
            >
              <Grid item>
                <ButtonDivStyle
                  style={{ display: 'none' }}
                  data-share-id="Share"
                  variant="outlined"
                  onClick={() => {
                    let formattedBody = `Hi,\n\nBelow is the link to Panorama-Healthcare - Insights\nLink: ${window.location.href}\n\n\nThis data is considered proprietary and confidential and should not be forwarded to parties outside of DPR.`
                    let mailToLink =
                      `mailto:?subject=Panorama-Healthcare - Insights&body=` +
                      encodeURIComponent(formattedBody)
                    window.location.href = mailToLink
                  }}
                >
                  <ShareIcon
                    style={{ fontSize: '1.2rem', cursor: 'pointer' }}
                  />
                </ButtonDivStyle>
              </Grid>
              <Grid item sx={{ margin: 'auto 10px' }}>
                {
                  (
                    <PopupState variant="dialog" popupId="Export">
                      {(popupState) => (
                        <div>
                          <ExportButtonStyle
                            variant="outlined"
                            {...bindTrigger(popupState)}
                            id="export-button"
                            endIcon={<KeyboardArrowDownIcon />}
                          >
                            Export
                          </ExportButtonStyle>
                          <Popover
                            {...bindPopover(popupState)}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'center',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'center',
                            }}
                          >
                            <Typography
                              sx={{ width: '90px', justifyContent: 'right' }}
                            >
                              <PopupState variant="dialog" popupId="PDF">
                                {(popupState1) => (
                                  <div>
                                    <MenuItem
                                      style={{
                                        fontSize: 14,
                                        fontWeight: 500,
                                        marginBottom: '25px',
                                      }}
                                      {...bindTrigger(popupState1)}
                                    >
                                      PDF
                                    </MenuItem>
                                    <Popover
                                      {...bindPopover(popupState1)}
                                      anchorOrigin={{
                                        vertical: -100,
                                        horizontal: -82,
                                      }}
                                      transformOrigin={{
                                        vertical: -110,
                                        horizontal: 35,
                                      }}
                                      onClick={() => {
                                        popupState1.close(); popupState.close()
                                      }}
                                    >

                                      {
                                        value === 0 ?
                                          // summaryPdf
                                          <SummaryPDFTable getDataFunction={getDataFunction} />
                                          :
                                          value === 1 ?
                                            // generalInfoPDF
                                            <GeneralInfoPDFTable getDataFunction={getDataFunction} />
                                            :
                                            value === 2 ?
                                              // performancePDF
                                              <PerformancePdfTable getDataFunction={getDataFunction} />
                                              :
                                              value === 3 ?
                                                // stakeholdersPDF
                                                <StakeHoldersPDF getDataFunction={getDataFunction} />
                                                :
                                                value === 4 ?
                                                  // hcaiPDF
                                                  <CaliforniaHCAIPDF getDataFunction={getDataFunction} />
                                                  : null
                                      }
                                      <HospitalAllTabPDFTable getDataFunction={getDataFunction} desicionMaker={state} />
                                    </Popover>
                                  </div>
                                )}
                              </PopupState>
                            </Typography>
                            <Typography
                              sx={{ width: '90px', marginTop: '-25px' }}
                            >
                              <PopupState variant="dialog" popupId="Excel">
                                {(popupState2) => (
                                  <div>
                                    <MenuItem
                                      style={{ fontSize: 14, fontWeight: 500 }}
                                      {...bindTrigger(popupState2)}
                                    >
                                      EXCEL
                                    </MenuItem>
                                    <Popover
                                      {...bindPopover(popupState2)}
                                      anchorOrigin={{
                                        vertical: -100,
                                        horizontal: -82,
                                      }}
                                      transformOrigin={{
                                        vertical: -110,
                                        horizontal: 35,
                                      }}
                                    >
                                      <MenuItem style={{ color: 'black' }}
                                        onClick={(event) => {
                                          ReactDOM.render(
                                            <DialogAlert />,
                                            document.createElement('div')
                                          )

                                          const close = ShowSnackBar(
                                            'Generating Export File, Please wait !!',
                                            'info',
                                            duration,
                                            'close'
                                          )
                                          sessionStorage.setItem(
                                            'EXPORT_STATUS',
                                            'IN_PROGRESS'
                                          )
                                          popupState.close()
                                          getDataFunction().then(() => {
                                            downloadExcelData(
                                              value
                                            )
                                            duration = 4000
                                            if (duration != 4000)
                                              closeSnackbar(close)
                                          })
                                        }}
                                      >Current Tab</MenuItem>
                                      <MenuItem style={{ color: 'black' }}
                                        onClick={() => {
                                          ReactDOM.render(
                                            <DialogAlert />,
                                            document.createElement('div')
                                          )

                                          const close = ShowSnackBar(
                                            'Generating Export File, Please wait !!',
                                            'info',
                                            duration,
                                            'close'
                                          )
                                          sessionStorage.setItem(
                                            'EXPORT_STATUS',
                                            'IN_PROGRESS'
                                          )
                                          popupState.close()
                                          getDataFunction().then(() => {
                                            downloadExcelData(
                                              -1
                                            )
                                            duration = 4000
                                            if (duration != 4000)
                                              closeSnackbar(close)
                                          })
                                        }}
                                      >All Tabs</MenuItem>
                                    </Popover>
                                  </div>
                                )}
                              </PopupState>

                            </Typography>
                          </Popover>
                        </div>
                      )}
                    </PopupState>
                  )}
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={12} container>
            <TabPanel value={value} index={0}>
              <ClientSummary />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <GeneralInfo />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <PerformanceTab />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <Stakeholders />
            </TabPanel>
            {state === 'California' ? (
              <TabPanel value={value} index={4}>
                <CaliforniaHCAI />
              </TabPanel>
            ) : null}
          </Grid>
        </OuterGridStyle>
        <HiddenComponentClient />

      </Grid>
    </>
  )
}
