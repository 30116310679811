import React, { forwardRef, useEffect, useRef, useState } from "react"
import DropDownBox from 'devextreme-react/drop-down-box';
import DataGrid, {
    Selection, Paging, FilterRow, Scrolling,
} from 'devextreme-react/data-grid';
import { ICounty } from "../../../models/ICounty";

interface ICountySelectionProps {
    county_data: ICounty[];
    getSelectedCounty: Function;
    disabled: boolean;
    label: string;
    type: string;
}

const CustomDataGrid: React.FC<any> = forwardRef((props: { selectedCounty: number[], county_data: ICounty[], onSelectionChange?: Function }, ref: any) => {
    const gridColumns = ['state', 'county'];
    return (
        <DataGrid
            height={345}
            keyExpr="Id"
            dataSource={props.county_data}
            columns={gridColumns} style={{ fontSize: "14px !important" }}
            hoverStateEnabled={true}
            ref={ref}
            defaultSelectedRowKeys={[]}
            onSelectionChanged={(e) => {
                if (props.onSelectionChange) {
                    props.onSelectionChange(e.selectedRowKeys)
                }
            }}
        >
            <Selection deferred={false} allowSelectAll mode="multiple" />
            <Scrolling mode="virtual" />
            <Paging enabled={true} pageSize={10} />
            <FilterRow visible={true} />
        </DataGrid >
    );
})

export const CountySelection: React.FC<ICountySelectionProps> = (props: ICountySelectionProps) => {
    const gridRef = useRef(null);
    const [selectedCounty, setSelectedCounty] = useState<number[]>([]);
    const memoDataGrid = React.useMemo(() =>
        <CustomDataGrid ref={gridRef} county_data={props.county_data} onSelectionChange={(keys: number[]) => setSelectedCounty(keys)} />,
        [props.type])

    useEffect(() => {
        if (selectedCounty && selectedCounty.length === 0 && gridRef && gridRef.current) {
            const instance = (gridRef.current as any).instance;
            instance?.clearSelection()
        }
    }, [selectedCounty])

    useEffect(() => {
        if (props.type !== "County Selection" && gridRef && gridRef.current) {
            const instance = (gridRef.current as any).instance;
            instance?.clearSelection()
        }
    }, [props.type])


    return (
        <DropDownBox
            value={selectedCounty}
            disabled={props.disabled}
            valueExpr="Id"
            deferRendering={false}
            displayExpr={(data) => `${data.statecode} - ${data.county}`}
            placeholder={props.label}
            showClearButton={true}
            dataSource={props.county_data}
            stylingMode={"outlined"}
            style={{
                padding: "4px", fontSize: "14px"
            }}
            dropDownOptions={{
                height: '50vh'
            }}
            onValueChanged={(e) => {
                setSelectedCounty(e.value || []);
                let county_string: string[] = [];
                if (e.value && e.value.length) {
                    e.value.forEach(element => {
                        let data: any = props.county_data.filter(county => county.Id === element)
                        if (data && data.length)
                            county_string.push(`${data[0].statecode} - ${data[0].county}`);
                    });
                }
                props.getSelectedCounty(county_string.join(", "))
            }}
            contentRender={() => memoDataGrid}
        />
    )
}
