import { performanceTextExcel } from "../../config/commonFunction";

export const getClientPerformanceaoa = (GeneralInfo_SummaryData, GeneralInfo_data, Performance_SummaryData, clinical_outcome_rating_data, clinical_outcome_score_data, facility_conditions_rating_data, facility_conditions_score_data, fiscal_rating_data, fiscal_score_data, operations_rating_data, operations_score_data, patient_experience_rating_data, patient_experience_score_data, positive_fiscal, negative_fiscal, positive_clinical, negative_clinical, positive_patient_exp, negative_patient_exp, positive_clinical_outcomes, negative_clinical_outcomes, positive_facility_conditions, negative_facility_conditions, OverallPositiveArray, OverallNegativeArray, performaceDataExcel) => {
    console.log(performaceDataExcel)

    let aoaPerformance = [['Metrics', 'Value', 'Score', 'Rating'],
    ['# of Discharges', performaceDataExcel[0].no_of_discharges ? performaceDataExcel[0].no_of_discharges : '-'],
    ['# of Staffed Beds', performaceDataExcel[0].number_of_beds ? performaceDataExcel[0].number_of_beds : '-'],
    ['# of Licensed Beds', performaceDataExcel[0].number_of_licensed_beds ? performaceDataExcel[0].number_of_licensed_beds : '-'],
    ['Intensive Care Unit Beds', performaceDataExcel[0].intensive_care_unit_beds ?  performaceDataExcel[0].intensive_care_unit_beds : '-'],
    ['Neonatal Intensive Care Unit Beds', performaceDataExcel[0].nicu_beds ? performaceDataExcel[0].nicu_beds : '-'],
    ['# of Employees', performaceDataExcel[0].no_of_employees ? parseInt(performaceDataExcel[0].no_of_employees) : '-'],
    ['Number of Interns and Residents', performaceDataExcel[0].no_of_interns_and_residents ? parseInt(performaceDataExcel[0].no_of_interns_and_residents) : '-'],
    ['Est # of Inpatient Surgeries', performaceDataExcel[0].est_no_of_inpatient_surgeries ? performaceDataExcel[0].est_no_of_inpatient_surgeries : '-'],
    ['Est # of Outpatient Surgeries', performaceDataExcel[0].est_no_of_outpatient_surgeries ? performaceDataExcel[0].est_no_of_outpatient_surgeries : '-'],
    ['Est # of Total Surgeries', performaceDataExcel[0].est_no_of_total_surgeries ? performaceDataExcel[0].est_no_of_total_surgeries : '-'],
    ['Est # of Outpatient Visits', performaceDataExcel[0].est_no_of_outpatient_visits ? performaceDataExcel[0].est_no_of_outpatient_visits : '-'],
    ['Est # of ER Visits', performaceDataExcel[0].est_no_of_er_visits ? performaceDataExcel[0].est_no_of_er_visits : '-'],
    ['Number of Births', performaceDataExcel[0].no_of_births ? performaceDataExcel[0].no_of_births : '-'],
    ['Number of Operating Rooms', performaceDataExcel[0].no_of_operating_rooms ? performaceDataExcel[0].no_of_operating_rooms : '-'],
    [' '],
    ['Overall Performance Score', ' ', Performance_SummaryData.overall_rating ? Performance_SummaryData.overall_rating.toFixed(0) : '-', Performance_SummaryData?.overall_rating ? performanceTextExcel(Math.round(Performance_SummaryData?.overall_rating)) : '-'],
    ...OverallPositiveArray,
    ...OverallNegativeArray,
    ['Fiscal Stewardship Score', ' ', Performance_SummaryData.fiscal_stewardship ? Performance_SummaryData.fiscal_stewardship?.toFixed(0) : '-', Performance_SummaryData.fiscal_stewardship ? performanceTextExcel(Math.round(Performance_SummaryData?.fiscal_stewardship)) : '-'],
    ...positive_fiscal,
    ...negative_fiscal,
    ['Total Patient Revenue', performaceDataExcel[0].total_patient_revenue ? performaceDataExcel[0].total_patient_revenue : '-'],
    ['Net Patient Revenue', performaceDataExcel[0].net_patient_revenue ? parseInt(performaceDataExcel[0].net_patient_revenue) : '-'],
    ['Net Operating Profit Margin', performaceDataExcel[0].net_operating_profit_margin ? Math.round(((performaceDataExcel[0]?.net_operating_profit_margin) * 100) * 10) / 10 : '-', performaceDataExcel[0].rtg_net_operating_profit_margin ? performaceDataExcel[0].rtg_net_operating_profit_margin : '-', performaceDataExcel[0].rtg_net_operating_profit_margin ? performanceTextExcel(performaceDataExcel[0].rtg_net_operating_profit_margin) : '-'],
    ['Outpatient Revenue', performaceDataExcel[0].outpatient_revenue ? performaceDataExcel[0].outpatient_revenue : '-'],
    ['Inpatient Revenue', performaceDataExcel[0].inpatient_revenue ? performaceDataExcel[0].inpatient_revenue : '-'],
    ['Est. IT Operating Expense Budget', performaceDataExcel[0].est_it_operating_expense_budget ? performaceDataExcel[0].est_it_operating_expense_budget : '-'],
    ['Est. IT Capital Budget', performaceDataExcel[0].est_it_capital_budget ? performaceDataExcel[0].est_it_capital_budget : '-'],
    ['Capital Expenditures', performaceDataExcel[0].capital_expenditures ? parseInt(performaceDataExcel[0].capital_expenditures) : '-', performaceDataExcel[0].rtg_capital_expenditures ? performaceDataExcel[0].rtg_capital_expenditures : '-', performaceDataExcel[0].rtg_capital_expenditures ? performanceTextExcel(performaceDataExcel[0].rtg_capital_expenditures) : '-'],
    ['Net Income', performaceDataExcel[0].net_income ? performaceDataExcel[0].net_income : '-'],
    ['Net Income Margin', performaceDataExcel[0].net_income_margin ? performaceDataExcel[0].net_income_margin : '-'],
    ['EBITDA', performaceDataExcel[0].ebitda ? performaceDataExcel[0].ebitda : '-'],
    ['Cash on Hand', performaceDataExcel[0].cash_on_hand ? performaceDataExcel[0].cash_on_hand : '-'],
    ['Days Cash on Hand', performaceDataExcel[0].days_cash_on_hands ? parseInt(performaceDataExcel[0].days_cash_on_hands) : '-', performaceDataExcel[0].rtg_days_cash_on_hand ? performaceDataExcel[0].rtg_days_cash_on_hand : '-', performaceDataExcel[0].rtg_days_cash_on_hand ? performanceTextExcel(performaceDataExcel[0].rtg_days_cash_on_hand) : '-'],
    ['Accounts Receivable', performaceDataExcel[0].accounts_receivable ? performaceDataExcel[0].accounts_receivable : '-'],
    ['Current Ratio', performaceDataExcel[0].current_ratio ? parseInt(performaceDataExcel[0].current_ratio) : '-', performaceDataExcel[0].rtg_current_ratio ? performaceDataExcel[0].rtg_current_ratio : '-', performaceDataExcel[0].rtg_current_ratio ? performanceTextExcel(performaceDataExcel[0]?.rtg_current_ratio) : '-'],
    ['Debt to Equity Ratio', performaceDataExcel[0].debt_to_equity_ratio ? performaceDataExcel[0].debt_to_equity_ratio : '-', performaceDataExcel[0].rtg_debt_to_equity_ratio ? performaceDataExcel[0].rtg_debt_to_equity_ratio : '-', performaceDataExcel[0].rtg_debt_to_equity_ratio ? performanceTextExcel(performaceDataExcel[0]?.rtg_debt_to_equity_ratio) : '-'],
    ['Bad Debt to Accounts Receivable Ratio', performaceDataExcel[0].bad_debt_to_accounts_receivable_ratio ? performaceDataExcel[0].bad_debt_to_accounts_receivable_ratio : '-'],
    ['Payor Mix: Medicare Days', performaceDataExcel[0].payor_mix_medicare_days ? performaceDataExcel[0].payor_mix_medicare_days : '-', performaceDataExcel[0].rtg_payor_mix_medicare_days ? performaceDataExcel[0].rtg_payor_mix_medicare_days : '-', performaceDataExcel[0].rtg_payor_mix_medicare_days ? performanceTextExcel(performaceDataExcel[0]?.rtg_payor_mix_medicare_days) : '-'],
    ['Payor Mix: Medicaid Days', performaceDataExcel[0].payor_mix_medicaid_days ? performaceDataExcel[0].payor_mix_medicaid_days : '-', performaceDataExcel[0].rtg_payor_mix_medicaid_days ? performaceDataExcel[0].rtg_payor_mix_medicaid_days : '-', performaceDataExcel[0].rtg_payor_mix_medicaid_days ? performanceTextExcel(performaceDataExcel[0]?.rtg_payor_mix_medicaid_days) : '-'],
    ['Payor Mix: Private/Self-Pay/Other Days', performaceDataExcel[0].payor_mix_private_selfpay_otherdays ? performaceDataExcel[0].payor_mix_private_selfpay_otherdays : '-'],
    [' '],
    ['Operations score', ' ', Performance_SummaryData.clinical_operations ? Performance_SummaryData.clinical_operations.toFixed(0) : '-', Performance_SummaryData.clinical_operations ? performanceTextExcel(Math.round(Performance_SummaryData?.clinical_operations)) : '-'],
    ...positive_clinical,
    ...negative_clinical,
    ['Bed Utilization Rate', performaceDataExcel[0].bed_utilization ? (performaceDataExcel[0].bed_utilization * 100).toFixed(1) + "%" : '-', performaceDataExcel[0].rtg_bed_utilization ? performaceDataExcel[0].rtg_bed_utilization : '-', performaceDataExcel[0].rtg_bed_utilization ? performanceTextExcel(performaceDataExcel[0].rtg_bed_utilization) : '-'],
    ['Average number of minutes patients spent in the emergency department before sent home rate', performaceDataExcel[0].mins_in_ed_before_sent_home ? Math.round(performaceDataExcel[0]?.mins_in_ed_before_sent_home) : '-', performaceDataExcel[0].rtg_mins_in_ed_before_sent_home ? performaceDataExcel[0].rtg_mins_in_ed_before_sent_home : '-', performaceDataExcel[0].rtg_mins_in_ed_before_sent_home ? performanceTextExcel(performaceDataExcel[0]?.rtg_mins_in_ed_before_sent_home) : '-'],
    ['Percentage of patients who left the emergency department before seen Rate', performaceDataExcel[0].pct_left_ed_before_being_seen ? performaceDataExcel[0].pct_left_ed_before_being_seen : '-', performaceDataExcel[0].rtg_pct_left_ed_before_being_seen ? performaceDataExcel[0].rtg_pct_left_ed_before_being_seen : '-', performaceDataExcel[0].rtg_pct_left_ed_before_being_seen ? performanceTextExcel(performaceDataExcel[0].rtg_pct_left_ed_before_being_seen) : '-'],
    ['Annual surgeries per Operating Room', operations_score_data.operating_room_throughput ? Math.round(operations_score_data.operating_room_throughput) : '-', performaceDataExcel[0].rtg_surgical_volume_per_or ? performaceDataExcel[0].rtg_surgical_volume_per_or : '-', performaceDataExcel[0].rtg_surgical_volume_per_or ? performanceTextExcel(performaceDataExcel[0].rtg_surgical_volume_per_or) : '-'],
    [' '],
    ['Clinical Outcomes Score', , Performance_SummaryData.clinical_outcomes ? Performance_SummaryData.clinical_outcomes.toFixed(0) : '-', Performance_SummaryData.clinical_outcomes ? performanceTextExcel(Math.round(Performance_SummaryData.clinical_outcomes)) : '-'],
    ...positive_clinical_outcomes,
    ...negative_clinical_outcomes,
    ['Hospital Compare Overall Rating', clinical_outcome_score_data.overall_quality_rating ? clinical_outcome_score_data.overall_quality_rating : '-', performaceDataExcel[0].rtg_overall_quality_rating ? performaceDataExcel[0].rtg_overall_quality_rating : '-', performaceDataExcel[0].rtg_overall_quality_rating ? performanceTextExcel(performaceDataExcel[0].rtg_overall_quality_rating): '-'],
    ['FY2021 Hospital Compare Overall Rating', performaceDataExcel[0].hospital_compare_overall_rating ? performaceDataExcel[0].hospital_compare_overall_rating : '-', performaceDataExcel[0].hospital_compare_overall_rating ? performaceDataExcel[0].hospital_compare_overall_rating : '-' , performaceDataExcel[0].hospital_compare_overall_rating ? performanceTextExcel(performaceDataExcel[0].hospital_compare_overall_rating) : '-'],
    ['FY2021 Hospital Acquired Condition Rate Penalty', performaceDataExcel[0].hospital_acquired_condition_rate_penalty ? performaceDataExcel[0].hospital_acquired_condition_rate_penalty : '-'],
    ['Est. FY2021 Revenue Loss Due to Hospital Acquired Conditions Penalty', performaceDataExcel[0].revenue_penalty_due_to_hospital_acquired_condition ? performaceDataExcel[0].revenue_penalty_due_to_hospital_acquired_condition : '-', performaceDataExcel[0].rtg_revenue_penalty_due_to_hospital_acquired_condition ? performaceDataExcel[0].rtg_revenue_penalty_due_to_hospital_acquired_condition : '-', performaceDataExcel[0].rtg_revenue_penalty_due_to_hospital_acquired_condition ? performanceTextExcel(performaceDataExcel[0].rtg_revenue_penalty_due_to_hospital_acquired_condition) : '-'],
    ['FY2020 Hospital Acquired Condition Rate Penalty', performaceDataExcel[0].prev_year_hospital_acquired_condition_rate_penalty ? performaceDataExcel[0].prev_year_hospital_acquired_condition_rate_penalty : '-'],
    ['Est. FY2020 Revenue Loss Due to Hospital Acquired Condition Penalty', performaceDataExcel[0].prev_year_revenue_penalty_due_to_hosp_acquired_cond ? performaceDataExcel[0].prev_year_revenue_penalty_due_to_hosp_acquired_cond : '-', performaceDataExcel[0].rtg_prev_year_revenue_penalty_due_to_hosp_acquired_cond ? performaceDataExcel[0].rtg_prev_year_revenue_penalty_due_to_hosp_acquired_cond : '-', performaceDataExcel[0].rtg_prev_year_revenue_penalty_due_to_hosp_acquired_cond ? performanceTextExcel(performaceDataExcel[0].rtg_prev_year_revenue_penalty_due_to_hosp_acquired_cond) : '-'],
    ['All Cause Hospital-Wide Readmission Rate', performaceDataExcel[0].all_cause_hospital_wide_readmission_rate ? Math.round(performaceDataExcel[0].all_cause_hospital_wide_readmission_rate * 100) : '-', performaceDataExcel[0].rtg_readmission_rate ? performaceDataExcel[0].rtg_readmission_rate : '-', performaceDataExcel[0].rtg_readmission_rate ? performanceTextExcel(performaceDataExcel[0].rtg_readmission_rate) : '-'],
    ['All Cause Hospital-Wide Readmission Cases', performaceDataExcel[0]?.all_cause_hospital_wide_readmission_cases],
    [' '],
    ['Patient Experience Score', ' ', Performance_SummaryData.experiential_design ? Performance_SummaryData.experiential_design.toFixed(0) : '-' , Performance_SummaryData.experiential_design ? performanceTextExcel(Math.round(Performance_SummaryData.experiential_design)) : '-'],
    ...positive_patient_exp,
    ...negative_patient_exp,
    ['Patient Survey (HCAHPS) Summary Star Rating', performaceDataExcel[0].patient_experience_hcahps_rating ? performaceDataExcel[0].patient_experience_hcahps_rating : '-', performaceDataExcel[0].rtg_patient_experience_hcahps_rating ? performaceDataExcel[0].rtg_patient_experience_hcahps_rating : '-', performaceDataExcel[0].rtg_patient_experience_hcahps_rating ? performanceTextExcel(performaceDataExcel[0].rtg_patient_experience_hcahps_rating) : '-'],
    ['Patients who reported that their nurses Always communicated well Rate', performaceDataExcel[0].patients_whose_nursesalwayscommunicatedwell_rate ? (performaceDataExcel[0].patients_whose_nursesalwayscommunicatedwell_rate * 100).toFixed(0) + "%" : '-', performaceDataExcel[0].rtg_my_nurses_always_communicated_well ? performaceDataExcel[0].rtg_my_nurses_always_communicated_well : '-', performaceDataExcel[0].rtg_my_nurses_always_communicated_well ? performanceTextExcel(performaceDataExcel[0].rtg_my_nurses_always_communicated_well) : '-'],
    ['Patients who reported that their nurses Always communicated well State AVG', performaceDataExcel[0].patients_whose_nursesalwayscommunicatedwell_state_avg ? (performaceDataExcel[0].patients_whose_nursesalwayscommunicatedwell_state_avg * 100).toFixed(0) + "%" : '-'],
    ['Patients who reported that their nurses Always communicated well National AVG', performaceDataExcel[0].patients_whose_nursesalwayscommunicatedwell_national_avg ? (performaceDataExcel[0].patients_whose_nursesalwayscommunicatedwell_national_avg * 100).toFixed(0) + "%" : '-'],
    ['Patients who reported that their nurses Always communicated well Star Rating (out of 5)', performaceDataExcel[0].patients_whose_nursesalwayscommunicatedwell_star_rating ? performaceDataExcel[0].patients_whose_nursesalwayscommunicatedwell_star_rating : '-'],
    ['Patients who reported that their doctors Always communicated well Rate', performaceDataExcel[0].patients_whosedoctorsalwayscommunicatedwell_rate ? (performaceDataExcel[0].patients_whosedoctorsalwayscommunicatedwell_rate * 100).toFixed(0) + "%" : '-', performaceDataExcel[0].rtg_my_doctors_always_communicated_well ? performaceDataExcel[0].rtg_my_doctors_always_communicated_well : '-', performaceDataExcel[0].rtg_my_doctors_always_communicated_well ? performanceTextExcel(performaceDataExcel[0].rtg_my_doctors_always_communicated_well) : '-'],
    ['Patients who reported that their doctors Always communicated well State AVG', performaceDataExcel[0].patients_whosedoctorsalwayscommunicatedwell_state_avg  ? (performaceDataExcel[0].patients_whosedoctorsalwayscommunicatedwell_state_avg * 100).toFixed(0) + "%" : '-'],
    ['Patients who reported that their doctors Always communicated well National AVG', performaceDataExcel[0].patients_whosedoctorsalwayscommunicatedwell_national_avg ? (performaceDataExcel[0].patients_whosedoctorsalwayscommunicatedwell_national_avg * 100).toFixed(0) + "%" : '-'],
    ['Patients who reported that their doctors Always communicated well Star Rating (out of 5)',performaceDataExcel[0].patients_whosedoctorsalwayscommunicatedwell_star_rating ? performaceDataExcel[0].patients_whosedoctorsalwayscommunicatedwell_star_rating : '-'],
    ['Patients who reported that they Always received help as soon as they wanted Rate', performaceDataExcel[0].patients_who_receivedhelpasatheywanted_rate ? (performaceDataExcel[0].patients_who_receivedhelpasatheywanted_rate * 100).toFixed(0) + "%" : '-', performaceDataExcel[0].rtg_i_always_received_help_as_soon_as_i_wanted ? performaceDataExcel[0].rtg_i_always_received_help_as_soon_as_i_wanted : '-',performaceDataExcel[0].rtg_i_always_received_help_as_soon_as_i_wanted ? performanceTextExcel(performaceDataExcel[0].rtg_i_always_received_help_as_soon_as_i_wanted) : '-'],
    ['Patients who reported that they Always received help as soon as they wanted State AVG', performaceDataExcel[0].patients_who_receivedhelpasatheywanted_state_avg ? (performaceDataExcel[0].patients_who_receivedhelpasatheywanted_state_avg * 100).toFixed(0) + "%" : '-'],
    ['Patients who reported that they Always received help as soon as they wanted National AVG', performaceDataExcel[0].patients_who_receivedhelpasatheywanted_national_avg ? (performaceDataExcel[0].patients_who_receivedhelpasatheywanted_national_avg * 100).toFixed(0) + "%" : '-'],
    ['Patients who reported that they Always received help as soon as they wanted Star Rating (out of 5)', performaceDataExcel[0].patients_who_receivedhelpasatheywanted_star_rating ? performaceDataExcel[0].patients_who_receivedhelpasatheywanted_star_rating : '-'],
    ['Patients who gave their hospital a rating of 9 or 10 on a scale from 0 (lowest) to 10 (highest) Rate', performaceDataExcel[0].patients_gavetheirhospitalratingof9or10_rate ? (performaceDataExcel[0].patients_gavetheirhospitalratingof9or10_rate * 100).toFixed(0) + "%" : '-', performaceDataExcel[0].rtg_i_gave_this_hospital_rating_of_9_or_10 ? performaceDataExcel[0].rtg_i_gave_this_hospital_rating_of_9_or_10 : '-', performaceDataExcel[0].rtg_i_gave_this_hospital_rating_of_9_or_10 ? performanceTextExcel(performaceDataExcel[0].rtg_i_gave_this_hospital_rating_of_9_or_10) : '-'],
    ['Patients who gave their hospital a rating of 9 or 10 on a scale from 0 (lowest) to 10 (highest) State AVG', performaceDataExcel[0].patients_gavetheirhospitalratingof9or10_state_avg ? (performaceDataExcel[0].patients_gavetheirhospitalratingof9or10_state_avg * 100).toFixed(0) + "%" : '-'],
    ['Patients who gave their hospital a rating of 9 or 10 on a scale from 0 (lowest) to 10 (highest) National AVG', performaceDataExcel[0].patients_gavetheirhospitalratingof9or10_national_avg ? (performaceDataExcel[0].patients_gavetheirhospitalratingof9or10_national_avg * 100).toFixed(0) + "%" : '-'],
    ['Patients who gave their hospital a rating of 9 or 10 on a scale from 0 (lowest) to 10 (highest) Star Rating (out of 5)',performaceDataExcel[0].patients_gavetheirhospitalratingof9or10_star_rating ? performaceDataExcel[0].patients_gavetheirhospitalratingof9or10_star_rating : '-'],
    ['Patients who reported yes they would definitely recommend the hospital Rate', performaceDataExcel[0].patients_woulddefinitelyrecommendthehospital_rate ? (performaceDataExcel[0].patients_woulddefinitelyrecommendthehospital_rate * 100).toFixed(0) + "%" : '-', performaceDataExcel[0].rtg_i_would_definitely_recommend_the_hospital ? performaceDataExcel[0].rtg_i_would_definitely_recommend_the_hospital : '-', performaceDataExcel[0].rtg_i_would_definitely_recommend_the_hospital ? performanceTextExcel(performaceDataExcel[0].rtg_i_would_definitely_recommend_the_hospital) : '-'],
    ['Patients who reported yes they would definitely recommend the hospital State AVG', performaceDataExcel[0].patients_woulddefinitelyrecommendthehospital_state_avg ? (performaceDataExcel[0].patients_woulddefinitelyrecommendthehospital_state_avg * 100).toFixed(0) + "%" : '-'],
    ['Patients who reported yes they would definitely recommend the hospital National AVG', performaceDataExcel[0].patients_woulddefinitelyrecommendthehospital_national_avg ? (performaceDataExcel[0].patients_woulddefinitelyrecommendthehospital_national_avg * 100).toFixed(0) + "%" : '-'],
    ['Patients who reported yes they would definitely recommend the hospital Star Rating (out of 5)', performaceDataExcel[0].patients_woulddefinitelyrecommendthehospital_star_rating ? performaceDataExcel[0].patients_woulddefinitelyrecommendthehospital_star_rating : '-'],
    [' '],
    ['Facility Conditions Score', "", Performance_SummaryData.facility_conditions ? Performance_SummaryData.facility_conditions.toFixed(0) : '-', Performance_SummaryData.facility_conditions ? performanceTextExcel(Math.round(Performance_SummaryData.facility_conditions)) : '-'],
    ...positive_facility_conditions,
    ...negative_facility_conditions,
    ['Total Facility Square Footage', performaceDataExcel[0].total_facility_square_footage ? parseInt(performaceDataExcel[0].total_facility_square_footage) : '-'],
    ['Inpatient Cost Center Square Footage', performaceDataExcel[0].inpatient_sq_footage ? parseFloat(performaceDataExcel[0].inpatient_sq_footage) : '-'],
    ['Outpatient Cost Center Square Footage', performaceDataExcel[0].outpatient_sq_footage ? parseFloat(performaceDataExcel[0].outpatient_sq_footage) : '-'],
    ['Average Age of Facility (In Years)', performaceDataExcel[0].avg_age_of_plant ? parseFloat(performaceDataExcel[0].avg_age_of_plant) : '-', performaceDataExcel[0].rtg_avg_age_of_facility_years ? performaceDataExcel[0].rtg_avg_age_of_facility_years : '-', performaceDataExcel[0].rtg_avg_age_of_facility_years ? performanceTextExcel(performaceDataExcel[0].rtg_avg_age_of_facility_years) : '-'],
    ['Inpatient SF / Bed', performaceDataExcel[0].inpatient_sq_ft_per_bed ? parseFloat(performaceDataExcel[0].inpatient_sq_ft_per_bed) : '-',
        performaceDataExcel[0].rtg_inpatient_sq_ft_per_bed ? performaceDataExcel[0].rtg_inpatient_sq_ft_per_bed : '-', performaceDataExcel[0].rtg_inpatient_sq_ft_per_bed ? performanceTextExcel(performaceDataExcel[0].rtg_inpatient_sq_ft_per_bed) : '-'],
    ['Inpatient Revenue per SF', performaceDataExcel[0].inpatient_revenue_per_bed ? parseFloat(performaceDataExcel[0].inpatient_revenue_per_bed) : '-', performaceDataExcel[0].rtg_inpatient_revenue_per_bed ? performaceDataExcel[0].rtg_inpatient_revenue_per_bed : '-', performaceDataExcel[0].rtg_inpatient_revenue_per_bed ? performanceTextExcel(performaceDataExcel[0].rtg_inpatient_revenue_per_bed) : '-'],
    ['Patients who reported that their room and bathroom were Always clean Rate', performaceDataExcel[0].patients_roomandbathroomwerealwaysclean_rate ? (performaceDataExcel[0].patients_roomandbathroomwerealwaysclean_rate * 100).toFixed(0) + "%" : '-', performaceDataExcel[0].rtg_my_room_and_bathroom_were_always_clean ? performaceDataExcel[0].rtg_my_room_and_bathroom_were_always_clean : '-', performaceDataExcel[0].rtg_my_room_and_bathroom_were_always_clean ? performanceTextExcel(performaceDataExcel[0].rtg_my_room_and_bathroom_were_always_clean) : '-'],
    ['Patients who reported that their room and bathroom were Always clean State AVG', performaceDataExcel[0].patients_roomandbathroomwerealwaysclean_state_avg ? (performaceDataExcel[0].patients_roomandbathroomwerealwaysclean_state_avg * 100).toFixed(0) + "%" : '-'],
    ['Patients who reported that their room and bathroom were Always clean National AVG', performaceDataExcel[0].patients_roomandbathroomwerealwaysclean_national_avg ? (performaceDataExcel[0].patients_roomandbathroomwerealwaysclean_national_avg * 100).toFixed(0) + "%" : '-'],
    ['Patients who reported that their room and bathroom were Always clean Star Rating (out of 5)', performaceDataExcel[0].patients_roomandbathroomwerealwaysclean_star_rating ? performaceDataExcel[0].patients_roomandbathroomwerealwaysclean_star_rating : '-'],
    ['']]
    return aoaPerformance
}