import React from 'react'
import { CaliforniaHCAITable } from './CaliforniaHCAITable/CaliforniaHCAITable'
import { CaliforniaHCAIInfoCard } from './CalifoniaHCAIInfoCard/CaliforniaHCAIInfoCard'

export const CaliforniaHCAI: React.FC = () => {
  return (
    <React.Fragment>
      <CaliforniaHCAIInfoCard />
      <CaliforniaHCAITable />
    </React.Fragment>
  )
}

export default CaliforniaHCAI
