import {
  Box,
  Divider,
  Grid,
  IconButton,
  Rating,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined'
import StarIcon from '@mui/icons-material/Star'
import {
  backgroundColor,
  performanceText,
  textColor,
} from '../../../../config/commonFunction'
import { MarketFactors } from './MarketFactor'
import {
  useGetMarketSummaryDataByCityLazyQuery,
  useGetMarketSummaryDataByCountyLazyQuery,
  useGetMarketSummaryDataByZipcodeAndRadiusLazyQuery,
  useGetMarketSummaryDataByZipCodeLazyQuery,
  useGetValidateZipcodeLazyQuery,
} from '../../../../__generated__/graphql'
import { renderTitleHeader } from '../../../CommonComponents/RenderTitleHeader/RenderTitleHeader'
import { useHistory, useLocation } from 'react-router-dom'
import { VariantType, useSnackbar } from 'notistack'
import { appInsights } from '../../../../providers/appInsightProvider'
import CloseIcon from '@mui/icons-material/Close'
import { AzureMapsProvider } from 'react-azure-maps'
import ServiceAreaMapPDF from '../../../CommonComponents/Maps/ServiceAreaMapPDF'
import PredictiveNeedsMapPDF from '../../../CommonComponents/Maps/PredictiveNeedsMapPDF'
import CountyAreaMapPDF from '../../../CommonComponents/Maps/CountyAreaMapPDF'

export const MarketSummary: React.FC = () => {
  const search = useLocation().search
  const city = new URLSearchParams(search).get('city')
  const county = new URLSearchParams(search).get('county')
  const zip_code = new URLSearchParams(search).get('zipcode')
  const radius = new URLSearchParams(search).get('radius')

  const [summaryData, setSummaryData] = useState<any>(null)
  const [marketData, setMarketData] = useState<any>([])
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  let location = useLocation()
  let history = useHistory()

  const [
    getValidZipcodes,
    { data: getZipcodes, loading: loadingZipCodes, error: errorZipCode },
  ] = useGetValidateZipcodeLazyQuery({
    variables: {
      zip_codes: zip_code?.split(','),
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (zip_code) {
      getValidZipcodes()
    }
  }, [])

  useEffect(() => {
    if (getZipcodes && getZipcodes.OTHERDATA_ZIPCODE_MAPPING) {
      if (getZipcodes.OTHERDATA_ZIPCODE_MAPPING.length) {
        let zip_codes_array = getZipcodes.OTHERDATA_ZIPCODE_MAPPING
        let zip: string[] = []
        zip_codes_array.forEach((element) => {
          zip.push(element?.zipcode as any)
        })
        let zip_code_not_in_database: string[] = []
        if (zip_code) {
          let split_zipcodes = zip_code.split(',')
          for (let i = 0; i < split_zipcodes.length; i++) {
            const codes = split_zipcodes[i]
            if (zip.indexOf(codes.toString()) === -1) {
              zip_code_not_in_database.push(codes)
            }
          }
          if (zip_code_not_in_database && zip_code_not_in_database.length) {
            ShowSnackBar(
              zip_code_not_in_database.length === 1
                ? `${zip_code_not_in_database} zip code does not exist(s)`
                : `${zip_code_not_in_database.join()}  zip code(s) does not exist(s)`,
              'error'
            )
          }
          if (zip_code_not_in_database.length === split_zipcodes.length) {
            history.push('/')
          }
        }
      } else {
        ShowSnackBar(`${zip_code} zip code(s) does not exist(s)`, 'error')
        history.push('/')
      }
    }
  }, [loadingZipCodes])

  const ShowSnackBar = (message, variant: VariantType) => {
    enqueueSnackbar(renderTitleHeader(message, '', 'snackbar'), {
      variant: variant,
      persist: true,
      anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
      style: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '400px',
        display: 'inline',
      },
      action: (key) => (
        <IconButton
          color="inherit"
          aria-label="dismiss"
          onClick={() => closeSnackbar(key)}
        >
          <CloseIcon />
        </IconButton>
      ),
    })
  }

  const [
    loadSummaryDataByCity,
    {
      data: MarkerSummaryByCity,
      loading: MarkerSummaryByCityLoading,
      error: MarkerSummaryByCityError,
    },
  ] = useGetMarketSummaryDataByCityLazyQuery({
    variables: {
      city,
    },
    fetchPolicy: 'network-only',
  })

  const [
    loadSummaryDataByZipCode,
    {
      data: MarkerSummaryByZipCode,
      loading: MarkerSummaryByZipCodeLoading,
      error: MarkerSummaryByZipCodeError,
    },
  ] = useGetMarketSummaryDataByZipCodeLazyQuery({
    variables: {
      zip_codes: zip_code,
    },
    fetchPolicy: 'network-only',
  })

  const [
    loadSummaryDataByZipCodeAndRadius,
    {
      data: MarkerSummaryByZipCodeAndRadius,
      loading: MarkerSummaryByZipCodeAndRadiusLoading,
      error: MarkerSummaryByZipCodeAndRadiusError,
    },
  ] = useGetMarketSummaryDataByZipcodeAndRadiusLazyQuery({
    variables: {
      zip_code: zip_code,
      radius: radius ? parseInt(radius) : 1,
    },
    fetchPolicy: 'network-only',
  })

  let county_data: string[] = []
  if (county) {
    let counties_array = county.split(',')
    for (let i = 0; i < counties_array.length; i++) {
      const element = counties_array[i]
      let splitted_val = element.split(' - ')
      let string_val: string = splitted_val[1].trim() + splitted_val[0].trim()
      county_data.push(string_val)
    }
  }

  const [
    loadSummaryDataByCounty,
    {
      data: MarkerSummaryByCounty,
      loading: MarkerSummaryByCountyLoading,
      error: MarkerSummaryByCountyError,
    },
  ] = useGetMarketSummaryDataByCountyLazyQuery({
    variables: {
      county: county_data.join(','),
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (MarkerSummaryByCityError instanceof Error) {
      appInsights.trackException({
        exception: MarkerSummaryByCityError,
        error: new Error('Failed to fetch Market summary for City.'),
      })
    } else if (MarkerSummaryByZipCodeError instanceof Error) {
      appInsights.trackException({
        exception: MarkerSummaryByZipCodeError,
        error: new Error('Failed to fetch Market summary for Zip code.'),
      })
    } else if (MarkerSummaryByZipCodeAndRadiusError instanceof Error) {
      appInsights.trackException({
        exception: MarkerSummaryByZipCodeAndRadiusError,
        error: new Error(
          'Failed to fetch Market summary for  zipcode and radius.'
        ),
      })
    } else if (MarkerSummaryByCountyError instanceof Error) {
      appInsights.trackException({
        exception: MarkerSummaryByCountyError,
        error: new Error('Failed to fetch Market summary for county.'),
      })
    }
  }, [
    MarkerSummaryByCityError,
    MarkerSummaryByZipCodeError,
    MarkerSummaryByZipCodeAndRadiusError,
    MarkerSummaryByCountyError,
  ])

  useEffect(() => {
    if (city) {
      loadSummaryDataByCity()
    } else if (zip_code && radius) {
      loadSummaryDataByZipCodeAndRadius()
    } else if (zip_code) {
      loadSummaryDataByZipCode()
    } else if (county) {
      loadSummaryDataByCounty()
    }
  }, [])

  useEffect(() => {
    let Summary_data: any = null
    if (
      MarkerSummaryByCity &&
      MarkerSummaryByCity?.udf_summary_dpr_market &&
      MarkerSummaryByCity.udf_summary_dpr_market.length
    )
      Summary_data = MarkerSummaryByCity?.udf_summary_dpr_market[0]
    else if (
      MarkerSummaryByZipCode &&
      MarkerSummaryByZipCode?.udf_multi_zip_result &&
      MarkerSummaryByZipCode?.udf_multi_zip_result.length
    )
      Summary_data = MarkerSummaryByZipCode?.udf_multi_zip_result[0]
    else if (
      MarkerSummaryByCounty &&
      MarkerSummaryByCounty?.udf_multi_counties_result &&
      MarkerSummaryByCounty.udf_multi_counties_result.length
    )
      Summary_data = MarkerSummaryByCounty?.udf_multi_counties_result[0]
    else if (
      MarkerSummaryByZipCodeAndRadius &&
      MarkerSummaryByZipCodeAndRadius.udf_zip_radius_result &&
      MarkerSummaryByZipCodeAndRadius.udf_zip_radius_result.length
    )
      Summary_data = MarkerSummaryByZipCodeAndRadius.udf_zip_radius_result[0]

    let market_data = [
      {
        Title: 'Market Concentration',
        Heading: Summary_data?.market_size_rating_desc
          ? Summary_data?.market_size_rating_desc
          : 'N/A',
        Comment: Summary_data?.market_size_rating_comment
          ? Summary_data?.market_size_rating_comment
          : 'N/A',
        rating: Summary_data?.market_size_rating
          ? Summary_data?.market_size_rating
          : 0,
        Tooltip:
          'Indicator of how concentrated and competitive a marketplace may be',
        URL: `/market_insights/ServiceArea/${location.search}`,
      },
      {
        Title: '5-Yr Population Growth',
        Heading: Summary_data?.population_growth
          ? Summary_data?.population_growth.toFixed(1) + '%'
          : 'N/A',
        Comment: Summary_data?.pop_growth_comment
          ? Summary_data?.pop_growth_comment
          : 'N/A',
        rating: Summary_data?.population_growth_rating
          ? Summary_data?.population_growth_rating
          : 0,
        Tooltip:
          'Projected 5-year growth of the overall population of the selected market',
        URL: `/market_insights/Demographics/${location.search}`,
      },
      {
        Title: '5-Yr Inpatient Growth',
        Heading: Summary_data?.ip_growth
          ? Summary_data?.ip_growth.toFixed(1) + '%'
          : 'N/A',
        Comment: Summary_data?.ip_growth_comment
          ? Summary_data?.ip_growth_comment
          : 'N/A',
        rating: Summary_data?.ip_growth_rating
          ? Summary_data?.ip_growth_rating
          : 0,
        Tooltip:
          'Projected 5-year growth for inpatient services for the selected market. Inpatient growth translates to need for inpatient beds',
        URL: `/market_insights/PredictiveNeeds${location.search}&siteOfCare=inpatient`,
      },
      {
        Title: '5-Yr Outpatient Growth',
        Heading: Summary_data?.op_growth
          ? Summary_data?.op_growth.toFixed(1) + '%'
          : 'N/A',
        Comment: Summary_data?.op_growth_comment
          ? Summary_data?.op_growth_comment
          : 'N/A',
        rating: Summary_data?.op_growth_rating
          ? Summary_data?.op_growth_rating
          : 0,
        Tooltip:
          'Projected 5-year growth for outpatient services for the selected market. Outpatient growth translates to need for clinics, ambulatory surgery centers, cancer centers, and medical office buildings',
        URL: `/market_insights/PredictiveNeeds${location.search}&siteOfCare=outpatient`,
      },
      {
        Title: 'County Health',
        Heading: Summary_data?.chr_desc ? Summary_data?.chr_desc : 'N/A',
        Comment: Summary_data?.chr_comment ? Summary_data?.chr_comment : 'N/A',
        rating: Summary_data?.chr_rating ? Summary_data.chr_rating : 0,
        Tooltip:
          'Indication of the health of the county that the selected market is located in',
        URL: `/market_insights/CountyData/${location.search}`,
      },
    ]

    setMarketData([...market_data])

    setSummaryData({ ...Summary_data })
  }, [
    MarkerSummaryByCityLoading,
    MarkerSummaryByZipCodeLoading,
    MarkerSummaryByCountyLoading,
    MarkerSummaryByZipCodeAndRadiusLoading,
  ])

  const GreenBoxStyle = styled(Box)(() => ({
    border: '2px solid #E8F5E9',
    borderRadius: '15px',
    width: '240px',
    maxWidth: 'fit-content',
    minWidth: '220px',
  }))

  const HeadingBox = styled(Box)(() => ({
    margin: '10px 0px 0px 10px',
    color: '#22577A',
    fontSize: '14px',
    fontWeight: '600',
  }))

  const ViewMoreGrid = styled(Grid)(() => ({
    background: '#E8F5E9',
    width: '100%',
    fontSize: '13px',
    marginTop: 'auto',
    borderBottomLeftRadius: '15px',
    borderBottomRightRadius: '15px',
  }))

  const OrangeTopDivStyle = styled(Box)(() => ({
    background: backgroundColor(
      summaryData?.ovr_rating ? Math.round(summaryData?.ovr_rating) : 0
    ),
    border: `1px solid ${textColor(
      summaryData?.ovr_rating ? Math.round(summaryData?.ovr_rating) : 0
    )}`,
    borderRadius: '4px',
    padding: '10px 35px 10px 15px',
    margin: '0px 0px 0px 10px',
  }))

  const ClientPerformanceText = styled(Grid)(() => ({
    fontSize: '14px',
    margin: 'auto',
    fontWeight: 'bold',
  }))

  const PerformanceTypeText = styled(Grid)(() => ({
    textAlign: 'center',
    textTransform: 'capitalize',
    fontSize: '14px',
    fontWeight: '600',
    margin: 'auto',
  }))

  const BoldText = styled(Box)(() => ({
    fontSize: '14px',
    fontWeight: '600',
    color: '#3E3E3E',
    margin: '0px 0px 0px 10px',
  }))

  const CommentText = styled(Box)(() => ({
    fontSize: '12px',
    fontWeight: '400',
    color: '#3E3E3E',
    margin: '0px 10px 0px 10px',
    textAlign: 'justify',
    lineHeight: 1.25,
    overflow: 'scroll',
    height: '112px',
    paddingRight: '5px',
  }))

  return (
    <Box>
      <Grid container style={{ overflow: 'scroll', height: '67vh' }}>
        <Grid lg={8} md={8} xs={12} sm={12}>
          {MarkerSummaryByCityLoading ||
            MarkerSummaryByCountyLoading ||
            MarkerSummaryByZipCodeLoading ||
            MarkerSummaryByZipCodeAndRadiusLoading ||
            !marketData ||
            !summaryData ? (
            <Box sx={{ margin: '10px' }}>
              <Typography variant="h3">
                <Skeleton variant="rounded" width="100%" />
              </Typography>
              <br />
              <Skeleton variant="rectangular" width="100%">
                <div style={{ paddingTop: '30%' }}></div>
              </Skeleton>
            </Box>
          ) : summaryData ? (
            <Grid>
              <OrangeTopDivStyle>
                <Grid container>
                  <ClientPerformanceText item lg={8.5} md={8.5}>
                    {renderTitleHeader(
                      'OVERALL MARKET OPPORTUNITY',
                      'DPR’s proprietary scoring system that evaluates a customized market’s attractiveness for DPR and its clients, looking at specific metrics across five key categories. This score is intended for internal use only and should not be shared outside of the company'
                    )}
                  </ClientPerformanceText>
                  <Grid item lg={2} md={2} sx={{ textAlign: 'end' }}>
                    <Rating
                      name="read-only"
                      value={
                        summaryData?.ovr_rating
                          ? Math.round(summaryData?.ovr_rating)
                          : 0
                      }
                      readOnly
                      sx={{
                        color: textColor(
                          summaryData?.ovr_rating
                            ? Math.round(summaryData?.ovr_rating)
                            : 0
                        ),
                      }}
                    />
                  </Grid>
                  <PerformanceTypeText
                    item
                    lg={1.5}
                    md={1.5}
                    sx={{ textAlign: 'end' }}
                  >
                    {performanceText(
                      summaryData?.ovr_rating
                        ? Math.round(summaryData?.ovr_rating)
                        : 0
                    )
                      ?.replace('(', '')
                      .replace(')', '')}
                  </PerformanceTypeText>
                </Grid>
              </OrangeTopDivStyle>

              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1.5}
                sx={{
                  margin: '0px -6px',
                  marginTop: '6px',
                }}
              >
                {marketData
                  ? marketData.map((i) => {
                    return (
                      <Grid item md={4} lg={3}>
                        <GreenBoxStyle>
                          <Grid
                            container
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            height="280px"
                            sx={{ position: 'relative' }}
                          >
                            <Grid item lg={2} md={2}>
                              <HeadingBox>
                                {' '}
                                {renderTitleHeader(i.Title, i.Tooltip)}
                              </HeadingBox>
                            </Grid>
                            <Grid
                              item
                              lg={0.5}
                              sx={{
                                width: '100%',
                                paddingLeft: '6px',
                                paddingRight: '6px',
                              }}
                            >
                              <Divider
                                style={{
                                  borderColor: '#E8F5E9',
                                  borderBottomWidth: 3,
                                }}
                              />
                            </Grid>
                            <Grid item lg={1.5} md={1.5}>
                              <BoldText>{i.Heading}</BoldText>
                            </Grid>

                            <Grid item>
                              <CommentText>{i.Comment}</CommentText>
                            </Grid>
                            <Grid
                              item
                              lg={0.5}
                              sx={{
                                width: '100%',
                                position: 'absolute',
                                bottom: '80px',
                              }}
                            >
                              <Divider
                                style={{
                                  borderColor: '#E8F5E9',
                                }}
                              />
                            </Grid>

                            <Grid
                              item
                              lg={1.5}
                              sx={{
                                width: '100%',
                                position: 'absolute',
                                bottom: '40px',
                              }}
                            >
                              <Box sx={{ marginTop: '10px' }}>
                                <Grid
                                  container
                                  direction="row"
                                  sx={{ fontSize: '14px' }}
                                >
                                  <Grid
                                    item
                                    md={2.5}
                                    sx={{ textAlign: 'center' }}
                                  >
                                    ( {Math.round(i.rating)}/5)
                                  </Grid>
                                  <Grid item md={5.5}>
                                    <StarIcon
                                      fontSize="small"
                                      sx={{
                                        color: textColor(
                                          Math.round(i.rating)
                                        ),
                                      }}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    md={4}
                                    sx={{ textAlign: 'center' }}
                                  >
                                    {performanceText(
                                      Math.round(i.rating),
                                      'capitalize'
                                    )
                                      ?.replace('(', '')
                                      .replace(')', '')}
                                  </Grid>
                                </Grid>
                              </Box>
                            </Grid>

                            <ViewMoreGrid item lg={1.5} md={1.5}>
                              <Stack
                                direction="row"
                                spacing={1}
                                sx={{
                                  float: 'right',
                                  padding: '8px',
                                  cursor: 'pointer',
                                }}
                              >
                                <div
                                  onClick={() => {
                                    history.push(i.URL)
                                    appInsights.trackEvent({
                                      name: `View Button | ${i.Title}`,
                                    })
                                  }}
                                >
                                  View More
                                </div>
                                <KeyboardDoubleArrowRightOutlinedIcon
                                  fontSize="inherit"
                                  sx={{ marginTop: '2px !important' }}
                                />
                              </Stack>
                            </ViewMoreGrid>
                          </Grid>
                        </GreenBoxStyle>
                      </Grid>
                    )
                  })
                  : null}
              </Grid>
            </Grid>
          ) : null}
        </Grid>
        <Grid item lg={0.5} md={0.5}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '97vh',
            }}
          >
            <Divider
              orientation="vertical"
              flexItem
              style={{
                borderColor: '#E8F5E9',
                borderRightWidth: 3,
              }}
            />
          </Box>
        </Grid>

        <Grid lg={3.5} md={3.5} xs={12} sm={12}>
          <MarketFactors></MarketFactors>
        </Grid>
      </Grid>
    </Box>
  )
}

export default MarketSummary
