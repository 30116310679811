import React, { useEffect, useState } from 'react'
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  PDFViewer,
  Image,
  pdf,
} from '@react-pdf/renderer'
import dprlogo from '../../../Assets/dprlogoImage.png'
import panaromalogo from '../../../Assets/PanoramaImage.png'
import defaultmapimage from '../../../Assets/defaultmap.png'
import { alpha, IconButton, MenuItem, Skeleton } from '@mui/material'
import { useLocation } from 'react-router'
import { saveAs } from 'file-saver'
import { useSnackbar, VariantType } from 'notistack'
import { renderTitleHeader } from '../../CommonComponents/RenderTitleHeader/RenderTitleHeader'
import CloseIcon from '@mui/icons-material/Close'
import DialogAlert from '../../CommonComponents/DialogBox/dialogAlert'
import ReactDOM from 'react-dom'
import { SSConst } from '../../../config/config'
import { ExportStatus, useExportData } from '../../../customHooks/useExportData'

interface GeneralInfoPDFTableProps {
  getDataFunction: any
}
export const GeneralInfoPDFTable: React.FC<GeneralInfoPDFTableProps> = ({ getDataFunction }: GeneralInfoPDFTableProps) => {

  const date = Date().split(' ')
  const monthConverter = (month: any) => {
    const monthArray = [
      ['Jan', '01'],
      ['Feb', '02'],
      ['Mar', '03'],
      ['Apr', '04'],
      ['May', '05'],
      ['Jun', '06'],
      ['Jul', '07'],
      ['Aug', '08'],
      ['Sep', '09'],
      ['Oct', '10'],
      ['Nov', '11'],
      ['Dec', '12'],
    ]
    for (let i = 0; i < monthArray.length; i++) {
      if (monthArray[i][0] == month) return monthArray[i][1]
    }
  }

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const ShowSnackBar = (
    message,
    variant: VariantType,
    duration,
    key?: string
  ) => {
    enqueueSnackbar(<div>{message}</div>, {
      variant: variant,
      persist: false,
      anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
      style: {
        flexDirection: 'row',
        maxWidth: '50px',
        marginLeft: '-150px',
      },
      autoHideDuration: duration,
      key: key,
      action: (key) => (
        <IconButton
          color="inherit"
          aria-label="dismiss"
          onClick={() => closeSnackbar(key)}
        >
          <CloseIcon />
        </IconButton>
      ),
    })
    return key
  }


  const search = useLocation().search
  const provider = new URLSearchParams(search).get('provider')
  const formatedDateTime = `${monthConverter(date[1])}/${date[2]}/${date[3]}`
  const pdfStyle = StyleSheet.create({
    body: {
      paddingTop: 15,
      paddingBottom: 45,
      paddingHorizontal: 15,
    },

    headerView: {
      display: 'flex',
      flexDirection: 'row',
      borderTop: '1px solid #EEE',
      paddingTop: 8,
      paddingBottom: 8,
      textAlign: 'center',
      fontSize: '14px',
      color: '#3E3E3E',
      backgroundColor: '#F3F3F3',
      borderRadius: '5px',
    },
    rowView: {
      display: 'flex',
      flexDirection: 'row',
      borderTop: '1px solid #EEE',
      paddingTop: 8,
      paddingBottom: 8,
      textAlign: 'center',
      fontSize: '12px',
      color: '#3E3E3E',
    },
    footer: {
      flexDirection: 'column',
      position: 'absolute',
      bottom: 10,
      left: 10,
    },
  })

  const MyDocument = (GeneralInfo_data, GeneralInfoMap) => (
    <Document>
      <Page size="A4" style={pdfStyle.body}>
        <View wrap fixed>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              paddingBottom: '10px',
              paddingRight: '20px',
            }}
          >
            <Image
              src={dprlogo}
              style={{
                width: '74px',
                height: '45px',
                padding: '5px',
              }}
            ></Image>

            <View
              style={{
                borderRight: '1px solid #CCCCCC',
                height: '30px',
                marginTop: '8px',
                marginLeft: '10px',
              }}
            ></View>

            <Image
              src={panaromalogo}
              style={{
                width: '100px',
                height: '30px',
                marginLeft: '20px',
                marginTop: '8px',
                padding: 5,
              }}
            ></Image>

            <Text
              style={{
                fontSize: '20px',
                color: '#000000',
                marginLeft: 'auto',
                marginTop: '15px',
              }}
            >
              CLIENT INSIGHTS
            </Text>
          </View>

          <View
            style={{
              borderBottom: '2px solid #4CAF50',
              width: '568px',
              marginBottom: '20px',
              marginTop: 10,
            }}
          ></View>
        </View>
        <View wrap style={{ flexDirection: 'row' }}>
          <Text
            style={{
              fontSize: '20px',
              color: '#000000',
              marginRight: 'auto',
              marginTop: '8px',
            }}
          >
            GENERAL INFO
          </Text>
          <View
            style={{
              borderBottom: '1px solid black',
              width: '70%',
              marginBottom: '10px',
              marginTop: 10,
            }}
          ></View>
        </View>
        <Image
          src={GeneralInfoMap}
          style={{ width: '568px', height: '276px', marginTop: '20px' }}
        ></Image>
        <View style={{ flexDirection: 'row' }}>
          <View
            style={{
              border: '1px solid #E8F5E9',
              backgroundColor: '#FFFFFF',
              width: '267.99px',
              height: '300px',
              borderRadius: '8.34px',
              marginTop: '30px',
            }}
          >
            <Text
              style={{
                marginLeft: '15px',
                marginTop: '15px',
                fontSize: 11,
                color: '#22577A',
              }}
            >
              General Information
            </Text>
            <View
              style={{
                border: '1px solid #E8F5E9',
                width: '95%',
                marginLeft: '2.5%',
                marginTop: '20px',
              }}
            ></View>
            <View
              wrap
              style={{
                flexDirection: 'row',
                marginLeft: '12px',
                marginTop: '10px',
              }}
            >
              <View style={{ flexDirection: 'column' }}>
                <View style={{ height: '50px' }}>
                  <Text
                    style={{
                      fontSize: 8,
                      padding: '5px',
                      paddingBottom: '0px',
                      color: '#3E3E3E',
                    }}
                  >
                    Parent Health System
                  </Text>
                  <Text
                    style={{ fontSize: 8, padding: '5px', width: '90px' }}
                  >
                    {GeneralInfo_data[0].parent_health_system
                      ? GeneralInfo_data[0].parent_health_system
                      : '-'}
                  </Text>
                </View>
                <View style={{ height: '50px', marginTop: '15px' }}>
                  <Text
                    style={{
                      fontSize: 8,
                      padding: '5px',
                      paddingBottom: '0px',
                      color: '#3E3E3E',
                    }}
                  >
                    Regional Health System
                  </Text>
                  <Text
                    style={{ fontSize: 8, padding: '5px', width: '90px' }}
                  >
                    {GeneralInfo_data[0].regional_health_system
                      ? GeneralInfo_data[0].regional_health_system
                      : '-'}
                  </Text>
                </View>
                <View style={{ height: '50px', marginTop: '15px' }}>
                  <Text
                    style={{
                      fontSize: 8,
                      padding: '5px',
                      paddingBottom: '0px',
                      color: '#3E3E3E',
                    }}
                  >
                    Ownership
                  </Text>
                  <Text
                    style={{ fontSize: 8, padding: '5px', width: '90px' }}
                  >
                    {GeneralInfo_data[0].ownership
                      ? GeneralInfo_data[0].ownership
                      : '-'}
                  </Text>
                </View>
                <View style={{ height: '50px', marginTop: '15px' }}>
                  <Text
                    style={{
                      fontSize: 8,
                      padding: '5px',
                      paddingBottom: '0px',
                      color: '#3E3E3E',
                    }}
                  >
                    No of Employees
                  </Text>
                  <Text
                    style={{ fontSize: 8, padding: '5px', width: '90px' }}
                  >
                    {GeneralInfo_data[0].no_of_employees
                      ? parseInt(
                        GeneralInfo_data[0].no_of_employees
                      ).toLocaleString('en-US')
                      : '-'}
                  </Text>
                </View>
              </View>
              <View style={{ flexDirection: 'column', marginLeft: '25px' }}>
                <View style={{ height: '50px' }}>
                  <Text
                    style={{
                      fontSize: 8,
                      padding: '5px',
                      paddingBottom: '0px',
                      color: '#3E3E3E',
                    }}
                  >
                    Type of Facility
                  </Text>
                  <Text
                    style={{ fontSize: 8, padding: '5px', width: '90px' }}
                  >
                    {GeneralInfo_data[0].hospital_type}
                  </Text>
                </View>
                <View style={{ height: '50px', marginTop: '14px' }}>
                  <Text
                    style={{
                      fontSize: 8,
                      padding: '5px',
                      paddingBottom: '0px',
                      color: '#3E3E3E',
                    }}
                  >
                    Total Facility Square Footage
                  </Text>
                  <Text
                    style={{ fontSize: 8, padding: '5px', width: '90px' }}
                  >
                    {GeneralInfo_data[0].total_facility_square_footage
                      ? parseInt(
                        GeneralInfo_data[0].total_facility_square_footage
                      ).toLocaleString('en-US')
                      : '-'}
                  </Text>
                </View>
                <View style={{ height: '50px', marginTop: '13px' }}>
                  <Text
                    style={{
                      fontSize: 8,
                      padding: '5px',
                      paddingBottom: '0px',
                      color: '#3E3E3E',
                    }}
                  >
                    No of interns and Residents
                  </Text>
                  <Text
                    style={{ fontSize: 8, padding: '5px', width: '90px' }}
                  >
                    {GeneralInfo_data[0].no_of_interns_and_residents
                      ? parseInt(
                        GeneralInfo_data[0].no_of_interns_and_residents
                      ).toLocaleString('en-US')
                      : '-'}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View
            style={{
              border: '1px solid #E8F5E9',
              backgroundColor: '#FFFFFF',
              width: '267.99px',
              height: '300px',
              borderRadius: '8.34px',
              marginTop: '30px',
              marginLeft: '30px',
            }}
          >
            <Text
              style={{
                marginLeft: '15px',
                marginTop: '15px',
                fontSize: 12,
                color: '#22577A',
              }}
            >
              Key Clinical-Metrics
            </Text>
            <View
              style={{
                border: '1px solid #E8F5E9',
                width: '95%',
                marginLeft: '2.5%',
                marginTop: '20px',
              }}
            ></View>
            <View
              wrap
              style={{
                flexDirection: 'row',
                marginLeft: '12px',
                marginTop: '10px',
              }}
            >
              <View style={{ flexDirection: 'column' }}>
                <View>
                  <Text
                    style={{
                      fontSize: 8,
                      padding: '5px',
                      paddingBottom: '0px',
                      color: '#3E3E3E',
                    }}
                  >
                    No. of staffed beds
                  </Text>
                  <Text style={{ fontSize: 8, padding: '5px' }}>
                    {GeneralInfo_data[0].number_of_beds
                      ? parseInt(
                        GeneralInfo_data[0].number_of_beds
                      ).toLocaleString('en-US')
                      : ' - '}
                  </Text>
                </View>
                <View>
                  <Text
                    style={{
                      fontSize: 8,
                      padding: '5px',
                      paddingBottom: '0px',
                      color: '#3E3E3E',
                    }}
                  >
                    No. of ICU beds
                  </Text>
                  <Text style={{ fontSize: 8, padding: '5px' }}>
                    {GeneralInfo_data[0].intensive_care_unit_beds
                      ? parseInt(
                        GeneralInfo_data[0].intensive_care_unit_beds
                      ).toLocaleString('en-US')
                      : ' - '}
                  </Text>
                </View>
                <View>
                  <Text
                    style={{
                      fontSize: 8,
                      padding: '5px',
                      paddingBottom: '0px',
                      color: '#3E3E3E',
                    }}
                  >
                    No. of discharges
                  </Text>
                  <Text style={{ fontSize: 8, padding: '5px' }}>
                    {GeneralInfo_data[0].no_of_discharges
                      ? parseInt(
                        GeneralInfo_data[0].no_of_discharges
                      ).toLocaleString('en-US')
                      : ' - '}
                  </Text>
                </View>
                <View>
                  <Text
                    style={{
                      fontSize: 8,
                      padding: '5px',
                      paddingBottom: '0px',
                      color: '#3E3E3E',
                    }}
                  >
                    Est. no of outpatient surgeries
                  </Text>
                  <Text style={{ fontSize: 8, padding: '5px' }}>
                    {GeneralInfo_data[0].est_no_of_outpatient_surgeries
                      ? parseInt(
                        GeneralInfo_data[0].est_no_of_outpatient_surgeries
                      ).toLocaleString('en-US')
                      : ' - '}
                  </Text>
                </View>
                <View>
                  <Text
                    style={{
                      fontSize: 8,
                      padding: '5px',
                      paddingBottom: '0px',
                      color: '#3E3E3E',
                    }}
                  >
                    Est. no of outpatient visits
                  </Text>
                  <Text style={{ fontSize: 8, padding: '5px' }}>
                    {GeneralInfo_data[0].est_no_of_outpatient_visits
                      ? parseInt(
                        GeneralInfo_data[0].est_no_of_outpatient_visits
                      ).toLocaleString('en-US')
                      : ' - '}
                  </Text>
                </View>
                <View>
                  <Text
                    style={{
                      fontSize: 8,
                      padding: '5px',
                      paddingBottom: '0px',
                      color: '#3E3E3E',
                    }}
                  >
                    No. of births
                  </Text>
                  <Text style={{ fontSize: 8, padding: '5px' }}>
                    {GeneralInfo_data[0].no_of_births
                      ? parseInt(
                        GeneralInfo_data[0].no_of_births
                      ).toLocaleString('en-US')
                      : ' - '}
                  </Text>
                </View>
              </View>
              <View style={{ flexDirection: 'column', marginLeft: '25px' }}>
                <View>
                  <Text
                    style={{
                      fontSize: 8,
                      padding: '5px',
                      paddingBottom: '0px',
                      color: '#3E3E3E',
                    }}
                  >
                    No. of routine beds
                  </Text>
                  <Text style={{ fontSize: 8, padding: '5px' }}>
                    {GeneralInfo_data[0].routine_service_beds
                      ? parseInt(
                        GeneralInfo_data[0].routine_service_beds
                      ).toLocaleString('en-US')
                      : ' - '}
                  </Text>
                </View>
                <View>
                  <Text
                    style={{
                      fontSize: 8,
                      padding: '5px',
                      paddingBottom: '0px',
                      color: '#3E3E3E',
                    }}
                  >
                    No. of NICU beds
                  </Text>
                  <Text style={{ fontSize: 8, padding: '5px' }}>
                    {GeneralInfo_data[0].nicu_beds
                      ? parseInt(
                        GeneralInfo_data[0].nicu_beds
                      ).toLocaleString('en-US')
                      : ' - '}
                  </Text>
                </View>
                <View>
                  <Text
                    style={{
                      fontSize: 8,
                      padding: '5px',
                      paddingBottom: '0px',
                      color: '#3E3E3E',
                    }}
                  >
                    Est. no of inpatient surgeries
                  </Text>
                  <Text style={{ fontSize: 8, padding: '5px' }}>
                    {GeneralInfo_data[0].est_no_of_inpatient_surgeries
                      ? parseInt(
                        GeneralInfo_data[0].est_no_of_inpatient_surgeries
                      ).toLocaleString('en-US')
                      : ' - '}
                  </Text>
                </View>
                <View>
                  <Text
                    style={{
                      fontSize: 8,
                      padding: '5px',
                      paddingBottom: '0px',
                      color: '#3E3E3E',
                    }}
                  >
                    Est. no total surgeries
                  </Text>
                  <Text style={{ fontSize: 8, padding: '5px' }}>
                    {GeneralInfo_data[0].est_no_of_total_surgeries
                      ? parseInt(
                        GeneralInfo_data[0].est_no_of_total_surgeries
                      ).toLocaleString('en-US')
                      : ' - '}
                  </Text>
                </View>
                <View>
                  <Text
                    style={{
                      fontSize: 8,
                      padding: '5px',
                      paddingBottom: '0px',
                      color: '#3E3E3E',
                    }}
                  >
                    Est. no of ER visits
                  </Text>
                  <Text style={{ fontSize: 8, padding: '5px' }}>
                    {GeneralInfo_data[0].est_no_of_er_visits
                      ? parseInt(
                        GeneralInfo_data[0].est_no_of_er_visits
                      ).toLocaleString('en-US')
                      : ' - '}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View wrap fixed style={pdfStyle.footer}>
          <View
            style={{
              border: '1px solid #D9D9D9',
              marginTop: '10px',
              width: '520px',
              height: '1px',
              left: '25px',
            }}
          ></View>
          <View
            style={{ flexDirection: 'row', fontSize: 8, marginTop: '10px' }}
          >
            <Text style={{ marginLeft: '30px', fontSize: 10 }}>
              Export Date : {formatedDateTime}
            </Text>
            <Text
              style={{
                fontWeight: 800,
                marginLeft: '70px',
                fontSize: 10,
              }}
            >
              Confidential: Do Not Distribute Outside of DPR
            </Text>
            <Text
              style={{ marginLeft: '110px', fontSize: 10 }}
              render={({ pageNumber }) => `Page ${pageNumber}`}
            />
          </View>
        </View>
      </Page>
    </Document>
  )

  return (
    <MenuItem
      style={{ color: 'black' }}
      onClick={() => {
        sessionStorage.setItem(
          SSConst.EXPORT_STATUS,
          'IN_PROGRESS'
        )
        ShowSnackBar(
          'Generating Export File, Please wait !!',
          'info',
          5000,
          'close'
        )
        getDataFunction().then(() => {
          ReactDOM.render(<DialogAlert />, document.createElement('div'))
          const ExportedData = JSON.parse(
            sessionStorage.getItem(SSConst.EXPORT_DATA)!
          )

          const GeneralInfoMap: any = sessionStorage.getItem('GeneralInfoMap')

          const GeneralInfo_data =
            ExportedData &&
              ExportedData.generalInfoSummaryData &&
              ExportedData.generalInfoSummaryData.length
              ? ExportedData.generalInfoSummaryData
              : null
          const pdfBlob = pdf(MyDocument(GeneralInfo_data, GeneralInfoMap)).toBlob()
          pdfBlob.then((data) => {
            const blobObj = new Blob([data], { type: 'application/pdf' })
            saveAs(blobObj, 'Healthcare Panorama - Hospital Insights')
          })
        })
      }}
    >
      Current Tab
    </MenuItem>
  )
}
