import React from "react";
import { Box, Stack, styled } from "@mui/material";
import ClientInsights from "../../../Assets/ClientInsights.webp";
import MarketInsights from "../../../Assets/MarketInsights.webp";

interface ImageComponentProps {
    TabValue: number
}

export const ImageComponent: React.FC<ImageComponentProps> = (props: ImageComponentProps) => {

    const HorizontalLine = styled(Box)(() => ({
        width: "25vw",
        borderTop: "1px solid #FFF",
        position: "absolute",
        marginTop: "5px"
    }));

    const BackgroundImage = styled(Box)(() => ({
        height: "100%",
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat",
        backgroundImage: props.TabValue === 0 ? `url(${ClientInsights})` : `url(${MarketInsights})`,
        position: "relative",
    }));

    const LargeText = styled(Box)(() => ({
        position: "absolute",
        color: "#FFF",
        fontFamily: "Oswald",
        fontWeight: "700",
        fontSize: "4rem",
    }));

    const SmallText = styled(Box)(() => ({
        position: "absolute",
        color: "#FFF",
        fontFamily: "Oswald",
        fontSize: "3.5rem"
    }));

    const MessageText = styled(Box)(() => ({
        marginTop: "-50px",
        color: "#FFF",
        fontFamily: "Open Sans",
        fontWeight: "600",
        fontSize: "1rem",
        position: "absolute",
    }));

    return (
        <Box sx={{ height: "100%" }}>
            {props.TabValue === 0 ?
                <BackgroundImage>
                    <Stack
                        direction="column"
                        justifyContent="centre"
                        alignItems="centre"
                        spacing={10}
                        p="13%"

                    >
                        <div><LargeText > Client insights </LargeText></div>
                        <div><SmallText> made easy</SmallText></div>
                        <div><HorizontalLine></HorizontalLine></div>
                        <div><MessageText >Panorama is an internal DPR healthcare insights and data <br></br> analyzer that gives you a 360 degree view of a customer.</MessageText></div>
                    </Stack>
                </BackgroundImage>
                :
                <BackgroundImage>
                    <Stack
                        direction="column"
                        justifyContent="centre"
                        alignItems="centre"
                        spacing={10}
                        p="13%"
                    >
                        <div><LargeText >Market insights</LargeText></div>
                        <div><SmallText> made easy</SmallText></div>
                        <div><HorizontalLine></HorizontalLine></div>
                        <div><MessageText >Panorama is an internal DPR healthcare insights and data <br></br> analyzer that gives you a 360 degree view of a market.</MessageText></div >
                    </Stack >
                </BackgroundImage >
            }
        </Box>
    );
}