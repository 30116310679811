import React from 'react'
import { ClientInsights } from '../ClientInsights/ClientInsights'
import { MarketInsights } from '../MarketInsights/MarketInsights'
import { Box, Tab, Tabs, TabProps, Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      sx={{ height: '100%' }}
      {...other}
    >
      {value === index && <Box sx={{ p: 0, height: '100%' }}>{children}</Box>}
    </Box>
  )
}

export const FilterTab: React.FC<TabPanelProps> = (props: TabPanelProps) => {
  const [value, setValue] = React.useState(0)
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
    props.value(newValue)
  }

  const TabStyle = styled(Tab)<TabProps>(({ theme }) => ({
    width: '100%',
    textTransform: 'capitalize',
    fontSize: '14px',
    fontWeight: '600',
    fontFamily: 'Open Sans',
  }))

  return (
    <React.Fragment>
      <Box sx={{ width: '100%', height: '100%', bgcolor: 'background.paper' }}>
        <Tabs
          variant="fullWidth"
          value={value}
          onChange={handleChange}
          centered
          textColor="inherit"
          TabIndicatorProps={{
            style: {
              backgroundColor: '#4CAF50',
              color: '#E8F5E9',
            },
          }}
          style={{ background: '#E8F5E9', color: '#4CAF50' }}
        >
          <Tooltip
            placement="top"
            title="Use Client Insights to profile individual clients and understand their current performance"
            arrow
          >
            <TabStyle label="Client Insights" data-client-id="Client Insights Tab" />
          </Tooltip>
          <Tooltip
            placement="top"
            title="Use Market Insights to get immediate access to key data insights for any geographic region"
            arrow
          >
            <TabStyle label="Market Insights" data-market-id="Market Insights Tab" />
          </Tooltip>
        </Tabs>

        <TabPanel value={value} index={0}>
          <ClientInsights />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <MarketInsights />
        </TabPanel>
      </Box>
    </React.Fragment>
  )
}
