export const getExcelMarketPredictiveNeeds = (exportData: any, predictiveData: any) => {
    let dataSet: (string[])[] = []
    const dataFormatHeaders = ['Site of Care', 'AgeGroup', 'ServiceLine', 'Sub ServiceLine', 'Current Volumes', 'Projected 5-Year Volumes', 'Projected 5-Year Growth', 'Projected 10-Year Volumes', 'Projected 10-Year Growth']
    const dataSetFormat = ['site_of_care', 'age_group', 'service_line', 'sub_service_line', 'vol_current', 'volume_5_yr', 'growth_5_yr', 'volume_10_yr', 'growth_10_yr']
    let predictiveNeedsData_asc_all = predictiveData?.PredictiveNeedsTableAmbulatorySurgeryCenterAll ? predictiveData?.PredictiveNeedsTableAmbulatorySurgeryCenterAll : []
    let predictiveNeedsData_asc_adult = predictiveData?.PredictiveNeedsTableAmbulatorySurgeryCenterAdult ? predictiveData?.PredictiveNeedsTableAmbulatorySurgeryCenterAdult : []
    let predictiveNeedsData_asc_geriatric = predictiveData?.PredictiveNeedsTableAmbulatorySurgeryCenterGeriatric ? predictiveData?.PredictiveNeedsTableAmbulatorySurgeryCenterGeriatric : []
    let predictiveNeedsData_asc_pediatric = predictiveData?.PredictiveNeedsTableAmbulatorySurgeryCenterPediatric ? predictiveData?.PredictiveNeedsTableAmbulatorySurgeryCenterPediatric : []

    let predictiveNeedsData_cancer_all = predictiveData?.PredictiveNeedsTableClinicAll ? predictiveData?.PredictiveNeedsTableClinicAll : []
    let predictiveNeedsData_cancer_adult = predictiveData?.PredictiveNeedsTableClinicAdult ? predictiveData?.PredictiveNeedsTableClinicAdult : []
    let predictiveNeedsData_cancer_geriatric = predictiveData?.PredictiveNeedsTableInpatientGeriatric ? predictiveData?.PredictiveNeedsTableInpatientGeriatric : []
    let predictiveNeedsData_cancer_pediatric = predictiveData?.PredictiveNeedsTableInpatientPediatric ? predictiveData?.PredictiveNeedsTableInpatientPediatric : []

    let predictiveNeedsData_ed_all = predictiveData?.PredictiveNeedsTableEmergencyDepartmentAll ? predictiveData?.PredictiveNeedsTableEmergencyDepartmentAll : []
    let predictiveNeedsData_ed_adult = predictiveData?.PredictiveNeedsTableEmergencyDepartmentAdult ? predictiveData?.PredictiveNeedsTableEmergencyDepartmentAdult : []
    let predictiveNeedsData_ed_geriatric = predictiveData?.PredictiveNeedsTableEmergencyDepartmentGeriatric ? predictiveData?.PredictiveNeedsTableEmergencyDepartmentGeriatric : []
    let predictiveNeedsData_ed_pediatric = predictiveData?.PredictiveNeedsTableEmergencyDepartmentPediatric ? predictiveData?.PredictiveNeedsTableEmergencyDepartmentPediatric : []

    let predictiveNeedsData_clinic_all = predictiveData?.PredictiveNeedsTableClinicAll ? predictiveData?.PredictiveNeedsTableClinicAll : []
    let predictiveNeedsData_clinic_adult = predictiveData?.PredictiveNeedsTableClinicAdult ? predictiveData?.PredictiveNeedsTableClinicAdult : []
    let predictiveNeedsData_clinic_geriatric = predictiveData?.PredictiveNeedsTableClinicGeriatric ? predictiveData?.PredictiveNeedsTableClinicGeriatric : []
    let predictiveNeedsData_clinic_pediatric = predictiveData?.PredictiveNeedsTableClinicPediatric ? predictiveData?.PredictiveNeedsTableClinicPediatric : []

    let predictiveNeedsData_inpatient_all = predictiveData?.PredictiveNeedsTableInpatientAll ? predictiveData?.PredictiveNeedsTableInpatientAll : []
    let predictiveNeedsData_inpatient_adult = predictiveData?.PredictiveNeedsTableInpatientAdult ? predictiveData?.PredictiveNeedsTableInpatientAdult : []
    let predictiveNeedsData_inpatient_geriatric = predictiveData?.PredictiveNeedsTableInpatientGeriatric ? predictiveData?.PredictiveNeedsTableInpatientGeriatric : []
    let predictiveNeedsData_inpatient_pediatric = predictiveData?.PredictiveNeedsTableInpatientPediatric ? predictiveData?.PredictiveNeedsTableInpatientPediatric : []

    let predictiveNeedsData_outpatient_all = predictiveData?.PredictiveNeedsTableOutpatientOverallAll ? predictiveData?.PredictiveNeedsTableOutpatientOverallAll : []
    let predictiveNeedsData_outpatient_adult = predictiveData?.PredictiveNeedsTableOutpatientOverAdult ? predictiveData?.PredictiveNeedsTableOutpatientOverAdult : []
    let predictiveNeedsData_outpatient_geriatric = predictiveData?.PredictiveNeedsTableOutpatientOverallGeriatric ? predictiveData?.PredictiveNeedsTableOutpatientOverallGeriatric : []
    let predictiveNeedsData_outpatient_pediatric = predictiveData?.PredictiveNeedsTableOutpatientOverallPediatric ? predictiveData?.PredictiveNeedsTableOutpatientOverallPediatric : []



    if (predictiveNeedsData_asc_all) predictiveNeedsData_asc_all.forEach(element => {
        element['site_of_care'] = 'Ambulatory Surgery Center';
        element['age_group'] = 'all';
        let sett: string[] = [];
        for (let j = 0; j < dataSetFormat.length; j++) {
            for (const key in element) {
                if (key == dataSetFormat[j]) {
                    sett.push(
                        element[key]
                    )
                }
            }
        }
        dataSet.push([...sett])
    })

    if (predictiveNeedsData_asc_adult) predictiveNeedsData_asc_adult.forEach(element => {
        element['site_of_care'] = 'Ambulatory Surgery Center';
        element['age_group'] = 'adult';
        let sett: string[] = [];
        for (let j = 0; j < dataSetFormat.length; j++) {
            for (const key in element) {
                if (key == dataSetFormat[j]) {
                    sett.push(
                        element[key]
                    )
                }
            }
        }
        dataSet.push([...sett])
    })

    if (predictiveNeedsData_asc_geriatric) predictiveNeedsData_asc_geriatric.forEach(element => {
        element['site_of_care'] = 'Ambulatory Surgery Center';
        element['age_group'] = 'Geriatric';
        let sett: string[] = [];
        for (let j = 0; j < dataSetFormat.length; j++) {
            for (const key in element) {
                if (key == dataSetFormat[j]) {
                    sett.push(
                        element[key]
                    )
                }
            }
        }
        dataSet.push([...sett])
    })

    if (predictiveNeedsData_asc_pediatric) predictiveNeedsData_asc_pediatric.forEach(element => {
        element['site_of_care'] = 'Ambulatory Surgery Center';
        element['age_group'] = 'pediatric';
        let sett: string[] = [];
        for (let j = 0; j < dataSetFormat.length; j++) {
            for (const key in element) {
                if (key == dataSetFormat[j]) {
                    sett.push(
                        element[key]
                    )
                }
            }
        }
        dataSet.push([...sett])
    })

    if (predictiveNeedsData_cancer_all) predictiveNeedsData_cancer_all.forEach(element => {
        element['site_of_care'] = 'Cancer Center';
        element['age_group'] = 'all';
        let sett: string[] = [];
        for (let j = 0; j < dataSetFormat.length; j++) {
            for (const key in element) {
                if (key == dataSetFormat[j]) {
                    sett.push(
                        element[key]
                    )
                }
            }
        }
        dataSet.push([...sett])
    })

    if (predictiveNeedsData_cancer_adult) predictiveNeedsData_cancer_adult.forEach(element => {
        element['site_of_care'] = 'Cancer Center';
        element['age_group'] = 'adult';
        let sett: string[] = [];
        for (let j = 0; j < dataSetFormat.length; j++) {
            for (const key in element) {
                if (key == dataSetFormat[j]) {
                    sett.push(
                        element[key]
                    )
                }
            }
        }
        dataSet.push([...sett])
    })

    if (predictiveNeedsData_cancer_geriatric) predictiveNeedsData_cancer_geriatric.forEach(element => {
        element['site_of_care'] = 'Cancer Center';
        element['age_group'] = 'geriatric';
        let sett: string[] = [];
        for (let j = 0; j < dataSetFormat.length; j++) {
            for (const key in element) {
                if (key == dataSetFormat[j]) {
                    sett.push(
                        element[key]
                    )
                }
            }
        }
        dataSet.push([...sett])
    })

    if (predictiveNeedsData_cancer_pediatric) predictiveNeedsData_cancer_pediatric.forEach(element => {
        element['site_of_care'] = 'Cancer Center';
        element['age_group'] = 'pediatric';
        let sett: string[] = [];
        for (let j = 0; j < dataSetFormat.length; j++) {
            for (const key in element) {
                if (key == dataSetFormat[j]) {
                    sett.push(
                        element[key]
                    )
                }
            }
        }
        dataSet.push([...sett])
    })

    if (predictiveNeedsData_ed_all) predictiveNeedsData_ed_all.forEach(element => {
        element['site_of_care'] = 'Emergency Department';
        element['age_group'] = 'all'
        let sett: string[] = [];
        for (let j = 0; j < dataSetFormat.length; j++) {
            for (const key in element) {
                if (key == dataSetFormat[j]) {
                    sett.push(
                        element[key]
                    )
                }
            }
        }
        dataSet.push([...sett])
    })

    if (predictiveNeedsData_ed_adult) predictiveNeedsData_ed_adult.forEach(element => {
        element['site_of_care'] = 'Emergency Department';
        element['age_group'] = 'adult'
        let sett: string[] = [];
        for (let j = 0; j < dataSetFormat.length; j++) {
            for (const key in element) {
                if (key == dataSetFormat[j]) {
                    sett.push(
                        element[key]
                    )
                }
            }
        }
        dataSet.push([...sett])
    })

    if (predictiveNeedsData_ed_pediatric) predictiveNeedsData_ed_pediatric.forEach(element => {
        element['site_of_care'] = 'Emergency Department';
        element['age_group'] = 'pediatric'
        let sett: string[] = [];
        for (let j = 0; j < dataSetFormat.length; j++) {
            for (const key in element) {
                if (key == dataSetFormat[j]) {
                    sett.push(
                        element[key]
                    )
                }
            }
        }
        dataSet.push([...sett])
    })

    if (predictiveNeedsData_ed_geriatric) predictiveNeedsData_ed_geriatric.forEach(element => {
        element['site_of_care'] = 'Emergency Department';
        element['age_group'] = 'geriatric'
        let sett: string[] = [];
        for (let j = 0; j < dataSetFormat.length; j++) {
            for (const key in element) {
                if (key == dataSetFormat[j]) {
                    sett.push(
                        element[key]
                    )
                }
            }
        }
        dataSet.push([...sett])
    })

    if (predictiveNeedsData_clinic_all) predictiveNeedsData_clinic_all.forEach(element => {
        element['site_of_care'] = 'MOB/Clinic';
        element['age_group'] = 'all';
        let sett: string[] = [];
        for (let j = 0; j < dataSetFormat.length; j++) {
            for (const key in element) {
                if (key == dataSetFormat[j]) {
                    sett.push(
                        element[key]
                    )
                }
            }
        }
        dataSet.push([...sett])
    })

    if (predictiveNeedsData_clinic_adult) predictiveNeedsData_clinic_adult.forEach(element => {
        element['site_of_care'] = 'MOB/Clinic';
        element['age_group'] = 'adult';
        let sett: string[] = [];
        for (let j = 0; j < dataSetFormat.length; j++) {
            for (const key in element) {
                if (key == dataSetFormat[j]) {
                    sett.push(
                        element[key]
                    )
                }
            }
        }
        dataSet.push([...sett])
    })

    if (predictiveNeedsData_clinic_geriatric) predictiveNeedsData_clinic_geriatric.forEach(element => {
        element['site_of_care'] = 'MOB/Clinic';
        element['age_group'] = 'geriatric';
        let sett: string[] = [];
        for (let j = 0; j < dataSetFormat.length; j++) {
            for (const key in element) {
                if (key == dataSetFormat[j]) {
                    sett.push(
                        element[key]
                    )
                }
            }
        }
        dataSet.push([...sett])
    })

    if (predictiveNeedsData_clinic_pediatric) predictiveNeedsData_clinic_pediatric.forEach(element => {
        element['site_of_care'] = 'MOB/Clinic';
        element['age_group'] = 'pediatric';
        let sett: string[] = [];
        for (let j = 0; j < dataSetFormat.length; j++) {
            for (const key in element) {
                if (key == dataSetFormat[j]) {
                    sett.push(
                        element[key]
                    )
                }
            }
        }
        dataSet.push([...sett])
    })

    if (predictiveNeedsData_inpatient_all) predictiveNeedsData_inpatient_all.forEach(element => {
        element['site_of_care'] = 'Inpatient';
        element['age_group'] = 'all';
        let sett: string[] = [];
        for (let j = 0; j < dataSetFormat.length; j++) {
            for (const key in element) {
                if (key == dataSetFormat[j]) {
                    sett.push(
                        element[key]
                    )
                }
            }
        }
        dataSet.push([...sett])
    })

    if (predictiveNeedsData_inpatient_adult) predictiveNeedsData_inpatient_adult.forEach(element => {
        element['site_of_care'] = 'Inpatient';
        element['age_group'] = 'adult';
        let sett: string[] = [];
        for (let j = 0; j < dataSetFormat.length; j++) {
            for (const key in element) {
                if (key == dataSetFormat[j]) {
                    sett.push(
                        element[key]
                    )
                }
            }
        }
        dataSet.push([...sett])
    })

    if (predictiveNeedsData_inpatient_geriatric) predictiveNeedsData_inpatient_geriatric.forEach(element => {
        element['site_of_care'] = 'Inpatient';
        element['age_group'] = 'geriatric';
        let sett: string[] = [];
        for (let j = 0; j < dataSetFormat.length; j++) {
            for (const key in element) {
                if (key == dataSetFormat[j]) {
                    sett.push(
                        element[key]
                    )
                }
            }
        }
        dataSet.push([...sett])
    })

    if (predictiveNeedsData_inpatient_pediatric) predictiveNeedsData_inpatient_pediatric.forEach(element => {
        element['site_of_care'] = 'Inpatient';
        element['age_group'] = 'pediatric';
        let sett: string[] = [];
        for (let j = 0; j < dataSetFormat.length; j++) {
            for (const key in element) {
                if (key == dataSetFormat[j]) {
                    sett.push(
                        element[key]
                    )
                }
            }
        }
        dataSet.push([...sett])
    })

    if (predictiveNeedsData_outpatient_all) predictiveNeedsData_outpatient_all.forEach(element => {
        element['site_of_care'] = 'Outpaitent Overall';
        element['age_group'] = 'all';
        let sett: string[] = [];
        for (let j = 0; j < dataSetFormat.length; j++) {
            for (const key in element) {
                if (key == dataSetFormat[j]) {
                    sett.push(
                        element[key]
                    )
                }
            }
        }
        dataSet.push([...sett])
    })

    if (predictiveNeedsData_outpatient_adult) predictiveNeedsData_outpatient_adult.forEach(element => {
        element['site_of_care'] = 'Outpatient Overall';
        element['age_group'] = 'adult';
        let sett: string[] = [];
        for (let j = 0; j < dataSetFormat.length; j++) {
            for (const key in element) {
                if (key == dataSetFormat[j]) {
                    sett.push(
                        element[key]
                    )
                }
            }
        }
        dataSet.push([...sett])
    })

    if (predictiveNeedsData_outpatient_geriatric) predictiveNeedsData_outpatient_geriatric.forEach(element => {
        element['site_of_care'] = 'Outpatient Overall';
        element['age_group'] = 'geriatric';
        let sett: string[] = [];
        for (let j = 0; j < dataSetFormat.length; j++) {
            for (const key in element) {
                if (key == dataSetFormat[j]) {
                    sett.push(
                        element[key]
                    )
                }
            }
        }
        dataSet.push([...sett])
    })

    if (predictiveNeedsData_outpatient_pediatric) predictiveNeedsData_outpatient_pediatric.forEach(element => {
        element['site_of_care'] = 'Outpatient Overall';
        element['age_group'] = 'pediatric';
        let sett: string[] = [];
        for (let j = 0; j < dataSetFormat.length; j++) {
            for (const key in element) {
                if (key == dataSetFormat[j]) {
                    sett.push(
                        element[key]
                    )
                }
            }
        }
        dataSet.push([...sett])
    })


    dataSet.unshift(dataFormatHeaders);

    return dataSet;
}