import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { AzureMapsProvider } from 'react-azure-maps'
import { ExportStatus, useExportData } from '../../../customHooks/useExportData'
import CountyAreaMapPDF from '../../CommonComponents/Maps/CountyAreaMapPDF'
import PredictiveNeedsMapPDF from '../../CommonComponents/Maps/PredictiveNeedsMapPDF'
import ServiceAreaMapPDF from '../../CommonComponents/Maps/ServiceAreaMapPDF'

interface IHiddenComponentProps {
  countyName: any
}

export const HiddenComponent: React.FC<IHiddenComponentProps> = (
  props: IHiddenComponentProps
) => {

  return <div
    style={{
      visibility: 'hidden',
    }}
  >
    <Grid
      item
      lg={0}
      md={0}
      sm={0}
      p="10px"
      xs={0}
      xl={0}
      height={'60vh'}
      overflow="hidden"
    >
      <AzureMapsProvider>
        <ServiceAreaMapPDF MapView="CurrentPopulation" AgeBracket="All" />
      </AzureMapsProvider>
    </Grid>

    <Grid
      item
      lg={0}
      md={0}
      sm={0}
      xs={0}
      xl={0}
      p="10px"
      height={'60vh'}
      overflow="hidden"
    >
      <AzureMapsProvider>
        <PredictiveNeedsMapPDF
          ageBracket="Adult,Geriatric,Pediatric"
          serviceLine="all"
          year="10-YearProjected"
          siteOfCare="inpatient"
        />
      </AzureMapsProvider>
    </Grid>

    <Grid
      item
      lg={0}
      md={0}
      sm={0}
      xs={0}
      xl={0}
      p="10px"
      height={'60vh'}
      overflow="hidden"
    >
      <AzureMapsProvider>
        <CountyAreaMapPDF countyName={sessionStorage.getItem("COUNTY_NAME")!} />
      </AzureMapsProvider>
    </Grid>
  </div>
}
