import { Box, Button, ClickAwayListener, Dialog, Divider, Grid, IconButton, Menu, MenuItem, MenuProps, Popover, Skeleton, Slide, Typography } from '@mui/material';
import React, { useEffect } from 'react'
import { alpha, styled } from '@mui/material/styles';
import LanguageIcon from '@mui/icons-material/Language';
import CallIcon from '@mui/icons-material/CallOutlined';
import LocationOnIcon from '@mui/icons-material/LocationOnOutlined';
import ShareIcon from '@mui/icons-material/Share';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useLocation } from 'react-router-dom';
import { useGetProvidersDetailsQuery, useGetProvidersTableDetailsQuery } from "../../../../../__generated__/graphql";
import { renderTitleHeader } from '../../../../CommonComponents/RenderTitleHeader/RenderTitleHeader';
import { downloadExcelHealthSystem } from '../../../../Export Functionality/ExcelHealthSystem';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { PdfTable } from '../../../../PDF Export Functionality/PdfTable';
import { appInsights } from '../../../../../providers/appInsightProvider';
import { useSnackbar, VariantType } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import ReactDOM from 'react-dom';
import DialogAlert from '../../../../CommonComponents/DialogBox/dialogAlert';

const OuterDivText1 = styled(Grid)(() => ({
  textAlign: "left",
  fontSize: "17px",
  fontWeight: "600",
  color: "#22577A",
}));

const BottomHeaderInnerDiv = styled(Grid)(() => ({
  margin: "0px 16px 8px 16px",
  background: "#FAFAFA",
  borderRadius: "5px",
  padding: "6px"
}))

const DividerStyle = styled(Divider)(() => ({
  marginRight: "20px",
}))

const InnerTextDivStyle = styled(Box)(() => ({
  fontSize: "13px",
  display: "flex"
}))

const InnerTextDivStyle1 = styled(Box)(() => ({
  fontSize: "12px",
  display: "flex"
}))

const OutermostGreyGrid = styled(Grid)(() => ({
  background: "#eee",
  margin: "10px",
  padding: "10px",
  borderRadius: "5px"
}))

const ButtonDivStyle = styled(Button)(() => ({
  border: "1px solid #3e3e3e",
  color: 'black',
  display: "inline-block",
  padding: "5px 6px 2px 6px",
  minWidth: '2px',
  height: '32px',
  "&:hover": { backgroundColor: "transparent", border: '1px solid #3e3e3e' }
}))

const ExportButtonStyle = styled(Button)(() => ({
  marginBottom: "10px",
  color: "#3e3e3e",
  border: "1px solid #3e3e3e",
  textTransform: "capitalize",
  "&:hover": { backgroundColor: "transparent", border: '1px solid #3e3e3e' }

}))


const InnerTextBoxStyled = styled(Box)(() => ({
  margin: "auto 10px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  width: "135px"
}))

const InnerTextBoxProviderStyled = styled(Box)(() => ({
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  width: "330px"
}))

interface DetailCardProps {
  dataSource: any,
}

export const DetailCard: React.FC<DetailCardProps> = (props: DetailCardProps) => {

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);

  };

  const search = useLocation().search;
  const type = new URLSearchParams(search).get('type');
  const definitive_id = new URLSearchParams(search).get('id');
  const provider = new URLSearchParams(search).get('provider');

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const ShowSnackBar = (message, variant: VariantType, duration, key?: string) => {
    enqueueSnackbar(<div>{message}</div>, {
      variant: variant,
      anchorOrigin: { horizontal: "center", vertical: "bottom" },
      style: {
        flexDirection: 'row',
        maxWidth: '50px',
        marginLeft: '-150px',
      },

      key: key,
      autoHideDuration: duration,
      action: (key) => (
        <IconButton
          color="inherit"
          aria-label="dismiss"
          onClick={() => closeSnackbar(key)}
        >
          <CloseIcon />
        </IconButton>
      ),
    })
    return key;
  }

  const { data: getProviderData, loading: loadingProviderState, error: errorProviderState } = useGetProvidersDetailsQuery({
    variables: {
      definitive_id
    }
  });

  useEffect(() => {
    if (errorProviderState instanceof Error) {
      appInsights.trackException({ exception: errorProviderState, error: new Error("Failed to fetch Provider Details.") })
    }
  }, [errorProviderState])

  let provider_data = getProviderData && getProviderData.DEFINITIVEHEALTHCARE_VW_HOSPITAL_INFO && getProviderData.DEFINITIVEHEALTHCARE_VW_HOSPITAL_INFO.length ?
    getProviderData.DEFINITIVEHEALTHCARE_VW_HOSPITAL_INFO[0] : null;

  const { data, loading, error } = useGetProvidersTableDetailsQuery({
    variables: {
      definitive_id
    }
  })

  const downloadExcelData = () => {
    let convertedData = data?.DEFINITIVEHEALTHCARE_VW_HOSPITAL_INFO
    let outDataFormatHeaders = ['Hospital Name', 'Hospital Type', 'Address', 'City', 'State', 'County', 'Zip Code', 'Phone', 'Overall Performance Score', '# of Discharges', '# of Staffed Beds', '# of Licensed Beds', 'Bed Utilization Rate', '# of Employees',
      'Number of Interns and Residents', 'Est # of Inpatient Surgeries', 'Est # of Outpatient Surgeries', 'Est # of Total Surgeries', 'Est # of Outpatient Visits', 'Est # of ER Visits', 'Number of Births', 'Number of Operating Rooms', 'Total Facility Square Footage', 'Average Age of Facility (Years)', 'Inpatient Cost Center Square Footage', 'Outpatient Cost Center Square Footage', 'Intensive Care Unit Beds', 'Neonatal Intensive Care Unit Beds',
      'Total Patient Revenue', 'Net Patient Revenue', 'Net Operating Profit Margin', 'Outpatient Revenue', 'Inpatient Revenue', 'Est. IT Operating Expense Budget', 'Est. IT Capital Budget', 'Capital Expenditures', 'Net Income', 'Net Income Margin', 'EBITDA', 'Cash on Hand', 'Days Cash on Hand', 'Account Receivable', 'Current Ratio', 'Debt to Equity Ratio', 'Bad Debt to Accounts Receivable Ratio', 'Payor Mix: Medicare Days', 'Payor Mix: Medicaid Days', 'Payor Mix: Private/Self-Pay/Other Days', 'Hospital Compare Overall Rating',
      'FY2021 Hospital Acquired Condition Rate Penalty', 'Est. FY2021 Revenue Loss Due to Hospital Acquired Condition Penalty', 'FY2020 Hospital Acquired Condition Rate Penalty', 'Est. FY2020 Revenue Loss Due to Hospital Acquired Condition Penalty', 'All Cause Hospital-Wide Readmission Rate', 'All Cause Hospital Wide Readmission Cases', 'Patient Survey (HCAHPS) Summary Star Rating', 'Patients who reported that their nurses Always communicated well Rate', 'Patients who reported that their nurses Always communicated well State AVG', 'Patients who reported that their nurses Always communicated well National AVG', 'Patients who reported that their nurses Always communicated well Star Rating (out of 5)',
      'Patients who reported that their doctors Always communicated well Rate', 'Patients who reported that their doctors Always communicated well State AVG', 'Patients who reported that their doctors Always communicated well National AVG', 'Patients who reported that their doctors Always communicated well Star Rating (out of 5)', 'Patients who reported that they Always received help as soon as they wanted Rate', 'Patients who reported that they Always received help as soon as they wanted State AVG', 'Patients who reported that they Always received help as soon as they wanted National AVG', 'Patients who reported that they Always received help as soon as they wanted Star Rating (out of 5)',
      'Patients who reported that their room and bathroom were Always clean Rate', 'Patients who reported that their room and bathroom were Always clean State AVG', 'Patients who reported that their room and bathroom were Always clean National AVG', 'Patients who reported that their room and bathroom were Always clean Star Rating (out of 5)', 'Patients who gave their hospital a rating of 9 or 10 on a scale from 0 (lowest) to 10 (highest) Rate', 'Patients who gave their hospital a rating of 9 or 10 on a scale from 0 (lowest) to 10 (highest) State AVG', 'Patients who gave their hospital a rating of 9 or 10 on a scale from 0 (lowest) to 10 (highest) National AVG',
      'Patients who reported yes they would definitely recommend the hospital Rate', 'Patients who reported yes they would definitely recommend the hospital State AVG', 'Patients who reported yes they would definitely recommend the hospital National AVG', 'Patients who reported yes they would definitely recommend the hospital Star Rating (out of 5)', 'Average number of minutes patients spent in the emergency department before being sent home Rate', 'Percentage of patients who left the emergency department before being seen Rate', 'Electronic Health/Medical Record - Inpatient', 'Electronic Health/Medical Record - Ambulatory', 'Computerized Physician Order Entry (CPOE)', 'Population Health Management', 'Emergency Department Information System', 'Cardiology Information System',
      'Revenue Cycle Management', 'Chief Executive Officer', 'Chief Executive Officer Title', 'Chief Executive Officer Email', 'Chief Executive Officer Phone', 'Chief Financial Officer', 'Chief Financial Officer Title', 'Chief Financial Officer Email', 'Chief Financial Officer Phone', 'Chief Information Officer', 'Chief Information Officer Title', 'Chief Information Officer Email', 'Chief Information Officer Phone', 'Chief Operations Officer', 'Chief Operations Officer Title', 'Chief Operations Officer Email', 'Chief Operations Officer Phone', 'Chief Medical Officer', 'Chief Medical Officer Title', 'Chief Medical Officer Email', 'Chief Medical Officer Phone',
      'Chief Nursing Officer', 'Chief Nursing Officer Title', 'Chief Nursing Officer Email', 'Chief Nursing Officer Phone', 'HIM Director', 'HIM Director Title', 'HIM Director Email', 'HIM Director Phone', 'Director of IT', 'Director of IT Title', 'Director of IT Email', 'Director of IT Phone']


    let outDataFormat = ['hospital_name', 'hospital_type', 'address', 'city', 'state', 'county', 'zipcode', 'phone', 'total_performance_score', 'no_of_discharges', 'number_of_beds', 'number_of_licensed_beds', 'bed_utilization', 'no_of_employees',
      'no_of_interns_and_residents', 'est_no_of_inpatient_surgeries', 'est_no_of_outpatient_surgeries', 'est_no_of_total_surgeries', 'est_no_of_outpatient_visits', 'est_no_of_er_visits', 'no_of_births', 'no_of_operating_rooms', 'total_facility_square_footage', 'avg_age_of_plant', 'inpatient_sq_footage', 'outpatient_sq_footage', 'intensive_care_unit_beds', 'nicu_beds',
      'total_patient_revenue', 'net_patient_revenue', 'net_operating_profit_margin', 'outpatient_revenue', 'inpatient_revenue', 'est_it_operating_expense_budget', 'est_it_capital_budget', 'capital_expenditures', 'net_income', 'net_income_margin', 'ebitda', 'cash_on_hand', 'days_cash_on_hands', 'accounts_receivable', 'current_ratio', 'debt_to_equity_ratio', 'bad_debt_to_accounts_receivable_ratio', 'payor_mix_medicare_days', 'payor_mix_medicaid_days', 'payor_mix_private_selfpay_otherdays', 'hospital_compare_overall_rating',
      'hospital_acquired_condition_rate_penalty', 'revenue_penalty_due_to_hospital_acquired_condition', 'prev_year_hospital_acquired_condition_rate_penalty', 'prev_year_revenue_penalty_due_to_hosp_acquired_cond', 'all_cause_hospital_wide_readmission_rate', 'all_cause_hospital_wide_readmission_cases', 'patient_experience_hcahps_rating', 'patients_whose_nursesalwayscommunicatedwell_rate', 'patients_whose_nursesalwayscommunicatedwell_state_avg', 'patients_whose_nursesalwayscommunicatedwell_national_avg', 'patients_whose_nursesalwayscommunicatedwell_star_rating',
      'patients_whosedoctorsalwayscommunicatedwell_rate', 'patients_whosedoctorsalwayscommunicatedwell_state_avg', 'patients_whosedoctorsalwayscommunicatedwell_national_avg', 'patients_whosedoctorsalwayscommunicatedwell_star_rating', 'patients_who_receivedhelpasatheywanted_rate', 'patients_who_receivedhelpasatheywanted_state_avg', 'patients_who_receivedhelpasatheywanted_national_avg', 'patients_who_receivedhelpasatheywanted_star_rating', 'patients_roomandbathroomwerealwaysclean_rate', 'patients_roomandbathroomwerealwaysclean_state_avg', 'patients_roomandbathroomwerealwaysclean_national_avg', 'patients_roomandbathroomwerealwaysclean_star_rating',
      'patients_gavetheirhospitalratingof9or10_rate', 'patients_gavetheirhospitalratingof9or10_state_avg', 'patients_gavetheirhospitalratingof9or10_national_avg', 'patients_woulddefinitelyrecommendthehospital_rate', 'patients_woulddefinitelyrecommendthehospital_state_avg', 'patients_woulddefinitelyrecommendthehospital_national_avg', 'patients_woulddefinitelyrecommendthehospital_star_rating', 'mins_in_ed_before_sent_home', 'pct_left_ed_before_being_seen', 'electronic_health_medical_record_inpatient', 'electronic_health_medical_record_ambulatory', 'computerized_physician_order_entry', 'population_health_management', 'emergency_department_information_system', 'cardiology_information_system',
      'revenue_cycle_management', 'chief_executive_officer', 'chief_executive_officer_title', 'chief_executive_officer_email', 'chief_executive_officer_phone', 'chief_financial_officer', 'chief_financial_officer_title', 'chief_financial_officer_email', 'chief_financial_officer_phone', 'chief_information_officer', 'chief_information_officer_title', 'chief_information_officer_email', 'chief_information_officer_phone', 'chief_operations_officer', 'chief_operations_officer_title', 'chief_operations_officer_email', 'chief_operations_officer_phone', 'chief_medical_officer', 'chief_medical_officer_title', 'chief_medical_officer_email', 'chief_medical_officer_phone',
      'chief_nursing_officer', 'chief_nursing_officer_title', 'chief_nursing_officer_email', 'chief_nursing_officer_phone', 'him_director', 'him_director_title', 'him_director_email', 'him_director_phone', 'director_of_it', 'director_of_it_title', 'director_of_it_email', 'director_of_it_phone']

    let dataSet: (string[])[] = []
    for (let i = 0; i < convertedData!.length; i++) {
      let sett: string[] = []
      let j
      for (j = 0; j < outDataFormat.length; j++) {
        for (const key in convertedData![i]) {
          if (key === outDataFormat[j]) {
            sett.push(
              outDataFormat[j] === "net_operating_profit_margin" && convertedData![i][key] ?
                parseFloat(convertedData![i][key]).toFixed(2) :
                outDataFormat[j] === "address" && convertedData![i][key] ?
                  (convertedData![i][key])?.replace(" , ", " ") :
                  outDataFormat[j] === 'avg_age_of_plant' && convertedData![i][key] ?
                    (parseFloat(convertedData![i][key])).toFixed(2) :
                    convertedData![i][key])
          }
        }
      }
      dataSet.push([...sett])
    }
    dataSet.unshift(outDataFormatHeaders)
    downloadExcelHealthSystem(dataSet, provider_data)
  }

  return <React.Fragment>

    <Grid container>
      <BottomHeaderInnerDiv
        container
      >
        {
          loadingProviderState ?
            <Box sx={{ width: "100%" }}>
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </Box>
            :
            type === "Hospital" && provider_data ?
              <OutermostGreyGrid container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start">
                <Grid lg={12} item>
                  <OuterDivText1>
                    <InnerTextBoxProviderStyled>
                      {renderTitleHeader(provider ? provider : "")}
                    </InnerTextBoxProviderStyled>
                  </OuterDivText1>
                  <Grid direction="row" container rowSpacing={1} style={{ marginTop: "2px" }}>
                    <Grid lg={2} item>
                      <InnerTextDivStyle><LanguageIcon style={{ fontSize: "1.2rem", color: "#3e3e3e" }} />
                        <InnerTextBoxStyled>
                          {
                            renderTitleHeader(provider_data?.website ? provider_data?.website : "-", "", "link")
                          }
                        </InnerTextBoxStyled>
                      </InnerTextDivStyle>
                    </Grid>
                    <Grid lg={1.5} item>
                      <InnerTextDivStyle ><CallIcon style={{ fontSize: "1.2rem", color: "#3e3e3e" }} />
                        <InnerTextBoxStyled>
                          <a target="_blank" rel="noreferrer" href={`tel:+${provider_data?.phone ? provider_data?.phone : "-"}`}>{provider_data?.phone ? provider_data?.phone : "-"}</a>
                        </InnerTextBoxStyled>
                      </InnerTextDivStyle>
                    </Grid>
                    <Grid lg={8.5} item>
                      <InnerTextDivStyle ><LocationOnIcon style={{ fontSize: "1.2rem", color: "#3e3e3e" }} />
                        <InnerTextBoxStyled style={{ width: "270px" }}>{
                          renderTitleHeader(provider_data?.address ? provider_data?.address.replace(" , ", " ") : "-")
                        }</InnerTextBoxStyled>
                      </InnerTextDivStyle>
                    </Grid>
                  </Grid>
                </Grid>
              </OutermostGreyGrid>
              :
              <OutermostGreyGrid container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start">
                <Grid lg={3} item style={{ padding: "10px" }}>
                  <OuterDivText1>
                    <InnerTextBoxProviderStyled>
                      {renderTitleHeader(provider ? provider : "")}
                    </InnerTextBoxProviderStyled>
                  </OuterDivText1>
                  <Grid direction="row" container rowSpacing={1} style={{ marginTop: "2px" }}>
                    <Grid lg={6} item>
                      <InnerTextDivStyle ><LanguageIcon style={{ fontSize: "1.2rem" }} /> <InnerTextBoxStyled>{
                        renderTitleHeader(provider_data?.website ? provider_data?.website : "-", "", "link")
                      }</InnerTextBoxStyled></InnerTextDivStyle>
                    </Grid>
                    <Grid lg={6} item>
                      <InnerTextDivStyle ><CallIcon style={{ fontSize: "1.2rem" }} /> <InnerTextBoxStyled>
                        <a target="_blank" rel="noreferrer" href={`tel:+${provider_data?.phone ? provider_data?.phone : "-"}`}>{provider_data?.phone ? provider_data?.phone : "-"}</a>
                      </InnerTextBoxStyled></InnerTextDivStyle>
                    </Grid>
                  </Grid>
                  <Grid container style={{ marginTop: "10px" }}>
                    <InnerTextDivStyle ><LocationOnIcon style={{ fontSize: "1.2rem" }} /> <InnerTextBoxStyled style={{ width: "270px" }}>{renderTitleHeader(provider_data?.address ? provider_data?.address.replace(" , ", " ") : "-")}</InnerTextBoxStyled></InnerTextDivStyle>
                  </Grid>
                </Grid>
                <Grid lg={0.2} item style={{ display: "contents" }}><DividerStyle orientation="vertical" flexItem /></Grid>
                <Grid lg={2} item style={{ padding: "10px" }} >
                  <InnerTextDivStyle1>Number of Hospitals</InnerTextDivStyle1>
                  <InnerTextDivStyle><b>{provider_data?.number_of_hospitals ? provider_data?.number_of_hospitals : "-"}</b></InnerTextDivStyle>
                  <br />
                  <InnerTextDivStyle1>Number of Imaging Centers</InnerTextDivStyle1>
                  <InnerTextDivStyle><b>{provider_data?.number_of_imaging_centers ? provider_data?.number_of_imaging_centers : "-"}</b></InnerTextDivStyle>
                </Grid>
                <Grid lg={0.2} item style={{ display: "contents" }}><DividerStyle orientation="vertical" flexItem /></Grid>
                <Grid lg={2} item style={{ padding: "10px" }}>
                  <InnerTextDivStyle1>Number of Urgent Care Clinics</InnerTextDivStyle1>
                  <InnerTextDivStyle><b>{provider_data?.number_of_urgent_care_clinics ? provider_data?.number_of_urgent_care_clinics : "-"}</b></InnerTextDivStyle>
                  <br />
                  <InnerTextDivStyle1>Number of Physician Groups</InnerTextDivStyle1>
                  <InnerTextDivStyle><b>{provider_data?.number_of_physician_groups ? provider_data?.number_of_physician_groups : "-"}</b></InnerTextDivStyle>
                </Grid>
                <Grid lg={0.2} item style={{ display: "contents" }}><DividerStyle orientation="vertical" flexItem /></Grid>
                <Grid lg={3.2} item style={{ padding: "10px" }}>
                  <InnerTextDivStyle1>Number of Ambulatory Surgery Centers</InnerTextDivStyle1>
                  <InnerTextDivStyle><b>{provider_data?.number_of_ambulatory_surgery_centers ? provider_data?.number_of_ambulatory_surgery_centers : "-"}</b></InnerTextDivStyle>
                  <br />
                  <InnerTextDivStyle1>Number of Skilled Nursing Facilities</InnerTextDivStyle1>
                  <InnerTextDivStyle><b>{provider_data?.number_of_skilled_nursing_facilities ? provider_data?.number_of_skilled_nursing_facilities : "-"}</b></InnerTextDivStyle>
                </Grid>
                <Grid lg={0.2} item style={{ display: "contents" }}><DividerStyle orientation="vertical" flexItem /></Grid>
                <Grid lg={1} style={{ textAlign: "right", margin: "auto", padding: "10px" }} >
                  <div>
                    <Typography sx={{ p: 1, width: '100px', justifyContent: 'right', marginLeft: '-5px' }}>
                      {
                        loading ?
                          <ExportButtonStyle variant="outlined"
                            disabled={true}
                            id="export-button"
                            endIcon={<KeyboardArrowDownIcon />}>
                            Export
                          </ExportButtonStyle>
                          :
                          <PopupState variant='dialog' popupId='PDF'>
                            {(popupState) => (

                              <div>
                                <ClickAwayListener onClickAway={() => popupState.close()}>
                                  <div>
                                    <ExportButtonStyle variant="outlined" data-export-id="Export"
                                      {...bindTrigger(popupState)}
                                      id="export-button"
                                      endIcon={<KeyboardArrowDownIcon />}>
                                      Export
                                    </ExportButtonStyle>
                                    <Popover
                                      {...bindPopover(popupState)}
                                      anchorOrigin={{
                                        vertical: -100,
                                        horizontal: -90,
                                      }}
                                      transformOrigin={{
                                        vertical: -140,
                                        horizontal: -100,
                                      }}
                                      onClick={() => {
                                        popupState.close();
                                      }}
                                    >
                                      <div style={{ width: '90px' }} onClick={() => { ReactDOM.render(<DialogAlert />, document.createElement('div')); }}><PdfTable provider_data={provider_data} dataSource={props?.dataSource} /></div>
                                      <div style={{ width: '90px' }}><MenuItem onClick={() => { ReactDOM.render(<DialogAlert />, document.createElement('div')); ShowSnackBar('Generating Export File, Please wait !!', 'info', 4000, "close"); downloadExcelData(); popupState.close(); }} style={{ fontSize: 14, fontWeight: 500, color: 'black' }}>EXCEL</MenuItem></div>
                                    </Popover>
                                  </div>
                                </ClickAwayListener>
                              </div>
                            )}
                          </PopupState>
                      }
                    </Typography>
                  </div>
                  <div style={{ marginTop: '-10px' }}>
                    <ButtonDivStyle style={{ display: 'none' }} data-share-id="Share" variant='outlined' onClick={() => {
                      let formattedBody = `Hi,\n\nBelow is the link to Panorama-Healthcare - Insights\nLink: ${window.location.href}\n\n\nThis data is considered proprietary and confidential and should not be forwarded to parties outside of DPR.`;
                      let mailToLink = `mailto:?subject=Panorama-Healthcare - Insights&body=` + encodeURIComponent(formattedBody);
                      window.location.href = mailToLink;
                    }} >
                      <ShareIcon style={{ fontSize: "1.2rem", cursor: "pointer" }}
                      />
                    </ButtonDivStyle>
                  </div>
                </Grid>
              </OutermostGreyGrid>
        }
      </BottomHeaderInnerDiv>
    </Grid>
  </React.Fragment>
}

