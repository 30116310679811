import { Box, Divider, Grid, Skeleton, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined'
import CakeOutlinedIcon from '@mui/icons-material/CakeOutlined'
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined'
import PaymentsIcon from '@mui/icons-material/Payments'
import { useLocation } from 'react-router-dom'
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined'
import { useGetDemographicsCardsDataByCityLazyQuery, useGetDemographicsCardsDataByCountyLazyQuery, useGetDemographicsCardsDataByZipAndRadiusLazyQuery, useGetDemographicsCardsDataByZipLazyQuery, useGetDemographicsCardsDataByZipQuery } from '../../../../__generated__/graphql'
import { numFormatter } from '../../../../config/commonFunction'
import { appInsights } from '../../../../providers/appInsightProvider'

export const DemographicsCard: React.FC = () => {
  const search = useLocation().search
  const city = new URLSearchParams(search).get('city');
  const county = new URLSearchParams(search).get('county');
  const zip_code = new URLSearchParams(search).get('zipcode');
  const radius = new URLSearchParams(search).get('radius');

  const [cardData, setCardData] = useState(null);

  let label_mapping = {
    current_population: "Current Population",
    projected_population: "5-Yr Projected Population",
    pop_growth: "5-Yr Growth %",
    median_age: "Median Age",
    median_hh_income: "Median Household income",
    high_school_grad_pct: "High School Graduation Rate"
  }

  const [loadDemographicsDatabyZip, {
    data: DemographicsDatabyZip,
    loading: DemographicsDatabyZipLoading,
    error: DemographicsDatabyZipError,
  }] = useGetDemographicsCardsDataByZipLazyQuery({
    variables: {
      zipcode: zip_code
    },
    fetchPolicy: 'network-only'
  })

  const [loadDemographicsDatabyCity, {
    data: DemographicsDatabyCity,
    loading: DemographicsDatabyCityLoading,
    error: DemographicsDatabyCityError,
  }] = useGetDemographicsCardsDataByCityLazyQuery({
    variables: {
      city
    },
    fetchPolicy: 'network-only'
  })


  let county_data: string[] = [];
  if (county) {
    let counties_array = county.split(",");
    for (let i = 0; i < counties_array.length; i++) {
      const element = counties_array[i];
      let splitted_val = element.split(" - ");
      let string_val: string = splitted_val[1].trim() + splitted_val[0].trim();
      county_data.push(string_val);
    }
  }
  const [loadDemographicsDatabyCounty, {
    data: DemographicsDatabyCounty,
    loading: DemographicsDatabyCountyLoading,
    error: DemographicsDatabyCountyError,
  }] = useGetDemographicsCardsDataByCountyLazyQuery({
    variables: {
      county: county_data.join(",")
    },
    fetchPolicy: 'network-only'
  })
  const [loadDemographicsDatabyZipCodeAndRadius, {
    data: DemographicsDatabyZipCodeAndRadius,
    loading: DemographicsDatabyZipCodeAndRadiusLoading,
    error: DemographicsDatabyZipCodeAndRadiusError,
  }] = useGetDemographicsCardsDataByZipAndRadiusLazyQuery({
    variables: {
      zipcode: zip_code,
      radius: radius ? parseInt(radius) : 1
    },
    fetchPolicy: 'network-only'
  })

  useEffect(() => {
    if (DemographicsDatabyZipError instanceof Error) {
      appInsights.trackException({ exception: DemographicsDatabyZipError, error: new Error("Failed to fetch Demographics Card Data for Zip Code.") })
    }
    else if (DemographicsDatabyCityError instanceof Error) {
      appInsights.trackException({ exception: DemographicsDatabyCityError, error: new Error("Failed to fetch Demographics Card Data for City.") })
    }
    else if (DemographicsDatabyCountyError instanceof Error) {
      appInsights.trackException({ exception: DemographicsDatabyCountyError, error: new Error("Failed to fetch Demographics Card Data for county.") })
    }
    else if (DemographicsDatabyZipCodeAndRadiusError instanceof Error) {
      appInsights.trackException({ exception: DemographicsDatabyZipCodeAndRadiusError, error: new Error("Failed to fetch Demographics Card Data for zipcode and radius.") })
    }
  }, [DemographicsDatabyZipError, DemographicsDatabyCityError, DemographicsDatabyCountyError, DemographicsDatabyZipCodeAndRadiusError])

  useEffect(() => {
    if (city) {
      loadDemographicsDatabyCity()
    }
    else if (zip_code && radius) {
      loadDemographicsDatabyZipCodeAndRadius()
    }
    else if (zip_code) {
      loadDemographicsDatabyZip()
    }
    else if (county) {
      loadDemographicsDatabyCounty()
    }
  }, [])

  useEffect(() => {
    let card_data: any = null;
    if (DemographicsDatabyZip && DemographicsDatabyZip?.udf_demo_avg_zip && DemographicsDatabyZip?.udf_demo_avg_zip.length)
      card_data = DemographicsDatabyZip?.udf_demo_avg_zip[0];
    else if (DemographicsDatabyCity && DemographicsDatabyCity?.udf_demo_avg_dpr_market && DemographicsDatabyCity?.udf_demo_avg_dpr_market.length)
      card_data = DemographicsDatabyCity?.udf_demo_avg_dpr_market[0];
    else if (DemographicsDatabyCounty && DemographicsDatabyCounty?.udf_demo_avg_county && DemographicsDatabyCounty?.udf_demo_avg_county.length)
      card_data = DemographicsDatabyCounty?.udf_demo_avg_county[0];
    else if (DemographicsDatabyZipCodeAndRadius && DemographicsDatabyZipCodeAndRadius.udf_demo_avg_zip_radius && DemographicsDatabyZipCodeAndRadius.udf_demo_avg_zip_radius.length)
      card_data = DemographicsDatabyZipCodeAndRadius.udf_demo_avg_zip_radius[0];

    setCardData(card_data);

  }, [DemographicsDatabyZipCodeAndRadiusLoading, DemographicsDatabyCountyLoading, DemographicsDatabyCityLoading, DemographicsDatabyZipLoading])


  const OuterDiv = styled(Box)(() => ({
    border: '2px solid #E8F5E9',
    borderRadius: '15px',
    width: '215px',
    marginLeft: '15px',
    marginTop: '18px',
  }))

  const HeadingCard = styled(Box)(() => ({
    fontSize: '15px',
    fontWeight: '600',
    color: '#22577A',
    textAlign: 'center',
    padding: '5px',
  }))

  const DataText = styled(Box)(() => ({
    fontSize: '21px',
    fontWeight: '600',
    color: '#3E3E3E',
    textAlign: 'center',
    padding: '10px',
  }))

  return (
    DemographicsDatabyZipCodeAndRadiusLoading || DemographicsDatabyCountyLoading || DemographicsDatabyCityLoading || DemographicsDatabyZipLoading ?
      <Grid container wrap="wrap">
        {Array.from(new Array(4)).map((item, index) => (
          <Box key={index} sx={{ width: 210, marginRight: 1, my: 1, marginLeft: '5%' }}>
            <Skeleton variant="rectangular" width={210} height={118} />
          </Box>
        ))}
      </Grid>
      :
      cardData && Object.keys(cardData).length ?
        <Box
          style={{
            display: 'inline-block',
            overflow: 'scroll',
            height: '40vh',
            marginLeft: '5%'
          }}
        >
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            {Object.keys(label_mapping).map((i) => {

              return (
                <OuterDiv>
                  <Grid
                    spacing={2}
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    height="135px"
                  >
                    <Grid item md={2} lg={2}>
                      <HeadingCard>{label_mapping[i]}</HeadingCard>
                    </Grid>
                    <Grid item md={2} lg={2}>
                      <Divider
                        flexItem
                        orientation="horizontal"
                        style={{
                          marginRight: '15px',
                          marginLeft: '15px',
                          padding: '3px',
                          borderColor: '#E8F5E9',
                        }}
                      />
                    </Grid>
                    <Grid item md={4} lg={4}>
                      <DataText>{
                        label_mapping[i] === 'Current Population' || label_mapping[i] === '5-Yr Projected Population' ?
                          // numFormatter(cardData[i])
                          typeof cardData[i] === 'number' ? parseInt(cardData[i]).toLocaleString() : "N/A"
                          :
                          label_mapping[i] === '5-Yr Growth %' ?
                            parseFloat(cardData[i])?.toFixed(1) + "%"
                            :
                            label_mapping[i] === 'Median Age' ?
                              parseFloat(cardData[i])?.toFixed(1)
                              :
                              label_mapping[i] === 'Median Household income' ?
                                "$" + numFormatter(cardData[i])
                                :
                                label_mapping[i] === "High School Graduation Rate" ?
                                  parseFloat(cardData[i])?.toFixed(1) + "%"
                                  :
                                  cardData[i]
                      }</DataText>
                    </Grid>
                    <Grid item md={4} lg={4}>
                      {label_mapping[i] === 'Current Population' ? (
                        <GroupsOutlinedIcon
                          fontSize="large"
                          style={{
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            display: 'block',
                          }}
                        />
                      ) : label_mapping[i] === '5-Yr Projected Population' ? (
                        <GroupsOutlinedIcon
                          fontSize="large"
                          style={{
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            display: 'block',
                          }}
                        />
                      ) : label_mapping[i] === '5-Yr Growth %' ? (
                        <AssessmentOutlinedIcon
                          fontSize="large"
                          style={{
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            display: 'block',
                          }}
                        />
                      ) : label_mapping[i] === 'Median Age' ? (
                        <CakeOutlinedIcon
                          fontSize="large"
                          style={{
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            display: 'block',
                          }}
                        />
                      ) : label_mapping[i] === 'Median Household income' ? (
                        <PaymentsIcon
                          fontSize="large"
                          style={{
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            display: 'block',
                          }}
                        />
                      ) : (
                        <SchoolOutlinedIcon
                          fontSize="large"
                          style={{
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            display: 'block',
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>
                </OuterDiv>
              )
            })}
          </Grid>
        </Box>
        : null
  )
}
