import React, { useEffect, useState } from 'react'
import {
  PDFDownloadLink,
  PDFViewer,
  Document,
  Page,
  StyleSheet,
  View,
  Image,
  Text,
  Svg,
  Path,
  pdf,
} from '@react-pdf/renderer'
import dprlogo from '../../../Assets/dprlogoImage.png'
import panaromalogo from '../../../Assets/PanoramaImage.png'
import { useLocation } from 'react-router'
import { alpha } from '@mui/system'
import { saveAs } from 'file-saver'
import { useSnackbar, VariantType } from 'notistack'
import { Button, IconButton, MenuItem, Skeleton } from '@mui/material'
import { ExportStatus, useExportData } from '../../../customHooks/useExportData'
import { renderTitleHeader } from '../../CommonComponents/RenderTitleHeader/RenderTitleHeader'
import CloseIcon from '@mui/icons-material/Close'
import { PredictiveNeedsTable, monthArray } from '../../../config/Constant'
import ReactDOM from 'react-dom'
import DialogAlert from '../../CommonComponents/DialogBox/dialogAlert'

interface PredictiveNeedPDFProps {
  getPdfData: any
}

export const PredictiveNeedPDF: React.FC<PredictiveNeedPDFProps> = ({ getPdfData }: PredictiveNeedPDFProps) => {

  const date = Date().split(' ')
  const search = useLocation().search
  const dpr_city = new URLSearchParams(search).get('city')
  const zipCode = new URLSearchParams(search).get('zipcode')
  const county = new URLSearchParams(search).get('county')
  const radius = new URLSearchParams(search).get('radius')

  const monthConverter = (month: any) => {
    for (let i = 0; i < monthArray.length; i++) {
      if (monthArray[i][0] == month) return monthArray[i][1]
    }
  }
  const formatedDateTime = `${monthConverter(date[1])}/${date[2]}/${date[3]}`



  const pdfStyle = StyleSheet.create({
    body: {
      paddingTop: 15,
      paddingBottom: 45,
      paddingHorizontal: 15,
    },
    headerView: {
      display: 'flex',
      flexDirection: 'row',
      borderTop: '1px solid #EEE',
      paddingTop: 8,
      paddingBottom: 8,
      textAlign: 'center',
      fontSize: '8px',
      color: '#3E3E3E',
      backgroundColor: '#F3F3F3',
      borderRadius: '5px',
    },
    rowView: {
      display: 'flex',
      flexDirection: 'row',
      borderTop: '1px solid #EEE',
      paddingTop: 8,
      paddingBottom: 8,
      textAlign: 'center',
      fontSize: '6px',
      color: '#3E3E3E',
    },
    footer: {
      flexDirection: 'column',
      position: 'absolute',
      bottom: 10,
      left: 10,
    },
  })

  const MyDocument = (PredictiveNeedsMap, predictive) => (
    <Document>
      <Page size="A4" style={pdfStyle.body}>
        <View wrap fixed>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              paddingBottom: '10px',
            }}
          >
            <Image
              src={dprlogo}
              style={{
                width: '74px',
                height: '45px',
                padding: '5px',
              }}
            ></Image>

            <View
              style={{
                borderRight: '1px solid #CCCCCC',
                height: '30px',
                marginTop: '8px',
                marginLeft: '10px',
              }}
            ></View>

            <Image
              src={panaromalogo}
              style={{
                width: '100px',
                height: '30px',
                marginLeft: '20px',
                marginTop: '8px',
                padding: 5,
              }}
            ></Image>

            <Text
              style={{
                fontSize: '20px',
                color: '#000000',
                marginLeft: 'auto',
                marginTop: '15px',
              }}
            >
              MARKET INSIGHTS
            </Text>
          </View>

          <View
            style={{
              borderBottom: '2px solid #4CAF50',
              width: '100%',
              marginBottom: '20px',
              marginTop: 10,
            }}
          ></View>
        </View>
        <View style={{ flexDirection: 'row', marginTop: '5px' }}>
          <Text
            style={{
              fontSize: '20px',
              color: alpha('#000000', 0.8),
              marginRight: 'auto',
              marginTop: '5px',
            }}
          >
            PREDICTIVE NEEDS
          </Text>
          <View
            style={{
              borderBottom: '1.2px solid black',
              width: '64%',
              marginBottom: '10px',
              marginTop: 10,
            }}
          ></View>
        </View>

        <View
          style={{
            marginTop: '5px',
          }}
        >
          <Image
            src={PredictiveNeedsMap}
            style={{
              height: '210px',
              width: '550px',
            }}
          ></Image>
        </View>

        <View style={{ marginTop: '20px' }}>
          <View style={pdfStyle.headerView} fixed>
            {PredictiveNeedsTable['column1'] && PredictiveNeedsTable['column1'].length ?
              PredictiveNeedsTable['column1'].map((c) => (
                <Text
                  style={{
                    width: `${100 / PredictiveNeedsTable['column1'].length}%`,
                    textAlign: 'left',
                    marginLeft: '10px',
                  }}
                >
                  {c}
                </Text>
              )) : null}
          </View>

          {predictive.map((rowData) => {
            return (
              <>
                <View style={pdfStyle.rowView}>
                  {PredictiveNeedsTable['column2'] && PredictiveNeedsTable['column2'].length ?
                    PredictiveNeedsTable['column2'].map((c) => (
                      <Text
                        style={{
                          width: `${100 / PredictiveNeedsTable['column2'].length
                            }%`,
                        }}
                      >
                        {rowData[c]}
                      </Text>
                    )) : null}
                </View>
              </>
            )
          })}
        </View>

        <View wrap fixed style={pdfStyle.footer}>
          <View
            style={{
              border: '1px solid #D9D9D9',
              marginTop: '10px',
              width: '520px',
              height: '1px',
              left: '25px',
            }}
          ></View>
          <View
            style={{ flexDirection: 'row', fontSize: 8, marginTop: '10px' }}
          >
            <Text style={{ marginLeft: '30px', fontSize: 10 }}>
              Export Date : {formatedDateTime}
            </Text>
            <Text
              style={{
                fontWeight: 800,
                marginLeft: '70px',
                fontSize: 10,
              }}
            >
              Confidential: Do Not Distribute Outside of DPR
            </Text>
            <Text
              style={{ marginLeft: '110px', fontSize: 10 }}
              render={({ pageNumber }) => `Page ${pageNumber}`}
            />
          </View>
        </View>
      </Page>
      )
    </Document>
  )

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const ShowSnackBar = (
    message,
    variant: VariantType,
    duration,
    key?: string
  ) => {
    enqueueSnackbar(<div>{message}</div>, {
      variant: variant,
      persist: false,
      anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
      style: {
        flexDirection: 'row',
        maxWidth: '50px',
        marginLeft: '-150px',
      },
      autoHideDuration: duration,
      key: key,
      action: (key) => (
        <IconButton
          color="inherit"
          aria-label="dismiss"
          onClick={() => closeSnackbar(key)}
        >
          <CloseIcon />
        </IconButton>
      ),
    })
    return key
  }

  return (
    <MenuItem
      onClick={() => {
        const closeSnack = ShowSnackBar(
          'Generating Export File, Please wait !!',
          'info',
          100000000,
          'close'
        )

        getPdfData().then(() => {
          ReactDOM.render(<DialogAlert />, document.createElement('div'))

          let predictiveNeeds: any = localStorage.getItem('EXPORT_DATA')
          let predictiveNeedsData = JSON.parse(predictiveNeeds)
          let predictive
          if (predictiveNeedsData) {
            predictive = predictiveNeedsData.PredictiveNeedsTable
          }
          let MarketTitle = dpr_city
            ? dpr_city
            : zipCode && radius
              ? `${radius} miles from ${zipCode}`
              : zipCode
                ? zipCode
                : county

          const PredictiveNeedsMap: any = sessionStorage.getItem('PredictiveNeedMap')

          const pdfBlob = pdf(MyDocument(PredictiveNeedsMap, predictive)).toBlob()
          pdfBlob.then((data) => {
            const blobObj = new Blob([data], { type: 'application/pdf' })
            saveAs(blobObj, 'Healthcare Panorama - Market Insights')
          })

          setTimeout(() => {
            closeSnackbar(closeSnack);
          }, 4000);
        })
      }}
    >
      Current Tab
    </MenuItem>
  )
}
