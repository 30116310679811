import React, { useEffect, useState } from 'react';
import { DataGrid, Sorting, HeaderFilter, Column, FilterRow, Selection, Scrolling, Export } from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.common.css';
import '../../../../../Theme/dx.material.panorama-datagrid-custom-scheme/dx.material.panorama-datagrid-custom-scheme.css';
// import 'devextreme/dist/css/dx.material.blue.light.css'
import { useGetProvidersTableDetailsQuery } from '../../../../../__generated__/graphql';
import { Link, useLocation } from 'react-router-dom';
import { renderTitleHeader } from '../../../../CommonComponents/RenderTitleHeader/RenderTitleHeader';
import { Box } from '@mui/material';
import { performanceText } from '../../../../../config/commonFunction';

interface Tableprops {
    setDataSourceFunction: any,
}
export const Table: React.FC<Tableprops> = (props: Tableprops) => {
    const search = useLocation().search;
    const definitive_id = new URLSearchParams(search).get('id');

    const { data, loading, error } = useGetProvidersTableDetailsQuery({
        variables: {
            definitive_id
        }
    });

    const dataGrid = React.useRef(null);

    const [dataSource, setDataSource] = useState([]);


    useEffect(() => {
        let datasource: any = data?.DEFINITIVEHEALTHCARE_VW_HOSPITAL_INFO;

        let updated_array: any = [];
        if (datasource && datasource.length) {
            for (var i = 0; i < datasource?.length; i++) {
                let table_json: any = {
                    ...datasource[i],
                    'net_operating_profit_margin': datasource[i].net_operating_profit_margin && typeof parseFloat(datasource[i].net_operating_profit_margin) === "number" ? (parseFloat(datasource[i].net_operating_profit_margin) * 100).toFixed(2) : "",
                    'avg_age_of_plant': datasource[i].avg_age_of_plant && typeof parseFloat(datasource[i].avg_age_of_plant) === "number" ? parseFloat(datasource[i].avg_age_of_plant).toFixed(2) : "",
                    'total_performance_score': typeof datasource[i].total_performance_score === 'number'
                        ? Math.round(datasource[i].total_performance_score)
                        : 'N/A',
                }
                updated_array.push(table_json as any)
            }
        }

        setDataSource(updated_array);
    }, [loading])

    props.setDataSourceFunction(data);

    return (
        <Box sx={{ background: "rgb(240 240 240)", width: "100%", height: "100vh" }}>
            <DataGrid
                dataSource={dataSource}
                keyExpr="hospital_name"
                showBorders={true}
                columnResizingMode="widget"
                hoverStateEnabled
                id="hospitals_grid"
                ref={dataGrid}
                style={{
                    padding: "0px 15px 0px 15px",
                    borderRadius: "5px",
                    height: "calc(100% - 47.5vh)"
                }}
                allowColumnResizing
                allowColumnReordering
                columnAutoWidth
            >
                <Sorting mode="multiple" />
                <HeaderFilter allowSearch visible={true} />
                <FilterRow visible />
                <Selection />
                <Scrolling useNative={true} preloadEnabled={true} mode="infinite" />
                <Column allowSearch caption="Hospital Name" dataField="hospital_name" dataType="string"
                    cellRender={(data) => {
                        let searchParams = new URLSearchParams(window.location.search);
                        searchParams.set("type", "Hospital");
                        searchParams.set("id", data?.data.definitive_id);
                        searchParams.set("state", data?.data.state)
                        searchParams.set("provider", data?.data.hospital_name);
                        let newurl = window.location.pathname + '?' + searchParams.toString();
                        return (
                            <Link to={newurl} target="_blank" rel="noopener noreferrer" data-hospital-id="Hospital Clicked">
                                {renderTitleHeader(data.value)}
                            </Link>
                        );
                    }}></Column>
                <Column allowSearch caption="Parent Health System" dataType="string"
                    headerCellRender={(cellData) =>
                        renderTitleHeader(cellData.column?.caption, "Parent organization or health system that owns and operates this individual healthcare facility")
                    }
                    dataField="parent_health_system"></Column>
                <Column allowSearch caption="Regional Health System" dataField="regional_health_system" dataType="string"
                    headerCellRender={(cellData) =>
                        renderTitleHeader(cellData.column?.caption, "Local health system (may be the same as parent health system if it has not been acquired by a larger organization)")
                    }></Column>
                <Column allowSearch caption="Address" dataField="address" dataType="string"
                    cellRender={(data) => {
                        return data.value?.replace(" , ", " ");
                    }}></Column>
                <Column allowSearch caption="City" dataField="city" dataType="string"></Column>
                <Column allowSearch caption="State" dataField="state" dataType="string" ></Column>
                <Column allowSearch allowFiltering caption="Overall Performance Score" dataField="total_performance_score" alignment='left' dataType="number"
                    cellRender={(data) => {
                        return typeof data.value === 'number'
                            ? Math.round(data.value) + performanceText(Math.round(data.value))
                            : data.value
                    }}
                    headerCellRender={(cellData) =>
                        renderTitleHeader(cellData.column?.caption, "DPR's proprietary scoring system that evaluates performance at the organizational level, looking at specific metrics across five key categories. This score is intended for internal use only and should not be shared outside of the company")
                    }></Column>
                <Column allowSearch caption="No. of Beds" dataField="number_of_beds" alignment='left' dataType="number"
                    cellRender={(data) => {
                        if (data.value === 0)
                            return "";
                        else return data.value;

                    }}
                    headerCellRender={(cellData) =>
                        renderTitleHeader(cellData.column?.caption, "Number of beds as reported in the Medicare Cost Reports")
                    }></Column>
                <Column allowSearch caption="Net Patient Revenue" dataType="number"
                    cellRender={(data) => {
                        return (
                            data.value.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                            })
                        )
                    }}
                    headerCellRender={(cellData) =>
                        renderTitleHeader(cellData.column?.caption, "Annual total patient revenue minus patient discounts")
                    }
                    dataField="net_patient_revenue" alignment='left' ></Column>
                <Column allowSearch caption="Operating Profit Margin" dataType="number"
                    cellRender={(data) => {
                        return data.value ? data.value + "%" : "";
                    }}
                    headerCellRender={(cellData) =>
                        renderTitleHeader(cellData.column?.caption, "Net Patient Revenue minus Total Operating Expenses divided by Net Patient Revenue. Highlights the business income derived from core operations and the most commonly-used metric of financial sustainability.")
                    }
                    dataField="net_operating_profit_margin" alignment='left' ></Column>
                <Column allowSearch caption="Total SF" dataField="total_facility_square_footage" dataType="number"
                    cellRender={(data) => {
                        return data.value?.toLocaleString();
                    }}
                    headerCellRender={(cellData) =>
                        renderTitleHeader(cellData.column?.caption, "Total square footage across inpatient, outpatient, and non reimbursable cost center areas")
                    }
                    alignment="left" />

                <Column allowSearch caption="Average Age of Plant" dataField="avg_age_of_plant" dataType="number"
                    headerCellRender={(cellData) =>
                        renderTitleHeader(cellData.column?.caption, "Weighted average of all of the ages of all fixed assets of the facility. Generally, organizations with an older age of facility will need to invest in their fixed assets to remain competitive")
                    }
                    cellRender={(data) => {
                        return typeof data.value === "number" ? data.value?.toFixed(2) : data.value;
                    }}
                    alignment="left" />

                <Column allowSearch caption="Capital Expenditures" dataField="capital_expenditures" dataType="number"
                    headerCellRender={(cellData) =>
                        renderTitleHeader(cellData.column?.caption, "Expenditures incurred on acquiring or maintaining fixed assets, such as land, buildings, and equipment")
                    }
                    cellRender={(data) => {
                        return (
                            data.value.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                            })
                        )
                    }}
                    alignment="left" />

                <Column allowSearch caption="Current Ratio" dataField="current_ratio" dataType="number"
                    headerCellRender={(cellData) =>
                        renderTitleHeader(cellData.column?.caption, "Measure of a hospital’s ability to pay off its short-term liabilities with its current assets. A ratio of 1.0 or greater indicates strong liquidity")
                    }
                    cellRender={(data) => {
                        return typeof data.value === "number" ? data.value?.toFixed(1) : data.value;
                    }}
                    alignment="left" />

                <Column allowSearch caption="Days Cash on Hand" dataField="days_cash_on_hands" dataType="number"
                    headerCellRender={(cellData) =>
                        renderTitleHeader(cellData.column?.caption, "Indicates a hospital’s ability to leverage cash and investment balances to survive a downturn and capitalize on strategic opportunities")
                    }
                    cellRender={(data) => {
                        return typeof data.value === "number" ? data.value?.toFixed(1) : data.value;
                    }}
                    alignment="left" />
            </DataGrid>
        </Box>
    )
}

export default Table;