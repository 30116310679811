import { Grid, Box, Divider, Rating, Stack, Skeleton, Typography, Tooltip } from '@mui/material';
import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import { useGetFiscalStewarshipRatingQuery, useGetFiscalStewarshipScoreQuery } from '../../../../../__generated__/graphql';
import { Factors } from './Factors';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { performanceText, textColor, backgroundColor } from '../../../../../config/commonFunction';
import { appInsights } from '../../../../../providers/appInsightProvider';


export interface FiscalStewardshipProps {
    overall_rating: number | null;
}
export const FiscalStewardship: React.FC<FiscalStewardshipProps> = (props: FiscalStewardshipProps) => {
    const search = useLocation().search;
    const definitive_id = new URLSearchParams(search).get('id');
    const { data: FiscalRatingData, loading: FiscalRatingLoading, error: FiscalRatingError } = useGetFiscalStewarshipRatingQuery({
        variables: {
            definitive_id
        }
    });

    const { data: FiscalScoreData, loading: FiscalScoreLoading, error: FiscalScoreError } = useGetFiscalStewarshipScoreQuery({
        variables: {
            definitive_id
        }
    });

    useEffect(() => {
        if (FiscalRatingError instanceof Error) {
            appInsights.trackException({ exception: FiscalRatingError, error: new Error("Failed to fetch Fiscal Stewardship Rating.") })
        }
    }, [FiscalRatingError])

    useEffect(() => {
        if (FiscalScoreError instanceof Error) {
            appInsights.trackException({ exception: FiscalScoreError, error: new Error("Failed to fetch Fiscal Stewardship Score.") })
        }
    }, [FiscalScoreError])

    let fiscal_rating_data: any = FiscalRatingData && FiscalRatingData.DEFINITIVEHEALTHCARE_VW_RATINGS_FISCAL_STEWARDSHIP && FiscalRatingData.DEFINITIVEHEALTHCARE_VW_RATINGS_FISCAL_STEWARDSHIP.length ? FiscalRatingData.DEFINITIVEHEALTHCARE_VW_RATINGS_FISCAL_STEWARDSHIP[0] : null;
    let fiscal_score_data: any = FiscalScoreData && FiscalScoreData.DEFINITIVEHEALTHCARE_VW_SCORE_FISCAL_STEWARDSHIP && FiscalScoreData.DEFINITIVEHEALTHCARE_VW_SCORE_FISCAL_STEWARDSHIP.length ? FiscalScoreData.DEFINITIVEHEALTHCARE_VW_SCORE_FISCAL_STEWARDSHIP[0] : null;

    const TopDivStyle = styled(Box)(() => ({
        borderRadius: "4px",
        padding: "10px 35px 10px 15px",
    }));

    const ClientPerformanceText = styled(Grid)(() => ({
        fontSize: "13px",
        margin: "auto",
        textAlign: "end",
        fontWeight: "600"
    }))

    const HeadingText = styled(Typography)(() => ({
        color: '#3E3E3E',
        fontWeight: '400px',
        fontSize: '13px',
    }))

    const DataText = styled(Typography)(() => ({
        fontWeight: '700',
        fontSize: '14px',
    }))


    return <React.Fragment>
        <Grid container sx={{ margin: "15px 0px" }}>
            <Grid item lg={4.5} md={4.5} >
                {props.overall_rating ?
                    <TopDivStyle sx={{ border: `1px solid ${textColor(Math.round(props.overall_rating))}`, background: backgroundColor(Math.round(props.overall_rating)) }}>
                        <Grid container>
                            <Grid item lg={6} md={6} sx={{ textAlign: "start" }}>
                                <Rating name="read-only" value={Math.round(props.overall_rating)} readOnly sx={{ color: textColor(Math.round(props.overall_rating)) }} />
                            </Grid>
                            <ClientPerformanceText item lg={6} md={6}>
                                {performanceText(Math.round(props.overall_rating))?.replace("(", "").replace(")", "")}
                            </ClientPerformanceText>
                        </Grid>
                    </TopDivStyle>
                    : null}

                {FiscalRatingLoading || FiscalScoreLoading ?
                    <Typography variant='h3' sx={{ margin: "5px" }}>
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                    </Typography>
                    :
                    fiscal_score_data && fiscal_rating_data ?
                        <Stack spacing={0.5} sx={{ marginTop: "20px", marginLeft: "10px", overflow: "scroll", height: "45vh", padding: "10px" }}>

                            <Stack direction="row" spacing={0.5}>
                                <HeadingText>Net Profit Margin</HeadingText>
                                <Tooltip
                                    title="Net Patient Revenue minus Total Operating Expenses divided by Net Patient Revenue. Highlights the business income derived from core operations and the most commonly-used metric of financial sustainability"
                                    placeholder="top"
                                    arrow
                                >
                                    <InfoOutlinedIcon
                                        fontSize="small"
                                        style={{ width: '15px' }}
                                    ></InfoOutlinedIcon>
                                </Tooltip>
                            </Stack>
                            <DataText sx={{
                                color: textColor(Math.round(fiscal_rating_data?.net_operating_profit_margin))
                            }}>
                                {fiscal_score_data?.net_operating_profit_margin || fiscal_score_data?.net_operating_profit_margin === 0 ? (fiscal_score_data.net_operating_profit_margin * 100).toFixed(1) + "%" : "N/A"}
                                {fiscal_rating_data?.net_operating_profit_margin ? performanceText(Math.round(fiscal_rating_data?.net_operating_profit_margin)) : ""}
                            </DataText>

                            <br />

                            <Stack direction="row" spacing={0.5}>
                                <HeadingText>Capital Expenditures</HeadingText>
                                <Tooltip
                                    title="Expenditures incurred on acquiring or maintaining fixed assets, such as land, buildings, and equipment"
                                    placeholder="top"
                                    arrow
                                >
                                    <InfoOutlinedIcon
                                        fontSize="small"
                                        style={{ width: '15px' }}
                                    ></InfoOutlinedIcon>
                                </Tooltip>
                            </Stack>
                            <DataText sx={{
                                color: textColor(Math.round(fiscal_rating_data?.capital_expenditures))
                            }}>
                                {fiscal_score_data?.capital_expenditures || fiscal_score_data?.capital_expenditures === 0 ? fiscal_score_data.capital_expenditures.toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                }) : "N/A"}
                                {fiscal_rating_data?.capital_expenditures ? performanceText(Math.round(fiscal_rating_data?.capital_expenditures)) : ""}
                            </DataText>
                            <br />

                            <Stack direction="row" spacing={0.5}>
                                <HeadingText>Debt to Equity Ratio</HeadingText>
                                <Tooltip
                                    title="Leverage ratio that calculates the value of total debt and liabilities against total shareholder’s equity. A ratio of 0.1 indicates that a business has little debt relative to equity and a ratio of 1.0 indicates that debt is equal to equity"
                                    placeholder="top"
                                    arrow
                                >
                                    <InfoOutlinedIcon
                                        fontSize="small"
                                        style={{ width: '15px' }}
                                    ></InfoOutlinedIcon>
                                </Tooltip>
                            </Stack>
                            <DataText sx={{
                                color: textColor(Math.round(fiscal_rating_data?.debt_to_equity_ratio))
                            }}>
                                {fiscal_score_data?.debt_to_equity_ratio || fiscal_score_data?.debt_to_equity_ratio === 0 ? fiscal_score_data.debt_to_equity_ratio.toFixed(2) : "N/A"}
                                {fiscal_rating_data?.debt_to_equity_ratio ? performanceText(Math.round(fiscal_rating_data?.debt_to_equity_ratio)) : ""}
                            </DataText>
                            <br />

                            <Stack direction="row" spacing={0.5}>
                                <HeadingText>Days Cash on Hand</HeadingText>
                                <Tooltip
                                    title="Indicates a hospital’s ability to leverage cash and investment balances to survive a downturn and capitalize on strategic opportunities"
                                    placeholder="top"
                                    arrow
                                >
                                    <InfoOutlinedIcon
                                        fontSize="small"
                                        style={{ width: '15px' }}
                                    ></InfoOutlinedIcon>
                                </Tooltip>
                            </Stack>
                            <DataText sx={{
                                color: textColor(Math.round(fiscal_rating_data?.days_cash_on_hand))
                            }}>
                                {fiscal_score_data?.days_cash_on_hand || fiscal_score_data?.days_cash_on_hand === 0 ? fiscal_score_data.days_cash_on_hand.toFixed(1) : "N/A"}
                                {fiscal_rating_data?.days_cash_on_hand ? performanceText(Math.round(fiscal_rating_data?.days_cash_on_hand)) : ""}
                            </DataText>
                            <br />

                            <Stack direction="row" spacing={0.5}>
                                <HeadingText>Current Ratio</HeadingText>
                                <Tooltip
                                    title="Measure of a hospital’s ability to pay off its short-term liabilities with its current assets. A ratio of 1.0 or greater indicates strong liquidity"
                                    placeholder="top"
                                    arrow
                                >
                                    <InfoOutlinedIcon
                                        fontSize="small"
                                        style={{ width: '15px' }}
                                    ></InfoOutlinedIcon>
                                </Tooltip>
                            </Stack>
                            <DataText sx={{
                                color: textColor(Math.round(fiscal_rating_data?.current_ratio))
                            }}>
                                {fiscal_score_data?.current_ratio || fiscal_score_data?.current_ratio === 0 ? fiscal_score_data.current_ratio : "N/A"}
                                {fiscal_rating_data?.current_ratio ? performanceText(Math.round(fiscal_rating_data?.current_ratio)) : ""}
                            </DataText>
                            <br />

                            <Stack direction="row" spacing={0.5}>
                                <HeadingText>Medicare Mix</HeadingText>
                                <Tooltip
                                    title="Percentage of payer mix that is comprised of Medicare, and is more at risk for regulatory impact"
                                    placeholder="top"
                                    arrow
                                >
                                    <InfoOutlinedIcon
                                        fontSize="small"
                                        style={{ width: '15px' }}
                                    ></InfoOutlinedIcon>
                                </Tooltip>
                            </Stack>
                            <DataText
                                sx={{
                                    color: textColor(Math.round(fiscal_rating_data?.payor_mix_medicare_days))
                                }}>
                                {fiscal_score_data?.payor_mix_medicare_days || fiscal_score_data.payor_mix_medicare_days === 0 ? (fiscal_score_data.payor_mix_medicare_days * 100).toFixed(1) + "%" : "N/A"}
                                {fiscal_rating_data?.payor_mix_medicare_days ? performanceText(Math.round(fiscal_rating_data?.payor_mix_medicare_days)) : ""}
                            </DataText>
                            <br />

                            <Stack direction="row" spacing={0.5}>
                                <HeadingText>Medicaid Mix</HeadingText>
                                <Tooltip
                                    title="Percentage of payer mix that is comprised of Medicaid, and is more at risk for regulatory impact"
                                    placeholder="top"
                                    arrow
                                >
                                    <InfoOutlinedIcon
                                        fontSize="small"
                                        style={{ width: '15px' }}
                                    ></InfoOutlinedIcon>
                                </Tooltip>
                            </Stack>
                            <DataText sx={{
                                color: textColor(Math.round(fiscal_rating_data?.payor_mix_medicaid_days))
                            }}>
                                {fiscal_score_data?.payor_mix_medicaid_days || fiscal_score_data?.payor_mix_medicaid_days === 0 ? (fiscal_score_data.payor_mix_medicaid_days * 100).toFixed(1) + "%" : "N/A"}
                                {fiscal_rating_data?.payor_mix_medicaid_days ? performanceText(Math.round(fiscal_rating_data?.payor_mix_medicaid_days)) : ""}
                            </DataText>

                        </Stack>
                        : null
                }
            </Grid>
            <Grid item lg={0.5} md={0.5}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: "center", height: "50vh" }}>
                    <Divider orientation="vertical" flexItem />
                </Box>
            </Grid>
            <Grid item lg={7} md={7} style={{ overflow: "scroll", height: "50vh" }}>
                <Factors module_name='FISCAL_STEWARDSHIP' />
            </Grid>
        </Grid >
    </React.Fragment >
}