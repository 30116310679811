/* eslint-disable react-hooks/rules-of-hooks */
import { Grid, Box, Stack, Typography, Skeleton } from '@mui/material';
import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import { useGetAllFactorsQuery, useGetMainFactorsQuery } from '../../../../../__generated__/graphql';
import { appInsights } from '../../../../../providers/appInsightProvider';

export interface IFactorProps {
    module_name?: string
}

export const Factors: React.FC<IFactorProps> = (props: IFactorProps) => {

    const search = useLocation().search;
    const definitive_id = new URLSearchParams(search).get('id');

    const { data: positiveFactorData, loading: positiveFactorLoading, error: positiveFactorError } =
        props.module_name ?
            useGetAllFactorsQuery({
                variables: {
                    definitive_id,
                    module_name: props.module_name,
                    factor_type: "positive"
                }
            }) :
            useGetMainFactorsQuery({
                variables: {
                    definitive_id,
                    factor_type: "positive"
                }
            });
    const { data: negativeFactorData, loading: negativeFactorLoading, error: negativeFactorError } = props.module_name ?
        useGetAllFactorsQuery({
            variables: {
                definitive_id,
                module_name: props.module_name,
                factor_type: "negative"
            }
        }) :
        useGetMainFactorsQuery({
            variables: {
                definitive_id,
                factor_type: "negative"
            }
        });

    useEffect(() => {
        if (positiveFactorError instanceof Error) {
            appInsights.trackException({ exception: positiveFactorError, error: new Error("Failed to fetch Positive Factors.") })
        }
    }, [positiveFactorError])

    useEffect(() => {
        if (negativeFactorError instanceof Error) {
            appInsights.trackException({ exception: negativeFactorError, error: new Error("Failed to fetch Risk Factors.") })
        }
    }, [negativeFactorError])


    const FactorsTextStyle = styled(Box)(() => ({
        color: '#22577A',
        fontSize: "16px",
        fontWeight: "700"
    }))

    const Item = styled(Box)(({ theme }) => ({
        backgroundColor: "#FAFAFA",
        padding: theme.spacing(1),
        textAlign: 'left',
        color: "#3e3e3e",
        border: "1px solid #E8F5E9",
        borderRadius: "15px",
        margin: "10px 10px 5px 0px",
        fontSize: "13px"
    }));

    return <React.Fragment>
        {positiveFactorLoading ?
            <Typography variant='h3' sx={{ margin: "10px" }}>
                <Skeleton />
                <Skeleton />
                <Skeleton />
            </Typography>
            :
            <Stack>
                <FactorsTextStyle>Positive Factors</FactorsTextStyle>

                {
                    positiveFactorData && positiveFactorData.OTHERDATA_VW_COMMENTS && positiveFactorData.OTHERDATA_VW_COMMENTS.length ?
                        <Grid>
                            {positiveFactorData.OTHERDATA_VW_COMMENTS.map(comments => {
                                return comments.comment && comments.comment === "UNAVAILABLE" ? "" : <Item>{comments.comment}</Item>
                            })}
                        </Grid>
                        : "N/A"
                }
            </Stack>
        }
        <br />
        {negativeFactorLoading ?
            <Typography variant='h3' sx={{ margin: "10px" }}>
                <Skeleton />
                <Skeleton />
                <Skeleton />
            </Typography>
            :
            <Stack>
                <FactorsTextStyle>Risk Factors</FactorsTextStyle>

                {
                    negativeFactorData && negativeFactorData.OTHERDATA_VW_COMMENTS && negativeFactorData.OTHERDATA_VW_COMMENTS.length ?
                        <Grid>
                            {negativeFactorData.OTHERDATA_VW_COMMENTS.map(comments => {
                                return <Item>{comments.comment}</Item>
                            })}
                        </Grid>
                        : "N/A"
                }
            </Stack>
        }
    </React.Fragment >
}

