import { performanceTextPDF } from "../../config/commonFunction";

export const getExcelMarketServiceAreaByZipCode = (exportData: any) => {
    let dataSet: any = [];

    let marketServiceAreaByZipCodeData = exportData?.ServiceAreaTableDataZipCode
    for (let i = 0; i < marketServiceAreaByZipCodeData.length; i++) {
        marketServiceAreaByZipCodeData[i]['pop_age_0_to_4'] = Math.round(parseFloat(marketServiceAreaByZipCodeData[i]['curr_0_4']))
        marketServiceAreaByZipCodeData[i]['pop_age_5_to_9'] = Math.round(parseFloat(marketServiceAreaByZipCodeData[i]['curr_5_9']))
        marketServiceAreaByZipCodeData[i]['pop_age_10_to_19'] = Math.round(parseFloat(marketServiceAreaByZipCodeData[i]['curr_10_14']) + parseFloat(marketServiceAreaByZipCodeData[i]['curr_15_19']));
        marketServiceAreaByZipCodeData[i]['pop_age_20_to_34'] = Math.round(parseFloat(marketServiceAreaByZipCodeData[i]['curr_20_24']) + parseFloat(marketServiceAreaByZipCodeData[i]['curr_25_29']) + parseFloat(marketServiceAreaByZipCodeData[i]['curr_30_34']));
        marketServiceAreaByZipCodeData[i]['pop_age_35_to_49'] = Math.round(parseFloat(marketServiceAreaByZipCodeData[i]['curr_35_39']) + parseFloat(marketServiceAreaByZipCodeData[i]['curr_40_44']) + parseFloat(marketServiceAreaByZipCodeData[i]['curr_45_49']));
        marketServiceAreaByZipCodeData[i]['pop_age_50_to_64'] = Math.round(parseFloat(marketServiceAreaByZipCodeData[i]['curr_50_54']) + parseFloat(marketServiceAreaByZipCodeData[i]['curr_55_59']) + parseFloat(marketServiceAreaByZipCodeData[i]['curr_60_64']));
        marketServiceAreaByZipCodeData[i]['pop_age_65_to_74'] = Math.round(parseFloat(marketServiceAreaByZipCodeData[i]['curr_65_69']) + parseFloat(marketServiceAreaByZipCodeData[i]['curr_70_74']));
        marketServiceAreaByZipCodeData[i]['pop_age_above_75'] = Math.round(parseFloat(marketServiceAreaByZipCodeData[i]['curr_75_79']) + parseFloat(marketServiceAreaByZipCodeData[i]['curr_80_84']) + parseFloat(marketServiceAreaByZipCodeData[i]['curr_over_85']));
        marketServiceAreaByZipCodeData[i]['pediatric_curr'] = Math.round(parseFloat(marketServiceAreaByZipCodeData[i]['curr_0_4']) + parseFloat(marketServiceAreaByZipCodeData[i]['curr_5_9']) + parseFloat(marketServiceAreaByZipCodeData[i]['curr_10_14'] + parseFloat(marketServiceAreaByZipCodeData[i]['curr_15_19'])));
        marketServiceAreaByZipCodeData[i]['geriatric_curr'] = Math.round(parseFloat(marketServiceAreaByZipCodeData[i]['pop_age_65_to_74']) + parseFloat(marketServiceAreaByZipCodeData[i]['pop_age_above_75']));

    }
    const dataFormatHeaders = ['Zip Code', 'Market Opportunity Score', 'Total Population', 'Pop age 0-4', 'Pop age 5 to 9', 'Pop age 10 to 19', 'Pop age 20 to 34', 'Pop age 35 to 49', 'Pop age 50 to 64', 'Pop 65 to 74', 'Pop age over 75', 'Pediatric Only (<19)', 'Geriatric Only (>65)', 'White', 'Black', 'American Indian/Alaskan Native', 'Asian', 'Hawaiian/Pacific Islander', 'Other Race', 'Multirace', 'Median Age', 'Median Household Income', 'Per Capita Income', 'High School Graduation Rate', 'Projected Population', '5-Year Population Growth', 'Pediatric Only (<19) 5-Year Population Growth', 'Geriatric Only (>65) 5-Year Population Growth', '10-Year Inpatient Growth', '10-Year Outpatient Growth'];
    const dataSetFormat = ['zipcode', 'market_score', 'total_pop', 'pop_age_0_to_4', 'pop_age_5_to_9', 'pop_age_10_to_19', 'pop_age_20_to_34', 'pop_age_35_to_49', 'pop_age_50_to_64', 'pop_age_65_to_74', 'pop_age_above_75', 'pediatric_curr', 'geriatric_curr', 'pop_white', 'pop_black', 'pop_american_indian', 'pop_asian', 'pop_hawaiian', 'pop_other_race', 'pop_multi_race', 'median_age', 'median_household_income', 'per_capita_income', 'hs_graduation_rate', 'proj_pop', 'pop_growth_5yr', 'pop_growth_5_yr_ped', 'pop_growth_5_yr_ger', 'ip_growth_10_yr', 'op_growth_10_yr'];
    // const datasetFormat
    for (let i = 0; i < marketServiceAreaByZipCodeData!.length; i++) {
        let sett: string[] = []
        let j
        for (j = 0; j < dataSetFormat.length; j++) {
            for (const key in marketServiceAreaByZipCodeData![i]) {
                if (key == dataSetFormat[j]) {
                    let temp = marketServiceAreaByZipCodeData![i][key]
                    if (key == 'op_growth' || key == 'ip_growth' || key == 'population_growth') temp = parseFloat(temp.toFixed(2))
                    else if (!marketServiceAreaByZipCodeData![i][key]) temp = "-"
                    else if (key == 'market_score') {
                        sett.push(performanceTextPDF(parseInt(temp)))
                    }
                    else {
                        sett.push(temp)
                    }
                }
            }
        }
        dataSet.push([...sett])
    }
    dataSet.unshift(dataFormatHeaders)
    return dataSet
}