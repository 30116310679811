import React, { useEffect } from 'react'
import {
  Button,
  Divider,
  Grid,
  Skeleton,
  styled,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined'
import { useGetStakeholderInfoQuery } from '../../../../../__generated__/graphql'
import { useHistory, useLocation } from 'react-router-dom'
import { appInsights } from '../../../../../providers/appInsightProvider'

export const Summary_stackholder: React.FC = () => {
  const search = useLocation().search
  let history = useHistory()
  let location = useLocation()

  let definitive_id = new URLSearchParams(search).get('id')?.toString()
  let definitiveID: number = definitive_id ? parseInt(definitive_id) : 0
  const {
    data: getStakeholderData,
    loading: loadingStakeholders,
    error: errorStakeholder,
  } = useGetStakeholderInfoQuery({
    variables: {
      definitive_id: definitiveID,
    },
  })

  useEffect(() => {
    if (errorStakeholder instanceof Error) {
      appInsights.trackException({ exception: errorStakeholder, error: new Error("Failed to fetch Stakeholder Info.") })
    }
  }, [errorStakeholder])

  let Stakeholder_data =
    getStakeholderData &&
      getStakeholderData.DEFINITIVEHEALTHCARE_VW_HOSPITAL_EXECUTIVES &&
      getStakeholderData.DEFINITIVEHEALTHCARE_VW_HOSPITAL_EXECUTIVES.length
      ? getStakeholderData.DEFINITIVEHEALTHCARE_VW_HOSPITAL_EXECUTIVES
      : null

  const Stackholder = styled(Box)(() => ({
    color: ' #22577A',
    fontWeight: '700',
    fontSize: '1.2rem',
    paddingTop: '28px',
    paddingLeft: '27px',
  }))

  const OuterDiv = styled(Box)(() => ({
    border: '1px solid #F3F3F3',
    background: '#F3F3F3',
    height: 'auto',
    borderRadius: '9px',
    margin: '10px',
  }))

  const HeadingText = styled(Box)(() => ({
    fontWeight: '600',
    fontSize: '0.9rem',
    color: '#3E3E3E',
    margin: '10px',
    maxWidth: '180px',
  }))

  const SmallText = styled(Box)(() => ({
    color: '#3E3E3E',
    fontWeight: '400px',
    fontSize: '0.7rem',
    textTransform: 'inherit',
    margin: '10px',
  }))

  const EmailButton = styled(Button)(() => ({
    color: '#22577A',
    fontWeight: '400px',
    position: 'absolute',
    right: 0,
    fontSize: '12px',
    textTransform: 'inherit',
    backgroundColor: 'transparent',
    paddingTop: '8px',
  }))

  return (
    <Box
      style={{
        margin: '10px', // Temporary
        height: '75vh',
        border: '2px solid #E8F5E9',
        borderRadius: '10px',
      }}
    >
      <Grid container spacing={2} style={{ position: 'relative' }}>
        <Grid lg={6}>
          <Stackholder>Stakeholders</Stackholder>
        </Grid>
        <Grid
          lg={6}
          sx={{
            textAlign: 'end',
            margin: 'auto',
            padding: '25px 15px 0px 15px',
          }}
        >
          <Button data-stakeholder-view-more-id="Summary | Stakeholder | View More"
            style={{
              color: '#3E3E3E',
              fontWeight: '400px',
              fontSize: '0.7rem',
              textTransform: 'inherit',
              backgroundColor: 'transparent',
            }}
            endIcon={<KeyboardDoubleArrowRightOutlinedIcon />}
            onClick={() => {
              history.push(`/client_insights/Stakeholder${location.search}`)
            }}
          >
            View More
          </Button>
        </Grid>
      </Grid>
      <Grid lg={12}>
        <Divider
          orientation="horizontal"
          flexItem
          style={{
            borderColor: '#E8F5E9',
            margin: '10px',
          }}
        />
      </Grid>

      {loadingStakeholders ? (
        <Typography variant="h3" sx={{ margin: '10px' }}>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </Typography>
      ) : Stakeholder_data && Stakeholder_data.length ? (
        <Box sx={{ height: '65vh', overflow: 'auto' }}>
          {Stakeholder_data.map((stakeholder_data, i) => {
            return (
              <OuterDiv key={i + 'stakeholder_div'}>
                <Grid container style={{ position: 'relative' }}>
                  <Grid lg={4}>
                    <Box>
                      <HeadingText>
                        {stakeholder_data.executive_name
                          ? stakeholder_data.executive_name
                          : '-'}
                      </HeadingText>
                      <SmallText>
                        {stakeholder_data.department_level
                          ? stakeholder_data.department_level
                          : '-'}
                      </SmallText>
                    </Box>
                  </Grid>
                  <Grid lg={6}>
                    <Box style={{ width: '20px' }}>
                      <EmailButton>
                        {stakeholder_data.email ? (
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`mailto:${stakeholder_data.email}`}
                          >
                            {stakeholder_data.email}
                          </a>
                        ) : (
                          ''
                        )}
                      </EmailButton>
                    </Box>
                  </Grid>
                </Grid>
                <Grid lg={12}>
                  <Divider
                    orientation="horizontal"
                    flexItem
                    style={{
                      margin: '6px',
                    }}
                  />
                </Grid>
                <Grid container style={{ position: 'relative' }}>
                  <Grid lg={4}>
                    <Box>
                      <SmallText>Office Contact</SmallText>
                      <HeadingText sx={{ fontWeight: '500' }}>
                        {stakeholder_data.office_phone ? (
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`tel:+${stakeholder_data.office_phone}`}
                          >
                            {stakeholder_data.office_phone}
                          </a>
                        ) : (
                          '-'
                        )}
                      </HeadingText>
                    </Box>
                  </Grid>
                  <Grid lg={4}>
                    <Box>
                      <SmallText>Personal Contact</SmallText>
                      <HeadingText sx={{ fontWeight: '500' }}>
                        {stakeholder_data.personal_phone ? (
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`tel:+${stakeholder_data.personal_phone}`}
                          >
                            {stakeholder_data.personal_phone}
                          </a>
                        ) : (
                          '-'
                        )}
                      </HeadingText>
                    </Box>
                  </Grid>

                  <Grid lg={4}>
                    {stakeholder_data.linkedin_profile ? (
                      <a
                        href={
                          stakeholder_data.linkedin_profile
                            ? stakeholder_data.linkedin_profile
                            : ''
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        title={
                          stakeholder_data.linkedin_profile
                            ? stakeholder_data.linkedin_profile
                            : ''
                        }
                      >
                        <LinkedInIcon
                          color="primary"
                          fontSize="large"
                          style={{
                            position: 'absolute',
                            right: '10px',
                            bottom: '10px',
                          }}
                        ></LinkedInIcon>
                      </a>
                    ) : null}
                  </Grid>
                </Grid>

              </OuterDiv>
            )
          })}
        </Box>
      ) : <div style={{ margin: "15px" }}>No Data</div>}
    </Box>
  )
}
