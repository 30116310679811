import { Grid, Skeleton, styled, Tooltip } from '@mui/material'
import { Box, Stack } from '@mui/system'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import { useGetCaliforniaHcaiDataQuery } from '../../../../../../__generated__/graphql';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { appInsights } from '../../../../../../providers/appInsightProvider';

export const CaliforniaHCAIInfoCard: React.FC = () => {
  const search = useLocation().search;

  let definitive_id = new URLSearchParams(search).get('id')?.toString();

  let definitiveID: number = definitive_id ? parseInt(definitive_id) : 0;

  const { data: CaliforniaHCAIData, loading: CaliforniaHCAILoading, error: CaliforniaHCAIError } = useGetCaliforniaHcaiDataQuery({
    variables: {
      definitive_id: definitiveID
    }
  });

  useEffect(() => {
    if (CaliforniaHCAIError instanceof Error) {
      appInsights.trackException({ exception: CaliforniaHCAIError, error: new Error("Failed to fetch California Data.") })
    }
  }, [CaliforniaHCAIError])

  let california_data = CaliforniaHCAIData?.OTHERDATA_HCAI_HOSPITAL_INFO && CaliforniaHCAIData?.OTHERDATA_HCAI_HOSPITAL_INFO.length ?
    CaliforniaHCAIData?.OTHERDATA_HCAI_HOSPITAL_INFO[0] : null;

  const HeadingText = styled(Box)(() => ({
    fontSize: '12px',
    fontWeight: 400,
  }))

  const InfoText = styled(Box)(() => ({
    fontSize: '13px',
    fontWeight: 600,
    color: '#3E3E3E',
  }))

  return (
    <Box>

      <Box
        style={{
          margin: '0px 8px 8px 8px',
          backgroundColor: '#FAFAFA',
          borderRadius: '5px',
          border: '1px solid #E8F5E9',
        }}
      >
        {
          CaliforniaHCAILoading ?
            <Box sx={{ width: "98%", margin: "10px" }} >
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </Box >
            :
            <Grid
              container
              style={{
                margin: '10px',
              }}
            >
              <Grid lg={2} item>
                <Stack direction="column" p="8px" spacing={1}>
                  <HeadingText>Campus Name</HeadingText>
                  <InfoText>{california_data?.FACILITY_DESC ? california_data?.FACILITY_DESC : "-"}</InfoText>
                </Stack>
              </Grid>
              <Grid lg={1.5} item>
                <Stack direction="column" spacing={1} p="8px">
                  <HeadingText>Active Projects
                    <Tooltip
                      title="Number of active building and construction projects"
                      placeholder="top"
                      arrow
                    >
                      <InfoOutlinedIcon
                        fontSize="small"
                        style={{ width: '15px', verticalAlign: "middle", marginLeft: "5px" }}
                      ></InfoOutlinedIcon>
                    </Tooltip>
                  </HeadingText>

                  <InfoText>{california_data?.NO_OF_ACTIVE_PROJECTS ? california_data?.NO_OF_ACTIVE_PROJECTS : "-"}</InfoText>
                </Stack>
              </Grid>
              <Grid lg={2.5} item>
                <Stack direction="column" spacing={1} p="8px">
                  <HeadingText>Overall HC Construction Project Cost
                    <Tooltip
                      title="Indicates value of construction projects at the facility"
                      placeholder="top"
                      arrow
                    >
                      <InfoOutlinedIcon
                        fontSize="small"
                        style={{ width: '15px', verticalAlign: "middle", marginLeft: "5px" }}
                      ></InfoOutlinedIcon>
                    </Tooltip>
                  </HeadingText>


                  <InfoText>{california_data?.ACTIVE_PROJECT_COST ?
                    california_data?.ACTIVE_PROJECT_COST.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    })
                    : "-"}</InfoText>
                </Stack>
              </Grid>
              <Grid lg item>
                <Stack direction="column" spacing={1} p="8px">
                  <HeadingText>Count of Buildings by Seismic Ratings
                    <Tooltip
                      title="Structural Performance Category (SPC) ratings range from 1 to 5 with SPC-1 assigned to buildings that may be at risk of collapse during a strong earthquake and SPC 5 assigned to buildings reasonably capable of providing services to the public following a strong earthquake."
                      placeholder="top"
                      arrow
                    >
                      <InfoOutlinedIcon
                        fontSize="small"
                        style={{ width: '15px', verticalAlign: "middle", marginLeft: "5px" }}
                      ></InfoOutlinedIcon>
                    </Tooltip>
                  </HeadingText>


                  <InfoText>
                    <Tooltip
                      title="A rating of 1 indicates that buildings pose significant risk of collapse and danger to the public"
                      placeholder="top"
                      arrow
                    >
                      <span style={{ cursor: "pointer" }}>Rating 1 : </span>
                    </Tooltip>

                    {california_data?.NO_OF_BLDGS_RATING_1} bldgs |
                    <Tooltip
                      title="A rating of 2 indicates that buildings do not significantly jeopardize life but may not be repairable or functional following strong ground motion"
                      placeholder="top"
                      arrow
                    >
                      <span style={{ cursor: "pointer" }}> Rating 2 : </span>
                    </Tooltip>
                    {california_data?.NO_OF_BLDGS_RATING_2} bldgs |
                    <Tooltip
                      title="A rating of 3 indicates that these buildings may experience structural damage which does not significantly jeopardize life but may not be repairable or functional following strong ground motion"
                      placeholder="top"
                      arrow
                    >
                      <span style={{ cursor: "pointer" }}> Rating 3 : </span>
                    </Tooltip>
                    {california_data?.NO_OF_BLDGS_RATING_3} bldgs |
                    <Tooltip
                      title="A rating of 4 indicates that buildings will have been constructed or reconstructed under a building permit obtained through HCAI"
                      placeholder="top"
                      arrow
                    >
                      <span style={{ cursor: "pointer" }}> Rating 4 : </span>
                    </Tooltip>
                    {california_data?.NO_OF_BLDGS_RATING_4} bldgs |
                    <Tooltip
                      title="A rating of 5 indicates that buildings are reasonable capable of providing services following strong ground motion"
                      placeholder="top"
                      arrow
                    >
                      <span style={{ cursor: "pointer" }}> Rating 5 : </span>
                    </Tooltip>
                    {california_data?.NO_OF_BLDGS_RATING_5} bldgs
                  </InfoText>
                </Stack>
              </Grid>
            </Grid>
        }

      </Box>
    </Box>
  )
}
