import React, { useEffect, useState } from 'react'
import { DataGrid, Sorting, HeaderFilter, Column, FilterRow, Selection, Scrolling, } from 'devextreme-react/data-grid'
import 'devextreme/dist/css/dx.common.css'
import { Box } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { useGetPredictiveNeedsDataForCityLazyQuery, useGetPredictiveNeedsDataForCountyLazyQuery, useGetPredictiveNeedsDataForZipCodeAndRadiusLazyQuery, useGetPredictiveNeedsDataForZipCodeLazyQuery } from '../../../../__generated__/graphql'
import { appInsights } from '../../../../providers/appInsightProvider'
import { Loader } from '../../../CommonComponents/Loader/Loader'

interface IPredectiveNeedsDataTableProps {
  siteOfCare: string
  ageBracket: string
  serviceLine: string
  year: string
  setUniqueServiceLine: Function
}

export const PredectiveNeedsDataTable: React.FC<IPredectiveNeedsDataTableProps> = (props: IPredectiveNeedsDataTableProps) => {
  const dataGrid = React.useRef(null)
  const search = useLocation().search

  const city = new URLSearchParams(search).get('city');
  const county = new URLSearchParams(search).get('county');
  const zip_code = new URLSearchParams(search).get('zipcode');
  const radius = new URLSearchParams(search).get('radius');

  const [dataSource, setDataSource] = useState([]);

  const [loadPredictiveNeedsTableDataForCity, {
    data: PredictiveNeedsTableDataForCity,
    loading: PredictiveNeedsTableDataForCityLoading,
    error: PredictiveNeedsTableDataForCityError,
  }] = useGetPredictiveNeedsDataForCityLazyQuery({
    variables: {
      city,
      patient_type: props.siteOfCare,
      age_group: props.ageBracket,
      sl: props.serviceLine,
    },
    fetchPolicy: 'network-only'
  })

  const [loadPredictiveNeedsTableDataForZipCode, {
    data: PredictiveNeedsTableDataForZipCode,
    loading: PredictiveNeedsTableDataForZipCodeLoading,
    error: PredictiveNeedsTableDataForZipCodeError,
  }] = useGetPredictiveNeedsDataForZipCodeLazyQuery({
    variables: {
      zipcode: zip_code,
      patient_type: props.siteOfCare,
      age_group: props.ageBracket,
      sl: props.serviceLine,
    },
    fetchPolicy: 'network-only'
  })

  let county_data: string[] = [];
  if (county) {
    let counties_array = county.split(",");
    for (let i = 0; i < counties_array.length; i++) {
      const element = counties_array[i];
      let splitted_val = element.split(" - ");
      let string_val: string = splitted_val[1].trim() + splitted_val[0].trim();
      county_data.push(string_val);
    }
  }

  const [loadPredictiveNeedsTableDataForCounty, {
    data: PredictiveNeedsTableDataForCounty,
    loading: PredictiveNeedsTableDataForCountyLoading,
    error: PredictiveNeedsTableDataForCountyError,
  }] = useGetPredictiveNeedsDataForCountyLazyQuery({
    variables: {
      county: county_data.join(","),
      patient_type: props.siteOfCare,
      age_group: props.ageBracket,
      sl: props.serviceLine,

    },
    fetchPolicy: 'network-only'
  })

  const [loadPredictiveNeedsTableDataForZipCodeAndRadius, {
    data: PredictiveNeedsTableDataForZipCodeAndRadius,
    loading: PredictiveNeedsTableDataForZipCodeAndRadiusLoading,
    error: PredictiveNeedsTableDataForZipCodeAndRadiusError,
  }] = useGetPredictiveNeedsDataForZipCodeAndRadiusLazyQuery({
    variables: {
      zipcode: zip_code,
      radius: radius ? parseInt(radius) : 1,
      patient_type: props.siteOfCare,
      age_group: props.ageBracket,
      sl: props.serviceLine,
    },
    fetchPolicy: 'network-only'
  })
  useEffect(() => {
    if (PredictiveNeedsTableDataForCityError instanceof Error) {
      appInsights.trackException({ exception: PredictiveNeedsTableDataForCityError, error: new Error("Failed to fetch Predictive Needs Table Data for City.") })
    }
    else if (PredictiveNeedsTableDataForZipCodeError instanceof Error) {
      appInsights.trackException({ exception: PredictiveNeedsTableDataForZipCodeError, error: new Error("Failed to fetch Demographics Table Data for Zip code.") })
    }
    else if (PredictiveNeedsTableDataForCountyError instanceof Error) {
      appInsights.trackException({ exception: PredictiveNeedsTableDataForCountyError, error: new Error("Failed to fetch Demographics Table Data for county.") })
    }
    else if (PredictiveNeedsTableDataForZipCodeAndRadiusError instanceof Error) {
      appInsights.trackException({ exception: PredictiveNeedsTableDataForZipCodeAndRadiusError, error: new Error("Failed to fetch Demographics Table Data for zipcode and radius.") })
    }
  }, [PredictiveNeedsTableDataForCityError, PredictiveNeedsTableDataForZipCodeError, PredictiveNeedsTableDataForCountyError, PredictiveNeedsTableDataForZipCodeAndRadiusError])


  const loadData = () => {
    if (city) {
      loadPredictiveNeedsTableDataForCity()
    }
    else if (zip_code && radius) {
      loadPredictiveNeedsTableDataForZipCodeAndRadius()
    }
    else if (zip_code) {
      loadPredictiveNeedsTableDataForZipCode()
    }
    else if (county) {
      loadPredictiveNeedsTableDataForCounty()
    }
  }

  useEffect(() => {
    loadData();
  }, [])

  useEffect(() => {
    let pred_data: any = PredNeedsData(dataSource, "DM");
    if (pred_data && pred_data.length)
      setDataSource(pred_data);
  }, [props.year])

  useEffect(() => {
    loadData();
  }, [props.siteOfCare, props.ageBracket, props.serviceLine])

  const PredNeedsData = (data, type?) => {
    let updated_pred_data: any = [];

    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      let volume = props.year === "10-YearProjected" ? element.volume_10_yr : element.volume_5_yr;
      let growth = props.year === "10-YearProjected" ? element.growth_10_yr : element.growth_5_yr;
      let table_json: any = {
        ...element,
        vol_current: typeof parseInt(element.vol_current) === "number" ? parseInt(element.vol_current) : "",
        volume_5_yr: typeof parseInt(element.volume_5_yr) === "number" ? parseInt(element.volume_5_yr) : "",
        growth_5_yr: typeof parseInt(element.growth_5_yr) === "number" ? parseFloat((element.growth_5_yr)) : "",
        volume_10_yr: typeof parseInt(element.volume_10_yr) === "number" ? parseInt(element.volume_10_yr) : "",
        growth_10_yr: typeof parseInt(element.growth_10_yr) === "number" ? parseFloat((element.growth_10_yr)) : "",
        tbl_volume: typeof parseInt(volume) === "number" ? parseInt(volume) : "",
        tbl_growth: typeof parseInt(growth) === "number" ? parseFloat((growth).toFixed(1)) : "",
      }
      updated_pred_data.push(table_json as any)
    }
    if (props.serviceLine === "all") {
      if (updated_pred_data && updated_pred_data.length)
        props.setUniqueServiceLine([...new Set(updated_pred_data.map(obj => obj.service_line))])
      else
        props.setUniqueServiceLine([]);
    }
    return updated_pred_data;
  }

  useEffect(() => {
    let Table_data: any = [];
    if (PredictiveNeedsTableDataForCity && PredictiveNeedsTableDataForCity?.udf_pred_needs_tbl_dpr_market && PredictiveNeedsTableDataForCity?.udf_pred_needs_tbl_dpr_market.length)
      Table_data = PredictiveNeedsTableDataForCity?.udf_pred_needs_tbl_dpr_market;
    else if (PredictiveNeedsTableDataForZipCode && PredictiveNeedsTableDataForZipCode?.udf_pred_needs_tbl_zip && PredictiveNeedsTableDataForZipCode?.udf_pred_needs_tbl_zip.length)
      Table_data = PredictiveNeedsTableDataForZipCode?.udf_pred_needs_tbl_zip;
    else if (PredictiveNeedsTableDataForCounty && PredictiveNeedsTableDataForCounty?.udf_pred_needs_tbl_county && PredictiveNeedsTableDataForCounty?.udf_pred_needs_tbl_county.length)
      Table_data = PredictiveNeedsTableDataForCounty?.udf_pred_needs_tbl_county;
    else if (PredictiveNeedsTableDataForZipCodeAndRadius && PredictiveNeedsTableDataForZipCodeAndRadius.udf_pred_needs_tbl_zip_radius && PredictiveNeedsTableDataForZipCodeAndRadius.udf_pred_needs_tbl_zip_radius.length)
      Table_data = PredictiveNeedsTableDataForZipCodeAndRadius.udf_pred_needs_tbl_zip_radius;
    let pred_data: any = PredNeedsData(Table_data);
    if (pred_data && pred_data.length)
      setDataSource(pred_data);
    else
      setDataSource([]);

  }, [PredictiveNeedsTableDataForCityLoading, PredictiveNeedsTableDataForZipCodeLoading, PredictiveNeedsTableDataForCountyLoading, PredictiveNeedsTableDataForZipCodeAndRadiusLoading])

  return (
    <Box>
      {(PredictiveNeedsTableDataForCityLoading || PredictiveNeedsTableDataForZipCodeLoading || PredictiveNeedsTableDataForCountyLoading || PredictiveNeedsTableDataForZipCodeAndRadiusLoading) ?
        <Loader message="Loading the data..." top="25vh" />
        :
        <Box
          sx={{
            height: '100vh',
            paddingLeft: '7px',
            paddingBottom: '2px'
          }}
        >
          <DataGrid
            dataSource={dataSource}
            keyExpr="service_line"
            showBorders={true}
            columnResizingMode="widget"
            hoverStateEnabled
            id="PredictiveNeeds_grid"
            ref={dataGrid}
            style={{
              height: 'calc(100% - 35vh)'
            }}
            loadPanel={
              {
                enabled: true,
                text: "Loading the data...",
                showPane: (PredictiveNeedsTableDataForCityLoading || PredictiveNeedsTableDataForZipCodeLoading || PredictiveNeedsTableDataForCountyLoading || PredictiveNeedsTableDataForZipCodeAndRadiusLoading) &&
                  dataSource.length < 0 ? true : false,
                showIndicator: (PredictiveNeedsTableDataForCityLoading || PredictiveNeedsTableDataForZipCodeLoading || PredictiveNeedsTableDataForCountyLoading || PredictiveNeedsTableDataForZipCodeAndRadiusLoading) &&
                  dataSource.length < 0 ? true : false
              }
            }
            allowColumnResizing
            allowColumnReordering
            columnAutoWidth
          >
            <Sorting mode="multiple" />

            <HeaderFilter allowSearch visible={true} />
            <FilterRow visible />
            <Selection />
            <Scrolling useNative={true} preloadEnabled={true} />

            <Column minWidth={180} width={220}
              allowSearch
              caption="Service Line"
              dataField="service_line"
              dataType="string" defaultSortOrder="asc"
            ></Column>
            <Column minWidth={180} width={220}
              allowSearch
              caption="Sub Service Line"
              dataField="sub_service_line"
              dataType="string"
              cellRender={(data) => {
                return data.value.toLocaleString('en-US')
              }}
            ></Column>
            <Column
              allowSearch
              caption="Current Volume"
              dataField="vol_current"
              dataType="number" alignment="left"
              cellRender={(data) => {
                return data.value.toLocaleString('en-US')
              }}
            ></Column>
            <Column
              allowSearch
              caption="Selected Year Volume"
              dataField="tbl_volume"
              dataType="number" alignment="left"
              cellRender={(data) => {
                return data.value.toLocaleString('en-US')
              }}
            ></Column>
            <Column
              allowSearch
              caption="Selected Year Growth"
              dataField="tbl_growth"
              dataType="number" alignment="left"
              cellRender={(data) => {
                return data.value + "%"
              }}
            ></Column>
          </DataGrid>
        </Box>
      }
    </Box>
  )
}
