import { utils, write, writeFile } from "xlsx-js-style";

export const downloadExcelHealthSystem = (data, otherData) => {
    const current = new Date();
    const date = `${current.getMonth() + 1}/${current.getDate()}/${current.getFullYear()}`;
    let healthSystemData = [['DPR Panorama Tool'], ['Confidential: Do Not Distribute Outside of DPR'], [' '], ['Date of Report', date], ['Health System Name', otherData.hospital_name], ['# of Hospitals', otherData.number_of_hospitals], ['# of Physician Groups', otherData.number_of_physician_groups], ['# Ambulatory Surgery Centers', otherData.number_of_ambulatory_surgery_centers], ['# of Imaging Centers', otherData.number_of_imaging_centers], ['# of Urgent Care Clinics', otherData.number_of_urgent_care_clinics], ['# of Skilled Nursing Facilities', otherData.number_of_skilled_nursing_facilities]]
    let workSheet = utils.aoa_to_sheet(healthSystemData)
    workSheet['!cols'] = [{ 'width': 40 }]
    for (let i = 1; i <= 118; i++) {
        workSheet['!cols'].push({ 'width': 30 })
    }
    utils.sheet_add_aoa(workSheet, data, { origin: { r: 12, c: 0 } })
    let columnArr = ['A13', 'B13', 'C13', 'D13', 'E13', 'F13', 'G13', 'H13', 'I13', 'J13', 'K13', 'L13', 'M13', 'N13', 'O13', 'P13', 'Q13', 'R13', 'S13', 'T13', 'U13', 'V13', 'W13', 'X13', 'Y13', 'Z13',
        'AA13', 'AB13', 'AC13', 'AD13', 'AE13', 'AF13', 'AG13', 'AH13', 'AI13', 'AJ13', 'AK13', 'AL13', 'AM13', 'AN13', 'AO13', 'AP13', 'AQ13', 'AR13', 'AS13', 'AT13', 'AU13', 'AV13', 'AW13', 'AX13', 'AY13', 'AZ13',
        'BA13', 'BB13', 'BC13', 'BD13', 'BE13', 'BF13', 'BG13', 'BH13', 'BI13', 'BJ13', 'BK13', 'BL13', 'BM13', 'BN13', 'BO13', 'BP13', 'BQ13', 'BR13', 'BS13', 'BT13', 'BU13', 'BV13', 'BW13', 'BX13', 'BY13', 'BZ13',
        'CA13', 'CB13', 'CC13', 'CD13', 'CE13', 'CF13', 'CG13', 'CH13', 'CI13', 'CJ13', 'CK13', 'CL13', 'CM13', 'CN13', 'CO13', 'CP13', 'CQ13', 'CR13', 'CS13', 'CT13', 'CU13', 'CV13', 'CW13', 'CX13', 'CY13', 'CZ13',
        'DA13', 'DB13', 'DC13', 'DD13', 'DE13', 'DF13', 'DG13', 'DH13', 'DI13', 'DJ13', 'DK13', 'DL13', 'DM13', 'DN13', 'DO13', 'DP13']

    for (let i = 0; i < columnArr.length; i++) {
        const element = columnArr[i];
        workSheet[element].s = { font: { bold: true, sz: 12 } }
    }

    const workBook = utils.book_new()
    utils.book_append_sheet(workBook, workSheet, 'Data Table')
    let buf = write(workBook, { bookType: 'xlsx', type: 'buffer' })
    write(workBook, { bookType: 'xlsx', type: 'binary' })
    // writeFile(workBook, `${otherData.hospital_name}.xlsx`)
    writeFile(workBook, `Healthcare Panorama - Health system Insights.xlsx`)
}