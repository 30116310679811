import React, { useEffect, useState } from 'react'
import { Box, Grid, styled } from '@mui/material'
import DataGrid, { Sorting, HeaderFilter, Column, FilterRow, Selection, Scrolling, } from 'devextreme-react/data-grid'
import 'devextreme/dist/css/dx.common.css'
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress'
import { useLocation } from 'react-router-dom'
import { useGetDemographicsTableDataByCityLazyQuery, useGetDemographicsTableDataByCountyLazyQuery, useGetDemographicsTableDataByZipAndRadiusLazyQuery, useGetDemographicsTableDataByZipLazyQuery } from '../../../../__generated__/graphql'
import { getDemographicsTableData } from '../../../../config/commonFunction'
import { IDemographicsTable } from '../../../../models/IDemographicsTable'
import { appInsights } from '../../../../providers/appInsightProvider'

interface DemographicsTableProps {
  AgeBracket: string

}
export const DemographicsTable: React.FC<DemographicsTableProps> = (props: DemographicsTableProps) => {
  const dataGrid = React.useRef(null)
  const search = useLocation().search
  const city = new URLSearchParams(search).get('city');
  const county = new URLSearchParams(search).get('county');
  const zip_code = new URLSearchParams(search).get('zipcode');
  const radius = new URLSearchParams(search).get('radius');

  const [tableData, setTableData] = useState<IDemographicsTable[]>([]);
  const [tableDataCopy, setTableDataCopy] = useState<IDemographicsTable[]>([]);


  const [loadTableDataByCity, {
    data: TableDataByCity,
    loading: TableDataByCityLoading,
    error: TableDataByCityError,
  }] = useGetDemographicsTableDataByCityLazyQuery({
    variables: {
      city
    },
    fetchPolicy: 'network-only'
  })

  const [loadTableDataByZip, {
    data: TableDataByZip,
    loading: TableDataByZipLoading,
    error: TableDataByZipError,
  }] = useGetDemographicsTableDataByZipLazyQuery({
    variables: {
      zipcode: zip_code
    },
    fetchPolicy: 'network-only'
  })

  let county_data: string[] = [];
  if (county) {
    let counties_array = county.split(",");
    for (let i = 0; i < counties_array.length; i++) {
      const element = counties_array[i];
      let splitted_val = element.split(" - ");
      let string_val: string = splitted_val[1].trim() + splitted_val[0].trim();
      county_data.push(string_val);
    }
  }
  const [loadTableDataByCounty, {
    data: TableDataByCounty,
    loading: TableDataByCountyLoading,
    error: TableDataByCountyError,
  }] = useGetDemographicsTableDataByCountyLazyQuery({
    variables: {
      county: county_data.join(",")
    },
    fetchPolicy: 'network-only'
  })

  const [loadTableDataByZipCodeAndRadius, {
    data: TableDataByZipCodeAndRadius,
    loading: TableDataByZipCodeAndRadiusLoading,
    error: TableDataByZipCodeAndRadiusError,
  }] = useGetDemographicsTableDataByZipAndRadiusLazyQuery({
    variables: {
      zipcode: zip_code,
      radius: radius ? parseInt(radius) : 1
    },
    fetchPolicy: 'network-only'
  })

  useEffect(() => {
    if (TableDataByCityError instanceof Error) {
      appInsights.trackException({ exception: TableDataByCityError, error: new Error("Failed to fetch Demographics Table Data for City.") })
    }
    else if (TableDataByZipError instanceof Error) {
      appInsights.trackException({ exception: TableDataByZipError, error: new Error("Failed to fetch Demographics Table Data for Zip code.") })
    }
    else if (TableDataByCountyError instanceof Error) {
      appInsights.trackException({ exception: TableDataByCountyError, error: new Error("Failed to fetch Demographics Table Data for county.") })
    }
    else if (TableDataByZipCodeAndRadiusError instanceof Error) {
      appInsights.trackException({ exception: TableDataByZipCodeAndRadiusError, error: new Error("Failed to fetch Demographics Table Data for zipcode and radius.") })
    }
  }, [TableDataByCityError, TableDataByZipError, TableDataByCountyError, TableDataByZipCodeAndRadiusError])


  useEffect(() => {
    if (city) {
      loadTableDataByCity()
    }
    else if (zip_code && radius) {
      loadTableDataByZipCodeAndRadius()
    }
    else if (zip_code) {
      loadTableDataByZip()
    }
    else if (county) {
      loadTableDataByCounty()
    }
  }, [])

  useEffect(() => {
    let table_data: IDemographicsTable[] = getFilteredData(tableDataCopy, props.AgeBracket)
    setTableData(table_data);
  }, [props.AgeBracket])

  const getFilteredData = (table_data: IDemographicsTable[], age_bracket: string) => {
    let pediatric_age_label_array = ["0-4", "5-9", "10-14", "15-19"]
    let adult_age_label_array = ["20-24", "25-29", "30-34", "35-39", "40-44", "45-49", "50-54", "55-59", "60-64"]
    let geriatric_age_label_array = ["65-69", "70-74", "75-79", "80-84", "Above 85"]
    let all_age_label_array = ["0-4", "5-9", "10-14", "15-19", "20-24", "25-29", "30-34", "35-39", "40-44", "45-49", "50-54", "55-59", "60-64", "65-69", "70-74", "75-79", "80-84", "Above 85"]

    let return_data: IDemographicsTable[] = [];
    if (age_bracket === "Pediatric") {
      return_data = table_data.filter(d => pediatric_age_label_array.indexOf(d.age_cohort) > -1);
    }
    else if (age_bracket === "Adult") {
      return_data = table_data.filter(d => adult_age_label_array.indexOf(d.age_cohort) > -1);
    }
    else if (age_bracket === "Geriatric") {
      return_data = table_data.filter(d => geriatric_age_label_array.indexOf(d.age_cohort) > -1);
    }
    else {
      return_data = table_data.filter(d => all_age_label_array.indexOf(d.age_cohort) > -1);
    }
    return return_data;
  }

  useEffect(() => {
    let Table_data: any = null;
    if (TableDataByCity && TableDataByCity?.udf_demo_tbl_dpr_market && TableDataByCity?.udf_demo_tbl_dpr_market.length)
      Table_data = TableDataByCity?.udf_demo_tbl_dpr_market[0];
    else if (TableDataByZip && TableDataByZip?.udf_demo_tbl_zip && TableDataByZip?.udf_demo_tbl_zip.length)
      Table_data = TableDataByZip?.udf_demo_tbl_zip[0];
    else if (TableDataByCounty && TableDataByCounty?.udf_demo_tbl_county && TableDataByCounty?.udf_demo_tbl_county.length)
      Table_data = TableDataByCounty?.udf_demo_tbl_county[0];
    else if (TableDataByZipCodeAndRadius && TableDataByZipCodeAndRadius.udf_demo_tbl_zip_radius && TableDataByZipCodeAndRadius.udf_demo_tbl_zip_radius.length)
      Table_data = TableDataByZipCodeAndRadius.udf_demo_tbl_zip_radius[0];

    let table_data: IDemographicsTable[] = getDemographicsTableData(Table_data)
    setTableData([...table_data]);
    setTableDataCopy([...table_data])

  }, [TableDataByCityLoading, TableDataByZipLoading, TableDataByCountyLoading, TableDataByZipCodeAndRadiusLoading])

  const NegativeLinearProgress = styled(LinearProgress)(({ theme }) => ({
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#D65D5D' : '#D65D5D',
    },
    width: '50%',
    marginTop: '4px',
    transform: 'rotate(180deg)',
  }))

  const PositiveLinearProgress = styled(LinearProgress)(({ theme }) => ({
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#4CAF50' : '#4CAF50',
    },
    width: '50%',
    marginTop: '4px',
  }))

  const growthPercentage = (e) => {

    return (
      <Box>
        {e.data.pop_growth ?
          e.data.pop_growth < 0 ? (
            <Box style={{ display: 'flex' }}>
              <NegativeLinearProgress
                value={Math.abs(e.data.pop_growth)}
                variant="determinate"
              />
              <Box
                style={{
                  borderLeft: '2px solid rgb(192 192 192)',
                  height: '15px',
                }}
              ></Box>

              <PositiveLinearProgress value={0} variant="determinate" />
              <Box style={{ marginLeft: '12px' }}>{parseFloat(e.data.pop_growth)?.toFixed(1)}%</Box>
            </Box>
          ) : (
            <Box style={{ display: 'flex' }}>
              <NegativeLinearProgress value={0} variant="determinate" />
              <Box
                style={{
                  borderLeft: '2px solid rgb(192 192 192)',
                  height: '15px',
                }}
              ></Box>

              <PositiveLinearProgress
                value={e.data.pop_growth}
                variant="determinate"
              />
              <Box style={{ marginLeft: '12px' }}>{parseFloat(e.data.pop_growth)?.toFixed(1)}%</Box>
            </Box>
          )
          : null}
      </Box>
    )
  }


  return (
    <Grid md={12} lg={12}>
      <Box
        sx={{
          height: 'auto',
          paddingLeft: '11px',
          paddingBottom: '20px',
          width: 'calc(100vw - 50px)',
        }}
      >
        <DataGrid
          dataSource={tableData}
          keyExpr="age_cohort"
          showBorders={true}
          columnResizingMode="widget"
          hoverStateEnabled
          id="DemographicsTable"
          ref={dataGrid}
          style={{
            height: 'auto',
          }}
          width="calc(100vw - 50px)"
          allowColumnResizing
          allowColumnReordering
          columnAutoWidth
        >
          <Sorting mode="multiple" />
          <FilterRow visible />
          <Selection />
          <Scrolling useNative={true} preloadEnabled={true} />
          <HeaderFilter allowSearch visible={true} />
          <Column
            caption="Age Cohort"
            dataField="age_cohort"
            dataType="string"
            minWidth={150} alignment={'left'}
            width={100}
            allowSorting={false} allowFiltering={true}
          ></Column>

          <Column
            caption="Current Population"
            dataField="current_pop" dataType="number" alignment={"left"}
            minWidth={150} width={100} allowFiltering={true}
            cellRender={(data) => {
              return typeof data.value === "number" ? Math.round(data.value)?.toLocaleString() : data.value;
            }}
          ></Column>
          <Column
            caption="5-Yr Projected Population"
            dataField="proj_pop" dataType="number"
            minWidth={250} alignment={'left'}
            width={200} allowFiltering={true}
            cellRender={(data) => {
              return typeof data.value === "number" ? Math.round(data.value)?.toLocaleString() : data.value;
            }}
          ></Column>

          <Column
            cellRender={(e) => growthPercentage(e)}
            caption="5-Yr Growth %" allowSearch={false} dataType="string"
            dataField="pop_growth" alignment={'left'}
          ></Column>
        </DataGrid>
      </Box>
    </Grid>
  )
}
