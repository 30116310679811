import React, { useEffect, useState } from "react";
import { Avatar, Box, Button, Divider, Grid, IconButton, Menu, MenuItem } from "@mui/material";
import { styled } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Link, useLocation } from "react-router-dom";
import { useUser } from '../../../customHooks/useUser';
import dprLogo from '../../../Assets/dprlogo.webp';
import panoramaLogo from '../../../Assets/Panorama.webp';
import { Home } from "./Home";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from 'material-ui-popup-state/hooks'
import { Feedback } from "../../Header/Feedback/Feedback";
import { VariantType, useSnackbar } from 'notistack'
import CloseIcon from '@mui/icons-material/Close';
import axios from "axios";
import { useIsValidUser } from "../../../customHooks/useIsValidUser";


export const Header: React.FC = () => {
  const useUserDetails = useUser(); // DON'T REMOVE THIS (calling custom hooks for user details)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  let [userName, setUserName] = useState<any>("");
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  const [userImage, setUserImage] = useState('')
  const aboutMenuPopupState = usePopupState({ variant: 'popover', popupId: 'aboutMenu' })
  const location = useLocation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  useEffect(() => {
    if (userName === '') {
      setUserName(sessionStorage.getItem('user_name') ?? '')
    }
    if (userImage === '') {
      setTimeout(() => {
        setUserImage(sessionStorage.getItem('user_photo') ?? '')
      }, 3000)
    }
    sessionStorage.removeItem("EXPORT_DATA");
    if (sessionStorage.getItem('EXPORT_STATUS') !== "NOT_STARTED")
      sessionStorage.removeItem("EXPORT_STATUS");
    fetchList()

  }, [])

  const fetchList = async () => {
    sessionStorage.removeItem('alert');
    let accesss_token = sessionStorage.getItem('user_graphaccessToken');

    let list_data = await axios({
      url: `https://graph.microsoft.com/v1.0/sites/dprprod.sharepoint.com:/sites/Panorama-Healthcare:/lists/${process.env.REACT_APP_LIST_ID}/items?expand=fields`,
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + accesss_token
      }
    })
    if (list_data && list_data.data && list_data.data.value && list_data.data.value.length) {
      let filtered_data = list_data.data.value.filter(val => val.fields.Status === "Active")
      if (filtered_data && filtered_data.length) {
        enqueueSnackbar(
          filtered_data[filtered_data.length - 1]?.fields?.Alert_x0020_Title,
          {
            persist: true,
            key: 'Alert',
            variant: 'info',
            anchorOrigin: { horizontal: "center", vertical: "top" },
            action: (key) => (
              <IconButton
                color="inherit"
                aria-label="dismiss"
                onClick={() => closeSnackbar(key)}
              >
                <CloseIcon />
              </IconButton>
            ),
          }
        )
      }
    }
  }

  const HomeButton = (props) => (
    <Button
      sx={{
        fontWeight: 600,
        fontSize: '0.8rem',
        color: props.currentLocation === "/" ? "#4CAF50" : "#3e3e3e",
        '&:hover': {
          backgroundColor: 'none',
        },
        fontFamily: "Open Sans"
      }}
      disabled={!isValidUser}

    >
      {props.children}
    </Button>
  );

  const AdditionalResourcesButton = (props) => (
    < Button
      sx={{
        backgroundColor: 'transparent',
        fontWeight: 600,
        fontSize: '0.8rem',
        color: props.currentLocation === "/additional_resources" ? "#4CAF50" : "#3e3e3e",
        '&:hover': {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
        '&:active': {
          boxShadow: 'none',
          backgroundColor: 'transparent',
        },
        fontFamily: "Open Sans"
      }}
      disabled={!isValidUser}

    >
      {props.children}
    </Button >
  )

  const AboutButton = (props) => {

    return (
      <Button
        disabled={!isValidUser}
        sx={{
          backgroundColor: 'transparent',
          fontWeight: 600,
          fontSize: '0.8rem',
          color: props.currentLocation === "/about/about_panorama" || props.currentLocation === "/about/feedback" ? "#4CAF50" : "#3e3e3e",
          '&:hover': {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
          '&:active': {
            boxShadow: 'none',
            backgroundColor: 'transparent',
          },
          fontFamily: "Open Sans"
        }}>
        {props.children}
      </Button>
    )
  }
  const FeedBackButton = (props) => (
    <Button
      disabled={!isValidUser}
      sx={{
        backgroundColor: 'transparent',
        fontWeight: 600,
        color: "#3e3e3e",
        fontSize: '0.8rem',
        '&:hover': {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
        '&:active': {
          boxShadow: 'none',
          backgroundColor: 'transparent',
        },
        fontFamily: "Open Sans"
      }}>
      {props.children}
    </Button>
  )
  const AboutParanomaButton = (props) => (
    <Button
      sx={{
        backgroundColor: 'transparent',
        fontWeight: 600,
        fontSize: '0.8rem',
        color: props.currentLocation === "/about/about_panorama" ? "#4CAF50" : "#3e3e3e",
        '&:hover': {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
        '&:active': {
          boxShadow: 'none',
          backgroundColor: 'transparent',
        },
        fontFamily: "Open Sans"
      }}
    >
      {props.children}
    </Button>
  )

  const DPRLogoStyle = styled(Box)(() => ({
    backgroundImage: `url(${dprLogo})`,
    backgroundSize: "100% 100%",
    width: "78px",
    height: '32px',
    paddingTop: '5px',
    paddingBottom: '5px'
  }));

  const PanoramaLogoStyle = styled(Box)(() => ({
    backgroundImage: `url(${panoramaLogo})`,
    backgroundSize: "100% 100%",
    width: "100px",
    height: '15px',
    paddingTop: '5px',
    paddingBottom: '5px'
  }));

  const isValidUser = useIsValidUser();

  return (
    <React.Fragment>
      <header>
        <Feedback open={openDrawer} setOpen={(open) => { setOpenDrawer(open) }} />
        <Grid container sx={{ height: '50px' }}>
          <Grid item>
            <Box sx={{ width: '100vw', height: '50px' }} >
              <Grid container >
                <Grid item lg={1} md={1}>
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: "center", height: '50px' }}>
                    <DPRLogoStyle />
                  </Box>
                </Grid>
                <Grid item lg={0.1} md={0.1}>
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: "center", height: '50px' }}>
                    <Divider orientation="vertical" flexItem />
                  </Box>
                </Grid>
                <Grid item lg={1} md={1}>
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: "center", height: '50px' }}>
                    {isValidUser ?
                      <Link to="/" style={{ textDecoration: "none" }}>
                        <PanoramaLogoStyle />
                      </Link>
                      :
                      <PanoramaLogoStyle />
                    }
                  </Box>
                </Grid>
                <Grid item lg={3.9} md={3.9}>
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: "center", height: '50px' }}>
                  </Box>
                </Grid>
                <Grid item lg={1} md={1} >
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: "center", height: '50px' }}>
                    <Link to={isValidUser ? "/" : "/request_access"} style={{ textDecoration: "none" }} >
                      <HomeButton currentLocation={location.pathname}
                        disabled={!isValidUser}
                      >HOME</HomeButton>
                    </Link>
                  </Box>
                </Grid>
                <Grid item lg={1} md={1} >
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: "center", height: '50px' }}>
                    <Box sx={{ display: 'block' }}>
                      <Button
                        {...bindTrigger(aboutMenuPopupState)}
                        endIcon={<KeyboardArrowDownIcon />}
                        sx={{
                          fontWeight: 600,
                          fontSize: '0.8rem',
                          color: "#212121",
                          fontFamily: "Open Sans",
                          backgroundColor: 'transparent'
                        }}
                        disableElevation
                        disabled={!isValidUser}
                      >
                        <AboutButton currentLocation={location.pathname}>
                          ABOUT
                        </AboutButton>
                      </Button>
                      <Menu
                        {...bindMenu(aboutMenuPopupState)}
                      >
                        <MenuItem data-about-pan-id="About Panorama" onClick={() => aboutMenuPopupState.close()}><Link to={'/about/about_panorama'} style={{ textDecoration: 'none' }}><AboutParanomaButton currentLocation={location.pathname}>About Panorama</AboutParanomaButton></Link></MenuItem>
                        <MenuItem data-feedback-id="FeedBack" onClick={() => { aboutMenuPopupState.close(); setOpenDrawer(true); }}><FeedBackButton>Feedback</FeedBackButton></MenuItem>
                      </Menu>
                    </Box>
                  </Box>
                </Grid>
                <Grid item lg={2} md={2}>
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: "center", height: '50px' }}>
                    <Link to={isValidUser ? "/additional_resources" : "/request_access"} style={{ textDecoration: "none" }}>
                      <AdditionalResourcesButton currentLocation={location.pathname}
                        disabled={!isValidUser}
                        data-additional-resource-id="Additional Resources"
                      >ADDITIONAL RESOURCES  </AdditionalResourcesButton>
                    </Link>
                  </Box>
                </Grid>
                <Grid item lg={2} md={2} >
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: "center", height: '50px' }}>
                    <Avatar alt={userName} src={`data:image/png;base64, ${userImage}`} sx={{ width: "35px", height: "35px" }} />
                    <Box style={{ paddingLeft: '10px', fontSize: "13px", fontWeight: 600, textTransform: "uppercase", fontFamily: "Open Sans" }}>{userName}</Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </header >
    </React.Fragment >
  );
}
