import { ApplicationInsights, ITelemetryPlugin } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import Config from '../config/config';
import { environment } from '../config/config';
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';

const reactPlugin = new ReactPlugin();
const clickPluginInstance = new ClickAnalyticsPlugin();
const extensions: ITelemetryPlugin[] = [reactPlugin as unknown as ITelemetryPlugin, clickPluginInstance as unknown as ITelemetryPlugin]
// Click Analytics configuration
const clickPluginConfig = {
  autoCapture: true,
  dataTags: {
    useDefaultContentNameOrId: true
  }
};

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: Config.appInsightKey,
    enableAutoRouteTracking: true,
    autoTrackPageVisitTime: true,
    extensions: extensions,
    extensionConfig: {
      [clickPluginInstance.identifier]: clickPluginConfig
    },
  },
});
appInsights.loadAppInsights();
appInsights.addTelemetryInitializer((e: any) => {
  e.data.AppEnvironment = environment;
  e.data.AppSource = 'FRONTEND';
});
export { reactPlugin, appInsights };
