import React, { useContext, useEffect } from 'react'
import { AzureMap, AzureMapsContext, IAzureMapOptions, IAzureMapsContextProps } from 'react-azure-maps'
import { AuthenticationType, data } from 'azure-maps-control'
import { useLocation } from 'react-router-dom'
import { useGetHospitalMapDataQuery } from '../../../__generated__/graphql'
import * as service from 'azure-maps-rest'
import * as atlas from 'azure-maps-control'
import config from '../../../config/config'
import { control as legendControl } from '../../../customMapsControl/LayerLegendControl'
import { appInsights } from '../../../providers/appInsightProvider'
import { MapImageExporter } from '../../../customMapsControl/MapImageExport/MapImageExporter'
import { Skeleton } from '@mui/material'

interface GeneralInfoMapProps {
  reference?: atlas.Map | null
  mapReady?: boolean
}

const GeneralInfoMap: React.FC<GeneralInfoMapProps> = (
  props: GeneralInfoMapProps
) => {
  let point = new data.Position(0, 0)
  const { mapRef, isMapReady } = useContext<IAzureMapsContextProps>(
    AzureMapsContext
  )
  const search = useLocation().search
  const definitive_id = new URLSearchParams(search).get('id')
  const mainHospital = new URLSearchParams(search).get('provider')

  const getMapImage = () => {
    setTimeout(() => {
      if (mapRef) {
        MapImageExporter.getImage(mapRef).then((i) => {
          const image: any = i.getAttribute('src')
          sessionStorage.setItem('GeneralInfoMap', image)
        })
      }
    }, 10000)
  }

  let popup
  const {
    data: mapdata,
    loading: mapdataLoading,
    error: errormapdata,
  } = useGetHospitalMapDataQuery({
    variables: {
      definitive_id,
    },
  })

  useEffect(() => {
    if (mapRef && isMapReady) {
      if (
        mapdata?.DEFINITIVEHEALTHCARE_VW_HOSPITAL_INFO &&
        mapdata?.DEFINITIVEHEALTHCARE_VW_HOSPITAL_INFO.length
      ) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        point = new data.Position(
          mapdata?.DEFINITIVEHEALTHCARE_VW_HOSPITAL_INFO[0].longitude,
          mapdata?.DEFINITIVEHEALTHCARE_VW_HOSPITAL_INFO[0].latitude
        )

        mapRef.setCamera({
          center: point,
        })

        //@ts-ignore
        const legend = new legendControl.LegendControl({
          layout: 'column',
          itemLayout: 'row',
          showToggle: false,
          fitItem: true,

          legends: [
            {
              type: 'category',
              items: [
                {
                  color: '#4CAF50',
                  label: 'Selected Hospital',
                  shape: 'square',
                },
                {
                  color: 'Red',
                  label: 'Nearby Hospitals',
                  shape: 'square',
                },
              ],
            },
          ],
        })

        mapRef.controls.add(legend, {
          position: atlas.ControlPosition.TopRight,
        })

        popup = new atlas.Popup({
          closeButton: true,
        })

        const main_marker = new atlas.HtmlMarker({
          text: 'H',
          position: point,
          popup: popup,
          htmlContent:
            "<div><div class='pin bounce'></div><div class='pulse'></div></div>",
        })
        props?.reference?.markers.add(main_marker)
        props?.reference?.events.add('click', main_marker, (e) => {
          //Get the clicked marker.
          let m: any = e.target
          const properties = {
            Provider_Name: mainHospital,
            address: mapdata?.DEFINITIVEHEALTHCARE_VW_HOSPITAL_INFO[0].address
              ? mapdata?.DEFINITIVEHEALTHCARE_VW_HOSPITAL_INFO[0].address.replace(
                ' , ',
                ' '
              )
              : '-',
            phone: mapdata?.DEFINITIVEHEALTHCARE_VW_HOSPITAL_INFO[0].phone
              ? mapdata?.DEFINITIVEHEALTHCARE_VW_HOSPITAL_INFO[0].phone
              : '-',
          }
          appInsights.trackEvent({ name: 'Popup Opened on Map', properties })
          //Get custom properties on the marker
          popup.setOptions({
            content: `<div style="padding: 8px 8px 1px 8px;"><strong>Provider Information</strong></div><table style="height: 151px; width: 96%; margin:8px; border-collapse: collapse;" border="1"> <tbody>  <tr style="height: 26px;">  <td style="width: 15.3282%; height: 26px; padding:5px;">Provider Name</td>  <td style="width: 34.6718%; height: 26px; padding:5px;">${mainHospital}</td>  </tr>  <tr style="height: 26px;">  <td style="width: 15.3282%; height: 31px; padding:5px;">Phone</td>  <td style="width: 34.6718%; height: 31px; padding:5px;">${mapdata?.DEFINITIVEHEALTHCARE_VW_HOSPITAL_INFO[0].phone
              ? mapdata?.DEFINITIVEHEALTHCARE_VW_HOSPITAL_INFO[0].phone
              : '-'
              }</td>  </tr>  <tr style="height: 26px;">  <td style="width: 15.3282%; height: 27px; padding:5px;">Address</td>  <td style="width: 34.6718%; height: 27px; padding:5px;">${mapdata?.DEFINITIVEHEALTHCARE_VW_HOSPITAL_INFO[0].address
                ? mapdata?.DEFINITIVEHEALTHCARE_VW_HOSPITAL_INFO[0].address.replace(
                  ' , ',
                  ' '
                )
                : '-'
              }</td>  </tr></tbody>  </table>`,
            position: m.getOptions().position,
            pixelOffset: [0, 0],
          })

          //Open the popup.
          popup.open(mapRef)
        })

        let pipeline = service.MapsURL.newPipeline(
          new service.MapControlCredential(mapRef)
        )
        let searchURL = new service.SearchURL(pipeline)
        let query = 'hospital'
        searchURL
          .searchPOI(service.Aborter.timeout(10000), query, {
            limit: 10,
            lat: mapdata?.DEFINITIVEHEALTHCARE_VW_HOSPITAL_INFO[0].latitude,
            lon: mapdata?.DEFINITIVEHEALTHCARE_VW_HOSPITAL_INFO[0].longitude,
            radius: 6000,
            view: 'Auto',
          })
          .then((results) => {
            let hospitalData = results.geojson.getFeatures()
            if (hospitalData) {
              for (let i = 0; i < hospitalData.features.length; i++) {
                let element = hospitalData.features[i]
                point = new data.Position(
                  element.geometry.coordinates[0],
                  element.geometry.coordinates[1]
                )
                let popup1 = new atlas.Popup({
                  closeButton: false,
                })

                if (i === hospitalData.features.length - 1) {
                  getMapImage()
                }

                let other_hospital_marker = new atlas.HtmlMarker({
                  color: 'Red',
                  text: 'H',
                  position: point,
                  popup: popup1,
                })
                props?.reference?.markers.add(other_hospital_marker)

                props?.reference?.events.add(
                  'click',
                  other_hospital_marker,
                  (e) => {
                    //Get the clicked marker.
                    let m: any = e.target

                    const properties = {
                      Provider_Name: element?.properties?.poi?.name,
                      address: element?.properties?.address?.freeformAddress
                        ? element?.properties?.address?.freeformAddress.replace(
                          ' , ',
                          ' '
                        )
                        : '-',
                      phone: element?.properties?.poi?.phone
                        ? element?.properties?.poi?.phone
                        : '-',
                    }
                    appInsights.trackEvent({
                      name: 'Popup Opened on Map',
                      properties,
                    })

                    //Get custom properties on the marker
                    popup.setOptions({
                      content: `<div style="padding: 8px 8px 1px 8px;"><strong>Provider Information</strong></div><table style="height: 151px; width: 96%; margin:8px; border-collapse: collapse;" border="1"> <tbody>  <tr style="height: 26px;">  <td style="width: 15.3282%; height: 26px; padding:5px;">Provider Name</td>  <td style="width: 34.6718%; height: 26px; padding:5px;">${element?.properties?.poi?.name
                        }</td>  </tr>  <tr style="height: 26px;">  <td style="width: 15.3282%; height: 31px; padding:5px;">Phone</td>  <td style="width: 34.6718%; height: 31px; padding:5px;">${element?.properties?.poi?.phone
                          ? element?.properties?.poi?.phone
                          : '-'
                        }</td>  </tr>  <tr style="height: 26px;">  <td style="width: 15.3282%; height: 27px; padding:5px;">Address</td>  <td style="width: 34.6718%; height: 27px; padding:5px;">${element?.properties?.address?.freeformAddress
                          ? element?.properties?.address?.freeformAddress.replace(
                            ' , ',
                            ' '
                          )
                          : '-'
                        }</td>  </tr></tbody>  </table>`,
                      position: m.getOptions().position,
                      pixelOffset: [0, 0],
                    })

                    //Open the popup.
                    popup.open(props?.reference)
                  }
                )
              }
            }
          })
      }
    }
  }, [props.reference, props.mapReady, mapdataLoading])

  useEffect(() => {
    if (errormapdata instanceof Error) {
      appInsights.trackException({
        exception: errormapdata,
        error: new Error('Failed to fetch Hospital Map data.'),
      })
    }
  }, [errormapdata])

  const option: IAzureMapOptions = {
    authOptions: {
      authType: AuthenticationType.subscriptionKey,
      subscriptionKey: config.azure_subscription_key,
    },
    renderWorldCopies: true,
    showLogo: false,
    view: 'Auto',
    center: [0, 0],
    zoom: 15,
    type: 'fly',
    preserveDrawingBuffer: true,
  }

  return (
    <AzureMap
      options={option}
      LoaderComponent={() => {
        return (
          <React.Fragment>
            <Skeleton variant="rectangular" width="100%">
              <div style={{ paddingTop: '53%' }} />
            </Skeleton>
          </React.Fragment>
        )
      }}
    ></AzureMap>
  )
}

export default GeneralInfoMap
