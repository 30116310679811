import { useIsAuthenticated } from '@azure/msal-react';
import { useState } from 'react';

export function useIsValidUser() {
    const isAuthenticated = useIsAuthenticated()
    const [isValidUser, setIsValidUser] = useState<boolean>(false)

    setInterval(() => {
        if (isAuthenticated) {
            const strValidUser = sessionStorage.getItem("IS_VALID_USER")
            if (strValidUser && strValidUser === "1") {
                setIsValidUser(true)
            }
            // setIsValidUser(sessionStorage.getItem("IS_VALID_USER") ?? null)
        }
    }, 1000)

    return isValidUser;
}
