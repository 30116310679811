import React, { useEffect, useState } from 'react'
import {
  useGetValidateZipcodeLazyQuery,
  useGetServiceAreaTableDataForCityByZipLazyQuery,
  useGetServiceAreaTableDataForZipCodeByZipLazyQuery,
  useGetServiceAreaTableDataForCountyByZipLazyQuery,
  useGetServiceAreaTableDataForZipCodeAndRadiusByZipLazyQuery,
} from '../../../__generated__/graphql'
import * as atlas from 'azure-maps-control'
import { AzureMap, IAzureMapOptions } from 'react-azure-maps'
import { AuthenticationType, data } from 'azure-maps-control'
import config from '../../../config/config'
import { useLocation } from 'react-router-dom'
import { control as legendControl } from '../../../customMapsControl/LayerLegendControl'
import { appInsights } from '../../../providers/appInsightProvider'
import { calculatePercentile } from '../../../config/commonFunction'
import { Skeleton } from '@mui/material'

interface ServiceAreaMapProps {
  reference: atlas.Map | null
  mapReady: boolean
  MapView: string
  AgeBracket: string
  Providerdata: any
  type: string
}

const ServiceAreaMap: React.FC<ServiceAreaMapProps> = (
  props: ServiceAreaMapProps
) => {
  const search = useLocation().search
  const dpr_city = new URLSearchParams(search).get('city')
  const zipCode = new URLSearchParams(search).get('zipcode')
  const county = new URLSearchParams(search).get('county')
  const radius = new URLSearchParams(search).get('radius')

  const [cityGeometryData, setCityGeometryData] = useState<string[]>([])

  const [mapDataSource, setMapDataSource] = useState<any>()
  const [layer1, setLayer1] = useState(0)
  const [layer2, setLayer2] = useState(0)
  const [layer3, setLayer3] = useState(0)
  const [layer4, setLayer4] = useState(0)
  const [layer5, setLayer5] = useState(0)

  const [growthlayer1, setGrowthLayer1] = useState(0)
  const [growthlayer2, setGrowthLayer2] = useState(0)
  const [growthlayer3, setGrowthLayer3] = useState(0)
  const [growthlayer4, setGrowthLayer4] = useState(0)
  const [growthlayer5, setGrowthLayer5] = useState(0)

  let county_data: string[] = []
  if (county) {
    let counties_array = county.split(',')
    for (let i = 0; i < counties_array.length; i++) {
      const element = counties_array[i]
      let splitted_val = element.split(' - ')
      let string_val: string = splitted_val[1].trim() + splitted_val[0].trim()
      county_data.push(string_val)
    }
  }

  const [
    getDprCityData,
    {
      data: mapData,
      loading: loadingMapData,
      error: errorMapData,
    },
  ] = useGetServiceAreaTableDataForCityByZipLazyQuery({
    variables: {
      city: dpr_city,
      age_group: props.AgeBracket === "All" ? 'Adult,Geriatric,Pediatric' : props.AgeBracket
    },
    fetchPolicy: 'network-only',
  })

  const [
    getzipcodeData,
    {
      data: zipMapData,
      loading: loadingZipMapData,
      error: errorZipMapData,
    },
  ] = useGetServiceAreaTableDataForZipCodeByZipLazyQuery({
    variables: {
      zipcode: zipCode,
      age_group: props.AgeBracket === "All" ? 'Adult,Geriatric,Pediatric' : props.AgeBracket
    },
    fetchPolicy: 'network-only',
  })

  const [
    getCountyData,
    {
      data: countyData,
      loading: loadingcountyData,
      error: errorcountyData,
    },
  ] = useGetServiceAreaTableDataForCountyByZipLazyQuery({
    variables: {
      county: county_data.join(','),
      age_group: props.AgeBracket === "All" ? 'Adult,Geriatric,Pediatric' : props.AgeBracket
    },
    fetchPolicy: 'network-only',
  })

  const [
    getZipCodeRadiusdata,
    {
      data: zipRadiusData,
      loading: loadingZipRadiusData,
      error: errorZipRadiusData,
    },
  ] = useGetServiceAreaTableDataForZipCodeAndRadiusByZipLazyQuery({
    variables: {
      zipcode: zipCode,
      radius: radius ? parseInt(radius) : 0,
      age_group: props.AgeBracket === "All" ? 'Adult,Geriatric,Pediatric' : props.AgeBracket
    },
    fetchPolicy: 'network-only',
  })

  const [
    getGeometryData,
    {
      data: geometryData,
      loading: geometryDataLoading,
      error: geometryDataError,
    },
  ] = useGetValidateZipcodeLazyQuery({
    variables: {
      zip_codes: cityGeometryData,
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (errorMapData instanceof Error) {
      appInsights.trackException({ exception: errorMapData, error: new Error("Failed to fetch Service Area Map data for City.") })
    }
    else if (errorZipMapData instanceof Error) {
      appInsights.trackException({ exception: errorZipMapData, error: new Error("Failed to fetch Service Area Map data for Zip Code.") })
    }
    else if (errorZipRadiusData instanceof Error) {
      appInsights.trackException({ exception: errorZipRadiusData, error: new Error("Failed to fetch Service Area Map data for Zip Code and Radius.") })
    }
    else if (errorcountyData instanceof Error) {
      appInsights.trackException({ exception: errorcountyData, error: new Error("Failed to fetch Service Area Map data for County.") })
    }
    else if (geometryDataError instanceof Error) {
      appInsights.trackException({ exception: geometryDataError, error: new Error("Failed to validate Zip codes.") })
    }
  }, [errorMapData, errorZipMapData, errorcountyData, geometryDataError, errorZipRadiusData])


  let dataSource = new atlas.source.DataSource()

  let point = new data.Position(0, 0)
  let popup

  const LegendColor = (type) => {
    let expr: any = ''
    if (
      type === 'CurrentPopulationDensity' ||
      type === 'ProjectPopulation'
    ) {
      expr = [
        'case',
        ['<=', ['get', type], layer1],
        '#f69a81',
        ['<=', ['get', type], layer2],
        '#f59660',
        ['<=', ['get', type], layer3],
        '#f58c1f',
        ['<=', ['get', type], layer4],
        '#cb7812',
        ['<=', ['get', type], layer5],
        '#794f07',
        '#794f07',
      ]
    } else if (
      type === 'GrowthRate'
    ) {
      expr = [
        'case',
        ['<=', ['get', 'GrowthRate'], -5],
        '#f69a81',
        ['<=', ['get', 'GrowthRate'], -3],
        '#f59660',
        ['<=', ['get', 'GrowthRate'], 0],
        '#f58c1f',
        ['<=', ['get', 'GrowthRate'], 3],
        '#cb7812',
        ['<=', ['get', 'GrowthRate'], 25],
        '#794f07',
        '#794f07',
      ]
    }
    return expr
  }

  const setLegends = (mapLegendData) => {
    if (mapLegendData) {
      let legend_data_city = JSON.parse(JSON.stringify(mapLegendData));
      let legend_data_growth = JSON.parse(JSON.stringify(mapLegendData));

      if (props.MapView === 'GrowthRate') {
        let dprGrowthPopulation = legend_data_growth.sort((a, b) => {
          if (a.population_growth && b.population_growth) {
            return a.population_growth - b.population_growth
          } else {
            return (a.population_growth || 0) - (b.population_growth || 0)
          }
        })

        let lowestGrowth = dprGrowthPopulation[0].population_growth
        setGrowthLayer1(lowestGrowth)
        setGrowthLayer2(lowestGrowth + 10)
        setGrowthLayer3(lowestGrowth + 20)
        setGrowthLayer4(lowestGrowth + 30)
        setGrowthLayer5(lowestGrowth + 40)
      }
      else {
        let dprCityPopulation = legend_data_city.sort((a, b) => {
          if (props.MapView === 'ProjectPopulation') {
            if (a.proj_population && b.proj_population) {
              return a.proj_population - b.proj_population
            } else {
              return (a.proj_population || 0) - (b.proj_population || 0)
            }
          }
          else {
            if (a.current_population && b.current_population) {
              return a.current_population - b.current_population
            } else {
              return (a.current_population || 0) - (b.current_population || 0)
            }
          }
        })

        let pop_arr: any = [];
        for (let index = 0; index < dprCityPopulation.length; index++) {
          const element = dprCityPopulation[index];
          if (props.MapView === 'ProjectPopulation' && element.proj_population)
            pop_arr.push(element.proj_population);
          else if (element.current_population)
            pop_arr.push(element.current_population);
        }
        let pop = calculatePercentile(pop_arr, 0.2)
        let pop1 = calculatePercentile(pop_arr, 0.4)
        let pop2 = calculatePercentile(pop_arr, 0.6)
        let pop3 = calculatePercentile(pop_arr, 0.8)
        let pop4 = calculatePercentile(pop_arr, 1.0)

        setLayer1(pop)
        setLayer2(pop1)
        setLayer3(pop2)
        setLayer4(pop3)
        setLayer5(pop4)

        setGrowthLayer1(0)
        setGrowthLayer2(0)
        setGrowthLayer3(0)
        setGrowthLayer4(0)
        setGrowthLayer5(0)
      }

    }
  }

  useEffect(() => {
    if (growthlayer5 && props.MapView === 'GrowthRate') {
      let label1: string = ''
      let label2: string = ''
      let label3: string = ''
      let label4: string = ''
      let label5: string = ''

      label1 = label1.concat(
        growthlayer1.toFixed().toString() + "%",
        ' - ',
        growthlayer2.toFixed().toString() + "%"
      )
      label2 = label2.concat(
        growthlayer2.toFixed().toString() + "%",
        ' - ',
        growthlayer3.toFixed().toString() + "%"
      )
      label3 = label3.concat(
        growthlayer3.toFixed().toString() + "%",
        ' - ',
        growthlayer4.toFixed().toString() + "%"
      )
      label4 = label4.concat(
        growthlayer4.toFixed().toString() + "%",
        ' - ',
        growthlayer5.toFixed().toString() + "%"
      )
      label5 = label5.concat(
        'Above ', growthlayer5.toFixed().toString() + "%"
      )
      //@ts-ignore
      const Growthlegend = new legendControl.LegendControl({
        layout: 'column',
        itemLayout: 'row',
        showToggle: false,
        fitItem: true,
        title: 'Growth Rate',
        legends: [
          {
            type: 'category',
            // eslint-disable-next-line no-sparse-arrays
            items: [
              {
                color: '#f69a81',
                label: label1,
                shape: 'square',
              },
              {
                color: '#f59660',
                label: label2,
                shape: 'square',
              },
              {
                color: '#f58c1f',
                label: label3,
                shape: 'square',
              },
              {
                color: '#cb7812',
                label: label4,
                shape: 'square',
              },
              {
                color: '#794f07',
                label: label5,
                shape: 'square',
              }
            ],
          },
        ],
        id: 'Service-Growth',
      })
      // setgrowthLegend(1)
      props.reference?.controls.add(Growthlegend, {
        position: atlas.ControlPosition.TopRight,
      })
    }
    else if (layer5) {
      let label1: string = ''
      let label2: string = ''
      let label3: string = ''
      let label4: string = ''
      let label5: string = ''

      label1 = label1.concat('0 - ', parseInt(layer1.toFixed()).toLocaleString().toString())
      label2 = label2.concat(
        parseInt(layer1.toFixed()).toLocaleString().toString(),
        ' - ',
        parseInt(layer2.toFixed()).toLocaleString().toString()
      )
      label3 = label3.concat(
        parseInt(layer2.toFixed()).toLocaleString().toString(),
        ' - ',
        parseInt(layer3.toFixed()).toLocaleString().toString()
      )
      label4 = label4.concat(
        parseInt(layer3.toFixed()).toLocaleString().toString(),
        ' - ',
        parseInt(layer4.toFixed()).toLocaleString().toString()
      )
      label5 = label5.concat('Above ', parseInt(layer4.toFixed()).toLocaleString().toString())
      //@ts-ignore
      const Populationlegend = new legendControl.LegendControl({
        layout: 'column',
        itemLayout: 'row',
        showToggle: false,
        fitItem: true,
        title: 'Population Density',
        legends: [
          {
            type: 'category',
            // eslint-disable-next-line no-sparse-arrays
            items: [
              {
                color: '#f69a81',
                label: label1,
                shape: 'square',
              },
              {
                color: '#f59660',
                label: label2,
                shape: 'square',
              },
              {
                color: '#f58c1f',
                label: label3,
                shape: 'square',
              },
              {
                color: '#cb7812',
                label: label4,
                shape: 'square',
              },
              {
                color: '#794f07',
                label: label5,
                shape: 'square',
              }
            ],
          },
        ],
        id: props.type === "demographics" ? 'demographics-population' : 'service-Population',
      })
      props.reference?.controls.add(Populationlegend, {
        position: atlas.ControlPosition.TopRight,
      })
    }
  }, [layer5, growthlayer5])

  const removeAllLegend = () => {
    if (document.querySelectorAll('[id=demographics-population]').length) {
      for (let i = 0; i < document.querySelectorAll('[id=demographics-population]').length; i++) {
        const element = document.querySelectorAll('[id=demographics-population]')[i];
        if (element && element.parentNode) {
          //@ts-ignore
          element.parentNode.style.display = 'none'
        }
      }
    }
    if (document.querySelectorAll('[id=service-Population]').length) {
      for (let i = 0; i < document.querySelectorAll('[id=service-Population]').length; i++) {
        const element = document.querySelectorAll('[id=service-Population]')[i];
        if (element && element.parentNode) {
          //@ts-ignore
          element.parentNode.style.display = 'none'
        }
      }
    }
    if (document.querySelectorAll('[id=Service-Growth]').length) {
      for (let i = 0; i < document.querySelectorAll('[id=Service-Growth]').length; i++) {
        const element = document.querySelectorAll('[id=Service-Growth]')[i];
        if (element && element.parentNode) {
          //@ts-ignore
          element.parentNode.style.display = 'none'
        }
      }
    }
  }

  const addDensity = (data, obj) => {
    let data1 = JSON.parse(JSON.stringify(data))
    data1.features[0].properties['CurrentPopulationDensity'] = obj.current_population
    data1.features[0].properties['ProjectPopulation'] = obj.proj_population
    data1.features[0].properties['GrowthRate'] = obj.population_growth
    return data1
  }

  useEffect(() => {
    if (props.Providerdata) {
      for (let i = 0; i < props.Providerdata.length; i++) {

        let element = props.Providerdata[i]
        point = new data.Position(element.longitude, element.latitude)
        popup = new atlas.Popup({
          closeButton: true,
        })

        let hospital_marker = new atlas.HtmlMarker({
          color: 'Red',
          text: 'H',
          position: point,
          popup: popup,
        })
        props?.reference?.markers.add(hospital_marker)
        // eslint-disable-next-line no-loop-func
        props?.reference?.events.add('click', hospital_marker, (e) => {
          //Get the clicked marker.
          let m: any = e.target

          //Get custom properties on the marker
          let score =
            typeof element.total_performance_score === 'number'
              ? parseFloat(element.total_performance_score)?.toFixed(1)
              : element.total_performance_score

          const properties = {
            Provider_Name: element.hospital_name, Parent_Health_System: element.parent_health_system,
            address: element.address.replace(' , ', ' '),
            number_of_beds: element.number_of_beds, score: score
          }
          appInsights.trackEvent({ name: "Service Area | Popup Opened on Map", properties })

          popup.setOptions({
            content: `<div style="width:600px"><div style="padding: 8px 8px 1px 8px;"><strong>Provider Information</strong></div><table style="height: 151px; width: 96%; margin:8px; border-collapse: collapse;" border="1"> <tbody> <tr style="height: 26px;"> <td style="width: 15.3282%; height: 26px; padding:5px">Provider Name</td> <td style="width: 34.6718%; height: 26px; padding:5px;word-break: break-word;white-space: break-spaces;">${element.hospital_name
              }</td> </tr> <tr style="height: 26px;"> <td style="width: 15.3282%; height: 31px; padding:5px;">Parent Health System</td> <td style="width: 34.6718%; height: 31px; padding:5px;word-break: break-word;white-space: break-spaces;">${element.parent_health_system
              }</td> </tr> <tr style="height: 26px;"> <td style="width: 15.3282%; height: 27px; padding:5px;">Address</td> <td style="width: 34.6718%; height: 27px; padding:5px;word-break: break-word;white-space: break-spaces;">${element.address.replace(
                ' , ', ' ')}</td> </tr> <tr style="height: 32px;"> <td style="width: 15.3282%; height: 31px; padding:5px;">Number of Beds</td> <td style="width: 34.6718%; height: 31px; padding:5px; word-break: break-word;white-space: break-spaces;">${element.number_of_beds
              }</td> </tr> <tr style="height: 30px;"> <td style="width: 15.3282%; height: 36px; padding:5px;">Peformance Score</td> <td style="width: 34.6718%; height: 36px; padding:5px;word-break: break-word;white-space: break-spaces;">${score}</td> </tr> </tbody> </table></div>`,
            position: m.getOptions().position,
            pixelOffset: [0, 0],
            showPointer: false
          })
          //Open the popup.
          popup.open(props?.reference)
        })
      }
    }
  }, [props.Providerdata])

  const getZipData = () => {
    if (zipMapData?.udf_tbl_by_zip_multi_zip && props.reference && props.mapReady) {
      let zipcodes: string[] = []

      setLegends(zipMapData?.udf_tbl_by_zip_multi_zip)

      zipMapData?.udf_tbl_by_zip_multi_zip.map((zip_codes) => {
        if (zip_codes.zipcode) {
          zipcodes.push(zip_codes.zipcode)
        }
      })

      if (zipcodes) {
        setCityGeometryData(zipcodes)
      }
    }
  }

  const getCityData = () => {
    if (mapData?.udf_tbl_by_zip_dpr_city && props.reference && props.mapReady) {
      let zip_codes: string[] = []
      setLegends(mapData?.udf_tbl_by_zip_dpr_city)

      mapData?.udf_tbl_by_zip_dpr_city.map((city) => {
        if (city.zipcode) {
          zip_codes.push(city.zipcode)
        }
      })

      if (zip_codes) {
        setCityGeometryData(zip_codes)
      }
    }
  }

  const getZipRadiusData = () => {
    if (
      zipRadiusData?.udf_zip_radius_by_zip_tbl &&
      props.reference &&
      props.mapReady
    ) {
      let zipcodes: string[] = []

      setLegends(zipRadiusData?.udf_zip_radius_by_zip_tbl)
      zipRadiusData?.udf_zip_radius_by_zip_tbl.map((zip_codes) => {
        if (zip_codes.zipcode) {
          zipcodes.push(zip_codes.zipcode)
        }
      })
      if (zipcodes) {
        setCityGeometryData(zipcodes)
      }
    }
  }

  const getCountyMapData = () => {
    if (countyData?.udf_tbl_by_zip_multi_county && props.reference && props.mapReady) {
      let zip_codes: string[] = []

      setLegends(countyData?.udf_tbl_by_zip_multi_county)

      countyData?.udf_tbl_by_zip_multi_county.map((county) => {
        if (county.zipcode) {
          zip_codes.push(county.zipcode)
        }
      })

      if (zip_codes) {
        setCityGeometryData(zip_codes)
      }
    }
  }

  useEffect(() => {
    getGeometryData()
  }, [cityGeometryData])

  useEffect(() => {
    if (
      geometryData?.OTHERDATA_ZIPCODE_MAPPING &&
      props.reference &&
      props.mapReady
    ) {
      props.reference?.sources.add(dataSource)
      let map_data: any = []
      if (dpr_city) {
        map_data = mapData?.udf_tbl_by_zip_dpr_city
      }
      else if (zipCode && radius) {
        map_data = zipRadiusData?.udf_zip_radius_by_zip_tbl
      }
      else if (zipCode) {
        map_data = zipMapData?.udf_tbl_by_zip_multi_zip
      } else if (county) {
        map_data = countyData?.udf_tbl_by_zip_multi_county
      }
      if (map_data && map_data.length) {
        geometryData.OTHERDATA_ZIPCODE_MAPPING.map((Geometry) => {
          map_data.map((propertyData) => {
            if (Geometry?.zip_geometry && Geometry.zipcode === propertyData.zipcode) {
              let densityData: any = addDensity(
                Geometry.zip_geometry.geometry_json.body.additionalData[0]
                  .geometryData,
                propertyData
              )
              if (densityData) {
                dataSource.add(densityData)
                setMapDataSource(dataSource)
              }
              if (dataSource) {
                props.reference?.layers.add(
                  new atlas.layer.PolygonLayer(dataSource, 'Service area data', {
                    fillColor: LegendColor('CurrentPopulationDensity'),
                    fillOpacity: 0.5,
                  })
                )
              }

              props.reference?.setCamera({
                center: [
                  Geometry.zip_geometry.geometry_json.center.lon,
                  Geometry.zip_geometry.geometry_json.center.lat,
                ],
              })
            }
          })
        })
      }
    }
  }, [geometryDataLoading])

  const setLegendVal = () => {
    removeAllLegend();

    if (zipMapData?.udf_tbl_by_zip_multi_zip)
      setLegends(zipMapData?.udf_tbl_by_zip_multi_zip)
    else if (mapData?.udf_tbl_by_zip_dpr_city)
      setLegends(mapData?.udf_tbl_by_zip_dpr_city)
    else if (zipRadiusData?.udf_zip_radius_by_zip_tbl)
      setLegends(zipRadiusData?.udf_zip_radius_by_zip_tbl)
    else if (countyData?.udf_tbl_by_zip_multi_county)
      setLegends(countyData?.udf_tbl_by_zip_multi_county)
  }

  useEffect(() => {
    if (props.MapView && props.reference) {
      if (
        props.MapView === 'ProjectPopulation' &&
        props.AgeBracket && mapDataSource
      ) {
        props.reference?.layers.add(
          new atlas.layer.PolygonLayer(mapDataSource, 'Service area data', {
            fillColor: LegendColor('ProjectPopulation'),
            fillOpacity: 0.5,
          })
        )
        setLegendVal();

      } else if (
        props.MapView === 'CurrentPopulation' &&
        props.AgeBracket && mapDataSource
      ) {
        props.reference?.layers.add(
          new atlas.layer.PolygonLayer(mapDataSource, 'Service area data', {
            fillColor: LegendColor('CurrentPopulationDensity'),
            fillOpacity: 0.5,
          })
        )
        setLegendVal();

      } else if (props.MapView === 'GrowthRate' && props.AgeBracket && mapDataSource) {
        props.reference?.layers.add(
          new atlas.layer.PolygonLayer(mapDataSource, 'Service area data', {
            fillColor: LegendColor('GrowthRate'),
            fillOpacity: 0.5,
          })
        )
        setLegendVal();

      }
    }
  }, [props.MapView])


  const fetchMapData = () => {
    if (dpr_city) {
      getDprCityData()
    }
    else if (zipCode && radius) {
      getZipCodeRadiusdata()
    }
    else if (zipCode) {
      getzipcodeData()
    } else if (county) {
      getCountyData()
    }
  }
  useEffect(() => {
    fetchMapData();
  }, [])

  useEffect(() => {
    removeAllLegend();
    fetchMapData();
  }, [props.AgeBracket])


  useEffect(() => {
    if (
      mapData &&
      mapData.udf_tbl_by_zip_dpr_city &&
      mapData?.udf_tbl_by_zip_dpr_city.length
    ) {
      getCityData()
    } else if (
      zipMapData &&
      zipMapData?.udf_tbl_by_zip_multi_zip &&
      zipMapData.udf_tbl_by_zip_multi_zip.length
    ) {
      getZipData()
    }
    else if (
      zipRadiusData &&
      zipRadiusData.udf_zip_radius_by_zip_tbl &&
      zipRadiusData.udf_zip_radius_by_zip_tbl.length
    ) {
      getZipRadiusData()
    }
    else if (
      countyData &&
      countyData.udf_tbl_by_zip_multi_county &&
      countyData.udf_tbl_by_zip_multi_county.length
    ) {
      getCountyMapData()
    }
  }, [
    loadingMapData,
    props.reference,
    props.mapReady,
    loadingZipMapData,
    loadingZipRadiusData,
    loadingcountyData,
  ])

  const option: IAzureMapOptions = {
    authOptions: {
      authType: AuthenticationType.subscriptionKey,
      subscriptionKey: config.azure_subscription_key,
    },
    renderWorldCopies: true,
    showLogo: false,
    view: 'Auto',
    center: [0, 0],
    zoom: 7,
    type: 'fly',
  }

  return <AzureMap options={option} LoaderComponent={() => {
    return <React.Fragment><Skeleton variant="rectangular" width="100%">
      <div style={{ paddingTop: '40%' }} />
    </Skeleton></React.Fragment>
  }

  }></AzureMap>
}

export default ServiceAreaMap