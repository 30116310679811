import React, { useState } from 'react'
import MapController from '../../../CommonComponents/Maps/MapController'
import { AzureMapsProvider } from 'react-azure-maps'
import { Box, Button, ButtonGroup, Grid } from '@mui/material'
import { ServiceAreaDataTable } from './ServiceAreaDataTable'
import { ServiceAreaFilterButton } from './ServiceAreaFilterButton'
import PredictiveNeedsMapPDF from '../../../CommonComponents/Maps/PredictiveNeedsMapPDF'
import CountyAreaMapPDF from '../../../CommonComponents/Maps/CountyAreaMapPDF'

export interface IServiceAreaProps {}

export const ServiceArea: React.FC = () => {
  const [mapview, setMapview] = useState('CurrentPopulation')
  const [ageBracket, setAgeBracket] = useState('All')
  const [Providerdata, setProviderData] = useState()
  const [buttonType, GetButtonType] = useState('Provider')

  const getFilterMapdata = (mapview, ageBracket) => {
    setMapview(mapview)
    setAgeBracket(ageBracket)
  }

  const getProviderData = (data: any) => {
    setProviderData(data)
  }

  React.useEffect(() => {
    sessionStorage.setItem('Demo_age_group', 'All');
  }, [])

  return (
    <Box>
      <Grid
        container
        style={{
          overflow: 'scroll',
          height: '70vh',
        }}
      >
        <Grid
          item
          lg={12}
          md={12}
          style={{
            height: '40vh',
          }}
        >
          <AzureMapsProvider>
            <MapController
              MapType="ServiceArea"
              MapView={mapview}
              AgeBracket={ageBracket}
              Providerdata={Providerdata}
            />
          </AzureMapsProvider>
        </Grid>
        <Grid
          container
          lg={12}
          md={12}
          style={{ height: '63px', margin: '5px 0px' }}
        >
          <Grid md={6} lg={6}>
            <ServiceAreaFilterButton
              mapview={mapview}
              ageBracket={ageBracket}
              getFilterMapdata={getFilterMapdata}
              setMapview={setMapview}
              setAgeBracket={setAgeBracket}
            />
          </Grid>
          <Grid md={6} lg={6}>
            <ButtonGroup
              variant="contained"
              sx={{
                margin: '4px 14px 4px 10px',
                float: 'right',
              }}
            >
              <Button
                data-by-provider-id="Service Area | By Provider"
                style={{
                  backgroundColor:
                    buttonType === 'Provider' ? '#22577A' : '#FFFFFF',
                  color: buttonType === 'Provider' ? '#FFFFFF' : '#22577A',
                  textTransform: 'capitalize',
                }}
                onClick={() => {
                  GetButtonType('Provider')
                }}
              >
                By Provider
              </Button>
              <Button
                data-by-zip-code-id="Service Area | By Zip Code"
                style={{
                  backgroundColor:
                    buttonType === 'Zipcode' ? '#22577A' : '#FFFFFF',
                  color: buttonType === 'Zipcode' ? '#FFFFFF' : '#22577A',
                  textTransform: 'capitalize',
                }}
                onClick={() => {
                  GetButtonType('Zipcode')
                }}
              >
                By Zip Code
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
        <Grid item lg={12} md={12}>
          <ServiceAreaDataTable
            Data={getProviderData}
            buttonType={buttonType}
            ageBracket={ageBracket}
          />
        </Grid>
      </Grid>
    </Box>
  )
}


