export enum EnvironmentTypes {
  DEV = 'DEV',
  PROD = 'PROD',
}

export const SSConst = {
  EXPORT_DATA: 'EXPORT_DATA',
  EXPORT_PREDICTIVE_DATA: 'EXPORT_PREDICTIVE_DATA',
  EXPORT_STATUS: 'EXPORT_STATUS',
  COUNTY_HEALTH_DATA: 'COUNTY_HEALTH_DATA',
  COUNTIES: 'COUNTIES',
  EXPORT_COUNTY_DATA: 'EXPORT_COUNTY_DATA'
}

export let environment = process.env.REACT_APP_ENVIRONMENT ?? EnvironmentTypes.DEV;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  hasura_url: process.env.REACT_APP_HASURA_URL ?? "",
  azure_subscription_key: '3Z87mmBiF_YMeNkU3RExQpbtBLe61fQwATaVkLtV498',
  appInsightKey: process.env.REACT_APP_APPINSIGHT_KEY ?? ""
}

export const zipCodeRadiusList = [
  {
    value: '5',
    label: '5 miles',
  },
  {
    value: '10',
    label: '10 miles',
  },
  {
    value: '15',
    label: '15 miles',
  },
  {
    value: '25',
    label: '25 miles',
  },
  {
    value: '50',
    label: '50 miles',
  },
  {
    value: '100',
    label: '100 miles',
  }
];
