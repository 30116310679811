import React, { useEffect, useState } from 'react'
import { DemographicsCard } from './DemographicsCard'
import { DemographicsTable } from './DemograhicsTable'
import { AzureMapsProvider } from 'react-azure-maps'
import MapController from '../../../CommonComponents/Maps/MapController'
import { Box, Grid } from '@mui/material'
import { ServiceAreaFilterButton } from '../ServiceArea/ServiceAreaFilterButton'

export const DemographicsTab: React.FC = () => {
  const [mapview, setMapview] = useState('CurrentPopulation')
  const [ageBracket, setAgeBracket] = useState('All')
  const [Providerdata, setProviderData] = useState()
  const getFilterMapdata = (mapview, ageBracket) => {
    setMapview(mapview)
    setAgeBracket(ageBracket)
  }
  useEffect(() => {
    sessionStorage.setItem('Demo_age_group', 'All')
  }, [])
  return (
    <Box>
      <Grid
        container
        style={{
          overflow: 'scroll',
          height: '70vh',
        }}
      >
        <Grid item lg={12} md={12} style={{ paddingTop: '4px' }}>
          <ServiceAreaFilterButton
            mapview={mapview}
            ageBracket={ageBracket}
            getFilterMapdata={getFilterMapdata}
            setMapview={setMapview}
            setAgeBracket={setAgeBracket}
          />
        </Grid>
        <Grid container lg={12} md={12}>
          <Grid
            item
            lg={7.8}
            md={7.8}
            style={{
              height: '38vh',
              marginLeft: '1%',
              marginTop: '5px',
            }}
          >
            <AzureMapsProvider>
              <MapController
                MapType="Demographic"
                MapView={mapview}
                AgeBracket={ageBracket}
                Providerdata={Providerdata}
              ></MapController>
            </AzureMapsProvider>
          </Grid>
          <Grid lg={4} md={4} item>
            <DemographicsCard />
          </Grid>
        </Grid>
        <Grid lg={12} md={12} item>
          <DemographicsTable AgeBracket={ageBracket} />
        </Grid>
      </Grid>
    </Box>
  )
}
