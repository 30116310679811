import { useState } from 'react';

export enum ExportStatus {
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETE = 'COMPLETE',
    NOT_STARTED = 'NOT_STARTED'
}

export function useExportData() {

    const [exportStatus, setExportstatus] = useState<ExportStatus>(ExportStatus.NOT_STARTED)

    function getExportStatus() {

        const strExportStatus = sessionStorage.getItem("EXPORT_STATUS")

        switch (strExportStatus) {
            case "IN_PROGRESS":
                setExportstatus(ExportStatus.IN_PROGRESS)
                break;
            case "COMPLETE":
                setExportstatus(ExportStatus.COMPLETE)
                break;
            default:
                setExportstatus(ExportStatus.NOT_STARTED)
                break;
        }
    }

    setInterval(() => {
        getExportStatus()
    }, 1000)

    return exportStatus;
}
