import React, { useContext, useEffect, useState } from 'react'
import {
  useGetPredictiveNeedsMapDataForCityLazyQuery,
  useGetPredictiveNeedsMapDataForCountyLazyQuery,
  useGetPredictiveNeedsMapDataForZipCodeAndRadiusLazyQuery,
  useGetPredictiveNeedsMapDataForZipCodeLazyQuery,
  useGetValidateZipcodeLazyQuery,
} from '../../../__generated__/graphql'
import * as atlas from 'azure-maps-control'
import {
  AzureMap,
  AzureMapsContext,
  IAzureMapOptions,
  IAzureMapsContextProps,
} from 'react-azure-maps'
import { AuthenticationType } from 'azure-maps-control'
import config from '../../../config/config'
import { useLocation } from 'react-router-dom'
import { control as legendControl } from '../../../customMapsControl/LayerLegendControl'
import { appInsights } from '../../../providers/appInsightProvider'
import { calculatePercentile } from '../../../config/commonFunction'
import { MapImageExporter } from '../../../customMapsControl/MapImageExport'

interface PredictiveNeedsMapProps {
  reference?: atlas.Map | null
  mapReady?: boolean
  siteOfCare: string
  ageBracket: string
  serviceLine: string
  year: string
}

const PredictiveNeedsMapPDF: React.FC<PredictiveNeedsMapProps> = (
  props: PredictiveNeedsMapProps
) => {
  const { mapRef, isMapReady } = useContext<IAzureMapsContextProps>(
    AzureMapsContext
  )
  const search = useLocation().search
  const dpr_city = new URLSearchParams(search).get('city')
  // const dpr_city = 'Austin'
  const zipCode = new URLSearchParams(search).get('zipcode')
  const county = new URLSearchParams(search).get('county')
  const radius = new URLSearchParams(search).get('radius')

  const [zipGeometryData, setZipGeometryData] = useState<string[]>([])

  const [mapDataSource, setMapDataSource] = useState<any>()
  const [vol1, setVol1] = useState(0)
  const [vol2, setVol2] = useState(0)
  const [vol3, setVol3] = useState(0)
  const [vol4, setVol4] = useState(0)
  const [vol5, setVol5] = useState(0)

  const getMapImage = () => {
    if (mapRef) {
      setTimeout(() => {
        MapImageExporter.getImage(mapRef).then((i) => {
          const image: any = i.getAttribute('src')
          sessionStorage.setItem('PredictiveNeedMap', image)
        })
      }, 10000)
    }
  }

  const [
    loadPredictiveNeedsMapDataForCity,
    {
      data: PredictiveNeedsMapDataForCity,
      loading: PredictiveNeedsMapDataForCityLoading,
      error: PredictiveNeedsMapDataForCityError,
    },
  ] = useGetPredictiveNeedsMapDataForCityLazyQuery({
    variables: {
      city: dpr_city,
      patient_type: props.siteOfCare,
      age_group: props.ageBracket,
      sl: props.serviceLine,
    },
    fetchPolicy: 'network-only',
  })

  const [
    loadPredictiveNeedsMapDataForZipCode,
    {
      data: PredictiveNeedsMapDataForZipCode,
      loading: PredictiveNeedsMapDataForZipCodeLoading,
      error: PredictiveNeedsMapDataForZipCodeError,
    },
  ] = useGetPredictiveNeedsMapDataForZipCodeLazyQuery({
    variables: {
      zipcode: zipCode,
      patient_type: props.siteOfCare,
      age_group: props.ageBracket,
      sl: props.serviceLine,
    },
    fetchPolicy: 'network-only',
  })

  let county_data: string[] = []
  if (county) {
    let counties_array = county.split(',')
    for (let i = 0; i < counties_array.length; i++) {
      const element = counties_array[i]
      let splitted_val = element.split(' - ')
      let string_val: string = splitted_val[1].trim() + splitted_val[0].trim()
      county_data.push(string_val)
    }
  }

  const [
    loadPredictiveNeedsMapDataForCounty,
    {
      data: PredictiveNeedsMapDataForCounty,
      loading: PredictiveNeedsMapDataForCountyLoading,
      error: PredictiveNeedsMapDataForCountyError,
    },
  ] = useGetPredictiveNeedsMapDataForCountyLazyQuery({
    variables: {
      county: county_data.join(','),
      patient_type: props.siteOfCare,
      age_group: props.ageBracket,
      sl: props.serviceLine,
    },
    fetchPolicy: 'network-only',
  })

  const [
    loadPredictiveNeedsMapDataForZipCodeAndRadius,
    {
      data: PredictiveNeedsMapDataForZipCodeAndRadius,
      loading: PredictiveNeedsMapDataForZipCodeAndRadiusLoading,
      error: PredictiveNeedsMapDataForZipCodeAndRadiusError,
    },
  ] = useGetPredictiveNeedsMapDataForZipCodeAndRadiusLazyQuery({
    variables: {
      zipcode: zipCode,
      radius: radius ? parseInt(radius) : 1,
      patient_type: props.siteOfCare,
      age_group: props.ageBracket,
      sl: props.serviceLine,
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (PredictiveNeedsMapDataForZipCodeAndRadiusError instanceof Error) {
      appInsights.trackException({
        exception: PredictiveNeedsMapDataForZipCodeAndRadiusError,
        error: new Error(
          'Failed to fetch Predictive Needs Map data for Zip code and Radius.'
        ),
      })
    } else if (PredictiveNeedsMapDataForCountyError instanceof Error) {
      appInsights.trackException({
        exception: PredictiveNeedsMapDataForCountyError,
        error: new Error(
          'Failed to fetch Predictive Needs Map data for County.'
        ),
      })
    } else if (PredictiveNeedsMapDataForZipCodeError instanceof Error) {
      appInsights.trackException({
        exception: PredictiveNeedsMapDataForZipCodeError,
        error: new Error(
          'Failed to fetch Predictive Needs Map data for Zip code.'
        ),
      })
    } else if (PredictiveNeedsMapDataForCityError instanceof Error) {
      appInsights.trackException({
        exception: PredictiveNeedsMapDataForCityError,
        error: new Error('Failed to fetch Predictive Needs Map data for City.'),
      })
    }
  }, [
    PredictiveNeedsMapDataForZipCodeAndRadiusError,
    PredictiveNeedsMapDataForCountyError,
    PredictiveNeedsMapDataForZipCodeError,
    PredictiveNeedsMapDataForCityError,
  ])

  let dataSource = new atlas.source.DataSource()

  useEffect(() => {
    if (vol5) {
      let label1: string = ''
      let label2: string = ''
      let label3: string = ''
      let label4: string = ''
      let label5: string = ''
      label1 = label1.concat('0', ' - ', vol1.toFixed().toString())
      label2 = label2.concat(
        vol1.toFixed().toString(),
        ' - ',
        vol2.toFixed().toString()
      )
      label3 = label3.concat(
        vol2.toFixed().toString(),
        ' - ',
        vol3.toFixed().toString()
      )
      label4 = label4.concat(
        vol3.toFixed().toString(),
        ' - ',
        vol4.toFixed().toString()
      )
      label5 = label5.concat('Above ', vol4.toFixed().toString())

      //@ts-ignore

      const Volumelegend = new legendControl.LegendControl({
        layout: 'column',
        itemLayout: 'row',
        showToggle: false,
        fitItem: true,
        title: 'Volume Density',
        legends: [
          {
            type: 'category',
            // eslint-disable-next-line no-sparse-arrays
            items: [
              {
                color: '#f69a81',
                label: label1,
                shape: 'square',
              },
              {
                color: '#f59660',
                label: label2,
                shape: 'square',
              },
              {
                color: '#f58c1f',
                label: label3,
                shape: 'square',
              },
              {
                color: '#cb7812',
                label: label4,
                shape: 'square',
              },
              {
                color: '#794f07',
                label: label5,
                shape: 'square',
              },
            ],
          },
        ],
        id: 'ranking-legend',
      })

      mapRef?.controls.add(Volumelegend, {
        position: atlas.ControlPosition.TopRight,
      })
    }
  }, [vol5])

  useEffect(() => {
    removeAllLegend()
    if (
      PredictiveNeedsMapDataForZipCodeAndRadius?.udf_pred_needs_map_zip_radius
    ) {
      setLegends(
        PredictiveNeedsMapDataForZipCodeAndRadius?.udf_pred_needs_map_zip_radius
      )
    } else if (PredictiveNeedsMapDataForCounty?.udf_pred_needs_map_county) {
      setLegends(PredictiveNeedsMapDataForCounty?.udf_pred_needs_map_county)
    } else if (PredictiveNeedsMapDataForCity?.udf_pred_needs_map_dpr_market) {
      setLegends(PredictiveNeedsMapDataForCity?.udf_pred_needs_map_dpr_market)
    } else if (PredictiveNeedsMapDataForZipCode?.udf_pred_needs_map_zip) {
      setLegends(PredictiveNeedsMapDataForZipCode?.udf_pred_needs_map_zip)
    }
    if (props.year === '10-YearProjected' && mapDataSource) {
      mapRef?.layers.add(
        new atlas.layer.PolygonLayer(mapDataSource, 'Predictive Needs data', {
          fillColor: LegendColor('volume_density_10'),
          fillOpacity: 0.5,
        })
      )
    } else if (props.year === '5-YearProjected' && mapDataSource) {
      mapRef?.layers.add(
        new atlas.layer.PolygonLayer(mapDataSource, 'Predictive Needs data', {
          fillColor: LegendColor('volume_density_5'),
          fillOpacity: 0.5,
        })
      )
    } else if (mapDataSource) {
      mapRef?.layers.add(
        new atlas.layer.PolygonLayer(mapDataSource, 'Predictive Needs data', {
          fillColor: LegendColor('volume_density_curr'),
          fillOpacity: 0.5,
        })
      )
    }
  }, [props.year])

  const removeAllLegend = () => {
    for (
      let i = 0;
      i < document.querySelectorAll('[id=ranking-legend]').length;
      i++
    ) {
      const element = document.querySelectorAll('[id=ranking-legend]')[i]
      if (element && element.parentNode) {
        //@ts-ignore
        element.parentNode.style.display = 'none'
      }
    }
  }

  useEffect(() => {
    loadData()
  }, [props.siteOfCare, props.ageBracket, props.serviceLine])

  const LegendColor = (year) => {
    let expr: any = ''
    if (year === 'volume_density_10') {
      expr = [
        'case',
        ['<=', ['get', 'volume_density_10'], vol1],
        '#f69a81',
        ['<=', ['get', 'volume_density_10'], vol2],
        '#f59660',
        ['<=', ['get', 'volume_density_10'], vol3],
        '#f58c1f',
        ['<=', ['get', 'volume_density_10'], vol4],
        '#cb7812',
        ['<=', ['get', 'volume_density_10'], vol5],
        '#794f07',
        '#794f07',
      ]
    } else if (year === 'volume_density_5') {
      expr = [
        'case',
        ['<=', ['get', 'volume_density_5'], vol1],
        '#f69a81',
        ['<=', ['get', 'volume_density_5'], vol2],
        '#f59660',
        ['<=', ['get', 'volume_density_5'], vol3],
        '#f58c1f',
        ['<=', ['get', 'volume_density_5'], vol4],
        '#cb7812',
        ['<=', ['get', 'volume_density_5'], vol5],
        '#794f07',
        '#794f07',
      ]
    } else {
      expr = [
        'case',
        ['<=', ['get', 'volume_density_curr'], vol1],
        '#f69a81',
        ['<=', ['get', 'volume_density_curr'], vol2],
        '#f59660',
        ['<=', ['get', 'volume_density_curr'], vol3],
        '#f58c1f',
        ['<=', ['get', 'volume_density_curr'], vol4],
        '#cb7812',
        ['<=', ['get', 'volume_density_curr'], vol5],
        '#794f07',
        '#794f07',
      ]
    }

    return expr
  }

  const addDensity = (data, obj) => {
    let data1 = JSON.parse(JSON.stringify(data))
    data1.features[0].properties['volume_density_10'] = obj.volume_10_yr
    data1.features[0].properties['volume_density_5'] = obj.volume_5_yr
    data1.features[0].properties['volume_density_curr'] = obj.vol_current
    return data1
  }

  const setLegends = (mapLegendData) => {
    if (mapLegendData) {
      let legend_data = JSON.parse(JSON.stringify(mapLegendData))
      let volumeDensity = legend_data.sort((a, b) => {
        if (props.year === '10-YearProjected') {
          if (a.volume_10_yr && b.volume_10_yr) {
            return a.volume_10_yr - b.volume_10_yr
          } else {
            return (a.volume_10_yr || 0) - (b.volume_10_yr || 0)
          }
        } else if (props.year === '5-YearProjected') {
          if (a.volume_5_yr && b.volume_5_yr) {
            return a.volume_5_yr - b.volume_5_yr
          } else {
            return (a.volume_5_yr || 0) - (b.volume_5_yr || 0)
          }
        } else {
          if (a.vol_current && b.vol_current) {
            return a.vol_current - b.vol_current
          } else {
            return (a.vol_current || 0) - (b.vol_current || 0)
          }
        }
      })
      let density_arr: any = []
      for (let index = 0; index < volumeDensity.length; index++) {
        const element = volumeDensity[index]
        if (props.year === '10-YearProjected')
          density_arr.push(element.volume_10_yr)
        else if (props.year === '5-YearProjected')
          density_arr.push(element.volume_5_yr)
        else density_arr.push(element.vol_current)
      }

      let percentile = calculatePercentile(density_arr, 0.2)
      let percentile1 = calculatePercentile(density_arr, 0.4)
      let percentile2 = calculatePercentile(density_arr, 0.6)
      let percentile3 = calculatePercentile(density_arr, 0.8)
      let percentile4 = calculatePercentile(density_arr, 1.0)

      setVol1(percentile)
      setVol2(percentile1)
      setVol3(percentile2)
      setVol4(percentile3)
      setVol5(percentile4)
    }
  }

  const getZipData = () => {
    if (
      PredictiveNeedsMapDataForZipCode?.udf_pred_needs_map_zip &&
      mapRef &&
      isMapReady
    ) {
      let zipcodes: string[] = []

      setLegends(PredictiveNeedsMapDataForZipCode?.udf_pred_needs_map_zip)

      PredictiveNeedsMapDataForZipCode?.udf_pred_needs_map_zip.map(
        (zip_codes) => {
          if (zip_codes.zipcode) {
            zipcodes.push(zip_codes.zipcode)
          }
        }
      )

      if (zipcodes) {
        setZipGeometryData(zipcodes)
      }
    }
  }

  const getCityData = () => {
    if (
      PredictiveNeedsMapDataForCity?.udf_pred_needs_map_dpr_market &&
      mapRef &&
      isMapReady
    ) {
      let zip_codes: string[] = []
      setLegends(PredictiveNeedsMapDataForCity?.udf_pred_needs_map_dpr_market)

      PredictiveNeedsMapDataForCity?.udf_pred_needs_map_dpr_market.map(
        (city) => {
          if (city.zipcode) {
            zip_codes.push(city.zipcode)
          }
        }
      )

      if (zip_codes) {
        setZipGeometryData(zip_codes)
      }
    }
  }

  const getZipRadiusData = () => {
    if (
      PredictiveNeedsMapDataForZipCodeAndRadius?.udf_pred_needs_map_zip_radius &&
      mapRef &&
      isMapReady
    ) {
      let zipcodes: string[] = []

      setLegends(
        PredictiveNeedsMapDataForZipCodeAndRadius?.udf_pred_needs_map_zip_radius
      )
      PredictiveNeedsMapDataForZipCodeAndRadius?.udf_pred_needs_map_zip_radius.map(
        (zip_codes) => {
          if (zip_codes.zipcode) {
            zipcodes.push(zip_codes.zipcode)
          }
        }
      )
      if (zipcodes) {
        setZipGeometryData(zipcodes)
      }
    }
  }

  const getCountyMapData = () => {
    if (
      PredictiveNeedsMapDataForCounty?.udf_pred_needs_map_county &&
      mapRef &&
      isMapReady
    ) {
      let zip_codes: string[] = []

      setLegends(PredictiveNeedsMapDataForCounty?.udf_pred_needs_map_county)

      PredictiveNeedsMapDataForCounty?.udf_pred_needs_map_county.map(
        (county) => {
          if (county.zipcode) {
            zip_codes.push(county.zipcode)
          }
        }
      )

      if (zip_codes) {
        setZipGeometryData(zip_codes)
      }
    }
  }

  const loadData = () => {
    if (dpr_city) {
      loadPredictiveNeedsMapDataForCity()
    } else if (zipCode && radius) {
      loadPredictiveNeedsMapDataForZipCodeAndRadius()
    } else if (zipCode) {
      loadPredictiveNeedsMapDataForZipCode()
    } else if (county) {
      loadPredictiveNeedsMapDataForCounty()
    }
  }

  const [
    getGeometryData,
    {
      data: geometryData,
      loading: geometryDataLoading,
      error: geometryDataError,
    },
  ] = useGetValidateZipcodeLazyQuery({
    variables: {
      zip_codes: zipGeometryData,
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    getGeometryData()
  }, [zipGeometryData])

  useEffect(() => {
    if (
      geometryData?.OTHERDATA_ZIPCODE_MAPPING &&
      geometryData?.OTHERDATA_ZIPCODE_MAPPING.length &&
      mapRef &&
      isMapReady
    ) {
      mapRef?.sources.add(dataSource)
      let map_data: any = []
      if (dpr_city) {
        map_data = PredictiveNeedsMapDataForCity?.udf_pred_needs_map_dpr_market
      } else if (zipCode && radius) {
        map_data =
          PredictiveNeedsMapDataForZipCodeAndRadius?.udf_pred_needs_map_zip_radius
      } else if (zipCode) {
        map_data = PredictiveNeedsMapDataForZipCode?.udf_pred_needs_map_zip
      } else if (county) {
        map_data = PredictiveNeedsMapDataForCounty?.udf_pred_needs_map_county
      }
      let count = 0

      geometryData?.OTHERDATA_ZIPCODE_MAPPING.map((Geometry) => {
        if (map_data && map_data.length) {
          map_data.map((propertyData) => {
            if (Geometry?.zip_geometry && Geometry.zipcode === propertyData.zipcode) {
              let densityData: any = addDensity(
                Geometry.zip_geometry.geometry_json.body.additionalData[0]
                  .geometryData,
                propertyData
              )
              if (densityData) {
                dataSource.add(densityData)
                setMapDataSource(dataSource)
              }
              if (dataSource) {
                count = 1
                mapRef?.layers.add(
                  new atlas.layer.PolygonLayer(
                    dataSource,
                    'Predictive Needs data',
                    {
                      fillColor: LegendColor('volume_density_10'),
                      fillOpacity: 0.5,
                    }
                  )
                )
              }

              mapRef?.setCamera({
                center: [
                  Geometry.zip_geometry.geometry_json.center.lon,
                  Geometry.zip_geometry.geometry_json.center.lat,
                ],
              })
            }
          })
        }
      })

      if (count == 1) {
        getMapImage()
      }
    }
  }, [geometryDataLoading])

  useEffect(() => {
    loadData()
  }, [])

  const getPredictiveNeedsMapData = () => {
    if (
      PredictiveNeedsMapDataForCity &&
      PredictiveNeedsMapDataForCity.udf_pred_needs_map_dpr_market &&
      PredictiveNeedsMapDataForCity?.udf_pred_needs_map_dpr_market.length
    ) {
      removeAllLegend()
      getCityData()
    } else if (
      PredictiveNeedsMapDataForZipCode &&
      PredictiveNeedsMapDataForZipCode?.udf_pred_needs_map_zip &&
      PredictiveNeedsMapDataForZipCode.udf_pred_needs_map_zip.length
    ) {
      removeAllLegend()
      getZipData()
    } else if (
      PredictiveNeedsMapDataForZipCodeAndRadius &&
      PredictiveNeedsMapDataForZipCodeAndRadius.udf_pred_needs_map_zip_radius &&
      PredictiveNeedsMapDataForZipCodeAndRadius.udf_pred_needs_map_zip_radius
        .length
    ) {
      removeAllLegend()
      getZipRadiusData()
    } else if (
      PredictiveNeedsMapDataForCounty &&
      PredictiveNeedsMapDataForCounty.udf_pred_needs_map_county &&
      PredictiveNeedsMapDataForCounty.udf_pred_needs_map_county.length
    ) {
      removeAllLegend()
      getCountyMapData()
    }
  }

  useEffect(() => {
    getPredictiveNeedsMapData()
  }, [
    PredictiveNeedsMapDataForCountyLoading,
    mapRef,
    isMapReady,
    PredictiveNeedsMapDataForZipCodeAndRadiusLoading,
    PredictiveNeedsMapDataForZipCodeLoading,
    PredictiveNeedsMapDataForCityLoading,
  ])

  const option: IAzureMapOptions = {
    authOptions: {
      authType: AuthenticationType.subscriptionKey,
      subscriptionKey: config.azure_subscription_key,
    },
    renderWorldCopies: true,
    showLogo: false,
    view: 'Auto',
    center: [0, 0],
    zoom: 7,
    type: 'fly',
    preserveDrawingBuffer: true,
  }

  return <AzureMap options={option}></AzureMap>
}

export default PredictiveNeedsMapPDF
